import { createApp } from 'vue'
import { createServiceWorker } from '@/registerServiceWorker'
import { createAvocado } from '@avocado/avocado'

import { vMaska } from 'maska'
import App from '@/App.vue'

import { vuePinia } from '@/pinia'
import { vueI18n } from '@/i18n'
import { vueRouter } from '@/router'

import 'bootstrap'
import 'driver.js/dist/driver.css'
import ConfirmationService from 'primevue/confirmationservice'
import Aura from '@primevue/themes/aura'

import PrimeVue from 'primevue/config'
import '@/assets/sass/core/theme.scss' // Переопределяем тему primevue
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import vue3GoogleLogin from 'vue3-google-login'

const gAuthOptions = {
  clientId: process.env.APP_GOOGLE_CLIENT_ID
}

createServiceWorker()

const app = createApp(App)
const avocado = createAvocado()

const { routes, i18nOptions, plugin } = avocado.init()

const pinia = vuePinia()
const i18n = vueI18n(i18nOptions)
const router = vueRouter(routes)

app.use(pinia)
app.use(PrimeVue, {
  zIndex: {
    modal: 1059
  },
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: '.app-dark',
      cssLayer: false
    }
  }
})
app.use(i18n)
app.use(router)
app.use(plugin)
app.use(ConfirmationService)
app.use(ToastService)
app.use(vue3GoogleLogin, gAuthOptions)

app.directive('maska', vMaska)
app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)

app.mount('#app')
