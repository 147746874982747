import type { StoreType } from '../types'
import { AcademicCoursesResourcesSingleRequest } from '@shared/swagger'

import { getAcademicCoursesResourceSingle } from '@shared/api'

export default async function fetchAcademicSingleCoursesResource (this: StoreType, body: AcademicCoursesResourcesSingleRequest): Promise<void> {
  const { ok, data } = await getAcademicCoursesResourceSingle(body)

  if (ok) {
    this.setAcademicSingleCoursesResource(data)
  }
}
