import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, ResourceRequest, KnowledgeBaseResourceResponse } from '@shared/swagger'

export default async function getKnowledgeResource (params: ResourceRequest): Promise<AxiosResponse<KnowledgeBaseResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeBaseResourceResponse>({
    url: 'knowledge/resources',
    body: { params: params },
    loader: 'knowledge/resources'
  })

  return response
}
