import { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: false
  },
  {
    title: 'common.table.course-title',
    field: 'courseTitle',
    slotCellName: 'courseTitle',
    slotName: 'courseTitleHeader',
    sortable: false
  },
  {
    title: 'common.table.editor',
    field: 'editor_name',
    sortable: true
  },
  {
    title: 'common.table.status',
    field: 'status',
    cellClass: 'text-center',
    subHeaders: [
      {
        title: 'common.en',
        field: 'status_for_english',
        slotCellName: 'status_for_english',
        cellClass: 'text-center'
      },
      {
        title: 'common.ru',
        field: 'status_for_russian',
        slotCellName: 'status_for_russian',
        cellClass: 'text-center'
      },
      {
        title: 'common.kz',
        field: 'status_for_kazakh',
        slotCellName: 'status_for_kazakh',
        cellClass: 'text-center'
      }
    ]
  },
  {
    title: 'common.table.details',
    field: 'details',
    subHeaders: [
      {
        title: 'common.table.credits',
        field: 'credits',
        cellClass: 'text-center'
      },
      {
        title: 'common.table.ects',
        field: 'ects',
        cellClass: 'text-center'
      }
    ]
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]
