import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, UnknownRequest } from '@shared/swagger'

export default async function deleteProgramSectionHour (section: number, program: number, schedule: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownRequest, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}/hours/${schedule}`,
    body: { },
    loader: 'delete-academic/manage/schedules/sections/program/hours'
  })

  return response
}
