import type { ProfileEducationArticlesGetResponse } from '@/shared/swagger'
import { getProfileEducationArticles } from '@shared/api'

import type { StoreType } from '../types'
import { AxiosResponse } from '@/shared/types'

const articleTypes = [
  'international',
  'national',
  'other'
]

export default async function fetchArticles (this:StoreType): Promise<void> {
  const request: Array<Promise<{type: string, response: AxiosResponse<ProfileEducationArticlesGetResponse>}>> = []

  for (const articleType in articleTypes) {
    request.push(getProfileEducationArticles({ type: articleTypes[articleType] }))
  }

  const responses = await Promise.all(request)

  responses.forEach(({ type, response }) => {
    const { ok, data } = response
    if (ok) {
      this.setArticles(type, data)
    }
  })
}
