<template>
  <div
    id="export-actions"
    class="d-flex justify-content-between mb-4"
  >
    <div class="d-flex flex-row gap-2">
      <a
        v-test-id="'print'"
        class="btn btn-sm mr-2"
        :class="'btn-primary'"
        @click="onPrintAction"
      >
        {{ $t('domains.administrative.gate-entry.table.print') }}
      </a>
      <a
        v-test-id="'table-download'"
        class="btn btn-sm mr-2"
        :class="'btn-primary'"
        @click="onExcelDownloadAction"
      >
        {{ $t('domains.administrative.gate-entry.table.download') }}
      </a>
      <a
        class="btn btn-sm mr-2"
        :class="'btn-primary'"
        @click="openWorkload"
      >
        {{ $t('domains.administrative.gate-entry.table.Workload') }}
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAlert } from '@shared/composable'
import { useStaffList } from '../store'
import { printEmployeeList } from '../utils'
import { utils, writeFile } from 'xlsx'

const { t } = useI18n()
const { error } = useAlert()
const store = useStaffList()
const employees = computed(() => store.getEmployees)

const onExcelDownloadAction = (): void => {
  const wb = utils.book_new()
  let number = 1

  const wsData = employees.value.map((employee) => [
    `${number++}`,
    `${employee.name} ${employee.surname}`,
    `${employee.department_positions.map(pos => pos.title).join('\n')}`,
    `${employee.staff}`,
    `${employee.staff_status}`,
    `${employee.outer_email}`,
    `${employee.inner_phone === null ? '' : employee.inner_phone}`
  ])

  const ws = utils.aoa_to_sheet([['NO', 'FULL NAME', 'POSITION', 'STAFF', 'STATUS', 'EMAIL', 'INNER PHONE'], ...wsData])

  ws['!cols'] = [{ wch: 5 }, { wch: 25 }, { wch: 40 }, { wch: 20 }, { wch: 35 }, { wch: 25 }]
  utils.book_append_sheet(wb, ws, 'GATEENTRY')
  writeFile(wb, 'StaffList.xlsx')
}

const onPrintAction = () => {
  const headers = [
    t('common.table.no'),
    t('common.table.full-name'),
    t('common.table.position'),
    t('common.table.staff'),
    t('common.table.status'),
    t('common.table.email'),
    t('common.table.inner-phone')
  ]

  if (employees.value.length > 0) {
    printEmployeeList(employees.value, headers)
  } else {
    error({ text: 'No records found' })
  }
}

const openWorkload = () => {
  store.setCurrentStep('workload')
}
</script>
