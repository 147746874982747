import type { DropdownMenuItem } from '@shared/types'
import { exportSchedule } from '../utils'

export const dropdownItems: DropdownMenuItem[] = [
  {
    label: '',
    menuType: 'slot',
    slotName: 'menuWithSlot'
  },
  {
    menuType: 'link',
    label: 'domains.academic.my-schedule.actions.google',
    menuIcon: true,
    menuIconClass: 'bi-google',
    action: () => exportSchedule('google')
  },
  {
    menuType: 'link',
    label: 'domains.academic.my-schedule.actions.microsoft',
    menuIcon: true,
    menuIconClass: 'bi-microsoft',
    action: () => exportSchedule('microsoft')
  },
  {
    menuType: 'link',
    label: 'domains.academic.my-schedule.actions.file-earmark-excel-fill',
    menuIcon: true,
    menuIconClass: 'bi-file-earmark-excel-fill',
    action: () => exportSchedule('file-earmark-excel-fill')
  },
  {
    menuType: 'link',
    label: 'domains.academic.my-schedule.actions.printer-fill',
    menuIcon: true,
    menuIconClass: 'bi-printer-fill',
    action: () => exportSchedule('printer-fill')
  }
]
