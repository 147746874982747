import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesQRPostRequest, AcademicSingleCourseAttendancesQRPostResponse } from '@shared/swagger'

import { postAcademicSingleCourseAttendanceQR } from '@shared/api'

export default async function (this: StoreType, params: AcademicSingleCourseAttendancesQRPostRequest): Promise<AcademicSingleCourseAttendancesQRPostResponse> {
  const { data } = await postAcademicSingleCourseAttendanceQR(params)

  return data
}
