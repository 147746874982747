export const formatContent = (text: string) => {
  const regex = /<img[^>]*>/g
  const matches = text.match(regex)
  if (matches) {
    matches.forEach((match) => {
      const newMatch = match.replace('>', ' style="max-width: 100% !important;">')
      text = text.replace(match, newMatch)
    })
  }

  return text
}
