<template>
  <template v-if="curricula">
    <readonly-view-long-text
      :information="curricula.information"
      class="mb-4"
    />
    <pms-tab
      v-model:active="active"
      :tabs="tabs"
    >
      <template #program>
        <readonly-view-program
          :tab="active"
          :curricula="curricula"
        />
      </template>
      <template #elective>
        <readonly-view-elective
          :tab="active"
          :curricula="curricula"
        />
      </template>
      <template #reference>
        <readonly-view-reference
          :tab="active"
          :curricula="curricula"
        />
      </template>
    </pms-tab>
  </template>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'

import ReadonlyViewLongText from './ReadonlyViewLongText.vue'
import ReadonlyViewProgram from './ReadonlyViewProgram.vue'
import ReadonlyViewElective from './ReadonlyViewElective.vue'
import ReadonlyViewReference from './ReadonlyViewReference.vue'

import { useStore } from '../../../store'
import { readonlyTabs as tabs } from '../values'

const store = useStore()
const active = ref<string>('all')
const curricula = computed(() => store.getCurricula)
</script>
