<template>
  <div class="row">
    <div class="col-12 mb-6">
      <syllabus-header />
    </div>
    <div class="col-12">
      <editor-syllabus />
    </div>
  </div>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n'
import { currentLanguage } from '../values'
import SyllabusHeader from './SyllabusHeader.vue'
import EditorSyllabus from '../features/EditorSyllabus/EditorSyllabus.vue'
const i18n = useI18n()
if (!currentLanguage.value) {
  currentLanguage.value = i18n.locale.value
}
</script>
