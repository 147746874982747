import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicManageCourseResourceDepartmentsGetRequest,
  AcademicManageCourseResourceDepartmentsGetResponse
} from '@shared/swagger'

export default async function getAcademicManageCourseResourceDepartments (params: AcademicManageCourseResourceDepartmentsGetRequest): Promise<AxiosResponse<AcademicManageCourseResourceDepartmentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCourseResourceDepartmentsGetResponse>({
    url: 'academic/manage/courses/resources/departments',
    body: { params },
    loader: 'academic/manage/courses/resources/departments'
  })

  return response
}
