<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="viewBy === 'grid' ? 'cards' : 'table'"
    :full-width="true"
  />
  <template v-else>
    <GridView
      v-if="viewBy === 'grid'"
      :courses="courses"
      :current-year-term="currentYearTerm"
    />
    <ListView
      v-if="viewBy === 'list'"
      :courses="courses"
      :current-year-term="currentYearTerm"
    />
  </template>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@shared/composable'

import { useStore } from '../store'
import { viewBy } from '../values'

import GridView from './GridView.vue'
import ListView from './ListView.vue'

const store = useStore()
const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf(['academic/courses/my', 'academic/courses/resources'])
// eslint-disable-next-line dot-notation, vue/no-side-effects-in-computed-properties
const courses = computed(() => store.getMyCourses)
const currentYearTerm = computed(() => store.getCurrentYearTerm)
</script>
