import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, UnknownRequest } from '@shared/swagger'

export default async function deleteProgram (announcementId: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownRequest, UnknownResponse>({
    url: `service/manage/announcements/${announcementId}`,
    body: { },
    loader: 'service/manage/announcements/single/delete'
  })

  return response
}
