import { useI18n } from 'vue-i18n'
import { ProfileResourceResponse } from '@/shared/swagger'

type ResourceTypes = ProfileResourceResponse['cities'] | ProfileResourceResponse['countries'] | ProfileResourceResponse['regions']

export default (resource: ResourceTypes, id: number | null) => {
  const { t } = useI18n()

  if (!id) return t('common.no-data')
  const resourceItem = resource.find((item) => item.id === id)
  if (resourceItem) return resourceItem.title
  return t('common.no-data')
}
