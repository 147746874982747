import { createPage } from '@shared-utils/storybook'
import { usePagination } from '@shared/composable'

import SchedulePage from '../../Schedule/SchedulePage.vue'
import { useSingleCourse } from '../../../store'

const Template = createPage({
  layout: 'default',
  component: SchedulePage,
  setup () {
    const store = useSingleCourse()
    const pagination = usePagination()

    store.$reset()
    pagination.$reset()
    store.bootstrap('schedule', { code: 'CSS 358', year: '2022', term: '2' })
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Pages/Single Course/Schedule',
  component: SchedulePage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
