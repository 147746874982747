import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicAdvisoryMyStudentsGetRequest,
  AcademicAdvisoryMyStudentsGetResponse
} from '@shared/swagger'

export default async function getAcademicAdvisoryStudents (params: AcademicAdvisoryMyStudentsGetRequest): Promise<AxiosResponse<AcademicAdvisoryMyStudentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicAdvisoryMyStudentsGetResponse>({
    url: 'academic/students/my',
    body: { params: params },
    loader: 'academic/students/my'
  })

  return response
}
