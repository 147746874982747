import { SmartTableHeader } from '@shared/types'
// todo add translate
export const referenceCoursesTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },

  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    sortable: true
  },

  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  }
]

export const referenceFoundCourseTableHeaders = [
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    sortable: false
  },
  {
    title: 'common.year',
    field: 'year',
    slotCellName: 'year',
    sortable: false
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    sortable: false
  },
  {
    title: 'equicode',
    field: 'year',
    slotCellName: 'year',
    sortable: false
  }
]
