<template>
  <pms-smart-table
    id="found-course-table"
    :items="props.courses"
    :headers="foundCourseTableHeaders"
    :sortable="true"
  >
    <template #department="{item}">
      {{ item.department.title }}
    </template>
    <template #code="{item}">
      {{ item.code }}
    </template>
    <template #year="{item}">
      {{ item.year }}
    </template>
    <template #title="{item}">
      {{ item.title }}
    </template>
    <template #equicode="{item}">
      {{ item.equicode }}
    </template>
    <template #theory="{item}">
      {{ item.theory }}
    </template>
    <template #practice="{item}">
      {{ item.practice }}
    </template>
    <template #credits="{item}">
      {{ item.credits }}
    </template>
    <template #ects="{item}">
      {{ item.ects }}
    </template>
    <template #actions="{ item }">
      <pms-button
        :data-bs-target="'#' + modalInfo.name"
        :data-bs-toggle="'modal'"
        @Click="handleAddCourse(item)"
      >
        Add this course
      </pms-button>
    </template>
  </pms-smart-table>
</template>
<script setup lang="ts">
import { AcademicManageCurriculumCourseSearchResponse, AcademicManageCurriculumCoursesResponse } from '@/shared/swagger'
import { useStore } from '../../../store'
import { foundCourseTableHeaders, modalInfo } from '../values'
import { PropType } from 'vue'

const props = defineProps({
  courses: {
    type: Object as PropType<AcademicManageCurriculumCoursesResponse>,
    required: true
  }
})

const store = useStore()

const handleAddCourse = (course: AcademicManageCurriculumCourseSearchResponse['courses'][0]) => {
  store.setSelectedCourseForAddToTerm({
    courseCode: course.code
  })
}
</script>
