<template>
  <pms-checkbox
    class="d-flex justify-content-center"
    :variant="themeMode == 'dark' ? 'solid' : 'default'"
    :state="grades.length > 0 && selectedStudentIds.length === grades.length"
    :disabled="grades.length === 0"
    @on-check="handleSelect()"
    @on-uncheck="handleUnselect()"
  />
</template>

<script setup lang="ts">
import { PropType } from 'vue'

import { themeMode } from '@shared/composable/pattern'
import { selectedStudentIds } from '../values'
import { AcademicSingleCourseGradeResponse } from '@shared/swagger'

const props = defineProps({
  grades: {
    type: Array as PropType<AcademicSingleCourseGradeResponse>,
    required: true
  }
})

const handleSelect = () => {
  selectedStudentIds.value = props.grades.map((grade) => grade.student_id)
}

const handleUnselect = () => {
  selectedStudentIds.value = []
}
</script>

<style scoped lang="scss">

</style>
