import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageLanguagesStudentsGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesStudents (params: UnknownRequest): Promise<AxiosResponse<ServiceManageLanguagesStudentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageLanguagesStudentsGetResponse>({
    url: 'service/manage/languages/students',
    body: { params: params },
    loader: 'service/manage/languages/students'
  })

  return response
}
