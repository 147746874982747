<template>
  <template v-if="!editor.started">
    <pms-dropdown
      :items="dropItems"
      :menu-item-class="'w-150px'"
    >
      <template #button>
        <input
          :checked="isSessionActive"
          type="checkbox"
          class="form-check-input"
          disabled
        >
      </template>
    </pms-dropdown>
  </template>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useAttendanceEditor } from '../../composable'

const { editor, setMultipleAttendance } = useAttendanceEditor()

const props = defineProps({
  sessionId: {
    type: Number,
    required: true
  }
})

const isSessionActive = computed(() => (Array.isArray(editor.value?.sessions) ? editor.value?.sessions : []).includes(props.sessionId))

const dropItems = computed(() => [
  {
    menuType: 'link',
    label: isSessionActive.value
      ? 'common.de-select'
      : 'common.select',
    action: () => {
      setMultipleAttendance(props.sessionId, isSessionActive.value ? 'remove' : 'add')
    }
  }
])
</script>
