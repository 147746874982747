import type { StoreType } from '../types'

import { useAlert } from '@shared/composable'
import { getReports } from '@shared/api'
import { AcademicReportsGetRequest } from '@/shared/swagger'

export default async function (this: StoreType, params:AcademicReportsGetRequest): Promise<void> {
  const { error } = useAlert()
  const { ok, message, data } = await getReports(params)
  ok
    ? this.setReports(data)
    : error({ text: message })
}
