// eslint-disable-next-line @typescript-eslint/no-explicit-any
const organizedDepartments = (departments: any[]) => {
  return departments.map(child => {
    if (child.children.length > 0 && Array.isArray(child.children)) {
      return {
        id: child.id,
        label: child.title,
        children: organizedDepartments(child.children),
        department: child
      }
    } else {
      return {
        id: child.id,
        label: child.title,
        department: child
      }
    }
  })
}

export default organizedDepartments
