<template>
  <div class="d-flex gap-5 flex-column">
    <pms-grid
      :col="12"
      :order-mb="2"
      class="mt-5 mt-md-0"
    >
      <pms-card
        :header="false"
        :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-4'}}"
      >
        <div class="d-flex flex-column">
          <pms-page-shimmer
            v-if="loader"
            :shimmer="'table'"
            :full-width="true"
          />
          <pms-smart-table
            v-else
            :items="sections"
            :headers="courseHeader"
            :sortable="true"
            :class="'mt-4'"
            :options="{
              wrapper: { responsive: false}
            }"
          >
            <template #no="{ idx }">
              {{ idx + 1 }}
            </template>
            <template #courseCode="{item}">
              {{ item.course.code }}
            </template>
            <template #courseTitle="{item}">
              {{ item.course.title }}
            </template>
            <template #type="{item}">
              {{ item.type.key }} - {{ item.type.title }}
            </template>
            <template #teacher="{item}">
              {{ item.teacher ? item.teacher.full_name : '' }}
            </template>
            <template #requested="{item}">
              {{ item.requested_departments.join(', ') }}
            </template>
            <template #section-credit="{item}">
              {{ `${item.course.theory} - ${item.course.practice} - ${item.course.lab}` }}
            </template>
            <template #hour="{item}">
              {{ item.hours }}
            </template>
            <template #applied="{item}">
              {{ item.schedules.applied }}
            </template>
            <template #action="{ item }">
              <pms-button
                :size="'sm'"
                :variant="'tonal'"
                class="w-100px"
                @click="handleRedirectToCourseDetails(item.id)"
              >
                {{ $t('common.details') }}
                <pms-kt-icon
                  :icon-name="'arrow-right'"
                  class="m-0 p-0 ms-2"
                />
              </pms-button>
            </template>
          </pms-smart-table>
          <pms-pagination
            class="p-0 m-0 mt-6"
          />
        </div>
      </pms-card>
    </pms-grid>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@shared/composable'
import { courseHeader } from '../values'
import { useScheduleManagement } from '../store'
import { useRouter } from 'vue-router'

const router = useRouter()
const { isActive } = useLoader()
const store = useScheduleManagement()

const loader = isActive('academic/manage/schedules/sections')
const sections = computed(() => store.getSections)

const handleRedirectToCourseDetails = async (section: string) => {
  router.push({
    name: 'ScheduleManagementSectionPage',
    params: {
      sectionId: section
    }
  })
}
</script>
