import type { StoreType } from '../types'
import { destroySectionManagementRequestsQuota, destroySectionManagementReservedProgramsQuota } from '@shared/api'
import { StatusMessageResponse } from '@shared/types'
import { useAlert } from '@shared/composable'

export default async function deleteQuota (this: StoreType, requestId: number, quotaId: number, isReserved: boolean): Promise<StatusMessageResponse> {
  const { message, ok } = isReserved ? await destroySectionManagementReservedProgramsQuota(quotaId) : await destroySectionManagementRequestsQuota(requestId, quotaId)
  const { error, success } = useAlert()

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
