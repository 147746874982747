import { SmartTableHeader } from '@/shared/types'

export const electiveHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.course-code',
    field: 'code',
    cellClass: 'w-100px'
  },
  {
    title: 'common.table.course-title',
    field: 'title'
  },
  {
    title: 'common.table.group',
    field: 'group',
    slotCellName: 'group'
  },
  {
    title: 'common.table.terms',
    field: 'term',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    slotCellName: 'practice',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.credits',
    field: 'credits',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    headerClass: 'w-75px',
    cellClass: 'text-center'
  }
]

export const referenceHeader: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code'
  },
  {
    title: 'common.table.title',
    field: 'course_title'
  },
  {
    title: 'common.table.empty',
    field: 'icon',
    slotCellName: 'icon'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.reference',
    field: 'reference',
    slotCellName: 'reference',
    cellClass: 'text-center'
  }
]

export const coreHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'w-50px'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'course_code'
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    slotCellName: 'theory',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    slotCellName: 'practice',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.credits',
    field: 'credits',
    cellClass: 'text-center',
    slotCellName: 'credits'
  },
  {
    title: 'common.table.grades',
    field: 'grades',
    cellClass: 'text-center',
    slotCellName: 'grades'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    headerClass: 'w-75px',
    cellClass: 'text-center',
    slotCellName: 'ects'
  }
]

export const totalHeaders: Array<SmartTableHeader> = [
  {
    title: '',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.theoretical-course',
    field: 'theoreticalCourse',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.non_theoretical-course',
    field: 'nonTheoreticalCourse',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.sum',
    field: 'sum',
    cellClass: 'text-center'
  }
]
