<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead class="">
        <tr>
          <th>{{ $t('common.table.week') }}</th>
          <th>{{ $t('common.table.date') }}</th>
          <th>{{ $t('common.table.hour') }}</th>
          <th>{{ $t('common.table.room') }}</th>
          <th>{{ $t('common.table.block') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in teachingHours"
          :key="item.id"
        >
          <template v-if="isFirstInGroup(index, item.week_number)">
            <td
              :rowspan="getRowspan(item.week_number)"
              class="align-middle"
            >
              {{ item.week_number }}
            </td>
          </template>
          <td>{{ item.start_date }}</td>
          <td>{{ item.start_time }} - {{ item.end_time }}</td>
          <td>{{ item.room.room }}</td>
          <td>{{ item.building.title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { useScheduleManagement } from '@/domains/ScheduleManagement/store'
import { storeToRefs } from 'pinia'

const store = useScheduleManagement()
const { teachingHours } = storeToRefs(store)

// Функция для определения первого элемента в группе
function isFirstInGroup (index: number, week_number: number): boolean {
  return index === 0 || teachingHours.value[index - 1].week_number !== week_number
}

// Функция для подсчета rowspan
function getRowspan (week_number: number): number {
  return teachingHours.value.filter((item) => item.week_number === week_number).length
}
</script>
