import { AcademicSingleCourseGradeStatisticsGetResponse } from '@shared/swagger'
import { GradesDetailsGraph, Series } from '../types'

const formatGradesForGraphs = (data: AcademicSingleCourseGradeStatisticsGetResponse, showInRange = false): GradesDetailsGraph => {
  const series: Series = {
    name: 'Grades',
    data: []
  }

  const letters: Array<string> = []
  const ranges: Array<string> = []

  const defaultLetters = ['A', 'A-', 'B', 'B+', 'B-', 'C', 'C+', 'C-', 'D', 'D+', 'FX', 'F', 'IP', 'NP', 'P']
  const defaultRanges = ['95 - 100', '90 - 94', '80 - 84', '85 - 89', '75 - 79', '65 - 69', '70 - 74', '60 - 64', '50 - 54', '55 - 59', '25 - 49', '0 - 24']

  if (showInRange) {
    defaultRanges.forEach((range, index) => {
      if (defaultRanges.length > index) {
        const item = data.find((item) => item.range === range)
        if (item) {
          letters.push(item.letter)
          series.data.push(item.percent + '')
          ranges.push(item.range)
        } else {
          letters.push(defaultLetters[index])
          series.data.push('0')
          ranges.push(range)
        }
      }
    })
  } else {
    for (const letter of defaultLetters) {
      const item = data.find((item) => item.letter === letter)
      if (item) {
        letters.push(item.letter)
        series.data.push(item.percent + '')
      } else {
        letters.push(letter)
        series.data.push('0')
      }
    }
  }

  return {
    letters,
    ranges,
    series
  }
}

export default formatGradesForGraphs
