// chatGPT aman bolsyn
export function getWeekNumber (date: Date): number {
  // Copy date so don't modify original
  date = new Date(date)
  date.setHours(0, 0, 0, 0)
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setDate(date.getDate() + 4 - (date.getDay() || 7))
  // Get first day of year
  const yearStart = new Date(date.getFullYear(), 0, 1)
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((date.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7)
  // Return week number
  return weekNo
}
