import { DashboardWidgetsResponse } from '@/shared/swagger'
import { dashboardWidgets, widgetsStore } from '../values'
import { loadOrder } from './saveWidgetsOrder'

const sortWidgets = (data: DashboardWidgetsResponse) => {
  widgetsStore.value = []
  dashboardWidgets.value = []
  for (const key in data) {
    if (data[key].is_active === true) {
      dashboardWidgets.value.push({ id: data[key].id })
    } else if (data[key].is_active === false) {
      widgetsStore.value.push({ id: data[key].id })
    }
  }
  loadOrder()
}

export default sortWidgets
