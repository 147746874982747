import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import type { ProfileAddressesPutRequest } from '@/shared/swagger'

import { putProfileAddresses } from '@shared/api'

export default async function editAddress (this:StoreType, address_type: number, data: ProfileAddressesPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileAddresses(address_type, data)

  return {
    status: ok,
    message: message
  }
}
