import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendanceRelocatesPutRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { putAcademicSingleCourseAttendanceRelocate } from '@shared/api'

export default async function (this: StoreType, params: AcademicSingleCourseAttendanceRelocatesPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicSingleCourseAttendanceRelocate(params)

  return {
    status: ok,
    message
  }
}
