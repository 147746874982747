<template>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div class="text-dark fw-bold min-w-75px">
      {{ $t('domains.service.message-service.new-message.students.title') }}:
    </div>
    <div class="d-flex align-items-center pe-2">
      <span
        v-for="(student, index) in students"
        :key="student"
        v-styler="{ borderRadius: '50px', paddingLeft: '14px', paddingRight: '10px', marginLeft: '7px' }"
        class="border border-dashed border-gray-300 py-2 fw-bold d-flex d-flex align-items-center"
      >
        <span class="me-1 fs-5">{{ student }}</span>
        <pms-kt-icon
          class="text-danger fs-2"
          :icon-name="'cross'"
          @click="students.splice(index, 1)"
        />
      </span>
    </div>
    <input
      v-model="newStudent"
      type="text"
      class="form-control form-control-transparent border-0"
      :placeholder="$t('domains.service.message-service.new-message.students.placeholder')"
      @keyup.space="onNewStudent()"
      @change="onNewStudent()"
    >
  </div>
</template>
<script setup lang="ts">
import { PropType, ref, watch } from 'vue'
import { useAlert } from '@shared/composable'

const props = defineProps({
  students: {
    type: Array as PropType<Array<string>>,
    required: true
  }
})

const emit = defineEmits(['update:students'])

const alert = useAlert()

const newStudents = ref<Array<string>>(props.students)
const newStudent = ref<string>('')

const isValidStudent = (student: string) => {
  return student.length === 9 && !isNaN(Number(student)) && !props.students.includes(student)
}

const onNewStudent = () => {
  if (!isValidStudent(newStudent.value)) {
    alert.error({
      text: 'domains.service.message-service.new-message.errors.invalid-student',
      textProperties: { student: newStudent.value }
    })
    return
  }

  newStudents.value.push(newStudent.value)
  newStudent.value = ''
}

watch(
  () => newStudents,
  (newValue) => {
    emit('update:students', newValue)
  }
)
watch(
  () => props.students,
  (newValue) => {
    newStudents.value = newValue
  }
)
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}
</style>
