import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AdministrativeStaffsWorkloadRequest, AdministrativeStaffsWorkloadResponse } from '@shared/swagger'

export default async function getAdministrativeStaffsWorkload (params: AdministrativeStaffsWorkloadRequest): Promise<AxiosResponse<AdministrativeStaffsWorkloadResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeStaffsWorkloadResponse>({
    url: 'administrative/staffs/workload',
    body: { params: params },
    loader: 'administrative/staffs/workload'
  })

  return response
}
