import { SelectField } from '@shared/types'
import { groupByParentChildFields } from '@shared/utils'

import { AcademicResourceResponse } from '@shared/swagger'

export default (data: AcademicResourceResponse['departments']): SelectField['options'] => {
  const options: SelectField['options'] = []
  const dataFormatted = formatter(data)

  dataFormatted.forEach((department) => {
    options.push({
      label: `${department.title} (${department.code})`,
      value: department.code,
      group: [
        ...department.children.map((child) => ({
          label: `${child.title} (${child.code})`,
          value: child.code
        }))
      ]
    })
  })

  return options
}

const formatter = (departments: AcademicResourceResponse['departments']) => {
  const grouped = groupByParentChildFields(departments, 'parent_id', 'id')
  const ids = grouped.map(el => el.id)
  const data = grouped.filter((group) => {
    return !ids.includes(group.parent_id)
  })

  return data
}
