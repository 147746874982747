import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumCoursesRequest, AcademicManageCurriculumCoursesResponse } from '@shared/swagger'

export default async function getCoursesOfferedOtherDepartment (params: AcademicManageCurriculumCoursesRequest): Promise<AxiosResponse<AcademicManageCurriculumCoursesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumCoursesResponse>({
    url: 'academic/manage/curriculum/courses',
    body: { params },
    loader: 'academic/manage/curriculum/courses/search'
  })

  return response
}
