import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationBooksPostRequest } from '@shared/swagger'
import { postProfileEducationBooks } from '@shared/api'

import type { StoreType } from '../types'

export default async function addBooks (this: StoreType, values: ProfileEducationBooksPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducationBooks(values)

  if (ok) await this.fetchBooks()

  return {
    status: ok,
    message
  }
}
