import type { StatusMessageResponse } from '@shared/types'
import type { ScheduleManagement1Section1ProgramPutRequest } from '@shared/swagger'
import { putScheduleManagement1Section1Program } from '@shared/api'

import type { StoreType } from '../types'

export default async function editProgram (this:StoreType, section: string, program: string, values: ScheduleManagement1Section1ProgramPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putScheduleManagement1Section1Program(section, program, values)

  if (ok) await this.fetchSingleSection1Program(section, program)

  return {
    status: ok,
    message: message
  }
}
