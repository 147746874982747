import type { StoreType } from '../types'
import type { AcademicManageCurriculumElectiveGetRequest } from '@/shared/swagger'

import api from '@/shared/api/AcademicCurricula/fetchCurriculumElectiveSlot'

export default async function fetchCurriculumElectiveSlot (this: StoreType, params: AcademicManageCurriculumElectiveGetRequest): Promise<void> {
  const { data, ok } = await api(params)

  if (ok) {
    this.setCurriculumElectiveSlot(data)
  }
}
