<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        :no-mobile="true"
        @launch="launch"
      >
        <template #toolbar>
          <div class="d-flex gap-3 justify-content-between mb-2 mt-md-0">
            <pms-driver-button />
            <pms-feedback-modal :options="[]" />
          </div>
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <pms-row v-if="loader">
      <pms-page-shimmer
        :shimmer="'table'"
        :filter="true"
      />
    </pms-row>
    <pms-column v-else>
      <pms-grid
        :col="12"
      >
        <gate-entry-search />
      </pms-grid>
      <pms-grid
        class="mt-4"
        :col="12"
        :order-mb="2"
      >
        <gate-entry-list />
      </pms-grid>
    </pms-column>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useDriver, useLoader, useTour } from '@shared/composable'
import { header, studentQuickTourSteps, employeeQuickTourSteps, tour, view } from './values'
import GateEntryList from './ui/GateEntryList.vue'
import GateEntrySearch from './ui/GateEntrySearch.vue'

const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()
const { isActive } = useLoader()

const loader = isActive('administrative/resources')

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await employeeQuickTourSteps())
})

watch(() => view.value, async (newVal) => {
  if (newVal === 'student') {
    setQuickTourSteps(await studentQuickTourSteps())
  } else {
    setQuickTourSteps(await employeeQuickTourSteps())
  }
})
</script>
