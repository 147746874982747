import { defineComponent, onMounted } from 'vue'
import { useBodyStore } from '@shared-stores/pattern'

export const SystemLayout = defineComponent({
  name: 'SystemLayout',
  components: {},
  setup () {
    const store = useBodyStore()

    onMounted(() => {
      store.addBodyClassName('app-blank')
      store.addBodyClassName('bgi-size-cover')
      store.addBodyClassName('bgi-position-center')
      store.addBodyClassName('bgi-no-repeat')
    })

    return () => (
      <div class="d-flex flex-column flex-root" id="kt_app_root">
        <router-view />
      </div>
    )
  }
})

export default SystemLayout
