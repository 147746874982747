import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicManageCoursesPutRequest, UnknownResponse } from '@shared/swagger'

export default async function (body: AcademicManageCoursesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageCoursesPutRequest, UnknownResponse>({
    url: 'academic/manage/courses',
    body,
    loader: 'academic/manage/courses'
  })

  return response
}
