
/* eslint-disable @typescript-eslint/no-var-requires */
import { PathFilePaired } from '@avocado/types'

export const loadRouteFiles = (): PathFilePaired[] => {
  const files: PathFilePaired[] = []

  require.context(
    '@/domains/',
    true,
    /^((?![\\/][\\/]).)*\.route.ts$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/domains/' + filename.substring(2)).default }))

  require.context(
    '@/domains/',
    true,
    /^((?![\\/][\\/]).)*\/routing.ts$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/domains/' + filename.substring(2)).default }))

  return files
}
