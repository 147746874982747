import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileCertificatesPostRequest } from '@shared/swagger'

export default async function putProfileCertificates (certificate_id: number, values: ProfileCertificatesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileCertificatesPostRequest, UnknownResponse>({
    url: `profile/certificates/${certificate_id}`,
    body: values,
    loader: 'profile/certificates-edit'
  })

  return response
}
