import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { RulesAndRegulationsAttachmentsPutResponse } from '@shared/swagger'

export default async function putRulesAndRegulationsAttachment (rule: string, attachment: string, body: FormData): Promise<AxiosResponse<RulesAndRegulationsAttachmentsPutResponse>> {
  const { post } = useRequests()

  const response = await post<FormData, RulesAndRegulationsAttachmentsPutResponse>({
    url: `academic/information/rules/${rule}/attachments/${attachment}`,
    body,
    loader: 'academic/information/rules/single/attachments/single/edit-contents',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}
