<template>
  <pms-card
    :title="`${t('domains.academic.manage.curriculum.course-information-title')}`"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <div
      v-else
      class="mb-5"
    >
      <h1>{{ `${t('common.department')}: ` + curriculumCore?.track?.program?.department?.title }}</h1>
      <h2>{{ `${t('common.program')}: ` + curriculumCore?.track?.program?.code }}</h2>
      <h3>{{ `${t('common.track')}: ` + curriculumCore?.track?.title }}</h3>
      <p>{{ `${t('common.course-code')}: ${curriculumCore?.code}` }}</p>
      <p>{{ `${t('common.title')}: ${curriculumCore?.title}` }}</p>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'

const { isActive } = useLoader()
const { t } = useI18n()

const loader = isActive('api-fetchCurriculumCore')

const store = useStore()
const { curriculumCore } = storeToRefs(store)
</script>
