import { useAcademicReports } from '@/domains/AcademicReports/store'
import { useAlert } from '@/shared/composable'
import { computed, ref } from 'vue'
const fields = ref([] as string[])
const parameters = ref([] as
  {
    id:number,
    values:{
      id:string
    }[]
  }[]
)

export function useViewPageComposable () {
  const store = useAcademicReports()
  const { error } = useAlert()

  const all_fields = computed(() => store.single_report.default_fields.concat(store.single_report.additional_fields))
  const includesAllFields = computed(() => all_fields.value.every(field => fields.value.includes(field)))

  // fields
  function toggleAllDefaultsOn () {
    fields.value = []
    console.log(store.single_report.default_fields)
    store.single_report.default_fields.forEach(field => {
      if (!fields.value.includes(field)) {
        fields.value.push(field)
      }
    })
  }

  function toggleAllFieldsOn () {
    all_fields.value.forEach(field => {
      if (!fields.value.includes(field)) {
        fields.value.push(field)
      }
    })
  }
  function toggleAllFieldsOff () {
    fields.value = []
  }

  function toggleFieldOn (field:string) {
    fields.value.push(field)
  }
  function toggleFieldOff (field:string) {
    fields.value.splice(fields.value.indexOf(field), 1)
  }

  // params
  function toggleAllParamsOn (param_id:number) {
    const server_param_found = store.single_report.parameters.find(param => param.id === param_id)
    const local_param_found = parameters.value.find(param => param.id === param_id)
    if (!local_param_found) {
      parameters.value.push({
        id: param_id,
        values: server_param_found?.values ?? []
      })
    } else {
      if (server_param_found) {
        server_param_found.values.forEach(value => {
          if (!local_param_found.values.find(v => v.id === value.id)) {
            local_param_found.values.push({
              id: value.id
            })
          }
        })
      }
    }
  }
  function toggleAllParamsOff (param_id:number) {
    parameters.value.splice(parameters.value.findIndex(param => param.id === param_id), 1)
  }
  function toggleParamFieldOn (param_id:number, field_id:string) {
    const param_found = parameters.value.find(param => param.id === param_id)
    if (param_found) {
      param_found.values.push({ id: field_id })
    } else {
      parameters.value.push({ id: param_id, values: [{ id: field_id }] })
    }
  }
  function toggleParamFieldOff (param_id:number, field_id:string) {
    const param_found = parameters.value.find(param => param.id === param_id)
    if (param_found && param_found.values.length > 0) {
      param_found.values.splice(param_found.values.findIndex(field => field.id === field_id), 1)
    }
    if (param_found && param_found.values.length === 0) {
      parameters.value.splice(parameters.value.findIndex(param => param.id === param_id), 1)
    }
  }
  function selectParam (event:Event, param_id:number) {
    const value = (event.target as HTMLSelectElement).value
    const param_found = parameters.value.find(param => param.id === param_id)
    if (param_found) {
      parameters.value.splice(parameters.value.findIndex(param => param.id === param_id), 1)
    }
    toggleParamFieldOn(param_id, value)
  }

  // main view page
  async function execute (report_id:string) {
    const { status, message } = await store.execute({
      report: report_id,
      fields: fields.value,
      parameters: parameters.value
    })
    if (!status) error({ text: message })
  }
  function exportToPDF (report_id:string) {
    store.export({
      report: report_id,
      fields: fields.value,
      parameters: parameters.value
    })
  }
  async function fetchSingleReport (report_id:string) {
    await store.fetchSingleReport({
      report: report_id
    })
    fields.value = JSON.parse(JSON.stringify(store.single_report.default_fields))
  }

  return {
    fields,
    all_fields,
    includesAllFields,
    toggleAllDefaultsOn,
    toggleAllFieldsOn,
    toggleAllFieldsOff,

    toggleFieldOn,
    toggleFieldOff,

    parameters,
    toggleAllParamsOn,
    toggleAllParamsOff,
    toggleParamFieldOn,
    toggleParamFieldOff,
    selectParam,
    fetchSingleReport,
    execute,
    exportToPDF
  }
}
