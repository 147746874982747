import type { StatusMessageResponse } from '@shared/types'
import type { ProfileChildrenPutRequest } from '@shared/swagger'
import { putProfileChildren } from '@shared/api'

import type { StoreType } from '../types'

export default async function editChildren (this:StoreType, childId: number, values: ProfileChildrenPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileChildren(childId, values)

  return {
    status: ok,
    message: message
  }
}
