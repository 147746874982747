import type { StoreType } from '../types'
import { useResource } from '@shared/stores'
import { usePagination } from '@shared/composable'

export default async function bootstrap (this: StoreType, page: string, uuid?: string, search?: string | undefined, category?: string | undefined): Promise<void> {
  const { perPage } = usePagination()
  const resource = useResource()

  switch (page) {
    case 'listing':
      await Promise.all([
        this.fetchPosts({ search, category, per_page: perPage.value, offset: 1 }),
        resource.fetchKnowledgeManageResource({ modules: ['types', 'categories'] })
      ])
      break
    case 'single':
      await Promise.all([
        this.fetchPost(uuid ?? ''),
        resource.fetchKnowledgeManageResource({ modules: ['types'] })
      ])
      break
    case 'new':
      await Promise.all([
        this.setStepper(1),
        resource.fetchKnowledgeManageResource({ modules: ['types', 'tags', 'languages', 'categories'] })
      ])
      break
    case 'edit':
      await Promise.all([
        this.setStepper(1),
        this.fetchPost(uuid ?? ''),
        resource.fetchKnowledgeManageResource({ modules: ['types', 'tags', 'languages', 'categories'] })
      ])
      break
  }
}
