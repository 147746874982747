<template>
  <div
    class="d-flex align-items-center"
  >
    <div
      class="symbol symbol-40px me-3"
      @mouseenter="onImageMouseEnter($event, student)"
      @mouseleave="onImageMouseLeave()"
    >
      <img
        :src="student.photo ? `${student.photo}` : '/user.png'"
        class="rounded"
        :alt="`${student.surname} ${student.name}`"
        style="object-fit: cover; cursor: pointer;"
      >
    </div>
    <div
      class="d-flex justify-content-start flex-column"
    >
      <p class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
        {{ student.name }} {{ student.surname }}
      </p>
      <span class="text-gray-400 fw-semibold d-block fs-7">
        {{ student.id }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AcademicAdvisoryMyStudentsGetResponse } from '@shared/swagger'

defineProps<{ student: AcademicAdvisoryMyStudentsGetResponse['students'][0] }>()

const onImageMouseEnter = (event, student) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const image = document.querySelector('.student-image') as any

  if (tooltip && image) {
    image.src = student.photo ? `${student.photo}` : '/user.png'
    tooltip.style.display = 'block'
    tooltip.style.top = `${event.pageY}px`
    tooltip.style.left = `${event.pageX}px`
  }
}

const onImageMouseLeave = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any
  if (tooltip) tooltip.style.display = 'none'
}
</script>
