import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useAlert, usePagination } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useSingleCourse } from './store'
import { RouteParams } from './types'
import { sensitivePages } from './values'
const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSingleCourse()
  const pagination = usePagination()
  const params = to.query as unknown as RouteParams

  store.$reset()
  pagination.$reset()

  if (params.year && params.term && params.code) {
    await store.bootstrap(params)
    next()
  } else {
    next({ name: 'AcademicCoursesMyCourses' })
  }
}

const handlePageRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const params = to.query as unknown as RouteParams
  const store = useSingleCourse()
  const resource = useResource()

  const { error } = useAlert()
  const { page } = to.meta as { page: string }

  if (params.year && params.term && params.code) {
    const theCourse = resource.getAcademicSingleCoursesResource

    if (theCourse && !theCourse.enrolled && sensitivePages.includes(page)) {
      error({ title: 'common.course-error', text: 'common.course-not-enrolled-error-message' })
      next({ name: 'AcademicCoursesMyCourses', query: { ...params } })
      return
    }

    store.bootstrapByPage(page, params)
    next()
  } else {
    error({ title: 'common.course-error', text: 'common.course-general-params-error-message' })
    next({ name: 'AcademicCoursesMyCourses' })
  }
}

export default {
  layout: 'default',
  path: '/academic/course',
  children: [
    {
      path: '',
      name: 'AcademicSingleCoursePage',
      component: () => import('./SingleCourse.vue'),
      beforeEnter: [
        handleRoute
      ],
      children: [
        {
          path: '',
          name: 'SingleCourseOverviewPage',
          component: () => import('./features/OverviewPage/OverviewPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'overview'
          }
        },
        {
          path: 'syllabus',
          name: 'SingleCourseSyllabusPage',
          component: () => import('@domains/AcademicSingleCourse/features/SyllabusPage/SyllabusPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'syllabus'
          }
        },
        {
          path: 'history',
          name: 'SingleCourseHistoryPage',
          component: () => import('@domains/AcademicSingleCourse/features/HistoryPage/HistoryPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'history'
          }
        },
        {
          path: 'attendance',
          name: 'SingleCourseAttendancePage',
          component: () => import('./features/Attendance/AttendancePage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'attendance'
          }
        },
        {
          path: 'attendance/qr',
          name: 'SingleCourseAttendanceQRPage',
          component: () => import('./features/AttendanceQR/AttendanceQR.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'attendanceQR'
          }
        },
        {
          path: 'schedule',
          name: 'SingleCourseSchedulePage',
          component: () => import('./features/Schedule/SchedulePage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'schedule'
          }
        },
        {
          path: 'exam-dates',
          name: 'SingleCourseExamDatesPage',
          component: () => import('./features/ExamDates/ExamDatesPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'exam-dates'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
