<template>
  <div class="d-flex justify-content-end mb-4">
    <pms-button
      :text="$t('knowledge.manage.post.new-content')"
      size="sm"
      @click="handleEditor(-1)"
    />
  </div>
  <pms-loader v-if="loader" />
  <pms-smart-table
    v-else
    :items="postContents"
    :headers="tableContentHeader"
    :sortable="true"
  >
    <template #no="{ idx }">
      {{ idx + 1 }}
    </template>
    <template #active="{ item }">
      {{ item.active }}
    </template>
    <template #type="{ item }">
      {{ getTypeById(item.type_id,types) }}
    </template>
    <template #action="{ idx }">
      <div class="d-flex gap-3 justify-content-center flex-wrap">
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-icon btn-light-primary"
          @click="handleEditor(idx)"
        >
          <pms-kt-icon
            icon-name="click"
            class="fs-3 m-0 p-0"
          />
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-icon btn-light-danger"
          @click="deleteContent(idx)"
        >
          <pms-kt-icon
            icon-name="trash"
            class="fs-3 m-0 p-0"
          />
        </a>
      </div>
    </template>
  </pms-smart-table>
  <div class="form-group mt-3 col-12 d-flex justify-content-end">
    <pms-button
      :disabled="store.isLoading || postContents.length === 0"
      size="lg"
      :text="$t('common.submit')"
      @click="onSubmit"
    />
  </div>
  <post-content-form v-if="modifier && modifier.name === 'post-content'" />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import type { KnowledgeManagePostsContentsPutRequest } from '@/shared/swagger'

import { useKnowledgeManagePost } from '../../../store'
import { tableContentHeader } from '../values'
import { getTypeById } from '../utils'

import PostContentForm from './PostContentForm.vue'

const { t } = useI18n()
const router = useRouter()
const { isActive } = useLoader()
const { dialog, error, success } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useKnowledgeManagePost()

const loader = isActive('knowledge/manage/posts/single')

const types = computed(() => resource.getKnowledgeManageResourceTypes)
const postContents = computed(() => store.postContents)

function deleteContent (tableIndex: number) {
  dialog(
    {
      text: 'knowledge.manage.post.delete-content.text',
      onConfirm: async () => {
        if (store.postContents.length === 1) {
          error({ text: t('common.fields.required.last-content.error') })
          return
        }
        const contentUuid = store.postContents[tableIndex].uuid
        if (contentUuid) {
          store.postContents.splice(tableIndex, 1)
        }

        const { status, message } = await store.deleteContent(store.postUuid, { uuid: [contentUuid] })

        if (!status) {
          error({ text: message })
        }
      }
    }
  )
}

const handleEditor = async (tabIndex: number) => {
  setModifier('post-content',
    { id: tabIndex, uuid: store.postUuid },
    { title: tabIndex === -1 ? 'post-content-new-title' : 'post-content-edit-title', description: tabIndex === -1 ? 'post-content-new-description' : 'post-content-edit-description' }
  )
  open({ name: 'post-content-modal' })
}

const onSubmit = async () => {
  const requestBody: KnowledgeManagePostsContentsPutRequest = []
  store.postContents.forEach((el) => {
    const { type_id, visible, ...request } = el
    requestBody.push({ ...request, type_id: String(type_id), visible: !!visible })
  })
  const { status, message } = await store.editContent(store.postUuid, requestBody)
  if (status) {
    success({ text: message, isToast: true })
    router.push({
      path: '/knowledge-base/manage/articles'
    })
  } else {
    error({ text: message })
  }
}

</script>
