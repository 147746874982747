import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'
import { useConsentRequest } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useConsentRequest()
  pagination.$reset()
  store.$reset()

  await store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/academic/courses/consent-requests',
  children: [
    {
      path: '',
      name: 'ConsentRequestPage',
      component: () => import('./ConsentRequestPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'request',
      name: 'SingleRequestPage',
      component: () => import('./ui/SingleRequestPage.vue'),
      meta: {
        page: 'request'
      }
    }
  ]
} as RouteRecordWithLayout
