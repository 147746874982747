<template>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div class="text-dark fw-bold min-w-125px">
      {{ $t('domains.academic.manage.section.sent-requests.student-reservation-title') }}:
    </div>
    <input
      v-model="newStudent"
      type="text"
      class="form-control form-control-transparent border-0"
      :placeholder="$t('domains.service.message-service.new-message.students.placeholder')"
      @keyup.space="onNewStudent()"
      @change="onNewStudent()"
    >
  </div>
  <pms-card
    :header="false"
    class="mt-4"
    :options="{
      body: { class: 'pb-3 pt-0' }
    }"
  >
    <div class="d-flex flex-wrap">
      <span
        v-for="(obj, index) in newStudents"
        :key="obj.student"
        v-styler="{ borderRadius: '50px', paddingLeft: '14px', paddingRight: '10px', marginLeft: '7px' }"
        class="border border-dashed border-gray-300 py-2 mb-2 fw-bold d-flex d-flex align-items-center"
      >
        <span class="me-1 fs-5">{{ obj.student }}</span>
        <pms-kt-icon
          class="text-danger fs-2"
          :icon-name="'cross'"
          @click="deleteReservedStudent(index)"
        />
      </span>
    </div>
    <div class="form-group mt-3 col-12 d-flex justify-content-start gap-2">
      <pms-button
        type="submit"
        class="me-5"
        :text="$t('common.save')"
        :disabled="newStudents.length === 0"
        @click="onSubmit"
      />
      <pms-button
        variant="link"
        color="danger"
        type="reset"
        @click="store.quota_id = -1"
      >
        {{ $t('common.cancel') }}
      </pms-button>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useAlert } from '@shared/composable'

import { useSentRequests } from '../store'
import { newStudents, newStudent } from '../values'

const { error, success } = useAlert()

const props = defineProps({
  requestId: {
    type: Number,
    required: true
  }
})

const store = useSentRequests()

const onNewStudent = () => {
  if (!isValidStudent(newStudent.value)) {
    error({
      text: 'domains.service.message-service.new-message.errors.invalid-student',
      textProperties: { student: newStudent.value }
    })
    return
  }

  newStudents.value.push({ id: -1, student: newStudent.value })
  newStudent.value = ''
}
const isValidStudent = (student: string) => {
  return student.length === 9 && !isNaN(Number(student)) && !newStudents.value.find(el => el.student === student)
}

const onSubmit = async () => {
  const { status, message } = await store.postSentRequestReserves(props.requestId, store.quota_id, { students: newStudents.value.map(el => el.student) })
  if (status) {
    store.fetchSentRequestQuotas(props.requestId)
    store.quota_id = -1
    success({
      text: message
    })
  } else {
    error({ text: message })
  }
}
const deleteReservedStudent = async (index: number) => {
  if (newStudents.value[index].id !== -1) {
    const { status, message } = await store.deleteSentRequestReserve(store.request_id, store.quota_id, newStudents.value[index].id)
    if (status) {
      success({
        text: message
      })
    } else {
      error({ text: message })
    }
  }
  newStudents.value.splice(index, 1)
  store.fetchSentRequestQuotas(store.request_id)
}
</script>
