import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationOrganizationsPostRequest } from '@shared/swagger'
import { postProfileEducationOrganizations } from '@shared/api'

import type { StoreType } from '../types'

export default async function addOrganization (this: StoreType, values: ProfileEducationOrganizationsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducationOrganizations(values)

  if (ok) await this.fetchOrganizations()

  return {
    status: ok,
    message
  }
}
