import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentAttendanceGetRequest, AcademicSingleStudentAccountingDetailsGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentAccountingDetails (id: string, params: AcademicSingleStudentAttendanceGetRequest): Promise<AxiosResponse<AcademicSingleStudentAccountingDetailsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentAccountingDetailsGetResponse>({
    url: `academic/students/${id}/accounting/details`,
    body: { params: params },
    loader: 'academic/students/accounting/details'
  })

  return response
}
