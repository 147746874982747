import type { StoreType } from '../types'
import type { SectionManagementRequestCourseGetResponse } from '@shared/swagger'

export default function getMappedProgramCourses (this: StoreType) : SectionManagementRequestCourseGetResponse[] {
  if (!this.requestCourses) return []
  const trackMap = this.requestCourses.reduce((acc, course) => {
    const trackId = course.track?.id

    if (!acc.has(trackId)) {
      acc.set(trackId, [])
    }

    acc.get(trackId).push(course)

    return acc
  }, new Map())

  return Array.from(trackMap.values())
}
