import type { StoreType } from '../types'
import type { ServiceMessageBroadcastEmployeeGetRequest } from '@shared/swagger'
import getServiceMessageBroadcastEmployee from '@/shared/api/ServiceMessageBroadcast/getServiceMessageBroadcastEmployee'
import { StatusMessageResponse } from '@/shared/types'

export default async function getEmployees (this: StoreType, body: ServiceMessageBroadcastEmployeeGetRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceMessageBroadcastEmployee(body)

  if (ok) {
    this.employee_by_department = data
  }

  return {
    status: ok,
    message
  }
}
