import type { StatusMessageResponse } from '@shared/types'
import type { ProfileEducationSupervisionsPostRequest } from '@shared/swagger'
import { putProfileEducationSupervisions } from '@shared/api'

import type { StoreType } from '../types'

export default async function editSupervision (this:StoreType, id: number, values: ProfileEducationSupervisionsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileEducationSupervisions(id, values)

  return {
    status: ok,
    message: message
  }
}
