import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileCertificatesPostRequest } from '@shared/swagger'
import { postProfileCertificates } from '@shared/api'

import type { StoreType } from '../types'

export default async function addCertificate (this: StoreType, values: ProfileCertificatesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileCertificates(values)

  if (ok) await this.fetchCertificates()

  return {
    status: ok,
    message
  }
}
