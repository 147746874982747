<template>
  <pms-card
    :title="'domains.academic.syllabuses.table.title'"
    :description="'domains.academic.syllabuses.table.description'"
    :options="{ body: { class: 'pt-0' }}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      :items="courses"
      :headers="tableHeaders"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #courseTitle="{ item }">
        <span class="fw-bold">
          {{ item.code }}. {{ item.titles[titleSelector] || item.title }}
        </span>
      </template>
      <template #courseTitleHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap">
          {{ $t(item.title) }}
          <SyllabusesTableTitleSelector
            v-model="titleSelector"
            class="ms-2"
          />
        </div>
      </template>
      <template #courseStatus="{ item }">
        {{ statusesObject[item.status] }}
      </template>
      <template #action="{ item }">
        <router-link
          class="btn btn-link text-primary"
          :to="`/academic/course/syllabus?year=${item.year}&term=${item.term}&code=${item.code}&previous_page=SyllabusesPage`"
        >
          {{ $t('common.view') }}
        </router-link>
      </template>
      <template #status_for_english="{item}">
        <span :class="statusesObject[item.status.status_for_english].color">
          {{ statusesObject[item.status.status_for_english].title }}
        </span>
      </template>
      <template #status_for_russian="{item}">
        <span :class="statusesObject[item.status.status_for_russian].color">
          {{ statusesObject[item.status.status_for_russian].title }}
        </span>
      </template>
      <template #status_for_kazakh="{item}">
        <span :class="statusesObject[item.status.status_for_kazakh].color">
          {{ statusesObject[item.status.status_for_kazakh].title }}
        </span>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useLoader } from '@/shared/composable'

import { useSyllabuses } from './../store'
import { tableHeaders } from './../values'

import SyllabusesTableTitleSelector from './SyllabusesTableTitleSelector.vue'
import { useResource } from '@/shared/stores'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()

const store = useSyllabuses()
const syllabusesStore = storeToRefs(store)
const loader = isActive('academic/information/syllabuses')
const titleSelector = ref(0)
const resource = useResource()

const colors = ['text-gray-500', 'text-warning', 'text-success', 'text-danger', 'text-primary']

const statusesObject = computed(() => {
  if (!resource.academicInformationResource) return {}

  const result = resource.academicInformationResource.application_statuses.reduce((acc, { id, title }) => {
    acc[id] = {
      title: title,
      color: colors[id]
    }
    return acc
  }, {} as { [key: number]: {title:string, color:string} })

  return result
})

const courses = computed(() =>
  syllabusesStore.syllabuses.value
    ? syllabusesStore.syllabuses.value.map((syllabus) => ({ ...syllabus.course, ...syllabus }))
    : []
)

</script>
