import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationArticlesPostRequest } from '@shared/swagger'
import { postProfileEducationArticles } from '@shared/api'

import type { StoreType } from '../types'

export default async function addArticle (this: StoreType, values: ProfileEducationArticlesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducationArticles(values)

  if (ok) await this.fetchArticles()

  return {
    status: ok,
    message
  }
}
