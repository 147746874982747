import { getProfileEducationBooks } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchBooks (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileEducationBooks()

  if (ok) {
    this.setBooks(data)
  }
}
