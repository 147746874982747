import { AcademicSingleCourseGradeResponse } from '@/shared/swagger'
import { AddedGrades } from '../types'

const getStudentsGrade = (grades: AcademicSingleCourseGradeResponse, assessment_id: number): AddedGrades => {
  const assessmentGrades = grades.map((grade) => {
    return {
      student_id: grade.student_id,
      grade: grade.grades.find((g) => g.assessment_id === assessment_id)?.grade as number
    }
  })

  return {
    assessment_id,
    grades: assessmentGrades
  }
}

export default getStudentsGrade
