import { useRequests } from '@shared/composable'
import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumCorePutBulkRequest } from '@shared/swagger'

export default async function editCoreCourseBulk (body: AcademicManageCurriculumCorePutBulkRequest): Promise<StatusMessageResponse> {
  const { put } = useRequests()

  const { ok, message } = await put({
    url: '/academic/manage/curriculum/core/bulk',
    body: body,
    loader: 'put-academic/manage/curriculum/core/bulk'
  })

  return {
    status: ok,
    message
  }
}
