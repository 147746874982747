import { SmartTableHeader, ScheduleWeekDay, ScheduleHour } from '@/shared/types'

export const courseHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'courseCode',
    slotCellName: 'courseCode'
  },
  {
    title: 'common.table.course-title',
    field: 'courseTitle',
    slotCellName: 'courseTitle'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'

  },
  {
    title: 'common.table.section',
    field: 'section'
  },
  {
    title: 'common.table.teacher',
    field: 'teacher',
    slotCellName: 'teacher'
  },
  {
    title: 'common.table.requested',
    field: 'requested',
    slotCellName: 'requested'
  },
  {
    title: 'common.table.section-credit',
    field: 'section-credit',
    slotCellName: 'section-credit'
  },
  {
    title: 'common.table.hour',
    field: 'hour',
    slotCellName: 'hour'
  },
  {
    title: 'common.common.table.applied',
    field: 'applied',
    slotCellName: 'applied'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]
export const singleSectionHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.main',
    field: 'is_main',
    slotCellName: 'is_main'
  },
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.common.table.date-range',
    field: 'dateRange',
    slotCellName: 'dateRange'
  },
  {
    title: 'common.common.table.rooms',
    field: 'rooms',
    slotCellName: 'rooms'
  },
  {
    title: 'common.table.hour',
    field: 'hour',
    slotCellName: 'hour'
  },
  {
    title: 'common.common.table.applied',
    field: 'applied',
    slotCellName: 'applied'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const scheduleWeekDays: Array<ScheduleWeekDay> = [
  {
    id: 1,
    title: 'common.week-days.monday',
    abbreviation: 'domains.academic.schedule.table.week-days.monday.abbreviation'
  },
  {
    id: 2,
    title: 'common.week-days.tuesday',
    abbreviation: 'domains.academic.schedule.table.week-days.tuesday.abbreviation'
  },
  {
    id: 3,
    title: 'common.week-days.wednesday',
    abbreviation: 'domains.academic.schedule.table.week-days.wednesday.abbreviation'
  },
  {
    id: 4,
    title: 'common.week-days.thursday',
    abbreviation: 'domains.academic.schedule.table.week-days.thursday.abbreviation'
  },
  {
    id: 5,
    title: 'common.week-days.friday',
    abbreviation: 'domains.academic.schedule.table.week-days.friday.abbreviation'
  },
  {
    id: 6,
    title: 'common.week-days.saturday',
    abbreviation: 'domains.academic.schedule.table.week-days.saturday.abbreviation'
  }
]
