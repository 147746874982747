import { FormField } from '@/shared/types'
import { computed, ref, watch } from 'vue'
import { SelectTypes } from '../values'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { TCourseTypesValue } from '../types'
import { useResource } from '@/shared/stores'

export const useCourseAdder = () => {
  const store = useStore()
  const { track } = storeToRefs(store)
  const route = useRoute()
  const resource = useResource()

  const query = route.query

  const terms = ref<number[]>([])

  type Option = {
    label: string,
    value: string
  }

  const electiveTypesOptions = ref<Option[]>([])
  const coreTypesOptions = ref<Option[]>([])
  watch(() => resource.academicManageCurriculumResource, () => {
    if (resource.academicManageCurriculumResource) {
      electiveTypesOptions.value = resource.academicManageCurriculumResource.elective_names.map((electiveName) => {
        return {
          label: electiveName.title,
          value: electiveName.code
        }
      })

      coreTypesOptions.value = resource.academicManageCurriculumResource.core_types.map((coreType) => {
        return {
          label: coreType.title,
          value: coreType.key
        }
      })
    }
  })

  const courseSettings = ref<{
    term: null | number,
    coreType: TCourseTypesValue | null,
    electiveType: null | string,
    courseGroup: null | string
  }>({
    term: null,
    coreType: null,
    electiveType: null,
    courseGroup: null
  })

  watch(() => track.value, (newValue) => {
    if (newValue) {
      terms.value = Array.from({ length: newValue.terms }, (_, i) => i + 1)
      const termNumber = query.term && typeof query.term === 'string' ? +query.term : undefined
      if (termNumber) {
        courseSettings.value.term = termNumber
      }
    }
  }, { immediate: true })

  const courseSettingsFormFields = computed<Array<FormField>>(() => [
    {
      label: {
        // text: `${t('common.search-field.select-speciality')}`,
        text: 'Select Term',
        class: 'mb-1'
      },
      name: 'term',
      type: 'select',
      value: courseSettings.value.term || null,
      options: terms.value.map((term) => {
        return {
          label: term.toString(),
          value: term
        }
      })
    },
    {
      label: {
        text: 'Select Course Type',
        class: 'mb-1'
      },
      name: 'coreType',
      type: 'select',
      value: courseSettings.value.coreType || null,
      options: coreTypesOptions.value
    },
    {
      label: {
        text: courseSettings.value.coreType === 'E' ? 'Elective Type' : '',
        class: 'mb-1'
      },
      name: 'electiveType',
      type: 'select',
      class: courseSettings.value.coreType === 'E' ? 'd-block' : 'd-none',
      value: courseSettings.value.coreType || null,
      options: electiveTypesOptions.value
    }
  ])

  const courseGroupFormFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: 'Courses from',
        class: 'mb-1'
      },
      name: 'courseGroup',
      type: 'select',
      value: courseSettings.value.courseGroup || null,
      options: [
        {
          label: 'Courses in the System',
          value: SelectTypes.CoursesFromSearch
        },
        {
          label: 'Service courses offered from other Departments',
          value: SelectTypes.CoursesOffered
        }
      ]
    }
  ])

  return {
    courseSettingsFormFields,
    courseGroupFormFields,
    courseSettings,
    terms
  }
}
