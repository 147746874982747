<template>
  <div class="d-flex flex-row">
    <div class="d-flex flex-row align-items-center">
      <span class="me-2 fw-bold">
        {{ $t('common.search') }}:
      </span>
      <input
        v-model="search"
        type="text"
        class="form-control form-control-sm w-150px"
      >
    </div>
    <div class="d-flex flex-row align-items-center ms-3">
      <span class="me-2 fw-bold">
        {{ $t('common.filter') }}:
      </span>
      <select
        v-model="filter"
        class="form-select form-select-sm w-100px"
      >
        <option value="all">
          {{ $t('common.all') }}
        </option>
        <option value="present">
          {{ $t('common.present') }}
        </option>
        <option value="absent">
          {{ $t('common.absent') }}
        </option>
      </select>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useQr } from '../../../composable'
const { filter, search } = useQr()
</script>
