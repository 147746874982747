import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicCoursesOverviewRequest,
  AcademicCoursesOverviewResponse
} from '@shared/swagger'

export default async function getAcademicCoursesOverview (params: AcademicCoursesOverviewRequest): Promise<AxiosResponse<AcademicCoursesOverviewResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicCoursesOverviewResponse>({
    url: 'academic/courses/overview',
    body: { params: params },
    loader: 'academic/courses/overview'
  })

  return response
}
