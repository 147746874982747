import type { StatusMessageResponse } from '@shared/types'
import type { ProfileEducationConductedPostRequest } from '@shared/swagger'
import { putProfileEducationConducted } from '@shared/api'

import type { StoreType } from '../types'

export default async function editConductedCourses (this:StoreType, id: number, values: ProfileEducationConductedPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileEducationConducted(id, values)

  return {
    status: ok,
    message: message
  }
}
