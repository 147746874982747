import { FormGroup } from '@/shared/types'
import { computed } from 'vue'
import { useRulesAndRegulations } from '../store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { useTeleportModal } from '@/shared/composable'
import type { IRuleFormEmits, IRuleFormProps } from '../types'
import { RulesAndRegulationsPostRequest, SingleRuleGetResponse } from '@/shared/swagger'
import { useToast } from 'primevue/usetoast'

const useRuleForm = (emit: IRuleFormEmits, props: Required<IRuleFormProps>) => {
  const toast = useToast()
  const store = useRulesAndRegulations()
  const { categories } = storeToRefs(store)
  const { t } = useI18n()
  const { setModifier, open } = useTeleportModal()

  const setAction = (attachment?: SingleRuleGetResponse['attachments'][0]) => {
    setModifier('attachment-modal', attachment ? { attachment } : {})
    open({ name: 'attachment-modal' })
  }

  const category_id = computed(() => categories.value.find(c => c.title === props.rule.category_title)?.id || undefined)

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.common.feedback-modal.select-category')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-5'
          },
          name: 'category_id',
          type: 'select',
          options: categories.value.map(c => ({ label: c.title, value: c.id })),
          value: category_id.value,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('domains.rules-and-regulations.rule-form.category-required') }
            ]
          }
        }
      ]
    },
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.table.title_en')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-4'
          },
          name: 'title_en',
          type: 'text',
          value: props.rule.title_en,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('domains.rules-and-regulations.rule-form.title-en-required') }
            ]
          }
        },
        {
          label: {
            text: `${t('common.table.title_ru')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'title_ru',
          type: 'text',
          value: props.rule.title_ru
        },
        {
          label: {
            text: `${t('common.table.title_kz')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'title_kz',
          type: 'text',
          value: props.rule.title_kz
        },
        {
          label: {
            text: `${t('common.table.description_en')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-4'
          },
          name: 'description_en',
          type: 'textarea',
          required: true,
          value: props.rule.description_en,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('domains.rules-and-regulations.rule-form.description-en-required') }
            ]
          }
        },
        {
          label: {
            text: `${t('common.table.description_ru')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'description_ru',
          type: 'textarea',
          value: props.rule.description_ru
        },
        {
          label: {
            text: `${t('common.table.description_kz')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'description_kz',
          type: 'textarea',
          value: props.rule.description_kz
        }
      ]
    }
  ])

  const onSubmit = ({ values }) => {
    toast.removeAllGroups()
    const newRule = values as RulesAndRegulationsPostRequest
    emit('save', newRule)
  }
  return {
    formFields,
    onSubmit,
    setAction
  }
}

export default useRuleForm
