import { computed } from 'vue'
import { SelectField } from '@/shared/types'
import { AssesmentTypes } from '@/shared/enums'

export default computed(() => {
  const options: SelectField['options'] = []

  Object.keys(AssesmentTypes).forEach(key => {
    options.push({
      label: key,
      value: AssesmentTypes[key]
    })
  })

  return options.sort((a, b) => a.label.localeCompare(b.label))
})
