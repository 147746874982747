
import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { useToken } from '@shared/composable'
import { RouteLocationNormalized } from 'vue-router'
import { AcademicManageCoursesRequisitesGetResponse, AcademicManageCoursesRequisitesItemsGetResponse } from '@/shared/swagger'

export default async function bootstrap (this: StoreType, to:RouteLocationNormalized): Promise<void> {
  this.setPageParams(to)

  const resource = useResource()
  const year = useToken().getYear()
  const page = to.meta.page as 'main' | 'new' | 'edit' | 'requisite'

  Promise.all([
    resource.fetchResource({ modules: ['year-terms'] }),
    resource.fetchAcademicResource({ modules: ['departments'], year, term: 1 })
  ])

  if (page === 'new' || page === 'edit') {
    await resource.fetchAcademicManageCourseResource({ modules: ['terms', 'languages', 'types', 'grading_types', 'requisite_types', 'consent_statuses', 'section_types', 'course_prefixes'] })
    await resource.fetchAcademicManageCourseDepartmentsResource({ year })
  }

  const requisite_based_type = (this.page_params.program_code || this.page_params.track) ? 'program' : 'university'

  switch (page) {
    case 'edit':
      this.fetch_course_info({
        code: this.page_params.course_code || '',
        year: this.page_params.course_year || year,
        requisite_based_type: requisite_based_type,
        track: this.page_params.track,
        program_code: this.page_params.program_code
      })
      this.setDescriptionEditable(true)
      break
    case 'new':
      this.setCourseManageRequest({
        code: '',
        title: '',
        year: 0,
        language: '',
        title_kz: '',
        title_ru: '',
        title_en: '',
        department_code: '',
        theory: 0,
        practice: 0,
        lab: 0,
        credits: 0,
        ects: 0,
        course_code_prefix: '',
        course_code_code: '',
        local_code: '',
        grading_type: '',
        desctiption_en: '',
        desctiption_ru: '',
        desctiption_kz: '',
        period_code: 'S',
        term: 0,
        equicode: 0,
        type: '',
        ar_requisite: '',
        pr_requisite: '',
        cr_requisite: ''
      })
      this.setDescriptionEditable(false)
      break
    case 'requisite':
      this.setRequisiteInfoResponse({} as AcademicManageCoursesRequisitesGetResponse)
      this.setRequisiteItemsResponse([] as AcademicManageCoursesRequisitesItemsGetResponse)
      await resource.fetchAcademicManageCourseResource({ modules: ['consent_statuses'] })
      if (this.page_params.program_code) {
        await resource.fetchAcademicManageCourseRequisitesResource({ program_code: this.page_params.program_code })
      }
      await this.fetch_requisite_info({
        course_code: this.page_params.course_code || '',
        year: this.page_params.course_year || year,
        requisite_type: this.page_params.requisite_type || '',
        requisite_based_type: requisite_based_type,
        track: this.page_params.track,
        program_code: this.page_params.program_code
      })
      if (this.requisite_info_response.id) {
        await this.search_requisite_items({
          requisite_id: this.requisite_info_response.id
        })
      }

      break
  }
}
