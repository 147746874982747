import { SelectField } from '@shared/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (data: any): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((item) => {
    options.push({
      label: `${item.title}`,
      value: item.isTrue
    })
  })

  return options
}
