import type { StoreType } from '../types'

import { useToken } from '@shared/composable'
import { useResource } from '@shared/stores'
import { sensitiveObject } from '@shared/utils'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()
  const token = useToken()
  const sensitive = sensitiveObject()

  let year = token.getYear()
  let term = token.getTerm()

  await resource.fetchAcademicCoursesResources({ modules: ['my-year-terms'] })

  const yearTerms = resource.getAcademicCoursesResourceMyYearTerms
  const hasCurrentYearTerm = yearTerms.find((item) => item.year === year && item.term === term)

  if (!hasCurrentYearTerm && yearTerms.length > 0) {
    year = yearTerms[0].year
    term = yearTerms[0].term
  } else {
    resource.fetchYearTermScheduleHoursResources({ year: year, term: term })
  }

  this.fetchScheduleRecords({ year, term, schedule_by: 'instructor', filter: [sensitive.employee_id as string] })
}
