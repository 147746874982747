import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import type { AdministrativeMessageBoardPostsPostRequest } from '@shared/swagger'

import { putAdministrativeMessageBoardPosts } from '@shared/api'

export default async function editPost (this: StoreType, id: number, values: AdministrativeMessageBoardPostsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAdministrativeMessageBoardPosts(id, values)

  if (ok) await this.fetchPosts(undefined, 'administrative/message-board/posts-put')

  return {
    status: ok,
    message
  }
}
