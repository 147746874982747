<template>
  <div class="mt-5 ms-5">
    <pms-button
      :variant="'tonal'"
      class="py-1 ps-2 pe-0"
      @click="router.back()"
    >
      <pms-kt-icon
        :icon-name="'arrow-left'"
        class="fs-1"
      />
    </pms-button>
  </div>
  <div class="fixed-center text-muted">
    <p class="fs-1 text-center m-0">
      {{ $t('domains.student.attendance.actions.start-attendance') }}
    </p>
    <p class="fs-5 text-center m-0 mb-3">
      {{ $t('domains.student.attendance.actions.start-attendance-description') }}
    </p>
    <pms-kt-icon
      :icon-name="'scan-barcode'"
      style="font-size: 125px; position: relative; margin-left: 95px;"
    />
    {{ message }}
  </div>
  <pms-button
    :text="'domains.student.attendance.actions.start-attendance-action'"
    class="w-350px btn-fixed px-3 fs-5 h-50px"
    @click="askForGrant()"
  />
</template>
<script lang="ts" setup>
import { useAttendance } from '../composable'
import { router } from '@/router'
const { message, askForGrant } = useAttendance()
</script>
<style lang="scss" scoped>
.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-fixed {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
}
</style>
