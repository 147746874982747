<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          class="mt-3"
          v-bind="header"
          :no-mobile="true"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex align-items-center gap-2">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </template>
          <pms-page-tour
            :launched="status"
            :steps="steps"
            @launch="launch"
          />
        </pms-page-header>
      </pms-grid>
    </pms-row>

    <pms-row v-if="itemsLoader || resourceLoader">
      <pms-page-shimmer
        :shimmer="`card`"
        :filter="true"
      />
    </pms-row>

    <pms-row v-else>
      <pms-grid
        :col="8"
        :order-mb="1"
        class="mt-3 mt-md-0"
      >
        <pms-card
          :header="false"
        >
          <pms-button
            id="knowledge-search-back-button"
            :variant="'tonal'"
            :size="'sm'"
            :text="'domains.knowledge-base.search.back-to-main'"
            :icon-placement="'start'"
            :icon="'bi bi-arrow-left'"
            class="mb-4"
            @click="backToMain"
          />
          <div id="knowledge-search-posts">
            <template v-if="searchedKnowledgeBase.length !== 0">
              <KnowledgeBaseSearchTitle />
              <template
                v-for="(oneKnowledgeBase, index) in searchedKnowledgeBase"
                :key="index"
              >
                <KnowledgeBaseSearchPost
                  :class="{ 'pt-4': index !== 0 }"
                  :knowledge-base-single-post="oneKnowledgeBase"
                />
              </template>
            </template>
            <pms-no-data v-else />
          </div>
          <pms-pagination
            id="knowledge-search-pagination"
            class="mt-6"
          />
        </pms-card>
      </pms-grid>
      <pms-grid
        :col="4"
        class="order-first order-md-last"
      >
        <KnowledgeBaseSearchFilterForm id="knowledge-search-search-form" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader, useTour, useDriver } from '@shared/composable'

import KnowledgeBaseSearchFilterForm from '@domains/KnowledgeBaseSearch/ui/KnowledgeBaseSearchFilterForm.vue'
import KnowledgeBaseSearchTitle from '@domains/KnowledgeBaseSearch/ui/KnowledgeBaseSearchTitle.vue'
import KnowledgeBaseSearchPost from '@domains/KnowledgeBaseSearch/ui/KnowledgeBaseSearchPost.vue'

import { useSearchKnowledgeBase } from './store'
import { header, quickTour, tour } from './values'
import { router } from '@/router'

const store = useSearchKnowledgeBase()

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const itemsLoader = isActive('knowledge/posts')
const resourceLoader = isActive('knowledge/resources')

const searchedKnowledgeBase = computed(() => store.getSearchKnowledgeBase)

const backToMain = () => {
  router.push({ path: '/knowledge-base' })
}

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTour())
})
</script>
