<template>
  <HeadInfo class="mt-5" />
  <pms-card
    :title="t('domains.academic.curricula.reference.add-tool-card')"
    class="justify-content-center mt-5"
  >
    <div class="d-flex gap-3 mb-5">
      <pms-checkbox
        :state="applyForAllYears"
        @on-check="onApplyForAllYearsCheckboxChange(true)"
        @on-uncheck="onApplyForAllYearsCheckboxChange(false)"
      />
      <label>{{ t('domains.academic.curricula.reference.apply-for-all-years-checkbox') }} </label>
    </div>
    <div class="d-flex flex-row align-items-center col-12">
      <CoursesTable class="col-5" />
      <div class="d-flex align-items-center justify-content-center col-2">
        <pms-kt-icon
          :icon-name="'arrow-mix'"
          style="font-size: 45px;"
          class="text-success"
        />
      </div>
      <ReferenceCoursesTable class="col-5" />
    </div>
    <pms-divider />
    <pms-button @Click="handleClickSave">
      {{ t('common.save') }}
    </pms-button>
  </pms-card>

  <pms-card
    :title="t('domains.academic.curricula.reference.search-courses-tool-card')"
    class="justify-content-center mt-5"
  >
    <SearchInput />
    <pms-divider />
    <SearchCourseReferences />
  </pms-card>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import CoursesTable from './ui/CoursesTable.vue'
import ReferenceCoursesTable from './ui/ReferenceCoursesTable.vue'
import SearchInput from './ui/SearchInput.vue'
import SearchCourseReferences from './ui/SearchCourseReferences.vue'
import HeadInfo from './ui/HeadInfo.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAlert } from '@/shared/composable'
import addReference from '@/shared/api/AcademicCurricula/addReference'
import { referenceCourses } from './values'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { router } from '@/router'

const store = useStore()
const { coreCoursesForReference } = storeToRefs(store)

const { t } = useI18n()
const route = useRoute()

const applyForAllYears = ref(false)
const onApplyForAllYearsCheckboxChange = (value: boolean) => {
  applyForAllYears.value = value
}

const { dialog, error } = useAlert()

const handleClickSave = () => {
  dialog({
    onConfirm: async () => {
      if (!coreCoursesForReference.value) return
      const requestData = {
        curriculum_ids: coreCoursesForReference.value.courses.map(coreCourseForReference => coreCourseForReference.id),
        equicodes: referenceCourses.value.map((referenceCourse) => referenceCourse.equicode.toString()),
        apply_all_years: applyForAllYears.value
      }

      const { status, message } = await addReference(requestData)

      if (status) {
        router.back()
      } else {
        error({
          text: message
        })
      }
    },
    text: t('domains.academic.curricula.reference.save-confirm-text')
  })
}

const trackId = parseInt(route.params.trackId as string)
let courses = route.query.courses as string[]

onMounted(() => {
  if (trackId && courses && courses.length > 0) {
    if (typeof courses === 'string') courses = [courses]

    store.fetchCoreCoursesForReference({
      track_id: trackId as number,
      curricula_ids: courses.map(item => parseInt(item))
    })
  }
})

onUnmounted(() => {
  referenceCourses.value = []
})
</script>
