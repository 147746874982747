import type { SectionManagementRequestCourseGetResponse } from '@shared/swagger'
import type { StoreType } from '../types'
import { ReferencesSelects } from '../../types'

function getReferencesYearCode (course : SectionManagementRequestCourseGetResponse[0]['references'][0]) : ReferencesSelects[] {
  return course.courses.map((reference) => {
    return {
      course_code: reference.code || '',
      course_year: reference.year || 0,
      course_title: reference.title || ''
    }
  })
}

export default function getCourseAndReferences (this: StoreType) {
  return (equicode: number, courseCode: string) => {
    const output : ReferencesSelects[] = []
    const selectedCourse = this.requestCourses.find((course) => course.equicode === equicode && course.code === courseCode)
    if (!selectedCourse) return undefined

    const mainCourseData = {
      course_code: selectedCourse.code,
      course_year: selectedCourse.year,
      course_title: selectedCourse.title
    }
    output.push(mainCourseData)

    const referencesData = selectedCourse.references.map(getReferencesYearCode).flat()

    if (referencesData) {
      output.push(...referencesData)
    }

    return output
  }
}
