import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicManageCourseRequisitesResourceGetResponse,
  AcademicManageCourseRequisitesResourceGetRequest
} from '@shared/swagger'

export default async function getAcademicManageCourseResourceDepartments (params: AcademicManageCourseRequisitesResourceGetRequest): Promise<AxiosResponse<AcademicManageCourseRequisitesResourceGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCourseRequisitesResourceGetResponse>({
    url: 'academic/manage/courses/requisites/resources',
    body: { params },
    loader: 'academic/manage/courses/requisites/resources'
  })

  return response
}
