import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { SignInRequest, SignInResponse } from '@shared/swagger'

export default async function postUserAuthenticate (body: SignInRequest): Promise<AxiosResponse<SignInResponse>> {
  const { post } = useRequests()

  const response = post<SignInRequest, SignInResponse>({
    url: 'sign-in',
    body,
    configs: { token: false },
    loader: '/1.0.0/sign-in',
    camelize: true
  })
  return response
}
