<template>
  <div
    id="fixed-menu"
    class="card shadow"
  >
    <div class="card-body p-4 align-items-center">
      <span class="fw-bold fs-5 pe-0">{{ $t('common.action') }}:</span>
      <pms-button
        :text="'common.set-all'"
        class="ms-2 py-2 px-4"
        @click="setAction()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAlert } from '@shared/composable'
import { selectedCourses, uploaded, selectedCoursesReq } from '../values'
import { useExamDates } from '../store'

const store = useExamDates()
const { error, success } = useAlert()

const setAction = async () => {
  if (uploaded) {
    const { status, message } = await store.putExamDates(selectedCoursesReq.value)
    if (status) {
      success({ text: message, isToast: true })
      selectedCourses.value = []
      uploaded.value = false
      selectedCoursesReq.value = []
    } else {
      error({ text: message })
      selectedCoursesReq.value = []
    }
  }
}

</script>

  <style scoped lang="scss">
  #fixed-menu {
    position: fixed;
    height: 60px;
    width: inherit;
    bottom: 0px;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
