<template>
  <template v-if="!sessionEditingStatus">
    <session-icon
      :status="student.attend==null || student.attend==undefined ? 'empty' : statuses[student.attend]"
      @click="activate"
    />
  </template>
  <template v-else>
    <div class="d-flex justify-content-center">
      <div
        class="hidden-btn-group"
      >
        <pms-button
          :disabled="isBubbleOpen"
          variant="link"
          size="sm"
          class="main-btn p-0 m-0"
          :class="{'hidden':isBubbleOpen}"
          @click="openBubble"
        >
          <session-icon
            :status="sessionEditingStatus.attend_id!=undefined && sessionEditingStatus.attend_id!=null ? statuses[sessionEditingStatus.attend_id]:'empty'"
            icon-class="fs-2hx m-0"
          />
        </pms-button>
        <div
          class="hidden-btns"
        >
          <template
            v-for="(status_id) in Object.keys(statuses)"
            :key="status_id"
          >
            <div
              class="btn-item"
              :class="{'active': isBubbleOpen}"
            >
              <pms-button
                :variant="'link'"
                :size="'sm'"
                class="m-0 p-0 pe-2"
                @click="updateAttendance(status_id)"
              >
                <session-icon
                  :status="statuses[status_id]"
                  :icon-class="'fs-2hx m-0 cursor-pointer'"
                />
              </pms-button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { ref, PropType, computed } from 'vue'
import SessionIcon from './AttendanceTableBodySessionIcon.vue'
import { TableDataWithRowspan } from '../types'
import { attendances, useAttendanceTable } from '../composable'
import { statuses } from '../values'
const isBubbleOpen = ref<boolean>(false)

const props = defineProps({
  student: {
    type: Object as PropType<TableDataWithRowspan[0]>,
    required: true
  },
  modeAll: {
    type: Boolean,
    default () {
      return false
    }
  }
})
const { setSingleAttendance, setAllAttendances, startEditingSingleAttendance, startEditingAll } = useAttendanceTable()

const sessionEditingStatus = computed(() => {
  if (props.modeAll) {
    if (props.student.attend !== undefined && props.student.attend != null) {
      return {
        attend_id: props.student.attend
      }
    } else {
      return undefined
    }
  } else {
    const stud_attendance = attendances.value.find((item) => item.schedule_id === props.student.course_schedule_id && item.student_id === props.student.student_id + '')
    return stud_attendance
  }
})

function activate () {
  if (props.modeAll) {
    props.student.attend = 1
    startEditingAll()
  } else {
    startEditingSingleAttendance(props.student)
  }
}

const updateAttendance = (status: string) => {
  if (props.modeAll) {
    setAllAttendances(+status)
  }
  setSingleAttendance(props.student, +status)
  closeBubble()
}

const openBubble = () => {
  isBubbleOpen.value = true
}

const closeBubble = () => {
  isBubbleOpen.value = false
}
</script>
<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
  transition-delay: 0.25s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.hidden-btn-group {
  position: relative;
  z-index: 20;
  width: fit-content;
}
.hidden-btns {
  position: absolute;
  width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  top:-24px;
  left:-29px;
}
.main-btn{
  position: relative;
  z-index: 20;
  transition: .3s;
}
.btn-item {
  position: absolute;
  & button {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    opacity: 0;
  }
  &:nth-child(1) button {
    transition-delay: 0.25s ;
  }

  &:nth-child(3) button {
    transition-delay: 0s;
  }

  &:nth-child(2) button {
    transition-delay: 0.125s;
  }
}

.active {
  & button {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    opacity: 1;
  }
  &:nth-child(1) button {
    left: -30px;
    transition-delay: 0s;
  }

  &:nth-child(3) button {
    left: 30px;
    transition-delay: 0.25s;
  }

  &:nth-child(2) button {
    top: -25px;
    transition-delay: 0.125s;
  }
}

.hidden{
  opacity: 0;
}
</style>
