import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileExperiencesGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileExperiences (): Promise<AxiosResponse<ProfileExperiencesGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileExperiencesGetResponse>({
    url: 'profile/experiences',
    body: {},
    loader: 'profile/experiences'
  })
  return response
}
