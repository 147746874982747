import { useUser } from '@shared/stores'
import { AxiosResponse } from '@shared/types'

import type { StoreType } from '../types'
import type { CheckTokenRequest, CheckTokenResponse } from '@shared/swagger'

export default async function checkToken (this: StoreType, credentials: CheckTokenRequest): Promise<AxiosResponse<CheckTokenResponse>> {
  const user = useUser()

  return await user.checkToken(credentials)
}
