import type { SmartTableHeader } from '@shared/types'
export const tableHeadersLanguage: Array<SmartTableHeader> = [
  {
    title: 'common.table.language',
    field: 'language',
    slotCellName: 'language'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'common.table.level',
    field: 'level',
    slotCellName: 'level'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-150px'
  }
]
