import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { isTranslatable } from '@shared/utils'

const ButtonComponent = defineComponent({
  name: 'ButtonComponent',
  props: {
    variant: {
      type: String as PropType<'default' | 'outlined' | 'outlined-dashed' | 'tonal' | 'link'>,
      required: false,
      default: 'default'
    },
    color: {
      type: String as PropType<'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'>,
      required: false,
      default: 'primary'
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md'
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    iconPlacement: {
      type: String as PropType<'start' | 'end'>,
      required: false,
      default: 'start'
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String as PropType<'submit'|'button'|'reset'>,
      required: false,
      default: 'button'
    },
    loader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:loader', 'update:disabled'],
  setup: function (props, { slots }) {
    const { t } = useI18n()

    const hasIcon = props.icon || slots.icon

    return () => (
      <button
        class={[
          'btn',
          `${props.variant === 'default' ? `btn-${props.color}` : ''}`,
          `${props.variant === 'tonal' ? `btn-light-${props.color}` : ''}`,
          `${props.variant === 'outlined' ? `btn-outline btn-outline-${props.color} btn-active-light-${props.color}` : ''}`,
          `${props.variant === 'outlined-dashed' ? `btn-outline btn-outline-dashed btn-outline-${props.color} btn-active-light-${props.color}` : ''}`,
          `${props.variant === 'link' ? `btn-link btn-color-${props.color} btn-active-color-primary` : ''}`,
          `${props.size !== 'md' ? `btn-${props.size}` : ''}`
        ]}
        disabled={props.disabled}
        type={props.type}
      >
        {
          slots.default && (
            slots.default()
          )
        }
        {
          !slots.default && (
            <>
              { hasIcon && props.iconPlacement === 'start' && (
                <>
                  { props.icon && <i class={`${props.icon} me-1`}></i> }
                  { slots.icon && slots.icon() }
                </>
              )}
              { props.text && !props.loader && (
                <span>{isTranslatable(props.text) ? t(props.text) : props.text}</span>
              )}
              {
                props.text && props.loader && (
                  <span class="indicator-label">
                    { t('phrases.please-wait') } <span class="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )
              }
              { hasIcon && props.iconPlacement === 'end' && (
                <>
                  { props.icon && <i class={`${props.icon} ms-1`}></i> }
                  { slots.icon && slots.icon() }
                </>
              )}
            </>
          )
        }
      </button>
    )
  },
  watch: {
    loader: {
      handler (newVal) {
        this.$emit('update:loader', newVal)
      },
      immediate: true
    },
    disabled: {
      handler (newVal) {
        this.$emit('update:disabled', newVal)
      },
      immediate: true
    }
  }
})

export default ButtonComponent
