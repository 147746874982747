import { Service } from '../../types'
import icon from './icon'

const dms: Service = {
  id: 'dms-service',
  title: 'domains.service.services.dms-service.title',
  description: 'domains.service.services.dms-service.description',
  categories: ['general'],
  url: 'https://bpm.sdu.edu.kz',
  route: '/services/dms-service',
  image: '/img/dms.png',
  icon,
  contents: async () => (await import('@/contents/services/dms.json')).default
}

export default dms
