import { PropType, defineComponent } from 'vue'

import { handleOptions } from '@shared/utils'
import type { AccordionItem, AccordionOptions } from '@shared/types'

const defaultOptions: AccordionOptions = {
  container: {
    class: '',
    style: {}
  },
  item: {
    class: '',
    style: {}
  },
  title: {
    class: '',
    style: {}
  },
  body: {
    class: '',
    style: {}
  },
  content: {
    class: '',
    style: {}
  }
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<AccordionItem>>,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object as PropType<AccordionOptions>,
      default: () => ({})
    }
  },
  emits: ['onOpen'],
  setup (props, { slots, emit }) {
    const options = handleOptions(props.options, defaultOptions)

    const onOpenAccordion = () => {
      emit('onOpen')
    }

    return () => (
      <div
        class={{
          accordion: true,
          [options?.container?.class || '']: true
        }}
        style={options?.container?.style}
      >
        {
          props.items.map((item, index) => {
            return (
              <div
                class={{
                  'accordion-item': true,
                  [options?.item?.class || '']: true
                }}
                style={options?.item?.style}
              >
                <h2 class="accordion-header" id={`heading-${item.slot}`}>
                  <button
                    class={{
                      'accordion-button': true,
                      collapsed: !props.open && index === 0,
                      [options?.title?.class || '']: true
                    }}
                    style={options?.title?.style}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${item.slot}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${item.slot}`}
                    onClick={onOpenAccordion}

                  >
                    { slots.header ? slots.header() : item.title }
                  </button>
                </h2>
                <div
                  id={`collapse-${item.slot}`}
                  class={{
                    'accordion-collapse collapse': true,
                    show: props.open && index === 0,
                    [options?.content?.class || '']: true
                  }}
                  style={options?.content?.style}
                  aria-labelledby={`heading-${item.slot}`}
                >
                  <div
                    class={'accordion-body ' + (options.body?.class || '') }
                    style={options?.body?.style}
                  >
                    { slots[item.slot]?.() }
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
})
