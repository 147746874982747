<template>
  <pms-card
    id="system-events"
    :title="$t('domains.profile.overview.system-events.title')"
    :description="$t('domains.profile.overview.system-events.description')"
    :options="{body: {class: 'pt-0'}}"
  >
    <template #toolbar>
      <router-link
        v-if="events.length > 0"
        to="/administrative/system-calendar"
      >
        {{ $t('common.view-all') }}
      </router-link>
    </template>
    <div
      v-if="events.length > 0"
      class="table-responsive h-400px"
    >
      <table class="table table-row-dashed align-middle gs-0 gy-4">
        <thead>
          <tr class="fs-6 fw-bold border-0 text-gray-400">
            <th>{{ $t('domains.profile.overview.system-events.table.title') }}</th>
            <th class="text-end pe-0">
              {{ $t('domains.profile.overview.system-events.table.start') }}
            </th>
            <th class="text-end">
              {{ $t('domains.profile.overview.system-events.table.end') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(event, index) in events"
            :key="index"
          >
            <tr>
              <td class="">
                <span class="text-gray-800 fw-bold mb-1 fs-6">
                  {{ event.title.toUpperCase() }}
                </span>
              </td>
              <td class="pe-0">
                <div class="d-flex justify-content-end">
                  <div class="me-5">
                    <span class="text-gray-800 fw-bold fs-8">
                      {{ event.startDate }}
                    </span>
                    <span class="text-gray-400 fw-semibold fs-8 d-block text-start ps-0">
                      {{ event.startTime }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="">
                <div class="d-flex justify-content-end">
                  <div class="me-5">
                    <span class="text-gray-800 fw-bold fs-8">
                      {{ event.endDate }}
                    </span>
                    <span class="text-gray-400 fw-semibold fs-8 d-block text-start ps-0">
                      {{ event.endTime }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-if="events.length === 0"
      v-test-id="'no-data'"
      class="d-flex align-items-center justify-content-center min-h-150px"
    >
      {{ $t('common.no-data') }}
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUser } from '@shared/stores'

const eventStore = useUser()
const events = computed(() => eventStore.getEvents)
</script>
