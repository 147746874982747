import { AddedGrades, BellCurvedGrades } from '../types'

export default (bellCurve: BellCurvedGrades): AddedGrades => ({
  assessment_id: bellCurve.assessment_id,
  grades: bellCurve.grades.map(g => ({
    grade: g.currentGrade,
    student_id: g.student_id,
    absent: g.absent
  }))
})
