import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileProjectsPostRequest } from '@shared/swagger'
import { postProfileProjects } from '@shared/api'

import type { StoreType } from '../types'

export default async function addProject (this: StoreType, values: ProfileProjectsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileProjects(values)

  if (ok) await this.fetchProjects()

  return {
    status: ok,
    message
  }
}
