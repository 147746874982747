<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { SyllabusFormMode } from './../types'
import { objectDeepCopy } from '@shared/utils'
import { useI18n } from 'vue-i18n'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const store = useStore()
const { t } = useI18n()

const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['learning_outcomes'][0] & SyllabusFormMode> = {
  id: -1,
  outcome: '',
  subject: '',
  action: '',
  count: 0,
  mode: 'add'
}

const tempValue = ref()

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.outcome')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'outcome',
    value: tempValue.value.outcome || ''
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.subject')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'subject',
    value: tempValue.value.subject || ''
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.action')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'action',
    value: tempValue.value.action || ''
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (newValue.data.mode === 'edit') {
        const item = store.getLearningOutcomes.find((item) => item.id === newValue.data?.id)
        tempValue.value = { mode: newValue.data.mode, ...(item || defaultValue) }
      } else {
        tempValue.value = objectDeepCopy(defaultValue)
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'edit') {
    store.editLearningOutcome({
      action: values.action || '',
      subject: values.subject || '',
      outcome: values.outcome || ''
    }, tempValue.value.id)
  }
  if (tempValue.value.mode === 'add') {
    store.addLearningOutcome({
      action: values.action || '',
      subject: values.subject || '',
      outcome: values.outcome || ''
    })
  }
  close({ name: 'syllabus-form-content-modal' })
  unsetModifier()
}
</script>
../store../types
../store../types
