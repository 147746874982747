import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicManageCoursesRequisitesDeleteRequest, UnknownResponse } from '@shared/swagger'

export default async function (body: AcademicManageCoursesRequisitesDeleteRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<AcademicManageCoursesRequisitesDeleteRequest, UnknownResponse>({
    url: `academic/manage/courses/requisites/${body.id}`,
    body,
    loader: 'academic/manage/courses/requisites/delete'
  })

  return response
}
