import { useAdvisorAssignment } from '@/domains/AdvisorAssignment/store'
import { studentsArray } from '@/domains/AdvisorAssignment/store/types'
import { storeToRefs } from 'pinia'

export default function useAdvisorAssignmentTable () {
  const store = useAdvisorAssignment()

  const { students } = storeToRefs(store)

  /**
   * Toogle _selected property value of student
   */
  const toogleSelectStudent = (item: studentsArray[0]) => {
    item._selected = !item._selected
  }

  return {
    students,
    toogleSelectStudent
  }
}
