import type { AdministrativeGateEntryResponse } from '@shared/swagger'

export default (records: AdministrativeGateEntryResponse): {hours: number, minutes: number} => {
  let total = 0
  records.forEach(record => {
    total += record.work_time
  })

  return { hours: Math.floor(total / 60), minutes: total % 60 }
}
