import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentAttendanceDetailsGetRequest, AcademicSingleStudentAttendanceDetailsGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentAttendanceDetails (id: string, params: AcademicSingleStudentAttendanceDetailsGetRequest): Promise<AxiosResponse<AcademicSingleStudentAttendanceDetailsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentAttendanceDetailsGetResponse>({
    url: `academic/students/${id}/attendance/details`,
    body: { params: params },
    loader: 'academic/students/attendance/details'
  })

  return response
}
