<template>
  <div class="row align-items-center border-bottom min-h-50px">
    <div class="col-6 my-2">
      <label
        class="text-nowrap mb-1"
        for="select-department"
      >{{ $t('common.common.select-department') }}:</label>
      <treeselect
        id="select-department"
        v-model="departments_value"
        value-format="object"
        :multiple="true"
        :options="departments_options"
      />
    </div>
    <div class="col-6 my-2">
      <div
        class="text-nowrap mb-1"
        for="select-department"
      >
        {{ $t('common.select-user') }}:
      </div>
      <div class="w-100">
        <MultiSelect
          v-model="selectedEmployee"
          :options="employee_groups"
          option-label="label"
          filter
          option-group-label="label"
          option-group-children="items"
          option-value="value"
          class="w-100"
        >
          <template #value="slotProps">
            <template
              v-for="(username,index) in slotProps.value"
              :key="index"
            >
              {{ username }}{{ index!=slotProps.value.length-1 ? ', ':'' }}
            </template>
          </template>
          <template #footer>
            <div class="py-2 px-4">
              {{ $t('domains.service.message-service.new-message.employees.selected-items-number') }}
              <b>{{ selectedEmployee ? selectedEmployee.length : 0 }}</b>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Treeselect from 'vue3-treeselect'
import MultiSelect from 'primevue/multiselect'
import { usePersonalByDepartment } from '../composables/personal_by_department'
import { watch } from 'vue'

const { selectedEmployee, employee_groups, departments_value, departments_options, setDepartments, setEmployees } = usePersonalByDepartment()

watch(() => departments_value.value, async () => {
  await setDepartments()
  setEmployees()
})

</script>
