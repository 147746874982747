import { SmartTableHeader } from '@/shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.title',
    field: 'title',
    cellClass: 'fw-bold'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action',
    headerClass: 'text-center'
  }
]
