<template>
  <content-wrapper
    :title="'domains.contents.about.title'"
  >
    <pms-card :header="false">
      <pms-no-data v-if="!currentContent" />
      <template v-else>
        <span class="text-dark text-hover-primary fs-2 fw-bold">
          {{ currentContent.title }}
        </span>
        <div
          v-if="currentContent.description"
          class="mb-0 fs-5"
        >
          <div class="text-muted fw-semibold lh-lg mb-2">
            {{ currentContent.description }}
          </div>
        </div>
        <div v-html="currentContent.content" />
      </template>
    </pms-card>
  </content-wrapper>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { useLocale } from '@shared/composable'
import { SingleContent } from '@shared/types'

import { content } from './values'

import ContentWrapper from '../../ui/ContentWrapper.vue'

const { locale } = useLocale()
const formattedContent = ref<null | SingleContent>(null)
const currentContent = computed(() => formattedContent.value?.contents.find((item) => item.locale === locale.value) || null)

onMounted(async () => {
  formattedContent.value = await content() as SingleContent
})
</script>
