import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDriver } from '@shared/composable'
import { isTranslatable } from '@shared/utils'

export default defineComponent({
  name: 'DriverButton',
  props: {
    title: {
      type: String,
      default: 'common.quick-tour'
    },
    isIcon: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const { drive } = useDriver()
    const { t } = useI18n()
    return () => (
      <pms-button
        variant={ 'tonal' }
        size={ 'sm' }
        onClick={ () => drive() }
        class={
          'fs-5' + (
            !props.isIcon
              ? ' w-150px'
              : ' ps-3 pe-3'
          )
        }
      >
        { props.isIcon && (
          <pms-kt-icon
            icon-name={'question-2'}
            class="fs-2x p-0 m-0"
          />
        ) }
        { !props.isIcon && (
          isTranslatable(props.title) ? t(props.title) : props.title
        ) }
      </pms-button>
    )
  }
})
