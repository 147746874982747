<template>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-10"
    >
      <pms-page-shimmer
        shimmer="card"
        :full-width="true"
      />
    </pms-row>
    <pms-row
      v-else
      class="mt-10"
    >
      <announcement-form
        v-if="singleAnnouncement"
        :announcement="singleAnnouncement"
        :title="$t('domains.service.announcements.edit-announcement.title')"
        @save="handleAnnouncementFormSave"
      />
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import AnnouncementForm from '../../ui/AnnouncementForm.vue'
import { useAlert, useLoader } from '@/shared/composable'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAnnouncements } from '../../store'
import { ServiceManageAnnouncementsPostRequest } from '@/shared/swagger'

const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const store = useAnnouncements()
const route = useRoute()
const router = useRouter()

const loader = isActiveOneOf(['service/manage/announcements/resources', 'service/manage/announcements/single'])

const { singleAnnouncement } = storeToRefs(store)

const handleAnnouncementFormSave = async (values: ServiceManageAnnouncementsPostRequest) => {
  const { status, message } = await store.editExistingAnnouncement(singleAnnouncement.value?.id || 0, values)
  if (status) {
    success({ text: message, isToast: true })
    router.push({ name: 'AnnouncementsPage' })
  } else {
    error({ text: message })
  }
}

onMounted(() => {
  store.fetchSingleAnnouncement(String(route.params.id))
})
</script>
