import { useI18n } from 'vue-i18n'

import { months } from '../values'
import { SelectField } from '@/shared/types'

interface args {
  id: number | string;
  title: string;
}

export const showInitials = (title:string | null):string => {
  return title?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() ?? ''
}

export const displayDate = (item): string => {
  const { t } = useI18n()
  item.start_month = item.start_month as number
  item.start_year = item.start_year as number

  let res = t(months[item.start_month - 1]).slice(0, 3) + ' ' + item.start_year + ' - '

  if (!item.end_month && !item.end_year) {
    res += t('common.currently') + ' - ' + (new Date().getFullYear() - item.start_year)
  } else if (item.end_year && item.end_month) {
    res += t(months[item.end_month - 1]).slice(0, 3) + ' ' + item.end_year + ' - ' + (item.end_year - item.start_year)
  }
  return res + ` ${t('common.year-s')}`
}

export const getTitleById = <T extends args>(id: number | string | null, list: Array<T>): string => {
  const item = list.find((item: T) => item.id === id)
  return item ? item.title : ''
}

export const getIdByTitle = <T extends args>(title: string, list: Array<T>): number => {
  const item = list.find((item: T) => item.title === title)
  return item ? Number(item.id) : -1
}

export const monthToOptionsType = (data): SelectField['options'] => {
  const options: SelectField['options'] = []
  let id = 1
  data.forEach((el) => {
    options.push({
      label: el,
      value: id++
    })
  })
  return options
}

export const toOptionsType = (data): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((el) => {
    options.push({
      label: el.title,
      value: el.id
    })
  })
  return options
}
