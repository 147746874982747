import { useAlert } from '@/shared/composable'
import deleteCurriculumElectiveSlot from '@/shared/api/AcademicCurricula/deleteCurriculumElectiveSlot'
import deleteCurriculumCoreCourse from '@/shared/api/AcademicCurricula/deleteCurriculumCoreCourse'
import { useI18n } from 'vue-i18n'

const useCurriculumDeleter = () => {
  const { dialog, success, error } = useAlert()
  const { t } = useI18n()

  type TItem = {
    id: number,
    is_slot: boolean,
  }

  const handleDelete = (item: TItem, callback?: () => void) => {
    dialog({
      text: t('domains.academic.curricula.delete-course-button'),
      onConfirm: async () => {
        let status, message
        if (item.is_slot) {
          const response = await deleteCurriculumElectiveSlot(item.id)
          status = response.status
          message = response.message
        } else {
          const response = await deleteCurriculumCoreCourse(item.id)
          status = response.status
          message = response.message
        }

        if (status) {
          success({
            text: message,
            isToast: true
          })
          if (callback) callback()
        } else {
          error({
            text: message
          })
        }
      }
    })
  }

  return {
    handleDelete
  }
}

export default useCurriculumDeleter
