<template>
  <div>
    <div class="card-header p-0">
      <div class="d-flex align-items-center">
        <div class="symbol symbol-50px me-5">
          <div class="d-flex align-items-center justify-content-center rounded w-50px h-50px fs-5 bg-light text-dark  ">
            {{ showInitials(getTitleById(experience.company,companies)) }}
          </div>
        </div>

        <div class="flex-grow-1">
          <span class="text-gray-800 fs-3 fw-bold d-block lh-sm">{{ getTitleById(experience.company,companies) }}</span>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="timeline">
        <div
          v-for="(child,index2) in experience.experienceChildren"
          :key="index2"
          class="timeline-item"
        >
          <div class="timeline-line w-50px mt-16" />

          <div class="timeline-icon w-50px me-4 d-flex justify-content-center">
            <div class="rounded-circle bg-light w-15px h-15px mt-8" />
          </div>
          <div class="timeline-content mb-10 mt-n1">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex">
                <div class="flex-grow-1 ">
                  <span class="text-gray-800 fs-3 fw-bold d-block lh-sm">{{ child.title }}</span>
                  <span class="text-gray-800 fs-4 fw-semibold d-block">{{ getTitleById(child.company_id,companies) + ',' + getTitleById(child.country_id,country) }}</span>

                  <span class="text-gray-400 d-block">{{ displayDate(child) }}</span>
                  <span class="text-gray-400 d-block">{{ getTitleById(child.country_id,country) + ',' + getTitleById(child.city_id,cities) }}</span>
                </div>
              </div>

              <div class="d-flex gap-2 mt-5">
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-icon btn-light-primary"
                  @click="emit('set-id',child.id)"
                >
                  <pms-kt-icon
                    icon-name="notepad-edit"
                    class="fs-3 m-0 p-0"
                  />
                </a>

                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-icon btn-light-primary"
                  @click="emit('delete-id', child.id, child.title)"
                >
                  <pms-kt-icon
                    icon-name="trash"
                    class="fs-3 m-0 p-0"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border border-primary" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'

import { ExperienceForDisplay } from '../types'
import { showInitials, displayDate, getTitleById } from '../utils'
import { ProfileResourceResponse } from '@/shared/swagger'

defineProps({
  experience: {
    type: Object as PropType<ExperienceForDisplay>,
    required: true
  },
  country: {
    type: Array as PropType<ProfileResourceResponse['countries']>,
    required: true
  },
  companies: {
    type: Array as PropType<ProfileResourceResponse['companies']>,
    required: true
  },
  cities: {
    type: Array as PropType<ProfileResourceResponse['cities']>,
    required: true
  }
})
const emit = defineEmits(['set-id', 'delete-id'])
</script>
