import { SectionManagementSectionsQuotasGetResponse } from './../../../../../../shared/swagger/entities/SectionManagement/Response/index'
import type { StoreType } from '../types'
import { getSectionManagementSectionsQuotas } from '@shared/api'
import { SectionManagementSectionsQuotasGetRequest } from '@shared/swagger'

export default async function fetchReservedPrograms (this: StoreType, params: SectionManagementSectionsQuotasGetRequest): Promise<{
  reservedPrograms: SectionManagementSectionsQuotasGetResponse
}> {
  const { data, ok } = await getSectionManagementSectionsQuotas(params)

  if (ok) {
    this.selectedRequestsQuotas = data.map(d => ({
      id: d.id,
      total_quota: d.quota,
      registered_count: 0,
      reserves_count: 0,
      available_quota: 0,
      date: d.date,
      program: {
        ...d.reserved
      },
      sections: [...d.sections],
      reserves: [],
      is_reserved: d.is_reserved
    }))
  } else {
    this.selectedRequestsQuotas = []
  }

  return {
    reservedPrograms: ok ? data : []
  }
}
