import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileProjectsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileProjects (): Promise<AxiosResponse<ProfileProjectsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileProjectsGetResponse>({
    url: 'profile/projects',
    body: {},
    loader: 'profile/projects'
  })
  return response
}
