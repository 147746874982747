<template>
  <pms-smart-form
    :fields="[fields]"
    @handleSubmit="onSubmit"
  >
    <template #formControls="{inputs}">
      <div class="flex-fill d-flex">
        <pms-button
          v-if="requisite_info_response.id"
          type="button"
          class="btn btn-light-danger px-4 py-2"
          :disabled="delete_loader"
          @click="delete_requisite"
        >
          <pms-kt-icon
            :icon-name="'trash'"
            class="fw-bold fs-3 p-0"
          />
          {{ $t('domains.academic.manage.course.requisite.delete') }}
        </pms-button>
        <button
          class="btn btn-primary ms-auto"
          type="submit"
          :disabled="!(inputs.values[0].expression && inputs.values[0].expression.trim()) || submit_loader"
        >
          {{ submit_loader ? 'Loading...' : $t('common.submit') }}
        </button>
      </div>
    </template>
  </pms-smart-form>

  <div class="mt-3">
    {{ $t('domains.academic.manage.course.requisite.items.title') }}
  </div>
  <pms-smart-table
    :options="items_table_options"
    :headers="items_table_headers"
    :items="requisite_items_response"
  >
    <template #action="{ item }">
      <pms-button
        type="button"
        class="btn btn-light-danger px-4 py-2"
        :disabled="delete_loader"
        @click="delete_item(item)"
      >
        <pms-kt-icon
          :icon-name="'trash'"
          class="fw-bold fs-3 p-0"
        />
      </pms-button>
    </template>
  </pms-smart-table>
</template>
<script setup lang="ts">
import { useManageCourses } from '@/domains/AcademicCourses/store'
import { storeToRefs } from 'pinia'
import { items_table_headers, items_table_options } from '../values'
import { useRequisiteExpressionForm, useManageRequisiteComposable } from '../composable'
import { useLoader } from '@/shared/composable'

const { requisite_items_response, requisite_info_response } = storeToRefs(useManageCourses())
const { fields } = useRequisiteExpressionForm()
const { onSubmit } = useManageRequisiteComposable()
const { delete_item, delete_requisite } = useManageRequisiteComposable()

const submit_loader = useLoader().isActiveOneOf(['academic/manage/courses/requisites/manage'])
const delete_loader = useLoader().isActiveOneOf(['academic/manage/courses/requisites/delete'])
</script>
