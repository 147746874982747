import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SingleRuleGetResponse
} from '@shared/swagger'

export default async function getRulesAndRegulationsSingleRule (rule: string): Promise<AxiosResponse<SingleRuleGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SingleRuleGetResponse>({
    url: `academic/information/rules/${rule}`,
    body: {},
    loader: 'academic/information/rules/single'
  })

  return response
}
