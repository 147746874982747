import { App } from 'vue'

import KTIcon from '@shared-ui/shared/kt-icon/KTIcon.vue'
import SmartForm from '@shared-ui/shared/form/SmartForm.vue'
import SmartList from '@shared-ui/shared/list/SmartList.vue'
import SmartTable from '@shared-ui/shared/table/SmartTable.vue'

const runner = (vue: App<Element>): void => {
  vue.component('PmsKtIcon', KTIcon)
  vue.component('PmsSmartForm', SmartForm)
  vue.component('PmsSmartList', SmartList)
  vue.component('PmsSmartTable', SmartTable)

  // tour css
  require('../shared/assets/tour.css')
  require('../shared/assets/shimmer.css')
  require('../shared/assets/dropdown.css')
  require('../shared/assets/common.css')
  require('../shared/assets/toolbar.css')
}

export default {
  run: runner,
  priority: 99
}
