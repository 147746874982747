<template>
  <service-wrapper>
    <pms-row class="mt-6">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'card'"
        :filter="true"
      />
      <template v-else>
        <pms-grid
          v-if="service && contents"
          :col="8"
        >
          <service-card
            :service="service"
            :contents="contents"
          />
        </pms-grid>
        <pms-grid
          :col="!(service && contents) ? 12 : 4"
          class="order-first order-md-last"
        >
          <a
            v-if="service"
            :href="service.url"
            class="btn btn-lg btn-primary d-block mb-4 mt-md-0"
            target="_blank"
          >
            {{ $t('phrases.go-to') + " " + $t( service.title ) }}
          </a>
          <pms-card
            :header="false"
            class="d-none d-md-block"
          >
            <pms-no-data />
          </pms-card>
        </pms-grid>
      </template>
    </pms-row>
  </service-wrapper>
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useLoader } from '@/shared/composable'

import { fetchService } from './utils'
import { service, contents } from './values'

import ServiceWrapper from '../ui/ServiceWrapper.vue'
import ServiceCard from './ui/ServiceCard.vue'

const { isActive } = useLoader()
const route = useRoute()

const loader = isActive('single-service')

onMounted(async () => {
  fetchService(route.params.serviceId)
})

watch(
  () => route.params.serviceId,
  (newValue) => { fetchService(newValue) },
  { immediate: true }
)
</script>
