<template>
  <div
    v-if="footerDisplay"
    id="kt_app_footer"
    class="app-footer"
  >
    <div
      class="app-container d-flex flex-column flex-md-row flex-center flex-md-stack py-3"
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-semibold me-1">
          © {{ currentYear }}.
        </span>
        <span class="text-gray-800 text-hover-primary">
          {{ appName }}
        </span>
        <span class="ms-1 text-gray-800">
          v{{ version }}
        </span>
      </div>
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1 fs-6">
        <li class="menu-item">
          <router-link
            to="/about"
            target="_blank"
            class="menu-link px-2"
          >
            {{ $t("layout.footer.about-title") }}
          </router-link>
        </li>
        <li class="menu-item">
          <router-link
            to="/support"
            target="_blank"
            class="menu-link px-2"
          >
            {{ $t("layout.footer.support-title") }}
          </router-link>
        </li>
        <li class="menu-item">
          <router-link
            to="/privacy-policy"
            target="_blank"
            class="menu-link px-2"
          >
            {{ $t("layout.footer.privacy-policy-title") }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { footerWidthFluid, footerDisplay } from '@shared-composable/pattern'

const currentYear = new Date().getFullYear()
const appName = 'Personnel Information System'
const version = process.env.APP_VERSION || '1.0.0'
</script>
