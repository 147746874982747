import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileContactsPutRequest } from '@shared/swagger'
import { postProfileContacts } from '@shared/api'

import type { StoreType } from '../types'

export default async function addContact (this: StoreType, values: ProfileContactsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileContacts(values)

  if (ok) await this.fetchContacts()

  return {
    status: ok,
    message
  }
}
