<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-5 "
      :options="{ body: { class: 'pt-5'} }"
    >
      <PermissionCheck :permission-code="permissionCodes.show_course_contents_description_syllabus.code">
        <syllabus-information-description
          :description="description"
        />
      </PermissionCheck>
    </pms-card>

    <span v-if="amIEnrolled">
      <PermissionCheck :permission-code="permissionCodes.show_objectives_description_syllabus.code">
        <SyllabusDescriptionObjective />
      </PermissionCheck>
      <PermissionCheck :permission-code="permissionCodes.show_description_syllabus.code">
        <SyllabusDescriptionContent />
      </PermissionCheck>
      <PermissionCheck :permission-code="permissionCodes.show_program_components_description_syllabus.code">
        <SyllabusDescriptionComponents />
      </PermissionCheck>
      <PermissionCheck :permission-code="permissionCodes.show_work_placements_description_syllabus.code">
        <SyllabusDescriptionWorkPlacement />
      </PermissionCheck>
      <SyllabusDescriptionForm v-if="modifier && modifier.name === 'syllabus.description'" />
    </span>
    <PermissionCheck :permission-code="permissionCodes.show_mode_of_education_description_syllabus.code">
      <SyllabusModeOfDelivery />
    </PermissionCheck>
  </div>
</template>
<script setup lang="ts">
import { useLoader, useTeleportModal } from '@shared/composable'

import SyllabusDescriptionContent from './SyllabusDescriptionContent.vue'
import SyllabusDescriptionObjective from './SyllabusDescriptionObjective.vue'
import SyllabusDescriptionComponents from './SyllabusDescriptionComponents.vue'
import SyllabusDescriptionForm from './SyllabusDescriptionForm.vue'
import SyllabusDescriptionWorkPlacement from './SyllabusDescriptionWorkPlacement.vue'
import SyllabusModeOfDelivery from './SyllabusModeOfDelivery.vue'
import SyllabusInformationDescription from '../../../ui/SyllabusInformationDescription.vue'
import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { computed } from 'vue'
import { useResource } from '@/shared/stores'

import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

const { isActive } = useLoader()
const { modifier } = useTeleportModal()
const loader = isActive('academic/manage/syllabus/descriptions')
const store = useSingleCourse()
const description = computed(() => store.getSyllabusDescription)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
</script>
