import { postRulesAndRegulations } from '@/shared/api'
import { StoreType } from '../types'
import { RulesAndRegulationsPostRequest } from '@/shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

export default async function createNewRule (this: StoreType, values: RulesAndRegulationsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await postRulesAndRegulations(values)
  ok && this.setSingleRule(data)
  return {
    status: ok,
    message
  }
}
