
import type { StoreType } from '../types'
import type { UnknownResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

import { saveContentsTranslateContentByPath } from '@/shared/api'

export default async function bootstrap (this: StoreType, path: string): Promise<AxiosResponse<UnknownResponse>> {
  const response = await saveContentsTranslateContentByPath(path, this.getPath)

  return response
}
