<template>
  <div>
    <ul
      class="nav"
    >
      <li
        class="nav-item cursor-pointer"
        @click="showComments"
      >
        <span
          class="nav-link btn btn-sm user-select-none btn-color-gray-600 btn-active-light-primary fw-bold px-4 me-1 collapsible pe-none"
          :class="{ 'active': comments_shown }"
        >
          <i class="bi bi-chat-square fs-2 me-1" />
          {{ $t(
            `domains.administrative.message-board.post-item.comment${ comComments.length == 1 ? '' : 's'}`,
            { comments: comComments.length }
          ) }}
        </span>
      </li>
      <li class="ms-auto nav-item">
        <span
          class="nav-link btn btn-sm user-select-none btn-color-gray-600 btn-active-light-primary fw-bold px-4 me-1 collapsible pe-none"
        >
          {{ $t(
            `domains.administrative.message-board.post-item.view${ post.views == 1 ? '' : 's'}`,
            { views: post.views }
          ) }}
        </span>
      </li>
    </ul>
    <div
      class="collapse"
      :class="{show:comments_shown}"
    >
      <template
        v-for="comment in comComments.sort((a,b)=>a.created_at>b.created_at ? 1 : 0)"
        :key="comment.commentId"
      >
        <comment-item
          :comment="comment"
          :username="user?.username || ''"
        />
      </template>

      <pms-divider class="my-6" />

      <new-comment :post-id="post.id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue'

import CommentItem from './CommentItem.vue'
import NewComment from '@domains/AdministrativeMessageBoard/ui/NewComment.vue'

import { useUser } from '@shared/stores'
import { AdministrativeMessageBoardPostsGetResponse } from '@shared/swagger'

const props = defineProps({
  post: {
    type: Object as PropType<AdministrativeMessageBoardPostsGetResponse['posts'][0]>,
    required: true
  },
  expanded: {
    type: Boolean,
    required: true
  }
})

const user = computed(() => useUser().currentUser)
const comComments = computed(() => props.post.comments)

const comments_shown = ref(false)

function showComments () {
  comments_shown.value = !comments_shown.value
}
</script>
