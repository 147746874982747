<template>
  <pms-search
    v-model:value="keyword"
    class="h-40px w-250px border border-secondary rounded"
    :loader="loader"
    @handle-search="onSearch"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useLoader, usePagination } from '@shared/composable'
import { useMyStudents } from '../store'

const { isActive } = useLoader()
const { perPage } = usePagination()
const store = useMyStudents()

const keyword = ref<string>('')

const loader = isActive('academic/students/my')

const onSearch = async () => {
  await (store.mode === 'advisory' ? store.fetchMyStudents : store.fetchThesisStudents)({
    keyword: keyword.value,
    per_page: perPage.value,
    offset: 1
  })
}
</script>

<style scoped lang="scss">

</style>
