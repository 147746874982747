import type { StoreType } from '../types'
import { getAcademicManageCurriculumNavigation } from '@shared/api'

export default async function fetchNavigation (this: StoreType, year: number): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumNavigation({ year })

  if (ok) {
    this.setNavigation(data)
  }
}
