import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { useTeleportModal, useEncrypt, useAlert } from '@/shared/composable'
import { useResource, useUser } from '@/shared/stores'
import { FormField } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RoomHourMatrix } from '../types'
import { relocate_date } from '../values'
import { useAttendanceRelocate } from './attendance_relocate'

export function useSubmitForm () {
  const { modifier, close, unsetModifier } = useTeleportModal()
  const hour = computed(() => modifier.value?.data?.hour as unknown as NonNullable<RoomHourMatrix[0]['hours']>[0])

  const store = useSingleSection()
  const resourceStore = computed(() => useResource().getAcademicSingleCourseAttendanceResource)

  const { schedule } = useAttendanceRelocate()
  const { encrypt } = useEncrypt()
  const { success, error } = useAlert()

  const tempValues = ref({
    message: undefined as string | undefined,
    password: undefined as string | undefined,
    move_all: false
  })
  const { t } = useI18n()

  const formFields = computed(
    ():Array<FormField> =>
      hour.value.lesson
        ? [
            {
              label: {
                text: `${t('common.table.message')}:`,
                class: 'mb-1'
              },
              name: 'message',
              type: 'text',
              value: tempValues.value.message
            },
            {
              label: {
                text: `${t('common.table.password')}:`,
                class: 'mb-1 mt-2 required'
              },
              name: 'password',
              type: 'password',
              value: tempValues.value.password || null,
              required: true
            },
            {
              group: {
                class: 'mt-3'
              },
              name: 'move_all',
              type: 'checkbox',
              inline: false,
              options: [
                {
                  id: '1',
                  value: tempValues.value.move_all,
                  label: {
                    class: 'text-dark',
                    text: t('common.table.move_all')
                  }
                }
              ]
            }
          ]
        : [
            {
              label: {
                text: `${t('common.table.password')}:`,
                class: 'mb-1 mt-2 required'
              },
              name: 'password',
              type: 'password',
              value: tempValues.value.password || null,
              required: true
            },
            {
              group: {
                class: 'mt-3'
              },
              name: 'move_all',
              type: 'checkbox',
              inline: false,
              options: [
                {
                  id: '1',
                  value: tempValues.value.move_all,
                  label: {
                    class: 'text-dark',
                    text: t('common.table.move_all')
                  }
                }
              ]
            }
          ]
  )

  async function onSubmit ({ values }) {
    tempValues.value = values
    const encrypted = encrypt({
      password: values.password,
      date: new Date()
    })
    const user = useUser().currentUser

    let relocate_type:string|undefined
    if (!hour.value.lesson) {
      relocate_type = resourceStore.value?.relocate_types.find(type => type.id === 'EMPTY_PLACE')?.title
    } else if (hour.value.lesson.teacher_id === user?.id) {
      relocate_type = resourceStore.value?.relocate_types.find(type => type.id === 'CHANGE_LESSON')?.title
    } else {
      relocate_type = resourceStore.value?.relocate_types.find(type => type.id === 'REPLACE')?.title
    }

    const captcha = JSON.parse(encrypted || '{}')
    const user_message = values.message as string || 'null'
    const request = {
      captcha,
      course_schedule_id: schedule.value?.id || -1,
      date: relocate_date.value,
      lesson_hour_id: hour.value.hour_id,
      room_id: hour.value.room_id,
      claim_message: user_message,
      move_all: values.move_all?.length > 0 ?? false,
      relocate_type: relocate_type ?? ''
    }
    const { status, message } = await store.putAttendanceRelocates(request)
    if (status) {
      await success({ text: message, isToast: true })
      close({ name: 'relocate-modal' })
      unsetModifier()
      history.back()
    } else {
      error({ text: message })
    }
  }

  return {
    formFields,
    onSubmit
  }
}
