import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCoursesRequisitesPostRequest } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (body: AcademicManageCoursesRequisitesPostRequest): Promise<AxiosResponse<UnknownRequest>> {
  const { post } = useRequests()

  const response = post<AcademicManageCoursesRequisitesPostRequest, UnknownRequest>({
    url: 'academic/manage/courses/requisites',
    body,
    loader: 'academic/manage/courses/requisites/manage'
  })

  return response
}
