/* eslint-disable @typescript-eslint/no-explicit-any */

type ModalName = { name: string }

const useModal = () => {
  const jq: any = jQuery

  return {
    open: (element: HTMLElement & ModalName | ModalName | null) => {
      if (element !== null) {
        jq(`#${element.name}`).modal('show')
      }
    },
    close: (element: HTMLElement & ModalName | ModalName | null) => {
      if (element !== null) {
        jq(`#${element.name}`).modal('hide')
      }
    }
  }
}

export default useModal
