import { useAlert, usePagination } from '@/shared/composable'

import { useManageCourses } from '@/domains/AcademicCourses/store'

import { createCourseFormat, editCourseFormat } from './format'
import { ManageCourseRequestType } from '@/domains/AcademicCourses/types'

const { success, error } = useAlert()
const store = useManageCourses()
const { perPage } = usePagination()

async function updateCourses () {
  const values = store.course_search_request
  store.search_course({ ...values, per_page: perPage.value, offset: 1 })
}

export function useManageCourseComposable () {
  async function createCourse (values:ManageCourseRequestType) {
    const course = createCourseFormat(values)

    const { status, message } = await store.create_course(course)

    status
      ? success({ text: message, isToast: true })
      : error({ text: message })

    if (status) {
      updateCourses()
      store.fetch_course_info_response.year = parseInt(course.year + '')
      store.fetch_course_info_response.code = course.code
      store.setDescriptionEditable(true)
    }
  }

  async function editCourse (values:ManageCourseRequestType) {
    const course = editCourseFormat(values)

    const { status, message } = await store.edit_course(course)

    status
      ? success({ text: message, isToast: true })
      : error({ text: message })
  }

  async function onSubmit ({ values }) {
    if (store.page_params.course_code) {
      editCourse(values)
    } else {
      createCourse(values)
    }
  }

  return {
    createCourse,
    editCourse,
    onSubmit
  }
}
