<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    :breadcrumbs="[
      {
        text: 'Search courses',
      },
    ]"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-2">
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
        <PermissionCheck :permission-code="permissionCodes.edit_courses.code">
          <pms-button
            id="new-course-btn"
            class="me-2 pt-3 pb-3"
            type="button"
            :text="$t('domains.academic.manage.courses.add-course')"
            :variant="'tonal'"
            :size="'md'"
            @click="goToNewCoursePage()"
          />
        </PermissionCheck>
      </div>
    </template>
  </pms-page-toolbar>
  <pms-page-tour
    :launched="status"
    :steps="steps"
    @launch="launch"
  />
  <pms-container>
    <course-search
      class="mb-6"
    />
    <pms-row
      v-if="loader"
    >
      <pms-page-shimmer
        :shimmer="'table'"
        :filter="true"
      />
    </pms-row>
    <pms-row
      v-else
    >
      <pms-grid :col="12">
        <PermissionCheck :permission-code="permissionCodes.show_courses.code">
          <course-list />
        </PermissionCheck>
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang = "ts">
import { onMounted } from 'vue'
import { useToolbar, useLoader, useTour, useDriver } from '@shared/composable'

import { header, feedback, tour, quickAcademicManageCoursesSteps } from './values'

import CourseSearch from './ui/CourseSearch.vue'
import CourseList from './ui/CourseList.vue'

import { useAcademicManageCoursesComposable } from './composable'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'

const { isActiveOneOf } = useLoader()
const loader = isActiveOneOf([])

const { setSteps: setQuickTourSteps } = useDriver()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const { goToNewCoursePage } = useAcademicManageCoursesComposable()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickAcademicManageCoursesSteps())
})

</script>
