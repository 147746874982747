import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentGetOpinionResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentAdvisorOpinion (student: string, opinion: string): Promise<AxiosResponse<AcademicSingleStudentGetOpinionResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentGetOpinionResponse>({
    url: `academic/students/${student}/information/opinions/${opinion}`,
    body: { },
    loader: 'academic/students/information/opinions/'
  })

  return response
}
