<template>
  <pms-card>
    <template #header>
      <rule-form-header
        :title="props.title"
        :description="props.description"
      />
    </template>
    <template #toolbar>
      <div
        v-if="props.rule.id"
        type="button"
        class="btn btn-light-primary  fw-bold"
        :class="{ disabled: !props.rule.id }"
        @click="setAction()"
      >
        <pms-kt-icon
          :icon-name="'plus'"
          class="fw-bold fs-3 p-0"
        />
        {{ $t('common.add-attachment') }}
      </div>
    </template>
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 d-flex justify-content-end">
          <pms-button
            type="submit"
            class="mt-2"
            :loader="loader"
            :disabled="loader"
            :text="props.rule.id ? $t('domains.rules-and-regulations.rule-form.save-changes') : $t('domains.rules-and-regulations.rule-form.create-rule')"
          />
        </div>
      </template>
    </pms-smart-form>
    <pms-divider
      v-if="props.rule.id"
      :content="$t('common.attachments')"
      design="dotted"
      color="primary"
      margin-y="5"
    />
    <div
      v-if="props.rule.id"
      class="mt-3 d-flex flex-column gap-2"
    >
      <div
        v-if="!props.rule.attachments.length"
        class="text-center fs-4 mt-12 mb-12"
      >
        {{ $t('common.no-data') }}
      </div>
      <pms-row v-else>
        <pms-grid
          v-for="attachment in props.rule.attachments"
          :key="attachment.id"
          :col="2"
          class="mb-3"
        >
          <single-attachment-card
            :key="attachment.id"
            :attachment="attachment"
            @edit="handleAttachmentEdit(attachment)"
          />
        </pms-grid>
      </pms-row>
    </div>
  </pms-card>
  <pms-teleport-modal
    :name="'attachment-modal'"
    :teleport-name="'attachment-modal-content'"
    :title="$t(`domains.rules-and-regulations.attachment-form.title`)"
    :description="$t('domains.rules-and-regulations.attachment-form.description')"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'pt-0'}
    }"
  />
  <attachment-modal-form v-if="modifier && modifier.name === 'attachment-modal'" />
</template>

<script setup lang="ts">

import { useLoader, useTeleportModal } from '@/shared/composable'
import type { IRuleFormEmits, IRuleFormProps } from '../types'
import { SingleRuleGetResponse } from '@/shared/swagger'

import SingleAttachmentCard from './SingleAttachmentCard.vue'
import AttachmentModalForm from './AttachmentModalForm.vue'
import RuleFormHeader from './RuleFormHeader.vue'
import { useRuleForm } from '../composables'

const { isActiveOneOf } = useLoader()
const loader = isActiveOneOf(['academic/information/rules/single/edit-contents'])

const props = withDefaults(defineProps<IRuleFormProps>(), {
  title: '',
  description: '',
  rule: () => ({
    id: 0,
    title_en: '',
    title_ru: '',
    title_kz: '',
    description_en: '',
    description_ru: '',
    description_kz: '',
    category_title: '',
    author_full_name: '',
    attachments: []
  })
})

const emit = defineEmits<IRuleFormEmits>()

const { modifier } = useTeleportModal()
const { setAction, formFields, onSubmit } = useRuleForm(emit, props)

const handleAttachmentEdit = (attachment: SingleRuleGetResponse['attachments'][0]) => {
  setAction(attachment)
}

</script>
