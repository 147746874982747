<template>
  <pms-row>
    <pms-grid
      id="gate-entry-switch"
      :col="12"
      class="d-flex gap-5"
    />
    <pms-grid
      id="search-form"
      :col="12"
    >
      <pms-page-shimmer
        v-if="loaderResource"
        :shimmer="'list'"
        :full-width="true"
      />
      <pms-card
        v-else
        :title="$t('common.search')"
        :description="$t('domains.academic.schedule.filter.description', { year: currentYearTerm.year, term: currentYearTerm.term })"
        :options="{
          body: { class: 'pt-0' }
        }"
      >
        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmitForm"
        >
          <template #[schedule_by_values.instructor]>
            <div class="card p-fluid">
              <span class="mb-1 mt-2 required"> {{ `${t('common.instructor')}:` }}</span>
              <AutoComplete
                v-model="selectedKey"
                :suggestions="suggestions"
                field="key"
                option-label="label"
                class="mt-2"
                @complete="search"
              />
            </div>
          </template>
          <template #[schedule_by_values.department]>
            <div class="card">
              <span class="mb-1 mt-2 required"> {{ `${t('common.department')}:` }}</span>
              <TreeSelect
                v-model="selectedKey"
                :options="formattedDepartment"
                selection-mode="checkbox"
                class="mt-2"
              />
            </div>
          </template>
          <template #[schedule_by_values.speciality]>
            <div class="card">
              <span class="mb-1 mt-2 required"> {{ `${t('common.speciality')}:` }}</span>
              <TreeSelect
                v-model="selectedKey"
                :options="specialitiesTree"
                selection-mode="checkbox"
                class="mt-2"
              />
            </div>
          </template>
          <template #[schedule_by_values.room]>
            <div class="card">
              <span class="mb-1 mt-2 required"> {{ `${t('common.room')}:` }}</span>
              <TreeSelect
                v-model="selectedKey"
                :options="roomsMaped"
                selection-mode="checkbox"
                class="mt-2"
              />
            </div>
          </template>
          <template #schedule_by>
            <label class="fs-6 fw-bold">
              {{ $t(`common.filter-by`) }}
            </label>
            <div
              class="btn-group w-100"
            >
              <pms-button
                v-for="key in Object.keys(schedule_by_values)"
                :key="key"
                type="button"
                class="text-hover-white"
                :class="schedule_by === schedule_by_values[key] ? 'text-white': 'text-muted'"
                :variant="schedule_by === schedule_by_values[key] ? 'default' : 'tonal'"
                @click="updateScheduleBy(schedule_by_values[key])"
              >
                {{ $t(`common.${key}`) }}
              </pms-button>
            </div>
          </template>
          <template #formControls>
            <pms-button
              type="submit"
              class="w-150px mt-6"
              :disabled="loader"
            >
              {{ !loader ? $t('common.search') : $t('common.loading') }}
              <span
                v-if="loader"
                class="spinner-border spinner-border-sm align-middle ms-2"
              />
            </pms-button>
          </template>
        </pms-smart-form>
      </pms-card>
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { roomsToOption, formatterSpecialties, specialtiesToOption, formatterDep, formatterInstructors } from '../utils'
import type { FormField } from '@shared/types'
import { useResource } from '@shared/stores'
import { useAlert, useLoader } from '@shared/composable'
import { useAcademicSchedule } from '../store'
import TreeSelect from 'primevue/treeselect'
import AutoComplete from 'primevue/autocomplete'
import { schedule_by_values } from '../enums'

const { t } = useI18n()
const { isActive } = useLoader()
const resource = useResource()
const store = useAcademicSchedule()

const loaderResource = isActive('academic/resources')
const loader = isActive('academic/information/schedules')

const instructors = computed(() => resource.getAcademicInformationScheduleCourseResourceInstructors)
const rooms = computed(() => resource.getAcademicInformationScheduleCourseResourceBuildings)
const specialities = computed(() => resource.getAcademicInformationScheduleCourseResourceSpecialities)
const departments = computed(() => resource.getAcademicInformationScheduleCourseResourceDepartments)
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const schedule_by = ref(schedule_by_values.instructor)
const filter = ref<Array<string|number>>([])
const selectedKey = ref()
const suggestions = ref()

const updateScheduleBy = (newVal) => {
  filter.value = []
  schedule_by.value = newVal
}

const formattedDepartment = computed(() => formatterDep(departments.value))
const specialtiesMapped = computed(() => formatterSpecialties(specialities.value, departments.value))
const formattedInstructors = computed(() => formatterInstructors(instructors.value))
const specialitiesTree = computed(() => specialtiesToOption(specialtiesMapped.value))
const roomsMaped = computed(() => roomsToOption(rooms.value))

const formFields = computed<Array<FormField>>(() => {
  const result: Array<FormField> = [
    {
      type: 'slot',
      name: 'schedule_by'
    },
    {
      name: schedule_by.value,
      type: 'slot',
      group: {
        class: 'mb-3 col-12'
      }
    }
  ]
  return result
})

const onSubmitForm = async () => {
  if (filter.value.length === 0) {
    useAlert().error({ text: 'Filter is empty', isToast: true })
    return
  }
  const reqBody = {
    year: currentYearTerm.value.year,
    term: currentYearTerm.value.term,
    schedule_by: schedule_by.value,
    filter: filter.value
  }
  await store.fetchScheduleRecords(reqBody)
}

const search = (event) => {
  suggestions.value =
  (
    event.query
      ? formattedInstructors.value.filter(item => item.label.toLowerCase().includes((event.query as string).toLowerCase()))
      : formattedInstructors.value
  )
}

watch(() => selectedKey.value, () => {
  filter.value = []
  switch (schedule_by.value) {
    case schedule_by_values.instructor:
      filter.value.push(selectedKey.value.key)
      break
    case schedule_by_values.department:
      Object.keys(selectedKey.value).forEach(key => { if (!key.startsWith('F_')) { filter.value.push(key) } })
      break
    default:
      filter.value = Object.keys(selectedKey.value).filter(key => selectedKey.value[key] && !isNaN(Number(key)) && !isNaN(parseFloat(key))) ?? []
      break
  }
})

watch(() => formattedInstructors.value, () => {
  suggestions.value = formattedInstructors.value
})
</script>
