import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  ProfileEducationConferencesGetRequest,
  ProfileEducationConferencesGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationConferences (params: ProfileEducationConferencesGetRequest): Promise<{type: string, response: AxiosResponse<ProfileEducationConferencesGetResponse>}> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ProfileEducationConferencesGetResponse>({
    url: 'profile/education/conferences',
    body: { params },
    loader: 'profile/education/conferences'
  })

  return {
    type: params.type,
    response
  }
}
