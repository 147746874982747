<template>
  <Teleport to="#biography-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :text="'common.submit'"
            :disabled="loader"
            :loader="loader"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'

import { useTeleportModal, useLoader, useAlert } from '@shared/composable'
import type { FormField } from '@shared/types'

import { useProfile } from '../../../store'

const { isActive } = useLoader()
const { success, error } = useAlert()
const { close, modifier, show, unsetModifier } = useTeleportModal()

const store = useProfile()

const loader = isActive('profile/biography-edit')
const tempValues = ref('')

const formFields = computed<Array<FormField>>(() => [
  {
    name: 'bio',
    type: 'editor',
    value: tempValues
  }
])

const onSubmit = async ({ values }) => {
  const { status, message } = await store.editBiography(values.bio)

  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'biography-modal' })
    unsetModifier()
    store.fetchBiography()
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'biography-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.biography) {
      tempValues.value = newValue.data.biography as string
    }
    show()
  },
  { immediate: true }
)
</script>
