import type { StoreType } from '../types'

import { useAlert, usePagination } from '@shared/composable'
import { getAcademicInformationConsentRequest } from '@shared/api'
import { AcademicInformationConsentRequestGetRequest, AcademicInformationConsentRequestGetResponse } from '@shared/swagger'

export default async function fetchConsentRequests (this: StoreType, params: AcademicInformationConsentRequestGetRequest): Promise<void> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AcademicInformationConsentRequestGetResponse): void => {
    this.setConsentRequests(data.sections || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAcademicInformationConsentRequest({ ...params, per_page: perPage, offset: currentPage })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getAcademicInformationConsentRequest(params)

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }
}
