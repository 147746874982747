<template>
  <pms-card
    :title="'domains.student.dashboard.actions.title'"
    :description="'domains.student.dashboard.actions.description'"
    :options="{
      body: {
        class: 'pt-0'
      }
    }"
  >
    <template
      v-for="(action, index) in actions"
      :key="index"
    >
      <template v-if="action.type === 'link'">
        <div class="d-flex flex-stack">
          <router-link
            class="text-primary fw-semibold fs-5 me-2"
            :to="action.routeLink"
            :title="action.description"
          >
            {{ $t(action.title) }}
          </router-link>
          <pms-button
            :variant="'link'"
            :size="'sm'"
            class="btn-icon h-auto btn-color-gray-500 justify-content-end"
            @click="goTo(action.routeLink)"
          >
            <pms-kt-icon
              :icon-name="'exit-right-corner'"
              class="fs-2"
            />
          </pms-button>
        </div>
      </template>
      <template v-if="action.type === 'action'">
        <div class="d-flex flex-stack">
          <a
            href="javascript:;"
            class="text-primary fw-semibold fs-5 me-2"
            :title="action.description"
            @click="action.action()"
          >
            {{ $t(action.title) }}
          </a>
          <pms-button
            :variant="'link'"
            :size="'sm'"
            class="btn-icon h-auto btn-color-gray-500 justify-content-end"
            @click="action.action()"
          >
            <pms-kt-icon
              :icon-name="'exit-right-corner'"
              class="fs-2"
            />
          </pms-button>
        </div>
      </template>
    </template>
  </pms-card>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useModal } from '@shared/composable'

const { open } = useModal()
const router = useRouter()

const actions = [
  {
    type: 'link',
    title: 'domains.student.dashboard.actions.attendance-qr-title',
    description: 'domains.student.dashboard.actions.attendance-qr-description',
    routeLink: '/student/attendance'
  },
  {
    type: 'action',
    title: 'domains.student.dashboard.actions.my-qr-title',
    description: 'domains.student.dashboard.actions.my-qr-description',
    action: () => {
      open({ name: 'my-qr' })
    }
  }
]

const goTo = (route: string) => {
  router.push(route)
}
</script>
