import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSingleSectionGetResponse
} from '@shared/swagger'

export default async function getScheduleManagementSingleSection (section: string): Promise<AxiosResponse<ScheduleManagementSingleSectionGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSingleSectionGetResponse>({
    url: `academic/manage/schedules/sections/${section}`,
    body: {},
    loader: 'academic/manage/schedules/sections-single'
  })

  return response
}
