/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue'
import { ModalModifier, NamedModal } from '@shared/types'

const modifier = ref<ModalModifier>(null)
const showContent = ref(false)

const useTeleportModal = () => {
  const jq: any = jQuery

  return {
    modifier,
    showContent,
    setModifier: (name, data, translate = {}) => {
      modifier.value = { name, data, translate }
    },
    unsetModifier: () => {
      modifier.value = null
    },
    show: () => {
      showContent.value = true
    },
    hide: () => {
      showContent.value = false
    },
    open: (element: HTMLElement & NamedModal | NamedModal | null) => {
      if (element !== null) {
        jq(`#${element.name}`).modal('show')
      }
    },
    close: (element: HTMLElement & NamedModal | NamedModal | null) => {
      if (element !== null) {
        jq(`#${element.name}`).modal('hide')
      }
    }
  }
}

export default useTeleportModal
