import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageAnnouncementsResourcesGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesApplicants (): Promise<AxiosResponse<ServiceManageAnnouncementsResourcesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageAnnouncementsResourcesGetResponse>({
    url: 'service/manage/announcements/resources',
    body: {},
    loader: 'service/manage/announcements/resources'
  })

  return response
}
