import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  KnowledgeManagePostsSingleGetResponse
} from '@shared/swagger'

export default async function getKnowledgeManageSinglePost (uuid: string): Promise<AxiosResponse<KnowledgeManagePostsSingleGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeManagePostsSingleGetResponse>({
    url: `knowledge/manage/posts/${uuid}`,
    body: {},
    loader: 'knowledge/manage/posts/single'
  })

  return response
}
