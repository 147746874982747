<template>
  <h4 class="text-center m-6">
    {{ $t('domains.academic.reports.parameters') }}
  </h4>
  <div
    class="row align-items-center flex-wrap my-3"
  >
    <div
      v-for="(parameter,index) in single_report.parameters.filter(param=>param.select_type!='checkedCombo')"
      :key="index"
      class="col-12"
    >
      <div class="p-5 my-2 bg-gray-200 d-flex px-8">
        <h6 class="mb-0 required">
          {{ parameter.title }}:
        </h6>
      </div>
      <div class="col-10 d-flex p-2 px-8">
        <select
          v-if="parameter.select_type=='lookUp'"
          class="form-select"
          required
          @change="selectParam($event,parameter.id)"
        >
          <option
            :value="undefined"
          >
            {{ $t('common.choose') }}
          </option>
          <option
            v-for="(value,i) in parameter.values"
            :key="i"
            :value="value.id"
          >
            {{ value.title }}
          </option>
        </select>
        <input
          v-if="parameter.select_type=='dateEdit'"
          class="form-control"
          type="date"
          required
          @change="selectParam($event,parameter.id)"
        >
      </div>
    </div>
    <div
      v-for="(parameter,index) in single_report.parameters.filter(param=>param.select_type=='checkedCombo')"
      :key="index"
      class="col-12 mt-2"
    >
      <div class="d-flex p-5 my-2 bg-gray-200 d-flex px-8">
        <h6 class="mb-0 required">
          {{ parameter.title }}:
        </h6>
        <div class="ms-4 d-flex justify-content-start">
          <pms-checkbox
            :state="parameters.find(param=>param.id==parameter.id)?.values.length==single_report.parameters.find(param=>param.id ==parameter.id)?.values.length"
            @onCheck="toggleAllParamsOn(parameter.id)"
            @onUncheck="toggleAllParamsOff(parameter.id)"
          />
          <label
            class="me-3"
          >
            {{ $t('common.select-all') }}
          </label>
        </div>
      </div>
      <div
        class="row px-9"
        :class="parameter.values.length<6 ? 'justify-content-between' : 'flex-wrap'"
      >
        <div
          v-for="(value,i) in parameter.values"
          :key="i"
          class="d-flex mt-2"
          :class="parameter.values.length>=6 || i==parameter.values.length-1 ? 'col-2' : 'w-unset'"
          :value="value.id"
        >
          <pms-checkbox
            :state="(parameters.find(param=>param.id==parameter.id)?.values.filter(val=>val.id ===value.id)??[]).length>0"
            :name="parameter.id"
            @onCheck="toggleParamFieldOn(parameter.id,value.id)"
            @onUncheck="toggleParamFieldOff(parameter.id,value.id)"
          />
          <label>
            {{ value.title }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useViewPageComposable } from '../composable'
import { useAcademicReports } from '@/domains/AcademicReports/store'

const { single_report } = storeToRefs(useAcademicReports())
const { parameters, selectParam, toggleAllParamsOn, toggleAllParamsOff, toggleParamFieldOn, toggleParamFieldOff } = useViewPageComposable()

</script>
