import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSkillsHardPostRequest } from '@shared/swagger'
import { putProfileSkillsHard } from '@shared/api'

import type { StoreType } from '../types'

export default async function editSkill (this:StoreType, id: number, values: ProfileSkillsHardPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSkillsHard(id, values)

  return {
    status: ok,
    message: message
  }
}
