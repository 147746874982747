
import { defineComponent, PropType, ref, StyleValue, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { handleOptions, isTranslatable } from '@shared/utils'

type ClassAndStyle = Partial<{
  class: string;
  style: string | Record<string, string>;
}>

type CardOptions = Partial<{
  card: ClassAndStyle;
  title: ClassAndStyle;
  description: ClassAndStyle;
  header: ClassAndStyle;
  body: ClassAndStyle;
  footer: ClassAndStyle;
}>

const defaultOptions: CardOptions = {
  card: { class: 'card-flush', style: '' },
  title: { class: 'card-label fw-bold text-gray-800', style: '' },
  description: { class: 'text-gray-400 mt-1 fw-semibold fs-6', style: '' },
  header: { class: '', style: '' },
  body: { class: '', style: '' },
  footer: { class: '', style: '' }
}

export default defineComponent({
  name: 'UiCard',
  props: {
    title: {
      type: String,
      default: 'Please, set a title'
    },
    description: {
      type: String,
      default: ''
    },
    header: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object as () => Record<string, unknown>,
      default: () => ({})
    },
    stretch: {
      type: Number as PropType<100 | 75 | 50 | 33 | 25>,
      default: 0
    },
    expandable: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { slots }) {
    const { t } = useI18n()
    const options = ref<CardOptions>(handleOptions(props.options, defaultOptions))
    const expander = ref(false)

    const collapsed = () => {
      if (props.expandable) return expander.value
      return false
    }

    watch(
      () => props.options,
      (newValue: CardOptions) => { options.value = handleOptions(newValue, defaultOptions) }
    )

    const style: StyleValue = {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      ...options.value.card && options.value.card.style ? options.value.card.style as Record<string, string> : {}
    }

    return () => (
      <div
        class={{
          card: true,
          [options.value.card?.class || '']: true,
          [`card-stretch-${props.stretch}`]: [75, 50, 33, 25].includes(props.stretch),
          'card-stretch': props.stretch === 100
        }}
        style={ style }
      >
        { props.header && (
          <div
            class={{
              'card-header': true,
              [options.value.header?.class || '']: true,
              'mb-3': collapsed()
            }}
            style={ options.value.header?.style }
          >
            { slots.header
              ? slots.header()
              : <h3 class="card-title align-items-start flex-column mb-0">
              <span
                class={{
                  [options.value.title?.class || '']: true
                }}
                style={ options.value.title?.style }
              >
                {props.title && (isTranslatable(props.title) ? t(props.title) : props.title)}
              </span>
              {
                props.description && (
                  <span
                    class={{
                      [options.value.description?.class || '']: true
                    }}
                    style={ options.value.description?.style }
                  >
                    { isTranslatable(props.description) ? t(props.description) : props.description }
                  </span>
                )
                }
              </h3>
            }

            <div class="card-toolbar">
              { slots.toolbar ? slots.toolbar() : null }
              { props.expandable && (
                <button
                  class="btn btn-icon btn-sm btn-light-primary ms-2 mt-3"
                  type="button"
                  onClick={() => { expander.value = !expander.value }}
                >
                  <pms-kt-icon
                    icon-name={ expander.value ? 'plus' : 'minus' }
                    class="fs-1 fw-semibold"
                  />
                </button>
              )}
            </div>
          </div>
        )}
        {
          !collapsed() && (
            <>
            <div
              class={{
                'card-body': true,
                [options.value.body?.class || '']: true
              }}
              style={ options.value.body?.style }
            >
              { props.loader === true && (
                <pms-loader
                  center={true}
                  height={200}
                />
              )}
              { props.loader === false && slots.default ? slots.default() : null }
            </div>
            { props.footer && (
              <div
                class={{
                  'card-footer': true,
                  [options.value.footer?.class || '']: true
                }}
                style={ options.value.footer?.style }
              >
                { slots.footer && slots.footer() }
              </div>
            )}
            </>
          )
        }
      </div>
    )
  },
  watch: {
    loader: {
      handler (newValue) {
        this.$emit('update:loader', newValue)
      },
      immediate: true
    }
  }
})
