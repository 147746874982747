<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          v-bind="header"
          :no-mobile="true"
          @launch="launch"
        >
          <template #toolbar>
            <pms-driver-button class="me-2" />
            <pms-feedback-modal :options="[]" />
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>
    <pms-row>
      <pms-grid
        :col="8"
        :order-mb="2"
        class="mt-5 mt-md-0"
      >
        <router-view />
      </pms-grid>
      <pms-grid :col="4">
        <profile-sidebar />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useTour } from '@shared/composable'

import { header, tour } from './values'

import ProfileSidebar from './ui/ProfileSidebar.vue'

const { status, steps, launch, setSteps } = useTour([])

onMounted(async () => {
  setSteps(await tour())
})
</script>
