<template>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-10"
    >
      <pms-page-shimmer
        shimmer="card"
        :full-width="true"
      />
    </pms-row>
    <pms-row
      v-else
      class="mt-10"
    >
      <rule-form
        v-if="singleRule"
        :rule="singleRule"
        :title="$t('domains.rules-and-regulations.edit-rule.title')"
        @save="handleRuleFormSave"
      />
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRulesAndRegulations } from '../../store'
import RuleForm from '../../ui/RuleForm.vue'
import { useAlert, useLoader } from '@/shared/composable'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { RulesAndRegulationsPostRequest } from '@/shared/swagger'

const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const store = useRulesAndRegulations()
const route = useRoute()

const loader = isActiveOneOf(['academic/information/rules/single', 'academic/information/rules/single/attachments/single'])

const { singleRule } = storeToRefs(store)

const handleRuleFormSave = async (values: RulesAndRegulationsPostRequest) => {
  const { status, message } = await store.editExistingRule(singleRule.value?.id + '', values)
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message, isToast: true, toastOptions: { life: undefined } })
  }
}

onMounted(() => {
  store.fetchSingleRule(String(route.params.id))
})
</script>
