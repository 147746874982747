import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileEducationGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducation (): Promise<AxiosResponse<ProfileEducationGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileEducationGetResponse>({
    url: 'profile/education',
    body: {},
    loader: 'profile/education'
  })
  return response
}
