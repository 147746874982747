<template>
  <div
    id="profile-education-conducted"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.education.conducted.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-150px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="conductedCourses"
        :headers="tableHeadersConducted"
        :sortable="true"
      >
        <template #level="{item}">
          <span
            v-if="getLevelById(item.level)"
            class="text-gray-800 badge badge-light-primary p-3 fs-5"
          >
            {{ getLevelById(item.level) }}
          </span>
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <CoursesConductedForm v-if="modifier && modifier.name === 'conducted'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { tableHeadersConducted } from '../values'
import { useProfile } from '../../../store'

import CoursesConductedForm from './CourseConductedForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const conductedCourses = computed(() => store.getConductedCourses)
const levels = computed(() => resource.getProfileResource?.edu_levels || [])

const setIdAction = (id: number) => {
  setModifier('conducted',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'conducted-new-title' : 'conducted-edit-title',
      description: id === -1 ? 'conducted-new-description' : 'conducted-edit-description'
    }
  )
  open({ name: 'education-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.education.conducted.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteConductedCourse(id)
    }
  })
}

const getLevelById = (id: number|string): string => {
  return levels.value.find((level) => level.code === id)?.title || ''
}
</script>
