import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicManageCoursesDescriptionPostRequest, UnknownResponse } from '@shared/swagger'

export default async function (body: AcademicManageCoursesDescriptionPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageCoursesDescriptionPostRequest, UnknownResponse>({
    url: 'academic/manage/courses/descriptions',
    body,
    loader: 'academic/manage/courses/descriptions'
  })

  return response
}
