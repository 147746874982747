import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useSyllabuses } from './store'
import { usePagination } from '@shared/composable'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useSyllabuses()

  pagination.$reset()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/academic/syllabuses',
  children: [
    {
      path: '',
      name: 'SyllabusesPage',
      component: () => import('./SyllabusesPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
