import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
export default async function bootstrap (this: StoreType, student_id? : string): Promise<void> {
  const resource = useResource()

  await resource.fetchAcademicStudentsResources({ modules: ['student-statuses', 'specialities'] })

  if (student_id) await this.fetchStudents({ student_id: parseInt(student_id), per_page: 10, offset: 1 })
}
