import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { UnknownRequest, UnknownResponse, KnowledgeManagePostsContentsDeleteRequest } from '@shared/swagger'

export default async function destroyKnowledgeManagePostsContents (uuid: string, params: KnowledgeManagePostsContentsDeleteRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()
  const response = destroy<UnknownRequest, UnknownResponse>({
    url: `knowledge/manage/posts/${uuid}/contents`,
    body: { params },
    loader: 'knowledge/manage/posts/delete-contents'
  })

  return response
}
