import type { StoreType } from '../types'
import { AcademicSingleStudentScheduleRequest } from '@shared/swagger'
import { getAcademicSingleStudentSchedule } from '@shared/api'
import { useAlert } from '@/shared/composable'

export default async function fetchSchedule (this: StoreType, id: string, params: AcademicSingleStudentScheduleRequest): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getAcademicSingleStudentSchedule(id, params)

  if (ok) {
    this.setSchedule(data)
  } else {
    error({ text: message, isToast: true })
  }
}
