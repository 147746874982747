import { SelectField } from '@/shared/types'
import { KnowledgeManageResourceResponse } from '@shared/swagger'

export default (data: KnowledgeManageResourceResponse['types']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((type) => {
    options.push({
      label: type.translation,
      value: type.id
    })
  })

  return options
}
