<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-2">
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
      </div>
    </template>
  </pms-page-toolbar>
  <pms-page-tour
    :launched="status"
    :steps="steps"
    @launch="launch"
  />
  <ChooseCategory />
  <div class="mt-3">
    <router-view />
  </div>
</template>
<script setup lang = "ts">
import { onMounted } from 'vue'
import { useToolbar, useTour, useDriver } from '@shared/composable'

import { header, feedback, tour, quickAcademicReportsSteps } from './values'

import ChooseCategory from './ui/ChooseCategory.vue'

const { setSteps: setQuickTourSteps } = useDriver()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickAcademicReportsSteps())
})

</script>
