import { AcademicInformationScheduleRequest } from '@/shared/swagger'
import { StoreType } from '../types'

import { getAcademicInformationSchedule } from '@shared/api'
import { useAlert } from '@shared/composable'

export default async function fetchScheduleRecords (this:StoreType, body: AcademicInformationScheduleRequest): Promise<void> {
  const { error } = useAlert()
  const { ok, message, data } = await getAcademicInformationSchedule(body)
  ok
    ? this.setScheduleRecords(data)
    : error({ text: message })
}
