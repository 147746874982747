import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusWeeklyPlansPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusWeeklyPlan (body: AcademicManageSyllabusWeeklyPlansPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageSyllabusWeeklyPlansPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/weekly-plans',
    body,
    loader: 'academic/manage/syllabus/weekly-plans'
  })
  return response
}
