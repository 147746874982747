import { useRequests } from '@/shared/composable'

import type { AcademicReportsExecutePostRequest, AcademicReportsExecutePostResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params:AcademicReportsExecutePostRequest): Promise<AxiosResponse<AcademicReportsExecutePostResponse>> {
  const { post } = useRequests()

  const response = post<AcademicReportsExecutePostRequest, AcademicReportsExecutePostResponse>({
    url: `academic/information/reports/${params.report}/execute`,
    body: params,
    loader: 'academic/information/reports/execute'
  })

  return response
}
