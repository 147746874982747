<template>
  <div
    id="languages"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.skills.languages.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="languages"
        :headers="tableHeadersLanguage"
        :sortable="true"
      >
        <template #language="{item}">
          {{ getName(item.language_code) }}
        </template>
        <template #type="{item}">
          {{ $t(`domains.profile.skills.languages.${ item.is_native ? 'native' : 'secondary' }`) }}
        </template>
        <template #level="{item}">
          {{ getLevelById(item.level_id) }}
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <LanguageForm v-if="modifier && modifier.name === 'language'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { tableHeadersLanguage } from '../values'
import { useProfile } from '../../../store'

import LanguageForm from './LanguageForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const languageLevels = computed(() => resource.getProfileResource?.language_levels || [])
const languageNames = computed(() => resource.getProfileResource?.languages || [])
const languages = computed(() => store.getLanguages)

const setIdAction = (id: number) => {
  setModifier('language',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'language-new-title' : 'language-edit-title',
      description: id === -1 ? 'language-new-description' : 'language-edit-description'
    }
  )
  open({ name: 'skill-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.skills.are-you-sure',
    text: t('domains.profile.skills.language.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteLanguage(id)
    }
  })
}

const getLevelById = (id: number|string): string => {
  return languageLevels.value.find((level) => level.id === id)?.level || ''
}
const getName = (id: string|number):string => {
  return languageNames.value.find(language => language.id === id)?.language || ''
}
</script>
