<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-toolbar
          v-if="toolbar"
          v-bind="header"
          :go-back="{ name: query.previous_page }"
          @launch="launch"
        >
          <template #action>
            <pms-driver-button class="me-2" />
            <pms-feedback-modal :options="[]" />
          </template>
        </pms-page-toolbar>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>
    <pms-row>
      <pms-grid
        :col="12"
        class="mb-5"
      >
        <horizontal-tab-menu
          :menu-items="(
            myCourse ?
              menuItems :
              menuItems.filter(i => menu.includes(i.routeName))
          )
            .map(m => ({
              ...m, to: { path: m.to, query: { ...query } }
            }))"
        />
      </pms-grid>
      <pms-grid
        :col="12"
        :order-mb="2"
        class="mt-5 mt-md-0"
      >
        <router-view />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useTour, useToolbar } from '@shared/composable'

import { header, menuItems, tour } from './values'
import HorizontalTabMenu from '@/shared/ui/components/HorizontalTabMenu.vue'
import { useRoute } from 'vue-router'
import { useResource } from '@/shared/stores'

const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()

const { query } = useRoute()
const resource = useResource()
const myCourse = computed(() => resource.getAcademicSingleCoursesResource?.enrolled)

const menu: Array<string> = [
  'SingleCourseOverviewPage',
  'SingleCourseSyllabusPage',
  'SingleCourseExamDatesPage',
  'SingleCourseHistoryPage'
]

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
