import { useAlert } from '@/shared/composable'

import { useManageCourses } from '@/domains/AcademicCourses/store'

const { success, error } = useAlert()
const store = useManageCourses()

export function useManageDescriptionComposable () {
  async function manageDescription (values) {
    values.year = store.fetch_course_info_response.year
    values.code = store.fetch_course_info_response.code

    const { status, message } = await store.manage_description(values)

    status
      ? success({ text: message, isToast: true })
      : error({ text: message })
  }

  const onSubmit = async ({ values }) => {
    if (store.description_editable) {
      manageDescription(values)
    } else {
      manageDescription(values)
    }
  }

  return {
    onSubmit
  }
}
