
import type { StatusMessageResponse } from '@/shared/types'
import {
  putAcademicManageSyllabusInstructorsMoodleLink
} from '@shared/api'
import { useAlert } from '@shared/composable'
import { AcademicManageSyllabusInstructorsMoodleLinkPutRequest } from '@shared/swagger'

import type { StoreType } from '../types'
import { fetchSyllabusAfterChange } from '../../utils'

export default async function editMoodleLink (this: StoreType, id: number, values: AcademicManageSyllabusInstructorsMoodleLinkPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicManageSyllabusInstructorsMoodleLink(id, values)
  const { error } = useAlert()

  if (ok) {
    fetchSyllabusAfterChange(this.year, this.course_code)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
