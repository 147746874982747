const permissionCodes = {
  show_academic_operations: {
    code: 'show_academic_operations'
  },
  show_schedule_management: {
    code: 'show_schedule_management'
  },
  show_filter_by_specialities_schedule_management: {
    code: 'show_filter_by_specialities_schedule_management'
  },
  show_section_management: {
    code: 'show_section_management'
  },
  show_sent_request: {
    code: 'show_sent_request'
  },
  show_receive_request: {
    code: 'show_receive_request'
  },
  show_courses: {
    code: 'show_courses'
  },
  show_electronic_attendance: {
    code: 'show_electronic_attendance'
  },
  show_grade_submission: {
    code: 'show_grade_submission'
  },
  edit_courses: {
    code: 'edit_courses'
  },
  show_curriculla: {
    code: 'show_curriculla'
  },
  show_curriculums_and_courses_curriculla: {
    code: 'show_curriculums_and_courses_curriculla'
  },
  edit_curricula: {
    code: 'edit_curricula'
  },
  edit_requisite_curricula: {
    code: 'edit_requisite_curricula'
  },
  show_track_curricula: {
    code: 'show_track_curricula'
  },
  show_selected_term_of_cirriculum_educational_program_management_curriculla: {
    code: 'show_selected_term_of_cirriculum_educational_program_management_curriculla'
  },
  edit_add_reference_educational_program_managements_curriculla: {
    code: 'edit_add_reference_educational_program_managements_curriculla'
  },
  show_program_learning_outcomes_curriculla: {
    code: 'show_program_learning_outcomes_curriculla'
  },
  show_working_curriculla_curriculla: {
    code: 'show_working_curriculla_curriculla'
  },
  show_course_schedule: {
    code: 'show_course_schedule'
  },
  show_download_bulk_course_schedule: {
    code: 'show_download_bulk_course_schedule'
  },
  show_consent_requests: {
    code: 'show_consent_requests'
  },
  edit_consent_request: {
    code: 'edit_consent_request'
  },
  show_syllabus: {
    code: 'show_syllabus'
  },
  show_description_syllabus: {
    code: 'show_description_syllabus'
  },
  show_objectives_description_syllabus: {
    code: 'show_objectives_description_syllabus'
  },
  show_course_contents_description_syllabus: {
    code: 'show_course_contents_description_syllabus'
  },
  show_program_components_description_syllabus: {
    code: 'show_program_components_description_syllabus'
  },
  show_lecturers_description_syllabus: {
    code: 'show_lecturers_description_syllabus'
  },
  show_mode_of_education_description_syllabus: {
    code: 'show_mode_of_education_description_syllabus'
  },
  show_work_placements_description_syllabus: {
    code: 'show_work_placements_description_syllabus'
  },
  show_contents_syllabus: {
    code: 'show_contents_syllabus'
  },
  show_weekly_course_plan_contents_syllabus: {
    code: 'show_weekly_course_plan_contents_syllabus'
  },
  show_learning_outcomes_contents_syllabus: {
    code: 'show_learning_outcomes_contents_syllabus'
  },
  show_others_syllabus: {
    code: 'show_others_syllabus'
  },
  show_teaching_method_others_syllabus: {
    code: 'show_teaching_method_others_syllabus'
  },
  show_materials_used_in_the_textbook_others_syllabus: {
    code: 'show_materials_used_in_the_textbook_others_syllabus'
  },
  show_assessment_methods_and_criteria_others_syllabus: {
    code: 'show_assessment_methods_and_criteria_others_syllabus'
  },
  show_exam_dates: {
    code: 'show_exam_dates'
  },
  edit_exam_dates: {
    code: 'edit_exam_dates'
  },
  show_information: {
    code: 'show_information'
  },
  show_e_attendance_tracking: {
    code: 'show_e_attendance_tracking'
  },
  show_student_attendance: {
    code: 'show_student_attendance'
  },
  show_profile: {
    code: 'show_profile'
  },
  show_feedback: {
    code: 'show_feedback'
  },
  show_feedback_responsibilities_feedback: {
    code: 'show_feedback_responsibilities_feedback'
  },
  show_cv_information: {
    code: 'show_cv_information'
  },
  show_settings: {
    code: 'show_settings'
  },
  show_personal_information: {
    code: 'show_personal_information'
  },
  show_services: {
    code: 'show_services'
  },
  show_register_language_school_students_to_exam: {
    code: 'show_register_language_school_students_to_exam'
  },
  show_questionnaires: {
    code: 'show_questionnaires'
  },
  show_library: {
    code: 'show_library'
  },
  show_messages: {
    code: 'show_messages'
  },
  show_schedule_criteria: {
    code: 'show_schedule_criteria'
  },
  edit_extra_lesson_salary_approval: {
    code: 'edit_extra_lesson_salary_approval'
  },
  show_extra_lesson_salary: {
    code: 'show_extra_lesson_salary'
  },
  show_mail: {
    code: 'show_mail'
  },
  show_announcement: {
    code: 'show_announcement'
  },
  show_lock_management: {
    code: 'show_lock_management'
  },
  show_locking_departments_lock_management: {
    code: 'show_locking_departments_lock_management'
  },
  show_language_levels: {
    code: 'show_language_levels'
  },
  show_language_program_filter_language_levels: {
    code: 'show_language_program_filter_language_levels'
  },
  show_esing: {
    code: 'show_esing'
  },
  edit_documents_to_sign_esign: {
    code: 'edit_documents_to_sign_esign'
  },
  show_advisor_assignment: {
    code: 'show_advisor_assignment'
  },
  show_preparation_courses_language_school: {
    code: 'show_preparation_courses_language_school'
  },
  show_section_management_language_school: {
    code: 'show_section_management_language_school'
  },
  show_course_schedule_management_language_school: {
    code: 'show_course_schedule_management_language_school'
  },
  show_grade_submission_language_school: {
    code: 'show_grade_submission_language_school'
  },
  show_electronic_attendance_language_school: {
    code: 'show_electronic_attendance_language_school'
  },
  show_reports_language_school: {
    code: 'show_reports_language_school'
  },
  show_administrative: {
    code: 'show_administrative'
  },
  show_student_information: {
    code: 'show_student_information'
  },
  show_filter_by_department_student_information: {
    code: 'show_filter_by_department_student_information'
  },
  show_course_registration_student_information: {
    code: 'show_course_registration_student_information'
  },
  show_rules_and_regulations: {
    code: 'show_rules_and_regulations'
  },
  show_forms_and_reports: {
    code: 'show_forms_and_reports'
  },
  show_gate_entry_records: {
    code: 'show_gate_entry_records'
  },
  show_message_board: {
    code: 'show_message_board'
  },
  show_system_calendar: {
    code: 'show_system_calendar'
  },
  show_reports: {
    code: 'show_reports'
  },
  show_staff_list: {
    code: 'show_staff_list'
  },
  edit_inner_phone_staff_list: {
    code: 'edit_inner_phone_staff_list'
  },
  show_system: {
    code: 'show_system'
  },
  show_changelog: {
    code: 'show_changelog'
  },
  show_dashboard: {
    code: 'show_dashboard'
  },
  edit_rules_and_regulations: {
    code: 'edit_rules_and_regulations'
  }
}

export default permissionCodes
