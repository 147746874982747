<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center mb-5">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="handleBackToDepartments"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <span class="text-dark fs-3 fw-bold m-0 p-0">
          {{ selectedDepartment?.title+ ` (${selectedDepartment?.code})` }}
        </span>
        <span class="fs-6 m-0 p-0">
          {{ $t('domains.academic.manage.section.select-course-description') }}
        </span>
      </div>
    </div>

    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :full-width="true"
    />
    <pms-smart-table
      v-else
      :items="courses"
      :headers="courseTableHeader"
      :class="'mt-4'"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #quota="{item}">
        {{ item.statistics.quota_sum }}
      </template>
      <template #new="{item}">
        {{ item.statistics.new_count }}
      </template>
      <template #met="{item}">
        {{ item.statistics.met_count }}
      </template>
      <template #reserved="{item}">
        {{ item.statistics.reserved_count }}
      </template>
      <template #rejected="{item}">
        {{ item.statistics.reject_count }}
      </template>
      <template #action="{item}">
        <pms-button
          :size="'sm'"
          :variant="'tonal'"
          class="w-100px"
          @click="goToSingleCoursePage(item)"
        >
          {{ $t('common.details') }}
          <pms-kt-icon
            :icon-name="'arrow-right'"
            class="m-0 p-0 ms-2"
          />
        </pms-button>
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { useLoader } from '@/shared/composable'

import { useReceivedRequest } from '../store'
import { courseTableHeader, currentCourse, departmentCode } from '../values'
import { SectionManagementRequestCourseGetResponse } from '@shared/swagger'
import { storeToRefs } from 'pinia'
import { useSectionManagement } from '@/domains/SectionManagement/store'

const { isActive } = useLoader()

const store = useSectionManagement()
const receivedRequestStore = useReceivedRequest()
const { selectedDepartment } = storeToRefs(receivedRequestStore)
const { currentYearTerm } = storeToRefs(store)

const loader = isActive('academic/manage/sections/courses')

const courses = computed(() => receivedRequestStore.getSectionsCourses)

const goToSingleCoursePage = async (item: SectionManagementRequestCourseGetResponse[0]) => {
  receivedRequestStore.step = 'requests'
  receivedRequestStore.selectedCourse = item

  currentCourse.value = {
    type: 'received',
    course_code: item.code,
    course_year: item.year,
    term: currentYearTerm.value.term
  }
  await receivedRequestStore.fetchReceivedRequests(currentCourse.value)
}
function handleBackToDepartments () {
  receivedRequestStore.step = 'department'
  receivedRequestStore.sectionsCourses = []
}

watch(() => currentYearTerm.value, async () => {
  await receivedRequestStore.fetchSectionsCourses(
    {
      request_by: 'department',
      department_code: departmentCode.value,
      year: currentYearTerm.value.year,
      term: currentYearTerm.value.term
    })
},
{
  immediate: true,
  deep: true
})
</script>
