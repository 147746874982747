<template>
  <div class="row mb-3">
    <div class="col-3">
      <label>
        {{ $t('domains.academic-single-course.attendance.relocate.filter-by-room') }}
      </label>
      <select
        v-model="room_filter"
        class="form-select form-select"
      >
        <option
          :value="undefined"
        >
          {{ $t('common.all') }}
        </option>
        <option
          v-for="room in attendance_relocates.rooms"
          :key="room.id"
          :value="room.id"
        >
          {{ room.title }}
        </option>
      </select>
    </div>
  </div>
  <table class="table table-bordered table-hover table-striped">
    <thead>
      <tr>
        <th>{{ $t('domains.academic-single-course.attendance.room-time') }}</th>
        <th
          v-for="(hour) in attendance_relocates.hours"
          :key="hour.id"
        >
          {{ hour.start_time }} - {{ hour.end_time }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(room) in room_hour_matrix"
        :key="room.room?.id"
      >
        <td>{{ room.room?.title }}</td>
        <td
          v-for="(hour) in room.hours"
          :key="hour.hour_id"
          class=""
        >
          <div
            class="h-100 w-100 cursor-pointer"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            :data-bs-original-title="
              `
              <div class='text-start'>
                <div class='mb-2'>
                  <span class ='fw-bold'>${$t('common.table.room')}</span>: ${hour.room_name}
                  <br/>
                  <span class ='fw-bold mb-4'>${$t('common.table.hour')}</span>: ${hour.hour_value}
                  <br/>
                </div>
                <div>
                  ${lessionInfo(hour.lesson)}
                </div
              </div
              `
            "
            :class="hour.lesson ? 'bg-danger':'bg-success'"
            @click="openModal(hour)"
          >
                &nbsp;
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { useSingleSection } from '@/domains/AcademicSingleSection/store'

import { storeToRefs } from 'pinia'
import { useAttendanceRelocateTable } from '../composable/attendance_relocate_table'
import { useI18n } from 'vue-i18n'
import { RoomHourMatrix } from '../types'

const { attendance_relocates } = storeToRefs(useSingleSection())

const { room_filter, room_hour_matrix, openModal } = useAttendanceRelocateTable()

const { t } = useI18n()

function lessionInfo (lession:NonNullable<RoomHourMatrix[0]['hours']>[0]['lesson']) {
  if (lession) {
    return `
      <span class ='fw-bold'>${t('common.teacher')}</span>: ${lession.teacher_name}<br>
      <span class ='fw-bold'>${t('common.table.course')}</span>: ${lession.course_code} ${lession.course_title} <br>
    `
  } else {
    return 'Empty'
  }
}
</script>
