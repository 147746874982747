import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import type { AdministrativeMessageBoardCommentsPostRequest } from '@shared/swagger'

import { postAdministrativeMessageBoardComment } from '@shared/api'

export default async function addComment (this: StoreType, values: AdministrativeMessageBoardCommentsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postAdministrativeMessageBoardComment(values)

  if (ok) await this.fetchPosts(undefined, 'administrative/message-board/comments-post')

  return {
    status: ok,
    message
  }
}
