<template>
  <div class="mt-6">
    <pms-row v-if="loader">
      <pms-page-shimmer
        :shimmer="'text'"
        :full-width="true"
      />
    </pms-row>
    <pms-row v-if="!loader && curricula">
      <pms-grid :col="12">
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">
              <router-link :to="{name: 'AcademicCurriculaPage'}">
                <button
                  title="Back"
                  type="button"
                  class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
                >
                  <pms-kt-icon
                    icon-name="arrow-left"
                    class="m-0 p-0"
                  />
                </button>
              </router-link>
              <h1 class="text-dark fs-2 fw-bold mb-4">
                {{ $t(`${curricula.information.program.cipher} ${curricula.information.program.title} (${curricula.information.program.code})`) }}
              </h1>
            </div>
            <p class="mb-6 fs-5">
              {{ $t(`${curricula.information.department.title} (${curricula.information.department.code}) - ${curricula.information.year})`) }}
            </p>
          </div>
          <div>
            <div class="d-flex align-items-center gap-2">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </div>
        </div>
      </pms-grid>
      <pms-grid :col="12">
        <PermissionCheck :permission-code="permissionCodes.show_track_curricula.code">
          <readonly-view />
        </PermissionCheck>
      </pms-grid>
    </pms-row>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useLoader } from '@shared/composable'
import { useStore } from '../../store'
import ReadonlyView from './ui/ReadonlyView.vue'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { viewStepQuickTourSteps } from '../../values'
import permissionCodes from '@/shared/values/permissionCodes'
import { useResource } from '@/shared/stores'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()
const store = useStore()

const loader = isActiveOneOf(['resources', 'academic/manage/curriculum/resources', 'academic/manage/curriculum/view'])
const curricula = computed(() => store.getCurricula)

onMounted(async () => {
  setQuickTourSteps(await viewStepQuickTourSteps())

  const resource = useResource()

  resource.fetchAcademicManageCurriculumResource({ modules: [] })
})
</script>
