<template>
  <pms-card
    :title="`${t('domains.academic.curricula.track-info-card')}`"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <div
      v-else
      class="mb-5"
    >
      <h1>{{ `${t('common.department')}: ` + coreCoursesForReference?.track[0].program?.department.title }}</h1>

      <span class="fs-1 fw-1">
        <span class="fw-bold">{{ `${t('domains.academic.curricula.program')}: ` }}</span>{{ coreCoursesForReference?.track[0].program.code }}
      </span>
      <br>
      <span class="fs-2">
        <span class="fw-bold">{{ `${t('domains.academic.curricula.track')}: ` }}</span>{{ coreCoursesForReference?.track[0].title }}
      </span>
      <br>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '@/domains/AcademicCurricula/store'
import { useQueryHelper } from '../../AddCourseToTrackPage/composable/useQueryHelper'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'
const { t } = useI18n()

const store = useStore()
const queryHelper = useQueryHelper()

const { coreCoursesForReference } = storeToRefs(store)

const { isActive } = useLoader()
const loader = isActive('get-academic/manage/curriculum/references/courses')

onMounted(() => {
  if (queryHelper.params.trackId) {
    store.findTrack({ track: queryHelper.params.trackId })
  }
})
</script>
