<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      class="mb-5"
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="form-group mt-4 d-flex justify-content-end">
          <pms-button
            type="submit"
            class="w-10 text-center"
            :text="$t('common.submit')"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { FormField } from '@shared/types'
import { useI18n } from 'vue-i18n'
import { useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useStore } from '../store'

const { t } = useI18n()
const store = useStore()
const { show, modifier, close } = useTeleportModal()
const { error } = useAlert()

const defaultValue = {
  moodle_link: null
}

const tempValue = ref(objectDeepCopy(defaultValue))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: `${t('common.moodle_link')}:`,
      class: 'mb-1'
    },
    name: 'moodle_link',
    type: 'text',
    value: tempValue.value.moodle_link || '',
    validator: {
      schema: 'yup',
      rules: [
        'string',
        { func: 'label', value: t('common.moodle_link') },
        'trim'
      ]
    }
  }
])

async function onSubmit ({ values }) {
  tempValue.value.moodle_link = values.search
  const employeeId = modifier.value?.data?.id

  if (typeof values.moodle_link !== 'string' && values.moodle_link.length <= 0) {
    error({ text: 'domains.academic.editor.syllabus.moodle-link-error' })
  }

  await store.editMoodleLink(employeeId, values)
  close({ name: 'syllabus-form-content-modal' })
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      tempValue.value = { moodle_link: newValue?.data?.moodle_link }
    }
    show()
  },
  { immediate: true }
)

</script>
