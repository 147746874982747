import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSkillsLanguagesPostRequest } from '@shared/swagger'
import { putProfileSkillsLanguages } from '@shared/api'

import type { StoreType } from '../types'

export default async function editLanguage (this:StoreType, id: number, values: ProfileSkillsLanguagesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSkillsLanguages(id, values)

  return {
    status: ok,
    message: message
  }
}
