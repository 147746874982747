import { typeMapperFromArray } from '@/shared/utils'
import { ProfileSettingsGetResponse } from '@shared/swagger'
import { Privacy, ContactPrivacy } from '../types'

const formatPrivacy = (privacy: ProfileSettingsGetResponse['privacy']) => {
  const privacyArray: Array<Privacy> = []
  let ids = 1
  for (const [key, value] of Object.entries(privacy)) {
    privacyArray.push({
      id: ids++,
      type_id: 0,
      title: key,
      show_status: value
    })
  }
  return privacyArray
}

const formatContactPrivacy = (contactPrivacy: ProfileSettingsGetResponse['contact_privacy']):Array<ContactPrivacy> => {
  return typeMapperFromArray<ProfileSettingsGetResponse['contact_privacy'][0], ContactPrivacy>(contactPrivacy, [
    { from: 'id', to: 'id' },
    { from: 'type_id', to: 'type_id' },
    { from: 'show_status', to: 'show_status' },
    { from: 'contact', to: 'title' }
  ])
}

export default (data: ProfileSettingsGetResponse | null) => {
  return {
    privacy: data?.privacy ? formatPrivacy(data.privacy) : [],
    contactPrivacy: data?.contact_privacy ? formatContactPrivacy(data.contact_privacy) : []
  }
}
