import { useResource } from '@/shared/stores'
import { FormGroup, SelectField } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAdvisorAssignment } from '@/domains/AdvisorAssignment/store'
import { storeToRefs } from 'pinia'
import { useLoader, usePagination } from '@/shared/composable'

export default function useAssignForm () {
  const tempValues = ref({
    employee_id: undefined
  })

  const resource = useResource()
  const { t } = useI18n()
  const store = useAdvisorAssignment()
  const { students } = storeToRefs(store)
  const { trigger } = usePagination()
  const { isActive } = useLoader()
  const isSelectedStudents = computed(() => {
    return students.value ? students.value.some(i => i._selected) : false
  })

  const loader = isActive('put-service/manage/advisors/bulk')

  const advisorOptions = computed(() => {
    let options: SelectField['options'] = []
    if (resource.serviceAdvisorAssignmentResource) {
      options = resource.serviceAdvisorAssignmentResource.advisors.map((item) => {
        return {
          label: item.full_name,
          value: item.id
        }
      })
    }

    return options.sort((a, b) => a.label.localeCompare(b.label))
  })

  const formFields = computed(():Array<FormGroup> => [
    {
      class: 'row mb-3',
      fields: [
        // Advisor
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.form.advisor')}:`,
            class: 'mb-1'
          },
          name: 'employee_id',
          type: 'select',
          options: advisorOptions.value,
          value: tempValues.value.employee_id
        }
      ]
    }
  ])

  const onSubmit = async ({ values }) => {
    tempValues.value = { ...values }

    let studentIds: string[] = []

    if (!students.value || students.value.length === 0 || !tempValues.value.employee_id) return

    studentIds = students.value.filter(i => i._selected).map(i => i.id)

    const result = await store.actionAssignStudentsToAdvisor({
      students_list: studentIds,
      advisor_id: tempValues.value.employee_id
    })

    if (result.status) {
      trigger()
    }
  }

  return {
    formFields,
    students,
    onSubmit,
    loader,
    isSelectedStudents
  }
}
