import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordWithLayout } from '@shared-types/common'

import { useMessageBroadcastSingle } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const id = String(to.params.id)
  if (!id) {
    next({ path: '404' })
    return
  }

  const store = useMessageBroadcastSingle()
  await store.bootstrap(id)

  next()
}

export default {
  layout: 'default',
  path: '/services/message-broadcast/:id',
  children: [
    {
      path: '',
      name: 'MessageBroadcastSinglePage',
      component: () => import('./MessageBroadcastPageSingle.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
