import { SectionTypes } from '@/shared/enums'
import { SelectField } from '@/shared/types'
import { computed } from 'vue'

export default computed(() => {
  const options: SelectField['options'] = []

  Object.keys(SectionTypes).forEach(key => {
    options.push({
      label: key,
      value: SectionTypes[key]
    })
  })

  return options.sort((a, b) => a.label.localeCompare(b.label))
})
