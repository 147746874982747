<template>
  <pms-card
    id="single-student-sidebar"
    class="d-block"
    :header="false"
    :options="{ body: { class: 'px-0' } }"
  >
    <pms-page-shimmer
      v-if="false"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />

    <pms-row
      v-else
      class="p-5 w-100"
    >
      <pms-grid
        :col="12"
        :order-mb="2"
        class="mt-md-0 d-flex align-items-center justify-content-center gap-5 w-100"
      >
        <img
          v-if="information?.photo"
          :src="information?.photo"
          class="rounded object-fit-cover student-img"
        >
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped align-middle">
            <tbody>
              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.navigation.sidebar-information.student-id")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span class="fw-bold fs-4">
                    {{ information?.id }}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.navigation.sidebar-information.name")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span class="fw-bold fs-4">
                    {{ information?.name }} {{ information?.surname }}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.navigation.sidebar-information.department")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span class="fw-bold fs-4">
                    {{ information?.speciality }} ({{ information?.language }}) - {{ information?.class }} [{{ information?.level }}]
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.navigation.sidebar-information.advisor")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span class="fw-bold fs-4">
                    {{ information?.advisor }}
                  </span>
                </td>
              </tr>

              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.debt")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span
                    class="fw-bold fs-4"
                  >
                    <span v-html="debtInfo" />
                  </span>
                </td>
              </tr>

              <tr>
                <td
                  class="text-start ps-5"
                >
                  {{
                    $t("domains.academic.single-student.navigation.sidebar-information.status")
                  }}
                </td>
                <td
                  class="text-start pe-5 fs-4"
                >
                  <span
                    class="fw-bold fs-4"
                    :class="{
                      'text-primary': information?.status === 'Studying',
                      'text-success': information?.status === 'Graduated',
                      'text-danger': information?.status === 'Not Studying',
                      'text-warning': information?.status === 'Pre-Registered',
                    }"
                  >
                    {{ information?.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </pms-grid>
    </pms-row>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useResource } from '@shared/stores'
import { useI18n } from 'vue-i18n'
import { escapeHtml } from '@/shared/utils'

const resource = useResource()
const { t } = useI18n()

const information = computed(
  () => resource.getAcademicSingleStudentResourceInformation
)

function getDebtText (debt: number, currency: string) {
  if (debt > 0) {
    return `${debt} ${currency}`
  } else if (debt === 0) {
    return `<span class="text-success">${t('domains.academic.single-student.no_debt')}</span>`
  } else {
    return `<span class="text-success">${t('domains.academic.single-student.no_debt')}</span> ${t('domains.academic.single-student.debt_negative', { advance: Math.abs(debt), currency })}`
  }
}

const debtInfo = computed(() => {
  if (information.value) {
    // XSS protect в коде используется <span v-html="debtInfo" />
    const safeCurrency = escapeHtml(information.value.currency)
    return getDebtText(information.value.debt, safeCurrency)
  }
  return ''
})

</script>

<style  scoped lang="scss">
.student-img {
  width: 145px;
  height: 200px;
}
</style>
