import type { StoreType } from '../types'
import { useToken } from '@shared/composable'
import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()
  const token = useToken()

  const year = token.getYear()
  const term = token.getTerm()

  this.setCurrentYearTerm({ year, term, id: 0 })
  resource.fetchYearTermScheduleHoursResources({ year: year, term: term })

  resource.fetchResource({ modules: ['year-terms'] })
  resource.fetchAcademicInformationScheduleCourseResource({
    modules: ['instructors', 'specialities', 'rooms', 'departments'],
    year: year,
    term: term
  })
}
