import type { StoreType } from '../types'

import { myStudentsCurrentClass, myStudentsCurrentSpeciality, myStudentsSortField } from './../../values'

import { convertSortFieldToParams } from '@shared/utils'
import { useAlert, usePagination } from '@shared/composable'
import { getAcademicAdvisoryStudents } from '@shared/api'
import {
  AcademicAdvisoryMyStudentsGetRequest,
  AcademicAdvisoryMyStudentsGetResponse
} from '@shared/swagger'

export default async function fetchMyStudents (this: StoreType, params: AcademicAdvisoryMyStudentsGetRequest): Promise<void> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AcademicAdvisoryMyStudentsGetResponse): void => {
    this.setMyStudents(data.students || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAcademicAdvisoryStudents({
      ...params,
      ...convertSortFieldToParams(myStudentsSortField.value),
      class: myStudentsCurrentClass.value,
      program_code: myStudentsCurrentSpeciality.value ? parseInt(myStudentsCurrentSpeciality.value.split('-')[0]) : undefined,
      per_page: perPage,
      offset: currentPage
    })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getAcademicAdvisoryStudents({
    ...params,
    ...convertSortFieldToParams(myStudentsSortField.value),
    class: myStudentsCurrentClass.value,
    program_code: myStudentsCurrentSpeciality.value ? parseInt(myStudentsCurrentSpeciality.value.split('-')[0]) : undefined
  })

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }
}
