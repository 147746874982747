import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'
import api from '@/shared/api/ScheduleManagement/applyProgram'
import { ScheduleManagementProgramApplyPostRequest } from '@/shared/swagger'

export default async function applyProgram (this: StoreType, section: number, program: number, data: ScheduleManagementProgramApplyPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await api(section, program, data)

  if (ok) await this.fetchSingleSectionPrograms(section.toString())

  return {
    status: ok,
    message
  }
}
