<template>
  <pms-card
    :title="'domains.service.message-service.new-message.title'"
    :description="'domains.service.message-service.new-message.description'"
    :options="{
      body: {
        class: 'pt-0'
      }
    }"
  >
    <template #toolbar>
      <div class="mt-2">
        <pms-driver-button class="me-2" />
        <pms-feedback-modal :options="[]" />
      </div>
    </template>
    <div
      v-if="senderModes && senderModes?.length > 0 && roles.includes('administrator')"
      class="d-flex align-items-center border-bottom min-h-50px"
    >
      <div class="text-dark fw-bold min-w-75px">
        {{ $t('domains.service.message-service.new-message.from') }}:
      </div>
      <div class="d-flex align-items-center pe-2">
        <select
          v-model="newMessage.from"
          class="form-select form-select-transparent w-md-250px ml-5"
        >
          <option :value="null">
            {{ $t('common.not-selected') }}
          </option>
          <option
            v-for="sender in senderModes"
            :key="sender.id"
            :value="sender.id"
          >
            {{ sender.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="d-flex align-items-center border-bottom min-h-50px">
      <div class="text-dark fw-bold min-w-75px">
        {{ $t('domains.service.message-service.new-message.to') }}:
      </div>
      <div class="d-flex align-items-center pe-2">
        <select
          v-model="newMessage.type"
          class="form-select form-select-transparent w-md-250px ml-5"
        >
          <option :value="null">
            {{ $t('common.not-selected') }}
          </option>
          <option
            v-for="mode in messageTypes"
            :key="mode"
            :value="mode"
          >
            {{ $t(`domains.service.message-service.new-message.by.${mode}`) }}
          </option>
        </select>
      </div>
    </div>
    <personal-field
      v-if="newMessage.type === message_types.employee"
    />
    <students-field
      v-if="newMessage.type === message_types.student"
      v-model:students="newMessage.students"
    />
    <courses-field
      v-if="newMessage.type === message_types.course"
    />
    <programs-field
      v-if="newMessage.type === message_types.program"
    />
    <div class="border-bottom mb-2">
      <input
        v-model="newMessage.subject"
        class="form-control form-control-transparent border-0 min-h-45px ms-0 ps-0"
        :disabled="loader"
        :placeholder="$t('domains.service.message-service.new-message.subject.title')"
        required
      >
    </div>
    <div
      v-if="newMessage.subject?.length && newMessage.subject?.length > 290"
      class="fv-plugins-message-container mb-4"
    >
      <div class="fv-help-block">
        {{ $t('domains.service.message-service.new-message.subject.error-message') }}
      </div>
    </div>
    <EditorComponent
      v-model="newMessage.content"
      :loader="loader"
    />
    <div
      v-if="newMessage.content?.length && newMessage.content?.length > 3500"
      class="fv-plugins-message-container m"
    >
      <div class="fv-help-block">
        {{ $t('domains.service.message-service.new-message.content.error-message') }}
      </div>
    </div>
    <div class="d-flex flex-stack flex-wrap border-top p-6 ps-0 pt-4">
      <div class="d-flex align-items-center me-3 ">
        <pms-button
          :text="'common.send'"
          :size="'sm'"
          :loader="loader"
          :disabled="newMessage.content.length > 2000 || (newMessage.subject?.length && newMessage.subject?.length > 150)"
          class="px-8"
          @click="onSubmit"
        />
        <pms-button
          :text="'common.back'"
          :size="'sm'"
          class="px-8 ms-4"
          :color="'light'"
          @click="backToInbox"
        />
      </div>
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useResource } from '@shared/stores'
import { useAlert, useLoader } from '@shared/composable'
import { useDashboard } from '@domains/Dashboard/store'
import PersonalField from './PersonalField.vue'
import StudentsField from './StudentsField.vue'
import CoursesField from './CoursesField.vue'
import ProgramsField from './ProgramsField.vue'
import { createSubmitValues, formatDepartments, validator } from '../utils'
import { useMessageBroadcastCompose } from '../store'
import { MessageType } from '../type'
import { storeToRefs } from 'pinia'
import { message_types } from '../enums'
import EditorComponent from '@/shared/ui/components/EditorComponent.vue'

const alert = useAlert()
const router = useRouter()
const { isActive } = useLoader()
const resource = useResource()
const store = useMessageBroadcastCompose()
const dashboardStore = useDashboard()

const messageStore = useMessageBroadcastCompose()
const { selectedPrograms, selectedStudents, selectedEmployee } = storeToRefs(messageStore)

const loader = isActive('service/message-broadcast-post')

const departments = computed(() => formatDepartments(resource.getServiceMessageBroadcastResource?.department_programs))
const senderModes = computed(() => resource.getServiceMessageBroadcastResource?.from)
const roles = computed(() => dashboardStore.getRoles)
const messageTypes: Array<string> = [message_types.student, message_types.employee]

const newMessage = ref<MessageType>({
  from: null,
  type: null,
  subject: null,
  content: '',

  programs: [],
  employees: [],
  students: []
})

const onSubmit = async () => {
  const { validate, errorMessage } = validator(newMessage.value)

  if (!validate) {
    alert.error({ text: errorMessage || 'domains.service.message-service.new-message.errors.invalid' })
    return
  }

  switch (newMessage.value.type) {
    case message_types.program:
      newMessage.value.programs = selectedPrograms.value
      break
    case message_types.employee:
      newMessage.value.employees = selectedEmployee.value
      break
    case message_types.course:
      newMessage.value.students = selectedStudents.value
      break
  }

  const submitValues = createSubmitValues(newMessage.value)
  const { status, message, id } = await store.newMessage(submitValues)

  if (status) handleRedirectToMessage(id)
  else alert.error({ text: message })
}

function backToInbox () {
  router.back()
}

function handleRedirectToMessage (id: number) {
  router.push({
    path: `/services/message-broadcast/${id}`,
    params: {
      id: id
    }
  })
}

watch(
  newMessage.value,
  (newValue) => {
    if (newValue.type === message_types.program && departments.value.length === 0) {
      alert.error({ text: 'domains.service.message-service.new-message.errors.no-departments' })
      newMessage.value.type = null
    }
  }
)

watch(
  () => newMessage.value.type,
  () => {
    newMessage.value.students = []
    selectedEmployee.value = []
    selectedStudents.value = []
    selectedPrograms.value = []
  }
)

watch(
  () => resource.getServiceMessageBroadcastResource,
  (newValue) => {
    if (newValue?.courses && newValue?.courses?.length && newValue?.courses?.length > 0) messageTypes.push(message_types.course)
    if (newValue?.department_programs && newValue?.department_programs?.length && newValue?.department_programs?.length > 0) messageTypes.push(message_types.program)
  }
)

</script>

<style lang="scss" scoped>
.form-control:focus {
  box-shadow: none;
}
</style>
<style>
div.p-editor-toolbar{
  display: none
}
</style>
