<template>
  <Teleport to="#education-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationSupervisionsGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { levelsToOptionType } from '../utils'
import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/education-edit', 'profile/education-post'])
const currYear = new Date().getFullYear()

const supervisions = computed(() => store.getSupervisions)
const levels = computed(() => resource.getProfileResource?.edu_levels || [])

const defaultTempValues: ProfileEducationSupervisionsGetResponse[0] = {
  id: -1,
  student: '',
  level_id: '',
  university: '',
  topic: '',
  start_year: currYear,
  defense_year: currYear,
  student_status: ''
}

const tempValues = ref<ProfileEducationSupervisionsGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.education.supervision.student-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'student',
    type: 'text',
    value: tempValues.value.student,
    required: true,
    placeholder: t('domains.profile.education.supervision.student-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.supervision.topic-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'topic',
    type: 'text',
    value: tempValues.value.topic,
    required: true,
    placeholder: t('domains.profile.education.supervision.topic-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.supervision.university-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'university',
    type: 'text',
    value: tempValues.value.university,
    required: true,
    placeholder: t('domains.profile.education.supervision.university-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.supervision.level-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'level_id',
    type: 'select',
    value: tempValues.value.level_id,
    required: true,
    options: levelsToOptionType(levels.value)
  },
  {
    label: {
      text: t('domains.profile.education.supervision.start-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_year',
    type: 'number',
    value: tempValues.value.start_year,
    required: true
  },
  {
    label: {
      text: t('domains.profile.education.supervision.defense-year-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'defence_year',
    type: 'number',
    value: tempValues.value.defense_year

  }
])

const closeModal = () => {
  close({ name: 'education-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.student) {
    error({ text: 'student-error' })
    return
  }
  if (!values.level_id) {
    error({ text: 'level-error' })
    return
  }
  if (!values.university) {
    error({ text: 'university-error' })
    return
  }
  if (!values.topic) {
    error({ text: 'topic-error' })
    return
  }
  if (!values.start_year) {
    error({ text: 'start-year-error' })
    return
  }
  const { status, message } =
      modifier.value?.data?.action === 'new'
        ? await store.addSupervision({ ...values })
        : await store.editSupervision(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'education-modal' })
    unsetModifier()
    store.fetchSupervisions()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactSupervision = supervisions.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactSupervision || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
