<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          v-bind="header"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex gap-3">
              <pms-driver-button />
              <pms-page-tour
                :launched="status"
                :steps="steps"
                @launch="launch"
              />
            </div>
          </template>
        </pms-page-header>
      </pms-grid>
    </pms-row>
    <pms-row>
      <pms-grid
        :col="12"
      >
        <StudentsSearch />
      </pms-grid>
      <pms-grid
        :order-mb="1"
        class="mt-5"
        :col="12"
      >
        <LockTable />
      </pms-grid>
    </pms-row>
  </pms-container>
  <pms-teleport-modal
    :name="'locks-modal'"
    :teleport-name="'locks-modal-content'"
    :title="$t(`domains.service.manage.lock.${modifier?.translate?.title || 'title'}`)"
    :description="$t(`domains.service.manage.lock.${modifier?.translate?.description || 'title'}`)"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useTour, useDriver, useTeleportModal } from '@shared/composable'
import { header, tour, quickAllStudentsSetTourSteps } from '../ServiceManageLock/values'
import LockTable from './ui/LockTable.vue'
import StudentsSearch from './ui/StudentsSearch.vue'

const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()
const { modifier } = useTeleportModal()

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickAllStudentsSetTourSteps())
})
</script>
