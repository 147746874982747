import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusLearningOutcomesPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusLearningOutcomes (body: AcademicManageSyllabusLearningOutcomesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()
  debugger

  const response = post<AcademicManageSyllabusLearningOutcomesPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/learning-outcomes',
    body,
    loader: 'academic/manage/syllabus/learning-outcomes'
  })
  return response
}
