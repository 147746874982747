import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicInformationScheduleRequest, AcademicInformationScheduleResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicInformationSchedule (params: AcademicInformationScheduleRequest): Promise<AxiosResponse<AcademicInformationScheduleResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicInformationScheduleResponse>({
    url: 'academic/information/schedules',
    body: { params },
    loader: 'academic/information/schedules'
  })

  return response
}
