<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <accounting-component v-else />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourAccounting } from '../../values'

import AccountingComponent from './ui/AccountingComponent.vue'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/accounting'])

onMounted(async () => {
  setQuickTourSteps(await quickTourAccounting())
})
</script>
