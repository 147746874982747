const printStyle = `
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 10px !important;
}

.separator {
  border-bottom-style: dashed;
  display: block;
  width: 100% !important;
  height: 0;
  border-bottom: 1px solid #dddddd;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>
`

export default (): void => {
  const table = document.getElementById('singleCourseSchedulePrint')
  const w = window.open()

  if (table && w) {
    w.document.body.innerHTML = `${
      table.outerHTML
        .replaceAll(': ', '')
        .replaceAll(':00', ':00 ')
    }${printStyle}`
    w.print()
    w.close()
  }
}
