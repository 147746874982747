import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileSkillsLanguagesGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileSkillsLanguages (): Promise<AxiosResponse<ProfileSkillsLanguagesGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileSkillsLanguagesGetResponse>({
    url: 'profile/skills/languages',
    body: {},
    loader: 'profile/skills/languages'
  })
  return response
}
