import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  next()
}

export default {
  layout: 'empty',
  path: '/contents',
  redirect: '/support-page',
  children: [
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: () => import('./features/PrivacyPolicy/PrivacyPolicyPage.vue')
    },
    {
      path: '/terms',
      name: 'TermsOfServicePage',
      component: () => import('./features/TermsOfService/TermsOfServicePage.vue')
    },
    {
      path: '/support',
      name: 'SupportPage',
      component: () => import('./features/Support/SupportPage.vue')
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: () => import('./features/About/AboutPage.vue')
    }
  ],
  beforeEnter: [
    handleRoute
  ]
} as RouteRecordWithLayout
