<template>
  <Teleport to="#publication-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationArticlesGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { getTitleById, monthToOptionsType, toOptionsType } from '../utils'
import { months, yearSuggestions } from '../values'
import { getIdByTitle } from '../../Experience/utils'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()
const loader = isActiveOneOf(['profile/education/articles-edit', 'profile/education/articles-post'])
const selectedJounalTitle = ref('')

const journalsSuggestions = computed(() => resource.getProfileResource?.journals || [])
const countriesSuggestions = computed(() => resource.getProfileResource?.countries || [])
const articles = computed(() => store.getArticles)

const defaultTempValues: ProfileEducationArticlesGetResponse[0] = {
  id: -1,
  title: '',
  journal_id: 0,
  degree: 0,
  series: '',
  authors: '',
  pages: '',
  url: '',
  country: 0,
  published_month: -1,
  published_year: -1
}

const tempValues = ref<ProfileEducationArticlesGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.publications.article.title-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    required: true,
    placeholder: t('domains.profile.publications.article.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.journal-id-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'journal_id',
    type: 'autocomplete',
    value: selectedJounalTitle.value,
    required: true,
    suggestions: {
      itemField: 'title',
      items: journalsSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.publications.article.journal-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.degree-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'degree',
    type: 'number',
    value: tempValues.value.degree,
    placeholder: t('domains.profile.publications.article.degree-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        { func: 'min', value: 1 },
        { func: 'max', value: 4 }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.series-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'series',
    type: 'text',
    value: tempValues.value.series,
    placeholder: t('domains.profile.publications.article.series-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.pages-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'pages',
    type: 'number',
    value: Number(tempValues.value.pages),
    placeholder: t('domains.profile.publications.article.pages-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.url-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'url',
    type: 'url',
    value: tempValues.value.url,
    placeholder: t('domains.profile.publications.article.url-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.authors-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'authors',
    type: 'text',
    value: tempValues.value.authors,
    placeholder: t('domains.profile.publications.article.authors-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.country-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'country',
    type: 'select',
    value: tempValues.value.country,
    required: true,
    options: toOptionsType(countriesSuggestions.value),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.published-month-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_month',
    type: 'select',
    value: tempValues.value.published_month,
    required: true,
    options: monthToOptionsType(months),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.article.published-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_year',
    type: 'autocomplete',
    value: tempValues.value.published_year === -1 ? '' : tempValues.value.published_year,
    required: true,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.publications.article.published-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  }
])

const checkIfJournalIsNew = (articleTitle: string): boolean => {
  const journal = journalsSuggestions.value.find((journal) => journal.title === articleTitle)
  return !journal
}

const closeModal = () => {
  close({ name: 'publication-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  if (name === 'journal_id') {
    selectedJounalTitle.value = newValue
  }
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  values.type = modifier.value?.data?.type
  if (!values.published_year) {
    error({ text: t('domains.profile.publications.article.published-year-field-error') })
    return
  }
  if (!values.published_month) {
    error({ text: t('domains.profile.publications.article.published-month-field-error') })
    return
  }
  if (!values.country) {
    error({ text: t('domains.profile.publications.article.country-field-error') })
    return
  }
  if (!values.type) {
    error({ text: t('domains.profile.publications.article.type-field-error') })
    return
  }
  values.pages += ''
  const isJournalNew = checkIfJournalIsNew(selectedJounalTitle.value)
  if (isJournalNew) {
    values.journal_new = selectedJounalTitle.value
    values.journal_id = null
  } else {
    const journalId = getIdByTitle(selectedJounalTitle.value, journalsSuggestions.value)
    values.journal_id = journalId
  }
  const { status, message } =
         tempValues.value.id === -1
           ? await store.addArticle({ ...values })
           : await store.editArticle(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'publication-modal' })
    unsetModifier()
    store.fetchArticles()
  } else {
    error({ text: message })
  }
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action && newValue.data.type) {
      if (newValue.data.action === 'edit' && typeof newValue.data.type === 'string') {
        const exactArticle = articles.value.get(newValue.data.type)?.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactArticle || defaultTempValues)
        selectedJounalTitle.value = getTitleById(tempValues.value.journal_id, journalsSuggestions.value)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
