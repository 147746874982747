import { useResource } from '@/shared/stores'
import type { StoreType } from '../types'
import { usePagination } from '@/shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()

  await resource.fetchServiceEsignResource({
    modules:
    [
      'statuses', 'year-terms', 'types', 'entrance-years', 'programs', 'departments'
    ]
  })
  if (resource.serviceEsignResource) {
    this.fetchEsignStudents({
      year: +resource.serviceEsignResource.year_terms[0].year,
      term: +resource.serviceEsignResource.year_terms[0].term,
      per_page: usePagination().perPage.value,
      offset: usePagination().currentPage.value
    })
  }
}
