import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationConductedPostRequest } from '@shared/swagger'

export default async function postProfileEducationConducted (params: ProfileEducationConductedPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationConductedPostRequest, UnknownResponse>({
    url: 'profile/education/conducted-courses',
    body: params,
    loader: 'profile/education/conducted-courses-post'
  })

  return response
}
