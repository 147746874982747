/* eslint-disable camelcase */
import { StoreType } from '../types'
import { getDashboardWidgets } from '@shared/api'
import { formatAttendance, formatSyllabus, sortWidgets } from '../../utils'

export default async function bootstrap (this: StoreType): Promise<void> {
  const { ok, data } = await getDashboardWidgets()

  if (ok && data) {
    const { attendances, greetings, courses, schedules, faculty_statistics, rules_and_regulations, syllabus_statistics, posts, reports } = data
    this.setAttendance(formatAttendance(attendances.data))
    this.setGreetings(greetings.data)
    this.setCourses(courses.data)
    this.setSchedules(schedules.data)
    this.setFacultyStatistics(faculty_statistics.data)
    this.setRulesAndRegulations(rules_and_regulations.data)
    this.setSyllabusStatistics(formatSyllabus(syllabus_statistics.data))
    this.setPosts(posts.data)
    this.setReports(reports.data)
    this.setWidgets(data)
    sortWidgets(data)
  }
}
