import { computed } from 'vue'
import type { StoreType } from '../types'

import { RouteParams } from '../../types'
import { useResource } from '@/shared/stores'
import { getDepartmentId } from '../../utils'
import { useStore } from '@/domains/AcademicSingleCourse/features/SyllabusPage/features/EditorSyllabus/store'
import { current_course, header } from '../../values'
import { router } from '@/router'

export default async function bootstrapByPage (this: StoreType, page: string, params: RouteParams): Promise<void> {
  const { code, year, term, previous_page } = params
  const resource = useResource()
  const syllabusEditorStore = useStore()
  const department_id = getDepartmentId(
    resource.getAcademicSingleCoursesResourceInformation?.department_code || '',
    resource.getAcademicResourceDepartments
  )
  const course_code = computed(() => router.currentRoute.value.query.code)

  switch (page) {
    case 'overview':
      await this.fetchOverview({ code, year })
      this.overview && previous_page === 'AcademicCoursesPage' && (header.title = `${this.overview.code} - ${this.overview.title}`)
      break
    case 'syllabus':
      await Promise.all([
        this.fetchSyllabus({ course_code: code, year }),
        syllabusEditorStore.setCourseParams(code, year)
      ])
      break
    case 'history':
      await Promise.all([
        this.fetchHistory({ course_code: code, year, term })
      ])
      break
    case 'schedule':
      await Promise.all([
        this.fetchScheduleRecords({ year, term, course_code: code })
      ])
      break
    case 'exam-dates':
      await Promise.all([
        this.fetchExamDates({ year, term, department_id, course_code: code })
      ])
      break
    case 'attendance':
      await Promise.all([
        this.fetchAttendanceCourses({ year, term })
      ])
      break
    case 'attendanceQR':
      await Promise.all([
        this.fetchAttendanceCourses({ year, term })
      ])
      if (course_code) {
        current_course.value = this.attendance_courses.filter(course => course.course_code === course_code.value)[0]
      } else {
        router.push({ name: 'AcademicCoursesMyCourses' })
      }
      break
  }
}
