import { useManageCourses } from '@/domains/AcademicCourses/store'
import { useResource } from '@shared/stores'
import { FormField } from '@shared/types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { consentStatusOptions, trackOptions } from '../utils'

export function useRequisiteExpressionForm () {
  const store = useManageCourses()
  const resource_store = useResource()

  const tempValues = computed(() => ({
    cr_enabled: store.requisite_info_response.cr_enabled,
    expression: store.requisite_info_response.expression,
    track: store.requisite_info_response.track
  }))

  const { t } = useI18n()
  const resource = computed(() => resource_store.academicManageCourseResource)
  const tracks = computed(() => resource_store.academicManageCourseRequisitesResource)

  const fields = computed<FormField[]>(() => {
    let fields:FormField[] = []
    if (store.page_params.program_code && tracks.value && tracks.value.length > 0) {
      fields.push(
        {
          group: {
            class: 'mb-3'
          },
          name: 'track',
          type: 'select',
          value: tempValues.value.track,
          options: trackOptions(tracks.value),
          label: {
            text: t('domains.academic.manage.course.tracks')
          }
        }
      )
    }

    fields = fields.concat([
      {
        group: {
          class: 'mb-3'
        },
        name: 'cr_enabled',
        type: 'select',
        value: tempValues.value.cr_enabled,
        options: consentStatusOptions(resource.value?.consent_statuses || []),
        label: {
          text: t('domains.academic.manage.course.consent_statuses')
        }
      },
      {
        group: {
          class: 'mb-6'
        },
        name: 'expression',
        type: 'textarea',
        label: {
          text: `${t('domains.academic.manage.course.requisite_expression')} ${t('domains.academic.manage.course.requisite_expression_description')}`,
          style: {
            whiteSpace: 'pre'
          }
        },
        value: tempValues.value.expression,
        class: 'fs-2x p-4'
      },
      {
        name: 'table',
        type: 'slot'
      }
    ])
    return fields
  })

  return {
    fields
  }
}
