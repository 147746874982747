import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useMyStudents = defineStore<Name, State, Getters, Actions>('Academic.useMyStudents', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      mode: 'advisory',
      myStudents: [],
      thesisStudents: []
    }
  }
})
