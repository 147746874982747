<template>
  <p
    v-if="false"
    class="mb-4 fs-5 fw-semibold"
    v-html="$t('domains.editor.curricula.readonly.long-text', keywords)"
  />
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import type { AcademicSingleStudentCurriculumGetResponse } from '@/shared/swagger'

const props = defineProps({
  information: {
    type: Object as PropType<AcademicSingleStudentCurriculumGetResponse['information']>,
    required: true
  }
})

const keywords = computed(() => ({
  year: props.information.year,
  department_code: props.information.department.code,
  department_title: props.information.department.title,
  program_code: props.information.program.code,
  program_title: props.information.program.title,
  program_type: props.information.program.type,
  program_cipher: props.information.program.cipher,
  faculty_title: props.information.program.faculty.title,
  faculty_code: props.information.program.faculty.code,
  language_code: props.information.program.education.language.code,
  language_title: props.information.program.education.language.title,
  education_type: props.information.program.education.type.title,
  level_code: props.information.program.education.level.key,
  level_title: props.information.program.education.level.title,
  track_type: props.information.track.type,
  track_title: props.information.track.title
}))
</script>
