/* eslint-disable @typescript-eslint/no-var-requires */
import { PathFilePaired } from '@avocado/types'

export const loadLanguageFiles = (): PathFilePaired[] => {
  const files: PathFilePaired[] = []

  require.context(
    '@/i18n/',
    true,
    /^((?![\\/][\\/]).)*\.lang.ts$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/i18n/' + filename.substring(2)).default }))

  return files
}
