import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicManageCoursesDeleteRequest, UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function (params: AcademicManageCoursesDeleteRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: 'academic/manage/courses',
    body: { params },
    loader: 'academic/manage/courses'
  })

  return response
}
