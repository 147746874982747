<template>
  <pms-card
    id="search-form"
    :title="'common.search'"
    :description="$t('domains.service.esign.search.description')"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <div
          class="form-group d-flex justify-content-center mt-3"
        >
          <div
            class="flex-fill"
          >
            <pms-button
              type="submit"
              class="w-100 "
              :disabled="loader"
              :loader="searchLoader"
              :text="$t('common.search')"
            />
          </div>
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { useSearchForm } from '../composable'

const { isActive } = useLoader()

const loader = isActive('service/manage/esigns/resources')
const searchLoader = isActive('service/manage/esigns/get')

const { formFields, onSubmit, onHandleChange } = useSearchForm()

</script>
