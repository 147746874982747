import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LayoutGrid',
  props: {
    // grid system sizes
    col: {
      type: Number,
      required: true
    },
    colMb: {
      type: Number,
      default: 12
    },
    colLg: {
      type: Number,
      default: 0
    },
    // order system
    order: {
      type: Number,
      default: 0
    },
    orderMb: {
      type: Number,
      default: 0
    },
    orderLg: {
      type: Number,
      default: 0
    }
  },
  setup (props, { slots }) {
    const width = window.innerWidth

    return () => (
      <div class={{
        [`col-md-${props.col}`]: true,
        [`col-xs-${props.colMb}`]: true,
        [`col-sm-${props.colMb}`]: true,
        [`col-lg-${props.colLg}`]: props.colLg > 0,
        [`col-xl-${props.colLg}`]: props.colLg > 0,
        [`order-${props.order}`]: width >= 768 && width <= 992 && props.order > 0,
        [`order-${props.orderMb}`]: width < 768 && props.orderMb > 0,
        [`order-${props.orderLg}`]: width > 992 && props.orderLg > 0
      }}>
        { slots.default && slots.default() }
      </div>
    )
  }
})
