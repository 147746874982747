import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared-types/common'

import { useSingleStudent } from './store'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSingleStudent()

  store.$reset()

  const params = to.query

  if (params.id) {
    store.bootstrap(params)
  } else {
    next({ path: '/academic/students' })
  }

  next()
}

const handlePageAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSingleStudent()

  const params = to.query as {id: string}
  const { page } = to.meta as { page: string }

  if (params.id) {
    store.bootstrapByPage(page, params)
  } else {
    next({ path: '/academic/students' })
  }

  next()
}
export default {
  layout: 'default',
  path: '/academic/student',
  children: [
    {
      path: '',
      name: 'AcademicSingleStudentPage',
      component: () => import('./SingleStudent.vue'),
      beforeEnter: [
        handleAuthRoute
      ],
      children: [
        {
          path: '',
          name: 'SingleStudentInformationPage',
          component: () => import('./features/Information/InformationPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'information'
          }
        },
        {
          path: 'course-registration',
          name: 'SingleStudentCourseRegistrationPage',
          component: () => import('./features/CourseRegistration/CourseRegistrationPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'course-registration'
          }
        },
        {
          path: 'attendance',
          name: 'SingleStudentAttendancePage',
          component: () => import('./features/Attendance/AttendancePage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'attendance'
          }
        },
        {
          path: 'grades',
          name: 'SingleStudentGradesPage',
          component: () => import('./features/Grades/GradesPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'grades'
          }
        },
        {
          path: 'schedule',
          name: 'SingleStudentSchedulePage',
          component: () => import('./features/Schedule/SchedulePage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'schedule'
          }
        },
        {
          path: 'curriculum',
          name: 'SingleStudentCurriculumPage',
          component: () => import('./features/Curriculum/CurriculumPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'curriculum'
          }
        },
        {
          path: 'transcript',
          name: 'SingleStudentTranscriptPage',
          component: () => import('./features/Transcript/TranscriptPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'transcript'
          }
        },
        {
          path: 'accounting',
          name: 'SingleStudentAccountingPage',
          component: () => import('./features/Accounting/AccountingPage.vue'),
          beforeEnter: [
            handlePageAuthRoute
          ],
          meta: {
            page: 'accounting'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
