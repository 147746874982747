<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'card'"
    :filter="true"
    :full-width="true"
  />
  <template v-else>
    <pms-card
      :title="'domains.academic.single-course.navigation.items.attendance'"
      :description="'domains.academic.single-course.navigation.items.attendance-description'"
      :options="{
        body: {
          class: 'pt-0'
        }
      }"
    >
      <pms-smart-form
        :fields="formFields"
        @handleSubmit="onSubmit"
      />
    </pms-card>
  </template>
  <pms-loader v-if="table_loader" />
  <template v-else-if="attendance_students.length!=0">
    <div
      class="card mt-5"
    >
      <div class="card-body">
        <div class="d-flex align-items-center">
          <pms-button
            v-if="is_today"
            color="primary"
            class="ms-auto me-4 py-2 "
            @click="openQR"
          >
            {{ $t('domains.academic.single-course.attendance.QR') }}
          </pms-button>
        </div>
        <attendance-table />
      </div>
    </div>
  </template>
  <template v-else>
    <pms-card
      :header="false"
      class="mt-5"
    >
      <pms-no-data />
    </pms-card>
  </template>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { useAttendanceTable, useSearchForm } from './composable'
import { computed, onMounted } from 'vue'
import { router } from '@/router'

import AttendanceTable from './ui/AttendanceTable.vue'
import { current_course, search_values } from '@/domains/AcademicSingleCourse/values'
import { useSingleCourse } from '../../store'
import { storeToRefs } from 'pinia'

const { formFields, onSubmit, search } = useSearchForm()
const { openQR } = useAttendanceTable()
const { attendance_students } = storeToRefs(useSingleCourse())
const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf([
  'academic/courses/attendances/by-courses'
])
const table_loader = isActiveOneOf([
  'academic/courses/attendances/by-courses',
  'academic/courses/attendances/by-courses/students'
])

const is_today = computed(() => {
  const today = new Date()
  const to_check = new Date(search_values.value.date)
  return (today.getDate() === to_check.getDate() && today.getMonth() === to_check.getMonth() && today.getFullYear() === to_check.getFullYear()) || current_course.value.grading_type === 'S'
})

onMounted(() => {
  const course_code = router.currentRoute.value.query.code
  const date = new Date().toISOString()

  search_values.value.course_code = course_code as string
  search_values.value.date = date

  search({ course_code, date })
})
</script>
