import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { RulesAndRegulationsAttachmentsPostResponse } from '@shared/swagger'

export default async function postRulesAndRegulationsAttachments (body: FormData, rule: string): Promise<AxiosResponse<RulesAndRegulationsAttachmentsPostResponse>> {
  const { post } = useRequests()

  const response = post<FormData, RulesAndRegulationsAttachmentsPostResponse>({
    url: `academic/information/rules/${rule}/attachments`,
    body,
    loader: 'academic/information/rules/single/attachments/create',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}
