import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useSearchKnowledgeBase } from './store'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSearchKnowledgeBase()
  const search = to.query.search ? String(to.query.search) : undefined
  const category = to.query.category_uuid ? String(to.query.category_uuid) : undefined

  store.$reset()
  store.bootstrap(search, category)

  next()
}

export default {
  layout: 'default',
  path: '/knowledge-base/search',
  children: [
    {
      path: '',
      name: 'KnowledgeBaseSearch',
      component: () => import('./KnowledgeBaseSearch.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
