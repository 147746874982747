<template>
  <BackToMyCourseForm
    @update:is-post-offer="updatePostOffer"
  />
  <pms-row>
    <pms-grid :col="6">
      <MyCoursesCourseDetail :course="props.course" />
    </pms-grid>
    <pms-grid :col="6">
      <pms-smart-form
        :fields="[formFields]"
        @handleChange="onChange"
      >
        <template #formControls>
          <div class="text-end mt-4" />
        </template>
      </pms-smart-form>
      <div class="d-flex gap-3">
        <pms-button
          :color="'light'"
          :text="'common.cancel'"
          @click="updatePostOffer()"
        />
        <pms-button
          type="button"
          :color="'primary'"
          :text="'common.save'"
          @click="onSubmit()"
        />
      </div>
    </pms-grid>
  </pms-row>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { FormField } from '@shared/types'
import { useI18n } from 'vue-i18n'
import BackToMyCourseForm from '@domains/SectionManagement/features/NewRequest/ui/BackToMyCourseForm.vue'
import { SectionManagementRequestCourseGetResponse } from '@shared/swagger'
import { useAlert } from '@shared/composable'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import MyCoursesCourseDetail from '@domains/SectionManagement/features/NewRequest/ui/MyCoursesCourseDetail.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const { t } = useI18n()
const { error, success } = useAlert()

const props = defineProps<{
  course?: SectionManagementRequestCourseGetResponse[0];
  note: string
}>()

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'update:isPostOffer'): void
  (event: 'on:change', name: string, newValue: string): void
  (event: 'on:submit'): void
}>()

const newRequestStore = useNewRequest()

const formFields = computed<Array<FormField>>(() => [
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.my-courses-note')}`,
      class: 'fs-6 fw-semibold mb-2'
    },
    name: 'note',
    type: 'textarea',
    value: props.note || ''
  }
])

const onChange = ({ name, newValue }) => {
  emit('on:change', name as string, newValue as string)
}

async function onSubmit () {
  if (!props.course) return
  const { status, message } = await newRequestStore.sendPostOffer({
    course_code: props.course.code,
    year: Number(route.query.year) || NaN,
    note: props.note
  })
  if (status) {
    success({ text: message, isToast: true })
    await newRequestStore.fetchRequestCourses(
      {
        request_by: 'search',
        program_code: newRequestStore.selectedProgram?.code,
        program_year: newRequestStore.selectedProgram?.active_year
      }
    )
  } else {
    error({ text: message })
  }
  updatePostOffer()
}

function updatePostOffer () {
  emit('update:isPostOffer')
}
</script>
