import { useRoute } from 'vue-router'
import { defineComponent, nextTick, onBeforeMount, onMounted, watch } from 'vue'

import { useStudent } from '@shared/stores'
import { useToken, useWindow } from '@shared/composable'
import { reinitializeComponents, LayoutUtils } from '@shared-utils/pattern'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DefaultLayout',
  components: {

  },
  setup () {
    const { isMobile } = useWindow()
    const { t } = useI18n()
    const { isStudentExpired, clearStudentTokens } = useToken()
    const route = useRoute()
    const user = useStudent()

    if (isStudentExpired()) {
      clearStudentTokens()
    }

    user.fetchStudent()

    onBeforeMount(() => {
      LayoutUtils.init()
    })

    onMounted(() => {
      nextTick(() => reinitializeComponents())
    })

    watch(
      () => route.path,
      () => { nextTick(() => reinitializeComponents()) }
    )

    return () => (
      <>
        {
          !isMobile.value && (
            <div class="d-flex flex-column flex-center flex-column-fluid">
              <pms-card header={false} class="w-450px">
                <p class="text-center fs-4 fw-bold m-0">
                  { t('phrases.please-switch-to-mobile-version') }
                </p>
                <p class="text-center fs-6 text-muted m-0">
                  { t('phrases.please-switch-to-mobile-version') }
                </p>
              </pms-card>
            </div>
          )
        }
        {
          isMobile.value && (
            <div class="app-wrapper  flex-column flex-row-fluid" id="mobile_wrapper">
              <div class="app-main flex-column flex-row-fluid" id="mobile_main">
                <div class="d-flex flex-column flex-column-fluid">
                  <div class="app-content flex-column-fluid" id="mobile_content">
                    <div class="app-container container-fluid" id="mobile_container">
                      <router-view />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    )
  }
})
