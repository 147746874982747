import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { RouteParams } from '../../types'
import { getDepartmentId } from '../../utils'

export default async function bootstrapByPage (this: StoreType, page: string, params: RouteParams): Promise<void> {
  const resource = useResource()
  const { code, year, term } = params

  const department_id = getDepartmentId(
    resource.getAcademicSingleCoursesResourceInformation?.department_code || '',
    resource.getAcademicResourceDepartments
  )

  switch (page) {
    case 'students':
      await Promise.all([
        this.fetchStudents({ section_id: this.currentSection.section_id })
      ])
      break
    case 'exam-dates':
      await Promise.all([
        this.fetchExamDates({ year, term, department_id, course_code: code })
      ])
      break
    case 'consent-requests': {
      await Promise.all([
        this.fetchConsentRequests({ section_id: this.currentSection.section_id })
      ])
      break
    }
  }
}
