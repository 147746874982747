<template>
  <div class="d-flex align-items-center">
    <div
      class="symbol symbol-40px me-3"
      @mouseenter="onImageMouseEnter($event, student)"
      @mouseleave="onImageMouseLeave()"
    >
      <img
        :src="student.student_photo ? `${student.student_photo}` : '/user.png'"
        class="rounded"
        :alt="`${student.student_name} ${student.student_surname}`"
        style="object-fit: cover; cursor: pointer;"
      >
    </div>
    <div
      class="d-flex justify-content-start flex-column"
    >
      <p class="text-gray-800 fw-bold text-hover-primary mb-0 fs-6">
        {{ student.student_name }} {{ student.student_surname }}
      </p>
      <span class="text-gray-400 fw-semibold d-block fs-6">
        {{ student.student_id }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
defineProps({
  student: {
    type: Object,
    required: true
  }
})

const onImageMouseEnter = (event, row) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const image = document.querySelector('.student-image') as any

  if (tooltip && image) {
    image.src = row.student_photo ? `${row.student_photo}` : '/user.png'
    tooltip.style.display = 'block'
    tooltip.style.top = `${event.pageY}px`
    tooltip.style.left = `${event.pageX}px`
  }
}

const onImageMouseLeave = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any

  if (tooltip) {
    tooltip.style.display = 'none'
  }
}
</script>
