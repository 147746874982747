import type { StoreType } from '../types'
import type { AcademicManageCoursesDescriptionPostRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { postAcademicManageCourseDescription } from '@shared/api'

export async function manage_description (this: StoreType, values: AcademicManageCoursesDescriptionPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postAcademicManageCourseDescription(values)

  return {
    status: ok,
    message
  }
}
