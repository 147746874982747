<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    :breadcrumbs="[
      {
        text: 'Search courses',
        route: {
          name: 'AcademicCoursesPage'
        }
      },
      {
        text:page_params.course_code,
        route: {
          name: 'AcademicManageCoursesEditPage',
          params: {
            code: page_params.course_code,
            year: page_params.course_year
          }
        }
      },
      {
        text:page_params.requisite_type
      }
    ]
    "
    @launch="launch"
  >
    <template #action>
      <pms-feedback-modal :options="feedback" />
    </template>
  </pms-page-toolbar>
  <pms-page-tour
    :launched="status"
    :steps="steps"
    @launch="launch"
  />

  <pms-container v-if="loader">
    <pms-page-shimmer
      :shimmer="'card'"
    />
  </pms-container>

  <pms-container
    v-else
    class="mt-6"
  >
    <div class="card">
      <div class="card-body">
        <pms-row class="mt-6">
          <pms-grid
            :order-mb="1"
            class="mt-3 mt-sm-0"
            :col="12"
            :col-lg="6"
          >
            <requisite-expression />
          </pms-grid>
          <pms-grid
            class="mt-3 mt-lg-0"
            :col="12"
            :col-lg="6"
          >
            <search-course />
          </pms-grid>
        </pms-row>
      </div>
    </div>
    <pms-card
      v-if="requisite_info_response.additional_info && requisite_info_response.additional_info?.length>0"
      title="domains.academic.manage.course.requisite.additional_info_table.title"
      class="mt-6"
    >
      <pms-smart-table
        :options="additional_info_table_options"
        :headers="additional_info_table_headers"
        :items="requisite_info_response.additional_info"
      >
        <template #program_code="{item}">
          {{ item.program_code ?? '-' }}
        </template>
        <template #speciality="{item}">
          {{ item.speciality ?? $t('common.all') }}
        </template>
      </pms-smart-table>
    </pms-card>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'

import { useLoader, useToolbar, useTour } from '@/shared/composable'

import { header, tour, feedback, additional_info_table_headers, additional_info_table_options } from './values'

import RequisiteExpression from './ui/RequisiteExpression.vue'
import SearchCourse from './ui/SearchCourse.vue'

import { useManageCourses } from '../../store'
import { storeToRefs } from 'pinia'

const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])

const loader = useLoader().isActiveOneOf(['academic/manage/courses/requisites/items/search', 'academic/manage/courses/requisites'])

const { requisite_info_response, page_params } = storeToRefs(useManageCourses())

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})

</script>
