import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: []
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  next()
})

export const vueRouter = (routes: Array<RouteRecordRaw>): Router => {
  routes.forEach((route: RouteRecordRaw) => router.addRoute(route))
  return router
}
