<template>
  <div class="fw-semibold text-muted d-flex fs-6">
    <p class="pe-5 mb-1">
      {{ $t('domains.knowledge.all-posts.category') }}: {{ props.singleKnowledgeBase.category_title }}
    </p>
    <p class="pe-5 mb-1">
      {{ $t('domains.knowledge.all-posts.views') }}: {{ props.singleKnowledgeBase.views }}
    </p>
    <p class="pe-5 mb-1">
      {{ $t('domains.knowledge.all-posts.posted-at') }}: {{ dateFormatter(props.singleKnowledgeBase.created_at) }}
    </p>
  </div>
  <p
    class="fs-5 mb-1"
    v-html="props.singleKnowledgeBase.description"
  />
</template>
<script setup lang="ts">
import { KnowledgeBaseSingleResponse } from '@shared/swagger'
import { dateFormatter } from '@shared/utils'

const props = defineProps<{
  singleKnowledgeBase: KnowledgeBaseSingleResponse
}>()
</script>
