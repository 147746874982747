/* eslint-disable @typescript-eslint/no-explicit-any */
import { AcademicSingleStudentAttendanceDetailsGetResponse } from '@/shared/swagger'
import { AttendanceDetailsEndpoint, AttendanceDetailsGraph, SectionAttendanceRecord } from '../types'
import { arrayGroupByProperty, camelCaseToLowerKebabCase } from '@/shared/utils'

export const formatAttendanceDetailsEndpoint = (data: AcademicSingleStudentAttendanceDetailsGetResponse): AttendanceDetailsEndpoint => {
  return {
    year: data.year ? data.year : 0,
    term: data.term ? data.term : 0,
    courseCode: data.course_code ? data.course_code : '',
    courseTitle: data.course_title ? data.course_title : '',
    sections: data.sections.map((section) => ({ type: section.type.id, records: section.records }))
  }
}
export const formatAttendanceDetailsForGraphs = (data: AttendanceDetailsEndpoint): AttendanceDetailsGraph => {
  const items = ['N', 'P', 'L']
  const result: Array<any> = []

  for (const item of items) {
    const sectionRecords = data.sections.find(el => el.type === item)?.records || []
    const groupData = arrayGroupByProperty<SectionAttendanceRecord>(sectionRecords, 'hour')
    const hours: Array<any> = []

    for (const [key, values] of Object.entries(groupData)) {
      let data = values.map((item: SectionAttendanceRecord) => ({ ...item, track: item.attendance }))
      let present = 0
      let absent = 0
      let permitted = 0
      let notTracked = 0

      data.forEach((item) => {
        if (item.attendance?.attend === 0) {
          absent++
        } else if (item.attendance?.attend === 1) {
          present++
        } else if (item.attendance?.attend === 2) {
          permitted++
        } else {
          notTracked++
        }
      })

      data = data.sort((a: any, b: any) => a.date - b.date).reverse()
      hours.push({ hour: key, data, series: [present, absent, permitted, notTracked] })
    }

    let hourTabs = hours.map((item) => {
      const [hour, minute] = item.hour.split(':')
      return {
        id: item.hour,
        label: `${hour}:${minute}`
      }
    })

    hourTabs = hourTabs.sort((a, b) => b.id - a.id)

    result.push({
      title: `common.course-types.${camelCaseToLowerKebabCase(item)}`,
      room: sectionRecords[0] ? sectionRecords[0].room : '',
      showTable: false,
      activeHour: hours.length > 0 ? hours[0].hour : null,
      hourTabs,
      hours: hours
    })
  }

  return {
    title: data.courseTitle,
    code: data.courseCode,
    items: result
  }
}
