import type { ProfileResourceResponse } from '@shared/swagger'
import type { SelectField } from '@shared/types'

export default (genders: ProfileResourceResponse['genders']): SelectField['options'] => {
  const options: SelectField['options'] = []

  genders.forEach((gen) => {
    options.push({
      label: gen.gender,
      value: gen.id
    })
  })
  return options
}
