import { ref } from 'vue'
import { SelectTypes } from './enums'

export const selectedType = ref<SelectTypes>(SelectTypes.notSelected)

export const filterValues = ref({
  term: null,
  courseType: null,
  electiveType: null,
  courseList: null
})
