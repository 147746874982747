/* eslint-disable vue/one-component-per-file */
import { PropType, defineComponent } from 'vue'

export const BaseShimmer = defineComponent({
  name: 'BaseShimmer',
  setup (_, { slots }) {
    return () => (
      <div class="card is-loading min-w-100 shimmer">
        <div class="card-body mt-2">
          { slots.default ? slots.default() : null }
        </div>
      </div>
    )
  }
})

const Shimmer = defineComponent({
  name: 'ShimmerComponent',
  props: {
    shimmer: {
      type: String as PropType<'list' | 'card' | 'table' | 'text' | 'cards'>,
      default: 'list'
    },
    items: {
      type: Number,
      default: 6
    },
    filter: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    return () => (
      <>
        <pms-grid col={props.fullWidth ? 12 : 8 }>
          {
            props.shimmer === 'cards' && (
              <div class="row">
                {
                  [...Array(5).keys()].map(() => {
                    return (
                      <div class="col-12 col-md-4 mb-3">
                        <div class="card is-loading min-w-100 shimmer">
                          <div class="card-body">
                            <h2 style="height: 100px; width: 100%; margin-bottom: 7px" />
                            <h2 style="height: 15px; width: 30%; margin-bottom: 7px" />
                            {
                              [...Array(3).keys()].map(() => {
                                return (<h2 style="height: 10px; width: 100%; margin-bottom: 8px" />)
                              })
                            }
                            <h2 style="height: 10px; width: 80%;" />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
          { props.shimmer !== 'cards' && (
            <div class="card is-loading min-w-100 shimmer">
              <div class="card-body mt-2">
                {
                  props.shimmer === 'list' && (
                    [...Array(props.items).keys()].map(() => {
                      return (<h2 style="height: 30px; width: 100%; margin-bottom: 10px" />)
                    })
                  )
                }
                {
                  props.shimmer === 'card' && (
                    <>
                      <h2 style="height: 30px; width: 50%; margin-bottom: 10px" />
                      <h2 style="height: 15px; width: 30%; margin-bottom: 15px" />
                      <h2 style="height: 200px; width: 100%; margin-bottom: 20px" />
                      {
                        [...Array(3).keys()].map(() => {
                          return (<h2 style="height: 20px; width: 100%; margin-bottom: 10px" />)
                        })
                      }
                      <h2 style="height: 20px; width: 80%; margin-bottom: 10px" />
                    </>
                  )
                }
                {
                  props.shimmer === 'table' && (
                    <>
                      <h2 style="height: 40px; width: 100%; margin-bottom: 15px" />
                      {
                        [...Array(props.items).keys()].map(() => {
                          return (<h2 style="height: 23px; width: 100%; margin-bottom: 10px" />)
                        })
                      }
                    </>
                  )
                }
                {
                  props.shimmer === 'text' && (
                    <>
                      <h2 style="height: 30px; width: 50%; margin-bottom: 10px" />
                      <h2 style="height: 15px; width: 30%; margin-bottom: 15px" />
                      {
                        [...Array(props.items).keys()].map(() => {
                          return (<h2 style="height: 20px; width: 100%; margin-bottom: 10px" />)
                        })
                      }
                      <h2 style="height: 20px; width: 80%; margin-bottom: 10px" />
                    </>
                  )
                }
              </div>
            </div>
          )}
        </pms-grid>
        {
          props.filter && !props.fullWidth && (
            <pms-grid col={4}>
              <div class="card is-loading min-w-100 shimmer">
                <div class="card-body mt-2">
                  {
                    [...Array(4).keys()].map(() => {
                      return (<h2 style="height: 30px; width: 100%; margin-bottom: 10px" />)
                    })
                  }
                </div>
              </div>
            </pms-grid>
          )
        }
      </>
    )
  }
})

export default Shimmer
