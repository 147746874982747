import type { StoreType } from '../types'
import type { AcademicManageCurriculumLearningOutcomeRequest } from '@/shared/swagger'

import api from '@/shared/api/AcademicCurricula/fetchCurriculumOutcome'

export default async function fetchCurriculumOutcome (this: StoreType, params: AcademicManageCurriculumLearningOutcomeRequest): Promise<void> {
  const { data, ok } = await api(params)

  if (ok) {
    this.setCurriculumOutcome(data)
  }
}
