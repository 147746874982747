/* eslint-disable camelcase */
import type { StoreType } from '../types'
import { getAdministrativeSystemCalendar } from '@shared/api'
import { ResourceResponse } from '@shared/swagger'

export default async function fetchSystemCalendar (this: StoreType, yearTerm: ResourceResponse['year_terms'][0]): Promise<void> {
  const { data, ok } = await getAdministrativeSystemCalendar({ year: yearTerm.year, term: yearTerm.term })

  if (ok) {
    const { course_permissions, individual_permissions, events } = data

    this.setEvents(events)
    this.setIndividualPermissions(individual_permissions)
    this.setCoursePermissions(course_permissions)
  }
}
