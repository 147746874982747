<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <PermissionCheck
      v-else
      :permission-code="permissionCodes.show_courses.code"
    >
      <overview-info
        id="single-course-overview"
      />
    </PermissionCheck>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'
import { quickTourOverview } from '../../values'

import OverviewInfo from './ui/OverviewInfo.vue'

import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'
const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('academic/courses/overview')

onMounted(async () => {
  setQuickTourSteps(await quickTourOverview())
})
</script>
