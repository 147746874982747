<template>
  <div
    id="avatar_modal"
    ref="modalRef"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex d-flex justify-content-between mb-2">
            <div>
              <div
                class="btn-group"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.zoom(0.1)"
                >
                  <i class="fas fa-search-plus" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.zoom(-0.1)"
                >
                  <i class="fas fa-search-minus" />
                </button>
              </div>
              <div
                v-styler="{ marginLeft: '3px' }"
                class="btn-group"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.rotate(45)"
                >
                  <i class="fas fa-rotate" />
                </button>
              </div>
              <div
                v-styler="{ marginLeft: '3px' }"
                class="btn-group"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.move(-10, 0)"
                >
                  <i class="fas fa-arrow-left" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.move(10, 0)"
                >
                  <i class="fas fa-arrow-right" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.move(0, -10)"
                >
                  <i class="fas fa-arrow-up" />
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-icon btn-primary"
                  @click="vueCropperRef?.move(0, 10)"
                >
                  <i class="fas fa-arrow-down" />
                </button>
              </div>
            </div>
            <div>
              <input
                ref="fileInputRef"
                type="file"
                accept="image/*"
                style="display: none"
                @change="onFileSelect"
              >
              <button
                type="button"
                class="btn btn-sm btn-light-primary me-2"
                @click="reset()"
              >
                {{ $t('common.reset') }}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-info"
                @click="fileInputRef.click()"
              >
                {{ $t('common.upload') }}
              </button>
            </div>
          </div>
          <div class="row">
            <div
              id="cropperDiv"
              class="col-12"
            >
              <vue-cropper
                ref="vueCropperRef"
                :src="imageSrc"
                v-bind="cropperOptions"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end mb-0 mt-4">
            <button
              class="btn btn-light btn-sm btn-active-light-primary me-2"
              data-bs-dismiss="modal"
              type="button"
              @click="reset()"
            >
              {{ $t('common.discard') }}
            </button>
            <button
              class="btn btn-primary btn-sm"
              type="button"
              @click="submitAvatar()"
            >
              {{ !loader ? $t('phrases.save-changes') : $t('phrases.saving') }}
              <span
                v-if="loader"
                class="spinner-border spinner-border-sm align-middle ms-2"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useModal, useLoader } from '@/shared/composable'

import VueCropper from '@ballcat/vue-cropper'
import type Cropper from 'cropperjs'

import 'cropperjs/dist/cropper.css'

export default defineComponent({
  components: {
    VueCropper
  },
  props: {
    avatar: {
      type: String,
      required: true
    },
    cropperWidth: {
      type: Number,
      default: 755
    }
  },
  emits: ['uploadAvatar'],
  setup (props, { emit }) {
    const { isActive } = useLoader()
    const { close } = useModal()

    const modalRef = ref(null)
    const loader = isActive('upload:avatar')
    const vueCropperRef = ref()
    const imageSrc = ref(props.avatar)
    const fileInputRef = ref()

    const cropperOptions: Cropper.Options = reactive({
      aspectRatio: 1 / 1,
      viewMode: 0,
      minContainerWidth: props.cropperWidth,
      minContainerHeight: 400,
      responsive: true,
      restore: true,
      checkCrossOrigin: true,
      checkOrientation: true,
      modal: true,
      guides: true,
      center: true,
      highlight: true,
      background: true,
      autoCrop: true,
      movable: true,
      rotatable: true,
      scalable: true,
      zoomable: true,
      zoomOnTouch: true,
      zoomOnWheel: true,
      cropBoxMovable: true,
      cropBoxResizable: true
    })

    const onFileSelect = (event) => {
      const files = event.target.files
      if (files && files.length) {
        const file = files[0]
        if (/^image\/\w+/.test(file.type)) {
          imageSrc.value = URL.createObjectURL(file)
          cropperOptions.minContainerHeight = 400
          fileInputRef.value.value = null
        } else {
          window.alert('Please choose an image file.')
        }
      }
    }

    const reset = () => {
      imageSrc.value = props.avatar
      cropperOptions.minContainerHeight = 400
    }

    const submitAvatar = () => {
      const image = vueCropperRef.value?.getCroppedCanvas().toDataURL()
      emit('uploadAvatar', {
        photo: image,
        callback: () => {
          console.log(modalRef.value)
          close(modalRef.value)
        }
      })
    }

    return {
      reset,
      onFileSelect,
      submitAvatar,
      loader,
      modalRef,
      vueCropperRef,
      fileInputRef,
      cropperOptions,
      imageSrc
    }
  },
  watch: {
    avatar (val) {
      this.imageSrc = val
    },
    cropperWidth (val) {
      this.cropperOptions.minContainerWidth = val
    }
  }
})
</script>
