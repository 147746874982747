import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'
import * as actions from './actions'
import * as getters from './getters'

export const useKnowledgeBaseSingle = defineStore<Name, State, Getters, Actions>('KnowledgeBase.useKnowledgeBaseSingle', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      activeContent_uuid: null,
      contents: [],
      singleKnowledgeBase: null
    }
  }
})
