<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          class="mt-3"
          v-bind="header"
          :no-mobile="true"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex align-items-center gap-2">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>

    <pms-row v-if="knowledgeBaseLoader || resourceLoader">
      <pms-page-shimmer
        :shimmer="`text`"
        :filter="true"
      />
    </pms-row>

    <pms-row v-else>
      <pms-grid
        :col="8"
        :order-mb="1"
        class="mt-3 mt-md-0"
      >
        <pms-card :header="false">
          <div
            v-if="statistics.length > 0"
            id="knowledge-list-statistics"
            class="d-flex flex-wrap"
          >
            <template
              v-for="(stat, index) in statistics"
              :key="index"
            >
              <StatisticsCard
                :title="stat.title"
                :amount="stat.count"
              />
            </template>
          </div>
          <div id="knowledge-list-latest-posts">
            <KnowledgeBaseListingTitle />
            <template
              v-for="(oneKnowledgeBase, index) in knowledgeBase"
              :key="oneKnowledgeBase.uuid"
            >
              <KnowledgeBasePost
                :class="{ 'mt-4': index !== 0 }"
                :knowledge-base-single-post="oneKnowledgeBase"
              />
            </template>
            <pms-no-data v-if="knowledgeBase.length === 0" />
          </div>
          <pms-pagination
            id="knowledge-list-pagination"
            class="mt-6"
          />
        </pms-card>
      </pms-grid>
      <pms-grid
        :col="4"
        class="order-first order-md-last"
      >
        <SearchBar
          id="knowledge-list-search-bar"
        />
        <PopularContent
          id="knowledge-list-popular-posts"
          class="mt-3 d-none d-md-block"
        />
        <CategoryContent
          id="knowledge-list-category"
          class="mt-3 d-none d-md-block"
        />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useKnowledgeBase } from './store'
import { useResource } from '@shared/stores'
import { useLoader, useTour, useDriver } from '@shared/composable'

import StatisticsCard from './ui/StatisticsCard.vue'
import SearchBar from './ui/SearchBar.vue'
import PopularContent from './ui/PopularContent.vue'
import CategoryContent from './ui/CategoryContent.vue'
import KnowledgeBasePost from './ui/KnowledgeBasePost.vue'
import KnowledgeBaseListingTitle from './ui/KnowledgeBaseListingTitle.vue'

import { header, tour, quickTour } from './values'

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const knowledgeBaseLoader = isActive('knowledge/posts')
const resourceLoader = isActive('knowledge/resources')

const resource = useResource()
const store = useKnowledgeBase()

const statistics = computed(() => resource.getKnowledgeResourceStatistics)
const knowledgeBase = computed(() => store.getKnowledgeBase)

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTour())
})
</script>
