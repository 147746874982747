import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { RouteParams } from '../../types'

export default async function bootstrap (this: StoreType, params: RouteParams): Promise<void> {
  const resource = useResource()
  const { year, term } = params

  await Promise.all([
    this.setCurrentYearTerm({ year: parseInt(year + ''), term: parseInt(term + ''), id: 0 }),
    resource.fetchResource({ modules: ['year-terms'] })
  ])
}
