<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'card'"
    :filter="true"
    :full-width="true"
  />

  <div class="card">
    <div class="card-body">
      <pms-button
        variant="outlined"
        @click="goBack"
      >
        <i class="bi bi-arrow-left" />
        {{ $t('common.back') }}
      </pms-button>
      <div class="text-center">
        <p class="fs-3 fw-bold m-0">
          {{ current_course.course_code }}. {{ current_course.course_title }}
        </p>
      </div>

      <choose-section-time v-if="!router.currentRoute.value.query.section" />
      <pms-loader
        v-if="loading"
      />
      <div
        v-else
        class="d-flex align-items-center justify-content-center mt-5"
      >
        <canvas id="qr-canvas" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { router } from '@/router'
import { useLoader } from '@shared/composable'
import { current_course } from '@/domains/AcademicSingleCourse/values'
import { onMounted, onUnmounted } from 'vue'
import { useAttendanceQR } from './composable'
import ChooseSectionTime from './ui/ChooseSectionTime.vue'
const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf([
  'academic/courses/attendances/by-courses'
])

const {
  mount,
  unmount
} = useAttendanceQR()

const loading = useLoader().isActiveOneOf(['academic/courses/attendance/qr'])

function goBack () {
  router.back()
}
onMounted(mount)
onUnmounted(unmount)
</script>
