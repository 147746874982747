/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, computed } from 'vue'
import QrScanner from 'qr-scanner'
import { useEncrypt } from '@shared/composable'

const grantedGeoLocation = ref(false)
const grantedCamera = ref(false)
const geoPosition = ref<null | GeolocationPosition>(null)
const qrScanner = ref<null | any>(null)
const qrReaderStarted = computed(() => grantedCamera.value)
const message = ref('')
const qrData = ref<unknown>(null)
const modalState = ref(false)

export const useAttendance = () => {
  const { decrypt } = useEncrypt()

  const askForGrant = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((item) => {
        geoPosition.value = item
        grantedGeoLocation.value = true
      })
    } else {
      message.value = 'Geolocation is not supported by this browser.'
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          grantedCamera.value = true
        })
    } else {
      message.value = 'Camera is not supported by this browser.'
    }
  }

  const mountQrScanner = () => {
    const videoElem = document.getElementById('camera-video-here') as HTMLVideoElement

    qrScanner.value = new QrScanner(
      videoElem,
      (result) => {
        console.log('decoded qr code:', result)
        // Test
        const testResult = {
          ct: 'AS5qXEqOpNKLgghiBjqSgFlsJMh6pbFkPVvwX8R4I/uBRDgEhT4r93geBbXItrGg1PPoLuK9UqXgiJoCCSMAl8lBaJUs2Hk65s356JCEZCLBFF042OostovTtBahb6iwjGoZJFH8lkAS/ht2mkzCG5V2M6HEqXS4eAd41NRdSErj7orCoYux8LaaykEGf/Bu8QQyRG2npx023TbZwaPht41/V7m21xkiiuzR+3iH/228OBg9mqpn6B/QldWyRiv2Btyix30kRFnb+uahin/REopNPFWyf6bhj56YgRDzgx/i+dnWzrARnm3a8xAZ3/l7r3U16/WsALoxPcKyDGx1XsKVkRJ+X0govaWk1Q/EmgBw8ESx8kqG9CCsoyR/JUF9Pmky5E9s0s3fVTgYGXD90qduT+yqDaVIzA3Ch3W/hTzJabBP7oLqjxrU1pGLzUWKvMGs3BNDM7fdAhxzmQT/zOsoVskQaZDt12YkEk0DLDUgpwm1M4it7oUTdwURidXCIK9W71J0fYSaT3PzacKHcSoqDN2oJf/dfAfStG/c3BUHR5sqCanNZlqpKMRk3ujft3GX53ntOt+p1Sre+1nFQlqg7CFf1Y6GuvC+luVgFOiChiZcolSH0tQMa4zlmFkIrj1gC11bV2/qbT40FArzo/aHt/1vc4BWeAd0kVgaGQ2yXiZ1ohO2zojgx2hN1RfyKxm1dqBink4yDn8VwcZH3PpThtrfZ6qUaTr9mQeQDVG6PHqIAsM3Vab+MYx/WGXI1PLjTd0Vhjzs6yVpf+5INNHasNQt0Ixe6GrjxfppePKklxRNH7S1PfKbb8stn3zd/X09csUTGB/nSiB63ZcsJ0NSAEbGQ5d8DCSCNlC4E4M9eG2Lzo2YqoDt30JTat9I6anQ6GSn0llmbi52R02OpQ==',
          iv: 'd316f8fa2c7ad7012837d2074ed0e92f',
          s: '5d95053dfece426c'
        }
        const decrypted = decrypt(JSON.stringify(testResult))
        qrData.value = decrypted
        modalState.value = true
        stopQrScanner()
      },
      // eslint-disable-next-line node/handle-callback-err
      () => {
        // console.log('error', error)
      }
    )

    qrScanner.value.start()
  }

  const stopQrScanner = () => {
    const videoElem = document.getElementById('camera-video-here') as HTMLVideoElement

    navigator.mediaDevices.getUserMedia({ video: true })
      .then(mediaStream => {
        const tracks = mediaStream.getTracks()
        tracks.forEach((track) => track.stop())
      })
    qrScanner.value.stop()
    videoElem.pause()
  }

  const cancelAttendance = () => {
    modalState.value = false
    grantedCamera.value = false
  }

  const acceptAttendance = () => {
    console.log('acceptAttendance')
  }

  return {
    qrReaderStarted,
    grantedGeoLocation,
    grantedCamera,
    message,
    modalState,
    qrData,
    askForGrant,
    mountQrScanner,
    stopQrScanner,
    cancelAttendance,
    acceptAttendance
  }
}

export default useAttendance
