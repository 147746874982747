<template>
  <div class="w-100 d-flex flex-row justify-content-between align-items-center">
    <div>
      <pms-button
        :onclick="printSchdule"
        class="w-150px m-3"
        :disabled="loader"
      >
        {{ $t('common.print') }}
      </pms-button>
      <pms-button
        v-if="view == 'bulk'"
        :onclick="excelDownload"
        class="w-150px m-3"
        :disabled="loader"
      >
        {{ $t('common.excel') }}
      </pms-button>
    </div>
    <div>
      <label class="me-2">{{ $t('common.details') }}</label>
      <Checkbox
        v-model="details"
        :binary="true"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useLoader } from '@/shared/composable'
import { view, details } from '../values'
import { printSchdule, excelDownload } from '../utils'
import Checkbox from 'primevue/checkbox'

const { isActive } = useLoader()

const loader = isActive('academic/information/schedules')
</script>
