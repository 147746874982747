import type { StoreType } from '../types'

import { useResource } from '@/shared/stores'

export default async function bootstrap (this: StoreType, uuid: string): Promise<void> {
  const resource = useResource()

  await Promise.all([
    this.fetchKnowledgeBaseSingle(uuid),
    resource.fetchKnowledgeResource({ modules: [`related:${uuid}`], limit: -1 })
  ])
}
