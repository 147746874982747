import type { StoreType } from '../types'
import { SectionManagementResourceGetResponse } from '@shared/swagger'

function isProgram (value: unknown): value is SectionManagementResourceGetResponse['programs'][0] {
  return typeof value === 'object' &&
    value != null &&
    'code' in value &&
    'terms_count' in value
}

export default function setSelectProgram (this: StoreType, program: SectionManagementResourceGetResponse['programs'][0]): void {
  if (!isProgram(program)) return
  this.selectedProgram = program
}
