import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, KnowledgeBaseRequest, KnowledgeBaseResponse } from '@shared/swagger'

export default async function getKnowledgeBaseListing (params: KnowledgeBaseRequest): Promise<AxiosResponse<KnowledgeBaseResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeBaseResponse>({
    url: 'knowledge/posts',
    body: { params: params },
    loader: 'knowledge/posts'
  })

  return response
}
