import { ref } from 'vue'

export const cropperWidth = ref(755)

const getDeviceAdoptedWidth = () => {
  const width = window.innerWidth
  if (width > 768) return 755
  if (width > 576) return 400
  return 300
}

export const getCropperWidth = () => {
  cropperWidth.value = document.getElementById('cropperDiv')?.clientWidth || getDeviceAdoptedWidth()
}
