import { useAlert } from '@/shared/composable'
import type { StoreType } from '../types'
import { getAcademicSingleStudentGrades } from '@shared/api'

export default async function fetchGrades (this: StoreType, id: string): Promise<void> {
  const { error } = useAlert()
  const { data, ok, message } = await getAcademicSingleStudentGrades(id)

  if (ok) {
    this.setGrades(data)
  } else {
    error({ text: message, isToast: true })
  }
}
