<template>
  <pms-card
    :title="'common.search'"
    :description="$t('domains.academic.exam-dates.search-description', { year: currentYearTerm.year, term: currentYearTerm.term })"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #department_id="{ setValue }">
        <label
          class="mb-1 mt-2 required"
        >{{ $t('common.select-department') }}:</label>
        <treeselect
          :multiple="false"
          :options="departmentOptions"
          :placeholder="t('common.select-department')"
          class="styled"
          @select="(value)=>{
            setValue(value.id)
          }"
        />
      </template>
      <template #formControls>
        <div class="form-group mt-5 d-flex justify-content-end">
          <pms-button
            type="submit"
            :disabled="loader"
            :loader="searchLoader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Treeselect from 'vue3-treeselect'
import UseExamDatesSearch from './composable/UseExamDatesSearch/UseExamDatesSearch'

const { t } = useI18n()

const {
  formFields,
  searchLoader,
  onSubmit,
  loader,
  departmentOptions,
  currentYearTerm
} = UseExamDatesSearch()
</script>
