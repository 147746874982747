import { AttendanceChartOptions } from '../types'
import { ref } from 'vue'

export const attendanceGraphOptions = ref<AttendanceChartOptions>({
  chart: {
    fontFamily: 'inherit',
    type: 'area',
    toolbar: { show: false }
  },
  legend: {
    show: false
  },
  dataLabels: {
    enabled: false
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0,
      stops: [0, 80, 100]
    }
  },
  stroke: {
    curve: 'smooth',
    show: true,
    width: 3,
    colors: ['#50cd89', '#f1416c', '#7239ea']
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    tickAmount: 15,
    labels: {
      rotate: 0,
      rotateAlways: true,
      style: {
        colors: 'var(--bs-gray-800)',
        fontSize: '12px',
        fontWeight: '600',
        align: 'center'
      },
      formatter: (value) => {
        return String(value)
      }
    },
    crosshairs: {
      position: 'front',
      stroke: {
        color: '#009ef7',
        width: 1,
        dashArray: 3
      }
    },
    tooltip: {
      enabled: true,
      offsetY: 0,
      style: {
        fontSize: '12px'
      }
    },
    convertedCatToNumeric: true
  },
  yaxis: {
    title: { text: '' }
  },
  colors: ['#50cd89', '#f1416c', '#7239ea']
})

export const gradesGraphOptions = ref({
  chart: {
    fontFamily: 'inherit',
    type: 'bar',
    height: 350,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      horizontal: true,
      distributed: true,
      barHeight: '60%',
      dataLabels: {
        position: 'center'
      }
    }
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    offsetX: 0,
    formatter: function (val) {
      return val
    },
    style: {
      fontSize: '14px',
      fontWeight: '600',
      align: 'left'
    }
  },
  legend: {
    show: false
  },
  colors: ['#3E97FF', '#3E97FF', '#3E97FF', '#3E97FF', '#3E97FF'],
  xaxis: {
    categories: [''],
    labels: {
      formatter: function (val) {
        return val
      },
      style: {
        colors: ['var(--bs-gray-800)'],
        fontSize: '14px',
        fontWeight: '600',
        align: 'left'
      }
    },
    axisBorder: {
      show: false
    }
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val
      },
      style: {
        colors: 'var(--bs-gray-800)',
        fontSize: '14px',
        fontWeight: '600'
      },
      offsetY: 2,
      align: 'left'
    }
  },
  grid: {
    borderColor: 'rgba(0,0,0,0.2)',
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    strokeDashArray: 4
  },
  tooltip: {
    style: {
      fontSize: '14px'
    },
    y: {
      formatter: function (val) {
        return val + ' students'
      }
    }
  }
})
