import type { StoreType } from '../types'
import type { SectionManagementRequestCourseGetRequest } from '@shared/swagger'

import { getSectionManagementRequestCourses } from '@shared/api'

export default async function fetchSectionsCourses (this: StoreType, values: SectionManagementRequestCourseGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequestCourses(values)

  if (ok) {
    this.setSectionsCourses(data)
  } else {
    this.sectionsCourses = []
  }
}
