import type { StoreType } from '../types'

export default function getSelectedProgramsTerms (this: StoreType): number[] {
  const terms: number[] = []
  const termsCount = this.selectedProgram?.terms_count || 0

  for (let i = 1; i <= termsCount; i++) {
    terms.push(i)
  }

  return terms
}
