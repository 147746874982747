import type { AdministrativeStaffsResponse } from '@shared/swagger'

const generateHtml = (headers, rows) => {
  let tableRows = ''

  rows.forEach((row, index) => {
    tableRows += `
      <tr>
        <td>${index + 1}</td>
        <td>${row.name} ${row.surname}</td>
        <td>
          <div>
            ${row.department_positions.map(pos => `<span style="font-weight: ${pos.is_main ? 'bold' : 'normal'}">${pos.title}</span>`).join('<br>')}
          </div>
        </td>
        <td>${row.staff}</td>
        <td>${row.staff_status}</td>
        <td>${row.outer_email}</td>
        <td>${row.inner_phone === null ? ' ' : row.inner_phone}</td>
      </tr>
    `
  })

  return `
    <head>
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
      <style>
      .table td, .table th {
        border: 1px solid #000 !important;
      }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  ${headers.map((header) => `<th>${header}</th>`).join('')}
                </tr>
              </thead>
              <tbody>
                ${tableRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </body>
  `
}
export default (data: AdministrativeStaffsResponse['employees'], headers: Array<string>): void => {
  const myWindow: Window | null = window.open('', '', 'width=1200,height=600')

  if (myWindow) {
    myWindow.document.write(generateHtml(headers, data))
    myWindow.document.close()
    myWindow.focus()
    setTimeout(() => myWindow.print(), 100)
  }
}
