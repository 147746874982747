<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { objectDeepCopy } from '@shared/utils'
import { useI18n } from 'vue-i18n'
import { SyllabusFormMode } from '../types'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const store = useStore()
const { t } = useI18n()

const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['weekly_plan'][0] & SyllabusFormMode> = {
  id: -1,
  week_no: 0,
  activity: '',
  subject: '',
  count: 0,
  mode: 'add'
}

const tempValue = ref(objectDeepCopy(defaultValue))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.plan.subject')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'subject',
    value: tempValue.value.subject || ''
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.plan.activity')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'activity',
    value: tempValue.value.activity || ''
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if ((newValue.data as Record<string, unknown>).mode === 'edit') {
        const item = store.getWeeklyPlans.find((item) => item.id === (newValue.data as Record<string, unknown>)?.id)
        tempValue.value = { mode: (newValue.data as Record<string, unknown>).mode as 'edit' | 'add', ...(item || defaultValue) }
      }
      if ((newValue.data as Record<string, unknown>).mode === 'add' && (newValue.data as Record<string, unknown>).week_no) {
        tempValue.value = objectDeepCopy(defaultValue)
        tempValue.value.week_no = (newValue.data as Record<string, unknown>).week_no as number
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'edit') {
    store.editWeeklyPlan({
      subject: values.subject || '',
      activity: values.activity || ''
    }, tempValue.value.id)
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
  if (tempValue.value.mode === 'add') {
    store.addWeeklyPlan({
      subject: values.subject || '',
      activity: values.activity || '',
      week_no: tempValue.value.week_no
    })
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
}
</script>
