import { defineStore } from 'pinia'

import * as actions from './actions'
import * as getters from './getters'

import type { Actions, Getters, Name, State } from './types'

export default defineStore<Name, State, Getters, Actions>('shared.useStudent', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      user: null,
      isAuthenticated: false,
      events: []
    }
  }
})
