<template>
  <pms-dropdown
    :btn-class="'btn btn-light-primary btn-sm p-2 px-4'"
    :icon-placement="'start'"
    :placement="'start'"
  >
    <template
      v-for="(item, index) in dropdownItems"
      :key="index"
    >
      <div
        v-if="item.type === 'link'"
        class="menu-item w-175px"
      >
        <router-link
          :to="'/academic/course' + item.link + `?year=${currentYearTerm.year}&term=${currentYearTerm.term}&code=${course.code}&previous_page=AcademicCoursesMyCourses`"
          class="menu-link px-3 py-2"
        >
          <span
            class="menu-icon"
            data-kt-element="icon"
          >
            <i
              class="bi"
              :class="item.icon"
            />
          </span>
          <span class="menu-title">{{ $t(item.label) }}</span>
        </router-link>
      </div>
      <div
        v-if="item.type === 'separator'"
        class="separator my-1"
      />
    </template>
  </pms-dropdown>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useStore } from '../store'
import { AcademicCoursesMyResponse } from '@/shared/swagger'
import { dropdownItems } from '../values'
import { PropType } from 'vue'

const store = useStore()
const { currentYearTerm } = storeToRefs(store)

defineProps({
  course: {
    type: Object as PropType<AcademicCoursesMyResponse[0]>,
    required: true
  }
})
</script>
