import { Service } from '../../types'
import icon from './icon'

const website: Service = {
  id: 'website-service',
  title: 'domains.service.services.website-service.title',
  description: 'domains.service.services.website-service.description',
  categories: ['general'],
  url: 'https://sdu.edu.kz/',
  route: '/services/website-service',
  image: '/img/sdu.png',
  icon,
  contents: async () => (await import('@/contents/services/website.json')).default
}

export default website
