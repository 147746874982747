import { SelectField } from '@/shared/types'
import { ref } from 'vue'

export const classes:SelectField['options'] = [
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4',
    value: 4
  }
]

export const modal_shown = ref(false)
