<template>
  <div>
    <pms-button
      class="fw-bold py-2 px-5 m-1 rounded-pill"
      :text="'domains.administrative.message-board.tabs.all-posts'"
      :color="currentView === 'all-posts' ? 'primary' : 'secondary'"
      @click="onChange('all-posts')"
    />
    <pms-button
      class="fw-bold py-2 px-5 m-1 rounded-pill"
      :text="'domains.administrative.message-board.tabs.my-posts'"
      :color="currentView === 'my-posts' ? 'primary' : 'secondary'"
      @click="onChange('my-posts')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useMessageBoard } from '@domains/AdministrativeMessageBoard/store'

const store = useMessageBoard()

const currentView = computed<string>(() => store.currentPageView)

const onChange = (tab: string) => {
  store.setCurrentPageView(tab)
  store.fetchPosts()
}

</script>
