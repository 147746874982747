import { PropType, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { isTranslatable } from '@shared/utils'

const CheckboxComponent = defineComponent({
  name: 'CheckboxComponent',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String as PropType<'default' | 'solid'>,
      required: false,
      default: 'default'
    },
    color: {
      type: String as PropType<'success' | 'danger' | 'warning' | 'default'>,
      required: false,
      default: 'default'
    },
    size: {
      type: String as PropType<'sm'| 'md' | 'lg'>,
      required: false,
      default: 'md'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    state: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:state', 'onCheck', 'onUncheck'],
  setup: function (props, { emit }) {
    const { t } = useI18n()

    const value = ref<boolean>(props.state)

    const changeState = () => {
      value.value = !value.value
      emit('update:state', value.value)

      if (value.value) {
        emit('onCheck')
      } else {
        emit('onUncheck')
      }
    }

    watch(() => props.state, (newValue: boolean) => {
      value.value = newValue
    })

    return () => (
      <div class={[
        'form-check',
        `${props.variant === 'solid' ? 'form-check-solid' : ''}`,
        `${props.color !== 'default' ? `form-check-${props.color}` : ''}`,
        `${props.size !== 'md' ? `form-check-${props.size}` : ''}`
      ]}>
        <input class="form-check-input "
               type="checkbox"
               name={props.name}
               checked={props.state}
               disabled={props.disabled}
               required={props.required}
               onChange={ () => changeState() }
        />
        {
          props.label && (
            <label class="form-check-label">
              {isTranslatable(props.label) ? t(props.label) : props.label}
            </label>
          )
        }
      </div>
    )
  }
})

export default CheckboxComponent
