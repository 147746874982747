import { AcademicManageCurriculumCoreCoursesResponse } from '@/shared/swagger'
import { useRouter } from 'vue-router'

const useCourseEditor = () => {
  const router = useRouter()

  const handleEditCourse = (data: Pick<AcademicManageCurriculumCoreCoursesResponse[0], 'is_slot' | 'id'> & {trackId: number}) => {
    if (data.is_slot) {
      router.push({
        name: 'EditElectiveSlot',
        params: {
          courseId: data.id,
          trackId: data.trackId
        }
      })
    } else {
      router.push({
        name: 'EditCoreCourse',
        params: {
          courseId: data.id,
          trackId: data.trackId
        }
      })
    }
  }

  return {
    handleEditCourse
  }
}

export default useCourseEditor
