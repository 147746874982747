import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { UnknownResponse } from '@shared/swagger'
import { SectionManagementRequestsQuotasPostRequest } from '@shared/swagger'

export default async function postSectionManagementRequestQuotas (
  requestId: number,
  body: SectionManagementRequestsQuotasPostRequest
): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<SectionManagementRequestsQuotasPostRequest, UnknownResponse>({
    url: `academic/manage/sections/requests/${requestId}/quotas`,
    body,
    loader: 'academic/manage/sections/requests/{id}/quotas'
  })
  return response
}
