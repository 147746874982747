<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        :no-mobile="true"
        @launch="launch"
      >
        <template #toolbar>
          <div class="d-flex gap-2 justify-content-between mt-3 mb-3">
            <pms-driver-button />
            <pms-feedback-modal :options="[]" />
          </div>
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <EmployeePage v-if="step === 'employee'" />
    <WorkloadPage v-if="step === 'workload'" />
  </pms-container>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useTour } from '@shared/composable'
import { header, quickTourSteps, tour } from './values'
import EmployeePage from './ui/EmployeesPage.vue'
import WorkloadPage from './ui/WorkloadPage.vue'
import { useStaffList } from './store'

const store = useStaffList()
const step = computed(() => store.getCurrentStep)
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTourSteps())
})
</script>
