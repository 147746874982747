import { FormField } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAttendanceRelocate } from './attendance_relocate'
import { buildingsToOptions, dayToDate } from '../utils'
import { AcademicSingleCourseAttendancesResourcesResponse } from '@/shared/swagger'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { change_active } from '../values'

export function useSearchFormComposable () {
  const { resource, schedule } = useAttendanceRelocate()
  const store = useSingleSection()

  const building_id = computed(() => schedule.value?.room.building_id)
  const date = computed(() => dayToDate(schedule.value?.day))

  const tempValues = ref({
    building_id: undefined || building_id,
    date: undefined || date
  })
  const { t } = useI18n()
  const formFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: `${t('common.select-building')}:`,
        class: 'mb-1 required'
      },
      name: 'building_id',
      type: 'select',
      value: tempValues.value.building_id,
      options: buildingsToOptions(resource.value?.buildings as AcademicSingleCourseAttendancesResourcesResponse['buildings']),
      required: true
    },
    {
      label: {
        text: `${t('common.select-date')}:`,
        class: 'mb-1 mt-2 required'
      },
      name: 'date',
      type: 'date',
      value: tempValues.value.date || null,
      required: true
    }
  ])

  const onSubmit = async ({ values }) => {
    tempValues.value = { ...values }

    await store.fetchAttendanceRelocates({ ...values })
  }

  const onChange = () => {
    change_active.value = true
  }

  return {
    formFields,
    onSubmit,
    onChange
  }
}
