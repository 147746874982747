import type { StoreType } from '../types'
import { useResource } from '@shared/stores'
import { usePagination } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const { perPage } = usePagination()
  const resource = useResource()
  const bootstrapFetch = {
    per_page: perPage.value,
    offset: 1
  }

  await Promise.all([
    this.fetchKnowledgeBase(bootstrapFetch),
    resource.fetchKnowledgeResource({
      modules: ['types', 'popular', 'statistics', 'categories'],
      limit: 10
    })
  ])
}
