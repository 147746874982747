import type { StoreType } from '../types'
import { useEncrypt } from '@shared/composable'

export default function getRoles (this: StoreType): Array<string> {
  const { decrypt } = useEncrypt()
  const sensitive = window.localStorage.getItem('sensitive')
  if (!sensitive) return []

  const decrypted = decrypt(sensitive) as Record<string, unknown>
  return decrypted.roles as string[] ?? []
}
