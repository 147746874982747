import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicCoursesStudentsRequest,
  AcademicCoursesStudentsResponse
} from '@shared/swagger'

export default async function getAcademicCoursesStudents (params: AcademicCoursesStudentsRequest): Promise<AxiosResponse<AcademicCoursesStudentsResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicCoursesStudentsResponse>({
    url: 'academic/courses/students',
    body: { params: params },
    loader: 'academic/courses/students'
  })

  return response
}
