<template>
  <div
    class="d-flex justify-content-end mt-2 mt-md-0"
  >
    <div
      id="year-term-selector"
      class="d-flex align-items-center gap-2"
    >
      <p class="fs-5 m-0">
        {{ $t('common.year-terms') }}:
      </p>
      <div class="d-flex flex-row">
        <select
          class="form-select form-select w-175px"
          @change="setYearTerm($event)"
        >
          <option
            v-if="addAllAsOption"
            value="all"
          >
            {{ $t('common.all') }}
          </option>
          <option
            v-for="yearTerm in singleStudentResource?.year_terms || []"
            :key="(yearTerm.year + '' + yearTerm.term)"
            :value="yearTerm.year + '-' + yearTerm.term"
            :selected="(yearTerm.year === currentYearTerm.year && yearTerm.term === currentYearTerm.term)"
          >
            {{ getYearTermLabel(yearTerm, t) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useResource } from '@shared/stores'
import { getYearTermLabel } from '@/shared/utils'
import { useI18n } from 'vue-i18n'
import { useSingleStudent } from '../store'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const resource = useResource()
const store = useSingleStudent()

const { singleStudentResource } = storeToRefs(resource)
const { currentYearTerm } = storeToRefs(store)

defineProps({
  addAllAsOption: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits<{
  onOptionChange: [option: string]
}>()
const setYearTerm = async ($event) => {
  if ($event.target.value !== 'all') {
    const [year, term] = $event.target.value.split('-')
    store.setCurrentYearTerm({ year: parseInt(year), term: parseInt(term), id: 0 })
  }
  emit('onOptionChange', $event.target.value)
}
</script>
