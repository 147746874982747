import { formatAttendanceDetailsForGraphs, formatAttendanceDetailsEndpoint } from './../../utils/attendance'
import type { State } from '../types'
import { AcademicSingleStudentAttendanceDetailsGetResponse } from '@shared/swagger'

export default function setAttendanceDetails (this: State, attendanceDetails: AcademicSingleStudentAttendanceDetailsGetResponse | null): void {
  if (attendanceDetails) {
    const formattedData = formatAttendanceDetailsEndpoint(attendanceDetails)
    const formattedGraphData = formatAttendanceDetailsForGraphs(formattedData)
    this.attendanceDetails = formattedGraphData
  } else {
    this.attendanceDetails = null
  }
}
