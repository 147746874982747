<template>
  <div
    id="kt_activities"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'100%', 'lg': '450px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_activities_toggle"
    data-kt-drawer-close="#kt_activities_close"
  >
    <div class="card shadow-none w-100">
      <div
        id="kt_activities_header"
        class="card-header"
      >
        <h3 class="card-title fw-bold text-dark">
          {{ $t('layout.drawers.activity.header') }}
        </h3>

        <div class="card-toolbar">
          <button
            id="kt_activities_close"
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <div
        id="kt_activities_body"
        class="card-body position-relative"
      >
        <div
          id="kt_activities_scroll"
          class="position-relative scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_activities_body"
          data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
          data-kt-scroll-offset="5px"
        >
          <div class="timeline-label">
            <template
              v-for="(event, index) in events"
              :key="index"
            >
              <div class="timeline-item">
                <div class="timeline-label text-gray-800 fs-6">
                  {{ event.startDate }} - {{ event.endDate }}
                </div>
                <div class="timeline-badge">
                  <i
                    class="fa fa-genderless fs-1"
                    :class="{ 'text-success' : event.active, 'text-danger': !event.active }"
                  />
                </div>
                <div class="fw-normal timeline-content fs-5 fw-bold ps-3">
                  {{ $t('layout.drawers.activity.event-description', event) }}
                </div>
              </div>
            </template>
          </div>
          <div class="timeline" />
        </div>
      </div>
      <div
        id="kt_activities_footer"
        class="card-footer py-5 text-center"
      >
        <router-link
          to="/administrative/system-calendar"
          class="btn btn-bg-body text-primary"
        >
          {{ $t('phrases.view-all') }}
          <inline-svg src="/media/icons/duotune/arrows/arr064.svg" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUser } from '@shared/stores'

const user = useUser()

const events = computed(() => user.getEvents)
</script>
