<template>
  <Teleport to="#biography-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'

import type { FormField } from '@shared/types'
import type { ProfileChildrenGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { genderToOptionType } from '../utils'
import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/children-edit', 'profile/children-post'])

const genders = computed(() => resource.getProfileResource?.genders || [])
const children = computed(() => store.getChildren)

const defaultTempValues:ProfileChildrenGetResponse[0] = {
  id: -1,
  name: '',
  surname: '',
  gender: 0,
  birthdate: ''
}

const tempValues = ref<ProfileChildrenGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.autobiography.children.name'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'name',
    type: 'text',
    value: tempValues.value.name,
    required: true,
    placeholder: t('domains.profile.autobiography.children.name-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.autobiography.children.surname'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'surname',
    type: 'text',
    value: tempValues.value.surname,
    required: true,
    placeholder: t('domains.profile.autobiography.children.surname-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('common.table.course-code') }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.autobiography.children.gender'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'gender',
    type: 'select',
    value: tempValues.value.gender,
    required: true,
    options: genderToOptionType(genders.value)
  },
  {
    label: {
      text: t('domains.profile.autobiography.children.birthdate'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'birthdate',
    type: 'date',
    required: true,
    value: tempValues.value.birthdate,
    binding: {
      max: moment().format('YYYY-MM-DD')
    }
  }
])

const onSubmit = async ({ values }) => {
  const { status, message } =
  tempValues.value.id === -1
    ? await store.addChildren({ ...values })
    : await store.editChildren(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'biography-modal' })
    unsetModifier()
    store.fetchChildren()
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'biography-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactChild = children.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactChild || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
