import type { StoreType } from '../types'
import type { KnowledgeManageCategoriesPostRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { putKnowledgeManageCategory } from '@/shared/api'

export default async function editCategory (this: StoreType, uuid: string, values: KnowledgeManageCategoriesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putKnowledgeManageCategory(uuid, values)

  return {
    status: ok,
    message: message
  }
}
