<template>
  <Teleport to="#relocate-modal-content">
    <div>
      <span class="me-2">
        {{ $t('common.table.room') }}:
      </span>
      <span>
        {{ hour.room_name }}
      </span>

      <br>
      <span class="me-2">
        {{ $t('common.hour') }}:
      </span>
      <span>
        {{ hour.hour_value }}
      </span>
    </div>
    <div
      v-if="hour?.lesson"
      class="text-center"
    >
      <span class="fw-bold fs-3">
        {{ hour.lesson.teacher_name }}
      </span>
      <br>
      <span class="fs-6">
        {{ hour.lesson.course_code }} {{ hour.lesson.course_title }}
      </span>
    </div>
    <div>
      <pms-smart-form
        :fields="[formFields]"
        :loader="loader"
        @handleSubmit="onSubmit"
      />
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { useLoader, useTeleportModal } from '@/shared/composable'
import { RoomHourMatrix } from '../types'
import { useSubmitForm } from '../composable'
import { computed } from 'vue'

const { modifier } = useTeleportModal()
const { isActiveOneOf } = useLoader()
const { formFields, onSubmit } = useSubmitForm()

const hour = computed(() => modifier.value?.data?.hour as unknown as NonNullable<RoomHourMatrix[0]['hours']>[0])
const loader = isActiveOneOf(['academic/courses/attendances/relocates'])
</script>
