<template>
  <pms-row>
    <template
      v-for="(side, index) in items"
      :key="index"
    >
      <pms-grid :col="6">
        <pms-smart-table
          :headers="headers"
          :items="side"
          :options="{
            design: 'bordered-dashed',
            headers: {
              show: false
            },
            items: {
              translate: false
            }
          }"
        >
          <template #key="{ item, value }">
            <div class="text-nowrap">
              {{ $t(value) }}
              <template v-if="item.translateIcon">
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  :aria-label="item.translateIconText"
                  :data-bs-original-title="item.translateIconText"
                  data-kt-initialized="1"
                />
              </template>
            </div>
          </template>
          <template #value="{ item, value }">
            <template v-if="item.type === 'custom'">
              <template v-if="item.field === 'positions'">
                <template
                  v-for="(badge, idx) in value"
                  :key="idx"
                >
                  <pms-badge
                    :value="badge.title"
                    :color-variant="badge.is_main ? 'default' : 'outlined'"
                    :color="'primary'"
                    class="mb-1"
                  />
                </template>
              </template>
              <template v-if="item.field === 'birth_date'">
                {{ dateFormatter(value) }}
              </template>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </template>
        </pms-smart-table>
      </pms-grid>
    </template>
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { dateFormatter } from '@shared/utils'

import { useProfile } from '../../../store'
import { tableHeaders as headers } from '../values'
import { overviewInfoToTable } from '../utils'

const store = useProfile()

const items = computed(() => overviewInfoToTable(store.getOverviewInfo))
</script>
