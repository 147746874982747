import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { ProfileBiographyPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putProfileBiography (values: ProfileBiographyPutRequest['biography']): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileBiographyPutRequest, UnknownResponse>({
    url: 'profile/biography',
    body: { biography: values },
    loader: 'profile/biography-edit'
  })

  return response
}
