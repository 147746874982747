import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  ServiceManageLanguagesApplicantsBulkPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putServiceManageLanguagesApplicants (params: ServiceManageLanguagesApplicantsBulkPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceManageLanguagesApplicantsBulkPutRequest, UnknownResponse>({
    url: 'service/manage/languages/applicants/bulk',
    body: params,
    loader: 'service/manage/applicants[put]'
  })

  return response
}
