import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileChildrenGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileChildren (): Promise<AxiosResponse<ProfileChildrenGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileChildrenGetResponse>({
    url: 'profile/children',
    body: {},
    loader: 'profile/children'
  })
  return response
}
