import { SelectField } from '@shared/types'
import { AcademicManageCourseResourceDepartmentsGetResponse } from '@shared/swagger'

const organizedDepartments = (departments: AcademicManageCourseResourceDepartmentsGetResponse): SelectField['options'] => {
  const departmentMap: { [id: number]: SelectField['options'][0] } = {}
  const roots: SelectField['options'] = []

  // Create a map for quick lookup by id
  departments.forEach(department => {
    departmentMap[department.id] = {
      value: department.code,
      label: department.title
    }
  })

  // Build the tree structure
  departments.forEach(department => {
    const parent = departmentMap[department.parent_id]
    const currentDepartment = departmentMap[department.id]

    if (parent) {
      if (!parent.group) {
        parent.group = []
      }
      parent.group.push(currentDepartment)
    } else {
      roots.push(currentDepartment)
    }
  })

  return roots
}

export default (data: AcademicManageCourseResourceDepartmentsGetResponse): SelectField['options'] => {
  const options: SelectField['options'] = organizedDepartments(data)

  return options
}
