<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-2 flex-column flex-md-row">
        <YearTerms id="year-terms-selector" />
        <div
          class="d-flex mt-sm-3 flex-row mt-2 mt-md-0 gap-2"
        >
          <pms-driver-button />
          <pms-feedback-modal
            :options="feedback"
          />
          <pms-page-tour
            :launched="status"
            :steps="steps"
            @launch="launch"
          />
        </div>
      </div>
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row>
      <pms-grid
        :col="12"
      >
        <ConsentRequestsTable id="table-block-consent" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useToolbar, useTour, useDriver } from '@shared/composable'

import { header, tour, feedback, quickConsentRequestsSetTourSteps } from './values'

import YearTerms from './ui/YearTerms.vue'
import ConsentRequestsTable from '@domains/AcademicConsentRequest/ui/ConsentRequestsTable.vue'

const { setSteps: setQuickTourSteps } = useDriver()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickConsentRequestsSetTourSteps())
})
</script>
