<template>
  <BackToRequestBlock class="mb-5" />
  <ProgramDetails />
  <ProgramCoursesTable v-if="newRequestStore.isFetched" />
</template>

<script setup lang="ts">
import ProgramDetails from '@domains/SectionManagement/features/NewRequest/ui/ProgramDetails.vue'
import BackToRequestBlock from '@domains/SectionManagement/features/NewRequest/ui/BackToRequestBlock.vue'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { onMounted } from 'vue'
import ProgramCoursesTable from '@domains/SectionManagement/features/NewRequest/ui/ProgramCoursesTable.vue'

const newRequestStore = useNewRequest()

onMounted(async () => {
  newRequestStore.isFetched = true
  await newRequestStore.fetchRequestCourses({ request_by: newRequestStore.requestType || '' })
})
</script>
