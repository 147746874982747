<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div class="w-100 position-relative mb-5">
        <div
          :key="'content.course-learning-outcomes'"
          class="fs-2 fw-bold text-danger text-center flex-fill"
        >
          {{ getMessage('advanced.student-workload') }}
        </div>
        <button
          v-if="amIEnrolled && has_permission"
          type="button"
          class="btn btn-light-primary p-2 position-absolute top-0 end-0"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addActivity"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </div>

      <pms-smart-table
        :items="activityItems"
        :headers="header"
        :sortable="false"
        :options="{
          design: 'bordered-dashed'
        }"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #title="{ item }">
          <span class="fs-5 fw-bold">{{ item.title }}</span>
        </template>
        <template #quantity="{ item }">
          {{ item.quantity }}
        </template>
        <template #hour="{ item }">
          {{ item.hour }}
        </template>
        <template #action="{ item }">
          <div
            v-if="has_permission"
            class="btn-group btn-group-sm"
            role="group"
          >
            <button
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="openEditor(item)"
            >
              <pms-kt-icon
                :icon-name="'click'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              type="button"
              class="btn btn-light-danger p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.delete')"
              @click="deleteActivity(item.id)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
            </button>
          </div>
        </template>
      </pms-smart-table>
      <div class="mt-5 fs-5 fw-bold">
        {{ $t('domains.academic.manage.syllabus.work-load.total') }} : {{ totalWorkLoad }}
      </div>
    </pms-card>
  </div>
  <SyllabusWorkLoadForm v-if="modifier && modifier.name === 'syllabus.work-load'" />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { computed } from 'vue'
import { workflowTable, has_permission } from './../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import SyllabusWorkLoadForm from './SyllabusWorkLoadForm.vue'
import { AcademicManageSyllabusActivitiesGetResponse } from '@shared/swagger'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'

const { isActive } = useLoader()
const alert = useAlert()
const { setModifier, open, modifier } = useTeleportModal()
const loader = isActive('academic/manage/syllabus/activities')

const store = useStore()
const activityItems = computed(() => store.getActivities)
const totalWorkLoad = computed(() => {
  return activityItems.value.reduce((accumulator, activity) => accumulator + (activity.quantity || 0) * (activity.hour || 0), 0)
})

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...workflowTable,
      {
        title: 'common.action',
        field: 'action',
        slotCellName: 'action',
        headerClass: 'w-50px',
        cellClass: 'text-center'
      }]
  : workflowTable)
function openEditor (activity: AcademicManageSyllabusActivitiesGetResponse[0]) {
  setModifier(
    'syllabus.work-load',
    {
      id: activity.id,
      mode: 'edit'
    },
    { title: 'work-load.title.edit', description: 'work-load.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}
function addActivity () {
  setModifier(
    'syllabus.work-load',
    { mode: 'add' },
    { title: 'work-load.title.add', description: 'work-load.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

async function deleteActivity (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.work-load',
      onConfirm: async () => {
        await store.deleteActivity(id)
      }
    }
  )
}
</script>
../store../values
../store../values
