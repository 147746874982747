<template>
  <pms-card
    :header="false"
  >
    <syllabus-advanced-teaching-methods
      :teaching-methods="teachingMethods"
    />

    <syllabus-advanced-reading-list
      :readings="readings"
    />

    <syllabus-advanced-assessments
      :assessments="assessments"
    />

    <syllabus-advanced-activities
      :activities="activities"
    />
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '../../../store'

import SyllabusAdvancedTeachingMethods from './SyllabusAdvancedTeachingMethods.vue'
import SyllabusAdvancedReadingList from './SyllabusAdvancedReadingList.vue'
import SyllabusAdvancedAssessments from './SyllabusAdvancedAssessments.vue'
import SyllabusAdvancedActivities from './SyllabusAdvancedActivities.vue'

const store = useSingleCourse()

const teachingMethods = computed(() => store.getSyllabusTeachingMethods)
const readings = computed(() => store.getSyllabusReadings)
const assessments = computed(() => store.getSyllabusAssessments)
const activities = computed(() => store.getSyllabusActivities)

</script>
