<template>
  <div class="mb-5">
    <pms-button
      v-if="editor.status === 'set-multiple' && !editor.started"
      :disabled="reset_loading.value"
      :size="'sm'"
      variant="outlined"
      @click="startMultipleAttendance()"
    >
      <i class="bi bi-check2-square" />
      <span class="ms-2">
        {{ $t(reset_loading.value ? 'common.loading':'common.common.set-attendance') }}
      </span>
    </pms-button>
    <pms-button
      v-if="editor.status === 'set-multiple' && !editor.started"
      :disabled="reset_loading.value"
      :size="'sm'"
      variant="outlined"
      :color="'danger'"
      class="ms-2"
      @click="resetAttendances()"
    >
      <i class="bi bi-eraser" />
      <span class="ms-2">
        {{ $t(reset_loading.value ? 'common.loading':'common.reset') }}
      </span>
    </pms-button>

    <pms-button
      v-if="editor.status === 'set-multiple' && !editor.started && (editor.sessions as number[]).length<2"
      :disabled="reset_loading.value"
      :size="'sm'"
      variant="outlined"
      class="mx-10"
      @click="startSingleAttendanceRelocate(editor.sessions[0])"
    >
      <i class="bi bi-arrow-down-up" />
      <span class="ms-2">
        {{ $t(reset_loading.value ? 'common.loading':'common.common.relocate') }}
      </span>
    </pms-button>

    <pms-button
      v-if="qr_available"
      :disabled="reset_loading.value"
      :size="'sm'"
      variant="outlined"
      class="mx-10"
      @click="startSingleQrAttendance(editor.sessions[0])"
    >
      <i class="bi bi-arrow-down-up" />
      <span class="ms-2">
        {{ $t(reset_loading.value ? 'common.loading':'common.common.qr') }}
      </span>
    </pms-button>

    <pms-button
      v-if="editor.status === 'set-multiple'"
      :disabled="reset_loading.value"
      class="ms-2"
      :size="'sm'"
      :variant="'outlined'"
      :text="'common.cancel'"
      :color="'muted'"
      @click="resetDefault()"
    />
  </div>

  <div class="table-responsive">
    <table class="table table-bordered table-border-blue table-striped table-hover">
      <AttendanceTableHeaders />
      <AttendanceTableBody v-if="tableData.length>0" />
      <tr
        v-else
      >
        <td
          class="text-center"
          colspan="7"
        >
          <pms-no-data />
        </td>
      </tr>
    </table>
  </div>

  <pms-button
    v-if="editing.status"
    :size="'sm'"
    class="mb-2"
    :disabled="save_loading.value"
    @click="saveAttendance()"
  >
    {{ $t(save_loading.value ? 'common.loading':'common.set-attendance') }}
  </pms-button>
  <pms-button
    v-if="editing.status"
    :size="'sm'"
    :variant="'tonal'"
    :text="'common.cancel'"
    :color="'primary'"
    class="mb-2 ms-2"
    @click="resetDefault()"
  />
</template>
<script setup lang="ts">
import AttendanceTableHeaders from './AttendanceTableHeaders.vue'
import AttendanceTableBody from './AttendanceTableBody.vue'

import { useAttendanceEditor } from '../composable'
import { computed } from 'vue'
import { useLoader } from '@/shared/composable'

const {
  tableData,
  editor, editing, qr_available, startMultipleAttendance, startSingleQrAttendance, resetAttendances, saveAttendance, resetDefault, startSingleAttendanceRelocate
} = useAttendanceEditor()

const save_loading = computed(() => useLoader().isActiveOneOf(['academic/courses/attendances/post']))
const reset_loading = computed(() => useLoader().isActiveOneOf(['academic/courses/attendances/reset']))

</script>
