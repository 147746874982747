import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { AdministrativeGateEntryResourceResponse, AdministrativeGateEntryResourceRequest } from '@shared/swagger'

export default async function getAdministrativeGateEntryResource (params: AdministrativeGateEntryResourceRequest): Promise<AxiosResponse<AdministrativeGateEntryResourceResponse>> {
  const { get } = useRequests()

  const response = await get<AdministrativeGateEntryResourceRequest, AdministrativeGateEntryResourceResponse>({
    url: 'administrative/gate-entry/resources',
    body: params,
    loader: 'administrative/gate-entry/resources'
  })

  return response
}
