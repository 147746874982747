<template>
  <pms-checkbox
    class="d-flex justify-content-center"
    :state="isSelected()"
    @on-check="handleSelect()"
    @on-uncheck="handleUnselect()"
  />
</template>

<script setup lang="ts">
import { PropType } from 'vue'

import { selectedStudentIds } from '../values'
import { AcademicSingleCourseGradeResponse } from '@shared/swagger'

const props = defineProps({
  student: {
    type: Object as PropType<AcademicSingleCourseGradeResponse[0]>,
    required: true
  }
})

const handleSelect = () => {
  selectedStudentIds.value.push(props.student.student_id)
}

const handleUnselect = () => {
  selectedStudentIds.value = selectedStudentIds.value.filter((id) => id !== props.student.student_id)
}

const isSelected = () => {
  return selectedStudentIds.value.some((id) => id === props.student.student_id)
}
</script>

<style scoped lang="scss">

</style>
