<template>
  <pms-card>
    <template #header>
      <announcement-form-header
        :title="props.title"
        :description="props.description"
      />
    </template>

    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <div class="mt-3 d-flex justify-content-end">
          <pms-button
            type="submit"
            class="mt-2"
            :loader="loader"
            :disabled="loader"
            :text="
              props.announcement.id
                ? $t(
                  'domains.service.announcements.announcement-form.save-changes'
                )
                : $t(
                  'domains.service.announcements.announcement-form.create-announcement'
                )
            "
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>

<script setup lang="ts">
import { useLoader } from '@/shared/composable'
import type { IAnnouncementFormEmits, IAnnouncementFormProps } from '../types'

import AnnouncementFormHeader from './AnnouncementFormHeader.vue'
import { useAnnouncementForm } from '../composables'

const { isActiveOneOf } = useLoader()
const loader = isActiveOneOf([
  'service/manage/announcements/single/edit',
  'aservice/manage/announcements/post'
])

const props = withDefaults(defineProps<IAnnouncementFormProps>(), {
  title: '',
  description: '',
  announcement: () => ({
    id: 0,
    title_en: '',
    title_ru: '',
    title_kz: '',
    content_en: '',
    content_ru: '',
    content_kz: '',
    targets: [],
    status: false,
    start_date: '',
    end_date: ''
  })
})

const emit = defineEmits<IAnnouncementFormEmits>()
const { formFields, onHandleChange, onSubmit } = useAnnouncementForm(emit, props)
</script>
