<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <pms-driver-button
        class="me-2 ms-2 mt-2 mt-md-0"
      />
      <pms-feedback-modal
        :options="[]"
        :button-class="'mt-2 mt-md-0'"
      />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-6"
    >
      <pms-page-shimmer
        :shimmer="'table'"
        :filter="true"
      />
    </pms-row>
    <pms-column v-else>
      <pms-grid :col="12">
        <pms-card
          :header="false"
          class="mb-4"
        >
          <year-step v-if="currentStep === 'year'" />
          <department-step v-if="currentStep === 'department'" />
          <program-step v-if="currentStep === 'program'" />
          <track-step v-if="currentStep === 'track'" />
        </pms-card>
      </pms-grid>
      <pms-grid
        v-if="currentStep === 'year'"
        :col="12"
      >
        <curricula-navigation />
      </pms-grid>
    </pms-column>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useLoader, useTour, useToolbar } from '@shared/composable'

import { header, tour } from './values'
import { useStore } from './store'

import YearStep from './ui/YearStep.vue'
import DepartmentStep from './ui/DepartmentStep.vue'
import ProgramStep from './ui/ProgramStep.vue'
import TrackStep from './ui/TrackStep.vue'
import CurriculaNavigation from './ui/CurriculaNavigation.vue'

const { isActiveOneOf } = useLoader()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])

const store = useStore()
const loader = isActiveOneOf(['resources'])
const currentStep = computed(() => store.currentStep)

onMounted(async () => {
  toggleToolbar()
  setSteps(await tour())
})
</script>
