<template>
  <pms-card
    :header="false"
    :options="{
      card: {class: 'rounded-3 shadow-sm'},
      body: {class: 'p-0 rounded-3'}
    }"
  >
    <div class="table-responsive">
      <table
        id="singleCourseSchedulePrint"
        class="table table-row-bordered align-middle text-center gy-4 mb-0 pb-0"
      >
        <table-head />
        <table-body
          :schedule="scheduleRecords"
          :schedule-hours="scheduleHours"
        />
      </table>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import TableHead from '@domains/AcademicSchedule/ui/TableHead.vue'
import TableBody from '@domains/AcademicSchedule/ui/TableBody.vue'
import { computed } from 'vue'
import { useResource } from '@/shared/stores'

// eslint-disable-next-line vue/require-prop-types
defineProps(['scheduleRecords'])

const resource = useResource()

const scheduleHours = computed(() => {
  return resource.scheduleManagementSectionProgramHours ?? []
})

</script>
