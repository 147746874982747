import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCoursesRequisitesGetRequest, AcademicManageCoursesRequisitesGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicManageCoursesRequisitesGetRequest): Promise<AxiosResponse<AcademicManageCoursesRequisitesGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCoursesRequisitesGetResponse>({
    url: 'academic/manage/courses/requisites',
    body: { params },
    loader: 'academic/manage/courses/requisites'
  })

  return response
}
