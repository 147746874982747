import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { useToggler } from '@shared/composable'

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const { toggler, toggle, unToggle } = useToggler()

    return () => (
        <button
        class="btn btn-sm btn-light-primary pe-3 ps-3"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        data-bs-original-title={ t(`phrases.toggler-${toggler.value ? 'hide' : 'show'}`) }

        onClick={ () => { toggler.value ? unToggle() : toggle() }}
    >
        <span class="btn-label">
          <pms-kt-icon
            icon-name={toggler.value ? 'burger-menu-4' : 'burger-menu-3'}
            class="fw-semibold fs-2 p-0"
          />
        </span>
      </button>
    )
  }
})
