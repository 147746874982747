import { AcademicInformationSchedulesResourcesResponse } from '@/shared/swagger'
import { MappedChildren } from '../types'

const getDepartmentChildren = (
  parentDepartment: AcademicInformationSchedulesResourcesResponse['departments'][0],
  departments: AcademicInformationSchedulesResourcesResponse['departments']
) => {
  const children = departments
    .filter(d => d.parent_id === parentDepartment.id)
    .map(child => ({
      key: child.code.toString(),
      label: child.title,
      children: getDepartmentChildren(child, departments)
    }))

  return children.length ? children : null
}

export default (departments): MappedChildren[] => {
  const allParentDepartments: MappedChildren[] = departments
    .filter(parent => !parent.parent_id || !departments.some(child => child.id === parent.parent_id))
    .map(parent => ({
      key: parent.code.toString(),
      label: parent.title,
      children: getDepartmentChildren(parent, departments)
    }))

  return allParentDepartments
}
