import type { StoreType } from '../types'

import { getServiceManageLanguagesResources } from '@shared/api'
import { ServiceManageLanguagesResourcesRequest } from '@shared/swagger'

export default async function fetchServiceManageLanguageResource (this: StoreType, body: ServiceManageLanguagesResourcesRequest): Promise<void> {
  const { ok, data } = await getServiceManageLanguagesResources(body)

  if (ok) {
    this.setServiceManageLanguageResource(data)
  }
}
