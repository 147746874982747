import { router } from '@/router'

import { useAnalytics, useLoader, useToken } from '@shared/composable'
import { handleOptions, keysToCamel, useShadow } from '@shared/utils'
import { AxiosResponse } from '@shared/types'
import { useUser } from '../stores'
import { computed, watch } from 'vue'

export type RequestMethodOptionsConfigs = {
  token?: boolean;
  analytics?: boolean;
  loader?: boolean;
  userType?: 'employee' | 'student';
}

export type RequestMethodOptions<T> = {
  url: string;
  body: T;
  headers?: Record<string, string>;
  configs?: RequestMethodOptionsConfigs;
  loader?: string;
  camelize?: boolean;
}

type RequestMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'

const requestOptions: RequestMethodOptionsConfigs = {
  token: true,
  analytics: true,
  loader: true,
  userType: 'employee'
}

export const useRequests = () => {
  const vue = useShadow()

  const headersConfig = () => {
    const locale = localStorage.getItem('locale')
    vue.axios.defaults.headers.common['Content-Language'] = locale !== null ? locale : 'en-EN'
  }
  // To set impactable header to all other requests
  const setHeaders = (headers: Record<string, string>) => {
    for (let [k, v] of Object.entries(headers)) {
      if (v === null || v === undefined) {
        v = ''
      }
      vue.axios.defaults.headers.common[k] = v
    }
  }

  const setToken = () => {
    const token = useToken().getToken()
    vue.axios.defaults.headers.common.Authorization = token
  }

  const setStudentToken = () => {
    const token = useToken().getStudentToken()
    vue.axios.defaults.headers.common.Authorization = token
  }

  const unsetToken = () => {
    vue.axios.defaults.headers.common.Authorization = ''
  }

  const slugable = (slugObject: Record<string, unknown>): string => {
    let slug = ''

    for (let [k, v] of Object.entries(slugObject)) {
      if (v === null || v === undefined) {
        v = ''
      }
      slug += k + '=' + v + '&'
    }

    return slug.slice(0, -1)
  }

  const makeRequest = async <T, K>(method: RequestMethod, options: RequestMethodOptions<T>, returnResponse = false): Promise<Awaited<AxiosResponse<K>>> => {
    if (process.env.STORYBOOK) {
      return vue.config.globalProperties.$staticRequest(method, options)
    }

    const { activate, deactivate } = useLoader()
    const configs: RequestMethodOptionsConfigs = handleOptions<RequestMethodOptionsConfigs>(options.configs, requestOptions)
    const camelize = options.camelize !== undefined ? options.camelize : false
    headersConfig()
    configs.token
      ? options.configs?.userType === 'student' ? setStudentToken() : setToken()
      : unsetToken()
    if (configs.loader && options.loader) activate(options.loader)

    let requestResponse = {}

    const response = await vue.axios[method](options.url, options.body, { ...options.headers }).catch(async (error) => {
      if (error.response === undefined) {
        requestResponse = {
          ok: false,
          status: 500,
          message: 'Server Request Timeout (code: ERR_CONNECTION_TIMED_OUT)',
          data: []
        }
      } else {
        if (error.response.status === 401) {
          useToken().destroyToken()
          const isAuthenticated = computed(() => useUser().isAuthenticated)
          return new Promise((resolve) => {
            const unwatch = watch(isAuthenticated, (newValue) => {
              if (newValue) {
                resolve(newValue)
                unwatch()
              }
            })
          }).then(() => {
            return makeRequest(method, options, true)
          })
        } else {
          requestResponse = {
            ok: false,
            status: error.response.status,
            message: error.response.data.message,
            data: []
          }
        }
      }
    }).finally(() => {
      if (configs.loader && options.loader) deactivate(options.loader)
    })
    if (returnResponse) {
      return response as Awaited<AxiosResponse<K>>
    }

    if (response !== undefined) {
      requestResponse = {
        ok: response !== undefined,
        status: response !== undefined ? response.status : false,
        message: response !== undefined ? response.data.message : false,
        data: response !== undefined ? (camelize ? keysToCamel(response.data.data) : response.data.data) : [],
        auth: response !== undefined ? (response.data.auth) : ''
      }
    }

    if (configs.analytics) {
      const { onEvent } = useAnalytics()
      const route = router.currentRoute.value

      onEvent(
        `request: ${options.url}`, {
          request: {
            method,
            body: options.body,
            headers: options.headers
          },
          response: requestResponse,
          page: {
            name: route.name,
            path: route.fullPath,
            meta: route.meta,
            query: route.query,
            params: route.params
          }
        }
      )
    }

    return requestResponse as Awaited<AxiosResponse<K>>
  }

  const get = async <T, K>(options: RequestMethodOptions<T>): Promise<Awaited<AxiosResponse<K>>> => {
    return makeRequest<T, K>('get', options)
  }

  const post = async <T, K>(options: RequestMethodOptions<T>): Promise<Awaited<AxiosResponse<K>>> => {
    return makeRequest<T, K>('post', options)
  }

  const put = async <T, K>(options: RequestMethodOptions<T>): Promise<Awaited<AxiosResponse<K>>> => {
    return makeRequest<T, K>('put', options)
  }

  const patch = async <T, K>(options: RequestMethodOptions<T>): Promise<Awaited<AxiosResponse<K>>> => {
    return makeRequest<T, K>('patch', options)
  }

  const destroy = async <T, K>(options: RequestMethodOptions<T>): Promise<Awaited<AxiosResponse<K>>> => {
    return makeRequest<T, K>('delete', options)
  }

  return {
    get,
    post,
    put,
    patch,
    destroy,
    slugable,
    setHeaders
  }
}

export default useRequests
