import { useAlert } from '../composable'

export function getBase64 (file:NonNullable<HTMLInputElement['files']>[0]):Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

export function downloadFile (response:Blob, name:string) {
  const url = window.URL.createObjectURL(new Blob([response]))

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}

export function showFile (response:Blob) {
  const url = window.URL.createObjectURL(response)
  const pdf = window.open(url, '_blank')
  if (pdf) {
    pdf.focus()
  } else {
    useAlert().error({ text: 'Please, allow popups' })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function jsonToFormData (json:any):FormData {
  const formData = new FormData()

  Object.keys(json).forEach(key => {
    formData.append(key, json[key])
  })

  return formData
}
