import { getProfileBiography } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchBiography (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileBiography()

  if (ok) {
    this.setBiography(data.biography)
  }
}
