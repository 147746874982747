import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useScheduleManagement } from './store'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useScheduleManagement()

  pagination.$reset()
  // store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/academic/manage/schedule-management',
  children: [
    {
      path: '',
      name: 'ScheduleManagementPage',
      component: () => import('./ScheduleManagementPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'section/:sectionId',
      name: 'ScheduleManagementSectionPage',
      component: () => import('./features/SectionPage/SectionPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
