import { TabElement } from '@shared/types'

export const readonlyTabs: Array<TabElement> = [
  {
    id: 'program',
    label: 'domains.editor.curricula.tabs.programs'
  },
  {
    id: 'reference',
    label: 'domains.editor.curricula.tabs.references'
  },
  {
    id: 'elective',
    label: 'domains.editor.curricula.tabs.electives.tab-title'
  }
]
