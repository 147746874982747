import { ref } from 'vue'

const defaultPagination = {
  total: 0,
  perPage: 20,
  currentPage: 1,
  lastPage: 1,
  maxVisiblePages: 2
}

const total = ref<number>(0)
const perPage = ref<number>(20)
const currentPage = ref<number>(1)
const lastPage = ref<number>(1)
const maxVisiblePages = ref<number>(2)

let triggerFunction = (perPage: number, currentPage: number) => {
  console.log('triggerFunction', perPage, currentPage)
}

const trigger = () => {
  triggerFunction(perPage.value, currentPage.value)
}

const usePagination = () => {
  const setPerPage = (value: number) => {
    perPage.value = value
  }

  const setTotal = (value: number) => {
    total.value = value
  }

  const setLastPage = (value: number) => {
    lastPage.value = value
  }

  const setCurrentPage = (value: number) => {
    currentPage.value = value
  }

  const setMaxVisiblePages = (value: number) => {
    maxVisiblePages.value = value
  }

  const setTriggerFunction = (fn: (perPage: number, currentPage: number) => void) => {
    triggerFunction = fn
  }

  const setPaginate = (perPage: number, currentPage: number, lastPage: number, total: number) => {
    setPerPage(perPage)
    setCurrentPage(currentPage)
    setLastPage(lastPage)
    setTotal(total)
  }

  const $reset = () => {
    setPerPage(defaultPagination.perPage)
    setCurrentPage(defaultPagination.currentPage)
    setLastPage(defaultPagination.lastPage)
    setTotal(defaultPagination.total)
    setMaxVisiblePages(defaultPagination.maxVisiblePages)
  }

  return {
    perPage,
    currentPage,
    lastPage,
    total,
    maxVisiblePages,

    setPerPage,
    setTotal,
    setLastPage,
    setCurrentPage,
    setMaxVisiblePages,
    setTriggerFunction,
    setPaginate,
    trigger,

    $reset
  }
}

export default usePagination
