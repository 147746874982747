import { KnowledgeManagePostsSingleGetResponse } from '@shared/swagger'
import { UserContent } from '../types'

function isAlertType (alert: string) : alert is 'Success' | 'Warning' | 'Error' {
  return alert === 'Success' || alert === 'Warning' || alert === 'Error'
}

function convertToFormContents (content : KnowledgeManagePostsSingleGetResponse['contents'][0]) : UserContent {
  const newContent : UserContent = {
    uuid: content.uuid,
    type_id: content.type_id,
    version: content.version,
    image: content.image === null ? '' : content.image,
    video: content.video === null ? '' : content.video,
    video_type: content.video_type === null ? '' : content.video_type,
    content: content.content,
    active: content.active,
    visible: !!content.visible,
    alert_type: isAlertType(content.alert_type) ? content.alert_type : 'Success',
    alert_text: content.alert_text
  }
  return newContent
}

function convertFetchedDataToForm (data: KnowledgeManagePostsSingleGetResponse) {
  const postDetails = {
    title: data.title,
    description: data.description,
    category_uuid: data.category_uuid
  }
  const contents : UserContent[] = data.contents.map(convertToFormContents)
  const published = !!data.published

  return { postDetails, contents, published }
}

export default convertFetchedDataToForm
