import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ServiceAdvisorAssignmentResourceGetRequest,
  ServiceAdvisorAssignmentResourceGetResponse
} from '@shared/swagger'

export default async function getServiceAdvisorAssignmentResource (params: ServiceAdvisorAssignmentResourceGetRequest): Promise<AxiosResponse<ServiceAdvisorAssignmentResourceGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceAdvisorAssignmentResourceGetResponse>({
    url: 'service/manage/advisors/resources',
    body: { params: params },
    loader: 'get-service/manage/advisors/resources'
  })

  return response
}
