import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationSupervisionsPostRequest } from '@shared/swagger'
import { postProfileEducationSupervisions } from '@shared/api'

import type { StoreType } from '../types'

export default async function addSupervision (this: StoreType, values: ProfileEducationSupervisionsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducationSupervisions(values)

  if (ok) await this.fetchSupervisions()

  return {
    status: ok,
    message
  }
}
