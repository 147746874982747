<template>
  <pms-card
    :title="$t('domains.knowledge.all-posts.related-posts.title')"
    :description="$t('domains.knowledge.all-posts.related-posts.description')"
    :options="{ body: { class: 'pt-0'} }"
  >
    <template
      v-for="(post, index) in relatedPosts"
      :key="post.uuid"
    >
      <div :class="{ 'mt-4': index !== 0 }">
        <p class="fs-5 fw-bold mb-0">
          {{ post.title }}
        </p>
        <p class="fs-6 text-muted p-0 mb-1 m-0">
          {{ cutContent(post.description, { endWith: '...', length: 100, removeHtmlTags: true }) }}
        </p>
        <KnowledgeBaseSingleLinkPost :uuid="post.uuid" />
      </div>
    </template>
    <pms-no-data v-if="relatedPosts.length === 0" />
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useResource } from '@shared/stores'
import { cutContent } from '@shared/utils'

import KnowledgeBaseSingleLinkPost from './KnowledgeBaseSingleLinkPost.vue'

const resource = useResource()
const relatedPosts = computed(() => resource.getKnowledgeResourceRelated.slice(0, 3))
</script>
