import type { StoreType } from '../types'
import { postSectionManagementRequestQuotas } from '@shared/api'
import { SectionManagementRequestsQuotasPostRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@shared/types'
import { currentCourse } from '../../values'

export default async function createReceivedRequestQuota (this: StoreType, requestId: number, params: SectionManagementRequestsQuotasPostRequest): Promise<StatusMessageResponse> {
  const { message, ok } = await postSectionManagementRequestQuotas(requestId, params)

  if (ok) {
    await Promise.all([
      this.fetchReceivedRequests(currentCourse.value),
      this.fetchRequestsQuotasById(requestId)
    ])
  }

  return {
    status: ok,
    message
  }
}
