<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <transcript-component
      v-else
      id="single-student-transcript"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourTranscript } from '../../values'

import TranscriptComponent from './ui/TranscriptComponent.vue'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/transcript'])

onMounted(async () => {
  setQuickTourSteps(await quickTourTranscript())
})
</script>
