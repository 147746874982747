import { SmartTableHeader } from '@/shared/types'

export const headers: Array<SmartTableHeader> = [
  {
    title: '',
    field: 'checkbox',
    slotCellName: 'checkbox',
    cellClass: 'w-50px'
  },
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    cellClass: 'w-50px'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    cellClass: 'w-50px'
  },
  {
    title: 'common.credits',
    field: 'credits',
    slotCellName: 'credits',
    cellClass: 'w-50px'
  },
  {
    title: 'common.ects',
    field: 'ects',
    slotCellName: 'ects',
    cellClass: 'w-50px'
  },
  {
    title: 'common.language_code',
    field: 'language_code',
    slotCellName: 'language_code',
    cellClass: 'w-50px'
  }
]
