import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ServiceMessageBroadcastResourceResponse
} from '@shared/swagger'

export default async function getServiceMessageBroadcastResource (): Promise<AxiosResponse<ServiceMessageBroadcastResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceMessageBroadcastResourceResponse>({
    url: 'service/message-broadcast/resources',
    body: { },
    loader: 'service/message-broadcast/resources'
  })

  return response
}
