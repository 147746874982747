import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationConferencesPostRequest } from '@shared/swagger'
import { postProfileEducationConferences } from '@shared/api'

import type { StoreType } from '../types'

export default async function addConference (this: StoreType, values: ProfileEducationConferencesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducationConferences(values)

  if (ok) await this.fetchConferences()

  return {
    status: ok,
    message
  }
}
