import { QRCodeOptions } from 'qrcode'

export const opts = {
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  quality: 0.3,
  width: 500,
  height: 500,
  margin: 1
} as QRCodeOptions
