import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'

import type { DashboardWidgetsGoogleCalendarGetResponse } from '@shared/swagger'
import { UnknownRequest } from '@shared/swagger'

export default async function getDashboardWidgetsCalendarEventsTokens (): Promise<AxiosResponse<DashboardWidgetsGoogleCalendarGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, DashboardWidgetsGoogleCalendarGetResponse>({
    url: 'dashboard/widgets/google/calendar/tokens',
    body: { },
    loader: 'dashboard/widgets/google/calendar/tokens'
  })

  return response
}
