import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  AcademicManageSyllabusDeliveryTypesPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusModeDelivery (body: AcademicManageSyllabusDeliveryTypesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<AcademicManageSyllabusDeliveryTypesPutRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/delivery-types',
    body,
    loader: 'academic/manage/syllabus/delivery-types'
  })

  return response
}
