import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicCoursesSchedulesResponse, AcademicCoursesSchedulesRequest } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicCoursesSchedules (params: AcademicCoursesSchedulesRequest): Promise<AxiosResponse<AcademicCoursesSchedulesResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicCoursesSchedulesResponse>({
    url: 'academic/courses/schedules',
    body: { params },
    loader: 'academic/courses/schedules'
  })

  return response
}
