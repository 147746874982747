import { SyllabusWidget } from '../types/syllabus'
import { DashboardWidgetsResponse } from '@shared/swagger'
import { arrayGroupByProperty } from '@shared/utils/array'

const formatSyllabusStatistics = (statistics: DashboardWidgetsResponse['syllabus_statistics']['data']): Array<SyllabusWidget> => {
  const items = arrayGroupByProperty(statistics, 'department_code')
  const formatted: SyllabusWidget[] = []

  for (const [key, values] of Object.entries(items)) {
    const notApplied = values.find(item => item.status === 0)?.courses || 0
    const applied = values.find(item => item.status === 1)?.courses || 0
    const accepted = values.find(item => item.status === 2)?.courses || 0
    const rejected = values.find(item => item.status === 3)?.courses || 0
    const confirmed = values.find(item => item.status === 4)?.courses || 0

    formatted.push({
      title: values?.[0]?.title || key,
      notApplied,
      applied,
      accepted,
      rejected,
      confirmed
    })
  }
  return formatted
}

export default formatSyllabusStatistics
