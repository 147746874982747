<template>
  <div class="mt-5 ms-5">
    <pms-button
      :variant="'tonal'"
      class="py-1 ps-2 pe-0 btn-back"
      @click="stopQrScanner"
    >
      <pms-kt-icon
        :icon-name="'arrow-left'"
        class="fs-1"
      />
    </pms-button>
  </div>
  <div class="video-container">
    <video
      id="camera-video-here"
    />
  </div>
  <pms-modal-sidebar
    v-model:state="modalState"
    :title="'ascascascasc'"
    :description="'acascascasc'"
    :modal="'AttendanceQr'"
    :width="'100%'"
    :position="'bottom'"
    :scroll-out="false"
    :resize="false"
    :backdrop="false"
  >
    <user-first-action />
  </pms-modal-sidebar>
</template>
<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'
import { useAttendance } from '../composable'

import UserFirstAction from './StartAction.vue'

const { mountQrScanner, modalState, stopQrScanner } = useAttendance()

onMounted(mountQrScanner)

onBeforeUnmount(stopQrScanner)
</script>
<style scoped>
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.btn-back {
  position: fixed;
  z-index: 100;
}

.scan-region-highlight {
  position: absolute;
  pointer-events: none;
  transform: scaleX(-1);
  width: 200px;
  height: 200px;
  border-radius: 30px;
  outline: rgba(0, 0, 0, .4) solid 150vmax;
}
</style>
