import type { StoreType } from '../types'
import type { AcademicCoursesGradesExamReportsGetRequest } from '@shared/swagger'

import { getAcademicCoursesGradesExamReports } from '@shared/api'

export default async function fetchAcademicCoursesGradesExamReports (this: StoreType, values: AcademicCoursesGradesExamReportsGetRequest): Promise<void> {
  const { data, ok } = await getAcademicCoursesGradesExamReports(values)

  ok && this.setGradeExamReports(data)
}
