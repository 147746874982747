import { useResource } from '@shared/stores'

export default (activity_type_title: string | null): number => {
  if (activity_type_title !== null) {
    const activityTypes = useResource().getAcademicManageSyllabusResourceActivity
    if (activityTypes.length > 0) {
      return activityTypes.find((resource) => resource.title === activity_type_title)?.id ?? -1
    }
  }
  return -1
}
