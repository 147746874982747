import { ref } from 'vue'
import { FilterAnnouncements } from '../types'

export const tempValues = ref<FilterAnnouncements>({
  year: new Date()
})

export const setTempValues = (values: FilterAnnouncements) => {
  tempValues.value = { ...values }
}
