<template>
  <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
    <div
      id="kt_app_sidebar_menu_wrapper"
      class="app-sidebar-wrapper hover-scroll-overlay-y my-5"
      data-kt-scroll="true"
      data-kt-scroll-activate="true"
      data-kt-scroll-height="auto"
      data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
      data-kt-scroll-wrappers="#kt_app_sidebar_menu"
      data-kt-scroll-offset="5px"
      data-kt-scroll-save-state="true"
    >
      <div
        id="#kt_app_sidebar_menu"
        class="menu menu-column menu-rounded menu-sub-indention px-3"
        data-kt-menu="true"
      >
        <template
          v-for="(item, i) in SideBarMenu"
          :key="i"
        >
          <div
            v-if="item.heading"
            class="menu-item"
          >
            <div class="menu-content">
              <span class="menu-heading fw-bold text-uppercase fs-7">
                {{ $t(item.heading) }}
              </span>
            </div>
            <template
              v-for="(menuItem, j) in item.pages"
              :key="j"
            >
              <template v-if="menuItem.isRoute && (menuItem.moduleCode ? userStore.hasPermissionByModuleCode(menuItem.moduleCode) : true)">
                <div class="menu-item">
                  <router-link
                    class="menu-link"
                    :class="{ active: routePath === menuItem.route }"
                    :to="menuItem.route"
                  >
                    <span
                      v-if="menuItem.svgIcon || menuItem.fontIcon"
                      class="menu-icon"
                    >
                      <span
                        class="svg-icon svg-icon-2"
                      >
                        <inline-svg :src="menuItem.svgIcon" />
                      </span>
                    </span>
                    <span class="menu-title fs-6">{{ $t(menuItem.sectionTitle) }}</span>
                  </router-link>
                </div>
              </template>
              <div
                v-if="!menuItem.isRoute && hasSomePermission(menuItem.subPages)"
                :class="{ show: hasActiveChildren(menuItem.route) }"
                class="menu-item menu-accordion"
                data-kt-menu-sub="accordion"
                data-kt-menu-trigger="click"
              >
                <span class="menu-link">
                  <span
                    v-if="menuItem.svgIcon || menuItem.fontIcon"
                    class="menu-icon"
                  >
                    <span
                      class="svg-icon svg-icon-2"
                    >
                      <inline-svg :src="menuItem.svgIcon" />
                    </span>
                  </span>
                  <span class="menu-title">{{ $t(menuItem.sectionTitle) }}</span>
                  <span class="menu-arrow" />
                </span>
                <div
                  :class="{ show: hasActiveChildren(menuItem.route) }"
                  class="menu-sub menu-sub-accordion"
                >
                  <template
                    v-for="(item2, k) in menuItem.subPages"
                    :key="k"
                  >
                    <div
                      v-if="item2.isRoute && (item2.moduleCode ? userStore.hasPermissionByModuleCode(item2.moduleCode) : true)"
                      class="menu-item"
                    >
                      <router-link
                        class="menu-link"
                        :class="{ active: routePath === item2.route }"
                        :to="item2.route"
                      >
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot" />
                        </span>
                        <span class="menu-title">{{ $t(item2.sectionTitle) }}</span>
                      </router-link>
                    </div>
                    <div
                      v-if="!item2.isRoute"
                      :class="{ show: hasActiveChildren(item2.route) }"
                      class="menu-item menu-accordion"
                      data-kt-menu-sub="accordion"
                      data-kt-menu-trigger="click"
                    >
                      <span class="menu-link">
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot" />
                        </span>
                        <span class="menu-title">{{ $t(item2.sectionTitle) }}</span>
                        <span class="menu-arrow" />
                      </span>
                      <div
                        :class="{ show: hasActiveChildren(item2.route) }"
                        class="menu-sub menu-sub-accordion"
                      >
                        <template
                          v-for="(item3, item3Index) in item2.subPages"
                          :key="item3Index"
                        >
                          <div class="menu-item">
                            <router-link
                              class="menu-link"
                              :class="{ active: routePath === item3.route }"
                              :to="item3.route"
                            >
                              <span class="menu-bullet">
                                <span class="bullet bullet-dot" />
                              </span>
                              <span class="menu-title">
                                {{ $t(item3.sectionTitle) }}</span>
                            </router-link>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import SideBarMenu from '@shared-values/menu'
import useUser from '@shared/stores/useUser'
import { LayoutSubPage } from '@/shared/types/pattern'

const userStore = useUser()
const route = useRoute()
const scrollElRef = ref<{scrollTop: number|null}>({ scrollTop: null })

onMounted(() => {
  if (!scrollElRef.value.scrollTop) {
    scrollElRef.value.scrollTop = 0
  }
})

const hasActiveChildren = (match) => {
  return route.path.indexOf(match) !== -1
}

const hasSomePermission = (pages?: LayoutSubPage[]) => {
  if (!pages) return false
  // i.moduleCode === undefined если есть страница у которой нет permission code и должна показоваться
  return pages.some(i => userStore.hasPermissionByModuleCode(i.moduleCode + '') || i.moduleCode === undefined)
}

const routePath = computed(() => route.path)
</script>
