<template>
  <div class="d-flex flex-row gap-2 align-items-center">
    <span class="fs-5">
      {{ getOldBellCurvedGrade(studentId) }}
    </span>
    <pms-kt-icon
      :icon-name="'arrow-right'"
      class="text-primary fw-bold"
    />
    <div class="d-flex align-items-center gap-2">
      <input
        v-if="!isAbsent(studentId, assessmentId, true)"
        type="number"
        class="form-control p-2 w-50px fs-4 text-center"
        :disabled="isAbsent(studentId, assessmentId,true)"
        :value="getCurrentBellCurvedGrade(studentId)"
        @input="enforceMinMax($event.target)"
        @change="onAddGrade(studentId ,assessmentId, $event.target?.value)"
      >
      <span
        v-else
        class="text-danger me-2"
      >
        {{ $t('domains.academic.single-course.grade.absent') }}
      </span>
      <pms-dropdown
        :btn-text="''"
        :variant="'tonal'"
        :btn-class="'p-2 py-1'"
      >
        <div>
          <pms-button
            :text="
              isAbsent(studentId, assessmentId,true)
                ? 'common.undo'
                : `domains.academic.single-course.grade.absent`"
            :color="
              isAbsent(studentId, assessmentId,true)
                ? 'primary'
                : 'danger'"
            :size="'sm'"
            type="reset"
            @click="
              isAbsent(studentId, assessmentId, true)
                ? onAddGrade(studentId, assessmentId, 'undo-absent')
                : onAddGrade(studentId, assessmentId, 'absent')
            "
          />
        </div>
      </pms-dropdown>
    </div>
  </div>
</template>
<script setup lang="ts">

import { getOldBellCurvedGrade, getCurrentBellCurvedGrade, isAbsent } from '../utils'
import { PropType } from 'vue'
import { bellCurvedGrades } from '../values'

defineProps({
  assessmentId: {
    type: Number as PropType<number>,
    required: true
  },
  studentId: {
    type: String as PropType<string>,
    required: true
  }
})

const onAddGrade = (studentId: string, assessmentId: number, grade: string) => {
  if (bellCurvedGrades.value.assessment_id === assessmentId) {
    bellCurvedGrades.value = {
      ...bellCurvedGrades.value,
      grades: bellCurvedGrades.value.grades.map((g) => {
        if (g.student_id === studentId) {
          if (grade === 'absent') g.absent = true
          else if (grade === 'undo-absent') g.absent = false
          else g.currentGrade = parseInt(grade)
        }
        return g
      })
    }
  }
}

const enforceMinMax = (el) => {
  if (el.value !== '') {
    if (parseInt(el.value) < 0) {
      el.value = 0
    }
    if (parseInt(el.value) > 100) {
      el.value = 100
    }

    el.value = parseInt(el.value)
  }
}
</script>

  <style scoped lang="scss">
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  </style>
