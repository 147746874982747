import { FormGroup } from '@/shared/types'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useExamDates } from '@/domains/AcademicExamDates/store'
import { storeToRefs } from 'pinia'
import { useAlert, useLoader } from '@/shared/composable'
import { departmentOptions, instructorOptions, sectionTypeOptions, assessmentOptions } from './refs'

export default function UseExamDatesSearch () {
  const tempValues = ref<Record<string, string | number | null>>({
    year: null,
    term: null,
    department_id: null,
    instructor_id: null,
    course_code: null,
    section_type: null,
    assessment: null
  })

  const { t } = useI18n()
  const store = useExamDates()
  const { error } = useAlert()
  const { currentYearTerm, selectedDepartment } = storeToRefs(store)
  const { isActive, isActiveOneOf } = useLoader()

  const loader = isActiveOneOf(['academic/resources', 'academic/information/resources'])
  const searchLoader = isActive('academic/information/exam-dates')

  const formFields = computed(():Array<FormGroup> => [
    {
      class: 'row mb-3',
      fields: [
        // Department
        {
          group: {
            class: 'col-12 col-md-8'
          },
          name: 'department_id',
          class: 'w-100',
          type: 'slot',
          value: tempValues.value.department_id
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.course-code')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'course_code',
          class: 'w-100',
          type: 'text',
          value: tempValues.value.course_code
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: `${t('common.select-instructor')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'instructor_id',
          class: 'w-100',
          type: 'select',
          options: instructorOptions.value,
          value: tempValues.value.instructor_id
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: `${t('common.section-type.section-type')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'section_type',
          class: 'w-100',
          type: 'select',
          options: sectionTypeOptions.value,
          value: tempValues.value.section_type
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: `${t('common.assessment.assessment')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'assessment',
          class: 'w-100',
          type: 'select',
          options: assessmentOptions.value,
          value: tempValues.value.assessment
        }
      ]
    }
  ])

  const onSubmit = async ({ values }) => {
    if (values.department_id === null) {
      error({ text: t('domains.academic.exam-dates.department-error') })
      return
    }

    if (values.instructor && values.instructor_id === null) {
      error({ text: t('domains.academic.exam-dates.instructor-not-found-error', { name: values.instructor }) })
      return
    }

    const { status, message } = await store.fetchExamDates({ ...values, year: currentYearTerm.value.year, term: currentYearTerm.value.term })
    if (!status) error({ text: message })
    if (status) {
      selectedDepartment.value.department_id = values.department_id
      selectedDepartment.value.year = currentYearTerm.value.year
      selectedDepartment.value.term = currentYearTerm.value.term
    }
  }

  watch(
    () => currentYearTerm.value,
    (newValue) => {
      tempValues.value = {
        year: newValue.year,
        term: newValue.term,
        department_id: null,
        instructor_id: null,
        course_code: null,
        section_type: null,
        assessment: null
      }
    }
  )

  watch(
    () => tempValues.value.department_id,
    () => {
      tempValues.value.instructor_id = null
      tempValues.value.course_code = null
      tempValues.value.section_type = null
    }
  )

  return {
    formFields,
    searchLoader,
    onSubmit,
    loader,
    departmentOptions,
    currentYearTerm
  }
}
