import { useRequests } from '@shared/composable'
import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumCorePutRequest } from '@shared/swagger'

export default async function editCoreCourse (id: number, body: AcademicManageCurriculumCorePutRequest): Promise<StatusMessageResponse> {
  const { put } = useRequests()

  const { ok, message } = await put({
    url: `/academic/manage/curriculum/core/${id}`,
    body: body,
    loader: 'put-academic/manage/curriculum/core'
  })

  return {
    status: ok,
    message
  }
}
