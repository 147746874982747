<template>
  <pms-modal
    :name="'modal1'"
    :title="t('domains.schedule-management.section-program.apply-modal-title')"
    :description="t('domains.schedule-management.program.apply-modal-description')"
  >
    <ApplyProgramContent :program="selectedProgram" />
  </pms-modal>
  <pms-teleport-modal
    :name="'schedule-management-modal'"
    :teleport-name="'schedule-management-modal-content'"
    :title="$t(`domains.schedule-management.${modifier?.translate?.title || 'title'}`)"
    :description="$t(`domains.schedule-management.${modifier?.translate?.description || 'description'}`)"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
  <pms-row class="mt-5">
    <pms-grid
      :col="8"
      :order-mb="2"
      class="mt-5 mt-md-0"
    >
      <pms-card
        :header="false"
        :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-4'}}"
      >
        <div class="d-flex flex-column">
          <div class="d-flex flex-rows align-items-center">
            <button
              title="Back"
              type="button"
              class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
              @click="back()"
            >
              <pms-kt-icon
                icon-name="arrow-left"
                class="m-0 p-0"
              />
            </button>
            <div class="d-flex flex-column justify-content-start">
              <h1 class="text-dark fs-2 fw-bold mb-1">
                {{ $t('domains.schedule-management.back-to-select-course') }}
              </h1>
            </div>
          </div>
          <p class="fs-6 mt-3 mb-4">
            {{ $t('domains.schedule-management.select-program-description') }}
          </p>
          <div class="d-flex flex-row flex-end">
            <button
              class="btn btn-primary btn-lg ms-2"
              @click="openPropertyModal(-1)"
            >
              {{ $t('domains.schedule-management.section-program.add-program') }}
            </button>
          </div>
        </div>
      </pms-card>
      <SectionProgramsTable class="mt-5" />
    </pms-grid>
    <pms-grid :col="4">
      <CourseDetailsSidebar />
    </pms-grid>
  </pms-row>
  <PropertyForm v-if="modifier && modifier.name === 'schedule-management-property-modal'" />
  <ScheduleForm v-if="modifier && modifier.name === 'schedule-management-schedule-modal'" />
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTeleportModal } from '@shared/composable'
import SectionProgramsTable from './ui/SectionProgramsTable.vue'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import CourseDetailsSidebar from './ui/CourseDetailsSidebar.vue'
import PropertyForm from './ui/PropertyForm.vue'
import ScheduleForm from './ui/ScheduleForm/ScheduleForm.vue'
import ApplyProgramContent from './ui/ApplyProgramContent.vue'
import { useRoute, useRouter } from 'vue-router'
import { useResource } from '@/shared/stores'

const resource = useResource()

const { t } = useI18n()
const { open, modifier, setModifier } = useTeleportModal()

const store = useScheduleManagement()
const route = useRoute()
const router = useRouter()
const sectionId = route.params.sectionId.toString()

onMounted(async () => {
  await Promise.all([
    store.fetchSingleSection(sectionId),
    store.fetchSingleSectionPrograms(sectionId)
  ])

  store.fetchSectionResources()
})

watch(() => store.singleSection, () => {
  if (store.singleSection) { resource.fetchYearTermScheduleHoursResources({ year: store.singleSection.year, term: store.singleSection.term }) }
})

const openPropertyModal = (id: number) => {
  setModifier('schedule-management-property-modal',
    { id },
    { title: 'title', description: 'description' }
  )
  open({ name: 'schedule-management-modal' })
}

const back = () => {
  router.push({
    name: 'ScheduleManagementPage'
  })
}
</script>
