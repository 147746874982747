import { SelectField } from '@shared/types'
import { AdministrativeGateEntryResourceUsersResponse } from '@shared/swagger'

export default (data: AdministrativeGateEntryResourceUsersResponse | null): SelectField['options'] => {
  const options: SelectField['options'] = []

  if (Array.isArray(data)) {
    data.forEach((item) => {
      options.push({
        label: `${item.full_name}`,
        value: item.user_id
      })
    })
  }

  return options
}
