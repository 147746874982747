import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicManageSyllabusGetRequest, AcademicManageSyllabusGetResponse
} from '@shared/swagger'

export default async function getAcademicManageSyllabus (params : AcademicManageSyllabusGetRequest): Promise<AxiosResponse<AcademicManageSyllabusGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageSyllabusGetResponse>({
    url: 'academic/manage/syllabus',
    body: { params: params },
    loader: 'academic/manage/syllabus'
  })

  return response
}
