<template>
  <div class="d-flex p-3">
    <pms-button
      :text="'common.lock'"
      class="ms-2 py-2 px-4"
      @click="toggleLockAction()"
    />
    <pms-button
      :text="'common.unlock'"
      class="ms-2 py-2 px-4"
      @click="unlockStudents(selectedStudents)"
    />
    <pms-button
      :text="'common.edit-period'"
      class="ms-2 py-2 px-4"
      @click="editLockAction()"
    />
  </div>
</template>

<script setup lang="ts">
import { useTeleportModal, useAlert } from '@shared/composable'
import { useAllStudents } from '../store'
import { selectedStudents, lockIdsForModal } from '../values'
import { search } from '../utils'
const { setModifier, open } = useTeleportModal()

const store = useAllStudents()
const { success, error } = useAlert()

const toggleLockAction = () => {
  const lockIds = selectedStudents.value.map(student => student.lock_id)
  lockIdsForModal.value = selectedStudents.value
  setModifier('locks-modal',
    { lock: lockIds, action: 'toggle' },
    { title: 'common.lock-students', description: 'common.toggle-students' }
  )
  open({ name: 'locks-modal' })
}

const editLockAction = () => {
  const lockIds = selectedStudents.value.map(student => student.lock_id)
  lockIdsForModal.value = selectedStudents.value
  setModifier('locks-modal',
    { lock: lockIds, action: 'edit' },
    { title: 'common.edit-locks', description: 'common.edit-students' }
  )
  open({ name: 'locks-modal' })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unlockStudents = async (selectedStudents: any[]) => {
  const values = { locks: [] as number[], is_active: true }

  if (selectedStudents.length > 0) {
    values.locks = values.locks.concat(selectedStudents.map(lock => lock.lock_id))
  }

  values.is_active = false

  const { status, message } = await store.unlock(values)

  if (status) {
    success({ text: message, isToast: true })
    selectedStudents = []
    search()
  } else {
    error({ text: message })
  }
}

</script>

<style scoped lang="scss">
#fixed-menu {
  position: fixed;
  height: 60px;
  width: inherit;
  bottom: 0px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
