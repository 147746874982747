<template>
  <pms-smart-table
    :items="programs"
    :headers="resourceProgramTable"
    :sortable="true"
  >
    <template #code="{ item }">
      <span class="fs-5 fw-bold">{{ item.code }}</span>
    </template>
    <template #level="{ item }">
      <pms-badge
        v-if="item.education_level.code"
        :value="item.education_level.code"
        color="success"
        :size="'md'"
        class="me-2"
      />
    </template>
    <template #cipher="{ item }">
      <span class="fs-5 fw-bold">{{ item.cipher }}</span>
    </template>
    <template #title="{ item }">
      {{ item.title }}
    </template>
    <template #language="{ item }">
      <span class="fs-5 fw-bold">{{ item.language.code }}</span>
    </template>
    <template #termsCount="{ item }">
      {{ item.terms_count }}
    </template>
    <template #faculty="{ item }">
      {{ item.faculty.code }}
    </template>
    <template #action="{ item }">
      <pms-button
        :size="'sm'"
        :variant="'tonal'"
        class="w-100px"
        @click="newRequestStore.setSelectProgram(item)"
      >
        {{ $t('common.details') }}
        <pms-kt-icon
          :icon-name="'arrow-right'"
          class="m-0 p-0 ms-2"
        />
      </pms-button>
    </template>
  </pms-smart-table>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useNewRequest } from './../store'
import { resourceProgramTable } from './../values'

import { useResource } from '@shared/stores'

const resource = useResource()
const newRequestStore = useNewRequest()

const programs = computed(() => resource.getSectionManagementResourcePrograms)
</script>
