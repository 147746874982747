<template>
  <Teleport to="#experience-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileProjectsGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { months, yearSuggestions } from '../values'
import { getIdByTitle, getTitleById, monthToOptionsType } from '../utils'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/education-edit', 'profile/education-post'])

const projects = computed(() => store.getProjects)

const defaultTempValues: ProfileProjectsGetResponse[0] = {
  id: -1,
  title: '',
  sponsor: '',
  position: '',
  description: '',
  project_link: '',
  is_ongoing: false,
  start_month: -1,
  start_year: -1,
  end_month: -1,
  end_year: -1
}

const tempValues = ref<ProfileProjectsGetResponse[0]>(objectDeepCopy(defaultTempValues))

const companies = computed(() => resource.getProfileResource?.companies || [])
const professions = computed(() => resource.getProfileResource?.professions || [])

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.experience.project.title-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    required: true,
    placeholder: t('domains.profile.experience.project.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.description-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'description',
    type: 'editor',
    value: tempValues.value.description,
    required: true
  },
  {
    label: {
      text: t('domains.profile.experience.project.project-link-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'project_link',
    type: 'url',
    value: tempValues.value.project_link,
    required: true,
    placeholder: t('domains.profile.experience.project.project-link-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.sponsor-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'sponsor',
    type: 'autocomplete',
    value: tempValues.value.sponsor,
    required: true,
    suggestions: {
      itemField: 'title',
      items: companies.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.project.sponsor-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.position-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'position',
    type: 'autocomplete',
    value: tempValues.value.position,
    required: true,
    suggestions: {
      itemField: 'title',
      items: professions.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.project.position-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.start-month-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_month',
    type: 'select',
    value: tempValues.value.start_month,
    required: true,
    options: monthToOptionsType(months)
  },
  {
    label: {
      text: t('domains.profile.experience.project.start-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_year',
    type: 'autocomplete',
    value: tempValues.value.start_year === -1 ? '' : tempValues.value.start_year,
    required: true,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.project.start-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.end-month-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_month',
    type: 'select',
    value: tempValues.value.end_month,
    options: monthToOptionsType(months),
    disabled: tempValues.value.is_ongoing
  },
  {
    label: {
      text: t('domains.profile.experience.project.end-year-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_year',
    type: 'autocomplete',
    value: tempValues.value.end_year === -1 ? '' : tempValues.value.end_year,
    disabled: tempValues.value.is_ongoing,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.project.end-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.project.is-ongoing-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'is_ongoing',
    type: 'radio',
    value: tempValues.value.is_ongoing || false,
    inline: true,
    options: [
      { id: '1', value: true, label: { text: 'True' } },
      { id: '1', value: false, label: { text: 'False' } }
    ]
  }
])
const closeModal = () => {
  close({ name: 'experience-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  values.is_ongoing = values.is_ongoing === 'true'
  if (!values.title) {
    error({ text: 'domains.profile.experience.experience.title-field-error' })
    return
  }
  if (!values.description) {
    error({ text: 'domains.profile.experience.experience.description-field-error' })
    return
  }
  if (!values.project_link) {
    error({ text: 'domains.profile.experience.experience.project-link-field-error' })
    return
  }
  if (!values.start_month) {
    error({ text: 'domains.profile.experience.experience.start-month-field-error' })
    return
  }
  if (!values.start_year) {
    error({ text: 'domains.profile.experience.experience.start-year-field-error' })
    return
  }
  const positionId = getIdByTitle(tempValues.value.position, professions.value)
  if (positionId) {
    values.position = positionId
  } else {
    values.position_new = tempValues.value.position
  }
  const companyId = getIdByTitle(tempValues.value.sponsor, companies.value)
  if (companyId) {
    values.sponsor = companyId
  } else {
    values.sponsor_new = tempValues.value.sponsor
  }
  const { status, message } =
            tempValues.value.id === -1
              ? await store.addProject({ ...values })
              : await store.editProject(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'experience-modal' })
    unsetModifier()
    store.fetchProjects()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactProject = projects.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactProject || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
