import type { StoreType } from '../types'
import type { ServiceManageAnnouncementsPostRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { putServiceManageSingleAnnouncement } from '@/shared/api'

export default async function editExistingRule (this: StoreType, announcementId: number, values: ServiceManageAnnouncementsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageSingleAnnouncement(announcementId, values)

  return {
    status: ok,
    message: message
  }
}
