import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { getServiceEsigns } from '@shared/api'
import { ServiceEsignGetRequest } from '@/shared/swagger'

export default async function fetchEsignStudents (this: StoreType, params: ServiceEsignGetRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceEsigns(params)
  if (ok) {
    this.setEsignStudents(data)
  }

  return {
    status: ok,
    message
  }
}
