import type { StoreType } from '../types'
import type { ResourceRequest } from '@shared/swagger'
import { getKnowledgeResource } from '@shared/api'

export default async function fetchKnowledgeResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getKnowledgeResource(body)

  if (ok) {
    this.setKnowledgeResource(data)
  }
}
