import type { StoreType } from '../types'
import type { SectionManagementRequestPostRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { postSectionManagementRequest } from '@shared/api'

export default async function sendNewRequest (this: StoreType, params: SectionManagementRequestPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postSectionManagementRequest(params)

  return {
    status: ok,
    message
  }
}
