<template>
  <template v-if="!(editing.status && sessionEditingStatus && !student.block.blocked)">
    <session-icon
      v-if="typeof sessionStatus === 'number' && statuses[sessionStatus]"
      :status="statuses[sessionStatus]"
    />
  </template>
  <template v-else>
    <div class="d-flex justify-content-center">
      <div
        class="hidden-btn-group"
      >
        <Transition>
          <pms-button
            :disabled="isBubbleOpen"
            :variant="'link'"
            :size="'sm'"
            class="main-btn p-0 m-0"
            :class="{'hidden':isBubbleOpen}"
            @mouseover="openBubble"
            @click="openBubble"
          >
            <session-icon
              v-if="sessionEditingStatus && statuses[sessionEditingStatus]"
              :status="statuses[sessionEditingStatus]"
              :icon-class="'fs-2hx m-0'"
            />
          </pms-button>
        </Transition>
        <div
          class="hidden-btns"
          @mouseleave="closeBubble"
        >
          <template
            v-for="(status) in Object.keys(statuses)"
            :key="status"
          >
            <div
              class="btn-item"
              :class="{'active': isBubbleOpen}"
            >
              <pms-button
                :variant="'link'"
                :size="'sm'"
                class="m-0 p-0 pe-2"
                @click="updateAttendance(status)"
              >
                <session-icon
                  :status="statuses[status]"
                  :icon-class="'fs-2hx m-0 cursor-pointer'"
                  @click="updateAttendance(status)"
                />
              </pms-button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { PropType, computed, ref } from 'vue'
import { StudentWithSchedule, Session } from '../types'
import { useAttendanceEditor } from '../composable'

import SessionIcon from './AttendanceTableBodySessionIcon.vue'

const isBubbleOpen = ref<boolean>(false)

const props = defineProps({
  student: {
    type: Object as PropType<StudentWithSchedule>,
    required: true
  },
  session: {
    type: Object as PropType<Session>,
    required: true
  }
})

const statuses = {
  0: 'absence',
  1: 'attended',
  2: 'permitted'
}

const { editing, updateEditingRecord } = useAttendanceEditor()

const sessionStatus = computed(() => {
  return props.student.schedule
    .find((item) => item.schedule_id === props.session.id)?.attend_id ?? ''
})

const sessionEditingStatus = computed(() => {
  return editing.value.records
    .find((item) => item.schedule_id === props.session.id && item.student_id === props.student.id)
    ?.attend_id || null
})

const updateAttendance = (status: string) => {
  updateEditingRecord(props.session.id, props.student.id, status)
  closeBubble()
}

const openBubble = () => {
  isBubbleOpen.value = true
}

const closeBubble = () => {
  isBubbleOpen.value = false
}
</script>
<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
  transition-delay: 0.25s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.hidden-btn-group {
  position: relative;
  z-index: 20;
  width: fit-content;
}
.hidden-btns {
  position: absolute;
  width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  top:-20px;
  left:-25px;
}
.main-btn{
  position: relative;
  z-index: 20;
  transition: .3s;
}
.btn-item {
  position: absolute;
  & button {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    opacity: 0;
  }
  &:nth-child(1) button {
    transition-delay: 0.25s ;
  }

  &:nth-child(3) button {
    transition-delay: 0s;
  }

  &:nth-child(2) button {
    transition-delay: 0.125s;
  }
}

.active {
  & button {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    opacity: 1;
  }
  &:nth-child(1) button {
    left: -30px;
    transition-delay: 0s;
  }

  &:nth-child(3) button {
    left: 30px;
    transition-delay: 0.25s;
  }

  &:nth-child(2) button {
    top: -25px;
    transition-delay: 0.125s;
  }
}

.hidden{
  opacity: 0;
}
</style>
