import { SmartTableHeader } from '@/shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.student_id',
    field: 'student_id'
  },
  {
    title: 'common.table.student',
    field: 'student_name'
  },
  {
    title: 'common.table.date',
    field: 'lesson_date'
  },
  {
    title: 'common.table.hour',
    field: 'lesson_time'
  }
]

export const statuses = {
  0: 'absence',
  1: 'attended',
  2: 'permitted'
} as Record<number, 'absence'|'attended'|'permitted'>
