import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationArticlesPostRequest } from '@shared/swagger'

export default async function postProfileEducationArticles (params: ProfileEducationArticlesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationArticlesPostRequest, UnknownResponse>({
    url: 'profile/education/articles',
    body: params,
    loader: 'profile/education/articles-post'
  })

  return response
}
