<template>
  <div
    class="d-flex gap-3"
    style="width: fit-content;"
  >
    <select
      class="form-select form-select w-175px"
      aria-label="Default select example"
      @change="changeMode($event)"
    >
      <option
        v-for="(mode, index) in modes"
        :key="index"
        :value="mode.value"
        :selected="selectedMode === mode.value"
      >
        {{ $t(mode.title) }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { AdvisorMode } from './../types'
import { useMyStudents } from './../store'
import { modes } from '../values'

import { usePagination } from '@shared/composable'
import { storeToRefs } from 'pinia'

const store = useMyStudents()
const { mode: selectedMode } = storeToRefs(store)

const { perPage } = usePagination()

const changeMode = (event) => {
  store.setMode(event.target.value as AdvisorMode)
  handleTableFetch()
}

const handleTableFetch = async () => {
  await (selectedMode.value === 'advisory' ? store.fetchMyStudents : store.fetchThesisStudents)({
    per_page: perPage.value,
    offset: 1
  })
}
</script>
