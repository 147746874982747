import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManageSinglePostPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putKnowledgeManageSinglePost (uuid: string, body: KnowledgeManageSinglePostPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<KnowledgeManageSinglePostPutRequest, UnknownResponse>({
    url: `knowledge/manage/posts/${uuid}`,
    body,
    loader: 'knowledge/manage/posts/single'
  })
  return response
}
