
import type { StoreType } from '../types'
import type { AcademicManageCurriculumCoreCoursesRequest } from '@/shared/swagger'
import getAcademicalCoreCoursesByTerm from '@/shared/api/AcademicCurricula/getAcademicalCoreCoursesByTerm'

export default async function fetchAcademicalCoreCoursesByTerm (this: StoreType, params: AcademicManageCurriculumCoreCoursesRequest): Promise<void> {
  const { data, ok } = await getAcademicalCoreCoursesByTerm({
    track_id: params.track_id,
    term: params.term
  })

  if (ok) {
    this.setCoreCoursesByTerm(data)
  }
}
