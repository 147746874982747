import type { StoreType } from '../types'
import type { SectionManagementSectionsPostRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { putSectionManagementSections } from '@shared/api'

export default async function putSingleSection (this: StoreType, id: number, values: SectionManagementSectionsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putSectionManagementSections(id, values)

  return {
    status: ok,
    message
  }
}
