<template>
  <pms-loader v-if="loader" />
  <div v-else>
    <template
      v-for="(courses, index) in mappedCourses"
      :key="index"
    >
      <h3 class="d-flex justify-content-center mt-3">
        {{ courses[0].track?.title }}
      </h3>
      <pms-smart-table
        :items="courses"
        :headers="requestCourseTable"
        :sortable="true"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #courseCode="{ item }">
          {{ item.code }}
        </template>
        <template #courseCodeHeader="{ item }">
          {{ $t(item.title) }}
        </template>
        <template #title="{ item }">
          {{ item.title }}
        </template>
        <template #term="{ item }">
          {{ item.term }}
        </template>
        <template #theory="{ item }">
          {{ item.theory }}
        </template>
        <template #practice="{ item }">
          {{ item.practice }}
        </template>
        <template #ects="{ item }">
          {{ item.ects }}
        </template>
        <template #action="{ item }">
          <pms-button
            :size="'sm'"
            :variant="'tonal'"
            @click="addNewRequest(item)"
          >
            {{ $t('phrase.add-new-request') }}
            <pms-kt-icon
              :icon-name="'arrow-right'"
              class="m-0 p-0 ms-2"
            />
          </pms-button>
        </template>
      </pms-smart-table>
    </template>
    <pms-smart-table
      v-if="mappedCourses.length === 0"
      :items="[]"
      :headers="requestCourseTable"
      :sortable="false"
    />
  </div>

  <NewRequestForm v-if="modifier && modifier.name === 'new-request-content'" />
</template>

<script setup lang="ts">
import { requestCourseTable } from '@domains/SectionManagement/features/NewRequest/values'
import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import { computed } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import NewRequestForm from '@domains/SectionManagement/features/NewRequest/ui/NewRequestForm.vue'
import { SectionManagementRequestCourseGetResponse } from '@/shared/swagger'

const { isActive } = useLoader()
const { open, modifier, setModifier } = useTeleportModal()
const loader = isActive('academic/manage/sections/courses')

const newRequestStore = useNewRequest()
const mappedCourses = computed(() => newRequestStore.getMappedProgramCourses)

async function addNewRequest (item: SectionManagementRequestCourseGetResponse[0]) {
  setModifier('new-request-content',
    { equicode: item.equicode, courseCode: item.code },
    { title: 'new-request-title', description: 'new-request-description' }
  )
  open({ name: 'new-request-modal' })
}
</script>
