import type { StoreType } from '../types'
import { putSectionManagementRequest } from '@shared/api'
import { SectionManagementRequestPostRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@shared/types'
import { useAlert } from '@shared/composable'

export default async function rejectRequest (this: StoreType, requestId: number, params: SectionManagementRequestPostRequest): Promise<StatusMessageResponse> {
  const { message, ok } = await putSectionManagementRequest(requestId, params)
  const { error, success } = useAlert()

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
