<template>
  <tbody>
    <template
      v-for="(student, index) in tableData"
      :key="index"
    >
      <tr
        :class="{
          'student-blocked': student.block.blocked || false
        }"
      >
        <td
          class="text-center"
          style="max-width: 50px !important;"
        >
          <span class="text-dark-75 fw-semibold">{{ index + 1 }}</span>
        </td>
        <td class="text-start">
          <attendance-table-body-student-cell
            :student="student"
          />
        </td>
        <template
          v-for="(date) in tableHeaders.headers[currentTableHeaderIndex]"
        >
          <template
            v-for="(session, sessionIndex) in date.sessions"
            :key="sessionIndex"
          >
            <td class="text-center align-items-center">
              <attendance-table-body-session-cell
                :student="student"
                :session="session"
              />
            </td>
          </template>
        </template>

        <td class="text-center">
          <span class="text-dark-75 fw-semibold fs-6 px-0 mx-0">{{ student.attendance.attend_count }}</span>
        </td>
        <td class="text-center">
          <span class="text-dark-75 fw-semibold fs-6 px-0 mx-0">{{ student.attendance.absence_count }}</span>
        </td>
        <td class="text-center">
          <span class="text-dark-75 fw-semibold fs-6 px-0 mx-0">{{ student.attendance.permit_count }}</span>
        </td>
        <td class="text-center">
          <span class="text-dark-75 fw-semibold fs-6 px-0 mx-0">{{ student.absence_rate.section }}</span>
        </td>
        <td class="text-center">
          <span class="text-dark-75 fw-semibold fs-6 px-0 mx-0">{{ student.absence_rate.course }}</span>
        </td>
      </tr>
    </template>
  </tbody>
</template>
<script setup lang="ts">
import { useAttendance } from '../composable'

import AttendanceTableBodyStudentCell from './AttendanceTableBodyStudentCell.vue'
import AttendanceTableBodySessionCell from './AttendanceTableBodySessionCell.vue'

const { tableData, tableHeaders, currentTableHeaderIndex } = useAttendance()
</script>
<style scoped lang="scss">
.student-blocked > td {
  --bs-table-bg-type: #f8d7da !important;
}
</style>
