<template>
  <div
    id="kt_app_sidebar_footer"
    class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
  >
    <a
      href="/knowledge-base"
      class="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
      data-bs-toggle="tooltip"
      data-bs-trigger="hover"
      data-bs-dismiss-="click"
      :title="$t('layout.sidebar.footer.title-tooltip')"
    >
      <span class="btn-label">
        {{ $t('layout.sidebar.footer.title') }}
      </span>
    </a>
  </div>
</template>

<script setup lang="ts">
</script>
