<template>
  <p class="fs-5 fw-bold p-0 mb-2">
    {{ $t('domains.academic.manage.sections.request-search-component') }}
  </p>
  <div class="d-flex gap-4 align-items-center">
    <div
      class="form-group"
    >
      <label for="inputCourseCode">{{ $t('common.course-code') }}</label>
      <input
        id="inputCourseCode"
        name="SearchRequestCourseCode"
        class="form-control"
        type="text"
        :value="inputs.course_code"
        @input="setCourseCode($event)"
      >
    </div>

    <div
      class="form-group"
    >
      <label for="inputCourseTitle">{{ $t('common.course-title') }}</label>
      <input
        id="inputCourseTitle"
        name="SearchRequestCourseTitle"
        class="form-control"
        type="text"
        :value="inputs.title"
        @input="setCourseTitle($event)"
      >
    </div>

    <pms-button
      class="mt-5"
      :size="'sm'"
      :disabled="isDisabled"
      @click="handleSearchRequests"
    >
      <span class="fw-bold fs-6">
        {{ $t('common.search') }}
      </span>
    </pms-button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useNewRequest } from '../store'

const newRequestStore = useNewRequest()
const inputs = ref({
  course_code: null,
  title: null
})

const isDisabled = computed(() => !inputs.value.course_code && !inputs.value.title)

async function handleSearchRequests () {
  newRequestStore.isFetched = true
  await newRequestStore.fetchRequestCourses(
    {
      request_by: newRequestStore.requestType || '',
      program_code: newRequestStore.selectedProgram?.code,
      program_year: newRequestStore.selectedProgram?.active_year,
      course_code: inputs.value.course_code || undefined,
      title: inputs.value.title || undefined
    }
  )
}

function setCourseCode ($event) {
  if ($event.target.value) {
    inputs.value.course_code = $event.target.value
  } else {
    inputs.value.course_code = null
  }
}

function setCourseTitle ($event) {
  if ($event.target.value) {
    inputs.value.title = $event.target.value
  } else {
    inputs.value.title = null
  }
}
</script>
