import { AcademicCoursesStudentsResponse } from '@shared/swagger'
import { generateHtml } from './printSingleCourseStudentsList'

const excelDownloadAction = (data: AcademicCoursesStudentsResponse): void => {
  const table = generateHtml(data)
  if (table) {
    const url = 'data:application/vnd.ms-excel,' + escape(table)

    window.open(url, '_blank')
  }
}
export default excelDownloadAction
