<template>
  <a
    id="print-syllabus"
    href="javascript:void(0)"
    @click="onPrint()"
  >
    <div class="symbol symbol-40px symbol-rounded">
      <span class="symbol-label bg-primary">
        <i class="bi bi-printer text-white fs-3" />
      </span>
    </div>
  </a>

  <div class="d-none">
    <div id="printMe">
      <syllabus-information />
      <syllabus-content />
      <syllabus-advanced />
      <syllabus-policy />
    </div>
  </div>
</template>
<script setup lang="ts">
import { printSyllabus } from '../utils'

import SyllabusAdvanced from './SyllabusAdvanced.vue'
import SyllabusContent from './SyllabusContent.vue'
import SyllabusPolicy from './SyllabusPolicy.vue'
import SyllabusInformation from './SyllabusInformation.vue'

const onPrint = () => {
  const element = document.getElementById('printMe')

  if (!element) return
  printSyllabus(element)
}

</script>

<style scoped lang="scss">

</style>
