import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  AdministrativeMessageBoardPostsPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postAdministrativeMessageBoardPosts (params: AdministrativeMessageBoardPostsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<AdministrativeMessageBoardPostsPostRequest, UnknownResponse>({
    url: 'administrative/message-board/posts',
    body: params,
    loader: 'administrative/message-board/posts-post'
  })

  return response
}
