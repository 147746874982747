import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCurriculumNavigationRequest, AcademicManageCurriculumNavigationResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicManageCurriculumNavigation (params: AcademicManageCurriculumNavigationRequest): Promise<AxiosResponse<AcademicManageCurriculumNavigationResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumNavigationResponse>({
    url: 'academic/manage/curriculum/navigation',
    body: { params },
    loader: 'academic/manage/curriculum/navigation'
  })

  return response
}
