<template>
  <tr>
    <td
      v-styler="{paddingLeft: '30px'}"
      class="w-150px py-5"
    >
      <a
        class="d-flex align-items-center text-dark"
        href="javascript:void(0)"
        @click="handleRedirectToMessage(message.id)"
      >
        <div class="symbol symbol-35px me-3">
          <div
            class="symbol-label"
            :class="{
              'bg-light-primary': !message.message_from,
              'bg-light-warning': message.message_from,
            }"
          >
            <span
              :class="{
                'text-primary': !message.message_from,
                'text-warning': message.message_from,
              }"
            >{{ getAuthorFirstLetters(message.author) }}</span>
          </div>
        </div>
        <span class="fw-semibold">{{ message.author }}</span>
      </a>
    </td>
    <td class="py-5">
      <div class="text-dark me-6">
        <a
          class="text-dark"
          href="javascript:void(0)"
          @click="handleRedirectToMessage(message.id)"
        >
          <span class="fw-bold fs-5">{{ message.subject ? message.subject : $t('domains.service.message-service.no-subject') }}</span>
          <span
            v-if="message.message_from"
            v-styler="{ marginLeft: '10px' }"
            class="badge badge-light-warning"
          > {{ message.message_from }} </span>
          <span class="d-md-block fw-bolder text-muted mt-2">
            {{
              view === 'inbox' ?
                message.receives_count > 1
                  ? $t('domains.service.message-service.received-lot', { receivers: message.receives_count })
                  : $t('domains.service.message-service.received', { receivers: message.receives_count })
                :
                message.receives_count > 1
                  ? $t('domains.service.message-service.sent-lot', { receivers: message.receives_count })
                  : $t('domains.service.message-service.sent', { receivers: message.receives_count })
            }}
          </span>
          <span
            class="d-md-block text-muted"
            v-html="cutContent(message.content, {length: 100, removeHtmlTags: true})"
          />
        </a>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import { useRouter } from 'vue-router'

import { cutContent } from '@shared/utils'
import { ServiceMessageBroadcastGetResponse } from '@shared/swagger'
import { getAuthorFirstLetters } from '../utils'
import { useMessageBroadcast } from '../store'

defineProps({
  message: {
    type: Object as PropType<ServiceMessageBroadcastGetResponse['messages'][0]>,
    required: true
  }
})

const router = useRouter()
const store = useMessageBroadcast()

const view = computed(() => store.getCurrentMessageView)

function handleRedirectToMessage (id: number) {
  router.push({
    path: `/services/message-broadcast/${id}`,
    params: {
      id: id
    }
  })
}
</script>
