import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceEsignResourceGetRequest, ServiceEsignResourceGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceEsignsResource (params: ServiceEsignResourceGetRequest): Promise<AxiosResponse<ServiceEsignResourceGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceEsignResourceGetResponse>({
    url: 'service/manage/esigns/resources',
    body: { params: params },
    loader: 'service/manage/esigns/resources'
  })

  return response
}
