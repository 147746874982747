import { createPrototype } from '@shared-utils/storybook'
import MainPage from './../MainPage.vue'

const Template = createPrototype()

export const App = Template.bind({})

export default {
  title: 'Prototype/Prototype',
  component: MainPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
