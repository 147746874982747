<template>
  <pms-card
    :header="false"
  >
    <syllabus-content-academic-skills
      :academic-skills="academicSkills"
    />

    <syllabus-content-subject-skills
      :subject-skills="subjectSkills"
    />

    <syllabus-content-weekly-plan
      :weekly-plan="weeklyPlan"
    />

    <syllabus-content-learning-outcomes
      :learning-outcomes="learningOutcomes"
    />
  </pms-card>
</template>

<script setup lang="ts">

import { computed } from 'vue'
import { useSingleCourse } from '@domains/AcademicSingleCourse/store'

import SyllabusContentAcademicSkills from './SyllabusContentAcademicSkills.vue'
import SyllabusContentSubjectSkills from './SyllabusContentSubjectSkills.vue'
import SyllabusContentWeeklyPlan from './SyllabusContentWeeklyPlan.vue'
import SyllabusContentLearningOutcomes from './SyllabusContentLearningOutcomes.vue'

const store = useSingleCourse()

const academicSkills = computed(() => store.getSyllabusAcademicSkills)
const subjectSkills = computed(() => store.getSyllabusSubjectSkills)
const weeklyPlan = computed(() => store.getSyllabusWeeklyPlan)
const learningOutcomes = computed(() => store.getSyllabusLearningOutcomes)

</script>
