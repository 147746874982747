import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useModal } from '@shared/composable'
import { isTranslatable } from '@shared/utils'

const feedbackOptions = [
  'domains.feedback.general.page-feedback',
  'domains.feedback.general.content-feedback',
  'domains.feedback.general.bug-report'
]

export default defineComponent({
  name: 'LayoutRow',
  props: {
    options: {
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    buttonType: {
      type: String as PropType<'icon' | 'text'>,
      default: 'icon'
    },
    buttonClass: {
      type: String,
      default: ''
    }
  },
  setup (props, { slots }) {
    const { t } = useI18n()
    const { open, close } = useModal()
    const { error } = useAlert()

    const selectRef = ref(null)
    const openFeedbackModal = () => {
      open({ name: 'feedback-modal' })
    }

    const onSelect = (event) => {
      selectRef.value = event.target.value
    }

    const lead = () => {
      if (selectRef.value) {
        const url = `mailto:automation@sdu.edu.kz?subject=${selectRef.value}&body=[PAGE=${window.location.href}] - ${t('common.feedback-modal.content-short')} %0D%0A%0D%0A%0D%0A%0D%0A`
        window.open(url, '_blank')
        close({ name: 'feedback-modal' })
      } else {
        error({
          title: t('common.feedback-modal.error-title'),
          text: t('common.feedback-modal.select-option')
        })
      }
    }

    return () => (
      <>
        { slots.button
          ? slots.button({ onClick: openFeedbackModal })
          : (
              <pms-button
                id={'feedback-modal-btn'}
                type={'button'}
                color={'primary'}
                variant={'tonal'}
                text={t('common.leave-feedback')}
                title={t('common.leave-feedback')}
                size={'sm'}
                class={
                  props.buttonClass + ' fs-5' + (
                    props.buttonType === 'text'
                      ? ' w-150px'
                      : ' ps-3 pe-3'
                  )
                }
                onClick={ () => openFeedbackModal() }
              >
                { props.buttonType === 'icon' && (
                  <pms-kt-icon
                    icon-name="message-programming"
                    class="fs-2x p-0"
                  />
                )}
                {
                  props.buttonType === 'text' && (
                    <>{ t('common.leave-feedback') }</>
                  )
                }
              </pms-button>
            )
        }
        <pms-modal
          name="feedback-modal"
          title="common.feedback-modal.title"
          description="common.feedback-modal.description"
          keyboard={false}
          backdrop={false}
          centered={true}
          size="md"
          options={{
            content: { class: 'rounded' },
            header: { class: 'pb-0 pt-4 border-0 px-6 justify-content-between' },
            body: { class: 'scroll-y px-10 pb-10 pt-0' }
          }}
        >
          <div
            class="fs-6 fw-semibold"
            v-html={ t('common.feedback-modal.content') }
          />
          <label class="fs-5 fs-bold mb-2 p-0">{ t('common.feedback-modal.select-category') }:</label>
          <select
            class="form-select mt-0"
            onInput={ (e) => onSelect(e) }
          >
            <option selected value={null}>{ t('common.not-selected') }</option>
            {
              [...props.options, ...feedbackOptions].map((option) => {
                return <option value={isTranslatable(option) ? t(option) : option}>{isTranslatable(option) ? t(option) : option}</option>
              })
            }
          </select>
          <pms-button
            type={'button'}
            color={'primary'}
            text={t('common.continue')}
            size={'sm'}
            class="w-100 py-4 px-4 mt-3"
            onClick={ () => lead() }
          />
        </pms-modal>
      </>
    )
  }
})
