<template>
  <vue-resizable
    id="message-board-widget"
    class="card carousel carousel-custom carousel-stretch slide resizable-widget"
    :width="widgetSize.width"
    min-height="200"
    min-width="420"
    :height="widgetSize.height"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div
      class="card-header d-flex align-items-center justify-content-between flex-wrap"
      style="min-height: 30px; padding-top: 20px; padding-bottom: 5px;"
    >
      <span class="fs-4 fw-bolder">
        {{ $t('domains.dashboard.widgets.message-board.title') }}
      </span>
      <ol
        class="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-primary"
      >
        <li
          v-for="index in messages.length"
          :key="index"
          v-test-id="'item-dots'"
          class="ms-1 cursor-pointer"
          :class="{ active: index - 1 == carouselElement }"
          aria-current="true"
          @click="carouselElement = index - 1"
        />
      </ol>
    </div>
    <div class="card-body carousel-inner pt-2">
      <div
        v-for="(message, index) in messages"
        :key="index"
        v-test-id="'items'"
        class="carousel-item"
        :class="{ 'active show': index === carouselElement}"
      >
        <div class="m-0">
          <span
            v-test-id="'item-title'"
            class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base cursor-pointer"
            @click="goto(message.id)"
          >
            {{ message.title }}
          </span>
          <div
            v-test-id="'item-content'"
            class="fw-bold fs-5 text-gray-600 text-dark mt-2 mb-5"
            v-html="removeHtml(message.content).slice(0, 150).trim() + '...'"
          />
          <span
            v-test-id="'item-date'"
            class="text-muted me-2 fs-7"
          >
            {{ $t('phrases.message-send-at-by', { sendAt: message.created_at, sentBy: message.username }) }}
          </span>
        </div>
      </div>
      <div
        v-if="messages.length === 0"
        v-test-id="'no-data'"
        class="d-flex align-items-center justify-content-center min-h-100px"
      >
        {{ $t('common.no-data') }}
      </div>
    </div>
  </vue-resizable>
</template>

<script lang="ts" setup>
import { ref, onUnmounted, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useDashboard } from '../store'
import { storeToRefs } from 'pinia'
import { deviceType } from '@/shared/utils'
import VueResizable from 'vue-resizable'
import { onResize, getSavedWidgetSize } from '../utils'

const router = useRouter()
const store = useDashboard()
const device = deviceType()

const { posts: messages } = storeToRefs(store)

const carouselElement = ref<number>(0)
const removeHtml = (text: string) => text.replace(/<(?:.|\n)*?>/gm, '')

const timer = setInterval(() => {
  carouselElement.value = carouselElement.value + 1
  if (carouselElement.value >= messages.value.length) carouselElement.value = 0
}, 5000)

onUnmounted(() => {
  clearInterval(timer)
})

const goto = async (postId: number) => {
  await router.push({ name: 'MessageBoardPage', query: { postId } })
}

const widgetId = 'messages-widget'

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '50%', height: '210px' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>

<style>
.carousel.carousel-custom .carousel-indicators {
  align-items: center;
  position: static;
  z-index: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

.carousel.carousel-custom .carousel-indicators.carousel-indicators-bullet li.active {
  transition: all 0.3s ease;
  background-color: #33589E;
  height: 6px;
  width: 16px;
}

.carousel.carousel-custom .carousel-indicators.carousel-indicators-bullet li {
  transition: all 0.3s ease;
  background-color: #D9D9D9;
  border-radius: 6px;
  height: 6px;
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carousel.carousel-custom .carousel-indicators li {
  transform: none;
  opacity: 1;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 !important;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
</style>
