import { useRequests } from '@shared/composable'

import type { ProfileAddressesGetRequest, ProfileAddressesGetResponse, UnknownRequest } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileAddresses (params: ProfileAddressesGetRequest): Promise<{ address_type: string, response: AxiosResponse<ProfileAddressesGetResponse>}> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileAddressesGetResponse>({
    url: 'profile/addresses',
    body: { params },
    loader: 'profile/addresses'
  })

  return {
    address_type: params.type,
    response
  }
}
