import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileCertificatesGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileCertificates (): Promise<AxiosResponse<ProfileCertificatesGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileCertificatesGetResponse>({
    url: 'profile/certificates',
    body: {},
    loader: 'profile/certificates'
  })
  return response
}
