import type{ AcademicReportsExecutePostRequest } from '@shared/swagger'

import type { StoreType } from '../types'
import axios from 'axios'
import { useAlert, useLoader } from '@/shared/composable'
import { downloadFile } from '@/shared/utils'

export default async function (this: StoreType, request:AcademicReportsExecutePostRequest): Promise<void> {
  const loader = useLoader()
  const alertService = useAlert()
  loader.activate('academic/information/reports/export')

  axios.post(`academic/information/reports/${request.report}/export`, request, {
    responseType: 'blob'
  }).then(response => {
    const now = new Date()
    const name = `report_${now.getFullYear()}.${now.getMonth()}.${now.getDate()}_${now.getHours()}.${now.getMinutes()}.xlsx`

    downloadFile(new Blob([response.data]), name)
  }).catch(error => {
    alertService.error({ text: error.response.data.message ?? error })
  }).finally(() => {
    loader.deactivate('academic/information/reports/export')
  })
}
