<template>
  <div
    v-if="courses.length > 0"
    class="col-12"
  >
    <pms-card
      :title="'domains.academic.my-courses.list.title'"
      :description="'domains.academic.my-courses.list.description'"
      :options="{
        body: { class: 'pt-0' }
      }"
    >
      <pms-smart-table
        :headers="tableHeaders"
        :items="courses"
        :sortable="true"
        :options="{ wrapper: { responsive: false }}"
      >
        <template #details="{value}">
          <span class="fs-bold fs-5">{{ value }}</span>
        </template>
        <template #courseCode="{ value }">
          <span class="fw-bold fs-6">{{ value }}</span>
        </template>
        <template #item-courseTitle="{ row }">
          <span class="fw-bold fs-6">{{ row[`title_${localeShortest}`] }}</span>
        </template>
        <template #item-departmentTitle="{ row }">
          <div class="d-flex align-items-center">
            <div class="d-flex justify-content-start flex-column">
              <span class="text-gray-800 fw-bold mb-1 fs-6">
                {{ row.department_title }}
              </span>
              <span class="text-gray-400 fw-semibold d-block fs-7">
                {{ row.department_code }}
              </span>
            </div>
          </div>
        </template>
        <template #item-action="{ row }">
          <my-course-actions :course="row" />
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <empty-card
    v-else
    title="domains.academic.my-courses.empty"
  />
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { useLocale } from '@shared/composable'
import type { AcademicCoursesMyResponse, AcademicCoursesResourcesResponse } from '@shared/swagger'

import { tableHeaders } from '../values'
import MyCourseActions from './MyCourseActions.vue'
import EmptyCard from '@shared/ui/components/EmptyCard.vue'

defineProps({
  courses: {
    type: Array as PropType<AcademicCoursesMyResponse>,
    required: true
  },
  currentYearTerm: {
    type: Object as PropType<AcademicCoursesResourcesResponse['my_year_terms'][0]>,
    required: true
  }
})

const { localeShortest } = useLocale()
</script>
