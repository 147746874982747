import type { StatusMessageResponse } from '@shared/types'
import type{ ServiceManageLanguagesSingleApplicantPutRequest } from '@shared/swagger'
import { putServiceManageLanguagesSingleApplicant } from '@shared/api'

import type { StoreType } from '../types'

export default async function putSingleApplicant (this: StoreType, id: string, values: ServiceManageLanguagesSingleApplicantPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesSingleApplicant(id, values)

  return {
    status: ok,
    message
  }
}
