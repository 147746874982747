import type { StoreType } from '../types'

import { getDashboardWidgetsCalendarEventsTokens } from '@shared/api'

export default async function getCalendarEventsToken (this: StoreType): Promise<string|undefined> {
  const { ok, data } = await getDashboardWidgetsCalendarEventsTokens()
  if (ok) {
    this.refresh_token = data.refresh_token
  } else {
    this.refresh_token = undefined
  }
  return this.refresh_token
}
