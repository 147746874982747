import { objectToDotNotation } from '@shared/utils'
import { currentLanguage, messages } from '../values'

const getMessage = (key: string) => {
  const path = objectToDotNotation(messages)
  const language = currentLanguage.value

  return path[`${language}.${key}`]
}

export default getMessage
