import type { SelectField } from '@/shared/types'

export const toOptionsType = (data): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((el) => {
    options.push({
      label: el.title,
      value: el.id
    })
  })
  return options
}
export const monthToOptionsType = (data): SelectField['options'] => {
  const options: SelectField['options'] = []
  let id = 1
  data.forEach((el) => {
    options.push({
      label: el,
      value: id++
    })
  })
  return options
}
