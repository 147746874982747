<template>
  <div class="d-flex gap-5 mt-5 flex-column">
    <HeadInfo />
    <EditForm />
  </div>
</template>
<script setup lang="ts">
import { useResource } from '@/shared/stores'
import { onMounted } from 'vue'
import { useStore } from '../../store'
import { useRoute } from 'vue-router'
import HeadInfo from './ui/HeadInfo.vue'
import EditForm from './ui/EditForm.vue'

const store = useStore()
const route = useRoute()

const trackId = parseInt(route.params.trackId as string)
const courseId = parseInt(route.params.courseId as string)

const resource = useResource()

onMounted(() => {
  resource.fetchAcademicManageCurriculumResource({ modules: [] })
  if (trackId) {
    store.fetchCurriculumCore({ id: courseId })
  }
})

</script>

<style scope>
.separator {
  width: 100%
}
</style>
