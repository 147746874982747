<template>
  <Teleport to="#experience-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileExperiencesGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { toOptionsType, getTitleById, monthToOptionsType, getIdByTitle } from '../utils'
import { months } from '../values'
import { yearSuggestions } from '../../Publications/values'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/experiences-edit', 'profile/experiences-post'])

const selectedCity = ref('')
const selectedCompany = ref('')

const companies = computed(() => resource.getProfileResource?.companies || [])
const employment_types = computed(() => resource.getProfileResource?.employment_types || [])
const countries = computed(() => resource.getProfileResource?.countries || [])
const cities = computed(() => resource.getProfileResource?.cities || [])
const locations = computed(() => resource.getProfileResource?.location_types || [])

const experiences = computed(() => store.getExperiences)

const defaultTempValues: ProfileExperiencesGetResponse[0] = {
  id: -1,
  title: '',
  company_id: -1,
  start_month: -1,
  start_year: -1,
  end_month: -1,
  end_year: -1,
  country_id: -1,
  city_id: -1,
  description: '',
  industry: '',
  employment_type: -1,
  location_id: -1,
  is_current_position: false
}

const tempValues = ref<ProfileExperiencesGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.experience.experience.title-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    required: true,
    placeholder: t('domains.profile.experience.experience.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.description-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'description',
    type: 'editor',
    value: tempValues.value.description,
    required: true
  },
  {
    label: {
      text: t('domains.profile.experience.experience.sponsor-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'company_id',
    type: 'autocomplete',
    value: selectedCompany.value,
    required: true,
    suggestions: {
      itemField: 'title',
      items: companies.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.experience.sponsor-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.city-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'city_id',
    type: 'autocomplete',
    value: selectedCity.value,
    required: true,
    suggestions: {
      itemField: 'title',
      items: cities.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.experience.city-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.industry-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'industry',
    type: 'text',
    value: tempValues.value.industry,
    required: true,
    placeholder: t('domains.profile.experience.experience.industry-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.employment-type-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'employment_type',
    type: 'select',
    value: tempValues.value.employment_type,
    required: true,
    options: toOptionsType(employment_types.value)
  },
  {
    label: {
      text: t('domains.profile.experience.experience.location-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'location_id',
    type: 'select',
    value: tempValues.value.location_id,
    required: true,
    options: toOptionsType(locations.value)
  },
  {
    label: {
      text: t('domains.profile.experience.experience.country-type-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'country_id',
    type: 'select',
    value: tempValues.value.country_id,
    required: true,
    options: toOptionsType(countries.value)
  },
  {
    label: {
      text: t('domains.profile.experience.experience.start-month-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_month',
    type: 'select',
    value: tempValues.value.start_month,
    required: true,
    options: monthToOptionsType(months)
  },
  {
    label: {
      text: t('domains.profile.experience.experience.start-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_year',
    type: 'autocomplete',
    value: tempValues.value.start_year === -1 ? '' : tempValues.value.start_year,
    required: true,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.experience.start-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.end-month-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_month',
    type: 'select',
    value: tempValues.value.end_month,
    options: monthToOptionsType(months),
    disabled: tempValues.value.is_current_position
  },
  {
    label: {
      text: t('domains.profile.experience.experience.end-year-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_year',
    type: 'autocomplete',
    value: tempValues.value.end_year === -1 ? '' : tempValues.value.end_year,
    disabled: tempValues.value.is_current_position,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.experience.experience.end-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.experience.experience.is-ongoing-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'is_ongoing',
    type: 'radio',
    value: tempValues.value.is_current_position || false,
    inline: true,
    options: [
      { id: '1', value: true, label: { text: 'True' } },
      { id: '1', value: false, label: { text: 'False' } }
    ]
  }
])

const checkIfCompanyNew = (title: string): boolean => {
  const isNew = companies.value.find((company) => company.title === title)
  return !isNew
}
const closeModal = () => {
  close({ name: 'experience-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  if (name === 'city_id') {
    selectedCity.value = newValue
  } else if (name === 'company_id') {
    selectedCompany.value = newValue
  } else {
    tempValues.value[name] = newValue
  }
}

const onSubmit = async ({ values }) => {
  values.is_current_position = values.is_current_position === 'true'
  if (!values.title) {
    error({ text: 'domains.profile.experience.experience.title-field-error' })
    return
  }
  if (!values.description) {
    error({ text: 'domains.profile.experience.experience.description-field-error' })
    return
  }
  if (!values.industry) {
    error({ text: 'domains.profile.experience.experience.industry-field-error' })
    return
  }
  if (!values.start_year) {
    error({ text: 'domains.profile.experience.experience.start-year-field-error' })
    return
  }
  if (!tempValues.value.city_id) {
    error({ text: 'domains.profile.experience.experience.city-field-error' })
    return
  }
  if (!values.country_id) {
    error({ text: t('domains.profile.experience.experience.country-field-error') })
    return
  }
  if (!values.location_id) {
    error({ text: t('domains.profile.experience.experience.location-field-error') })
    return
  }
  if (!values.employment_type) {
    error({ text: t('domains.profile.experience.experience.employment-field-error') })
    return
  }
  values.city_id = getIdByTitle(selectedCity.value, cities.value)
  const isCompanyNew = checkIfCompanyNew(selectedCompany.value)
  if (isCompanyNew) {
    values.company_new = tempValues.value.company_id
    values.company_id = null
  } else {
    const companyId = getIdByTitle(selectedCompany.value, companies.value)
    values.company_id = companyId
  }
  const { status, message } =
            tempValues.value.id === -1
              ? await store.addExperience({ ...values })
              : await store.editExperience(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'experience-modal' })
    unsetModifier()
    store.fetchExperiences()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactExperience = experiences.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactExperience || defaultTempValues)
        selectedCity.value = getTitleById(tempValues.value.city_id, cities.value)
        selectedCompany.value = getTitleById(tempValues.value.company_id, companies.value)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
