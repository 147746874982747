<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <course-registration-component v-else />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourCourseRegistration } from '../../values'

import CourseRegistrationComponent from './ui/CourseRegistrationComponent.vue'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/course-registration'])

onMounted(async () => {
  setQuickTourSteps(await quickTourCourseRegistration())
})
</script>
