
const iterSubFields = (names, fields) => {
  for (const subField of fields) {
    names = {
      ...names,
      [subField.name]: null
    }
  }

  return names
}

const getFields = (fields) => {
  let fieldNames = {}

  for (const field of fields) {
    if ('fields' in field) {
      fieldNames = iterSubFields(fieldNames, field.fields)
    } else {
      fieldNames = iterSubFields(fieldNames, field)
    }
  }

  return fieldNames
}

export default (fields) => {
  return getFields(fields)
}
