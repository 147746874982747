import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useSingleSection = defineStore<Name, State, Getters, Actions>('Academic.useSingleSection', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state ():State {
    return {
      currentSection: {
        section_id: 0,
        section: '',
        bell_curve_enabled: false,
        last_moodle_sync_date: ''
      },
      course: null,
      students: [],
      consentRequests: [],
      examDates: [],
      grades: [],
      attendance: [],
      attendance_relocates: {
        rooms: [],
        hours: [],
        lessons: []
      },
      gradeExamReports: null,
      examParticipantsList: null
    }
  }
})
