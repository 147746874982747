import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileProjectsPostRequest } from '@shared/swagger'

export default async function postProfileProjects (params: ProfileProjectsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileProjectsPostRequest, UnknownResponse>({
    url: 'profile/projects',
    body: params,
    loader: 'profile/projects-post'
  })

  return response
}
