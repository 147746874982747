import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse,
  SectionManagementRequestsQuotasPutRequest,
  UnknownRequest
} from '@shared/swagger'

export default async function putSectionManagementRequestsQuota (quotaId: number, params: SectionManagementRequestsQuotasPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownRequest, UnknownResponse>({
    url: `academic/manage/sections/quotas/${quotaId}`,
    body: {
      quota_number: params.quota
    },
    loader: 'academic/manage/sections/quotas/{id}'
  })
  return response
}
