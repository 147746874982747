import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileContactsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileContacts (): Promise<AxiosResponse<ProfileContactsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileContactsGetResponse>({
    url: 'profile/contacts',
    body: {},
    loader: 'profile/contacts'
  })
  return response
}
