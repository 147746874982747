import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { currentLanguage } from '@/domains/AcademicSingleCourse/features/SyllabusPage/values'

const fetchSyllabusAfterChange = async (year: number, course_code: string) => {
  const singleCourseStore = useSingleCourse()
  await singleCourseStore.fetchSyllabus({
    course_code: course_code,
    year: year,
    language: currentLanguage.value
  })
}

export default fetchSyllabusAfterChange
