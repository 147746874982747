import type { StoreType } from '../types'
import type { SectionManagementRequestsQuotasReservesPostRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { postSectionManagementRequestsQuotasReserves } from '@shared/api'

export default async function postSentRequestReserves (this: StoreType, requestId: number, quotaId: number, params: SectionManagementRequestsQuotasReservesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postSectionManagementRequestsQuotasReserves(requestId, quotaId, params)

  return {
    status: ok,
    message
  }
}
