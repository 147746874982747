import type { StoreType } from '../types'
import type { AcademicCoursesConsentRequestsGetRequest } from '@shared/swagger'

import { getAcademicCoursesConsentRequests } from '@shared/api'

export default async function fetchConsentRequests (this: StoreType, values: AcademicCoursesConsentRequestsGetRequest): Promise<void> {
  const { ok, data } = await getAcademicCoursesConsentRequests(values)

  if (ok) {
    this.setConsentRequests(data)
  }
}
