import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  RulesAndRegulationsCategoriesGetResponse
} from '@shared/swagger'

export default async function getRulesAndRegulationsCategories (): Promise<AxiosResponse<RulesAndRegulationsCategoriesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, RulesAndRegulationsCategoriesGetResponse>({
    url: 'academic/information/rules/categories',
    body: { },
    loader: 'academic/information/rules/categories'
  })

  return response
}
