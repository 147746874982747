import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesByCourseStudentsGetRequest } from '@shared/swagger'

import { getAcademicSingleCourseAttendancesByCourseStudents } from '@shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { useAttendanceTable } from '../../features/Attendance/composable'
import { current_course } from '@/domains/AcademicSingleCourse/values'

export default async function (this: StoreType, params: AcademicSingleCourseAttendancesByCourseStudentsGetRequest): Promise<StatusMessageResponse> {
  const { data, message, ok } = await getAcademicSingleCourseAttendancesByCourseStudents(params)
  const { setTableData } = useAttendanceTable()

  if (ok) {
    current_course.value = this.attendance_courses.filter(course => course.course_code === params.course_code)[0]
    this.setAttendanceStudents(data)
    setTableData()
  }

  return {
    status: ok,
    message
  }
}
