<template>
  <pms-card
    :header="false"
  >
    <div
      :key="'policy.academic-integrity'"
      class="fs-2 fw-bold text-center text-danger mb-0"
    >
      {{ getMessage('policy.academic-integrity.title') }}
    </div>
    <div
      :key="'policy.academic-integrity.description'"
      class="fs-5 text-gray-900 mb-10 pt-2"
      style="text-align: justify;"
    >
      {{ getMessage('policy.academic-integrity.description') }}
    </div>

    <div
      :key="'policy.late-non-submission-and-attendance-policy'"
      class="fs-2 fw-bold text-center text-danger mb-0"
    >
      {{ getMessage('policy.late-non-submission-and-attendance-policy.title') }}
    </div>
    <div
      :key="'policy.late-non-submission-and-attendance-policy.description'"
      class="fs-5 text-gray-900 mb-10 pt-2"
      style="text-align: justify;"
    >
      {{ getMessage('policy.late-non-submission-and-attendance-policy.description') }}
    </div>

    <div
      :key="'policy.course-specific-policy'"
      class="fs-2 fw-bold text-center text-danger mb-0"
    >
      {{ getMessage('policy.course-specific-policy.title') }}
    </div>
    <div class="mb-4 mt-4">
      <template
        v-for="(item, index) in (policies || [])"
        :key="index"
      >
        <div
          class="fs-5 text-gray-900"
          style="text-align: justify;"
          v-html="item.title"
        />
      </template>
      <pms-no-data
        v-if="(policies || []).length === 0"
        class="mt-20 mb-20"
      />
    </div>
    <pms-smart-table
      :headers="header"
      :items="confirms"
      :translatable="false"
    >
      <template
        v-for="(title, index) in ['statusTitle', 'authorityTitle', 'lastModifiedTitle']"
        :key="index"
        #[title]="{ item }"
      >
        {{ getMessage(item.title) }}
      </template>
    </pms-smart-table>
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '../../../store'
import { currentLanguage, statusConfirmation as header } from '../values'
import { getMessage } from '../utils'
import { storeToRefs } from 'pinia'

const store = useSingleCourse()
const policies = computed(() => store.getSyllabusPolicies)

const { syllabus } = storeToRefs(store)
const confirms = computed(() => syllabus.value?.confirms.filter(confirm => confirm.language === currentLanguage.value.split('-')[1]) ?? [])

</script>
