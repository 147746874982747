import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSectionsGetRequest,
  ScheduleManagementSectionsGetResponse
} from '@shared/swagger'

export default async function getScheduleManagementSections (params: ScheduleManagementSectionsGetRequest): Promise<AxiosResponse<ScheduleManagementSectionsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSectionsGetResponse>({
    url: 'academic/manage/schedules/sections',
    body: { params },
    loader: 'academic/manage/schedules/sections'
  })

  return response
}
