<template>
  <pms-card
    :title="'domains.academic.editor.syllabus.components.title'"
    :description="'domains.academic.editor.syllabus.components.description'"
    class="mb-3"
    :options="{ body: { class: 'pt-0'} }"
  >
    <template #toolbar>
      <pms-button
        v-if="has_permission"
        size="sm"
        @click="openEditor()"
      >
        {{ $t('common.edit') }}
      </pms-button>
    </template>
    <template v-if="components !== null && components.length !== 0">
      <div
        class="mx-4"
        v-html="components"
      />
    </template>
    <template v-else>
      <pms-no-data />
    </template>
  </pms-card>
</template>

<script setup lang="ts">
import { useTeleportModal } from '@shared/composable'
import { useStore } from '../store'
import { computed } from 'vue'
import { has_permission } from '../values'

const store = useStore()
const { setModifier, open } = useTeleportModal()

const components = computed(() => store.getDescription.components)
const openEditor = () => {
  setModifier(
    'syllabus.description',
    { component: 'components', item: components.value },
    { title: 'components.title.edit', description: 'components.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}
</script>
