import { usePagination } from '@/shared/composable'
import { useAllStudents } from '../store'
import { applicantsSearchValues } from '../values'

const { perPage } = usePagination()
const store = useAllStudents()

export default async function applicantsSearch () {
  let no_level: 0|1 = 0
  if (applicantsSearchValues.value.no_level) no_level = 1
  await store.fetchApplicants({ ...applicantsSearchValues.value, no_level, per_page: perPage.value, offset: 1 })
}
