import { useStore } from '@/domains/AcademicCurricula/store'
import editCoreCourse from '@/shared/api/AcademicCurricula/editCoreCourse'
import { useAlert } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { AcademicManageCurriculumCorePutRequest } from '@/shared/swagger'
import { FormField, SelectField } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const useCoreEditor = () => {
  const store = useStore()
  const resource = useResource()
  const { t } = useI18n()
  const { error } = useAlert()

  const { curriculumCore } = storeToRefs(store)

  const formItemClasses = 'formItem me-5'

  const courseSettings = ref<{
    term: null | number,
    coreType: null | string,
    electiveType: null | string,
    is_active: null | boolean,
    trackCode: null | string
    }>({
      term: null,
      coreType: null,
      electiveType: null,
      is_active: null,
      trackCode: null
    })

  const terms = ref<number[]>([])

  watch(() => curriculumCore.value, (newValue) => {
    if (newValue) {
      terms.value = Array.from({ length: newValue.track.terms }, (_, i) => i + 1)

      if (newValue.term) courseSettings.value.term = newValue.term
      if (newValue.type) courseSettings.value.coreType = newValue.type
      if (newValue.elective_type) courseSettings.value.electiveType = newValue.elective_type
      if (newValue.is_active !== null) courseSettings.value.is_active = newValue.is_active
      if (newValue.track.code) courseSettings.value.trackCode = newValue.track.code
    }
  }, { immediate: true })

  const electiveTypesOptions = ref<SelectField['options']>([])
  const coreTypesOptions = ref<SelectField['options']>([])
  const trackOptions = ref<SelectField['options']>([])

  watch(() => resource.academicManageCurriculumResource, () => {
    if (resource.academicManageCurriculumResource) {
      electiveTypesOptions.value = resource.academicManageCurriculumResource.elective_names.map((electiveName) => {
        return {
          label: electiveName.title,
          value: electiveName.code
        }
      })

      coreTypesOptions.value = resource.academicManageCurriculumResource.core_types.map((coreType) => {
        return {
          label: coreType.title,
          value: coreType.key
        }
      })

      trackOptions.value = resource.academicManageCurriculumResource.tracks.map((track) => {
        return {
          label: track.title,
          value: track.key
        }
      })
    }
  })

  const courseSettingsFormFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: t('common.is_active'),
        class: 'mb-1'
      },
      name: 'is_active',
      value: courseSettings.value.is_active || null,
      type: 'switch',
      group: {
        class: formItemClasses
      }
    },
    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },
    {
      label: {
        text: t('common.type'),
        class: 'mb-1'
      },
      name: 'coreType',
      type: 'select',
      value: courseSettings.value.coreType || null,
      options: coreTypesOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.term'),
        class: 'mb-1'
      },
      name: 'term',
      type: 'select',
      value: courseSettings.value.term || null,
      options: terms.value.map((term) => {
        return {
          label: term.toString(),
          value: term
        }
      }),
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.group'),
        class: 'mb-1'
      },
      name: 'electiveType',
      type: 'select',
      value: courseSettings.value.electiveType,
      options: electiveTypesOptions.value,
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('common.track'),
        class: 'mb-1'
      },
      name: 'trackCode',
      type: 'select',
      value: courseSettings.value.trackCode,
      options: trackOptions.value,
      group: {
        class: formItemClasses
      }
    }
  ]
  )

  const onSubmit = async () => {
    if (!courseSettings.value.trackCode) {
      error({
        text: t('domains.academic.curricula.core-edit.track-not-selected-error')
      })
      return
    }
    if (!courseSettings.value.coreType) {
      error({
        text: t('domains.academic.curricula.core-edit.type-not-selected-error')
      })
      return
    }
    if (courseSettings.value.is_active === null) {
      error({
        text: t('domains.academic.curricula.core-edit.type-not-selected-error')
      })
      return
    }
    if (!curriculumCore.value) {
      return
    }
    const requestData: AcademicManageCurriculumCorePutRequest = {
      type: courseSettings.value.coreType,
      is_active: courseSettings.value.is_active,
      track_code: courseSettings.value.trackCode
    }
    if (courseSettings.value.term) {
      requestData.term = courseSettings.value.term
    }

    if (courseSettings.value.electiveType) {
      requestData.elective_type = courseSettings.value.electiveType
    }

    const { status, message } = await editCoreCourse(curriculumCore.value.id, requestData)

    if (status) {
      store.fetchCurriculumCore({ id: curriculumCore.value.id })
    } else {
      error({
        text: message
      })
    }
  }

  return {
    courseSettings,
    courseSettingsFormFields,
    onSubmit
  }
}

export default useCoreEditor
