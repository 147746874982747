<template>
  <pms-card
    :title="'domains.academic.single-course.navigation.items.attendance'"
    :description="'domains.academic.single-course.navigation.items.attendance-description'"
    :options="{
      body: {
        class: 'pt-0'
      }
    }"
  >
    <template #toolbar>
      <attendance-table-header-selector
        v-if="!loader"
      />
    </template>
    <attendance-indicator
      v-if="attendanceList.length>0"
      class="my-5"
    />
    <pms-loader v-if="loader" />
    <template v-else>
      <attendance-information />

      <attendance-table />
    </template>
  </pms-card>
  <qr-launcher v-if="modifier && modifier.name === 'qr-launcher'" />
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import { useAttendance } from '../composable'

import AttendanceInformation from './AttendanceInformation.vue'
import AttendanceTableHeaderSelector from './AttendanceTableHeaderSelector.vue'
import AttendanceTable from './AttendanceTable.vue'
import QrLauncher from './modals/QrLauncher.vue'
import AttendanceIndicator from './AttendanceIndicator.vue'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { storeToRefs } from 'pinia'

const { isActiveOneOf } = useLoader()
const { mount } = useAttendance()
const { modifier } = useTeleportModal()
const { getAttendance } = storeToRefs(useSingleSection())

const attendanceList = computed(() => getAttendance.value)

const loader = isActiveOneOf([
  'academic/courses/attendances/resources',
  'academic/courses/attendances/fetch'
])

onMounted(mount)
</script>
