import type { StoreType } from '../types'
import type { AcademicInformationExamDatesRequest, AcademicInformationExamDatesResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { getAcademicInformationExamDates } from '@shared/api'
import { usePagination } from '@shared/composable'

export default async function fetchExamDates (this: StoreType, values: AcademicInformationExamDatesRequest): Promise<StatusMessageResponse> {
  const { setTriggerFunction } = usePagination()

  const setData = (data: AcademicInformationExamDatesResponse): void => {
    this.setExamDates(data)
  }

  setTriggerFunction(async () => {
    const { ok, data } = await getAcademicInformationExamDates({ ...values })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getAcademicInformationExamDates(values)

  if (ok) {
    setData(data)
  }

  return {
    status: ok,
    message
  }
}
