import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusPoliciesPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusPolicy (body: AcademicManageSyllabusPoliciesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageSyllabusPoliciesPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/policies',
    body,
    loader: 'academic/manage/syllabus/policies'
  })
  return response
}
