<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          class="mt-3"
          v-bind="header"
          :no-mobile="true"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex align-items-center gap-2 mb-5">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
              <PermissionCheck :permission-code="permissionCodes.edit_rules_and_regulations.code">
                <pms-button
                  id="new-rule-btn"
                  class="me-2 pt-3 pb-3"
                  type="button"
                  :text="$t('domains.rules-and-regulations.add-rule')"
                  :variant="'tonal'"
                  :size="'md'"
                  @click="router.push('/administrative/rules-and-regulations/create')"
                />
              </permissioncheck>
            </div>
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>

    <pms-row v-if="loader">
      <pms-page-shimmer
        shimmer="card"
        :filter="true"
      />
    </pms-row>

    <pms-row v-else>
      <pms-grid
        :col="12"
        class="mt-3 m-md-0"
      >
        <RulesAndRegulationsContentColumn id="rules-and-regulations-content" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { header, tour, quickTour } from './values'
import { useLoader, useTour, useDriver } from '@shared/composable'

import RulesAndRegulationsContentColumn from './ui/RulesAndRegulationsContentColumn.vue'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('administrative/resources')
const router = useRouter()

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTour())
})
</script>
