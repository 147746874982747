import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  AcademicManageSyllabusPoliciesPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusPolicy (id: number, body: AcademicManageSyllabusPoliciesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusPoliciesPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/policies/${id}`,
    body,
    loader: 'academic/manage/syllabus/policies'
  })
  return response
}
