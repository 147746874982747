function matchTypeTitleById (id: number) {
  switch (id) {
    case 1:
      return 'Guides'
    case 2:
      return 'FAQ'
    case 3:
      return 'Tutorials'
    default:
      return 'FAQ'
  }
}

export default matchTypeTitleById
