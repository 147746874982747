<template>
  <div class="d-flex align-items-stretch">
    <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3 displayer">
      {{ domainName }}
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm align-middle ms-2"
      />
    </h1>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useLoader } from '@shared-composable/index'

const { t } = useI18n()

const route = useRoute()
const loader = useLoader()

const domainName = computed(() => route.meta.domain ? t(route.meta.domain as string) : '')
const loading = loader.isActive('/1.0.0/me')

</script>

<style lang="scss" scoped>
  @media only screen and (max-width: 600px){
    .displayer {
      display: none !important;
    }
  }

  @media only screen and (min-width: 600px){
    .displayer-reverse {
      display: none !important;
    }
  }
</style>
