import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { destroyAdministrativeMessageBoardComment } from '@shared/api'

export default async function deletePosts (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyAdministrativeMessageBoardComment(id)

  if (ok) await this.fetchPosts(undefined, 'administrative/message-board/comments-delete')

  return {
    status: ok,
    message
  }
}
