import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCurriculumTracksRequest, AcademicManageCurriculumTracksResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicManageCurriculumTracks (params: AcademicManageCurriculumTracksRequest): Promise<AxiosResponse<AcademicManageCurriculumTracksResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumTracksResponse>({
    url: 'academic/manage/curriculum/tracks',
    body: { params },
    loader: 'academic/manage/curriculum/tracks'
  })

  return response
}
