<template>
  <div
    ref="offcanvas"
    :class="['offcanvas offcanvas-end', { show: isMenuVisible }]"
    tabindex="-1"
    class="w-500px"
  >
    <div class="offcanvas-header">
      <h2 class="offcanvas-title">
        {{ $t('common.widgets') }}
      </h2>
      <button
        type="button"
        class="btn-close fs-3 text-reset"
        @click="toggleMenu"
      />
    </div>
    <pms-page-shimmer
      v-if="loader"
      :full-width="true"
    />
    <div
      v-else
      class="offcanvas-body"
    >
      <div
        class="w-200px w-lg-450px bg-gray"
        data-kt-menu="true"
      >
        <div
          id="widget-container"
          class="row g-0 w-100 h-100 p-3"
          style="overflow-y: auto;"
        >
          <draggable
            class="dragArea list-group d-flex flex-row flex-wrap"
            :list="widgetsStore"
            item-key="id"
            group="addWidgets"
            @start="start"
            @end="end"
          >
            <template #item="{ element }">
              <component
                :is="getWidget(element.id)"
                :id="element.id"
                :data-widget-id="element.id"
                class="w-200px h-200px border border-3 overflow-hidden list-group-item p-2 m-2 trembling"
              />
            </template>
          </draggable>
          <div
            v-show="widgetsStore.length === 0"
            class="card border border-dashed border-2 border-primary w-90 h-100"
          >
            <draggable
              class="dragArea list-group d-flex flex-row flex-wrap"
              group="addWidgets"
              item-key="id"
            >
              <template #item>
                <div />
              </template>
              <template #footer>
                <div class="card-body text-center w-100 h-100px">
                  <h3 class="card-title">
                    {{ $t('dashboard.widgets.drop.card') }}
                  </h3>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import { useDashboard } from '../store'
import { widgetsStore, isMenuVisible, showEmptyCard } from '../values'
import { toggleMenu } from '../utils'
import { useLoader } from '@/shared/composable'
import { storeToRefs } from 'pinia'

const store = useDashboard()
const { isActiveOneOf } = useLoader()
const { widgets } = storeToRefs(store)

const loader = isActiveOneOf(['/1.0.0/dashboard/widgets'])

const getWidget = (widgetId: number) => {
  return widgets.value[widgetId] || null
}

const start = () => {
  showEmptyCard.value = false
}

async function end (event) {
  showEmptyCard.value = true

  const { item, from, to } = event

  if (from !== to) {
    const widgetId = item.dataset.widgetId

    if (widgetId) {
      await store.addWidgets({ widget_id: Number(widgetId) })
    }
  }
}

</script>

<style>
@keyframes trembling {
  0% { transform: translateY(1px) rotate(0deg); }
  10% { transform: translateY(-1px) rotate(-1deg); }
  20% { transform: translateY(1px) rotate(1deg); }
  30% { transform: translateY(-1px) rotate(0deg); }
  40% { transform: translateY(1px) rotate(1deg); }
  50% { transform: translateY(-1px) rotate(-1deg); }
  60% { transform: translateY(1px) rotate(0deg); }
  70% { transform: translateY(-1px) rotate(-1deg); }
  80% { transform: translateY(1px) rotate(1deg); }
  90% { transform: translateY(-1px) rotate(0deg); }
  100% { transform: translateY(1px) rotate(-1deg); }
}

.trembling {
  animation: trembling 0.9s infinite;
}
</style>
