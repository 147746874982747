<template>
  <div class="d-flex align-items-start mt-4">
    <button
      type="button"
      class="btn btn-light-primary btn-sm me-3 p-2 ps-3 pe-3"
      @click="router.go(-1)"
    >
      <pms-kt-icon
        icon-name="arrow-left"
        class="m-0 p-0"
      />
    </button>
    <span class="d-flex flex-column">
      <span class="fs-3 fw-bold">{{ title }}</span>
      <span
        v-if="description"
        class="fs-5 text-muted"
      >{{ description }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

defineProps<{
    title: string;
    description?: string;
}>()

const router = useRouter()
</script>
