import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useSingleCourse = defineStore<Name, State, Getters, Actions>('Academic.useSingleCourse', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      currentSection: null,
      course: null,
      overview: null,
      syllabus: null,
      history: null,
      students: [],
      consentRequests: [],
      examDates: [],
      scheduleRecords: [],
      attendance_courses: [],
      attendance_students: []
    } as State
  }
})
