export const imageMap = {
  N: '/img/courses/lecture.jpg',
  P: '/img/courses/practice.jpg',
  L: '/img/courses/lab.jpg'
}

export const dropdownItems = [
  {
    type: 'link',
    label: 'common.open',
    icon: 'bi-info-circle-fill',
    link: ''
  },
  {
    type: 'separator',
    label: '',
    icon: ''
  },
  {
    type: 'link',
    label: 'common.open-attendance',
    icon: 'bi-check2-all',
    link: '/attendance'
  }
]
