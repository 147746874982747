import { OverviewTableData } from '../types'
import { AcademicCoursesOverviewResponse } from '@shared/swagger'

const formatTableData = (data: AcademicCoursesOverviewResponse | null): OverviewTableData | null => {
  return data
    ? {
        courseCode: {
          code: data.code,
          year: data.year
        },
        equCode: data.equicode,
        title: data.title,
        department: {
          code: data.department_code,
          title: data.department_title
        },
        periodCode: data.period_code,
        term: data.term,
        createDate: {
          date: data.open_date,
          time: data.open_time
        },
        credit: {
          theory: data.theory,
          practice: data.practice,
          lab: data.lab,
          ects: data.ects
        },
        language: {
          code: data.language_code,
          title: data.language_title
        },
        type: data.type_title,
        gradingType: data.grading
      }
    : null
}

export default formatTableData
