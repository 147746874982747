<template>
  <div class="d-flex flex-column">
    <h1 class="text-dark fs-2 fw-bold mb-4">
      {{ $t('domains.section-management.sidebar.sent-requests') }}
    </h1>
    <p class="mb-6 fs-5">
      {{ $t('domains.academic.manage.section.sent-requests-description') }}
    </p>

    <div class="d-flex flex-row">
      <Select
        v-model="selectedProgram"
        filter
        :options="groupedPrograms"
        option-label="label"
        option-group-label="label"
        option-group-children="items"
        class="w-full md:w-56"
      >
        <template #optiongroup="slotProps">
          <div class="flex items-center">
            <div>{{ slotProps.option.label }}</div>
          </div>
        </template>
      </Select>
      <button
        class="btn btn-primary btn-lg ms-2"
        @click="next"
      >
        {{ $t('common.next') }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { SectionManagementRequestGetRequest } from '@/shared/swagger'
import { useResource } from '@shared/stores'
import { objectDeepCopy } from '@/shared/utils'
import { useSentRequests } from '../store'
import { useRoute } from 'vue-router'
import { sentRequest } from '../values'
import Select from 'primevue/select'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const resource = useResource()
const store = useSentRequests()
const { query } = useRoute()

const programs = computed(() => resource.getSectionManagementResourcePrograms)
const departments = computed(() => resource.getSectionManagementResourceDepartments)

const defaultSentRequest: SectionManagementRequestGetRequest = {
  type: 'sent',
  year: parseInt(query.year + '') ? parseInt(query.year + '') : undefined,
  term: parseInt(query.term + '') ? parseInt(query.term + '') : undefined
}

const next = async () => {
  store.step = 'course'
}

const groupedPrograms = computed(() => {
  return [{
    label: '',
    items: [
      {
        value: null,
        label: t('common.all')
      }
    ]
  }, ...departments.value.map((department) => {
    return {
      label: department.title,
      code: department.code,
      items: programs.value.filter(pr => pr.department.code === department.code).map((program) => {
        return {
          value: program.code,
          label: `${program.code} - ${program.language.code} - [${program.cipher}]: - ${program.title} - [${program.terms_count}]`
        }
      })
    }
  })]
})

const selectedProgram = ref()

watch(selectedProgram, () => {
  if (selectedProgram.value.value) {
    store.setCurrentProgram(selectedProgram.value.label)
  } else { store.setCurrentProgram('common.all-programs') }
})

onMounted(() => {
  sentRequest.value = objectDeepCopy(defaultSentRequest)
  selectedProgram.value = groupedPrograms.value[0].items[0]
  store.setCurrentProgram(selectedProgram.value.label)
})
</script>
