import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useProfile = defineStore<Name, State, Getters, Actions>('Profile.useProfile', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state: ():State => ({
    overview: null,
    // addresses
    modifier: null,
    addresses: new Map(),
    // biography
    biography: '',
    children: [],
    // contacts
    contacts: [],
    // educations
    educations: [],
    supervisions: [],
    conductedCourses: [],
    organizations: [],
    // publications
    articles: new Map(),
    books: [],
    conferences: new Map(),
    // experiences
    experiences: [],
    projects: [],
    certificates: [],
    // skills
    languages: [],
    skills: [],
    interest: '',
    // settings
    settings: null
  })
})
