<template>
  <UserCard />
  <UserPasswordCard />
  <UserPrivacyList
    v-if="privacy"
    :type="'general'"
    :title="$t('domains.profile.settings.user-privacy-card.title')"
    :privacy="privacy"
    :loader="'profile/settings/privacies-edit'"
    @set-privacy="setPrivacy"
  >
    <template #item="{ title }">
      <div class="d-flex flex-column">
        <span class="fs-5 text-dark text-hover-primary fw-bold">
          {{ $t(`domains.profile.settings.user-privacy-card.privacy.${title}`) }}
        </span>
        <div class="fs-6 fw-semibold text-gray-400">
          {{ $t(`domains.profile.settings.user-privacy-card.privacy.description-${title}`) }}
        </div>
      </div>
    </template>
  </UserPrivacyList>
  <UserPrivacyList
    :type="'contacts'"
    :title="$t('domains.profile.settings.user-contacts-privacy-card.title')"
    :privacy="contactPrivacy"
    :loader="'profile/settings/privacies/contact-edit'"
    @set-privacy="setContactPrivacy"
  >
    <template #item="{ title, type_id }">
      <div class="d-flex flex-column mb-4">
        <span class="fs-5 text-dark text-hover-primary fw-bold">
          {{ title }}
          <span
            v-if="getContactTypeTitle(type_id)"
            v-styler="{ marginLeft: '5px' }"
            class="ml-2 badge badge-light-primary"
          >
            {{ getContactTypeTitle(type_id) }}
          </span>
        </span>
        <div class="fs-6 fw-semibold text-gray-400">
          {{ $t('domains.profile.settings.user-contacts-privacy-card.phone-description') }}
        </div>
      </div>
    </template>
  </UserPrivacyList>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useAlert } from '@shared/composable'
import type { ProfileSettingsPrivaciesContactPutRequest, ProfileSettingsPrivaciesPutRequest } from '@shared/swagger'

import { useProfile } from '../../../store'
import { getContactTypeTitle } from '../../../utils'

import { settingsFormatter } from '../utils'
import { ContactPrivacy, Privacy } from '../types'

import UserCard from './UserCard.vue'
import UserPasswordCard from './UserPasswordCard.vue'
import UserPrivacyList from './UserPrivacyList.vue'

const { success, error } = useAlert()

const store = useProfile()

const settings = computed(() => store.getSettings)
const privacy = computed<Privacy[]>(() => settingsFormatter(settings.value).privacy)
const contactPrivacy = computed<ContactPrivacy[]>(() => settingsFormatter(settings.value).contactPrivacy)

const setContactPrivacy = async (data: ContactPrivacy[]) => {
  const requestBody: ProfileSettingsPrivaciesContactPutRequest = {
    privacy: data.map((el) => {
      return {
        id: el.id,
        contact: el.title,
        contact_type: el.type_id,
        show_status: el.show_status
      }
    })
  }
  const { status, message } = await store.editPrivaciesContact(requestBody)

  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}
const setPrivacy = async (data: Privacy[]) => {
  const mappedPrivacy: ProfileSettingsPrivaciesPutRequest = {
    age: false,
    birth_date: false,
    gender: false,
    marital_status: false,
    cell: false,
    job_experience: false,
    education: false,
    theses: false,
    publications: false,
    projects: false,
    organizations: false,
    awards: false,
    courses: false,
    it_skills: false,
    language: false,
    website: false,
    autobiography: false,
    research_areas: false
  }
  data.forEach((el) => { mappedPrivacy[el.title] = el.show_status })

  const { status, message } = await store.editPrivacies(mappedPrivacy)
  status
    ? success({ text: message, isToast: true })
    : error({ text: message })
}
</script>
