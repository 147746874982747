import { event } from 'vue-gtag'

export type Analytics = {
  onEvent(eventName: string, eventOptions: Record<string, unknown> | Array<unknown>): Promise<unknown>;
}

const useAnalytics = (): Analytics => {
  return {
    onEvent: (eventName, eventOptions) => {
      return Promise.resolve(
        event(eventName, { ...eventOptions })
      )
    }
  }
}

export default useAnalytics
