<template>
  <vue-resizable
    id="faculty-widget"
    class="resizable-widget card card-flush"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-width="400"
    min-height="500"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div
      class="card-header d-flex flex-column rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start"
      style="background-image:url(/media/misc/pattern-2.png)"
    >
      <h3
        class="card-title align-items-start flex-column text-white pt-8"
      >
        <span class="fw-bolder fs-2x mb-2">
          {{ $t('domains.dashboard.widgets.faculties.title') }}
        </span>
      </h3>
      <div
        class="card-toolbar pb-10"
      >
        <select
          v-model="formModel"
          v-test-id="'faculty-select'"
          class="form-select border-0"
          @change="updateView()"
        >
          <option
            v-for="(faculty,index) in faculties"
            :key="index"
            :value="faculty.title"
          >
            {{ faculty.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="card-body">
      <div
        class="mt-n20 position-relative"
      >
        <div class="row g-3 g-lg-6">
          <div class="col-6">
            <div
              class="rounded-2 px-6 py-5"
              style="background-color: #F5F8FA;"
            >
              <div class="symbol symbol-30px me-5 mb-5">
                <pms-kt-icon
                  :icon-name="'home'"
                  class="symbol-label"
                />
              </div>
              <div class="m-0">
                <span
                  v-test-id="'faculty-programs'"
                  class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1"
                >
                  {{ currentView?.programs }}
                </span>
                <span class="text-gray-500 fw-bold fs-4">
                  {{ $t('domains.dashboard.widgets.faculties.programs') }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="rounded-2 px-6 py-5"
              style="background-color: #F5F8FA;"
            >
              <div class="symbol symbol-30px me-5 mb-5">
                <span class="symbol-label">
                  <pms-kt-icon
                    :icon-name="'calendar'"
                    class="symbol-label"
                  />
                </span>
              </div>
              <div class="m-0">
                <span
                  v-test-id="'faculty-courses'"
                  class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1"
                >
                  {{ currentView?.courses }}
                </span>
                <span class="text-gray-500 fw-bold fs-4">
                  {{ $t('domains.dashboard.widgets.faculties.courses') }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="rounded-2 px-6 py-5"
              style="background-color: #F5F8FA;"
            >
              <div class="symbol symbol-30px me-5 mb-5">
                <span class="symbol-label">
                  <pms-kt-icon
                    :icon-name="'heart'"
                    class="symbol-label"
                  />
                </span>
              </div>
              <div class="m-0">
                <span
                  v-test-id="'faculty-teachers'"
                  class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1"
                >
                  {{ currentView?.employee }}
                </span>
                <span class="text-gray-500 fw-bold fs-4">
                  {{ $t('domains.dashboard.widgets.faculties.teachers') }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="rounded-2 px-6 py-5"
              style="background-color: #F5F8FA;"
            >
              <div class="symbol symbol-30px me-5 mb-5">
                <span class="symbol-label">
                  <pms-kt-icon
                    :icon-name="'people'"
                    class="symbol-label"
                  />
                </span>
              </div>
              <div class="m-0">
                <span
                  v-test-id="'faculty-students'"
                  class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1"
                >
                  {{ currentView?.students }}
                </span>
                <span class="text-gray-500 fw-bold fs-4">
                  {{ $t('domains.dashboard.widgets.faculties.student') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { DashboardWidgetsResponse } from '@shared/swagger'
import { ArrayElement } from '@shared/types'
import { deviceType } from '@/shared/utils'
import { onResize, getSavedWidgetSize } from '../utils'

const store = useDashboard()
const device = deviceType()

const faculties = computed(() => store.getFacultyStatistics)
const currentView = ref<ArrayElement<DashboardWidgetsResponse['faculty_statistics']['data']>|null>(null)
const formModel = ref<string>('')

const widgetId = 'faculty-widget'

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '45%', height: 'auto' })

const updateView = (): void => {
  const faculty = faculties.value.find((faculty) => faculty.title === formModel.value)

  if (faculty) {
    currentView.value = faculty
  }
}

if (faculties.value.length > 0) {
  currentView.value = faculties.value[0]
  formModel.value = faculties.value[0].title
}

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
