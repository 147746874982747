
<template>
  <pms-card
    :title="'domains.knowledge.all-posts.categories.title'"
    :description="'domains.knowledge.all-posts.categories.description'"
    :options="{ body: { class: 'pt-0 pb-3' }}"
  >
    <template
      v-for="category in categoryContent"
      :key="category.uuid"
    >
      <div class="d-flex">
        <div class="d-flex align-items-center flex-wrap flex-grow-1 mt-2 mt-lg-n1">
          <div class="d-flex flex-column flex-grow-1 pe-3">
            <span
              class="text-hover-primary fw-bolder fs-5 cursor-pointer pb-4"
              @click="handleSearch(category.uuid)"
            >
              {{ category.title }}
            </span>
          </div>
          <div class="text-end pt-3">
            <p class="fs-5 badge badge-primary text-white fw-bold">
              {{ category.count }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <pms-no-data v-if="categoryContent.length === 0" />
  </pms-card>
</template>

<script setup lang="ts">
import { useResource } from '@shared/stores'
import { computed } from 'vue'
import { LocationQueryRaw, useRouter } from 'vue-router'

const router = useRouter()
const resource = useResource()
const categoryContent = computed(
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  () => resource.getKnowledgeResourceCategories
    .sort((a, b) => b.count - a.count)
    .slice(0, 5)
)

function handleSearch (category_uuid : string) {
  const query : LocationQueryRaw = {}
  if (!category_uuid) return
  query.category_uuid = category_uuid

  router.push({
    path: 'knowledge-base/search',
    query
  })
}
</script>
