<template>
  <form class="">
    <label
      class="d-block required"
      for="search"
    >
      Code
    </label>
    <div class="d-flex">
      <input
        id="search"
        v-model="courseCode"
        class="form-control"
        type="text"
      >
      <button
        :disabled="!courseCode.length"
        class="btn btn-primary ms-5"
        @click.prevent="search_course"
      >
        {{ search_loader ? "Loading... " : $t('common.search') }}
      </button>
    </div>
  </form>
  <div class="mt-3">
    <div>
      {{ $t('domains.academic.manage.course.requisite.courses.title') }}
    </div>
    <pms-smart-table
      :options="courses_table_options"
      :headers="courses_table_headers"
      :items="requisite_courses_search_response"
    >
      <template #action="{ item }">
        <pms-button
          class="ps-4 pe-3"
          :size="'sm'"
          :variant="'tonal'"
          @click="add_item(item)"
        >
          {{ add_loader ? "Loading..." : $t('common.add') }}
        </pms-button>
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { courses_table_headers, courses_table_options } from '../values'
import { useManageCourses } from '../../../store'

import { useManageRequisiteComposable } from '../composable'
import { useLoader } from '@/shared/composable'

const store = useManageCourses()
const { requisite_courses_search_response } = storeToRefs(store)
const { courseCode, search_course, add_item } = useManageRequisiteComposable()

const search_loader = useLoader().isActiveOneOf(['academic/courses'])
const add_loader = useLoader().isActiveOneOf(['academic/manage/courses/requisites/items/create'])
</script>
