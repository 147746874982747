<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div class="w-100 position-relative mb-5">
        <div
          :key="'content.course-learning-outcomes'"
          class="fs-2 fw-bold text-danger text-center flex-fill"
        >
          {{ getMessage('advanced.teaching-methods') }}
        </div>
        <button
          v-if="amIEnrolled && has_permission"
          type="button"
          class="btn btn-light-primary p-2 position-absolute top-0 end-0"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addMethod"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </div>

      <pms-smart-table
        :items="teachingMethods"
        :headers="header"
        :sortable="false"
        :options="{
          design: 'bordered-dashed'
        }"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #title="{ item }">
          <span class="fs-5 fw-bold">{{ item.title }}</span>
        </template>
        <template #delete="{ item }">
          <button
            v-if="has_permission"
            type="button"
            class="btn btn-light-danger p-2"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-dismiss-="click"
            :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.delete')"
            @click="deleteMethod(item.id)"
          >
            <pms-kt-icon
              :icon-name="'trash'"
              class="fw-bold fs-3 p-0"
            />
          </button>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <SyllabusTeachingMethodForm v-if="modifier && modifier.name === 'syllabus.teaching-methods'" />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { computed } from 'vue'
import { teachingMethodsTable, has_permission } from './../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import SyllabusTeachingMethodForm from './SyllabusTeachingMethodForm.vue'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus/teaching-methods')
const alert = useAlert()
const { setModifier, open, modifier } = useTeleportModal()

const store = useStore()
const teachingMethods = computed(() => store.getTeachingMethods)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...teachingMethodsTable,
      {
        title: 'common.delete',
        field: 'delete',
        slotCellName: 'delete',
        headerClass: 'w-50px',
        cellClass: 'text-center'
      }]
  : teachingMethodsTable)

function addMethod () {
  setModifier(
    'syllabus.teaching-methods',
    {},
    { title: 'teaching-methods.title.add', description: 'teaching-methods.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}
async function deleteMethod (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-teaching-method',
      onConfirm: async () => {
        await store.deleteTeachingMethod(id)
      }
    }
  )
}
</script>
../store../values
../store../values
