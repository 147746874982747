<template>
  <div
    class="input-group"
    style="width: 400px;"
  >
    <span
      id="inputGroup-sizing-default"
      class="input-group-text"
    >
      <!-- Course code -->
      {{ t('common.common.course-code') }}
    </span>
    <input
      id="inputGroupFile04"
      v-model="courseSeachQuery"
      type="text"
      class="form-control"
      :placeholder="t('domains.academic.curricula.add-course.search-course-placeholder')"
    >
    <pms-button
      class="m-0 ps-4 pe-4 me-2 btn btn-outline-secondary"
      :size="'sm'"
      :text="'common.search'"
      @Click="handleSearchCourse"
    />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from '../../../store'
import { usePagination } from '@/shared/composable'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { track } = storeToRefs(store)
const courseSeachQuery = ref('')
const { perPage } = usePagination()
const { t } = useI18n()

const handleSearchCourse = () => {
  if (!track.value) return

  store.fetchCourses({
    course_code: courseSeachQuery.value,
    year: track.value.program.year,
    offset: 1,
    per_page: perPage.value
  })
}
</script>
