import { SmartTableHeader } from '@/shared/types'

export const courseHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.code',
    field: 'code',
    sortable: true
  },
  {
    title: 'common.table.language',
    field: 'language',
    sortable: true
  },
  {
    title: 'common.table.equicode',
    field: 'equicode',
    sortable: true
  },
  {
    title: 'common.table.year',
    field: 'year',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.department',
    field: 'department_code'
  },
  {
    title: 'common.table.credits',
    field: 'credits',
    subHeaders: [
      {
        title: 'common.table.theory',
        field: 'theory',
        sortable: true
      },
      {
        title: 'common.table.practice',
        field: 'practice',
        sortable: true
      },
      {
        title: 'common.table.labs',
        field: 'lab',
        sortable: true
      },
      {
        title: 'common.table.credits',
        field: 'credits',
        sortable: true
      },
      {
        title: 'common.table.ects',
        field: 'ects',
        sortable: true
      }
    ]
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]
