<template>
  <pms-card
    id="search-student-widget"
    :title="'common.search'"
    :description="$t('')"
    class="mt-5"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div
          class="form-group d-flex justify-content-center mt-3"
        >
          <div
            class="flex-fill"
          >
            <pms-button
              type="submit"
              class="w-100 "
              :disabled="loader"
              :loader="searchLoader"
              :text="$t('common.search')"
            />
          </div>
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { useAdvisorAssignmentSearchForm } from './composable/useAdvisorAssignmentSearchForm'

const { isActive } = useLoader()

const loader = isActive('get-service/manage/advisors/resources')
const searchLoader = isActive('get-service/manage/advisors/students')
const { formFields, onSubmit } = useAdvisorAssignmentSearchForm()
</script>
