import type { StoreType } from '../types'
import type { KnowledgeManageCategoriesPostRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { postKnowledgeManageCategory } from '@/shared/api'

export default async function createCategory (this: StoreType, values: KnowledgeManageCategoriesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postKnowledgeManageCategory(values)

  return {
    status: ok,
    message: message
  }
}
