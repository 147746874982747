<template>
  <pms-card
    :title="$t('domains.academic.editor.syllabus.policies.no-title', { no: idx })"
    class="mb-3 px-4"
    :options="{
      title: { class: 'fs-4 fw-bold '},
      header: { class: 'p-0', style: 'min-height: 30px;' },
      body: { class: 'px-2 py-0'}
    }"
  >
    <template #toolbar>
      <button
        v-if="has_permission"
        type="button"
        class="btn btn-light-primary me-1 p-2"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
        @click="editPolicy"
      >
        <pms-kt-icon
          :icon-name="'click'"
          class="fw-bold fs-3 p-0"
        />
      </button>
      <button
        v-if="has_permission"
        type="button"
        class="btn btn-light-danger ms-1 p-2"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.delete')"
        @click="deletePolicy"
      >
        <pms-kt-icon
          :icon-name="'trash'"
          class="fw-bold fs-3 p-0"
        />
      </button>
    </template>
    <div
      v-html="props.policy"
    />
  </pms-card>
</template>

<script setup lang="ts">
import { has_permission } from '../values'

const props = defineProps<{
  id: number,
  policy: string,
  order: string
}>()

const emits = defineEmits<
  {(eventName: 'open:editor', id: number): void;
    (eventName: 'delete:editor', id: number): void;
  }>()

const idx = Number(props.order) + 1

function editPolicy () {
  emits('open:editor', props.id)
}

function deletePolicy () {
  emits('delete:editor', props.id)
}

</script>
