<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :filter="true"
      :full-width="true"
    />
    <students-list v-else />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'
import { quickTourStudents } from '../../values'

import StudentsList from './ui/StudentsList.vue'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()
const loader = isActive('academic/courses/students')

onMounted(async () => {
  setQuickTourSteps(await quickTourStudents())
})
</script>
