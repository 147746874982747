import { ManageCourseRequestType } from './../../types/course'
import { State } from '../types'
import { AcademicCoursesResponse, AcademicManageCoursesRequisitesItemsGetResponse, AcademicManageCoursesRequisitesGetResponse, AcademicCoursesRequest, AcademicManageCoursesSearchResponse } from '@/shared/swagger'
import { RouteLocationNormalized } from 'vue-router'

export function setPageParams (this:State, to:RouteLocationNormalized) {
  this.page_params = {
    course_code: (to.params.code as string),
    course_year: (+to.params.year as number),
    requisite_type: (to.params.requisite_type as string),
    ...to.query
  }
}

export function setCourseSearchinfoResponse (this:State, response:AcademicManageCoursesSearchResponse[0]) {
  this.fetch_course_info_response = response
}
export function setCourseSearchRequest (this: State, courses: AcademicCoursesRequest) {
  this.course_search_request = courses
}
export function setCourseSearchResponse (this: State, courses: AcademicCoursesResponse['courses']): void {
  this.course_search_response = courses
}
export function setCourseManageRequest (this:State, value:ManageCourseRequestType) {
  this.manage_course_request = value
}

export function setDescriptionEditable (this: State, value: boolean): void {
  this.description_editable = value
}

export function setRequisiteInfoResponse (this: State, response: AcademicManageCoursesRequisitesGetResponse): void {
  this.requisite_info_response = response
}
export function setRequisiteCoursesSearchResponse (this: State, response: AcademicCoursesResponse['courses']): void {
  this.requisite_courses_search_response = response
}
export function setRequisiteItemsResponse (this: State, response: AcademicManageCoursesRequisitesItemsGetResponse): void {
  this.requisite_items_response = response
}
