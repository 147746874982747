import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileSettingsPrivaciesPutRequest } from '@shared/swagger'

export default async function putProfileSettingsPrivacies (values: ProfileSettingsPrivaciesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileSettingsPrivaciesPutRequest, UnknownResponse>({
    url: 'profile/settings/privacies',
    body: values,
    loader: 'profile/settings/privacies-edit'
  })

  return response
}
