<template>
  <div
    class="d-flex flex-rows align-items-center"
  >
    <button
      title="Back"
      type="button"
      class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
      @click="handleMyCourseBack"
    >
      <pms-kt-icon
        icon-name="arrow-left"
        class="m-0 p-0"
      />
    </button>
    <div class="d-flex flex-column justify-content-start">
      <h4 class="fs-5 text-primary fw-bold p-0 m-0">
        {{ $t('domains.academic.manage.section.new-request.back-to-my-course') }}
      </h4>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{(event: 'update:isPostOffer'): void}>()
function handleMyCourseBack () {
  emit('update:isPostOffer')
}
</script>
