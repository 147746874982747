<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semobold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <div
      v-if="user"
      class="menu-item px-3"
    >
      <div class="menu-content d-flex align-items-center px-3">
        <div class="symbol symbol-50px me-5">
          <img
            style="object-fit: cover"
            :src="user.photo || '/user.png'"
            :alt="user.name + ' ' + user.surname"
          >
        </div>
        <div class="d-flex flex-column">
          <div class="fw-bold d-flex align-items-center fs-5">
            {{ user.name + ' ' + user.surname }}
          </div>
          <a
            href="javascript:void(0)"
            class="fw-semobold text-muted text-hover-primary fs-7"
          >
            {{ user.username }}
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="user"
      class="separator my-2"
    />

    <div
      class="menu-item px-5"
    >
      <router-link
        to="/profile"
        class="menu-link px-5"
      >
        {{ $t('layout.header.user-menu.my-profile') }}
      </router-link>
    </div>

    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <div class="menu-link px-5">
        <span class="menu-title">{{ $t('layout.header.user-menu.my-courses') }}</span>
        <span class="menu-arrow" />
      </div>

      <div class="menu-sub menu-sub-dropdown w-200px py-4">
        <div class="menu-item px-5">
          <router-link
            to="/academic/courses/my-courses"
            class="menu-link px-5"
          >
            {{ $t('layout.header.user-menu.my-courses') }}
          </router-link>
        </div>

        <div class="menu-item px-5">
          <router-link
            to="/academic/courses/my-schedule"
            class="menu-link px-5"
          >
            {{ $t('layout.header.user-menu.course-schedule') }}
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <div class="menu-link px-5">
        <span class="menu-title">{{ $t('layout.header.user-menu.my-students') }}</span>
        <span class="menu-arrow" />
      </div>

      <div
        class="menu-sub menu-sub-dropdown w-200px py-4"
      >
        <div class="menu-item px-5">
          <router-link
            to="/academic/students"
            class="menu-link px-5"
          >
            {{ $t('layout.header.user-menu.students-search') }}
          </router-link>
        </div>

        <div class="menu-item px-5">
          <router-link
            to="/academic/students/my-advisory"
            class="menu-link px-5"
          >
            {{ $t('layout.header.user-menu.my-students') }}
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="menu-item px-5"
    >
      <router-link
        to="/administrative/system-calendar"
        class="menu-link px-5"
      >
        {{ $t('layout.header.user-menu.system-calendar') }}
      </router-link>
    </div>

    <div
      class="menu-item px-5"
    >
      <router-link
        to="/administrative/rules-and-regulations"
        class="menu-link px-5"
      >
        {{ $t('layout.header.user-menu.rules-and-regulations') }}
      </router-link>
    </div>

    <div
      class="separator my-2"
    />

    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top"
    >
      <div class="menu-link px-5">
        <span class="menu-title position-relative">
          {{ $t('layout.header.user-menu.language') }}
          <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {{ currentLocale.locale }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="currentLocale.flag"
              :alt="currentLocale.locale"
            >
          </span>
        </span>
      </div>

      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <template
          v-for="(localeItem, index) in locales"
          :key="index"
        >
          <div class="menu-item px-3">
            <a
              href="javascript:void(0)"
              class="menu-link d-flex px-5"
              :class="{ active: locale === localeItem.short }"
              @click="setLocale(localeItem.short)"
            >
              <span class="symbol symbol-20px me-4">
                <img
                  class="rounded-1"
                  :src="localeItem.flag"
                  :alt="localeItem.locale"
                >
              </span>
              {{ localeItem.locale }}
            </a>
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="false"
      class="menu-item px-5"
    >
      <router-link
        to="/profile/settings"
        class="menu-link px-5"
      >
        {{ $t('layout.header.user-menu.settings') }}
      </router-link>
    </div>

    <div class="menu-item px-5">
      <router-link
        to="/auth/sign-out"
        class="menu-link px-5"
      >
        {{ $t('layout.header.user-menu.sign-out') }}
      </router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLocale } from '@shared-composable/index'
import { useUser } from '@shared/stores'

const store = useUser()
const { locale, locales, setLocale } = useLocale()

const user = computed(() => store.currentUser)
const currentLocale = computed(() => locales.value.find((item) => item.short === locale.value) || locales.value[0])
</script>
