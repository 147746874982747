import type { StoreType } from '../types'

import { getProfileProjects } from '@shared/api'

export default async function fetchProjects (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileProjects()

  if (ok) {
    this.setProjects(data)
  }
}
