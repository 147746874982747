<template>
  <div class="d-flex align-items-center">
    <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
      {{ $t('common.form-fields.section') }}:
    </span>
    <select
      v-model="currentSection"
      class="form-select form-select-md w-200px"
    >
      <template
        v-for="section in sections"
        :key="section.id"
      >
        <option
          :value="section.id"
        >
          {{ section.section }}
        </option>
      </template>
    </select>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'

import { useSingleCourse } from '../../../store'
import { currentSection } from '../values'

const store = useSingleCourse()
const sections = computed(() => store.getHistorySections)

watch(() => sections.value,
  (newValue) => {
    sections.value.length && (currentSection.value = newValue[0].id)
  }
)

</script>
