<template>
  <pms-card
    id="quick-navigation"
    :title="'common.quick-navigation'"
    :description="$t('domains.academic.manage.curricula.navigation.description', { year })"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-list
      v-else
      :items="items"
      :nestable="true"
    >
      <template #item="{ item, level}">
        <div class="d-flex flex-row align-items-center">
          <div>{{ item.title }}</div>
          <template v-if="level === 2">
            <router-link
              :to="{
                path: '/academic/curricula/view',
                query: {
                  year: year,
                  department: item.params.departmentId,
                  program: item.params.programId,
                  track: item.params.trackId
                }
              }"
              class="btn btn-sm btn-light-primary ms-2"
              style="padding: 0.25rem 0.5rem 0.25rem 0.5rem;"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="m-0 p-0"
              />
            </router-link>
          </template>
        </div>
      </template>
    </pms-smart-list>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@shared/composable'
import { NestedListItem } from '@shared/types'

import { useStore } from '../store'
import { navItemsToListItems } from '../utils'

const { isActive } = useLoader()

const store = useStore()
const loader = isActive('academic/manage/curriculum/navigation')
const year = computed(() => store.getYear)
const items = computed<Array<NestedListItem>>(() => navItemsToListItems(store.getNavigationItems))
</script>
