<template>
  <pms-kt-icon
    class="p-0"
    :icon-name="statusIcons[status]"
    :title="$t(`common.table.attendance.${status}`)"
    :class="{
      'text-success': status === 'attended',
      'text-danger': status === 'absence',
      'text-primary': status === 'permitted',
      'text-dark' : status === 'empty',
      [iconClass]: true
    }"
    style="letter-spacing: 0px; margin: 0px;"
  />
</template>
<script setup lang="ts">
import { PropType } from 'vue'

defineProps({
  status: {
    type: String as PropType<'attended' | 'absence' | 'permitted' | 'empty'>,
    required: true
  },
  iconClass: {
    type: String,
    default: 'fs-2 m-0'
  }
})

const statusIcons = {
  empty: 'plus',
  attended: 'check-square',
  absence: 'cross-square',
  permitted: 'user-square'
}
</script>
