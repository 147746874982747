import type { StoreType } from '../types'
import type { AcademicInformationExamDatesRequest, AcademicInformationExamDatesResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { getAcademicInformationExamDates } from '@shared/api'

export default async function fetchExamDates (this: StoreType, values: AcademicInformationExamDatesRequest): Promise<StatusMessageResponse> {
  const setData = (data: AcademicInformationExamDatesResponse): void => {
    this.setExamDates(data)
  }

  const { ok, message, data } = await getAcademicInformationExamDates(values)

  if (ok) {
    setData(data)
  }

  return {
    status: ok,
    message
  }
}
