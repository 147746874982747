import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { deleteRulesAndRegulationsAttachment } from '@/shared/api'

export default async function deleteRuleAttachment (this: StoreType, ruleId: string, attachmentId: string): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteRulesAndRegulationsAttachment(ruleId, attachmentId)

  ok && this.fetchSingleRule(ruleId)

  return {
    status: ok,
    message: message
  }
}
