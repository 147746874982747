import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumCourseSearchRequest, AcademicManageCurriculumCourseSearchResponse } from '@shared/swagger'

export default async function findCourseByCodeAndYear (params: AcademicManageCurriculumCourseSearchRequest): Promise<AxiosResponse<AcademicManageCurriculumCourseSearchResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumCourseSearchResponse>({
    url: 'academic/manage/curriculum/courses/search',
    body: { params: params },
    loader: 'academic/manage/curriculum/courses/search'
  })

  return response
}
