import { defineComponent, onMounted } from 'vue'

import { useBodyStore, useThemeStore } from '@shared/stores/pattern'
import { LayoutUtils } from '@shared/utils/pattern'

const EmptyLayout = defineComponent({
  setup () {
    const store = useBodyStore()
    const theme = useThemeStore()

    onMounted(() => {
      LayoutUtils.emptyElementClassesAndAttributes(document.body)
      store.addBodyClassName('app-blank')
    })

    return () => (
      <div class="d-flex flex-column flex-root" style={{
        backgroundColor: theme.theme === 'light'
          ? '#F5F8FA'
          : 'inherit'
      }}>
        <router-view />
      </div>
    )
  }
})

export default EmptyLayout
