import type { StoreType } from '../types'
import type { ResourceRequest } from '@shared/swagger'

import { getAdministrativeResource } from '@shared/api'

export default async function fetchAdministrativeResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getAdministrativeResource(body)

  if (ok) {
    this.setAdministrativeResource(data)
  }
}
