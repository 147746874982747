import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationBooksPostRequest } from '@shared/swagger'

export default async function postProfileEducationBooks (params: ProfileEducationBooksPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationBooksPostRequest, UnknownResponse>({
    url: 'profile/education/books',
    body: params,
    loader: 'profile/education/books-post'
  })

  return response
}
