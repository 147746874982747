import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentCurriculumGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentCurriculum (id: string): Promise<AxiosResponse<AcademicSingleStudentCurriculumGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentCurriculumGetResponse>({
    url: `academic/students/${id}/curriculum`,
    body: {},
    loader: 'academic/students/curriculum'
  })

  return response
}
