<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :full-width="true"
  />
  <div
    v-else
    id="grades-history"
    class="mt-4"
  >
    <h3 class="py-2">
      <span class="card-label fw-bold text-dark fs-3">
        {{ title }}
      </span>
    </h3>
    <div v-if="!!series && series.data.length">
      <apex-chart
        type="bar"
        :height="300"
        :options="gradesGraphOptions"
        :series="[series]"
      />
    </div>
    <div
      v-else
    >
      <pms-no-data class="mt-20 fs-4 fw-bold" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useLoader } from '@/shared/composable'
import { GradesDetailsGraph } from '../types'
import ApexChart from 'vue3-apexcharts'

interface IGradeStatisticsProps {
  title: string;
  gradesGraphOptions: unknown;
  series: GradesDetailsGraph['series']
}

const { isActive } = useLoader()
const loader = isActive('academic/courses/grades/{assesment}/statistics')

const { title, gradesGraphOptions, series } = defineProps<IGradeStatisticsProps>()

</script>
