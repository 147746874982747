<template>
  <pms-smart-table
    id="table-search-reference-courses"
    :headers="headers"
    :items="foundCourses"
    :sortable="true"
  >
    <template #code="{item}">
      {{ item.code }}
    </template>
    <template #title="{item}">
      {{ item.title }}
    </template>
    <template #actions="{item}">
      <pms-button @Click="handleAddCourseToReferences(item)">
        <i class="bi bi-plus-lg" />
      </pms-button>
    </template>
  </pms-smart-table>
</template>
<script lang="ts" setup>
import { SmartTableHeader } from '@/shared/types'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { referenceCourses } from '../values'

const store = useStore()
const { foundCourses } = storeToRefs(store)

const handleAddCourseToReferences = (item: typeof foundCourses.value[0]) => {
  const existCourse = referenceCourses.value.find(referenceCourse => {
    return referenceCourse.equicode === item.equicode
  })
  if (!existCourse) {
    referenceCourses.value = [{ code: item.code, title: item.title, equicode: item.equicode }, ...referenceCourses.value]
  }
}

const headers: Array<SmartTableHeader> = [
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.actions',
    field: 'actions',
    slotCellName: 'actions'
  }
]
</script>
