import type { StatusMessageResponse } from '@shared/types'
import type{ ServiceManageLanguagesSingleStudentPutRequest } from '@shared/swagger'
import { putServiceManageLanguagesSingleStudent } from '@shared/api'

import type { StoreType } from '../types'

export default async function putSingleStudent (this: StoreType, id: string, values: ServiceManageLanguagesSingleStudentPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesSingleStudent(id, values)

  return {
    status: ok,
    message
  }
}
