<template>
  <pms-card
    :title="$t( 'domains.academic.advisory.table.header-thesis')"
    :description="$t('domains.academic.advisory.table.header-description-thesis')"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <template #toolbar>
      <student-search />
    </template>
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="table-block-thesis-students"
      :headers="advisoryThesisStudentsTable"
      :items="thesisStudents"
      :sortable="true"
      :sort-field="thesisStudentsSortField"
      @onSortFieldChange="handleSortClick"
    >
      <template #studentData="{ item }">
        <span
          class="cursor-pointer"
          @click="handleRedirectToStudentInfo(item.id)"
        >
          <MyStudentData :student="item" />
        </span>
      </template>
      <template #class="{ value }">
        <span class="fs-5 fw-bold">{{ value }}</span>
      </template>
      <template #academicStatus="{ item }">
        <pms-badge
          v-if="item.status"
          :value="item.status"
          :color="getAcademicStatus(item.status)"
          :size="'md'"
          class="me-2"
        />
      </template>
    </pms-smart-table>
    <pms-pagination class="p-0 m-0 mt-6" />
  </pms-card>
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>
<script setup lang="ts">
import { useLoader, usePagination } from '@shared/composable'

import { advisoryThesisStudentsTable, thesisStudentsSortField } from '../values'
import { useRedirectToSingleStudent } from '../composables'
import { useMyStudents } from '../store'

import MyStudentData from './MyStudentData.vue'
import StudentSearch from '@domains/AcademicAdvisory/ui/StudentSearch.vue'
import { storeToRefs } from 'pinia'
import { SmartTableSortField } from '@/shared/types'
import { StudentStatus } from '@/shared/enums'
import { watch } from 'vue'

const { isActive } = useLoader()
const { perPage } = usePagination()

const { handleRedirectToStudentInfo } = useRedirectToSingleStudent()
const store = useMyStudents()

const loader = isActive('academic/students/thesis')
const { thesisStudents } = storeToRefs(store)

const getAcademicStatus = (status: string) => {
  let color = 'primary'
  switch (status) {
    case StudentStatus.NotStudying: {
      color = 'secondary'
      break
    }
    case StudentStatus.Graduated: {
      color = 'success'
      break
    }
  }

  return color
}

const handleSortClick = (sortField: SmartTableSortField) => {
  console.log('render handle sort click')

  thesisStudentsSortField.value = { ...sortField }
}
watch(thesisStudentsSortField, async (newTableFields, oldTableFields) => {
  JSON.stringify(newTableFields) !== JSON.stringify(oldTableFields) &&
  await store.fetchThesisStudents({
    per_page: perPage.value,
    offset: 1
  })
}, { deep: true })
</script>

  <style scoped>
  .student-tooltip {
    position: absolute;
    z-index: 999;
    display: none;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
    width: 200px;
    pointer-events: none;

    .student-image {
      width: 100%;
      height: 100%;
      min-height: 250px;
      object-fit: cover;
    }
  }
  </style>
