<template>
  <pms-modal
    :name="'coursesModal'"
    :header="false"
  >
    <Accordion
      v-if="serviceMessageBroadcastResource !== null"
      multiple
    >
      <AccordionTab
        v-for="(course) in serviceMessageBroadcastResource.courses"
        :key="course.course_id"
        :header="`${course.title} [${course.type}-${course.section}]`"
      >
        <pms-smart-table

          :items="course.students"
          :headers="headers"
          :sortable="true"
        >
          <template #select-all-checkbox>
            <pms-checkbox
              :state="course.students.every(student => selectedStudents.includes(student.id))"
              @click="toggleCourseStudents(course)"
            />
          </template>
          <template #checkbox="{ item }">
            <pms-checkbox
              v-if="item"
              :state="selectedStudents.some(e => e === item.id)"
              @click="toggleStudent(item)"
            />
          </template>
          <template #fullname="{ item }">
            <span
              v-if="item"
            >{{ item.full_name }}</span>
          </template>
        </pms-smart-table>
      </AccordionTab>
    </Accordion>
  </pms-modal>
</template>
<script lang="ts" setup>
import { coursesHeaders as headers } from '../values'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useResource } from '@/shared/stores'
import { storeToRefs } from 'pinia'
import { useMessageBroadcastCompose } from '../store'
import { useCourseModal } from '../composables/course_modal'

const resourceStore = useResource()
const { serviceMessageBroadcastResource } = storeToRefs(resourceStore)

const store = useMessageBroadcastCompose()
const { selectedStudents } = storeToRefs(store)

const { toggleStudent, toggleCourseStudents } = useCourseModal()
</script>
