import type { StatusMessageResponse } from '@shared/types'
import type{ AcademicCoursesConsentRequestsPutRequest } from '@shared/swagger'
import { putAcademicCoursesConsentRequests } from '@shared/api'
import type { StoreType } from '../types'
import { selectedSection, YearTerm } from '../../values'

export default async function putConsentRequest (this: StoreType, id: number, values: AcademicCoursesConsentRequestsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicCoursesConsentRequests(id, values)
  ok && this.fetchConsentRequestsSections({ section: selectedSection.value.id })
  ok && this.fetchConsentRequests(YearTerm.value)

  return {
    status: ok,
    message
  }
}
