import type { StoreType } from '../types'
import type { AdministrativeGateEntrySendMailRequest } from '@shared/swagger'

import { postAdministrativeGateEntrySendMail } from '@shared/api'
import { useAlert } from '@shared/composable'

export default async function fetchMyRecords (this: StoreType, body: AdministrativeGateEntrySendMailRequest): Promise<void> {
  const { error, success } = useAlert()

  const { ok, message, data } = await postAdministrativeGateEntrySendMail(body)
  if (ok) {
    success({ text: message, isToast: true })
    this.setSendMail(data)
  } else {
    error({ text: message })
  }
}
