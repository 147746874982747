import type { StoreType } from '../types'

import { getAdministrativeGateEntryResourceUsers } from '@shared/api'
import { AdministrativeGateEntryResourceUsersRequest } from '@shared/swagger'

export default async function fetchAdministrativeGateEntryResourceUsers (this: StoreType, dat: AdministrativeGateEntryResourceUsersRequest): Promise<void> {
  const { ok, data } = await getAdministrativeGateEntryResourceUsers(dat)
  if (ok) {
    this.setAdministrativeGateEntryResourceUsers(data)
  }
}
