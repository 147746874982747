import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementRequestPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putSectionManagementRequest (id: number, params: SectionManagementRequestPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/requests/${id}`,
    body: params,
    loader: 'academic/manage/sections/requests-post'
  })

  return response
}
