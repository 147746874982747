import type { StoreType } from '../types'
import { ResourceRequest } from '@shared/swagger'

import { getAcademicManageCourseResource } from '@shared/api'

export default async function fetchAcademicManageCourseResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getAcademicManageCourseResource(body)

  if (ok) {
    this.setAcademicManageCourseResource(data)
  }
}
