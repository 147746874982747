import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicCoursesHistoryRequest,
  AcademicCoursesHistoryResponse
} from '@shared/swagger'

export default async function getAcademicCoursesHistory (params: AcademicCoursesHistoryRequest): Promise<AxiosResponse<AcademicCoursesHistoryResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicCoursesHistoryResponse>({
    url: 'academic/courses/history',
    body: { params: params },
    loader: 'academic/courses/history'
  })

  return response
}
