<template>
  <pms-card :title="`${t('common.actions')}`">
    <div class="d-flex gap-5">
      <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
        <AddCourseButton
          v-if="trackId"
          :term="term"
          :track-id="trackId"
        />
      </PermissionCheck>
      <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
        <AddElectiveSlotButton
          v-if="trackId"
          :term="term"
          :track-id="trackId"
        />
      </PermissionCheck>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AddCourseButton from '../../../shared/ui/AddCourseButton.vue'
import AddElectiveSlotButton from '../../../shared/ui/AddElectiveSlotButton.vue'
import permissionCodes from '@/shared/values/permissionCodes'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'

const route = useRoute()
const { t } = useI18n()
const trackId = typeof route.params.trackId === 'string' ? +route.params.trackId : undefined
const term = typeof route.params.term === 'string' ? +route.params.term : undefined
</script>
