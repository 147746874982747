import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicSingleCourseAttendancesQRPostRequest, AcademicSingleCourseAttendancesQRPostResponse } from '@shared/swagger'

export default async function postAcademicCoursesAttendances (body: AcademicSingleCourseAttendancesQRPostRequest): Promise<AxiosResponse<AcademicSingleCourseAttendancesQRPostResponse>> {
  const { post } = useRequests()

  const response = post<AcademicSingleCourseAttendancesQRPostRequest, AcademicSingleCourseAttendancesQRPostResponse>({
    url: 'academic/courses/attendances/by-courses/qr',
    body,
    loader: 'academic/courses/attendance/qr'
  })

  return response
}
