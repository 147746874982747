import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumCoreCoursesResponse, AcademicManageCurriculumCoreCoursesRequest } from '@shared/swagger'

export default async function getAcademicalCoreCourses (params: AcademicManageCurriculumCoreCoursesRequest): Promise<AxiosResponse<AcademicManageCurriculumCoreCoursesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumCoreCoursesResponse>({
    url: '/academic/manage/curriculum/core',
    body: { params },
    loader: '/academic/manage/curriculum/core'
  })

  return response
}
