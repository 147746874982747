export const views: Array<{ id: 'employee' | 'student' } & Record<string, string>> = [
  {
    id: 'employee',
    image: '/media/icons/duotune/communication/com005.svg',
    title: 'domains.administrative.gate-entry.views.view-employee-title',
    description: 'domains.administrative.gate-entry.views.view-employee-description'
  },
  {
    id: 'student',
    image: '/media/icons/duotune/technology/teh002.svg',
    title: 'domains.administrative.gate-entry.views.view-student-title',
    description: 'domains.administrative.gate-entry.views.view-student-description'
  }
]
