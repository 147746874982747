import { AcademicCoursesStudentsResponse } from '@shared/swagger'

const getStudentsBySection = (students:AcademicCoursesStudentsResponse, section: number|null) => {
  if (section === null) {
    return students
  }
  return students.filter((s) => s.enrolled_sections.some((es) => es.section_id === section))
}

export default getStudentsBySection
