
const generateHtml = (element: HTMLElement) => {
  return `
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Inter:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
        <title>Examination report</title>
        <style type="text/css">
        
    
        td {
            padding-left:2px; 
            font-family:Arial, Helvetica, sans-serif
        }
        @media print {
            .more { 
                page-break-after: always;
            } 
        } 
        .graph li
        {
            position:absolute;
            list-style:none;
            background:lightblue;
            width:50px;
            text-align:center;
            border:1px solid black;
            visibility: hidden;
            
        }
        .graph ul {

            position: absolute;
            top: 0;
            left: 32px;
            display: block;
            width: 1000px;
            height: 244px;
            background-color: white;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            margin: 0;

        }
        .graph{

            position: relative;
            width: 1000px;
            height: 240px;
            margin-left: 20px;
            padding: 0;
            font-size: 12px;

        }
        .labels {
            margin-top: -10px;
            margin: 8px;
            padding: 0;
            position: relative;
            width: 1000px;
        }
        hr{
            
            border: 1px solid #000
        }
        .parent_graph{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
           
        }

        </style>
    </head>
    <body>
        
        ${element.innerHTML}
    <script type="text/javascript">
        var container = document.getElementById("graph_of_grades");
        var labels = document.getElementById("labels_of_grades");
        var dnl = container.getElementsByTagName("li");

        for(var i = 0; i < dnl.length; i++)
        {
            var item = dnl.item(i);
            var value = item.innerHTML;
            var content = value.split(":");
            var color =item.style.background;
            value = content[0];
            item.style.top=(243 - value  * 2.24) + "px";
            item.style.left = (i * 60 + 20) + "px";
            item.style.height = value * 2.24 + "px";
            item.innerHTML = "<span align='center' style='position:absolute; margin-left:-15px; margin-top:-20px;'>"+value+"%</span>";
            item.style.visibility="visible";
            if(content.length > 2)
            {
                color = content[2];
                item.style.background=color;
            }

            left = new String(i * 60 + 68) + "px";

            labels.innerHTML = labels.innerHTML + "<span align='center' style='position:absolute;margin-left:15px; left:"+ left+";background:"+ color+"'>" + content[1] + "</span>";

        }
    </script> 
    </body> 
  `
}

const printExamReport = (element: HTMLElement) => {
  const printWindow: Window | null = window.open('', '', 'height=400,width=500')

  if (!printWindow) return
  printWindow.document.write(generateHtml(element))
  printWindow.document.close()
  printWindow.focus()
  setTimeout(() => printWindow.print(), 100)
}

export default printExamReport
