<template>
  <Teleport to="#education-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationConductedGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { levelsToOptionType } from '../utils'
import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/education-edit', 'profile/education-post'])

const conductedCourses = computed(() => store.getConductedCourses)
const levels = computed(() => resource.getProfileResource?.edu_levels || [])

const defaultTempValues: ProfileEducationConductedGetResponse[0] = {
  id: -1,
  title: '',
  level: ''
}

const tempValues = ref<ProfileEducationConductedGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.education.conducted.level-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'level',
    type: 'select',
    value: tempValues.value.level,
    required: true,
    options: levelsToOptionType(levels.value)
  },
  {
    label: {
      text: t('domains.profile.education.conducted.title-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    required: true,
    placeholder: t('domains.profile.education.conducted.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  }
])

const closeModal = () => {
  close({ name: 'education-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.level) {
    error({ text: t('domains.profile.conducted.level-id-field-error') })
    return
  }
  if (!values.title) {
    error({ text: t('domains.profile.conducted.title-field-error') })
    return
  }
  const { status, message } =
        modifier.value?.data?.action === 'new'
          ? await store.addConductedCourse({ ...values })
          : await store.editConductedCourse(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'education-modal' })
    unsetModifier()
    store.fetchConductedCourses()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactCourse = conductedCourses.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactCourse || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
