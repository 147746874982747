import { App, Component, Plugin } from 'vue'
import { AvocadoDirective, AvocadoScript, AvocadoUI, AvocadoUIComponent } from '@/avocado/types'

import { SharedComponentConfiguration } from '@shared-types/common'
import { capitalizeFirstLetter, snakeToCamel } from '@shared-utils/text'

const getMappedName = ({ mappedNames }: SharedComponentConfiguration, key: string): string | null => {
  if (!mappedNames) return null
  if (key in mappedNames) { return mappedNames[key] }
  return null
}

const prepareUIComponents = (ui: Array<AvocadoUI>): Array<AvocadoUIComponent> => {
  const components: Array<AvocadoUIComponent> = []
  const configElement = ui.find((uiItem) => uiItem.type === 'config')

  ui
    .filter((uiItem) => uiItem.type === 'component')
    .forEach(({ name, component }) => {
      name = name.replace('.tsx', '')

      if (configElement) {
        const configuration = configElement.component as SharedComponentConfiguration
        const mapName = getMappedName(configuration, name)

        components.push({ name: capitalizeFirstLetter(snakeToCamel(configuration.prefix)) + (mapName !== null ? mapName : name), component: component as Component })
      } else {
        components.push({ name, component: component as Component })
      }
    })

  return components
}

export const buildPlugin = (scripts: Array<AvocadoScript>, directives: Array<AvocadoDirective>, ui: Array<AvocadoUI>): Plugin => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    install: (app: App<Element>, options?: Record<string, unknown>): void => {
      const uiComponents: Array<AvocadoUIComponent> = prepareUIComponents(ui)

      directives
        .forEach(({ name, directive }) => { app.directive(name, directive) })

      scripts
        .sort((a, b) => {
          if (a.priority && b.priority) return b.priority - a.priority
          if (a.priority) return -1
          if (b.priority) return 1
          return 0
        })
        .forEach((script) => {
          script.run(app)
        })

      uiComponents
        .forEach(({ name, component }) => { app.component(name, component) })
    }
  }
}
