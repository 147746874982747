/* eslint-disable no-return-assign */

const applyTestId = (el, value) => {
  if (process.env.NODE_ENV !== 'production' || location.hostname.includes('localhost')) {
    el.setAttribute('data-testid', value)
  }
}

export const applyTestID = {
  mounted: (el, binding) => applyTestId(el, binding.value),
  updated: (el, binding) => applyTestId(el, binding.value)
}

export default {
  name: 'test-id',
  directive: applyTestID
}
