import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { getScheduleManagementSectionResources } from '@shared/api'

export default async function fetchSectionResources (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getScheduleManagementSectionResources()

  if (ok) {
    this.setBuildings(data.buildings)
  }

  return {
    status: ok,
    message
  }
}
