import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementCoursesStatisticsGetRequest,
  SectionManagementCoursesStatisticsGetResponse
} from '@shared/swagger'

export default async function getSectionManagementCoursesStatistics (params: SectionManagementCoursesStatisticsGetRequest): Promise<AxiosResponse<SectionManagementCoursesStatisticsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementCoursesStatisticsGetResponse>({
    url: 'academic/manage/sections/courses/statistics',
    body: { params: params },
    loader: 'academic/manage/sections/courses/statistics'
  })

  return response
}
