import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, KnowledgeManageResourceRequest, KnowledgeManageResourceResponse } from '@shared/swagger'

export default async function getKnowledgeResource (params: KnowledgeManageResourceRequest): Promise<AxiosResponse<KnowledgeManageResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeManageResourceResponse>({
    url: 'knowledge/manage/resources',
    body: { params: params },
    loader: 'knowledge/manage/resources'
  })

  return response
}
