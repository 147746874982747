import { postRulesAndRegulationsAttachments } from '@/shared/api'
import { StoreType } from '../types'
import { StatusMessageResponse } from '@/shared/types'

export default async function createRuleAttachment (this: StoreType, ruleId: string, attachment: FormData): Promise<StatusMessageResponse> {
  const { ok, message } = await postRulesAndRegulationsAttachments(attachment, ruleId)

  return {
    status: ok,
    message
  }
}
