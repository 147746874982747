<template>
  <div
    id="profile-education-supervision"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.education.supervision.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-150px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="supervisions"
        :headers="tableHeadersSupervision"
        :sortable="true"
      >
        <template #student="{item}">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-35px me-3">
              <div
                class="symbol-label fw-bold"
                :class="{
                  'bg-light-primary': !item.is_ongoing,
                  'bg-light-warning': item.is_ongoing
                }"
              >
                <span
                  :class="{
                    'text-primary': !item.is_ongoing,
                    'text-warning': item.is_ongoing
                  }"
                >{{ getNameFirstLetters(item.student) }}</span>
              </div>
            </div>
            <div>
              <span class="text-gray-800 fw-bold m-0 lh-1 fs-5">{{ item.student }}</span>
            </div>
          </div>
        </template>
        <template #topic="{item}">
          <div class="mb-2">
            <span class="fs-7 lh-1 me-1">{{ item.university }}</span>

            <pms-badge
              v-if="getLevelById(item.level_id)"
              :color-variant="'tonal'"
            >
              {{ getLevelById(item.level_id) }}
            </pms-badge>

            <pms-badge
              class="ms-1 "
              :color-variant="'tonal'"
              :color="item.student_status === 'Graduated' ? 'success' : item.student_status === 'Not studying' ? 'danger' : item.student_status === 'Pre-Registered' ? 'warning' : 'info'"
            >
              {{ item.student_status }}
            </pms-badge>
          </div>
          <p class="text-gray-800 fw-bold m-0 fs-5">
            {{ item.topic }}
          </p>
        </template>
        <template #date="{item}">
          <p class="text-gray-800 m-0 fs-5">
            <span class="fw-bold">Start date: </span> <br>{{ item.start_year }}
          </p>
          <p
            v-if="!item.is_ongoing"
            class="text-gray-800 m-0 fs-5"
          >
            <span
              v-if="item.defense_year"
              class="fw-bold"
            >End date: <br></span> {{ item.defense_year }}
          </p>
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <SupervisionForm v-if="modifier && modifier.name === 'supervision'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { tableHeadersSupervision } from '../values'
import { useProfile } from '../../../store'

import SupervisionForm from './SupervisionForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const supervisions = computed(() => store.getSupervisions)
const levels = computed(() => resource.getProfileResource?.edu_levels || [])

const setIdAction = (id: number) => {
  setModifier('supervision',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'supervision-new-title' : 'supervision-edit-title',
      description: id === -1 ? 'supervision-new-description' : 'supervision-edit-description'
    }
  )
  open({ name: 'education-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.education.supervision.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteSupervision(id)
    }
  })
}

const getLevelById = (id: number|string): string => {
  return levels.value.find((level) => level.code === id)?.title || ''
}

const getNameFirstLetters = (name: string) => {
  let letters = ''
  name.split(' ').forEach(word => { letters += word[0] })

  return (letters.length > 2 ? letters.substring(0, 2) : letters).toUpperCase()
}

</script>
