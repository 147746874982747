<template>
  <div
    :id="`user-privacy-${type}`"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ title }}
      </span>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: {
          class: 'pb-10'
        }
      }"
    >
      <template
        v-for="(item, index) in editPrivacy"
        :key="index"
      >
        <div
          class="d-flex flex-stack"
          :class="{
            'mt-0': index === 0,
            'mt-2': index !== 0
          }"
        >
          <div class="d-flex">
            <slot
              name="item"
              v-bind="item"
            >
              <div class="d-flex flex-column">
                <span class="fs-5 text-dark text-hover-primary fw-bold">{{ item.id }}</span>
                <div class="fs-6 fw-semibold text-gray-400">
                  {{ $t(`domains.profile.settings.user-privacy-card.id-${item.id}`) }}
                </div>
              </div>
            </slot>
          </div>
          <div class="d-flex justify-content-end">
            <div class="form-check form-check-solid form-check-custom form-switch">
              <input
                :id="`checker-${index}`"
                v-model="item.show_status"
                class="form-check-input w-45px h-30px"
                type="checkbox"
                @change="modified = true"
              >
              <label
                class="form-check-label"
                :for="`checker-${index}`"
              />
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="modified"
        class="d-flex justify-content-end mt-6"
      >
        <button
          class="btn btn-light btn-sm btn-active-light-primary me-2"
          type="button"
          @click="discardChanges()"
        >
          {{ $t('common.discard') }}
        </button>
        <button
          class="btn btn-primary btn-sm"
          type="button"
          @click="submitPrivacy()"
        >
          {{ loader ? $t('phrases.save-changes') : $t('phrases.saving') }}
          <span
            v-if="!loader"
            class="spinner-border spinner-border-sm align-middle ms-2"
          />
        </button>
      </div>
      <pms-no-data v-if="privacy.length ===0" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { watch, PropType, ref } from 'vue'

import { objectDeepCopy } from '@shared/utils'
import type { Privacy, ContactPrivacy } from '../types'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  loader: {
    type: String,
    required: true
  },
  privacy: {
    type: Array as PropType<Array<Privacy | ContactPrivacy>>,
    required: true
  }
})

const emit = defineEmits(['set-privacy'])
const modified = ref(false)

let firstCopy
const editPrivacy = ref(props.privacy)

const discardChanges = () => {
  editPrivacy.value = objectDeepCopy(firstCopy)
  modified.value = false
}

const submitPrivacy = () => {
  emit('set-privacy', editPrivacy.value)
  firstCopy = objectDeepCopy(editPrivacy.value)
  modified.value = false
}

watch(() => props.privacy,
  (newValue) => {
    editPrivacy.value = objectDeepCopy(newValue)
    firstCopy = objectDeepCopy(newValue)
  },
  { immediate: true }
)
</script>
