import { SmartTableHeader } from '@/shared/types'

const tableCategoryHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title',
    sortable: true
  },
  {
    title: 'common.table.language',
    field: 'language_short',
    sortable: true
  },
  {
    title: 'common.table.related-posts',
    field: 'posts_count',
    sortable: true,
    slotCellName: 'posts_count'
  },
  {
    title: 'common.table.created-at',
    field: 'created_at',
    sortable: true
  },
  {
    title: 'common.table.updated-at',
    field: 'updated_at',
    sortable: true
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export default tableCategoryHeaders
