import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  AcademicManageSyllabusLearningOutcomesPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusLearningOutcomes (id: number, body: AcademicManageSyllabusLearningOutcomesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusLearningOutcomesPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/learning-outcomes/${id}`,
    body,
    loader: 'academic/manage/syllabus/learning-outcomes'
  })
  return response
}
