import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationPostRequest } from '@shared/swagger'

export default async function putProfileEducation (id: number, values: ProfileEducationPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileEducationPostRequest, UnknownResponse>({
    url: `profile/education/${id}`,
    body: values,
    loader: 'profile/education-edit'
  })

  return response
}
