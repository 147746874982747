<template>
  <div
    id="fixed-menu"
    class="card shadow"
  >
    <div class="card-body p-4 align-items-center">
      <span class="fw-bold fs-5 pe-0">
        {{ $t('common.action') }}:
      </span>
      <pms-button
        class="ms-2 py-2 px-4 pe-0"
        @click="goToGmail(studentEmails, courseDetails)"
      >
        <span>{{ $t('domains.academic.single-course.students.compose-email') }}</span>
        <pms-kt-icon
          :icon-name="'send'"
          class="mx-2 pb-1"
        />
      </pms-button>
      <pms-button
        :text="'domains.academic.single-course.students.print'"
        class="ms-2 py-2 px-4"
        @click="printSingleCourseStudentsList(students)"
      />
      <pms-button
        :text="'domains.academic.single-course.students.excel'"
        class="ms-2 py-2 px-4"
        @click="excelDownloadAction(students)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import { AcademicCoursesStudentsResponse } from '@shared/swagger'
import { useResource } from '@/shared/stores'

import { getStudentsEmails, printSingleCourseStudentsList, excelDownloadAction } from '../utils'
import { goToGmail } from '../../../utils'

const props = defineProps({
  students: {
    type: Array as unknown as PropType<AcademicCoursesStudentsResponse>,
    required: true,
    default: () => []
  }
})

const resource = useResource()

const studentEmails = computed(() => getStudentsEmails(props.students).join(', '))
const courseDetails = computed<string>(() => {
  const information = resource.getAcademicSingleCoursesResource?.information
  return information ? `${information.code} - ${information.title}` : ''
})
</script>

<style scoped lang="scss">
#fixed-menu {
  position: fixed;
  height: 60px;
  width: inherit;
  bottom: 0px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
