<template>
  <div
    id="biography"
    class="col-12"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.autobiography.biography.title') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="onClickEdit"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.edit') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
    >
      <div
        v-if="biography"
        class="text-dark biography-body fs-5 lh-base"
        v-html="biography"
      />
      <div
        v-else
        class="text-center"
        style="padding-top: 50px; padding-bottom: 50px;"
      >
        <span>
          {{ $t('common.no-data') }}
        </span>
      </div>
    </pms-card>
  </div>
  <BiographyForm v-if="modifier && modifier.name === 'biography'" />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useTeleportModal } from '@shared/composable'

import { useProfile } from '../../../store'
import BiographyForm from './BiographyContentForm.vue'

const { open, setModifier, modifier } = useTeleportModal()

const store = useProfile()
const biography = computed(() => store.getBiography)

const onClickEdit = () => {
  setModifier('biography',
    { biography: biography.value },
    { title: 'biography', description: 'biography-description' })
  open({ name: 'biography-modal' })
}
</script>
