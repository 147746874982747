import type { StoreType } from '../types'
import { destroySectionManagementOffer } from '@shared/api'
import { StatusMessageResponse } from '@shared/types'

export default async function removeOffer (this: StoreType, offerId: number): Promise<StatusMessageResponse> {
  const { message, ok } = await destroySectionManagementOffer(offerId)
  return {
    status: ok,
    message
  }
}
