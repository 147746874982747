import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'
import { useToken } from '@shared/composable'
import * as actions from './actions'
import * as getters from './getters'

export const useSyllabuses = defineStore<Name, State, Getters, Actions>('Academic.useSyllabuses', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    const token = useToken()

    return {
      yearTerm: {
        year: token.getYear(),
        term: token.getTerm(),
        id: 0
      },
      syllabuses: null
    }
  }
})
