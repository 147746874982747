<template>
  <pms-checkbox
    :title="assessment.assessment_title"
    class="d-flex justify-content-center"
    :variant="themeMode == 'dark' ? 'solid' : 'default'"
    :state="assessment.assessment_id === selectedAssessment"
    :disabled="!assessment.is_open"
    @on-check="handleSelectAssessment(assessment.assessment_id)"
    @on-uncheck="handleUnselectAssessment()"
  />
</template>

<script setup lang="ts">
import { themeMode } from '@shared/composable/pattern'
import { selectedAssessment, addedGrades, clearAllGradesValues, clearBellCurvedGrades, selectedLmsAssessment } from '../values'
import { PropType } from 'vue'
import { AcademicCoursesResourcesSingleResponse, AcademicSingleCourseGradeResponse } from '@shared/swagger'
import { getStudentsGrade } from '../utils'

const props = defineProps({
  assessment: {
    type: Object as PropType<AcademicCoursesResourcesSingleResponse['assessments'][0]>,
    required: true
  },
  grades: {
    type: Array as PropType<AcademicSingleCourseGradeResponse>,
    required: true
  }
})

const handleSelectAssessment = (id: number) => {
  clearBellCurvedGrades()
  selectedAssessment.value = id
  addedGrades.value = getStudentsGrade(props.grades, id)
  selectedLmsAssessment.value = null
}

const handleUnselectAssessment = () => {
  clearAllGradesValues()
}
</script>

<style scoped lang="scss">

</style>
