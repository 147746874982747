<template>
  <div
    v-if="resource"
    class="mb-6"
  >
    <div class="text-center">
      <p class="fs-3 fw-bold m-0">
        {{ resource.course.code }}. {{ resource.course.title }} ({{ resource.course.credits }} {{ $t('common.table.credits') }})
      </p>
      <p class="m-0 mb-1">
        {{ resource.course.department.title }} ({{ resource.course.department.code }})
      </p>
      <p class="m-0 fw-semibold">
        <span>
          {{ resource.course.week_number }} ({{ $t('common.table.number-of-weeks') }}),
        </span>
        <span>
          {{ resource.course.teaching_hours }} ({{ $t('common.table.teaching-hours') }}),
        </span>
        <span>
          {{ resource.absence_limit }}% ({{ $t('common.table.absence-limit') }})
        </span>
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAttendance } from '../composable'
const { resource } = useAttendance()
</script>
