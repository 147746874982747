import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementSectionsGetRequest,
  SectionManagementSectionsGetResponse
} from '@shared/swagger'

export default async function getSectionManagementSections (params: SectionManagementSectionsGetRequest): Promise<AxiosResponse<SectionManagementSectionsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSectionsGetResponse>({
    url: 'academic/manage/sections',
    body: { params: params },
    loader: 'academic/manage/sections'
  })

  return response
}
