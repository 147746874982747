import { RouteRecordRaw } from 'vue-router'

import { AvocadoLayout } from '@avocado/types'
import { RouteRecordWithLayout } from '@shared/types'

const matchLayouts = (routes: Array<RouteRecordWithLayout>, layouts: Array<AvocadoLayout>): Array<RouteRecordRaw> => {
  const matched: Array<RouteRecordRaw> = []

  routes.forEach((route: RouteRecordWithLayout) => {
    if ('layout' in route) {
      const layout = layouts.find((l) => l.name === route.layout)

      if (layout && layout.component !== undefined) {
        matched.push({ ...route, component: layout.component } as RouteRecordRaw)
      } else {
        throw new Error(`No layout found for route: ${route.path}`)
      }
    } else {
      matched.push(route as RouteRecordRaw)
    }
  })
  return matched
}

export const buildRoutes = (routes: Array<RouteRecordWithLayout>, layouts: Array<AvocadoLayout>): Array<RouteRecordRaw> => {
  return matchLayouts(routes, layouts)
}
