<template>
  <pms-card
    class="mt-5"
    :title="outcome?.program_year + ' - ' + outcome?.program_code + ' ' + outcome?.program_title"
    :description="outcome?.education_level"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <div class="mb-5">
      <!-- Қазақ тіліндегі сипаттама: -->
      <label for="kz">{{ $t('domains.academic.curricula.outcome.description.kz') }}</label>
      <Textarea
        id="kz"
        v-model="localLang['kz']"
      />
    </div>
    <div class="mb-5">
      <label for="ru">{{ $t('domains.academic.curricula.outcome.description.ru') }}</label>
      <Textarea
        id="ru"
        v-model="localLang['ru']"
      />
    </div>
    <div class="mb-5">
      <label for="en">{{ $t('domains.academic.curricula.outcome.description.en') }}: </label>
      <Textarea
        id="en"
        v-model="localLang['en']"
      />
    </div>

    <div class="d-flex justify-content-center">
      <Button
        v-if="isDirty"
        :label="$t('common.save')"
        :class="'w-100px'"
        class="save-button"
        @click="save"
      />
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import useOutcomeForm from '../composable/useOutcomeForm'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'

const store = useStore()
const { outcome } = storeToRefs(store)

const {
  localLang,
  isDirty,
  save
} = useOutcomeForm()
</script>

<style scoped>
.outcomes-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

Textarea {
  width: 100%;
}
</style>
