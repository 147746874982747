import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationOrganizationsPostRequest } from '@shared/swagger'

export default async function putProfileEducationOrganizations (id: number, values: ProfileEducationOrganizationsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileEducationOrganizationsPostRequest, UnknownResponse>({
    url: `profile/education/organizations/${id}`,
    body: values,
    loader: 'profile/education/organizations-edit'
  })

  return response
}
