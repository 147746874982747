import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { postSectionManagementOffer } from '@shared/api'
import { SectionManagementOfferPostRequest } from '@shared/swagger'

export default async function sendPostOffer (this: StoreType, params: SectionManagementOfferPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postSectionManagementOffer(params)

  return {
    status: ok,
    message
  }
}
