<template>
  <div class="d-flex justify-content-end">
    <pms-button
      type="button"
      color="warning"
      :text="'domains.academic.manage.sections.open-my-courses'"
      :loader="loader"
      @click="openMyCourses"
    />
  </div>
  <div class="d-flex align-items-center gap-2 mt-4">
    <p class="fs-5 m-0">
      {{ $t('domains.academic.manage.sections.new-request.request-by') }}:
    </p>

    <div class="d-flex flex-row">
      <select
        v-model="staticRequest"
        class="form-select form-select w-155px"
        @change="setRequestType($event)"
      >
        <option :value="null" />
        <option
          v-for="type in requests"
          :key="type.title"
          :value="type.value"
          :selected="type.value === staticRequest"
        >
          {{ type.title }}
        </option>
      </select>
    </div>

    <pms-button
      type="button"
      class=""
      :disabled="staticRequest === null"
      :text="'common.next'"
      @click="setRequestComponent(staticRequest)"
    />
  </div>
  <MyCourcesForm v-if="modifier && modifier.name === 'my-courses-content'" />
</template>

<script setup lang="ts">
import { useNewRequest } from '../store'
import { requests } from '../values'
import { ref } from 'vue'
import { RequestType } from '../types'
import { useLoader, useTeleportModal } from '@shared/composable'
import MyCourcesForm from './MyCoursesForm.vue'

const { isActive } = useLoader()
const loader = isActive('academic/manage/sections/courses')

const newRequestStore = useNewRequest()
const staticRequest = ref<RequestType['value'] | null>(null)
const { setModifier, modifier, open } = useTeleportModal()

function setRequestType ($event) {
  if ($event.target.value) {
    staticRequest.value = $event.target.value
  } else {
    staticRequest.value = null
    newRequestStore.setRequestType(null)
  }
}

function setRequestComponent (request: RequestType['value'] | null) {
  newRequestStore.setRequestType(request)
}

async function openMyCourses () {
  await newRequestStore.fetchRequestCourses(
    {
      request_by: 'search',
      program_code: newRequestStore.selectedProgram?.code,
      program_year: newRequestStore.selectedProgram?.active_year
    }
  )

  setModifier('my-courses-content',
    { title: 'my-courses-title', description: 'my-courses-description' }
  )
  open({ name: 'new-request-modal' })
}
</script>
