import { createPage } from '@shared-utils/storybook'

import { useSingleSection } from '../../../store'

import OverviewPage from '../StudentsPage.vue'

const Template = createPage({
  layout: 'default',
  component: OverviewPage,
  setup () {
    const store = useSingleSection()
    store.$reset()
    store.bootstrap('overview', { year: 2021, term: 2, course: 'CSS 101', section: 'A' })
    return {}
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Single Course/StudentsPage',
  component: OverviewPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
