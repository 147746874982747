import { computed, ref } from 'vue'
import { cloneDeep } from 'lodash'

import { useAttendance } from './attendance'
import { EditorStates, EditingStatus, StudentWithSchedule } from '../types'
import { modules } from '../values'
import { useSingleSection } from '../../../store'

import { useAlert } from '@shared/composable'
import { arrayGroupByProperty } from '@shared/utils'
import { AcademicSingleCourseAttendancesPostRequest } from '@shared/swagger'
import { router } from '@/router'
import { useResource } from '@/shared/stores'

const editor = ref<EditorStates>({
  status: null,
  sessions: []
})
const editing = ref<EditingStatus>({
  status: false,
  records: []
})

export const useAttendanceEditor = () => {
  const store = useSingleSection()
  const resourceStore = useResource()
  const course = computed(() => store.getCourse)

  const { success, error, dialog } = useAlert()
  const { tableData, getCourseProperties, setTableData, fetchAttendance } = useAttendance()

  const qr_available = computed(() => {
    if (editor.value.status === 'set-multiple' && !editor.value.started && (editor.value.sessions as number[]).length < 2) {
      return resourceStore.getAcademicSingleCourseAttendanceResource?.schedule.find(schedule => schedule.id === editor.value.sessions[0])?.qr_available
    } else {
      return false
    }
  })

  const updateStudentAttendance = async () => {
    const { year, term, course_code, section } = getCourseProperties()
    await fetchAttendance(year, term, course_code, section)
    await resourceStore.fetchAcademicSingleCourseAttendanceResource({ course_code, year, term, section, modules })
    await setTableData()
  }

  const setEditingActive = () => {
    editing.value.status = true

    if (editor.value.status === 'set-single') {
      const sessionRecords = tableData.value.map((student) => {
        const record = student.schedule.find((session) => session.schedule_id === editor.value.sessions)

        if (record) return record
        return {
          schedule_id: editor.value.sessions,
          student_id: student.id,
          attend_id: 1
        }
      })

      editing.value.records = cloneDeep(sessionRecords)
    }

    if (editor.value.status === 'set-multiple') {
      editing.value.records = []
      for (const session of editor.value.sessions) {
        const sessionRecords = tableData.value.map((student) => {
          const record = student.schedule.find((sessionItem) => sessionItem.schedule_id === session)

          if (record) return record

          return {
            schedule_id: session,
            student_id: student.id,
            attend_id: 1
          }
        })

        editing.value.records.push(...cloneDeep(sessionRecords))
      }
    }

    editing.value.records = editing.value.records.map((rec) => {
      return {
        ...rec,
        attend_id: `${rec.attend_id}`
      }
    })
  }

  const updateEditingRecord = (session: number, student: number, attend_id: string) => {
    const record = editing.value.records.find((record) => record.schedule_id === session && record.student_id === student)
    if (record) record.attend_id = attend_id
  }

  const startSingleAttendance = (id: number) => {
    editor.value = {
      status: 'set-single',
      sessions: id
    }
    setEditingActive()
  }

  const resetSingleAttendance = async (id: number) => {
    const onConfirm = async () => {
      editor.value = { status: 'reset', sessions: [id] }

      const { status, message } = await store.deleteAttendance({
        schedule_id: id,
        students: tableData.value.map((student) => `${student.id}`)
      })

      status
        ? success({ title: 'phrases.reset-attendance-success', text: message, isToast: true })
        : error({ title: 'phrases.reset-attendance-error', text: message })

      if (status) {
        updateStudentAttendance()
      }
      resetDefault()
    }

    dialog({
      title: 'phrases.reset-attendance',
      text: 'phrases.reset-attendance-text',
      onConfirm
    })
  }

  const resetAttendances = async () => {
    let allSessions: Array<Omit<StudentWithSchedule['schedule'][0], 'id'>> = []
    for (const session of (Array.isArray(editor.value.sessions) ? editor.value.sessions : [])) {
      const sessionRecords = tableData.value.map((student) => {
        const record = student.schedule.find((sessionItem) => sessionItem.schedule_id === session)

        if (record) {
          return {
            schedule_id: record.schedule_id,
            student_id: `${record.student_id}`,
            attend_id: record.attend_id
          }
        }

        return {
          schedule_id: session,
          student_id: `${student.id}`,
          attend_id: 2
        }
      })

      allSessions = [...allSessions, ...sessionRecords]
    }

    await dialog({
      title: 'phrases.reset-session',
      text: 'phrases.reset-session-text-for-multiple',
      onConfirm: async () => {
        const schedules = [...new Set(allSessions.map(session => session.schedule_id))]
        const { status, message } = await store.putAttendancesReset({
          schedules
        })
        status
          ? success({ title: 'phrases.reset-attendance-success', text: message, isToast: true })
          : error({ title: 'phrases.reset-attendance-error', text: message })

        if (status) {
          updateStudentAttendance()
          resetDefault(true)
        }
      }
    })
  }

  const startSingleQrAttendance = (session_id: number) => {
    const { year, term, code, section } = course?.value || {}
    router.push({
      name: 'SingleCourseAttendanceQRPage',
      query: {
        schedule_id: session_id,
        section,
        year,
        term,
        code
      }
    })
  }

  const startSingleAttendanceRelocate = (id:number) => {
    const { year, term, code, section } = course?.value || {}

    router.push({
      name: 'AttendanceRelocate',
      params: {
        id: id
      },
      query: {
        section,
        year,
        term,
        code
      }
    })
  }

  const setMultipleAttendance = (id: number, action: 'remove' | 'add') => {
    if (editor.value.status !== 'set-multiple') {
      editor.value = {
        status: 'set-multiple',
        started: false,
        sessions: [id]
      }
      return
    }

    if (action === 'add') {
      editor.value.sessions.push(id)
    }

    if (action === 'remove') {
      editor.value.sessions = editor.value.sessions.filter(session => session !== id)
    }

    if (editor.value.sessions.length === 0) {
      resetDefault()
    }
  }

  const startMultipleAttendance = () => {
    if (editor.value.status === 'set-multiple') {
      editor.value.started = true
      setEditingActive()
    }
  }

  const resetDefault = (update = false) => {
    editor.value = {
      status: null,
      sessions: []
    }

    editing.value = {
      status: false,
      records: []
    }

    if (update) updateStudentAttendance()
  }

  const removeSessionFromMultiple = (id: number) => {
    if (editor.value.status === 'set-multiple') {
      editor.value.sessions = editor.value.sessions.filter(session => session !== id)
      editing.value.records = editing.value.records.filter(record => record.schedule_id !== id)

      if (editor.value.sessions.length === 0) {
        resetDefault()
      }
    }
  }

  const formattedAttendance = (): AcademicSingleCourseAttendancesPostRequest => {
    const editedGroupBySchedule = arrayGroupByProperty(editing.value.records, 'schedule_id')
    return Object.keys(editedGroupBySchedule).map((schedule_id) => {
      return {
        schedule_id: parseInt(schedule_id),
        students: editedGroupBySchedule[schedule_id].map((record) => {
          return {
            student_id: record.student_id,
            attend_id: parseInt(record.attend_id)
          }
        })
      }
    })
  }

  const saveAttendance = async (notConduct = false) => {
    let attendance = formattedAttendance()

    if (notConduct) {
      attendance = attendance.map((session) => ({ ...session, not_conducted: true }))
    }

    const { status, message } = await store.postAttendance(attendance)

    status
      ? success({ title: 'phrases.save-attendance-success', text: message, isToast: true })
      : error({ title: 'phrases.save-attendance-error', text: message })

    if (status) {
      updateStudentAttendance()
      resetDefault(true)
    }
  }

  const notConductSession = async (id: number) => {
    const sessionRecords = tableData.value.map((student) => {
      const record = student.schedule.find((session) => session.schedule_id === id)

      if (record) return record
      return {
        schedule_id: id,
        student_id: student.id,
        attend_id: 2
      }
    })

    await dialog({
      title: 'phrases.not-conduct-session',
      text: 'phrases.not-conduct-session-text',
      onConfirm: async () => {
        editing.value.records = cloneDeep(sessionRecords)
        await saveAttendance(true)
      }
    })
  }

  const startMultipleNotConducted = async () => {
    let allSessions: Array<Omit<StudentWithSchedule['schedule'][0], 'id'>> = []
    for (const session of (Array.isArray(editor.value.sessions) ? editor.value.sessions : [])) {
      const sessionRecords = tableData.value.map((student) => {
        const record = student.schedule.find((sessionItem) => sessionItem.schedule_id === session)

        if (record) {
          return {
            schedule_id: record.schedule_id,
            student_id: `${record.student_id}`,
            attend_id: record.attend_id
          }
        }

        return {
          schedule_id: session,
          student_id: `${student.id}`,
          attend_id: 2
        }
      })

      allSessions = [...allSessions, ...sessionRecords]
    }

    await dialog({
      title: 'phrases.not-conduct-session',
      text: 'phrases.not-conduct-session-text-for-multiple',
      onConfirm: async () => {
        editing.value.records = cloneDeep(allSessions)
        await saveAttendance(true)
      }
    })
  }

  return {
    tableData,
    editor,
    editing,
    qr_available,
    setEditingActive,
    updateEditingRecord,
    saveAttendance,
    resetDefault,
    startSingleAttendance,
    resetSingleAttendance,
    startSingleQrAttendance,
    startSingleAttendanceRelocate,
    startMultipleAttendance,
    setMultipleAttendance,
    removeSessionFromMultiple,
    notConductSession,
    startMultipleNotConducted,
    resetAttendances
  }
}
