import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationConferencesPostRequest } from '@shared/swagger'

export default async function putProfileEducationConferences (id: number, values: ProfileEducationConferencesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileEducationConferencesPostRequest, UnknownResponse>({
    url: `profile/education/conferences/${id}`,
    body: values,
    loader: 'profile/education/conferences-edit'
  })

  return response
}
