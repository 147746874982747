import type { StoreType } from '../types'
import type { AcademicManageCurriculumCourseSearchRequest } from '@/shared/swagger'

import findCourse from '@/shared/api/AcademicCurricula/findCourse'

export default async function fetchCourses (this: StoreType, params: AcademicManageCurriculumCourseSearchRequest): Promise<void> {
  const { data, ok } = await findCourse(params)

  if (ok) {
    this.setFoundCourses(data.courses)
  }
}
