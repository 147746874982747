import type { StoreType } from '../types'

import { getContentTranslateByPath } from '@shared/api'

export default async function bootstrap (this: StoreType, path: string): Promise<void> {
  const { ok, data } = await getContentTranslateByPath(path)

  if (ok && data) {
    this.setLocales(data.locales as Array<string>)
    this.setPath(data.file)
  }
}
