import { destroyProfileContacts } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteContact (this: StoreType, contact_id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyProfileContacts(contact_id)

  if (ok) await this.fetchContacts()

  return {
    status: ok,
    message
  }
}
