import { SmartTableHeader } from '@/shared/types'

export const allStudentsTable: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.student-card-id',
    field: 'id',
    slotCellName: 'id-number',
    sortable: true
  },
  {
    title: 'common.table.student-name-surname',
    field: 'id',
    slotCellName: 'id',
    sortable: true
  },
  {
    title: 'common.table.start-date',
    field: 'start-date',
    slotCellName: 'start-date'
  },
  {
    title: 'common.table.end-date',
    field: 'end-date',
    slotCellName: 'end-date'
  },
  {
    title: 'common.table.locked-by',
    field: 'locked-by',
    slotCellName: 'locked-by'
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const selectedStudentsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.student-card-id',
    field: 'id',
    slotCellName: 'id-number',
    sortable: true
  },
  {
    title: 'common.table.student-name-surname',
    field: 'full_name',
    slotCellName: 'full_name',
    sortable: true
  }
]
