import type { StoreType } from '../types'
import { getServiceEsignsResource } from '@/shared/api'
import { ServiceEsignResourceGetRequest } from '@/shared/swagger'

export default async function fetchServiceEsignResource (this: StoreType, body: ServiceEsignResourceGetRequest): Promise<void> {
  const { ok, data } = await getServiceEsignsResource(body)

  if (ok) {
    this.setServiceEsignResource(data)
  }
}
