import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.course-code',
    field: 'code',
    headerClass: 'w-150px',
    slotCellName: 'courseCode',
    cellClass: 'text-center',
    sortable: true
  },
  {
    title: 'common.table.course-title',
    field: 'courseTitle'
  },
  {
    title: 'common.table.department',
    field: 'departmentTitle'
  },
  {
    title: 'common.table.details',
    field: 'details',
    subHeaders: [
      {
        title: 'common.table.theory',
        field: 'theory',
        slotCellName: 'details',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'common.table.practice',
        field: 'practice',
        slotCellName: 'details',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'common.table.labs',
        field: 'lab',
        slotCellName: 'details',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'common.table.credits',
        field: 'credits',
        slotCellName: 'details',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'common.table.ects',
        field: 'ects',
        slotCellName: 'details',
        cellClass: 'text-center',
        sortable: true
      }
    ]
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]
