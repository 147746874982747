import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileEducationPostRequest } from '@shared/swagger'
import { postProfileEducation } from '@shared/api'

import type { StoreType } from '../types'

export default async function addEducation (this: StoreType, values: ProfileEducationPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileEducation(values)

  if (ok) await this.fetchEducations()

  return {
    status: ok,
    message
  }
}
