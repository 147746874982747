import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest
} from '@/shared/swagger'
import { ServiceAdvisorAssignmentStudentsGetRequest } from '@/shared/swagger/entities/AdvisoryAssignment/Request'
import { ServiceAdvisorAssignmentStudentsGetResponse } from '@/shared/swagger/entities/AdvisoryAssignment/Response'
import type { AxiosResponse } from '@shared/types'

export default async function getServiceAdvisorAssignmentStudents (params: ServiceAdvisorAssignmentStudentsGetRequest): Promise<AxiosResponse<ServiceAdvisorAssignmentStudentsGetResponse>> {
  const { post } = useRequests()

  const response = post<UnknownRequest, ServiceAdvisorAssignmentStudentsGetResponse>({
    url: 'service/manage/advisors/students',
    body: { ...params },
    loader: 'get-service/manage/advisors/students'
  })

  return response
}
