<template>
  <div v-if="qrData">
    <div class="text-center">
      <p class="fs-3 fw-bold m-0">
        {{ qrData.data.course.code }}. {{ qrData.data.course.title }} ({{ qrData.data.course.credits }} {{ $t('common.table.credits') }})
      </p>
      <p class="m-0 mb-1">
        {{ qrData.data.course.department }}
      </p>
      <p class="m-0 fw-semibold mt-5">
        <span>
          {{ qrData.data.session.room }} ({{ $t('common.table.room') }})
        </span>
        <br>
        <span>
          {{ dateFormatter(qrData.data.session.date, dateOptions) }} {{ dateFormatter(qrData.data.session.time, timeOptions) }}
        </span>
      </p>
    </div>
  </div>

  <div class="d-flex actions-fixed gap-3">
    <pms-button
      :text="'common.cancel'"
      :color="'secondary'"
      class="w-50"
      @click="cancelAttendance()"
    />
    <pms-button
      class="w-50"
      :text="'domains.student.attendance.actions.attendance-action'"
      @click="acceptAttendance()"
    />
  </div>
</template>
<script setup lang="ts">
import { dateFormatter } from '@shared/utils'
import { useAttendance } from '../composable'
const { qrData, cancelAttendance, acceptAttendance } = useAttendance()

const dateOptions = { inputFormat: 'YYYY-MM-DD', outputFormat: 'DD.MM.YYYY' }
const timeOptions = { inputFormat: 'HH:mm:ss', outputFormat: 'HH:mm' }
</script>

<style scoped lang="scss">
.actions-fixed {
  width: 90%;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
}
</style>
