import { AcademicCoursesHistoryResponse } from '@shared/swagger'
import { GradesDetailsGraph, Series } from '../types'

const formatGradesForGraphs = (data: AcademicCoursesHistoryResponse['grades']): GradesDetailsGraph => {
  const series: Series = {
    name: 'Grades',
    data: []
  }

  const letters: Array<string> = []
  const defaultLetters = ['A', 'A-', 'B', 'B+', 'B-', 'C', 'C+', 'C-', 'D', 'D+', 'F', 'FX', 'IP', 'NP', 'P']

  for (const letter of defaultLetters) {
    const item = data.find((item) => item.letter === letter)
    if (item) {
      letters.push(item.letter)
      series.data.push(item.students_count)
    } else {
      letters.push(letter)
      series.data.push(0)
    }
  }

  return {
    letters,
    series
  }
}

export default formatGradesForGraphs
