import { useRequests } from '@/shared/composable'

import type {
  AcademicCoursesBellCurvePutRequest,
  UnknownResponse
} from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function putAcademicCoursesBellCurve (params: AcademicCoursesBellCurvePutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicCoursesBellCurvePutRequest, UnknownResponse>({
    url: 'academic/courses/bell-curve',
    body: params,
    loader: 'academic/courses/bell-curve'
  })

  return response
}
