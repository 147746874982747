<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        :no-mobile="true"
        @launch="launch"
      >
        <template #toolbar>
          <div class="d-flex align-items-center gap-2">
            <pms-driver-button />
            <pms-feedback-modal :options="[]" />
          </div>
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <pms-row>
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'card'"
        :filter="true"
      />
      <template v-else>
        <pms-grid
          :col="12"
          class="mb-5"
        >
          <search-form id="message-board-search-form" />
        </pms-grid>
        <pms-grid
          :col="12"
          :order-mb="1"
          class="m-md-0"
        >
          <new-message-form />
          <posts-list />
          <!-- <post-list-v2 /> -->
        </pms-grid>
      </template>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useTour, useDriver } from '@shared/composable'

import { header, quickTour, tour } from './values'

import NewMessageForm from './ui/NewMessageForm.vue'
import PostsList from './ui/PostsList.vue'
import SearchForm from './ui/SearchForm.vue'

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('administrative/message-board')

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTour())
})
</script>
