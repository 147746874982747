import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  AcademicManageSyllabusAssessmentsPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusAssessments (id: number, body: AcademicManageSyllabusAssessmentsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusAssessmentsPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/assessments/${id}`,
    body,
    loader: 'academic/manage/syllabus/assessments'
  })
  return response
}
