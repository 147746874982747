import type { StoreType } from '../types'
import { getScheduleManagementSingleSectionPrograms } from '@shared/api'

export default async function fetchSingleSectionPrograms (this: StoreType, section: string): Promise<void> {
  const { data, ok } = await getScheduleManagementSingleSectionPrograms(section)

  if (ok) {
    this.setSingleSectionPrograms(data)
  }
}
