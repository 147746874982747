
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useAdvisorAssignment = defineStore<Name, State, Getters, Actions>('Service.useAdvisorAssignment', {
  actions: {
    ...actions
  },

  getters: {
    //
  },

  state () {
    return {
      students: null,
      currentYearTerm: { id: 0, year: 0, term: 0 }
    }
  }
})
