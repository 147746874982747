import { SelectField } from '@shared/types'
import { AcademicSingleCourseAttendancesResourcesResponse } from '@shared/swagger'

export function buildingsToOptions (buildings: AcademicSingleCourseAttendancesResourcesResponse['buildings'] | undefined): SelectField['options'] {
  if (buildings) {
    return buildings.map((building) => ({
      label: building.title,
      value: building.id
    })) as unknown as SelectField['options']
  } else {
    return []
  }
}
