import { createPage } from '@shared-utils/storybook'
import { useStore } from './../store'

import CurriculaPage from './../CurriculaPage.vue'

const Template = createPage({
  layout: 'default',
  component: CurriculaPage,
  setup () {
    const store = useStore()

    store.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Curricula',
  component: CurriculaPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
