import type { StoreType } from '../types'
import type { ResourceRequest } from '@shared/swagger'

import { getAcademicManageCurriculumResource } from '@shared/api'

export default async function fetchAcademicManageCurriculumResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getAcademicManageCurriculumResource(body)

  if (ok) {
    this.setAcademicManageCurriculumResource(data)
  }
}
