/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue'
import { ServiceManageLanguagesStudentsGetResponse, ServiceManageLanguagesApplicantsGetResponse } from '@/shared/swagger'

export interface ModalTableData{
    id: number,
    full_name: string
}
export const view = ref<'applicant' | 'student'>('applicant')
export const selectedStudents = ref<ServiceManageLanguagesStudentsGetResponse['students']>([])
export const selectedDatasForModal = ref<any>([])
export const selectedApplicants = ref<ServiceManageLanguagesApplicantsGetResponse['applicants']>([])
export const student_language_code = ([] as any)
export const applicant_language_code = ([] as any)
export const applicantsSearchValues = ref({
  iin: '',
  language_code: '',
  program_code: '',
  entrance_year: new Date().getFullYear(),
  name: '',
  surname: '',
  no_level: undefined
})
export const studentsSearchValues = ref({
  id: '',
  language_code: '',
  program_code: '',
  entrance_year: new Date().getFullYear(),
  name: '',
  surname: '',
  no_level: undefined
})
