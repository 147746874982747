import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicInformationSchedulesResourcesRequest,
  AcademicInformationSchedulesResourcesResponse
} from '@shared/swagger'

export default async function getAcademicInformationResource (params: AcademicInformationSchedulesResourcesRequest): Promise<AxiosResponse<AcademicInformationSchedulesResourcesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationSchedulesResourcesResponse>({
    url: 'academic/information/schedules/resources',
    body: { params: params },
    loader: 'academic/information/schedules/resources'
  })

  return response
}
