<template>
  <div class="d-flex flex-column">
    <h1 class="text-dark fs-2 fw-bold mb-4">
      {{ $t('domains.academic.manage.curricula.select-year') }}
    </h1>
    <p class="mb-6 fs-5">
      {{ $t('domains.academic.manage.curricula.select-year-description') }}
    </p>

    <div
      id="select-year"
      class="d-flex"
    >
      <select
        v-model="selectedYear"
        class="form-select form-select-solid form-select-sm w-200px"
        @change="setSelectYear($event)"
      >
        <option
          v-for="year in years"
          :key="year"
          :value="year"
        >
          {{ year }}
        </option>
      </select>
      <button
        class="btn btn-primary btn-sm ms-2"
        @click="next"
      >
        {{ $t('common.next') }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useResource } from '@shared/stores'
import { useDriver, useToggler } from '@shared/composable'

import { useStore } from '../store'
import { yearTermsToYears } from '../utils'
import { yearStepQuickTourSteps } from '../values'

const { toggle } = useToggler()
const { setSteps: setQuickTourSteps } = useDriver()

const store = useStore()
const resource = useResource()
const years = computed(() => yearTermsToYears(resource.getResourceYearTerms))

const next = () => {
  store.setCurrentStep('department')
  store.fetchDepartments(store.getYear)
  toggle()
}

const selectedYear = ref(years.value[0])

const setSelectYear = ($event) => {
  store.setYear(selectedYear.value)
  store.fetchNavigation($event.target.value)
}

watch(() => years.value, () => {
  selectedYear.value = years.value[0]
})

onMounted(async () => {
  setQuickTourSteps(await yearStepQuickTourSteps())
})
</script>
