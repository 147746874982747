<template>
  <pms-card :header="false">
    <ProgramStep v-if="receivedRequestStore.step === 'department'" />
    <CoursesStep v-if="receivedRequestStore.step === 'courses'" />
    <SingleCourseStep v-if="receivedRequestStore.step === 'requests'" />
  </pms-card>
  <pms-teleport-modal
    :name="'received-requests-modal'"
    :teleport-name="'received-requests-content'"
    :title="$t(`${modifier?.translate?.title || 'title'}`)"
    :description="$t(`${modifier?.translate?.description || 'description'}`)"
    :keyboard="false"
    :backdrop="false"
    :size="'xl'"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script setup lang="ts">
import { useTeleportModal } from '@shared/composable'
import ProgramStep from './ui/ProgramStep.vue'
import CoursesStep from './ui/CoursesStep.vue'
import SingleCourseStep from './ui/SingleCourseStep.vue'
import { onBeforeUnmount } from 'vue'
import { useReceivedRequest } from '@domains/SectionManagement/features/ReceivedRequests/store'

const { modifier } = useTeleportModal()
const receivedRequestStore = useReceivedRequest()

onBeforeUnmount(() => {
  receivedRequestStore.$reset()
})
</script>
