import { AttendanceWidget, SeriesItem } from '../types/attendance'
import { DashboardWidgetsResponse } from '@shared/swagger'

const formatAttendance = (data: DashboardWidgetsResponse['attendances']['data']): Array<AttendanceWidget> => {
  const formattedAttendances: AttendanceWidget[] = []

  data.sort((a, b) => a.week_day - b.week_day)
  data.forEach((item) => {
    const xaxis:string[] = []
    const attended: SeriesItem = { name: 'Attended', data: [] }
    const absent: SeriesItem = { name: 'Absent', data: [] }
    const permitted: SeriesItem = { name: 'Permitted', data: [] }

    item.attendance_chart.forEach((chart) => {
      xaxis.push(chart.course_date.split(' ')[0])
      attended.data.push(chart.attended)
      absent.data.push(chart.absent)
      permitted.data.push(chart.permitted)
    })

    const formattedAttendance: AttendanceWidget = {
      id: item.title + item.week_day + item.start_time,
      title: item.title,
      code: item.code,
      weekDay: item.week_day,
      startTime: item.start_time,
      series: [
        attended,
        absent,
        permitted
      ],
      xaxis: xaxis
    }

    formattedAttendances.push(formattedAttendance)
  })
  return formattedAttendances
}

export default formatAttendance
