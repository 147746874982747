import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  console.log('handleAuthRoute')

  next()
}

const mainPage: RouteRecordWithLayout = {
  layout: 'student-mobile',
  path: '/student/dashboard',
  children: [
    {
      path: '',
      name: 'StudentDashboardPage',
      component: () => import('./DashboardPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
}

export default mainPage
