<template>
  <div
    v-if="reports.length>0"
    class="card"
  >
    <div class="card-body">
      <pms-smart-table
        :headers="table_headers"
        :items="reports"
        :options="{
          wrapper: {
            responsive: true
          },
          table: {
            class: `table-bordered ${theme === 'dark' ?'':'table-border-blue'}`
          },
          headers: {
            columnStyle: theme==='dark' ? '':'border-color: #73b5d9 !important',
          },
          items: {
            style: 'border-bottom-width: 1px;'
          }
        }"
      >
        <template #number="{idx}">
          {{ idx + 1 }}
        </template>
        <template #title="{item}">
          <router-link
            :to="{name:'AcademicReportViewPage',params:{report_id:item.id}}"
            @click="resetReportTable"
          >
            {{ item.title }}
          </router-link>
        </template>
      </pms-smart-table>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { table_headers } from './values'
import { useAcademicReports } from '../../store'
import { useThemeStore } from '@/shared/stores/pattern'
const { reports, report_data } = storeToRefs(useAcademicReports())
function resetReportTable () {
  report_data.value = []
}
const { theme } = storeToRefs(useThemeStore())

</script>
