
import findTrackById from '@/shared/api/AcademicCurricula/findTrackById'
import type { StoreType } from '../types'
import type { AcademicManageCurriculumTrackRequest } from '@/shared/swagger'

export default async function findTrack (this: StoreType, params: AcademicManageCurriculumTrackRequest): Promise<void> {
  const { data, ok } = await findTrackById({ track: params.track })

  if (ok) {
    this.setTrack(data)
  }
}
