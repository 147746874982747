import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesPostRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { postAcademicCoursesAttendances } from '@shared/api'

export default async function postAttendance (this: StoreType, params: AcademicSingleCourseAttendancesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postAcademicCoursesAttendances(params)

  return {
    status: ok,
    message
  }
}
