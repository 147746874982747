<template>
  <Teleport to="#post-content-modal-content">
    <pms-smart-form
      :fields="formFields"
      :options="formOptions"
      :custom-validator="customValidatorForContent"
      @handle-change="onChange"
      @handle-submit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormGroup } from '@shared/types'
import { useResource } from '@shared/stores'
import { useAlert, useTeleportModal, useLoader } from '@shared/composable'
import { objectDeepCopy } from '@/shared/utils'
import type { KnowledgeManagePostsSingleGetResponse } from '@/shared/swagger'

import { useKnowledgeManagePost } from '../../../store'
import { knowledgeManageResourcesTypesToOptions, customValidatorForContent } from '../utils'
import { formOptions } from '../values'

const { t } = useI18n()
const { isActive } = useLoader()
const { error, success } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const loader = isActive('knowledge/manage/posts/post-contents')

const resource = useResource()
const store = useKnowledgeManagePost()

const typeResources = computed(() => resource.getKnowledgeManageResourceTypes)

const defaultTempValues: KnowledgeManagePostsSingleGetResponse['contents'][0] = {
  id: -1,
  uuid: '',
  type_id: -1,
  type_uuid: '',
  type_title: '',
  image: '',
  video: '',
  video_type: '',
  content: '',
  active: false,
  visible: 0,
  version: '',
  alert_type: '',
  alert_text: ''
}

const tempValues = ref<KnowledgeManagePostsSingleGetResponse['contents'][0]>(objectDeepCopy(defaultTempValues))

const onChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
  if (name === 'type_id') {
    switch (newValue) {
      case '1':
        tempValues.value.type_title = 'FAQ'
        break
      case '2':
        tempValues.value.type_title = 'Guidlines'
        break
      case '3':
        tempValues.value.type_title = 'Tutorial'
        break
    }
  }
}

const formFields = computed<Array<FormGroup>>(() => {
  let result: Array<FormGroup> = [
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.version')}`
      },
      fields: [
        {
          name: 'version',
          type: 'text',
          value: tempValues.value.version,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.type')}`
      },
      fields: [
        {
          type: 'select',
          name: 'type_id',
          options: knowledgeManageResourcesTypesToOptions(typeResources.value),
          value: Number(tempValues.value.type_id) || null,
          validator: {
            schema: 'yup',
            rules: [
              'number',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.alert-type')}`
      },
      fields: [
        {
          type: 'select',
          name: 'alert_type',
          options: [
            {
              label: 'Success',
              value: 'Success'
            },
            {
              label: 'Warning',
              value: 'Warning'
            },
            {
              label: 'Error',
              value: 'Error'
            }
          ],
          value: tempValues.value.alert_type || null,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.alert-text')}`
      },
      fields: [
        {
          name: 'alert_text',
          type: 'text',
          value: tempValues.value.alert_text,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5 mt-5',
        text: `${t('common.table.active')}`
      },
      fields: [
        {
          group: {
            class: 'mt-5'
          },
          class: 'w-60px h-35px',
          name: 'active',
          type: 'switch',
          value: tempValues.value.active
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5 mt-5',
        text: `${t('common.table.visible')}`
      },
      fields: [
        {
          group: {
            class: 'mt-5'
          },
          class: 'w-60px h-35px',
          name: 'visible',
          type: 'switch',
          value: tempValues.value.visible
        }
      ]
    }
  ]
  if (tempValues.value.type_title) {
    result = [
      ...result,
      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.content')}`
        },
        fields: [
          {
            type: 'text',
            name: 'content',
            value: tempValues.value.content,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      }
    ]
  }
  if (tempValues.value.type_title === 'Guidlines' || tempValues.value.type_title === 'Tutorial') {
    result = [
      ...result,

      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.image')}`
        },
        fields: [
          {
            type: 'text',
            name: 'image',
            value: tempValues.value.image,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      }
    ]
  }
  if (tempValues.value.type_title === 'Tutorial') {
    result = [
      ...result,
      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.video')}`
        },
        fields: [
          {
            type: 'text',
            name: 'video',
            value: tempValues.value.video,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      },
      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.video_type')}`
        },
        fields: [
          {
            type: 'text',
            name: 'video_type',
            value: tempValues.value.video_type,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      }
    ]
  }
  return result
})

const onSubmit = async ({ valid }) => {
  if (valid === false) {
    error({ text: t('common.fields.required.not-filled') })
    return
  }
  if (tempValues.value.id === -1) {
    const { visible, ...postRequest } = tempValues.value
    const { status, message } = await store.createContent(store.postUuid, { ...postRequest, visible: !!visible })

    if (status && store.newContent) {
      success({ text: message, isToast: true })
      store.postContents.push(store.newContent)
      closeModal()
    } else {
      error({ text: message })
    }
  } else {
    if (typeof modifier.value?.data?.id === 'number') {
      const { type_id, visible, ...request } = tempValues.value
      const { status, message } = await store.editContent(store.postUuid, [{ ...request, type_id: String(type_id), visible: !!visible }])
      if (status) {
        success({ text: message, isToast: true })
      } else {
        error({ text: message })
      }
      await store.fetchPost(store.postUuid)
      closeModal()
    }
  }
}

const closeModal = () => {
  close({ name: 'post-content-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && store.postContents) {
      if (newValue.data.id !== -1 && typeof newValue.data?.id === 'number') {
        const exactContent = store.postContents[newValue.data.id]
        tempValues.value = objectDeepCopy(exactContent || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
