import type { StatusMessageResponse } from '@shared/types'
import type{ ScheduleManagementSingleSectionProgramsPostRequest } from '@shared/swagger'
import { postScheduleManagementSingleSectionPrograms } from '@shared/api'

import type { StoreType } from '../types'

export default async function addProgram (this: StoreType, section: string, values: ScheduleManagementSingleSectionProgramsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postScheduleManagementSingleSectionPrograms(section, values)

  if (ok) await this.fetchSingleSectionPrograms(section)

  return {
    status: ok,
    message
  }
}
