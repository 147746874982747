type columnContent = {
  title: string,
  module: string
}

export const descriptionColumn : columnContent[] = [
  {
    title: 'domains.academic.manage.syllabus.content.course-description',
    module: 'course-description'
  },
  {
    title: 'domains.academic.manage.syllabus.content.instructors',
    module: 'instructors'
  }
]

export const contentsColumn : columnContent[] = [
  {
    title: 'domains.academic.manage.syllabus.content.academic-skills',
    module: 'academic-skills'
  },
  {
    title: 'domains.academic.manage.syllabus.content.subject-skills',
    module: 'subject-skills'
  },
  {
    title: 'domains.academic.manage.syllabus.content.weekly-plans',
    module: 'weekly-plans'
  },
  {
    title: 'domains.academic.manage.syllabus.content.learning-outcomes',
    module: 'learning-outcomes'
  }
]

export const othersColumn : columnContent[] = [
  {
    title: 'domains.academic.manage.syllabus.content.teaching-methods',
    module: 'teaching-methods'
  },
  {
    title: 'domains.academic.manage.syllabus.content.readings',
    module: 'readings'
  },
  {
    title: 'domains.academic.manage.syllabus.content.assessments',
    module: 'assessments'
  },
  {
    title: 'domains.academic.manage.syllabus.content.work-load',
    module: 'work-load'
  }
]

export const coursePolicyColumn : columnContent = {
  title: 'domains.academic.manage.syllabus.content.course-policy',
  module: 'course-policy'
}
