import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumCoreGetRequest, AcademicManageCurriculumCoreGetResponse } from '@shared/swagger'

export default async function fetchCurriculumCore (params: AcademicManageCurriculumCoreGetRequest): Promise<AxiosResponse<AcademicManageCurriculumCoreGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumCoreGetResponse>({
    url: `academic/manage/curriculum/core/${params.id}`,
    body: {},
    loader: 'api-fetchCurriculumCore'
  })

  return response
}
