import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesByCoursePutRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { putAcademicSingleCourseAttendancesByCourse } from '@shared/api'

export default async function (this: StoreType, params: Array<AcademicSingleCourseAttendancesByCoursePutRequest>): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicSingleCourseAttendancesByCourse(params)

  return {
    status: ok,
    message
  }
}
