import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileBiographyGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileBiography (): Promise<AxiosResponse<ProfileBiographyGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileBiographyGetResponse>({
    url: 'profile/biography',
    body: {},
    loader: 'profile/biography'
  })
  return response
}
