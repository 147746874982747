import html2pdf from 'html2pdf.js'

const generateHtml = (element: HTMLElement) => {
  return `
    <head>
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Inter:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
      <style>
        .svg-icon, .svg-icon-2, .svg-icon-dark {
            display: none;
        }
        .fs-2 {
          margin: 15px 0 10px 0;
          font-size: 1.3rem !important;
        }
        .fw-bold {
          font-weight: 700 !important;
        }
        .card {
          border: none;
        }
        table td, .table th {
          border: 0.5px solid #000 !important;
        }
      </style>
    </head>
    <body>
      <div class="container">
        ${element.innerHTML}
      </div>
    </body>
  `
}

export const downloadSyllabus = (element: HTMLElement) => {
  const options = {
    filename: 'syllabus.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  }

  html2pdf().set(options).from(generateHtml(element)).save()
}
