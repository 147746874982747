<template>
  <div
    class="d-flex justify-content-end"
  >
    <div
      class="d-flex align-items-center overflow-auto"
    >
      <div
        id="syllabus-language-selector"
        class="d-flex align-items-center overflow-auto"
      >
        <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
          {{ getMessage('selector') }}:
        </span>
        <select
          v-model="currentLanguage"
          class="form-select w-150px"
        >
          <option
            v-for="(language, index) in locales"
            :key="index"
            :value="language.short"
          >
            {{ language.locale }}
          </option>
        </select>
      </div>
      <div class="bullet bg-secondary h-35px w-1px mx-2" />
      <div class="p-2">
        <print-syllabus />
      </div>
      <div class="p-2">
        <download-syllabus />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import { useLocale } from '@shared/composable'
import { getMessage } from '../utils'
import { currentLanguage } from '../values'

import PrintSyllabus from './PrintSyllabus.vue'
import DownloadSyllabus from './DownloadSyllabus.vue'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'

const { locales } = useLocale()

const route = useRoute()
const store = useSingleCourse()

watch(() => currentLanguage.value, async (newValue) => {
  const { code, year } = route.query
  await store.fetchSyllabus({ course_code: code as string, year: Number(year), language: newValue })
})
</script>

<style scoped lang="scss">

</style>
