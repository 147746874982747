import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ScheduleManagement1Section1ProgramPutRequest } from '@shared/swagger'

export default async function putScheduleManagement1Section1Program (section: string, program: string, values: ScheduleManagement1Section1ProgramPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ScheduleManagement1Section1ProgramPutRequest, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}`,
    body: values,
    loader: 'academic/manage/schedules/sections-single/programs-single-put'
  })

  return response
}
