<template>
  <pms-card
    :header="false"
  >
    <pms-loader v-if="loader" />

    <grade-list v-else />
  </pms-card>
  <!--  -->
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { useLoader } from '@shared/composable'

import { useSingleSection } from '../../store'
import { RouteParams } from '../../types'
import GradeList from './ui/GradeList.vue'
import { clearAllGradesValues } from './values'

const { isActiveOneOf } = useLoader()
const route = useRoute()
const store = useSingleSection()
const loader = isActiveOneOf(['academic/courses/grades'])

onMounted(async () => {
  const { year, term, code: course_code, section } = route.query as unknown as RouteParams

  await store.fetchGrades({
    course_code,
    year,
    term,
    section
  })
})

onBeforeUnmount(() => clearAllGradesValues())
</script>
<style lang="scss" scoped>
.student-tooltip {
  position: absolute;
  z-index: 999;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 200px;
  pointer-events: none;
  .student-image {
    width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}
</style>
