import { sentRequest } from './../../values/refs'
import { destroySectionManagementSentRequest } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteSentRequest (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroySectionManagementSentRequest(id)

  if (ok) await this.fetchSentRequests({ ...sentRequest.value, status: this.status === 0 ? undefined : this.status })

  return {
    status: ok,
    message
  }
}
