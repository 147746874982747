<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        :title="$t('knowledge.manage.categories.title')"
        :description="$t('knowledge.manage.categories.description')"
        @launch="launch"
      >
        <template #toolbar>
          <pms-button
            class="w-100"
            size="sm"
            @click="setIdAction(-1)"
          >
            <span class="fw-bold fs-6">
              {{ $t('common.new') }}
            </span>
          </pms-button>
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <pms-row v-if="loader">
      <pms-page-shimmer
        :shimmer="'table'"
        :full-width="true"
      />
    </pms-row>
    <pms-row v-else>
      <pms-grid :col="12">
        <KnowledgeManageCategoriesTable :categories="categories" />
      </pms-grid>
    </pms-row>
    <pms-teleport-modal
      :name="'knowledge-categories-modal'"
      :teleport-name="'knowledge-categories-content'"
      :title="$t(`knowledge.manage.categories.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`knowledge.manage.categories.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
    <KnowledgeManageCategoriesForm v-if="modifier && modifier.name === 'categories'" />
  </pms-container>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import { useLoader, useToolbar, useTour, useTeleportModal } from '@shared/composable'

import { useKnowledgeCategories } from './store'
import { tour } from './values'

import KnowledgeManageCategoriesTable from './ui/KnowledgeManageCategoriesTable.vue'
import KnowledgeManageCategoriesForm from './ui/KnowledgeManageCategoriesForm.vue'

const { isActiveOneOf } = useLoader()
const { toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const { modifier, setModifier, open } = useTeleportModal()

const store = useKnowledgeCategories()

const loader = isActiveOneOf(['knowledge/manage/resources', 'knowledge/manage/categories'])

const categories = computed(() => store.getKnowledgeCategories)

const setIdAction = (id: number) => {
  setModifier('categories',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'categories-new-title' : 'categories-edit-title',
      description: id === -1 ? 'categories-new-description' : 'categories-edit-description'
    }
  )
  open({ name: 'knowledge-categories-modal' })
}

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
