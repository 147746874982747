import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'
import { useMyStudents } from '@domains/AcademicAdvisory/store'
import { resetMyStudentsTableFields, resetThesisStudentsSortField } from './values'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useMyStudents()
  const fromName = String(from.name)

  if (!fromName.includes('SingleStudent') || from.query?.previous_page !== 'AdvisoryPage') {
    store.$reset()
    pagination.$reset()
    resetMyStudentsTableFields()
    resetThesisStudentsSortField()
  }
  store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/academic/students/my-advisory',
  children: [
    {
      path: '',
      name: 'AdvisoryPage',
      component: () => import('./AdvisoryPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
