<template>
  <div class="w-100 d-flex justify-content-center">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onChange"
    >
      <template #formControls />
    </pms-smart-form>
  </div>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'

import { FormField } from '@/shared/types'
import { ServiceManageAnnouncementsGetResponse } from '@/shared/swagger'
import { useAnnouncements } from '../store'
import { useAlert } from '@/shared/composable'

const store = useAnnouncements()
const { success, error } = useAlert()
const props = defineProps({
  announcement: {
    type: Object as PropType<ServiceManageAnnouncementsGetResponse[0]>,
    required: true
  }
})
const formFields = computed(():Array<FormField> => [
  {
    name: 'status',
    type: 'switch',
    value: props.announcement.status
  }
])

const onChange = async ({ newValue }) => {
  const { message, status } = await store.publishAnnouncement(props.announcement.id, { status: newValue })
  status

    ? success({ text: message, isToast: true })

    : error({ text: message, isToast: true })
}
</script>
