<template>
  <div class="d-flex align-items-center gap-2">
    <input
      v-if="!isAbsent(studentId, assessmentId)"
      type="number"
      class="form-control p-2 w-50px fs-4 text-center"
      :disabled="isAbsent(studentId, assessmentId)"
      :value="getAddedGrade(studentId)"
      @input="enforceMinMax($event.target)"
      @change="onAddGrade(studentId ,assessmentId, $event.target?.value)"
    >
    <span
      v-else
      class="text-danger me-2"
    >
      {{ $t('domains.academic.single-course.grade.absent') }}
    </span>
    <pms-dropdown
      :btn-text="''"
      :variant="'tonal'"
      :btn-class="'p-2 py-1'"
    >
      <div>
        <pms-button
          :text="
            isAbsent(studentId, assessmentId)
              ? 'common.undo'
              : `domains.academic.single-course.grade.absent`"
          :color="
            isAbsent(studentId, assessmentId)
              ? 'primary'
              : 'danger'"
          :size="'sm'"
          type="reset"
          @click="
            isAbsent(studentId, assessmentId)
              ? onAddGrade(studentId, assessmentId, 'undo-absent')
              : onAddGrade(studentId, assessmentId, 'absent')
          "
        />
      </div>
    </pms-dropdown>
  </div>
</template>
<script setup lang="ts">

import { getAddedGrade, isAbsent } from '../utils'
import { PropType } from 'vue'
import { addedGrades, isEdited } from '../values'

defineProps({
  assessmentId: {
    type: Number as PropType<number>,
    required: true
  },
  studentId: {
    type: String as PropType<string>,
    required: true
  }
})

const onAddGrade = (studentId: string, assessmentId: number, grade: string) => {
  isEdited.value = true

  if (addedGrades.value.assessment_id === assessmentId) {
    addedGrades.value = {
      ...addedGrades.value,
      grades: addedGrades.value.grades.map((g) => {
        if (g.student_id === studentId) {
          if (grade === 'absent') g.absent = true
          else if (grade === 'undo-absent') g.absent = false
          else g.grade = parseInt(grade)
        }
        return g
      })
    }
  }
}

const enforceMinMax = (el) => {
  if (el.value !== '') {
    if (parseInt(el.value) < 0) {
      el.value = 0
    }
    if (parseInt(el.value) > 100) {
      el.value = 100
    }

    el.value = parseInt(el.value)
  }
}
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
