import { defineStore } from 'pinia'
import type { Name, Actions, State, Getters } from './types'
import * as actions from './actions'

export const useMessageBroadcastCompose = defineStore<Name, State, Getters, Actions>('Service.useMessageBroadcastCompose', {
  state: ():State => {
    return {
      selectedStudents: [],
      selectedCourses: [],
      selectedPrograms: [],
      selectedEmployee: [],
      employee_by_department: []
    }
  },
  actions: {
    ...actions
  }
})
