import { getProfileChildren } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchChildren (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileChildren()

  if (ok) {
    this.setChildren(data)
  }
}
