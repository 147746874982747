import type { StoreType } from '../types'
import { RequestType } from '../../types'

function isRequestType (value: unknown): value is RequestType['value'] | null {
  return value === 'program' || value === 'suggested' || value === 'search' || value === null
}

export default function setRequestType (this: StoreType, selectType: RequestType['value'] | null): void {
  if (!isRequestType(selectType)) return
  this.requestType = selectType
}
