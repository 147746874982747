import { formatTranscriptForStore } from './../../utils'
import type { State } from '../types'
import { AcademicSingleStudentTranscriptGetResponse } from '@shared/swagger'

export default function setTranscript (this: State, transcript: AcademicSingleStudentTranscriptGetResponse | null): void {
  if (transcript) {
    const formattedGraphData = formatTranscriptForStore(transcript)
    this.transcript = formattedGraphData
  } else {
    this.transcript = null
  }
}
