import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { AdministrativeGateEntrySendMailRequest, AdministrativeGateEntrySendMailResponse } from '@shared/swagger'

export default async function postAdministrativeGateEntrySendMail (body: AdministrativeGateEntrySendMailRequest): Promise<AxiosResponse<AdministrativeGateEntrySendMailResponse>> {
  const { post } = useRequests()

  const response = post<AdministrativeGateEntrySendMailRequest, AdministrativeGateEntrySendMailResponse>({
    url: 'administrative/gate-entry/send-email',
    body,
    loader: 'administrative/gate-entry/send-email'
  })
  return response
}
