import type { StoreType } from '../types'
import type { SectionManagementCoursesStatisticsGetRequest } from '@/shared/swagger'
import { getSectionManagementCoursesStatistics } from '@shared/api'

export default async function fetchCourseStatistics (this: StoreType, params: SectionManagementCoursesStatisticsGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementCoursesStatistics(params)

  if (ok) {
    this.setCourseStatistics(data)
  } else {
    this.setCourseStatistics([])
  }
}
