<template>
  <div class="d-flex flex-column mt-4 mt-md-0 flex-md-row align-items-md-center gap-1">
    <ToolbarYearTerms
      v-if="myYearTerms.length > 0"
      id="year-term-selector"
    />
    <div class="bullet bg-secondary h-0px h-md-35px w-1px mx-2" />
    <div class="d-flex gap-3">
      <ToolbarView id="toggle-view" />
      <pms-driver-button />
      <pms-feedback-modal
        :options="feedback"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ToolbarYearTerms from './ToolbarYearTerms.vue'
import ToolbarView from './ToolbarView.vue'
import { feedback, quickMyCoursesSetTourSteps } from './../values'
import { useDriver } from '@shared/composable'
import { computed, onMounted } from 'vue'
import { useResource } from '@/shared/stores'

const resource = useResource()
const myYearTerms = computed(() => resource.getAcademicCoursesResourceMyYearTerms)
const { setSteps: setQuickTourSteps } = useDriver()

onMounted(async () => {
  setQuickTourSteps(await quickMyCoursesSetTourSteps())
})
</script>
