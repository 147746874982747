import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileEducationBooksGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationBooks (): Promise<AxiosResponse<ProfileEducationBooksGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileEducationBooksGetResponse>({
    url: 'profile/education/books',
    body: {},
    loader: 'profile/education/books'
  })
  return response
}
