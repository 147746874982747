<template>
  <vue-resizable
    id="courses-widget"
    class="resizable-widget card pb-2"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-height="200"
    min-width="300"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column col-8">
        <span
          v-test-id="'course-title'"
          class="card-label fw-bolder text-dark fs-3"
        >
          {{ $t('domains.dashboard.widgets.courses.title') }}
        </span>
        <span
          v-test-id="'course-description'"
          class="text-muted mt-1 fw-bold fs-7"
        >
          {{ $t('domains.dashboard.widgets.courses.description') }}
        </span>
      </h3>
      <div class="card-toolbar">
        <a
          v-test-id="'all-courses-redirect'"
          class="btn btn-sm btn-light-primary"
          @click="goto(redirectMyCourses)"
        >
          {{ $t('domains.dashboard.widgets.courses.visit-link') }}
        </a>
      </div>
    </div>
    <div
      :class="`card-body pt-2 overflow-auto`"
      :style="{ height: `calc(100% - 105px)`}"
    >
      <div
        v-for="(course, index) in courses"
        :key="index"
        v-test-id="'course-item'"
        class="d-flex align-items-sm-center mt-3"
      >
        <div class="symbol symbol-50px me-5">
          <span class="symbol-label bg-primary text-white">
            {{ shortenCourseText(course.title) }}
          </span>
        </div>
        <div class="d-flex flex-row-fluid flex-wrap">
          <div class="flex-grow-1 me-2">
            <a
              v-test-id="'course-link'"
              class="text-dark text-left-important fw-bolder text-hover-primary mb-1 fs-6 btn btn-link p-0"
              @click="goto(redirectMyCourses, { course: course.code })"
            >
              {{ course.code }}.{{ course.section }} - {{ course.title }}
            </a>
            <span class="text-muted fw-bold d-block fs-7">
              {{ typePrinter(course.type) }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="courses.length === 0"
        class="min-h-200px h-100 d-flex justify-content-center align-items-center"
      >
        <div class="text-center">
          <span
            v-test-id="'no-data'"
            class="text-dark fw-bolder fs-4"
          >
            {{ $t('domains.dashboard.widgets.courses.no-courses') }}
          </span>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { deviceType } from '@/shared/utils'
import { onResize, getSavedWidgetSize } from '../utils'

const { t } = useI18n()

const router = useRouter()
const store = useDashboard()
const device = deviceType()

const courses = computed(() => store.getCourses)
const redirectMyCourses = '/academic/courses/my-courses'

const widgetId = 'courses-widget'

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '32%', height: '400px' })

const goto = (path:string, params = {}) => {
  router.push({ path: path, params })
}

const shortenCourseText = (title: string): string => {
  title = title.replace(/[{()}]/g, '')
  return title.split(' ').map(word => word.charAt(0)).join('').toUpperCase()
}

const typePrinter = (type: string): string => {
  return t('domains.dashboard.widgets.courses.type.' + type)
}

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>

<style>
  .text-left-important{
    text-align: left !important;
  }
</style>
