import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSectionProgramHoursGetRequest,
  ScheduleManagementSectionProgramHoursGetResponse
} from '@shared/swagger'

export default async function getScheduleManagementSectionProgramHours (data: ScheduleManagementSectionProgramHoursGetRequest): Promise<AxiosResponse<ScheduleManagementSectionProgramHoursGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSectionProgramHoursGetResponse>({
    url: `academic/manage/schedules/sections/${data.section}/programs/${data.program}/hours`,
    body: {
      params: data
    },
    loader: 'get-academic/manage/schedules/sections-single/programs-single/hours'
  })

  return response
}
