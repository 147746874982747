<template>
  <div class="d-flex justify-content-between mt-6">
    <div
      v-if="currentUser"
      class="d-flex align-items-center"
    >
      <div class="symbol symbol-35px me-3">
        <img
          :src="currentUser.photo"
          :alt="`${currentUser.name} ${currentUser.surname}`"
        >
      </div>
      <div class="d-flex justify-content-start flex-column">
        <p
          class="text-gray-800 fw-bold m-0 fs-6"
        >
          {{ `${currentUser.name} ${currentUser.surname}` }}
        </p>
        <span class="text-gray-500 fw-semibold d-block fs-7 m-0">
          {{ currentUser.id }}
        </span>
      </div>
    </div>
    <div>
      <pms-button
        :variant="'link'"
        class="m-0 p-0 pt-1"
        @click="exit()"
      >
        <pms-kt-icon
          :icon-name="'entrance-left'"
          class="fs-5x m-0"
        />
      </pms-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useStudent } from '@shared/stores'

const user = useStudent()
const currentUser = computed(() => user.currentUser)

const exit = () => {
  user.logout()
}
</script>
