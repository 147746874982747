import type { StoreType } from '../types'
import type { AcademicMySectionsGetResponse } from '@shared/swagger'

import { filterBy } from '../../values'

export default function getMySections (this: StoreType): AcademicMySectionsGetResponse {
  return this.courses.filter((course) => {
    if (filterBy.value === 'lecture') return course.type === 'N'
    if (filterBy.value === 'practice') return course.type === 'P'
    if (filterBy.value === 'laboratory') return course.type === 'L'

    return true
  })
}
