<template>
  <div class="d-flex flex-column gap-5 mt-5">
    <!-- Courses tool -->
    <pms-card :title="t('domains.academic.curricula.add-course.card-title-courses-tool')">
      <pms-smart-form
        class="d-flex align-items-start gap-3"
        :fields="[courseGroupFormFields]"
        @handleChange="onHandleChange"
      >
        <template #formControls />
      </pms-smart-form>
    </pms-card>

    <pms-card
      v-if="courseSettings.courseGroup === SelectTypes.CoursesFromSearch"
      :title="`${t('common.search')}`"
    >
      <SearchInput />
    </pms-card>

    <pms-card
      v-if="courseSettings.courseGroup === SelectTypes.CoursesFromSearch"
      :title="`${t('common.courses')}`"
    >
      <CoursesTable
        :courses="foundCourses"
      />
    </pms-card>

    <pms-card
      v-if="courseSettings.courseGroup === SelectTypes.CoursesOffered"
      :title="`${t('common.courses')}`"
    >
      <CoursesTable
        v-if="coursesOfferedOtherDepartment"
        :courses="coursesOfferedOtherDepartment"
      />
    </pms-card>
  </div>

  <pms-modal
    :name="modalInfo.name"
    :title="modalInfo.title"
    :description="modalInfo.description"
  >
    <AddCourseModalContent />
  </pms-modal>
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useStore } from '../../store'
import { storeToRefs } from 'pinia'
import CoursesTable from './ui/CoursesTable.vue'
import { SelectTypes, modalInfo } from './values'
import SearchInput from './ui/SearchInput.vue'
import { useCourseAdder } from './composable'
import AddCourseModalContent from './ui/AddCourseModalContent.vue'
import { useResource } from '@/shared/stores'
import { useQueryHelper } from './composable/useQueryHelper'
import { useI18n } from 'vue-i18n'

const { courseGroupFormFields, courseSettings } = useCourseAdder()

const store = useStore()
const { t } = useI18n()
const { foundCourses, track, coursesOfferedOtherDepartment } = storeToRefs(store)

const onHandleChange = ({ name, newValue }) => {
  courseSettings.value[name] = newValue
}

const { params } = useQueryHelper()

watch(() => track.value, () => {
  if (track.value) {
    store.fetchCoursesOfferedOtherDepartment({
      program_code: track.value.program.code,
      year: track.value.program.year
    })
  }
})

onMounted(async () => {
  const resource = useResource()

  resource.fetchAcademicManageCurriculumResource({ modules: [] })

  if (params.trackId) {
    store.findTrack({ track: params.trackId })
  }
})
</script>
