import { useRequests } from '@shared/composable'

import type {
  ProfileEducationArticlesGetRequest,
  ProfileEducationArticlesGetResponse,
  UnknownRequest
} from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationArticles (params: ProfileEducationArticlesGetRequest): Promise<{type: string, response: AxiosResponse<ProfileEducationArticlesGetResponse>}> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ProfileEducationArticlesGetResponse>({
    url: 'profile/education/articles',
    body: { params },
    loader: 'profile/education/articles'
  })

  return {
    type: params.type,
    response
  }
}
