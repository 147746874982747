<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { SyllabusFormMode } from './../types'
import { objectDeepCopy } from '@shared/utils'
import { useI18n } from 'vue-i18n'
import { academicSyllabusAssessmentTitleToId, academicSyllabusAssessmentToOptions } from '../utils'
import { useResource } from '@shared/stores'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const resource = useResource()
const store = useStore()
const { t } = useI18n()

const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['assessments'][0] & SyllabusFormMode> = {
  id: -1,
  title: '',
  quantity: 0,
  percentage: 0,
  description: '',
  mode: 'add'
}
const tempValue = ref()

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.assessment.title')}`
    },
    group: {
      class: 'mb-4'
    },
    name: 'assess_key',
    type: 'select',
    options: academicSyllabusAssessmentToOptions(resource.getAcademicManageSyllabusResourceAssessmentTypes),
    value: academicSyllabusAssessmentTitleToId(tempValue.value.title)
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.assesment.description')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'textarea',
    name: 'description',
    value: tempValue.value.description || ''
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.assessment.quantity')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'text',
    name: 'quantity',
    value: tempValue.value.quantity || null,
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        'positive'
      ]
    }
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.assessment.percentage')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'text',
    name: 'percentage',
    value: tempValue.value.percentage || null,
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        'positive'
      ]
    }
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (newValue.data.mode === 'edit') {
        const item = store.getAssessments.find((item) => item.id === newValue.data?.id)
        tempValue.value = { mode: newValue.data.mode, ...(item || defaultValue) }
      } else {
        tempValue.value = objectDeepCopy(defaultValue)
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'edit') {
    const percentage = values.percentage ? Number(values.percentage) : 0
    const quantity = values.quantity ? Number(values.quantity) : 0
    store.editAssessment({
      description: values.description,
      percentage: percentage,
      quantity: quantity,
      assess_type: values.assess_key
    }, tempValue.value.id)
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
  if (tempValue.value.mode === 'add') {
    const percentage = values.percentage ? Number(values.percentage) : 0
    const quantity = values.quantity ? Number(values.quantity) : 0
    store.addAssessment({
      description: values.description,
      percentage: percentage,
      quantity: quantity,
      assess_type: values.assess_key
    })
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
}
</script>
../store../types@/domains/AcademicSingleCourse/features/EditorSyllabus/utils
../store../types
