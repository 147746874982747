import { DashboardWidgetsResponse } from '@shared/swagger'
import { ScheduleType } from '../types'

const formatSchedules = (schedules: DashboardWidgetsResponse['schedules']['data']):ScheduleType => {
  const today = new Date().getDay()

  return schedules.filter((schedule) => schedule.week_day === today)
}

export default formatSchedules
