<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'table'"
    :full-width="true"
  />
  <pms-smart-table
    v-else
    id="table-courses-for-references"
    :headers="headers"
    :items="coreCoursesForReference ? coreCoursesForReference.courses : []"
    :sortable="true"
  >
    <template #code="{item}">
      {{ item.code }}
    </template>
    <template #title="{item}">
      {{ item.title }}
    </template>
  </pms-smart-table>
</template>
<script lang="ts" setup>
import { SmartTableHeader } from '@/shared/types'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { useLoader } from '@/shared/composable'

const store = useStore()
const { coreCoursesForReference } = storeToRefs(store)
const { isActive } = useLoader()
const loader = isActive('get-academic/manage/curriculum/references/courses')

const headers: Array<SmartTableHeader> = [
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    cellClass: 'w-50px'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    cellClass: 'w-50px'
  }
]
</script>
