<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <year-terms id="year-term-selector" />
      <pms-driver-button
        class="me-2 ms-2 mt-2 mt-md-0"
      />
      <pms-feedback-modal
        :options="feedback"
        :button-class="'mt-2 mt-md-0'"
      />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-6"
    >
      <pms-page-shimmer
        :shimmer="'table'"
        :filter="true"
      />
    </pms-row>
    <pms-column v-else>
      <pms-grid
        :col="12"
      >
        <exam-dates-search id="search-block-exams" />
      </pms-grid>
      <pms-grid
        :order-mb="1"
        class="mt-6"
        :col="12"
      >
        <exam-dates-list id="list-block-exams" />
      </pms-grid>
    </pms-column>
  </pms-container>
  <pms-teleport-modal
    :name="'exam-date-modal'"
    :teleport-name="'exam-date-modal-content'"
    :title="$t(`domains.academic.information.exam-date.modal.set-all`)"
    :description="$t('domains.academic.information.exam-date.modal.description')"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useTour, useDriver, useToolbar } from '@shared/composable'
import { header, tour, quickExamSetTourSteps, feedback } from './values'

import YearTerms from './ui/YearTerms.vue'
import ExamDatesSearch from './ui/ExamDatesSearch/ExamDatesSearch.vue'
import ExamDatesList from './ui/ExamDatesList.vue'

const { isActive } = useLoader()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('resources')

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickExamSetTourSteps())
})
</script>
