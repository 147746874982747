import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { useToken } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()

  const year = useToken().getYear()

  await Promise.all([
    resource.fetchResource({ modules: ['year-terms'] }),
    resource.fetchAcademicResource({ modules: ['departments', 'instructors'], year }),
    resource.fetchAcademicInformationResource({ modules: ['application-statuses'] })
  ])
}
