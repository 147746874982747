import type { StoreType } from '../types'
import type { AcademicInformationExamDatesRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { getAcademicInformationExamDates } from '@shared/api'

export default async function fetchExamDates (this: StoreType, values: AcademicInformationExamDatesRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getAcademicInformationExamDates(values)

  if (ok) {
    this.setExamDates(data.filter(d => this.currentSection.section.includes(d.section)))
  }

  return {
    status: ok,
    message
  }
}
