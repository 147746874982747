import { createPage } from '@shared-utils/storybook'
import { usePagination } from '@shared/composable'

import MessageBroadcastPage from '../MessageBroadcastPage.vue'
import { useMessageBroadcast } from './../store'

const Template = createPage({
  layout: 'default',
  component: MessageBroadcastPage,
  setup () {
    const store = useMessageBroadcast()
    const pagination = usePagination()

    store.$reset()
    pagination.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Service/Message Broadcast',
  component: MessageBroadcastPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
