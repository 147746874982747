import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useThemeStore } from '@shared/stores/pattern'
import { useAlert } from '@shared/composable'
import { useUser } from '@/shared/stores'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { setThemeByTime } = useThemeStore()

  setThemeByTime()

  useUser().isAuthenticated
    ? next({ name: 'DashboardPage' })
    : next()
}

const checkValidnessOfToken = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const token = to.params.token as string

  const { error } = useAlert()

  if (token === '' || token === undefined || token === null) {
    error({ text: 'phrases.messages.error-token' })
    next({ name: 'SignInPage' })
  }
  next()
}

const mainPage: RouteRecordWithLayout = {
  layout: 'auth',
  path: '/auth',
  children: [
    {
      path: 'sign-in',
      name: 'SignInPage',
      component: () => import('./SignInPage.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    },
    {
      path: 'forgot-password',
      name: 'ForgotPasswordPage',
      component: () => import('./ForgotPasswordPage.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    },
    {
      path: 'reset-password/:token',
      name: 'ResetPasswordPage',
      component: () => import('./ResetPasswordPage.vue'),
      beforeEnter: [
        handleAuthRoute,
        checkValidnessOfToken
      ]
    }
  ]
}

export default mainPage
