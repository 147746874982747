import type { StoreType } from '../types'

import { getAcademicStudentsResource } from '@shared/api'
import { AcademicStudentsResourceRequest } from '@shared/swagger'

export default async function fetchAcademicStudentsResources (this: StoreType, body: AcademicStudentsResourceRequest): Promise<void> {
  const { ok, data } = await getAcademicStudentsResource(body)

  if (ok) {
    this.setAcademicStudentsResources(data)
  }
}
