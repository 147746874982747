import type { StoreType } from '../types'
import type { AcademicSingleCourseGradeStatisticsGetRequest, AcademicSingleCourseGradeStatisticsGetResponse } from '@shared/swagger'

import { getAcademicSingleCourseGradeStatistics } from '@shared/api'

export default async function fetchGrades (this: StoreType, assesment: string, values: AcademicSingleCourseGradeStatisticsGetRequest): Promise<AcademicSingleCourseGradeStatisticsGetResponse> {
  const { data, ok } = await getAcademicSingleCourseGradeStatistics(assesment, values)

  if (ok) {
    return data
  }
  return []
}
