import fetchWorkingCurriculum from '@/shared/api/AcademicCurricula/fetchWorkingCurriculum'
import type { StoreType } from '../types'

export default async function fetchWorkingCurriculu2m2 (this: StoreType, trackId: number): Promise<void> {
  const { data, ok } = await fetchWorkingCurriculum(trackId)

  if (ok) {
    this.setWorkingCurriculum(data)
  }
}
