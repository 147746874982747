import { SmartTableHeader } from '@/shared/types'

export const gateEntryTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.full-name',
    field: 'full_name'
  },
  {
    title: 'common.table.table-date',
    field: 'tableDate',
    slotCellName: 'tableDate',
    sortable: true
  },
  {
    title: 'common.table.table-in',
    field: 'tableIn',
    slotCellName: 'tableIn'
  },
  {
    title: 'common.table.table-out',
    field: 'tableOut',
    slotCellName: 'tableOut'
  },
  {
    title: 'common.table.table-duration',
    field: 'tableDuration',
    slotCellName: 'tableDuration'
  }
]
