<template>
  <Teleport to="#new-request-modal-content">
    <pms-row>
      <pms-grid :col="5">
        <pms-smart-form
          :fields="[formFields]"
          @handleChange="onChange"
        >
          <template #formControls>
            <div class="text-end mt-4" />
          </template>
        </pms-smart-form>
        <div class="d-flex gap-3">
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
          <pms-button
            :color="'primary'"
            :text="'common.send'"
            data-bs-dismiss="modal"
            @click="onSubmit()"
          />
        </div>
      </pms-grid>
      <pms-grid :col="7">
        <NewRequestFormCourseStatistics
          :equicode="statistics.equicode"
        />
      </pms-grid>
    </pms-row>
  </Teleport>
</template>

<script setup lang="ts">
import NewRequestFormCourseStatistics from './NewRequestFormCourseStatistics.vue'

import { computed, watch, ref, Ref } from 'vue'
import type { FormField } from '@shared/types'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useRoute } from 'vue-router'

import { objectDeepCopy } from '@shared/utils'
import { useI18n } from 'vue-i18n'
import { ReferencesSelects, UserNewRequest } from '@domains/SectionManagement/features/NewRequest/types'
import { refsToRequestedCourseOptions } from '@domains/SectionManagement/features/NewRequest/utils'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'

const route = useRoute()

const { t } = useI18n()
const { error, success } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const newRequestStore = useNewRequest()
const statistics = ref({
  equicode: 0
})

const formFields = computed<Array<FormField>>(() => [
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course')}`,
      class: 'fs-6 fw-semibold mb-2 required'
    },
    name: 'requested_course',
    type: 'select',
    value: tempValues.value.requested_course || null,
    options: [],
    complexOption: refsToRequestedCourseOptions(references.value)
  },
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course-quota')}:`,
      class: 'fs-6 fw-semibold mb-2 required'
    },
    name: 'quota',
    type: 'number',
    value: tempValues.value.quota || '',
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.academic.manage.sections.error.requested-course-quota') },
        'trim'
      ]
    }
  },
  {
    group: {
      class: 'fv-row mb-4 fv-plugins-icon-container'
    },
    label: {
      text: `${t('domains.academic.manage.sections.requested-course-note')}`,
      class: 'fs-6 fw-semibold mb-2'
    },
    name: 'note',
    type: 'textarea',
    value: tempValues.value.note || ''
  }
])

const defaultValues = {
  cirriculum_id: -1,
  requested_course: null,
  quota: null,
  note: ''
}

const tempValues : Ref<UserNewRequest> = ref(objectDeepCopy(defaultValues))
const references : Ref<ReferencesSelects[]> = ref([])

const onChange = ({ name, newValue }) => {
  if (name === 'requested_course') {
    tempValues.value[name] = JSON.parse(newValue)
  } else tempValues.value[name] = newValue
}

const onSubmit = async () => {
  if (newRequestStore.requestType === 'program') {
    const { status, message } = await newRequestStore.sendNewRequest({
      course_code: tempValues.value.requested_course?.course_code || '', // program - requests reference or main
      course_year: Number(route.query.year) || 0, // program - course - reference or main
      term: Number(route.query.term) || 0, // current by route query
      program_year: newRequestStore.program_request_year || 0, // program - year - search - selector (-1 used for search/ suggest)
      program_code: newRequestStore.selectedProgram?.code || '', // program - selected
      curriculum_id: tempValues.value.cirriculum_id, // get COURSES id references the same
      quota: Number(tempValues.value.quota) || -1, // error
      note: tempValues.value.note, // пустой стринг бола алады
      period_number: newRequestStore.program_request_term || 0 // term - search - selector (-1 used for suggest/search)
    })
    if (status) {
      success({ text: message, isToast: true })
    } else {
      error({ text: message })
    }
  }
  if (newRequestStore.requestType === 'search' || newRequestStore.requestType === 'suggested') {
    const { status, message } = await newRequestStore.sendNewRequest({
      course_code: tempValues.value.requested_course?.course_code || '',
      course_year: tempValues.value.requested_course?.course_year || 0,
      term: Number(route.query.term) || 0,
      program_year: Number(route.query.year) || 0,
      program_code: newRequestStore.selectedProgram?.code || '',
      curriculum_id: tempValues.value.cirriculum_id,
      quota: Number(tempValues.value.quota) || -1,
      note: tempValues.value.note,
      period_number: -1
    })
    if (status) {
      success({ text: message, isToast: true })
    } else {
      error({ text: message })
    }
  }
}

const closeModal = () => {
  close({ name: 'addresses-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.equicode && newValue.data.courseCode) {
      statistics.value = {
        equicode: newValue.data.equicode as number
      }
      references.value = newRequestStore.getCourseAndReferences(newValue.data.equicode as number, newValue.data.courseCode as string) || []
      tempValues.value = objectDeepCopy(defaultValues)
      tempValues.value.cirriculum_id = newValue.data.id as number
      show()
    }
  }, { immediate: true }
)
</script>
