<template>
  <p>
    <b>{{ 'Selected course: ' }}</b>
    {{ selectedCourseForAddToTerm?.courseCode }}
  </p>
  <pms-smart-form
    class="d-flex align-items-start gap-3"
    :fields="[courseSettingsFormFields]"
    @handleChange="onHandleChange"
  >
    <template #formControls />
  </pms-smart-form>

  <pms-button
    v-if="courseSettings.term && (courseSettings.coreType === 'C' || (courseSettings.coreType === 'E' && courseSettings.electiveType))"
    class="mt-5"
    @Click="handleAddCourse"
  >
    {{ t('domains.academic.curricula.add-course') }}
  </pms-button>
</template>
<script setup lang="ts">
import { hideModal, useAlert } from '@/shared/composable'
import { useCourseAdder } from '../composable'
import { storeToRefs } from 'pinia'
import { useStore } from '@/domains/AcademicCurricula/store'
import addCourseToTrack from '@/shared/api/AcademicCurricula/addCourseToTrack'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { dialog, error } = useAlert()
const router = useRouter()

const store = useStore()
const { selectedCourseForAddToTerm, track } = storeToRefs(store)

const handleAddCourse = () => {
  dialog({
    text: 'Are you sure ',
    onConfirm: async () => {
      if (!track.value?.id) {
        error({
          text: 'Track is not defined'
        })
        return
      }
      if (!courseSettings.value.term) {
        error({
          text: 'Choose term'
        })
        return
      }
      if (!courseSettings.value.coreType) {
        error({
          text: 'Choose course type'
        })
        return
      }
      if (selectedCourseForAddToTerm.value) {
        const myModalElement = document.getElementById('modal')
        hideModal(myModalElement)

        const response = await addCourseToTrack({
          track_id: track.value.id,
          course_code: selectedCourseForAddToTerm.value.courseCode,
          terms: courseSettings.value.term,
          type: courseSettings.value.coreType,
          elective_type: courseSettings.value.electiveType ?? undefined
        })
        if (response.status) {
          router.back()
        } else {
          error({
            text: response.message
          })
        }
      }
    }
  })
}

const { courseSettingsFormFields, courseSettings } = useCourseAdder()

const onHandleChange = ({ name, newValue }) => {
  courseSettings.value[name] = newValue
}
</script>
