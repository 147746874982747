const printStyle = `
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 10px !important;
}

.separator {
  border-bottom-style: dashed;
  display: block;
  width: 100% !important;
  height: 0;
  border-bottom: 1px solid #dddddd;
}
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>
`

const generateHeaderHTML = (faculty: string, department: string, year: number, term : number): string => {
  return ` <table
  border="0"
  cellpadding="0"
  style="border-collapse:collapse"
  width="100%"
>
  <tr>
    <td colspan="2">
      <table
        border="0"
        bordercolor="#000000"
        width="100%"
      >
        <tr>
          <td
            style="display: flex; justify-content: center;"
          >
            <img src="/sdu.gif">
          </td>
          <td>
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 16px">
                <span>${faculty}</span>
                <span>${department} : ${year} - ${term}</span>
            </div>
          </td>
          <td 
            style="display: flex; justify-content: center;"
          >
            <img src="/sdu.gif">
          </td>
        </tr>
      </table>
    </td>
    </tr>
    </table>
  `
}

export default (faculty: string, department: string, year: number, term : number): void => {
  const table = document.getElementById('wokloadTable')
  const w = window.open()

  if (table && w) {
    w.document.body.innerHTML = `${generateHeaderHTML(faculty, department, year, term)} ${
        table.outerHTML
      }${printStyle}`
    w.document.close()
    w.focus()
    setTimeout(() => w.print(), 100)
  }
}
