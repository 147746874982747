import { defineComponent, onMounted } from 'vue'
import { useBodyStore, useThemeStore } from '@shared-stores/pattern'
import { LayoutUtils } from '@shared-utils/pattern'

const AuthLayout = defineComponent({
  name: 'AuthLayout',
  components: {},
  setup () {
    const store = useBodyStore()
    const theme = useThemeStore()

    onMounted(() => {
      LayoutUtils.emptyElementClassesAndAttributes(document.body)
      store.addBodyClassName('app-blank')
      store.addBodyClassName('app-blank')
      store.addBodyClassName('bgi-size-cover')
      store.addBodyClassName('bgi-attachment-fixed')
      store.addBodyClassName('bgi-position-center')
    })

    return () => (
      <div class="d-flex flex-column flex-root" style={{
        backgroundColor: theme.theme === 'light'
          ? '#F5F8FA'
          : 'inherit'
      }}>
        <router-view />
      </div>
    )
  }
})

export default AuthLayout
