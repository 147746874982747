import { SelectField } from '@shared/types'
import { AdministrativeResourceResponse } from '@shared/swagger'

export default (data: AdministrativeResourceResponse['gate_entry_types']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((el) => {
    options.push({
      label: el.title,
      value: el.type
    })
  })
  return options
}
