import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'
import type { AdministrativeMessageBoardPostsPostRequest } from '@shared/swagger'

import { postAdministrativeMessageBoardPosts } from '@shared/api'
import { usePagination } from '@shared/composable'

export default async function addPost (this: StoreType, values: AdministrativeMessageBoardPostsPostRequest): Promise<StatusMessageResponse> {
  const { perPage } = usePagination()

  const { ok, message } = await postAdministrativeMessageBoardPosts(values)

  if (ok) {
    await this.fetchPosts(
      {
        per_page: perPage.value,
        offset: 1,
        search: '',
        my_posts: this.currentPageView === 'my-posts' ? 1 : 0
      },
      'administrative/message-board/posts-post')
  }

  return {
    status: ok,
    message
  }
}
