import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { SingleRuleGetResponse, SingleRulePutRequest } from '@shared/swagger'

export default async function putRulesAndRegulations (rule: string, body: SingleRulePutRequest): Promise<AxiosResponse<SingleRuleGetResponse>> {
  const { put } = useRequests()

  const response = await put<SingleRulePutRequest, SingleRuleGetResponse>({
    url: `academic/information/rules/${rule}`,
    body,
    loader: 'academic/information/rules/single/edit-contents'
  })
  return response
}
