import type { StoreType } from '../types'

import { getProfileSettings } from '@shared/api'

export default async function fetchSettings (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileSettings()

  if (ok) {
    this.setSettings(data)
  }
}
