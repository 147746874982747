import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendancesByCourseGetRequest,
  AcademicSingleCourseAttendancesByCourseGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicSingleCourseAttendancesByCourseGetRequest): Promise<AxiosResponse<AcademicSingleCourseAttendancesByCourseGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendancesByCourseGetResponse>({
    url: 'academic/courses/attendances/by-courses',
    body: { params },
    loader: 'academic/courses/attendances/by-courses'
  })

  return response
}
