<template>
  <pms-row>
    <pms-grid
      id="single-student-accounting"
      class="mb-4"
    >
      <pms-card
        :title="'domains.academic.single-student.navigation.items.accounting'"
        :description="'domains.academic.single-student.navigation.items.accounting-description'"
        :options="{
          headers: { textAlignment: 'center' },
          body: { class: 'pt-0' }
        }"
      >
        <pms-smart-table
          :headers="accountingTableHeaders.main"
          :items="accounting || []"
          :options="{ items: { textAlignment: 'center' } }"
        >
          <template #item-no="{ idx }">
            <span class="fs-5"> {{ idx + 1 }}</span>
          </template>
          <template
            v-for="(item, index) in mainFields"
            :key="index"
            #[`item-${item}`]="{ value }"
          >
            <span class="fs-5 fw-semibold"> {{ value }}</span>
          </template>
          <template #item-action="{ row }">
            <div class="text-center">
              <button
                class="btn btn-sm btn-light-primary w-100px"
                :class="sideBarState && sideBarTitle == `${row.payment_year} - ${row.payment_term}}` ? 'active': ''"
                @click="() => openDetails(row.payment_year, row.payment_term, `${row.payment_year} - ${row.payment_term}`)"
              >
                {{ $t('common.details') }}
                <pms-kt-icon
                  icon-name="arrow-right"
                  class="ms-2 m-0 p-0"
                />
              </button>
            </div>
          </template>
        </pms-smart-table>
      </pms-card>
    </pms-grid>
    <accounting-details :side-bar-data="sideBarData" />
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useSingleStudent } from '../../../store'
import { accountingTableHeaders, sideBarTitle, sideBarState, mainFields } from '../values'

import AccountingDetails from './AccountingDetails.vue'

const store = useSingleStudent()

const studentId = computed(() => store.getStudentId)
const accounting = computed(() => store.getAccounting)
const sideBarData = computed(() => store.getAccountingDetails)

const openDetails = async (year: number, term: number, title: string) => {
  sideBarTitle.value = title
  sideBarState.value = true
  await store.fetchAccountingDetails(studentId.value, { year, term })
}

</script>
