import type { StoreType } from '../types'
import type { SectionManagementResourceGetRequest } from '@shared/swagger'
import { getSectionManagementResource } from '@shared/api'

export default async function fetchSectionManagementResource (this: StoreType, body: SectionManagementResourceGetRequest): Promise<void> {
  const { ok, data } = await getSectionManagementResource(body)

  if (ok) {
    this.setSectionManagementResource(data)
  }
}
