<template>
  <pms-card :header="false">
    <pms-loader v-if="resourceLoader" />
    <div v-else>
      <SelectedProgramBlock
        v-if="newRequestStore.selectedProgram"
      />
      <div
        v-else
        class="d-flex flex-column gap-5"
      >
        <span class="fs-3 fw-bold">{{ $t('domains.section-management.new-request.program-list-title') }}</span>
        <ProgramTable />
      </div>
    </div>
    <pms-teleport-modal
      :name="'new-request-modal'"
      :teleport-name="'new-request-modal-content'"
      :title="$t(`domains.academic.manage.sections.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`domains.academic.manage.sections.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :size="'xl'"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
  </pms-card>
</template>

<script setup lang="ts">
import { watch, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

import { useNewRequest } from './store'
import { useLoader, useTeleportModal } from '@shared/composable'

import SelectedProgramBlock from './ui/SelectedProgramBlock.vue'
import ProgramTable from './ui/ProgramTable.vue'

const route = useRoute()
const { isActiveOneOf } = useLoader()
const { modifier } = useTeleportModal()

const newRequestStore = useNewRequest()

const resourceLoader = isActiveOneOf(['resources', 'academic/manage/sections/resources'])

watch(() => route.query,
  () => {
    if (route.query.year && route.query.term) {
      const year = Number(route.query.year)
      const term = Number(route.query.term)

      newRequestStore.setYearTerm(year, term)
    }
  }, { immediate: true })

onBeforeUnmount(() => {
  newRequestStore.$reset()
})
</script>
