import type { StoreType } from '../types'
import type { SectionManagementRequestCourseGetRequest } from '@/shared/swagger'
import { StatusMessageResponse } from '@/shared/types'
import { getSectionManagementRequestCourses } from '@shared/api'

export default async function fetchRequestCourses (this: StoreType, params: SectionManagementRequestCourseGetRequest): Promise<StatusMessageResponse> {
  const { data, ok, message } = await getSectionManagementRequestCourses(params)

  if (ok) {
    this.setRequestCourses(data)
  } else {
    this.setRequestCourses([])
  }
  return {
    status: ok,
    message: message
  }
}
