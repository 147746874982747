import { RouteRecordWithLayout } from '@shared-types/common'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { usePagination } from '@shared/composable'
import { useKnowledgeCategories } from './store'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useKnowledgeCategories()

  pagination.$reset()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/knowledge-base/manage/categories',
  children: [
    {
      path: '',
      name: 'KnowledgeManageCategories',
      component: () => import('./KnowledgeManageCategories.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
