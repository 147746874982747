<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :filter="true"
    :full-width="true"
  />

  <grades-component v-else />
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourGrades } from '../../values'
import { useSingleStudent } from '../../store'

import { gradeSideBarState } from './values'
import GradesComponent from './ui/GradesComponent.vue'

import { storeToRefs } from 'pinia'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const store = useSingleStudent()

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/grades'])
const { currentYearTerm } = storeToRefs(store)

watch(() => currentYearTerm.value, () => {
  gradeSideBarState.value = false
})

onMounted(async () => {
  setQuickTourSteps(await quickTourGrades())
})
</script>
