import html2pdf from 'html2pdf.js'

const generateHtml = (element: HTMLElement) => {
  const html = `
    <html>
    <head>
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Inter:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
      <style>
        body {
          font-family: 'Poppins';
        }
        .bg-gradient {
          background-color: #e9e9e9;
        }
        .badge {
          background-color: #e9e9e9;
        }
      </style>
    </head>
    <body>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="w-75">
          ${element.innerHTML}
        </div>
      </div>
    </body>
    </html>
  `

  return html
}

const generatePdf = (element: HTMLElement) => {
  const options = {
    filename: 'CV.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  }

  html2pdf().set(options).from(generateHtml(element)).save()
}

export default generatePdf
