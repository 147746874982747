<template>
  <AddOpinionModal />
  <pms-tab
    id="single-student-information"
    v-model:active="activeTab"
    :all="false"
    :tabs="tabs"
    tab-buttons-class="w-100 justify-content-center"
    class="mw-100"
  >
    <template #information>
      <pms-card
        :title="'domains.academic.single-student.information.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <main-information
          v-if="information?.main_information"
          :information="information.main_information"
        />
        <template v-else>
          <div class="text-center fs-4 mt-20 mb-20">
            {{ $t('common.no-data') }}
          </div>
        </template>
      </pms-card>
    </template>
    <template #contact>
      <pms-card
        :title="'domains.academic.single-student.contact.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <pms-smart-table
          :headers="informationTableHeaders.contact"
          :items="information?.contacts"
        >
          <template #item-type="{ value }">
            <span
              v-styler="{ marginLeft: '15px'}"
              class="fw-bold fs-6"
            >{{ value }}</span>
          </template>
        </pms-smart-table>
      </pms-card>
    </template>
    <template #address>
      <pms-card
        :title="'domains.academic.single-student.address.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <pms-smart-table
          :headers="informationTableHeaders.address"
          :items="information?.addresses"
        >
          <template #item-type="{ value }">
            <span
              v-styler="{ marginLeft: '15px'}"
              class="fw-bold fs-6"
            >{{ value }}</span>
          </template>
          <template
            v-for="column in ['country', 'province', 'region', 'city']"
            :key="column"
            #[`item-${column}`]="{ value }"
          >
            {{ getAddressValue(value, column) }}
          </template>
        </pms-smart-table>
      </pms-card>
    </template>
    <template #parentInformation>
      <pms-card
        :title="'domains.academic.single-student.parent-information.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <pms-smart-table
          :headers="informationTableHeaders.parentInformation"
          :items="information?.parent_information"
        >
          <template #item-parent-type="{ value }">
            <span
              v-styler="{ marginLeft: '15px'}"
              class="fw-bold fs-6"
            >{{ value }}</span>
          </template>
        </pms-smart-table>
      </pms-card>
    </template>
    <template #decision>
      <pms-card
        :title="'domains.academic.single-student.decision.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <pms-smart-table
          :headers="informationTableHeaders.decisions"
          :items="information?.decisions"
        >
          <template #item-date="{ value }">
            <span
              class="fw-bold fs-6"
            >{{ value }}</span>
          </template>
        </pms-smart-table>
      </pms-card>
    </template>
    <template #advisorOpinion>
      <pms-card title="domains.academic.single-student.advisor-opinion.title">
        <template #header>
          <div class="d-flex w-100 justify-content-between ">
            <h3 class="card-title align-items-start flex-column mb-0 card-label fw-bold text-gray-800">
              <span>{{ $t('domains.academic.single-student.advisor-opinion.title') }}</span>
            </h3>
            <pms-button
              class="d-flex align-self-end"
              @click="visible = true"
            >
              {{ $t('common.add-opinion') }}
            </pms-button>
          </div>
        </template>
        <pms-smart-table
          :headers="informationTableHeaders.advisorOpinion"
          :items="information?.advisor_opinions"
        >
          <template #date="{ item }">
            <span
              v-styler="{ marginLeft: '15px'}"
              class="fw-bold fs-6"
            >{{ item.date }}</span>
          </template>
          <template #content="{item}">
            <span v-if="!editable">{{ item.content }}</span>
            <Textarea
              v-if="editable"
              v-model="item.content"
              class="w-100"
            />
          </template>
          <template #action="{ item }">
            <div
              v-if="!editable"
              class="d-flex justify-content-center gap-2"
            >
              <pms-button
                class="btn-sm btn btn-light-primary"
                @Click="editable = true"
              >
                <i class="bi bi-pencil" />
              </pms-button>

              <pms-button
                type="button"
                class="btn btn-sm btn-light-danger"
                :color="'danger'"
                @Click="deleteOpinion(item.id)"
              >
                <i class="bi bi-trash" />
              </pms-button>
            </div>
            <div
              v-if="editable"
              class="d-flex gap-3 justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="btn btn-sm btn btn-light-success"
                @click="editOpinion(item.id, item.content)"
              >
                {{ `${t('common.save')}` }}
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-sm btn btn-light-danger"
                @click="editable = false"
              >
                {{ `${t('common.cancel')}` }}
              </a>
            </div>
          </template>
        </pms-smart-table>
      </pms-card>
    </template>
    <template #dissertation>
      <pms-card
        :title="'domains.academic.single-student.dissertation.title'"
        :options="{
          body: {class: 'pt-4'}
        }"
      >
        <pms-smart-table
          :headers="informationTableHeaders.dissertation"
          :items="information?.dissertations"
        />
      </pms-card>
    </template>
  </pms-tab>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { useResource } from '@shared/stores'
import { useSingleStudent } from '../../../store'
import { tabs, informationTableHeaders, opinion, visible } from '../values'
import MainInformation from './MainInformation.vue'
import Textarea from 'primevue/textarea'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'
import AddOpinionModal from './AddOpinionModal.vue'

const resource = useResource()
const store = useSingleStudent()
const { error, success } = useAlert()
const { t } = useI18n()

const activeTab = ref<string>('information')
const editable = ref(false)

const countries = computed(() => resource.getProfileResourceCountries)
const provinces = computed(() => resource.getProfileResourceProvinces)
const regions = computed(() => resource.getProfileResourceRegions)
const cities = computed(() => resource.getProfileResourceCities)
const information = computed(() => store.getInformation)
opinion.value.student = store.getStudentId

const getAddressValue = (value: number|string, type: string): string => {
  if (!value) return ''
  let title = ''

  switch (type) {
    case 'country':
      title = countries.value.find((country) => country.id === value)?.title || ''
      break
    case 'province':
      title = provinces.value.find((province) => province.id === value)?.title || ''
      break
    case 'region':
      title = regions.value.find((region) => region.id === value)?.title || ''
      break
    case 'city':
      title = cities.value.find((city) => city.id === value)?.title || ''
      break
  }

  return title
}

const deleteOpinion = (id: number) => {
  store.deleteAdvisorOpinion(opinion.value.student, id.toString())
}

const editOpinion = async (id: number, content: string) => {
  opinion.value.values.content = content
  const { status, message } = await store.putAdvisorOpinion(opinion.value.student, id.toString(), opinion.value.values.content)
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}
</script>
