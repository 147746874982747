<template>
  <pms-smart-form
    class="d-flex align-items-start justify-content-end gap-3"
    :fields="[formFields]"
    @handleSubmit="onSubmit"
  >
    <template #label>
      <div class="fw-semibold mt-3">
        <span>
          {{ $t('domains.academic.single-course.grade.manage-password') }}
        </span>
      </div>
    </template>
    <template #formControls>
      <div class="d-flex gap-3 ms-2">
        <pms-button
          :text="`common.cancel`"
          :color="'secondary'"
          type="reset"
          @click="onCancel"
        />
        <pms-button
          :text="`common.submit`"
          :disabled="loader"
          :loader="loader"
          type="submit"
          :color="'primary'"
        />
      </div>
    </template>
  </pms-smart-form>
</template>

<script setup lang="ts">
import moment from 'moment/moment'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { AcademicSingleCourseGradeRequest } from '@shared/swagger'
import { FormField } from '@shared/types'
import { useAlert, useEncrypt, useLoader } from '@shared/composable'

import { useSingleSection } from '../../../store'

import { addedGrades, bellCurvedGrades, clearAllGradesValues } from '../values'
import { AddedGrades } from '../types'
import { convertBellCurveToAddedGrades } from '../utils'

const route = useRoute()

const { error } = useAlert()
const { encrypt } = useEncrypt()
const { t } = useI18n()
const { isActive } = useLoader()

const store = useSingleSection()
const loader = isActive('academic/courses/grades-put')

const onCancel = () => {
  clearAllGradesValues()
}

const onSubmit = async ({ values }) => {
  await onSend(bellCurvedGrades.value.assessment_id
    ? convertBellCurveToAddedGrades(bellCurvedGrades.value)
    : addedGrades.value, values.password)
}

const onSend = async (grade: AddedGrades, password: string) => {
  if (password === '') {
    error({ title: 'common.grade-error', text: t('domains.academic.single-course.grade.manage-password-required') })
    return
  }

  const encrypted = encrypt({
    password,
    date: moment().format('YYYY-MM-DD HH:mm:ss')
  })

  grade.grades = grade.grades.map((grade) => {
    return {
      ...grade,
      grade: grade.absent ? 0 : grade.grade
    }
  })

  const captcha = JSON.parse(encrypted || '{}')

  const { status, message } = await store.updateGrade(grade.assessment_id, { captcha, grades: grade.grades.filter(g => g.grade || g.grade === 0) })

  if (status) {
    clearAllGradesValues()

    const { code, section, year, term } = route.query as { code: string; section: string; year: string; term: string }

    const values: AcademicSingleCourseGradeRequest = {
      course_code: code,
      section: section,
      term: parseInt(term),
      year: parseInt(year)
    }

    await store.fetchGrades(values)
  } else {
    error({ title: 'common.error', text: message })
  }
}

const formFields: Array<FormField> = [
  {
    type: 'slot',
    name: 'label'
  },
  {
    type: 'password',
    name: 'password',
    binding: {
      autocomplete: 'manage-password'
    },
    class: 'w-300px',
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.academic.single-course.grade.manage-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'slot',
    name: 'link-page'
  }
]
</script>
