import { defineComponent, PropType, ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { TourButtonTexts, TourStep, TourTheme } from '@shared/types'
import { useThemeStore } from '@/shared/stores/pattern'
import { storeToRefs } from 'pinia'

const TourComponent = defineComponent({
  name: 'TourComponent',
  props: {
    steps: {
      type: Array as PropType<Array<TourStep>>,
      required: true,
      default: () => []
    },
    text: {
      type: Object as PropType<TourButtonTexts>,
      required: false,
      default: (): TourButtonTexts => {
        return {
          nextCta: 'common.next',
          prevCta: 'common.previous',
          restartCta: 'common.restart'
        }
      }
    },
    theme: {
      type: Object as PropType<TourTheme>,
      default: () => {
        return {
          color: '#009de0',
          radius: '2px'
        }
      }
    },
    blurEl: {
      type: String as PropType<string>,
      required: false,
      default: (): string => '.can-tour-blur'
    },
    debug: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    },
    storage: {
      type: String as PropType<string>,
      required: false,
      default: (): string => 'vue-tour-viewed'
    },
    startLarge: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true
    },
    launched: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: (): boolean => false
    },
    trimCount: {
      type: Number as PropType<number>,
      required: false,
      default: 200
    },
    startWith: {
      type: Number as PropType<number>,
      required: false,
      default: 1
    }
  },
  emits: ['launch', 'update:startWith'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const store = useThemeStore()
    const { theme } = storeToRefs(store)
    const currentStep = ref<number>(0)
    const open = ref<boolean>(props.launched)
    const scaled = ref<boolean>(false)

    watch(
      () => props.launched,
      (val: boolean) => {
        open.value = val
      }
    )

    watch(
      () => props.startWith,
      (val: number) => {
        currentStep.value = val - 1
      }
    )

    const next = (): void => {
      if (currentStep.value < props.steps.length - 1) currentStep.value++
      else currentStep.value = 0
    }

    const prev = (): void => {
      if (currentStep.value > 0) currentStep.value--
      else currentStep.value = props.steps.length - 1
    }

    const close = (): void => {
      emit('launch', false)
      emit('update:startWith', 1)
      open.value = false
      document.querySelector(props.blurEl)?.classList.remove('tour-blurred')
      localStorage.setItem('vue-tour-viewed', 'true')
    }

    const scale = (): void => {
      scaled.value = !scaled.value
      if (scaled.value) {
        document.querySelector(props.blurEl)?.classList.add('tour-blurred')
      } else {
        document.querySelector(props.blurEl)?.classList.remove('tour-blurred')
      }
    }

    const getDesc = (text: string): string => {
      const trimCount = props.trimCount || 200
      return text.length > trimCount && !scaled.value
        ? text.replace(/(<([^>]+)>)/gi, '').substring(0, trimCount) + '...'
        : text
    }

    onMounted(() => {
      currentStep.value = props.startWith - 1
      const watched = localStorage.getItem(props.storage)
      if (!props.debug && watched !== null && !!watched === true) { open.value = false }
      if (props.startLarge && !watched) {
        scaled.value = true
        document.querySelector(props.blurEl)?.classList.add('tour-blurred')
      } else {
        document.querySelector(props.blurEl)?.classList.remove('tour-blurred')
      }
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    })

    return () =>
      open.value && (
        <div>
          {scaled.value && <div class="tour-popover" onClick={scale} />}
          <div
            class={'tour-preview ' + (scaled.value ? 'is-scaled' : '')}
            style={{ 'border-radius': props.theme.radius }}
          >
            {props.steps.map(
              (step, index) =>
                currentStep.value === index && (
                  <div class="step card " style="border-radius: 0px;">
                    <div
                      class={'d-flex gap-2 position-absolute'}
                      style={{
                        top: '2%',
                        right: '2%'
                      }}
                    >
                      <button
                        class={{
                          'btn btn-icon btn-sm  rounded btn-primary': true,
                          'is-scaled': scaled.value
                        }}
                        onClick={scale}
                        style={{
                          width: '25px',
                          height: '25px',
                          right: '60px'
                        }}
                      >
                        <pms-kt-icon
                          icon-name="arrow-diagonal"
                          class="text-white fs-2"
                        />
                      </button>
                      <button
                        class="btn btn-icon btn-sm  rounded btn-primary"
                        onClick={close}
                        style={{
                          width: '25px',
                          height: '25px',
                          right: '30px'
                        }}
                      >
                        <pms-kt-icon
                          icon-name="cross"
                          class="text-white fs-2"
                        />
                      </button>
                    </div>

                    <img
                      src={step.preview}
                      alt={step.title}
                      style={{
                        height: scaled.value ? '250px' : '150px',
                        'border-top-left-radius': props.theme.radius,
                        'border-top-right-radius': props.theme.radius,
                        'object-fit': 'cover'
                      }}
                    />

                    <div class="card-body mt-0 mb-0 pt-4 pb-2">
                      <p
                        class="title h3 mb-2"
                        style={'color:' + props.theme.color}
                      >
                        {step.title.includes('domains.')
                          ? t(step.title)
                          : step.title}
                      </p>
                      <div
                        class={{
                          scroll: true
                        }}
                        style="height: 49vh"
                      >
                        <p class="description fw-bolder mb-2">
                          {getDesc(
                            step.description.includes('domains')
                              ? t(step.description)
                              : step.description
                          )}
                        </p>
                        {scaled.value && (
                          <p
                            class="fs-6 text-dark pb-2 pt-2"
                            innerHTML={step.content}
                          ></p>
                        )}
                      </div>
                    </div>
                    {props.steps.length > 1 && (
                      <div
                        class="card-footer pt-6 pb-6 w-100 position-absolute z-3 bottom-0 left-0"
                        style={{
                          'border-top': 'none',
                          'background-color':
                            theme.value === 'light' ? '#ffffff' : '#1e1e2d'
                        }}
                      >
                        <div class="footer-tour">
                          <div class="footer-dots">
                            {props.steps.map((step, index2) => (
                              <div
                                class="dot"
                                style={{
                                  'background-color':
                                    index2 === currentStep.value
                                      ? props.theme.color
                                      : '#e6eaee'
                                }}
                              ></div>
                            ))}
                          </div>
                          {currentStep.value > 0 && (
                            <div class="footer-link" onClick={prev}>
                              {' '}
                              {t(props.text.prevCta)}
                            </div>
                          )}
                          <div
                            class={
                              'footer-btn ' +
                              (currentStep.value === 0 ? 'ml-auto' : '')
                            }
                            onClick={next}
                            style={{
                              background: props.theme.color,
                              'border-radius': props.theme.radius
                            }}
                          >
                            {currentStep.value !== props.steps.length - 1
                              ? t(props.text.nextCta)
                              : t(props.text.restartCta)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )
  }
})

export default TourComponent
