<template>
  <pms-card
    v-if="students"
    :title="null"
    :description="$t('domains.services.advisor-assignment.assign-tool.description')"
    class="mt-5"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 d-flex justify-content-start">
          <pms-button
            v-if="isSelectedStudents"
            type="submit"
            class="mt-2"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.assign')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import useAssignForm from './composable/useAssignForm'

const { formFields, onSubmit, students, loader, isSelectedStudents } = useAssignForm()
</script>
