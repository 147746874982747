const enMessages = {
  policy: {
    title: 'Course Policy',
    'academic-integrity': {
      title: 'Academic Integrity',
      description: 'Students must ensure that all work completed for this course is their own work. Any evidence of plagiarism, data falsification, fabrication, collusion, self-plagiarism and/or other forms of academic misconduct will be penalized. Further, information can be found in the Code of Practice on Academic Integrity.'
    },

    'late-non-submission-and-attendance-policy': {
      title: 'Late/Non Submission and Attendance Policy',
      description: 'Academic excellence and high achievement are only possible in an environment where the highest standards of academic honesty and integrity are maintained: students at SDU must ensure they adhere to this requirement. Active participation is an integral part of teaching and learning at SDU. Therefore, all students are required to attend classes regularly, and any absences are recorded for each subject. The percentage of attendance should be at least 70%.'
    },

    'course-specific-policy': {
      title: 'Course Specific Policy'
    }
  },

  advanced: {
    title: 'Assessment and workload',
    'student-workload': 'Student workload',
    assessment: 'Assessment',
    'reading-list': 'Reading list',
    'teaching-methods': 'Teaching methods'
  },

  content: {
    title: 'Course content',
    'academic-skills': 'Academic skills',
    'subject-specific-skills': 'Subject-Specific skills',
    'weekly-course-plan': 'Weekly course plan',
    'course-learning-outcomes': 'Course learning outcomes'
  },

  information: {
    title: 'Course information',
    instructors: 'Instructor(s)',
    hybrid: 'Hybrid',
    online: 'Online',
    on_campus: 'On campus',
    requisites: 'Requisites',
    'course-description': 'Course description',
    'mode-of-delivery': 'Mode(s) of delivery',
    'programs-on-available': 'Programs on which the course is available',
    'course-information': 'General information'
  },

  'table-fields': {
    status: 'Status',
    authority: 'Authority',
    activity: 'Activity',
    quantity: 'Quantity',
    hours: 'Hours',
    assessment: 'Assessment',
    description: 'Description',
    percentage: 'Percentage (%)',
    title: 'Title',
    type: 'Type',
    author: 'Author',
    isbn: 'ISBN',
    published: 'Published (year)',
    publisher: 'Publisher/Web link',
    no: 'No',
    week: 'Week',
    topics: 'Topics',
    degree: 'Degree',
    email: 'Email',
    contacts: 'Contacts',
    department: 'Department',
    faculty: 'Faculty',
    language: 'Language',
    credits: 'Credits',
    grading: 'Grading',
    'last-modified': 'Last modified',
    'active-verb': 'Active verb',
    'what-will-be-done-produced': 'What will be done/produced',
    'how-this-learning-outcome-will-be-achieved': 'How this learning outcome will be achieved',
    'full-name': 'Full name',
    'program-code': 'Program code',
    'program-title': 'Program title',
    'course-title': 'Course title',
    'concent-status': 'Concent status',
    'learning-outcomes': 'Learning outcomes',
    'year-term': 'Year (term)',
    'course-code': 'Course code (equivalent code)',
    ects: 'ECTS',
    lab: 'Laboratory',
    practice: 'Practice',
    quota: 'Credits',
    theory: 'Theory'
  },

  selector: 'Syllabus version'
}

const kzMessages = {
  policy: {
    title: 'Пән саясаты',
    'academic-integrity': {
      title: 'Академиялық адалдық',
      description: 'Білімгерлер осы пән бойынша барлық жұмыс түрлерін өздері әзірлеу керек. Плагиаттың, деректерді бұрмалаудың, ұрлаудың, алдын-ала сөз байласудың, өз жұмыстарынан көшірме/плагиат және / немесе академиялық қателіктің басқа нысандарының кез-келген дәлелдері жазаланады.'
    },

    'late-non-submission-and-attendance-policy': {
      title: 'Сабаққа қатысуға қатысты пән саясаты',
      description: 'Академиялық артықшылық пен жоғары жетістіктер Академиялық адалдық пен парасаттылықтың ең жоғары стандарттары сақталатын ортада ғана мүмкін болады:СДУ білімгерлері осы талапты ұстанатындығына көз жеткізуі керек. Сабаққа белсенді қатысу СДУ-да оқу мен оқытудың ажырамас бөлігі болып табылады. Сондықтан барлық білімгерлерден сабаққа үнемі қатысу талап етіледі және әр пән үшін жеке есепке алынады: білімгердің сабаққа қатысу деңгейі 70% - дан төмен болмау керек.'
    },

    'course-specific-policy': {
      title: 'Сабаққа қатысуға қатысты пән саясаты'
    }
  },

  advanced: {
    title: 'Бағалау және жүктеме',
    'student-workload': 'Бағалау және жүктеме',
    assessment: 'Бағалау',
    'reading-list': 'Оқу тізімі',
    'teaching-methods': 'Оқыту әдістері'
  },

  content: {
    title: 'Курс мазмұны',
    'academic-skills': 'Академиялық дағдылар',
    'subject-specific-skils': 'Пәнге қатысты дағдылар',
    'weekly-course-plan': 'Апталық курс жоспары',
    'course-learning-outcomes': 'Курстың оқу нәтижелері'
  },

  information: {
    title: 'Курс туралы ақпарат',
    instructors: 'Оқытушы(лар)',
    hybrid: 'Гибрид',
    online: 'Онлайн',
    on_campus: 'Кампуста',
    requisites: 'Деректемелер',
    'course-description': 'Курс сипаттамасы',
    'mode-of-delivery': 'Жеткізу режимі(лері)',
    'programs-on-available': 'Курс қолжетімді бағдарламалар',
    'course-information': 'Жалпы ақпарат'
  },

  'table-fields': {
    status: 'Статус',
    authority: 'Жауапты тұлға',
    activity: 'Атқарылатын жұмыс',
    quantity: 'Саны',
    hours: 'Сағат',
    assessment: 'Бағалау',
    description: 'Сипаттамасы',
    percentage: 'Процент (%)',
    title: 'Атауы',
    type: 'Түрі',
    author: 'Автор',
    isbn: 'ISBN',
    published: 'Жарияланған (жылы)',
    publisher: 'Баспагер/веб сілтеме',
    no: 'Жоқ',
    week: 'Апта',
    topics: 'Тақырыптар',
    degree: 'Дәреже',
    email: 'Электрондық пошта',
    contacts: 'Контактілер',
    department: 'Бөлім',
    faculty: 'Факультет',
    language: 'Тіл',
    credits: 'Несиелер',
    grading: 'Баға қою',
    'last-modified': 'Соңғы өзгерту',
    'active-verb': 'белсенді етістік',
    'what-will-be-done-produced': 'Не жасалады/өндіріледі',
    'how-this-learning-outcome-will-be-achieved': 'Оқу нәтижесіне қалай қол жеткізіледі',
    'full-name': 'Толық аты-жөні',
    'program-code': 'Бағдарлама коды',
    'program-title': 'Бағдарлама тақырыбы',
    'course-title': 'Курс атауы',
    'concent-status': 'Келісім статусы',
    'learning-outcomes': 'Оқыту нәтижелері',
    'year-term': 'Жыл (мерзім)',
    'course-code': 'Курс коды (эквивалентті код)',
    ects: 'ECTS',
    lab: 'Зертхана',
    practice: 'Тәжірибе',
    quota: 'Несиелер',
    theory: 'Теория'
  },

  selector: 'Силлабус нұсқасы'
}

const ruMessages = {
  policy: {
    title: 'Политика курса',
    'academic-integrity': {
      title: 'Академическая честность',
      description: 'Студенты должны убедиться, что вся работа, выполненная для этого предмета, является их собственной работой. Любые доказательства плагиата, фальсификации данных, сговора, самоплагиата и/или других форм академического проступка будут наказываться. Кроме того, подробную информацию можно найти в Правилах Академической честности.'
    },

    'late-non-submission-and-attendance-policy': {
      title: 'Политика посещения занятий',
      description: 'Академическое превосходство и высокие достижения возможны только в среде, где поддерживаются самые высокие стандарты академической честности и порядочности: студенты SDU должны убедиться, что они придерживаются этого требования. Активное участие является неотъемлемой частью преподавания и обучения в СДУ. Поэтому от всех обучающихся требуется регулярное посещение занятий, и для каждого предмета ведется учет любых пропусков. Процент посещения занятий должен быть не ниже 70%.'
    },

    'course-specific-policy': {
      title: 'Дополнительные правила преподавателя'
    }
  },

  advanced: {
    title: 'Оценивание и нагрузка',
    'student-workload': 'Учебная нагрузка студента',
    assessment: 'Оценка',
    'reading-list': 'Список чтения',
    'teaching-methods': 'Методы преподавания'
  },

  content: {
    title: 'Содержание курса',
    'academic-skills': 'Академические навыки',
    'subject-specific-skills': 'Предметные навыки',
    'weekly-course-plan': 'План курса на неделю',
    'course-learning-outcomes': 'Результаты обучения на курсе'
  },

  information: {
    title: 'Информация о курсе',
    instructors: 'Инструктор(ы)',
    hybrid: 'Гибрид',
    online: 'Онлайн',
    on_campus: 'В кампусе',
    requisites: 'Реквизиты',
    'course-description': 'Описание курса',
    'mode-of-delivery': 'Способ(ы) доставки',
    'programs-on-available': 'Программы, в которых доступен курс',
    'course-information': 'Общая информация'
  },

  'table-fields': {
    status: 'Статус',
    authority: 'Ответственное лицо',
    activity: 'Выполняемая работа',
    quantity: 'Количество',
    hours: 'Количество часов',
    assessment: 'Оценка',
    description: 'Описание',
    percentage: 'Процент (%)',
    title: 'Название',
    type: 'Тип',
    author: 'Автор',
    isbn: 'ISBN',
    published: 'Опубликовано (год)',
    publisher: 'Издатель/Веб-ссылка',
    no: 'Нет',
    week: 'Неделя',
    topics: 'Темы',
    degree: 'Степень',
    email: 'Электронная почта',
    contacts: 'Контакты',
    department: 'Отдел',
    faculty: 'Факультет',
    language: 'Язык',
    credits: 'Кредиты',
    grading: 'Оценка',
    'last-modified': 'Последнее изменение',
    'active-verb': 'Активный глагол',
    'what-will-be-done-produced': 'Что будет сделано/произведено',
    'how-this-learning-outcome-will-be-achieved': 'Как будет достигнут этот-результат обучения',
    'full-name': 'Полное имя',
    'program-code': 'Программный код',
    'program-title': 'Название программы',
    'course-title': 'Название курса',
    'concent-status': 'Статус концентрации',
    'learning-outcomes': 'Результаты обучения',
    'year-term': 'Год (срок)',
    'course-code': 'Код курса (эквивалентный код)',
    ects: 'ECTS',
    lab: 'Лаборатория',
    practice: 'Практика',
    quota: 'Кредиты',
    theory: 'Теория'
  },

  selector: 'Версия силлабуса'
}

const messages = {
  'en-EN': enMessages,
  'kz-KZ': kzMessages,
  'ru-RU': ruMessages
}

export default messages
