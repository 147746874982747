import { useRequests } from '@/shared/composable'

import type {
  AcademicSingleCourseAttendancesByCoursePutRequest,
  UnknownResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: Array<AcademicSingleCourseAttendancesByCoursePutRequest>): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<Array<AcademicSingleCourseAttendancesByCoursePutRequest>, UnknownResponse>({
    url: 'academic/courses/attendances/by-courses',
    body: params,
    loader: 'academic/courses/attendances/by-courses'
  })

  return response
}
