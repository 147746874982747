<template>
  <div class="col-12 mb-2">
    <div class="d-flex justify-content-end">
      <div class="d-flex align-items-center">
        <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
          {{ $t('common.form-fields.section') }}:
        </span>
        <select
          v-model="selected"
          class="form-select form-select-md w-200px"
          @change="handleChange()"
        >
          <template
            v-for="(section, index) in sections"
            :key="index"
          >
            <option :value="section.section_id">
              {{ section.section }}
            </option>
          </template>
        </select>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch, ref, onMounted, computed } from 'vue'
import { useRouter, LocationQueryRaw } from 'vue-router'
import { sensitiveObject } from '@/shared/utils'
import { useResource } from '@shared/stores'

import { useSingleCourse } from '../store'
import { RouteParams } from '../types'

const props = defineProps({
  page: {
    type: String,
    required: true
  }
})

const router = useRouter()
const store = useSingleCourse()
const resource = useResource()

const { username } = sensitiveObject()

const sections = computed(() => {
  const course = resource.getAcademicSingleCoursesResource
  if (!course) return []

  const myDetails = course.instructors.find((inst) => inst.username === username)

  if (myDetails) {
    const { sections: mySections } = myDetails
    return course.sections.filter((section) => mySections.includes(section.section_id))
  }

  return []
})

const course = computed(() => store.getCourse)
const selected = ref<number>(-1)

const handleChange = () => {
  const { year, term, code } = course.value as RouteParams
  const section = sections.value.find((section) => section.section_id === selected.value)

  if (!section) return
  store.setCourse({ year, term, code, section: section.section })

  const query : LocationQueryRaw = {}

  query.section = section.section
  query.year = year
  query.term = term
  query.code = code

  store.setCourse({ year, term, code, section: section.section })
  router.push({
    path: `/academic/course/${props.page}`,
    query
  })

  setSection()
}

const getSectionId = (section: string | undefined) => {
  if (!section && sections.value.length > 0) return sections.value[0].section_id

  const sectionObj = sections.value.find((sectionObj) => sectionObj.section.includes(String(section)))
  return sectionObj?.section_id ?? -1
}

const setSection = () => {
  const sectionId = getSectionId(course.value?.section)
  selected.value = sectionId
  store.setCurrentSection(sectionId)
}

watch(() => sections.value,
  () => {
    setSection()
  }
)

onMounted(() => {
  setSection()
})
</script>
