<template>
  <pms-modal
    name="my-qr"
    :title="'domains.student.dashboard.actions.my-qr-title'"
    :description="''"
    :divider="false"
    :fullscreen="true"
  >
    <canvas
      id="qr-canvas"
    />
    <div class="text-center">
      <p class="fs-2hx fw-bold m-0">
        {{ `${currentUser.name} ${currentUser.surname}` }}
      </p>
      <p class="fs-1 m-0">
        {{ currentUser.student_id }}
      </p>
      <p class="fs-3 text-muted">
        {{ $t('domains.student.dashboard.actions.my-qr-expire') }}: {{ expireDate }}
      </p>
    </div>
    <pms-card
      :header="false"
      class="shadow-lg"
    >
      <p class="text-muted">
        {{ $t('domains.student.dashboard.actions.my-qr-description') }}
      </p>
    </pms-card>
  </pms-modal>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useStudent } from '@shared/stores'

import qrCode from 'qrcode'
import moment from 'moment/moment'

const user = useStudent()
const currentUser = computed(() => user.currentUser)
const expireDate = ref(null)

const regenerate = () => {
  expireDate.value = moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss')
  const canvas = document.getElementById('qr-canvas')
  const qrText = JSON.stringify({
    student_id: currentUser.value.student_id,
    expires_in: expireDate.value
  })

  qrCode.toCanvas(canvas, qrText)
}

onMounted(() => {
  regenerate()
  setInterval(() => {
    regenerate()
  }, 5000)
})

onUnmounted(() => {
  clearInterval()
})

</script>
<style lang="scss" scoped>
#qr-canvas {
  width: 300px !important;
  height: 300px !important;
  margin-left: 15px;
}
</style>
