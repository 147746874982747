/* eslint-disable @typescript-eslint/no-explicit-any */
type Func = (...args: any[]) => unknown

export function debounce <
  T extends Func
> (func: T, timeout = 300) {
  let idTimeout: number | undefined

  return function (...args: Parameters<T>) {
    clearTimeout(idTimeout)
    idTimeout = window.setTimeout(func, timeout, ...args)
  }
}
