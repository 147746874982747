import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'LayoutRow',
  setup () {
    const { t } = useI18n()
    return () => (
      <div class="text-center fs-4 mt-12 mb-12 pt-12 pt-md-0 pb-12 pb-md-0">
        { t('common.no-data') }
      </div>
    )
  }
})
