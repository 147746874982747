<template>
  <div v-if="props.course">
    <div class="table-responsive">
      <table class="table table-row-dashed">
        <tbody>
          <template
            v-for="field in ['course-code', 'equ-code', 'title', 'credit']"
            :key="field"
          >
            <tr style="vertical-align: middle;">
              <td class="text-start w-100px">
                <span
                  class="text-gray-800 d-block fs-5"
                >
                  {{ $t(`domains.academic.single-course.overview.${field}`) }}:
                </span>
              </td>

              <td class="text-start pe-5">
                <span class="text-gray-800 fw-bold d-block fs-4">
                  <template v-if="field === 'course-code'">
                    {{ props.course.code }}
                  </template>
                  <template v-else-if="field === 'equ-code'">
                    {{ props.course.equicode }}
                  </template>
                  <template v-else-if="field === 'title'">
                    {{ props.course.title }}
                  </template>
                  <template v-else-if="field === 'credit'">
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="credit in ['theory', 'practice', 'ects']"
                          :key="credit"
                        >
                          <td class="w-50px">
                            <span class="text-gray-800 d-block fw-normal fs-6">
                              {{ $t(`domains.academic.single-course.overview.${credit}`) }}
                            </span>
                          </td>
                          <td class="">
                            <span class="text-gray-800 fw-bold fs-6 d-block">
                              {{ props.course[credit] }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionManagementRequestCourseGetResponse } from '@shared/swagger'

const props = defineProps<{
  course?: SectionManagementRequestCourseGetResponse[0];
}>()
</script>
