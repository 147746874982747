import { StoreType } from '../types'

import { destroyAcademicManageCourseRequisite, getAcademicCourses, getAcademicManageCourseRequisite, postAcademicManageCourseRequisite } from '@/shared/api'
import { AcademicManageCoursesRequisitesGetRequest, AcademicCoursesRequest, AcademicManageCoursesRequisitesPostRequest, AcademicManageCoursesRequisitesDeleteRequest } from '@/shared/swagger'
export async function fetch_requisite_info (this: StoreType, request:AcademicManageCoursesRequisitesGetRequest) {
  const { ok, message, data } = await getAcademicManageCourseRequisite(request)

  if (ok) {
    this.setRequisiteInfoResponse(data)
  }

  return {
    status: ok,
    message
  }
}
export async function search_requisite_courses (this:StoreType, request:AcademicCoursesRequest) {
  const { ok, message, data } = await getAcademicCourses(request)

  if (ok) {
    this.setRequisiteCoursesSearchResponse(data.courses)
  }

  return {
    status: ok,
    message
  }
}

export async function manage_requisite (this:StoreType, request:AcademicManageCoursesRequisitesPostRequest) {
  const { ok, message } = await postAcademicManageCourseRequisite(request)

  return {
    status: ok,
    message
  }
}

export async function delete_requisite (this:StoreType, request:AcademicManageCoursesRequisitesDeleteRequest) {
  const { ok, message } = await destroyAcademicManageCourseRequisite(request)

  return {
    status: ok,
    message
  }
}
