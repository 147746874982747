<template>
  <pms-card
    v-if="employees !== null"
    :header="false"
  >
    <action-buttons />
    <pms-smart-table
      :items="employees ?? []"
      :headers="headers"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #fullName="{ row }">
        <span>{{ row.name }} {{ row.surname }}</span>
      </template>
      <template #departmentPosition="{ row }">
        <div>
          <p
            v-for="(depPosition, idx) in row.department_positions"
            :key="idx"
            class="mb-0 fs-6"
            :class="{ 'fw-bold': depPosition.is_main }"
          >
            {{ depPosition.title }}
          </p>
        </div>
      </template>
      <template #staffStatus="{ item }">
        <span
          class="badge fs-6"
          :class="{
            'badge-light-primary': employeeStatus(item.staff_status),
            'badge-light-warning': !employeeStatus(item.staff_status)
          }"
        >
          {{ item.staff_status }}
        </span>
      </template>
      <template #outerEmail="{ item }">
        <div class="d-flex justify-content-between align-items-center">
          <a
            :href="`mailto:${item.outer_email}`"
            class="btn btn-link"
          > {{ item.outer_email }} </a>
        </div>
      </template>
      <template #innerPhone="{ item }">
        <div class="d-flex justify-content-between align-items-center">
          {{ item.inner_phone }}
        </div>
      </template>
      <template #editEmailPhone="{item}">
        <pms-button
          class="p-2 px-4"
          :variant="'outlined'"
          data-bs-toggle="tooltip"
          data-kt-initialized="1"
          :data-bs-original-title="$t('domains.administrativestaff.edit_email_phone_toggle')"
          @Click="onClickEdit(item.id)"
        >
          <i class="bi bi-pencil p-0 pb-1" />
        </pms-button>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
  <EditPhoneEmailForm v-if="modifier && modifier.name==='email-phone-modal'" />
  <pms-teleport-modal
    :name="'email-phone-modal'"
    :teleport-name="'email-phone-modal-content'"
    :title="$t('domains.administrative.staff.title')"
    :description="$t('domains.administrative.staff.description')"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script setup lang="ts">
import ActionButtons from './ActionButtons.vue'
import { headers } from '../values'
import { useStaffList } from '../store'
import EditPhoneEmailForm from './EditPhoneEmailForm.vue'
import { storeToRefs } from 'pinia'

import { permissionCodes } from '@/shared/values'
import { computed, watch } from 'vue'
import { useUser } from '@/shared/stores'
import { useTeleportModal } from '@/shared/composable'

const store = useStaffList()
const staffState = storeToRefs(store)
const employees = staffState.employees
const { modifier, open, setModifier } = useTeleportModal()

const permission = computed(() => useUser().hasPermissionByModuleCode(permissionCodes.edit_inner_phone_staff_list.code))

watch(() => permission.value, () => {
  if (headers) {
    const headerIndex = headers.findIndex(header => header.slotCellName === 'editEmailPhone')
    if (permission.value && headerIndex < 0) {
      headers.push({
        title: 'common.actions',
        field: 'innerPhone',
        slotCellName: 'editEmailPhone'
      })
    } else if (!permission.value && headerIndex > 0) {
      delete headers[headerIndex]
    }
  }
}, {
  immediate: true
})

const onClickEdit = async (id:number) => {
  setModifier('email-phone-modal',
    { emp_id: id }
  )
  open({ name: 'email-phone-modal' })
}
const employeeStatus = (status: string): boolean => status.toLowerCase().startsWith('working')
</script>
