
<template>
  <pms-row>
    <pms-grid :col="12">
      <div class="d-flex justify-content-between">
        <h1 class="d-flex align-items-center flex-row">
          <template v-if="session">
            <span class="fs-2x me-2">{{ session.day }} - {{ session.time }}</span>
            <pms-badge
              :value="session.room.title"
              class="pb-2 mt-1 fs-4"
            />
          </template>
          <template v-else>
            {{ $t('common.students') }}
          </template>
        </h1>
        <qr-filters />
      </div>
      <div class="d-flex flex-wrap mt-6">
        <template
          v-for="(student, index) in computedStudents"
          :key="student.id"
        >
          <qr-single-student
            :student="student"
            :index="index"
          />
        </template>
      </div>
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useQr } from '../../../composable'

import QrFilters from './QrFilters.vue'
import QrSingleStudent from './QrSingleStudent.vue'

const { filter, search, session, students } = useQr()

const computedStudents = computed(() => {
  let studentsToReturn = [...students.value]

  if (search.value !== '') {
    studentsToReturn = studentsToReturn.filter((student) => {
      const studentID = student.id.toString().toLowerCase()
      const studentFullName = student.full_name.toLowerCase()

      return (
        studentID.includes(search.value.toLowerCase()) ||
        studentFullName.includes(search.value.toLowerCase())
      )
    })
  }

  if (filter.value !== 'all') {
    studentsToReturn = studentsToReturn.filter((student) => {
      if (student.schedule) {
        if (filter.value === 'present') {
          return student.schedule.attend_id === 1
        }

        if (filter.value === 'absent') {
          return student.schedule.attend_id === 0
        }
      }

      return false
    })
  }

  return studentsToReturn
})
</script>
