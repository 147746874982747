import type { StoreType } from '../types'
import type { AcademicInformationResourcesResponse } from '@shared/swagger'

export default function getAcademicInformationResourceSchedule (this: StoreType): AcademicInformationResourcesResponse['schedule'] {
  return this.academicInformationResource?.schedule || {
    buildings: [],
    rooms: [],
    specialities: []
  }
}
