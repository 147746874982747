import type { StoreType } from '../types'
import type { AdministrativeMessageBoardPostsGetRequest, AdministrativeMessageBoardPostsGetResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { getAdministrativeMessageBoardPosts } from '@shared/api'
import { usePagination } from '@shared/composable'

export default async function fetchPosts (this: StoreType, values?: AdministrativeMessageBoardPostsGetRequest, loader?: string): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction, perPage, currentPage } = usePagination()

  if (!values) values = { per_page: perPage.value, offset: currentPage.value, search: '', my_posts: this.currentPageView === 'my-posts' ? 1 : 0 }

  const setData = (data: AdministrativeMessageBoardPostsGetResponse): void => {
    this.setPosts(data.posts || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAdministrativeMessageBoardPosts({
      search: values?.search ? values.search : '',
      my_posts: this.currentPageView === 'my-posts' ? 1 : 0,
      per_page: perPage,
      offset: currentPage
    })

    if (ok) setData(data)
  })

  const { ok, message, data } = await getAdministrativeMessageBoardPosts(values, loader)

  if (ok) setData(data)
  return {
    status: ok,
    message
  }
}
