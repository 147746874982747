import { FormCustomValidatorFunction } from '@shared/types'

const customValidator: FormCustomValidatorFunction = (inputs) => {
  function hasUndefinedValues (array: Array<Record<string, unknown>>): boolean {
    for (const obj of array) {
      for (const key in obj) {
        if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
          return true
        }
      }
    }
    return false
  }
  return !hasUndefinedValues(inputs)
}

export default customValidator
