/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { App } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'

declare global {
  interface Window {
    $: any;
  }
}

const runner = (vue: App<Element>): void => {
  window?.$('#app')?.tooltip({ selector: '[data-bs-toggle="tooltip"]' })
  VueCookieNext.config({ expire: '7d' })
}

export default {
  run: runner,
  priority: 98
}
