<template>
  <pms-container class="mt-10">
    <pms-row>
      <pms-grid :col="12">
        <h2>Translate Messages</h2>
      </pms-grid>
    </pms-row>
    <pms-grid
      :col="12"
      class="mt-6"
    >
      <pms-smart-list
        :items="paths"
        :divider="false"
        :nestable="false"
      >
        <template #item="{ item }">
          <div class="d-flex flex-stack">
            <span class="fs-3 me-4"> - </span>
            <span class="text-primary fw-semibold fs-1">
              {{ item }}
            </span>
            <button
              type="button"
              class="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end"
              @click="goTo(item)"
            >
              <pms-kt-icon
                icon-name="exit-right-corner"
                class="fs-2"
              />
            </button>
          </div>
        </template>
      </pms-smart-list>
    </pms-grid>
  </pms-container>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { useStore } from './store'

const store = useStore()
const router = useRouter()
const paths = computed(() => store.getPaths)

const goTo = (item: string) => {
  router.push({ path: `/translate/messages/${item}` })
}
</script>
