import { AcademicSingleStudentCurriculumGetResponse } from '@shared/swagger'

const convertElectiveToCourse = (elective: AcademicSingleStudentCurriculumGetResponse['elective_sections']['area'][0]):
(AcademicSingleStudentCurriculumGetResponse['groups'][0])['items'][0] => ({
  course_code: elective.code,
  title: elective.title,
  theory: elective.theory,
  practice: elective.practice,
  credits: parseInt(elective.credits),
  ects: elective.ects,
  requisite: '',
  term: elective.term,
  course_type: elective.elective_type,
  elective_type: elective.elective_type,
  details: elective.details.map(d => ({
    course_status: 1,
    grade: d.grade,
    letter_grade: d.letter_grade,
    registration_date: new Date().toDateString(),
    course_code: d.course_code
  })),
  references: [],
  requisites: []
})

// Function to process electives
export default (
  term: number,
  electives: AcademicSingleStudentCurriculumGetResponse['elective_sections']
): AcademicSingleStudentCurriculumGetResponse['groups'][0]['items'] => {
  // If term is -1, collect all electives as courses
  if (term === -1) {
    return [
      ...electives.area,
      ...electives.non_area,
      ...electives.non_theoretical,
      ...electives.requisite_courses
    ].map(elective => convertElectiveToCourse(elective))
  }

  // Filter electives based on the specified term, convert them to courses, and sort by course_code
  return [
    ...electives.area,
    ...electives.non_area,
    ...electives.non_theoretical,
    ...electives.requisite_courses
  ]
    .filter(elective => elective.term === term)
    .map(elective => convertElectiveToCourse(elective))
    .sort((a, b) => a.course_code.localeCompare(b.course_code))
}
