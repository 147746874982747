import { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.student-id',
    field: 'id',
    slotCellName: 'id',
    sortable: true
  },
  {
    title: 'common.table.speciality',
    field: 'department_id',
    slotCellName: 'speciality'
  },
  {
    title: 'common.table.sections',
    field: 'enrolled_sections',
    slotCellName: 'sections'
  }
]
