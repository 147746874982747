import { useRequests } from '@/shared/composable'

import type {
  UnknownResponse,
  AcademicSingleCourseAttendanceRelocatesPutRequest
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicSingleCourseAttendanceRelocatesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicSingleCourseAttendanceRelocatesPutRequest, UnknownResponse>({
    url: 'academic/courses/attendances/relocates',
    body: params,
    loader: 'academic/courses/attendances/relocates'
  })

  return response
}
