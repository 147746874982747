import type { AcademicSingleCourseAttendancesGetResponse, AcademicSingleCourseAttendancesResourcesResponse } from '@/shared/swagger'
import type { StudentWithSchedule } from '../types/student'

export default (resource: AcademicSingleCourseAttendancesResourcesResponse | null, students: AcademicSingleCourseAttendancesGetResponse): Array<StudentWithSchedule> => {
  if (resource === null) return []

  return resource.students.map((item) => {
    return {
      ...item,
      schedule: students.filter((schedule) => schedule.student_id === `${item.id}`)
    }
  }) as Array<StudentWithSchedule>
}
