<template>
  <h3 class="card-title align-items-start flex-column mb-4">
    <span class="card-label fw-bold text-dark">{{ $t('domains.knowledge.search.title') }}</span>
    <span
      class="text-gray-400 mt-1 fw-semibold fs-6 ps-3"
    >{{ $t('domains.knowledge.search.search-total', { total: total }) }}</span>
  </h3>
</template>

<script setup lang="ts">
import { usePagination } from '@shared/composable'
const { total } = usePagination()
</script>
