<template>
  <pms-grid :col="12">
    <div class="d-flex flex-stack mb-4">
      <span class="text-dark fw-bold fs-2">
        {{ $t('domains.profile.overview.title') }}
      </span>
    </div>

    <pms-card :header="false">
      <div class="d-flex justify-content-end pt-2 pb-2">
        <pms-button @click="onPrint">
          <CV
            id="printCV"
            class="d-none"
          />
          {{ $t('domains.profile.overview.personal.export_cv') }}
        </pms-button>
      </div>
      <pms-alert :title="'domains.profile.overview.alert.title'">
        <span class="mt-3 fs-6">
          {{ $t('domains.profile.overview.alert.description') }}
        </span>
      </pms-alert>

      <pms-row class="mt-8">
        <pms-grid
          id="cv-progress"
          :col="12"
          :class="{ 'mb-10': info && cv }"
        >
          <div
            v-if="cv"
            class="d-flex text-muted fw-bold fs-5 mb-3"
          >
            <span class="flex-grow-1 text-gray-800">
              {{ $t('domains.profile.overview.personal.cv-progress.title') }}
            </span>
            <span class="text-gray-800">
              {{ $t('domains.profile.overview.personal.cv-progress.description', { progress: cv.progress }) }}
            </span>
          </div>
          <pms-progress
            :height="'8px'"
            :width="'100%'"
            :percentage="cv.progress"
            :bg-color="'light-primary'"
            :percentage-color="'primary'"
            :progress-class="'mb-2'"
          />
          <div class="fs-6 text-gray-600 fw-semibold">
            <span class="fw-bold">
              {{ $t('domains.profile.overview.personal.cv-progress.gain-points') }}:
            </span>
            <template
              v-for="(item, index) in cv.empty"
              :key="index"
            >
              {{ $t(`domains.profile.overview.personal.cv-progress.tags.${item}`) }}
              {{ index === cv.empty.length - 1 ? '' : ', ' }}
            </template>
          </div>
        </pms-grid>
      </pms-row>

      <pms-row
        v-if="info"
        id="profile-information"
      >
        <pms-grid :col="12">
          <h3>{{ $t('domains.profile.overview.personal.title-info') }}</h3>
        </pms-grid>
        <overview-table />
      </pms-row>
    </pms-card>
  </pms-grid>
  <overview-cards />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProfile } from '../../../store'
import { exportToPDF } from '../utils'
import OverviewTable from '../ui/OverviewTable.vue'
import OverviewCards from '../ui/OverviewCards.vue'
import CV from './CV.vue'

const store = useProfile()

const info = computed(() => store.getOverviewInfo)
const cv = computed(() => store.getOverviewCv)

const onPrint = () => {
  const element = document.getElementById('printCV')

  if (!element) return
  exportToPDF(element)
}
</script>
