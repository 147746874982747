import { createPage } from '@shared-utils/storybook'

import SchedulePage from './../SchedulePage.vue'
import { useAcademicSchedule } from '../store'

const Template = createPage({
  layout: 'default',
  component: SchedulePage,
  setup () {
    const store = useAcademicSchedule()

    store.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Schedule',
  component: SchedulePage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
