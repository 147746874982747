<template>
  <div
    class="card card-flush shadow-sm"
  >
    <div class="card-header align-items-center pt-5">
      <post-item-header
        :post="post"
        :toggle-show="toggleExpand"
        :expand="expand"
      />
    </div>

    <div
      class="card-body py-4"
      @click="toggleExpand"
    >
      <h2
        class="mb-3"
      >
        {{ post.title }}
      </h2>

      <div
        v-if="expand"
        class="fs-5 fw-normal text-gray-700 mt-6 contentDiv"
        v-html="formatContent(post.content)"
      />
    </div>

    <div class="card-footer pt-0">
      <post-item-footer
        :post="post"
        :expand="expand"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, PropType } from 'vue'

import PostItemHeader from './PostItemHeader.vue'
import PostItemFooter from '@domains/AdministrativeMessageBoard/ui/PostItemFooter.vue'

import { AdministrativeMessageBoardPostsGetResponse } from '@shared/swagger'

import { formatContent } from '../utils'
import { useMessageBoard } from '../store'

const props = defineProps({
  post: {
    type: Object as PropType<AdministrativeMessageBoardPostsGetResponse['posts'][0]>,
    required: true
  }
})

const expand = ref<boolean>(false)
const store = useMessageBoard()

function toggleExpand () {
  expand.value = !expand.value
  if (expand.value) {
    store.viewPost(props.post.id)
  }
}

</script>

<style scoped>
textarea {
  overflow: hidden;
  resize: none;
}

img {
  max-width: 100% !important;
}
</style>
