import { tempValues } from '../../values'
import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { deleteRulesAndRegulations } from '@/shared/api'

export default async function deleteExistingRule (this: StoreType, ruleId: string): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteRulesAndRegulations(ruleId)

  ok && await this.fetchRules({ ...tempValues.value })

  return {
    status: ok,
    message: message
  }
}
