import type { StoreType } from '../types'
import type { ResourceRequest } from '@shared/swagger'

import { getServiceManageLockResource } from '@shared/api'

export default async function fetchServiceManageLockResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getServiceManageLockResource(body)

  if (ok) {
    this.setServiceManageLockResource(data)
  }
}
