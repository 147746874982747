import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AdministrativeStaffsWorkloadResourcesResponse, UnknownRequest } from '@shared/swagger'

export default async function getAdministrativeStaffsWorkloadResources (): Promise<AxiosResponse<AdministrativeStaffsWorkloadResourcesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeStaffsWorkloadResourcesResponse>({
    url: 'administrative/staffs/workload/resources',
    body: { },
    loader: 'administrative/staffs/workload/resources'
  })

  return response
}
