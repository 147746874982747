<template>
  <div
    id="fixed-menu"
    class="card shadow"
  >
    <div class="card-body p-4 align-items-center">
      <span class="fw-bold fs-5 pe-0">{{ $t('common.action') }}:</span>
      <pms-button
        :text="'common.set-all'"
        class="ms-2 py-2 px-4"
        @click="setAction()"
      />
    </div>
    <ModalExamDate v-if="modifier && modifier.name === 'exam-date-modal'" />
  </div>
</template>

<script setup lang="ts">
import { useTeleportModal } from '@shared/composable'
import { selectedCourses } from '../values'
import ModalExamDate from './ModalExamDate.vue'

const { setModifier, open, modifier } = useTeleportModal()

const setAction = () => {
  setModifier('exam-date-modal',
    { courses: selectedCourses }
  )
  open({ name: 'exam-date-modal' })
}

</script>

<style scoped lang="scss">
#fixed-menu {
  position: fixed;
  height: 60px;
  width: inherit;
  bottom: 0px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
