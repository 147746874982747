<template>
  <div class="d-flex justify-content-between mb-4">
    <h1 class="pt-2">
      {{ $t('domains.academic.single-course.schedule.title') }}
    </h1>
    <div
      id="single-course-schedule-actions"
    >
      <div class="d-flex">
        <div class="d-flex flex-row align-center me-3">
          <span class="mt-3 fs-6 me-3">{{ $t('common.filter') }}:</span>
          <select
            v-model="filter"
            class="form-select w-175px"
          >
            <option value="all">
              {{ $t('common.all') }}
            </option>
            <template
              v-for="item in filterOptions"
              :key="item"
            >
              <option :value="item">
                {{ item }}
              </option>
            </template>
          </select>
        </div>
        <pms-dropdown
          :placement="'end'"
          :content-box-class="'pt-1'"
          :menu-item-class="'pb-1 px-6'"
          :items="dropdownItems"
        >
          <template #header>
            <p class="fw-bold fs-5 mb-0 mt-3">
              {{ $t('domains.academic.my-schedule.export') }}:
            </p>
          </template>
          <template #button>
            <span class="w-150px">
              <pms-button
                :text="$t('domains.academic.my-schedule.export-btn')"
              />
            </span>
          </template>
          <template #menuWithSlot>
            <div class="alert alert-primary d-flex align-items-center mb-2 w-250px">
              <div class="d-flex flex-column">
                <span>{{ $t('phrases.link-to-need-help') }}</span>
              </div>
            </div>
          </template>
        </pms-dropdown>
      </div>
    </div>
  </div>
  <individual-schedule-table
    id="single-course-schedule"
    class="mt-1"
    :schedule-records="scheduleRecords"
  />
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { dropdownItems } from '../values'
import { useSingleCourse } from '../../../store'

import IndividualScheduleTable from './IndividualScheduleTable.vue'

const store = useSingleCourse()
const filter = ref('all')
const filterOptions = computed(() => store.getScheduleRecords.reduce((acc: Array<string>, item) => {
  if (!acc.includes(item.course_instructor)) {
    acc.push(item.course_instructor)
  }
  return acc
}, []))

const scheduleRecords = computed(() => {
  return store.getScheduleRecords.filter((item) => {
    if (filter.value === 'all') return true
    return item.course_instructor === filter.value
  })
})
</script>
