<template>
  <div>
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :color="'danger'"
            :text="'common.reject'"
          />
        </div>
      </template>
    </pms-smart-form>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { FormField } from '@shared/types'
import { useI18n } from 'vue-i18n'
import { useReceivedRequest } from '../store'
import { useAlert } from '@shared/composable'
import { useSectionManagement } from '@domains/SectionManagement/store'

const { t } = useI18n()
const { dialog, error, success } = useAlert()

const store = useSectionManagement()
const receivedRequestStore = useReceivedRequest()
const tempValues = ref(
  {
    reject_message: ''
  }
)

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('phrase.section-management.reject-message'),
      class: 'mb-1'
    },
    name: 'reject_message',
    type: 'textarea',
    value: tempValues.value.reject_message
  }
])

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async () => {
  dialog({
    title: 'domains.academic.manage.sections.reject.are-you-sure',
    text: t('domains.academic.manage.sections.reject.text'),
    onConfirm: async () => {
      const { status, message } = await receivedRequestStore.rejectRequest(
        receivedRequestStore.selected_request_id,
        {
          status: 3,
          reject_message: tempValues.value.reject_message
        }
      )
      if (status) {
        success({ text: message, isToast: true })
        await receivedRequestStore.fetchReceivedRequests(
          {
            type: 'received',
            course_code: receivedRequestStore.selectedCourse?.code ?? '',
            course_year: receivedRequestStore.selectedCourse?.year ?? -1,
            term: store.currentYearTerm.term
          }
        )
      } else {
        error({ text: message })
      }
    }
  })
}
</script>
