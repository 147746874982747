<template>
  <pms-page-shimmer
    v-if="searchLoader"
    :shimmer="`table`"
    :full-width="true"
  />
  <pms-row v-else>
    <pms-grid
      :col="12"
      class="mb-4"
    >
      <pms-card
        :options="{
          body: {
            class: 'mt-0 pt-0'
          }
        }"
      >
        <template #header>
          <pms-tab
            v-model:active="category_id"
            class="mt-5 mb-0"
            :tabs="tabMenu"
            :icon="false"
            :all="false"
          />
        </template>
        <template #toolbar>
          <rule-search />
        </template>
        <div
          v-if="!rules.length && !searchLoader"
          class="text-center fs-4 mt-12 mb-12"
        >
          {{ $t('common.no-data') }}
        </div>
        <div
          v-else
          id="rulesAccordion"
          class="accordion"
        >
          <div
            v-for="rule in rules"
            :key="rule.id"
            class="accordion-item border-top-0 border-end-0 border-start-0"
          >
            <button
              :id="`heading${rule.id}`"
              class="accordion-header accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse${rule.id}`"
              :aria-controls="`collapse${rule.id}`"
            >
              <span class="fw-bold">
                {{ rule.title }}
              </span>
            </button>

            <div
              :id="`collapse${rule.id}`"
              class="accordion-collapse collapse"
              :aria-labelledby="`heading${rule.id}`"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <single-rule :rule="rule" />
              </div>
            </div>
          </div>
        </div>
      </pms-card>
    </pms-grid>
  </pms-row>
</template>

<script setup lang="ts">
import { useLoader } from '@shared/composable'

import { useRulesAndRegulations } from './../store'
import { setTempValues, tempValues } from './../values'
import { storeToRefs } from 'pinia'

import RuleSearch from './RuleSearch.vue'
import SingleRule from './SingleRule.vue'
import { computed, watch, ref } from 'vue'

const { isActive } = useLoader()
const store = useRulesAndRegulations()
const { rules, categories } = storeToRefs(store)
const searchLoader = isActive('academic/information/rules')

const category_id = ref<number>(tempValues.value.category_id)

const tabMenu = computed(() => categories.value.map(c => ({ id: c.id, label: c.title })))

watch(() => tempValues.value, async (newValue, oldValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    await store.fetchRules({ ...tempValues.value })
  }
}, { deep: true })

watch(() => category_id.value, () => setTempValues({ category_id: category_id.value }))

</script>

<style lang="scss" scoped>
.accordion-item{
  &:last-child{
    border: none;
  }
}
</style>
