<template>
  <form
    @submit.prevent="signDocument"
  >
    <div class="flex align-items-center gap-3 mb-3">
      <label
        for="username"
        class="font-semibold w-6rem required"
      >{{ $t('domains.service.esign.upload_certificate') }}</label>
      <input
        required
        class="form-control flex-auto"
        type="file"
        @change="onFileChange"
      >
    </div>
    <div class="flex align-items-center gap-3 mb-5">
      <label
        for="email"
        class="font-semibold w-6rem required"
      >{{ $t('common.table.password') }}:</label>
      <input
        v-model="sign_document_request.password"
        type="password"
        class="form-control flex-auto"
        required
      >
    </div>
    <div class="d-flex justify-content-between">
      <button
        type="submit"
        class="btn btn-success"
        :disabled="signLoading"
      >
        {{ signLoading ? $t('common.loading') : $t('domains.service.esign.sign') }}
      </button>
      <button
        class="btn btn-secondary"
        type="button"
        @click="closeSignModal"
      >
        {{ $t('common.cancel') }}
      </button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { useLoader } from '@/shared/composable'
import { useSearchList, sign_document_request } from '../composable'

const { isActive } = useLoader()

const { signDocument, closeSignModal, onFileChange } = useSearchList()
const signLoading = isActive('service/manage/esigns')
</script>
