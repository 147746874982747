import { notify, NotificationsOptions } from '@kyvg/vue3-notification'
import { isTranslatable, useShadow } from '@shared/utils'

const useNotify = (options: NotificationsOptions) => {
  const vue = useShadow()

  const t = vue.config.globalProperties.$t

  if (options.title && isTranslatable(options.title)) options.title = t(options.title)
  if (options.text && isTranslatable(options.text)) options.text = t(options.text)
  if (options.duration === undefined) options.duration = 5_000

  notify(options)
}

export default useNotify
