<template>
  <pms-card
    :title="`${t('domains.academic.curricula.track-info-card')}`"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <div
      v-else
      class="mb-5"
    >
      <h1>{{ `${t('common.department')}: ` + track?.program.department.title }}</h1>

      <span class="fs-1 fw-1">
        <span class="fw-bold">{{ `${t('domains.academic.curricula.program')}: ` }}</span>{{ track?.program.code }}
      </span>
      <br>
      <span class="fs-2">
        <span class="fw-bold">{{ `${t('domains.academic.curricula.track')}: ` }}</span>{{ track?.title }}
      </span>
      <br>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '@/domains/AcademicCurricula/store'
import { useQueryHelper } from '../../AddCourseToTrackPage/composable/useQueryHelper'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'
const { t } = useI18n()

const store = useStore()
const queryHelper = useQueryHelper()
const { track } = storeToRefs(store)
const { isActive } = useLoader()
const loader = isActive('academic/manage/curriculum/tracks/:track')

onMounted(() => {
  if (queryHelper.params.trackId) {
    store.findTrack({ track: queryHelper.params.trackId })
  }
})
</script>
