import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicCoursesConsentRequestsGetRequest,
  AcademicCoursesConsentRequestsGetResponse
} from '@shared/swagger'

export default async function getAcademicCoursesConsentRequests (params: AcademicCoursesConsentRequestsGetRequest): Promise<AxiosResponse<AcademicCoursesConsentRequestsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicCoursesConsentRequestsGetResponse>({
    url: 'academic/courses/consent-requests',
    body: { params: params },
    loader: 'academic/courses/consent-requests'
  })

  return response
}
