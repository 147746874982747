import type { StoreType } from '../types'
import type { ServiceMessageBroadcastGetRequest, ServiceMessageBroadcastGetResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { getServiceMessageBroadcast } from '@shared/api'
import { usePagination } from '@shared/composable'

export default async function fetchEmployees (this: StoreType, type: string): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction, perPage, currentPage } = usePagination()

  const values: ServiceMessageBroadcastGetRequest = {
    device: 'web:desktop',
    type: type,
    per_page: perPage.value,
    offset: currentPage.value
  }

  const setData = (data: ServiceMessageBroadcastGetResponse): void => {
    this.setMessages(data.messages || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getServiceMessageBroadcast({ ...values, per_page: perPage, offset: currentPage })

    if (ok) setData(data)
  })

  const { ok, message, data } = await getServiceMessageBroadcast(values)
  if (ok) setData(data)

  return {
    status: ok,
    message
  }
}
