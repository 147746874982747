<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        :no-mobile="true"
        @launch="launch"
      >
        <template #toolbar>
          <pms-feedback-modal
            class="me-2"
            :options="[]"
          />
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <slot />
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useTour } from '@shared/composable'

import { header, tour } from '../values'

const { status, steps, launch, setSteps } = useTour([])

onMounted(async () => {
  setSteps(await tour())
})
</script>
