
import type { StatusMessageResponse } from '@/shared/types'
import {
  postAcademicManageSyllabusLearningOutcomes
} from '@shared/api'
import { useAlert } from '@shared/composable'
import { UserAddLearningOutcome } from '../../types'
import type { StoreType } from '../types'
import { fetchSyllabusAfterChange } from '../../utils'

export default async function addLearningOutcome (this: StoreType, values: UserAddLearningOutcome): Promise<StatusMessageResponse> {
  const { ok, message } = await postAcademicManageSyllabusLearningOutcomes({ ...this.requestGetter, ...values })
  const { error } = useAlert()

  if (ok) {
    fetchSyllabusAfterChange(this.year, this.course_code)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
