import { SelectField } from '@shared/types'
import { ServiceEsignResourceGetResponse } from '@shared/swagger'

export function entranceYearsToYearOptions (entrance_years?: ServiceEsignResourceGetResponse['entrance_years']): SelectField['options'] {
  return [
    ...new Set(
      entrance_years?.map((year) => year.year)
    )
  ].map((year) => ({
    label: year,
    value: year
  })) as unknown as SelectField['options']
}
