const getMonthDays = (date: Date) => {
  const month = date.getMonth()
  const year = date.getFullYear()
  const monthDays = new Date(year, month + 1, 0).getDate()
  return monthDays
}

const getCurrentDay = (date: Date) => {
  const day = date.getDate()
  return day
}

const generateCalendar = (date: Date) => {
  const details = {
    totalDays: getMonthDays(date),
    day: 1,
    weekDays: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  }
  const start = new Date(date.getFullYear(), date.getMonth()).getDay()
  const today = getCurrentDay(date)
  const cal:unknown[][] = []

  for (let i = 0; i <= 6; i++) {
    cal.push([])
    for (let j = 0; j < 7; j++) {
      const row = { class: 'fs-4', tdClass: '', day: '', active: false, content: '' }
      if (i === 0) {
        row.class = 'tw-bolder text-hover-primary fs-4 pb-2'
        row.content = details.weekDays[j]
      } else if (details.day > details.totalDays) {
        row.content = ''
      } else {
        if (i === 1 && j < (start - 1)) {
          row.content = ''
        } else {
          if (details.day === today) {
            row.class = 'p-0'
            row.content = `
              <span class="symbol symbol-40px symbol-circle">
                <span class="symbol-label bg-primary fw-bolder fs-4 text-white">${details.day++}</span>
              </span>
            `
          } else {
            row.content = (details.day++) + ''
          }
        }
      }

      cal[i].push(row)
    }
  }

  return cal
}

export default generateCalendar
