import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  ServiceMessageBroadcastPostRequest,
  ServiceMessageBroadcastPostResponse
} from '@shared/swagger'

export default async function postServiceMessageBroadcast (body: ServiceMessageBroadcastPostRequest): Promise<AxiosResponse<ServiceMessageBroadcastPostResponse>> {
  const { post } = useRequests()

  const response = await post<ServiceMessageBroadcastPostRequest, ServiceMessageBroadcastPostResponse>({
    url: 'service/message-broadcast',
    body,
    loader: 'service/message-broadcast-post'
  })

  return response
}
