<template>
  <pms-grid>
    <YearTerm
      add-all-as-option
      @on-option-change="handleOptionChange"
    />
    <pms-row>
      <pms-grid
        id="single-student-grades"
        class="mt-4"
      >
        <pms-card
          :title="'domains.academic.single-student.navigation.items.grades'"
          :description="'domains.academic.single-student.navigation.items.grades-description'"
          :options="{
            body: { class: 'pt-0' }
          }"
        >
          <span
            v-if="showAllGrades"
            class="d-flex flex-column gap-5"
          >
            <div
              v-for="array in twoDimArray.sort((a, b) => (a[0].year + a[0].term) - (b[0].year + b[0].term))"
              :key="array[0].year"
              class="d-flex flex-column gap-3"
            >
              <div class="text-gary-800 fw-bold fs-3 text-danger">
                {{ array[0].year + '-' + (array[0].year+1) }} - {{ array[0].term +'. '+ $t('common.term') }}
              </div>
              <grades-table :grades="array" />
            </div>
          </span>
          <grades-table
            v-else
            :grades="grades.filter((grade)=>(grade.year == currentYearTerm.year && grade.term == currentYearTerm.term))"
          />
        </pms-card>
      </pms-grid>
      <grades-details :side-bar-data="sideBarData" />
    </pms-row>
  </pms-grid>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'

import { AcademicSingleStudentGradesGetResponse } from '@/shared/swagger'
import { useSingleStudent } from '../../../store'

import GradesTable from './GradesTable.vue'
import GradesDetails from './GradesDetails.vue'
import YearTerm from '@/domains/AcademicSingleStudent/ui/YearTerm.vue'

const store = useSingleStudent()

const { currentYearTerm, grades, gradesDetails: sideBarData } = storeToRefs(store)

const showAllGrades = ref(false)

// Group courses by year and term
const groupedGrades = computed(() => grades.value.reduce((acc, grade) => {
  const { year, term } = grade
  const key = `${year}-${term}`

  // If the key doesn't exist in the accumulator, create a new array for it
  if (!acc[key]) {
    acc[key] = []
  }
  // Push the current grade to the corresponding year-term array
  acc[key].push(grade)

  return acc
}, {}))

// Convert the grouped object into a two-dimensional array
const twoDimArray = computed<AcademicSingleStudentGradesGetResponse[]>(() => Object.values(groupedGrades.value))

const handleOptionChange = (option: string) => {
  showAllGrades.value = option === 'all'
}
</script>
