import { SelectField } from '@/shared/types'
import { KnowledgeManageResourceResponse } from '@shared/swagger'

export default (data: KnowledgeManageResourceResponse['categories']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((category) => {
    options.push({
      label: category.title,
      value: category.uuid
    })
  })

  return options
}
