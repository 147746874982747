import { createPage } from '@shared-utils/storybook'
import { usePagination } from '@shared/composable'

import ExamDates from '../ExamDates.vue'
import { useExamDates } from './../store'

const Template = createPage({
  layout: 'default',
  component: ExamDates,
  setup () {
    const store = useExamDates()
    const pagination = usePagination()

    store.$reset()
    pagination.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Information/Exam Dates',
  component: ExamDates,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
