import { SmartTableHeader } from '@shared/types'

export const departmentTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.table.code',
    field: 'code',
    sortable: true
  },
  {
    title: 'common.table.course-prefixes',
    field: 'course_prefixes',
    slotCellName: 'coursePrefixes'
  },
  {
    title: 'common.table.responsible-persons',
    field: 'responsible_persons',
    slotCellName: 'responsiblePersons'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]

export const programsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.table.code',
    field: 'code',
    sortable: true
  },
  {
    title: 'common.table.cipher',
    field: 'cipher',
    sortable: true
  },
  {
    title: 'common.table.level',
    field: 'level',
    slotCellName: 'level'
  },
  {
    title: 'common.table.language',
    field: 'language',
    slotCellName: 'language'
  },
  {
    title: 'common.table.faculty',
    field: 'faculty',
    slotCellName: 'faculty'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]

export const tracksTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.program-type',
    field: 'program_type',
    slotCellName: 'program_type',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.table.code',
    field: 'code',
    sortable: true
  },
  {
    title: 'common.table.program',
    field: 'program',
    slotCellName: 'program',
    sortable: true
  },
  {
    title: 'common.table.terms',
    field: 'terms',
    cellClass: 'text-center',
    sortable: true
  },
  {
    title: 'common.table.students',
    field: 'students',
    cellClass: 'text-center',
    sortable: true
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]
