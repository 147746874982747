import type { KnowledgeManagePostsSingleGetResponse, KnowledgeManageSinglePostPutRequest } from '@/shared/swagger'
import { typeMapperFromArray } from '@/shared/utils'

export const formatSinglePostToPutRequest = (data: KnowledgeManagePostsSingleGetResponse): KnowledgeManageSinglePostPutRequest => {
  const formattedDetails = typeMapperFromArray<KnowledgeManagePostsSingleGetResponse, KnowledgeManageSinglePostPutRequest>([data], [
    { from: 'category_uuid', to: 'category_uuid' },
    { from: 'title', to: 'title' },
    { from: 'description', to: 'description' },
    { from: 'published', to: 'published' },
    { from: 'meta', to: 'meta' },
    { from: 'details', to: 'details' }
  ])
  const formattedContents = typeMapperFromArray<KnowledgeManagePostsSingleGetResponse['contents'][0], KnowledgeManageSinglePostPutRequest['contents'][0]>(data.contents, [
    { from: 'uuid', to: 'uuid' },
    { from: 'type_id', to: 'type_id' },
    { from: 'version', to: 'version' },
    { from: 'image', to: 'image' },
    { from: 'video', to: 'video' },
    { from: 'video_type', to: 'video_type' },
    { from: 'content', to: 'content' },
    { from: 'active', to: 'active' },
    { from: 'visible', to: 'visible' },
    { from: 'alert_type', to: 'alert_type' },
    { from: 'alert_text', to: 'alert_text' }
  ])
  const formatted = {
    ...formattedDetails[0],
    tags: data.tags.map(el => el.tag),
    contents: formattedContents
  }
  return formatted
}
