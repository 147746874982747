import type { StoreType } from '../types'
import { AcademicManageCourseResourceDepartmentsGetRequest } from '@shared/swagger'

import { getAcademicManageCourseResourceDepartments } from '@shared/api'

export default async function fetchAcademicManageCourseDepartmentsResource (this: StoreType, body: AcademicManageCourseResourceDepartmentsGetRequest): Promise<void> {
  const { ok, data } = await getAcademicManageCourseResourceDepartments(body)

  if (ok) {
    this.setAcademicManageCourseDepartmentsResource(data)
  }
}
