import type { StoreType } from '../types'
import type { ServiceMessageBroadcastPostRequest } from '@shared/swagger'
import { postServiceMessageBroadcast } from '@shared/api'
import { StatusMessageResponseWithId } from '@domains/ServiceMessageBroadcastCompose/type'

export default async function newMessage (this: StoreType, body: ServiceMessageBroadcastPostRequest): Promise<StatusMessageResponseWithId> {
  const { ok, message, data } = await postServiceMessageBroadcast(body)

  return {
    status: ok,
    message,
    id: data.id
  }
}
