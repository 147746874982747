<template>
  <div class="d-flex flex-column flex-lg-row flex-column-fluid">
    <div
      class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center"
      :class="{
        'p-10': device === 'desktop',
        'p-2': device === 'tablet' || device === 'mobile'
      }"
    >
      <SignInAction />
    </div>
    <AsideDetails v-bind="aside" />
  </div>
</template>

<script lang="ts" setup>
import AsideDetails from './ui/AsideDetails.vue'
import SignInAction from './features/SignInAction.vue'
import { deviceType } from '@shared/utils'

import { signIn as aside } from './values'

const device = deviceType()

</script>
