import { useRequests } from '@/shared/composable'

import type { AxiosResponse } from '@/shared/types'
import type { UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function getTourTranslateContentByPath (path: string): Promise<AxiosResponse<UnknownResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, UnknownResponse>({
    url: `http://localhost:3000/tours/${path}`,
    body: {},
    loader: 'translates'
  })

  return response
}
