import { SmartTableHeader } from '@/shared/types'

export const courseHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'courseCode',
    slotCellName: 'courseCode'
  },
  {
    title: 'common.table.section',
    field: 'section'
  },
  {
    title: 'common.table.course-title',
    field: 'courseTitle',
    slotCellName: 'courseTitle'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'

  },

  {
    title: 'common.table.teacher',
    field: 'teacher',
    slotCellName: 'teacher'
  },
  {
    title: 'common.table.requested',
    field: 'requested',
    slotCellName: 'requested'
  },
  {
    title: 'common.table.section-credit',
    field: 'section-credit',
    slotCellName: 'section-credit'
  },
  {
    title: 'common.table.hour',
    field: 'hour',
    slotCellName: 'hour'
  },
  {
    title: 'common.common.table.applied',
    field: 'applied',
    slotCellName: 'applied'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]
