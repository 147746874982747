<template>
  <pms-smart-table
    :headers="headers.filter((item) => item.field !== 'group')"
    :items="curricula ? curricula.electives[electiveType] : []"
  >
    <template
      v-if="canEditCurricula"
      #select-header
    >
      <pms-checkbox
        :state="areAllSelected"
        @update:state="toggleSelectAll(electiveType)"
      />
    </template>
    <template
      v-if="canEditCurricula"
      #select="{ item }"
    >
      <pms-checkbox
        :key="item.id"
        :state="item._isSelected"
        @Click="toogleSelectElective(item)"
      />
    </template>
    <template #no="{idx}">
      {{ idx + 1 }}
    </template>
    <template #type>
      {{ $t(`domains.editor.curricula.tabs.electives.${electiveType}-short`) }}
    </template>
    <template #practice="{ item }">
      {{ item.practice }} + {{ item.lab }}
    </template>
    <template #actions="{item}">
      <div class="d-flex gap-2">
        <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
          <pms-button
            :variant="'outlined'"
            @Click="handleEdit(item)"
          >
            <i class="bi bi-pencil" />
          </pms-button>
        </PermissionCheck>

        <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
          <pms-button
            type="button"
            :variant="'outlined'"
            :color="'danger'"
            @Click="handleOnDelete(item)"
          >
            <i class="bi bi-trash" />
          </pms-button>
        </PermissionCheck>
      </div>
    </template>
  </pms-smart-table>
</template>
<script setup lang="ts">
import permissionCodes from '@/shared/values/permissionCodes'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { electiveHeaders as headers } from '../../values'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import useCurriculumDeleter from '@/domains/AcademicCurricula/shared/composable/useCurriculumCoreDeleter'
import useCourseEditor from '@/domains/AcademicCurricula/shared/composable/useCourseEditor'
import { AcademicManageCurriculumCoreCoursesResponse, AcademicManageCurriculumViewResponse } from '@shared/swagger'
import { ref } from 'vue'
import useUser from '@/shared/stores/useUser'

const { handleDelete } = useCurriculumDeleter()
const store = useStore()
const { curricula } = storeToRefs(store)
const { handleEditCourse } = useCourseEditor()
const userStore = useUser()
const canEditCurricula = userStore.hasPermissionByModuleCode(permissionCodes.edit_curricula.code)

defineProps({
  electiveType: {
    type: String,
    required: true
  }
})

const handleEdit = (item: Pick<AcademicManageCurriculumCoreCoursesResponse[0], 'is_slot' | 'id'>) => {
  if (!curricula.value) return
  handleEditCourse({
    id: item.id,
    is_slot: item.is_slot,
    trackId: curricula.value.information.track.id
  })
}

const toogleSelectElective = (item) => {
  item._isSelected = !item._isSelected
  if (!item._isSelected) {
    areAllSelected.value = false
  }
}

const handleOnDelete = (item: AcademicManageCurriculumViewResponse['electives']['area'][number]) => {
  handleDelete({
    id: item.id,
    // Elective course is not slot!
    is_slot: false
  }, () => {
    if (!curricula.value) return
    store.fetchCurricula({ track: curricula.value.information.track.id })
  })
}

const areAllSelected = ref(false)

const toggleSelectAll = (electiveType: string) => {
  if (!curricula.value) return
  areAllSelected.value = !areAllSelected.value

  curricula.value.electives[electiveType] = curricula.value.electives[electiveType].map(item => {
    item._isSelected = areAllSelected.value
    return item
  })
}
</script>
