import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, ServiceManageLocksGetRequest, ServiceManageLocksGetResponse } from '@shared/swagger'

export default async function getServiceManageLocks (params: ServiceManageLocksGetRequest): Promise<AxiosResponse<ServiceManageLocksGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageLocksGetResponse>({
    url: 'service/manage/locks',
    body: { params: params },
    loader: 'aservice/manage/locks'
  })

  return response
}
