<template>
  <pms-row>
    <pms-card :header="false">
      <div class="row mb-10">
        <div
          v-for="(item, index) in transcript?.information"
          :key="index"
          class="col-12 col-md-6"
        >
          <div
            v-for="el in item"
            :key="el.title"
            class="d-flex flex-stack mb-2"
          >
            <template v-if="el.value">
              <span class="text-gray-600 fw-semibold fs-5 me-2">
                {{ $t(el.title) }}:
              </span>
              <span
                v-if="el.title.includes('date')"
                class="fw-bold fs-5"
              >
                {{ dateFormatter(el.value, dateOptions) }}
              </span>
              <span
                v-else
                class="fw-bold fs-5"
              >
                {{ el.value }}
              </span>
            </template>
          </div>
        </div>
      </div>
      <!--  -->
      <div
        v-if="transcript?.transcript"
        class="table-responsive"
      >
        <table class="table">
          <thead class="text-center align-middle">
            <template
              v-for="(item, index) in transcriptTableHeader"
              :key="index"
            >
              <th class="fw-bolder fs-5">
                {{ $t(item.title) }}
              </th>
            </template>
          </thead>
          <tbody>
            <template
              v-for="(item, index) in transcript?.transcript || []"
              :key="index"
            >
              <tr>
                <td
                  colspan="8"
                  class="fw-bold fs-5"
                >
                  {{ item.year }} - {{ item.year + 1 }} . {{ item.term }}
                </td>
              </tr>

              <template
                v-for="(course) in item.courses"
                :key="course.courseCode"
              >
                <tr
                  class="text-center"
                  :style="{
                    border: `1pt solid rgba(${ themeMode === 'light' ? '0,0,0' : '255,255,255'}, .15)`
                  }"
                >
                  <td>{{ course.course_code }}</td>
                  <td class="text-start">
                    {{ course.course_title }}
                  </td>
                  <td>{{ course.credits }}</td>
                  <td>{{ course.ects }}</td>
                  <td>{{ course.grade }}</td>
                  <td>{{ course.grade_letter }}</td>
                  <td>{{ course.grade_point }}</td>
                  <td>{{ course.grade_title }}</td>
                </tr>
              </template>

              <template
                v-for="(gpa, gIndex) in item.gpa"
                :key="gIndex"
              >
                <tr class="text-center text-danger fw-bold">
                  <td />
                  <td />
                  <td>{{ gpa.credits }}</td>
                  <td>{{ gpa.ects }}</td>
                  <td>{{ gpa.sa }}</td>
                  <td>{{ gpa.ga }}</td>
                  <td>{{ gpa.spa }}</td>
                  <td>{{ gpa.gpa }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <!--  -->
      <div
        v-if="transcript"
        class="d-flex flex-row-reverse mb-3 mt-4"
      >
        <span class="fs-5">
          {{ $t('common.table.prepared') }}: {{ currentDate }}
        </span>
      </div>
      <!--  -->
      <transcript-description
        v-if="transcript && themeMode"
        :locale="locale"
        :theme-mode="themeMode"
      />
    </pms-card>
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { themeMode } from '@shared-composable/pattern'

import { dateFormatter } from '@/shared/utils'

import { useSingleStudent } from '../../../store'
import { dateOptions } from '../../../values'

import { transcriptTableHeader } from '../values'
import TranscriptDescription from './TranscriptDescription.vue'

const i18n = useI18n()
const store = useSingleStudent()

const currentDate = computed(() => dateFormatter(new Date(), { outputFormat: 'DD.MM.YYYY' }))
const locale = computed(() => i18n.locale.value)
const transcript = computed(() => store.getTranscript)
</script>
