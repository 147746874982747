import type { StoreType } from '../types'
import { getScheduleManagement1Section1Program } from '@shared/api'

export default async function fetchSingleSection1Program (this: StoreType, section: string, program: string): Promise<void> {
  const { data, ok } = await getScheduleManagement1Section1Program(section, program)

  if (ok) {
    this.setSingleSection1Program(data)
  }
}
