import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementRequestCourseGetRequest,
  SectionManagementRequestCourseGetResponse
} from '@shared/swagger'

export default async function getSectionManagementRequestCourses (params: SectionManagementRequestCourseGetRequest): Promise<AxiosResponse<SectionManagementRequestCourseGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementRequestCourseGetResponse>({
    url: 'academic/manage/sections/courses',
    body: { params: params },
    loader: 'academic/manage/sections/courses'
  })

  return response
}
