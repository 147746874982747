import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicSingleCourseGradeStatisticsGetRequest, AcademicSingleCourseGradeStatisticsGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicSingleCourseGradeStatistics (assesment: string, params: AcademicSingleCourseGradeStatisticsGetRequest): Promise<AxiosResponse<AcademicSingleCourseGradeStatisticsGetResponse>> {
  const { post } = useRequests()

  const response = post<UnknownRequest, AcademicSingleCourseGradeStatisticsGetResponse>({
    url: `academic/courses/grades/${assesment}/statistics`,
    body: { ...params },
    loader: 'academic/courses/grades/{assesment}/statistics'
  })

  return response
}
