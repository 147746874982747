<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
      :filter="true"
    />
    <pms-row
      v-else
      class="gap-4"
    >
      <overview-column />
    </pms-row>
  </div>
</template>
<script setup lang="ts">

import { useDriver, useLoader } from '@shared/composable'

import OverviewColumn from './ui/OverviewColumn.vue'
import { onMounted } from 'vue'
import { quickTourSteps } from './values'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('profile')

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
