import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function deleteRulesAndRegulations (rule: string): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: `academic/information/rules/${rule}`,
    body: {},
    loader: 'academic/information/rules'
  })
  return response
}
