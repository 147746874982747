<template>
  <div class="d-flex justify-content-between mb-4">
    <h3 class="card-title align-items-start flex-column mb-0">
      <span class="card-label fw-bold text-gray-800">
        {{ $t('domains.academic.single-course.grades.title') }}
      </span>
      <span class="text-gray-400 mt-1 fw-semibold fs-6 d-block">
        {{ $t('domains.academic.single-course.grades.description', { ...route.query }) }}
      </span>
    </h3>
    <div class="mt-1">
      <header-action-btns :student-ids="studentIds" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { useRoute } from 'vue-router'

import HeaderActionBtns from './HeaderActionBtns.vue'

defineProps({
  studentIds: {
    type: Array as PropType<Array<string>>,
    required: true
  }
})

const route = useRoute()
</script>
