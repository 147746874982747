import { QuickLinkWithRoles } from '../types/quick-links'

export default (): Array<QuickLinkWithRoles> => [
  { title: 'domains.dashboard.quick-links.go-to-dashboard', link: '/dashboard', role: '*' },
  { title: 'domains.dashboard.quick-links.go-to-syllabus', link: '/', role: 'coordinator' },
  { title: 'domains.dashboard.quick-links.go-to-system-calendar', link: '/administrative/system-calendar', role: 'administrator' },
  { title: 'domains.dashboard.quick-links.go-to-curriculum', link: '/', role: 'coordinator' },
  { title: 'domains.dashboard.quick-links.go-to-my-courses', link: '/academic/courses/my-courses', role: 'teacher' },
  { title: 'domains.dashboard.quick-links.go-to-my-courses', link: '/academic/courses/my-courses', role: 'coordinator' }
]
