import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

const handleServiceId = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!('serviceId' in to.params)) {
    next({ name: 'Services' })
    return
  }

  next()
}

export default {
  layout: 'default',
  path: '/services',
  children: [
    {
      path: '',
      name: 'Services',
      component: () => import('./AllServices.vue')
    },
    {
      path: ':serviceId',
      name: 'SingleService',
      component: () => import('./features/SingleService.vue'),
      beforeEnter: [
        handleServiceId
      ]
    }
  ]
} as RouteRecordWithLayout
