<template>
  <div
    class="d-flex flex-center flex-wrap px-5"
  >
    <div
      v-if="footerLinks.length > 0"
      class="d-flex fw-semibold text-primary fs-base"
    >
      <template
        v-for="(link, i) in footerLinks"
        :key="i"
      >
        <a
          v-test-id="'aside-link-' + (i + 1)"
          :href="link.url"
          class="px-5"
          target="_blank"
        >
          {{ link.label.startsWith('domains.') ? $t(link.label) : link.label }}
        </a>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
const footerLinks = [
  {
    label: 'domains.authenticator.aside.link-titles.privacy',
    url: '/privacy-policy'
  },
  {
    label: 'domains.authenticator.aside.link-titles.terms',
    url: '/terms'
  },
  {
    label: 'domains.authenticator.aside.link-titles.support',
    url: '/support'
  }
]
</script>
