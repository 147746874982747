<template>
  <Teleport to="#education-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationOrganizationsGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/education-edit', 'profile/education-post'])

const organizations = computed(() => store.getOrganizations)
const organizationsAll = computed(() => resource.getProfileResource?.organizations || [])

const defaultTempValues: ProfileEducationOrganizationsGetResponse[0] = {
  id: -1,
  organization: '',
  is_ongoing: false,
  membership_date: '',
  end_date: ''
}

const tempValues = ref<ProfileEducationOrganizationsGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.education.organization.organization-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'organization',
    type: 'autocomplete',
    value: tempValues.value.organization,
    required: true,
    suggestions: {
      itemField: 'title',
      items: organizationsAll.value,
      class: ''
    },
    placeholder: t('domains.profile.education.organization.organization-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.organization.membership-date-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'membership_date',
    type: 'date',
    value: tempValues.value.membership_date,
    required: true
  },
  {
    label: {
      text: t('domains.profile.education.organization.end-date-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_date',
    type: 'date',
    value: tempValues.value.end_date,
    disabled: tempValues.value.is_ongoing
  },
  {
    label: {
      text: t('domains.profile.education.organization.is-ongoing-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'is_ongoing',
    type: 'radio',
    required: true,
    value: tempValues.value.is_ongoing || false,
    inline: true,
    options: [
      { id: '1', value: true, label: { text: 'True' } },
      { id: '1', value: false, label: { text: 'False' } }
    ]
  }
])

const closeModal = () => {
  close({ name: 'education-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  const organizationId = organizationsAll.value.find(el => el.title === values.organization)
  organizationId ? values.organization = organizationId.id : values.organization = ''

  if (!values.organization) {
    error({ text: t('domains.profile.education.organization.organization-field-error') })
    return
  }
  if (!values.membership_date) {
    error({ text: t('domains.profile.education.organization.membership-date-field-error') })
    return
  }
  values.is_ongoing = values.is_ongoing === 'true'
  if (values.end_date === '') { values.end_date = undefined }

  const { status, message } =
          modifier.value?.data?.action === 'new'
            ? await store.addOrganization({ ...values })
            : await store.editOrganization(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'education-modal' })
    unsetModifier()
    store.fetchOrganizations()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactOrganization = organizations.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactOrganization || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
