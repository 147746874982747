<template>
  <Teleport to="#received-requests-content">
    <pms-row>
      <template
        v-for="common in commons"
        :key="common.type"
      >
        <pms-grid :col="4">
          <p v-if="initiateType(common.type)">
            {{ $t(`common.section-type.${initiateType(common.type)}`) }}
          </p>
          <pms-smart-table
            :items="common.sections"
            :headers="sectionTable"
            :class="'mt-4'"
          >
            <template #checkbox="{ item }">
              <pms-checkbox
                class="d-flex justify-content-center"
                :state="isSelected(item)"
                @on-check="handleSelect(item)"
                @on-uncheck="handleUnselect(item)"
              />
            </template>
            <template #section="{ item }">
              <pms-badge
                v-if="item.name"
                :value="item.name"
              />
            </template>
            <template #teacher="{ item }">
              {{ item.instructor?.full_name }}
            </template>
            <template #note="{ item }">
              {{ item.note }}
            </template>
          </pms-smart-table>
        </pms-grid>
      </template>
    </pms-row>
    <div class="form-group d-flex">
      <input
        v-model="quotas"
        type="number"
        class="form-control w-150px"
        :placeholder="$t('phrase.enter-quotas')"
      >
      <pms-button
        class="ms-4"
        :text="'common.submit'"
        type="submit"
        @click="onSubmit"
      />
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { watch, ref, shallowRef, computed } from 'vue'
import { sectionTable } from '@domains/SectionManagement/features/ReceivedRequests/values'
import { useReceivedRequest } from '@domains/SectionManagement/features/ReceivedRequests/store'
import { useAlert, useTeleportModal } from '@shared/composable'
import { SectionManagementSectionsGetResponse } from '@shared/swagger'

const { show, modifier, close } = useTeleportModal()
const receivedRequestStore = useReceivedRequest()
const { success, error } = useAlert()

const theories = computed(() => receivedRequestStore.getRequestsTheorySections)
const labs = computed(() => receivedRequestStore.getRequestsLaboratorySections)
const practices = computed(() => receivedRequestStore.getRequestsPracticeSections)

const quotas = shallowRef<number | null>(null)

const commons = [
  { type: 'N', sections: theories.value },
  { type: 'P', sections: practices.value },
  { type: 'L', sections: labs.value }
]

function initiateType (type: string) {
  switch (type) {
    case 'N':
      return 'theory'
    case 'L':
      return 'laboratory'
    case 'P':
      return 'practice'
    default:
      return 'theory'
  }
}

const selectedSections = ref<Record<string, SectionManagementSectionsGetResponse[0] | null>>(
  {
    N: null,
    L: null,
    P: null
  }
)

const handleSelect = (section) => {
  selectedSections.value[section.type.id] = section
}

const handleUnselect = (section) => {
  selectedSections.value[section.type.id] = null
}

const isSelected = (section) => {
  return selectedSections.value[section.type.id]?.id === section.id
}

async function onSubmit () {
  if (!selectedSections.value.N?.id) {
    error({
      text: 'Theory field is required'
    })
    return
  } // add error for submit
  if (typeof quotas.value !== 'number') {
    error({
      text: 'Quotas must be a nubmer'
    })
    return
  } // add error for submit
  const { status, message } = await receivedRequestStore.createReceivedRequestQuota(receivedRequestStore.selectedRequest?.id || 0, {
    theory_section_id: selectedSections.value.N?.id || undefined,
    lab_section_id: selectedSections.value.L?.id || undefined,
    practice_section_id: selectedSections.value.P?.id || undefined,
    quota: quotas.value
  })
  if (status) {
    close({ name: 'received-requests-modal' })
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      show()
    }
  }, { immediate: true }
)
</script>
