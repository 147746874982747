import { RulesAndRegulationsLanguagesGetResponse, SingleRuleGetResponse } from '@/shared/swagger'
import { ICustomAttachment } from '../types'

export default (
  tempValues: ICustomAttachment['attachment'],
  attachments: SingleRuleGetResponse['attachments'],
  languages: RulesAndRegulationsLanguagesGetResponse
) => {
  const errors: string[] = []
  const formData = new FormData()

  if ((tempValues.id
    ? attachments.filter(a => a.id !== tempValues.id)
    : attachments)
    .some(a => a.version === tempValues.version &&
      a.language_name === tempValues.language_name
    )) {
    errors.push('domains.rules-and-regulations.attachment-form.rule-exists')
  }

  if (!errors.length) {
    if (tempValues.file) {
      formData.append('file', tempValues.file)
    }

    const language = languages.find(l => l.short === tempValues.language_name)
    if (language) {
      formData.append('language_id', language.id + '')
    } else {
      errors.push('domains.rules-and-regulations.attachment-form.invalid-language-name')
    }
    if (tempValues.id) formData.append('_method', 'PUT')

    formData.append('version', tempValues.version)
  }
  return {
    formData,
    errors
  }
}
