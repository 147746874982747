import type { StoreType } from '../types'
import type { SectionManagementRequestGetRequest } from '@shared/swagger'

import { getSectionManagementRequests } from '@shared/api'

export default async function fetchReceivedRequests (this: StoreType, values: SectionManagementRequestGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequests(values)
  const { reservedPrograms } = await this.fetchReservedPrograms({
    course_code: values.course_code,
    course_year: values.course_year,
    term: values.term
  })
  if (ok) {
    this.setReceivedRequests([...data, ...reservedPrograms])
  } else {
    this.receivedRequests = []
  }
}
