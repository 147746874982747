/* eslint-disable @typescript-eslint/no-explicit-any */
import { router } from '@/router'
import { useEncrypt } from '@shared/composable/index'
import { useStudent, useUser } from '../stores'

const ID_TOKEN_KEY = 'token' as string
const TOKEN_EXPIRES_IN = 'expiresIn' as string
const TOKEN_TYPE = 'tokenType' as string

const ID_STUDENT_TOKEN_KEY = 'studentToken' as string
const STUDENT_TOKEN_EXPIRES_IN = 'studentExpiresIn' as string
const STUDENT_TOKEN_TYPE = 'studentTokenType' as string

export const useToken = () => {
  const getToken = (): string | null => {
    const token = window.localStorage.getItem(ID_TOKEN_KEY)
    const expiration = window.localStorage.getItem(TOKEN_EXPIRES_IN)
    const typeIs = window.localStorage.getItem(TOKEN_TYPE)

    if (!token || !expiration) return null
    if (Date.now() > parseInt(expiration) * 1000) return null

    return typeIs + ' ' + token
  }

  const getStudentToken = (): string | null => {
    const token = window.localStorage.getItem(ID_STUDENT_TOKEN_KEY)
    const expiration = window.localStorage.getItem(STUDENT_TOKEN_EXPIRES_IN)
    const typeIs = window.localStorage.getItem(STUDENT_TOKEN_TYPE)

    if (!token || !expiration) return null
    if (Date.now() > parseInt(expiration) * 1000) return null

    return typeIs + ' ' + token
  }

  const hasToken = (): boolean => {
    const token = window.localStorage.getItem(ID_TOKEN_KEY)
    const expiration = window.localStorage.getItem(TOKEN_EXPIRES_IN)

    if (!token || !expiration) { return false }

    return true
  }

  const hasStudentToken = (): boolean => {
    const token = window.localStorage.getItem(ID_STUDENT_TOKEN_KEY)
    const expiration = window.localStorage.getItem(STUDENT_TOKEN_EXPIRES_IN)

    if (!token || !expiration) { return false }

    return true
  }

  const setToken = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
  }

  const setTokenDetails = (token: any): void => {
    for (const key in token) {
      window.localStorage.setItem(key, token[key] as any)
    }
  }

  const setStudentToken = (token: string): void => {
    window.localStorage.setItem(ID_STUDENT_TOKEN_KEY, token)
  }

  const setStudentTokenDetails = (token: any): void => {
    for (const key in token) {
      window.localStorage.setItem('student' + (key.charAt(0).toUpperCase() + key.slice(1)), token[key] as any)
    }
  }

  const setTokensEncrypted = (token: any): void => {
    const { encrypt } = useEncrypt()
    window.localStorage.setItem('sensitive', encrypt(token) || '')
  }

  const destroyToken = (): void => {
    window.localStorage.removeItem(ID_TOKEN_KEY)
    window.localStorage.removeItem(TOKEN_EXPIRES_IN)
    window.localStorage.removeItem(TOKEN_TYPE)
    useUser().setAuthenticated(false)
  }

  const getYear = (): number => {
    const year = window.localStorage.getItem('year')

    return year
      ? parseInt(year)
      : new Date().getFullYear()
  }

  const getTerm = (): number => {
    const term = window.localStorage.getItem('term')

    if (!term) {
      return new Date().getMonth() < 6 ? 1 : new Date().getMonth() < 9 ? 3 : 2
    }

    return parseInt(term)
  }

  const clearTokens = (): void => {
    window.localStorage.clear()
    useUser().setAuthenticated(false)
    router.push({ name: 'SignInPage' })
  }

  const clearStudentTokens = (): void => {
    window.localStorage.clear()
    useStudent().setAuthenticated(false)
    router.push({ name: 'StudentSignInPage' })
  }

  const isExpired = () => {
    let isTokenExpired = false

    const token = window.localStorage.getItem(ID_TOKEN_KEY)
    const expiration = window.localStorage.getItem(TOKEN_EXPIRES_IN)

    if (!token || !expiration) isTokenExpired = true
    if (expiration && (Date.now() > parseInt(expiration) * 1000)) isTokenExpired = true

    return isTokenExpired
  }

  const isStudentExpired = () => {
    let isTokenExpired = false

    const token = window.localStorage.getItem(ID_STUDENT_TOKEN_KEY)
    const expiration = window.localStorage.getItem(STUDENT_TOKEN_EXPIRES_IN)

    if (!token || !expiration) isTokenExpired = true
    if (expiration && (Date.now() > parseInt(expiration) * 1000)) isTokenExpired = true

    return isTokenExpired
  }

  return {
    ID_TOKEN_KEY,
    TOKEN_EXPIRES_IN,
    TOKEN_TYPE,
    ID_STUDENT_TOKEN_KEY,
    STUDENT_TOKEN_EXPIRES_IN,
    STUDENT_TOKEN_TYPE,
    getToken,
    getStudentToken,
    setToken,
    getYear,
    getTerm,
    setTokenDetails,
    setStudentToken,
    setStudentTokenDetails,
    setTokensEncrypted,
    hasToken,
    destroyToken,
    clearTokens,
    isExpired,
    clearStudentTokens,
    isStudentExpired,
    hasStudentToken
  }
}

export default useToken
