<template>
  <pms-card
    :title="'common.search'"
    :description="$t('domains.academic.information.syllabuses.search.description')"
    :options="{ body: { class: 'pt-0'}}"
  >
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onChange"
    >
      <template #formControls>
        <div class="form-group mt-3 d-flex justify-content-end">
          <pms-button
            type="submit"

            :disabled="loader || !restForm"
            :loader="searchLoader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { useSyllabusesSearch } from '../composable/useSyllabusesSearch'

const { restForm, formFields, onSubmit, onChange } = useSyllabusesSearch()

const { isActive, isActiveOneOf } = useLoader()

const loader = isActive('academic/information/syllabuses')
const searchLoader = isActiveOneOf(['academic/courses', 'academic/resources'])
</script>
