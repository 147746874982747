import { SingleCourseSidebarItem } from '../types'

export const menuItems: Array<SingleCourseSidebarItem> = [
  {
    to: '/academic/courses/section',
    routeName: 'SingleSectionStudentsPage',
    label: 'domains.academic.single-course.navigation.items.students'
  },
  {
    to: '/academic/courses/section/grades',
    routeName: 'SingleSectionGradesPage',
    label: 'domains.academic.single-course.navigation.items.grades'
  },
  {
    to: '/academic/courses/section/attendance',
    routeName: 'SingleSectionAttendancePage',
    label: 'domains.academic.single-course.navigation.items.attendance'
  },
  {
    to: '/academic/courses/section/exam-dates',
    routeName: 'SingleSectionExamDatesPage',
    label: 'domains.academic.single-course.navigation.items.exam-dates'
  },
  {
    to: '/academic/courses/section/consent-requests',
    routeName: 'SingleSectionConsentRequestPage',
    label: 'domains.academic.single-course.navigation.items.concent-request'
  }
]
