import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  RulesAndRegulationsLanguagesGetResponse
} from '@shared/swagger'

export default async function getRulesAndRegulationsLanguages (): Promise<AxiosResponse<RulesAndRegulationsLanguagesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, RulesAndRegulationsLanguagesGetResponse>({
    url: 'academic/information/rules/languages',
    body: { },
    loader: 'academic/information/rules/languages'
  })

  return response
}
