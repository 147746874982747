<template>
  <div class="d-flex gap-3 justify-content-center flex-wrap">
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-icon btn-light-primary"
      @click="handleEditRoute(props.uuid)"
    >
      <pms-kt-icon
        icon-name="click"
        class="fs-3 m-0 p-0"
      />
    </a>
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-icon btn-light-danger"
      @click="handleDeletePost(props.uuid)"
    >
      <pms-kt-icon
        icon-name="trash"
        class="fs-3 m-0 p-0"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useKnowledgeManagePost } from '../../../store'
import { useAlert, usePagination } from '@shared/composable'

const router = useRouter()
const alert = useAlert()
const store = useKnowledgeManagePost()
const { perPage } = usePagination()

const props = defineProps<{
  uuid: string
}>()

function handleEditRoute (uuid: string) {
  router.push({
    path: `/knowledge-base/manage/article/edit/${uuid}`,
    params: {
      uuid: uuid
    }
  })
}

function handleDeletePost (uuid: string) {
  alert.dialog(
    {
      text: 'knowledge.manage.post.delete-post.text',
      onConfirm: async () => {
        const { status, message } = await store.deletePost(uuid)
        if (status) {
          store.fetchPosts({
            per_page: perPage.value,
            offset: 1
          })
        } else {
          alert.error({ text: message })
        }
      }
    }
  )
}
</script>
