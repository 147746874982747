import { getServiceManageSingleAnnouncement } from '@/shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'

export default async function fetchSingleRule (this: StoreType, announcementId: string): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceManageSingleAnnouncement(announcementId)

  ok && this.setSingleAnnouncement(data)
  return {
    status: ok,
    message
  }
}
