import { useRoute } from 'vue-router'

export const useQueryHelper = () => {
  const route = useRoute()

  const trackId = parseInt(route.params.trackId as string)
  const term = route.params.term ? parseInt(route.params.term as string) : parseInt(route.query.term as string)

  return {
    term,
    params: {
      trackId
    },
    query: {
      term
    }
  }
}
