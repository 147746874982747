<template>
  <pms-card :header="false">
    <div
      class="position-absolute end-0 top-0 z-index-0"
      :class="themeMode == 'dark' ? 'opacity-10' : 'opacity-25'"
    >
      <inline-svg
        :src="service.icon.icon"
        :width="service.icon.iconWidth"
        :height="service.icon.iconHeight"
      />
    </div>
    <img
      class="rounded-3 h-300px w-100 z-index-1"
      :src="service.image"
      style="object-fit: cover; object-position: top;"
      alt="banner"
    >
    <h1 class="mb-2 mt-4">
      {{ $t( service.title ) }}
    </h1>
    <p class="fs-5 fw-semibold z-index-1">
      {{ $t( service.description ) }}
    </p>
    <template
      v-for="(item, index) in contents.contents"
      :key="index"
    >
      <div
        v-if="item.locale === locale"
        class="fs-5 fw-semibold"
        v-html="item.content"
      />
    </template>
  </pms-card>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { SingleContent } from '@shared/types'
import { themeMode } from '@shared-composable/pattern'
import { useLocale } from '@shared/composable'

import { Service } from '../../types'

defineProps({
  service: {
    type: Object as PropType<Service>,
    required: true
  },
  contents: {
    type: Object as PropType<SingleContent>,
    required: true
  }
})

const { locale } = useLocale()
</script>
