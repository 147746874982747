<template>
  <pms-smart-table
    :headers="gradesTableHeader"
    :items="grades"
  >
    <template #item-no="{ idx }">
      <span class="fs-5"> {{ idx + 1 }}</span>
    </template>
    <template #item-action="{ row }">
      <div class="text-center">
        <button
          class="btn btn-sm btn-light-primary w-100px"
          :class="gradeSideBarState && sideBarTitle == `${row.course_code} - ${row.title}` ? 'active': ''"
          @click="() => openDetails(row)"
        >
          {{ $t('common.details') }}
          <pms-kt-icon
            icon-name="arrow-right"
            class="ms-2 m-0 p-0"
          />
        </button>
      </div>
    </template>
  </pms-smart-table>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { storeToRefs } from 'pinia'

import { AcademicSingleStudentGradesGetResponse } from '@shared/swagger'
import { gradesTableHeader, sideBarTitle, gradeSideBarState } from '../values'
import { useSingleStudent } from '../../../store'

const store = useSingleStudent()
const { studentId } = storeToRefs(store)

defineProps({
  grades: {
    type: Array as PropType<AcademicSingleStudentGradesGetResponse>,
    required: true
  }
})

const openDetails = async (grade: AcademicSingleStudentGradesGetResponse[0]) => {
  sideBarTitle.value = `${grade.course_code} - ${grade.title}`
  gradeSideBarState.value = !gradeSideBarState.value

  if (grade.letter_grade.toUpperCase() !== 'IP') {
    await store.fetchGradesDetails(studentId.value, {
      course_code: grade.course_code,
      year: grade.year,
      term: grade.term
    })
  } else {
    store.setGradesDetails(null)
  }
}

</script>
