<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-3">
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </div>
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid
        :col="12"
      >
        <SyllabusesFilterColumn id="search-block-syllabus" />
      </pms-grid>
      <pms-grid
        v-if="syllabusesStore.syllabuses.value !== null"
        :order-mb="1"
        class="mt-5"
        :col="12"
      >
        <SyllabusesTable id="table-block-syllabus" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import { header, tour, quickSyllabusSetTourSteps, feedback } from './values'
import { useToolbar, useTour, useDriver } from '@shared/composable'

import SyllabusesFilterColumn from './ui/SyllabusesFilterColumn.vue'
import SyllabusesTable from './ui/SyllabusesTable.vue'
import { useSyllabuses } from './store'
import { storeToRefs } from 'pinia'

const store = useSyllabuses()
const syllabusesStore = storeToRefs(store)

const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickSyllabusSetTourSteps())
})
</script>
