import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { AdministrativeGateEntryMyRequest, AdministrativeGateEntryResponse } from '@shared/swagger'

export default async function postAdministrativeGateEntryMy (body: AdministrativeGateEntryMyRequest): Promise<AxiosResponse<AdministrativeGateEntryResponse>> {
  const { post } = useRequests()

  const response = post<AdministrativeGateEntryMyRequest, AdministrativeGateEntryResponse>({
    url: 'administrative/gate-entry/my',
    body,
    loader: 'administrative/gate-entry'
  })
  return response
}
