import { AdministrativeStaffsWorkloadResponse } from '@/shared/swagger'
import { Workload } from '../types'

export default (workload: AdministrativeStaffsWorkloadResponse): Workload[] => {
  const res: Workload[] = []
  const instructors = allInstructors(workload)
  instructors.forEach(instructor => {
    res.push(getInstructorInfo(instructor, workload))
  })
  return res
}

const allInstructors = (workload: AdministrativeStaffsWorkloadResponse): string[] => {
  const instructors: string[] = []
  workload.forEach(el => {
    if (!instructors.includes(el.employee_name)) {
      instructors.push(el.employee_name)
    }
  })
  return instructors
}

const countCourses = (courses: Workload['courses']): number => {
  const count: string[] = []
  courses.forEach(course => {
    if (!count.includes(course)) {
      count.push(course)
    }
  })
  return count.length
}

const getInstructorInfo = (instructor: string, workload: AdministrativeStaffsWorkloadResponse): Workload => {
  const courses: string[] = []
  const sections: string[] = []
  const student_count: number[] = []
  const ects: number[] = []
  const credits: string[] = []
  let total_credits = 0
  let total_students = 0
  let thesis_advisor = 0
  let hours_per_week = 0
  workload.forEach(el => {
    if (el.employee_name === instructor) {
      courses.push(`${el.course_code} - ${el.course_title}`)
      sections.push(el.section)
      student_count.push(el.student_count)
      ects.push(el.ects)
      credits.push(`${el.theory}-${el.practice}-${el.lab}`)
      total_credits += (el.theory + el.practice + el.lab)
      total_students += el.student_count
      thesis_advisor += el.thesis_count
      hours_per_week += el.credits
    }
  })
  return {
    instructor: instructor,
    courses: courses,
    sections: sections,
    student_count: student_count,
    ects: ects,
    credits: credits,
    course_count: countCourses(courses),
    total_students: total_students,
    total_credits: total_credits,
    thesis_advisor: thesis_advisor,
    hours_per_week: hours_per_week,
    total_hour: hours_per_week * 15
  }
}
