<template>
  <tbody>
    <tr
      v-for="(hour, index) in scheduleHours"
      :key="index"
      class="fs-5 text-center align-middle"
    >
      <td
        v-styler="{ paddingRight: 0 }"
        class="bg-light"
        :style="index === scheduleHours.length - 1 ? 'border-bottom-left-radius: 0.625rem' : ''"
      >
        <span
          class="text-dark fw-bolder d-block fs-5"
        >
          {{ hour.start_time }}
        </span>
        <span class="d-block fs-5">
          {{ hour.end_time }}
        </span>
      </td>
      <td
        v-for="day in scheduleWeekDays"
        :key="day.id"
        class="p-2 hover-bg-active fs-6"
      >
        <template
          v-for="(section, ind) in getSections(day.id, hour.start_time, hour.end_time)"
          :key="ind"
        >
          <div
            v-if="ind !== 0"
            class="separator separator-dashed my-3"
          />
          <p
            class="mt-1 m-0 text-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            :title="`<strong>${$t('common.course')}: </strong>${section.title}; <br> <strong>${$t('common.teacher')}: </strong> ${section.teacher?.full_name}`"
          >
            {{ section.code }} - {{ section.section }}
            <span
              v-if="section.id === singleSection?.id && section.program_id && +programId === +section.program_id"
              style="color: red;"
              @click="handleDeleteProgramSectionHour(section.id, section.schedule_id)"
            ><i
              class="bi bi-trash"
              style="color: red;"
            />
            </span>
          </p>
        </template>
        <i
          v-if="canAddHour"
          class="bi bi-plus-circle"
          @click="onClickAdd(day.id, hour.start_time, hour.end_time)"
        />
      </td>
    </tr>
  </tbody>
</template>
<script setup lang='ts'>
import { ref, watch, computed } from 'vue'
import { useAlert, useTeleportModal } from '@shared/composable'
import { ScheduleManagementSectionProgramHoursGetResponse } from '@shared/swagger'
import { scheduleWeekDays } from '@domains/ScheduleManagement/features/SectionPage/values'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useResource } from '@/shared/stores'

const resource = useResource()

const store = useScheduleManagement()
const { scheduleManagementSectionProgramHours, singleSection, selectedRoomId } = storeToRefs(store)
const { dialog, success, error } = useAlert()
const { t } = useI18n()
const { modifier } = useTeleportModal()

const scheduleHours = computed(() => {
  return resource.scheduleManagementSectionProgramHours ?? []
})

const getSectionHours = () => {
  let count = 0

  scheduleManagementSectionProgramHours.value.forEach(day => {
    day.hours.forEach(hour => {
      hour.sections.forEach(section => {
        if (singleSection.value) {
          if (section.id === singleSection.value.id) {
            count++
          }
        }
      })
    })
  })

  return count
}

const canAddHour = ref(false)

watch(() => scheduleManagementSectionProgramHours.value, () => {
  if (scheduleManagementSectionProgramHours.value && singleSection.value) {
    canAddHour.value = singleSection && singleSection.value.credits > getSectionHours()
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const programId = (modifier.value as any).data.id

const onClickAdd = (id: number, start_time: string, end_time: string) => {
  const hour = getHour(id, start_time, end_time)
  if (!hour || !singleSection.value) return
  dialog({
    text: `${t('common.hour')}: ${hour.start_time} - ${hour.end_time}, ${t('common.section')}: ${singleSection.value?.course.code} - ${singleSection.value?.section}`,
    onConfirm: async () => {
      if (!selectedRoomId.value || !singleSection.value) return
      // todo Add to schedule, do API query
      const response = await store.addProgramSectionHour(singleSection.value.id, programId, { hour_id: hour.id, room_id: selectedRoomId.value })
      if (response.status) {
        success({
          text: response.message
        })
      } else {
        error({
          text: response.message
        })
      }
    }
  })
}

const handleDeleteProgramSectionHour = async (section: number, scheduleId: number) => {
  dialog({
    onConfirm: async () => {
      await store.deleteProgramSectionHour(section, programId, scheduleId).then(data => {
        if (!data.status) {
          error({
            text: data.message
          })
        } else {
          success({
            text: data.message
          })
        }
      })
    }
  })
}

const props = defineProps({
  print: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:print'])

watch(
  () => props.print,
  (value) => {
    emit('update:print', value)
  }
)

const getSections = (day: number, start: string, end: string): (ScheduleManagementSectionProgramHoursGetResponse[0]['hours'][0]['sections'][0])[] => {
  const daySchedule = scheduleManagementSectionProgramHours.value.find((scheduleManagementSectionProgramHour) => {
    return scheduleManagementSectionProgramHour.id === day
  })
  if (!daySchedule) return []
  const hour = daySchedule.hours.find((hour) => {
    return hour.start_time === start && hour.end_time === end
  })
  return hour
    ? hour.sections.map((section) => {
      return { ...section, hour: { id: hour.id, start_time: hour.start_time, end_time: hour.end_time } }
    })
    : []
}

const getHour = (day: number, start: string, end: string): null | Pick<ScheduleManagementSectionProgramHoursGetResponse[0]['hours'][0], 'id' | 'start_time' | 'end_time'> => {
  const daySchedule = scheduleManagementSectionProgramHours.value.find((scheduleManagementSectionProgramHour) => {
    return scheduleManagementSectionProgramHour.id === day
  })
  if (!daySchedule) return null
  const hour = daySchedule.hours.find((hour) => {
    return hour.start_time === start && hour.end_time === end
  })
  if (!hour) return null

  return { id: hour.id, start_time: hour.start_time, end_time: hour.end_time }
}
</script>
