import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileContactsPutRequest } from '@shared/swagger'

export default async function putProfileContacts (contact_id: number, values: ProfileContactsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileContactsPutRequest, UnknownResponse>({
    url: `profile/contacts/${contact_id}`,
    body: values,
    loader: 'profile/contacts-edit'
  })

  return response
}
