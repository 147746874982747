<template>
  <select
    id="selectElement"
    :value="modelValue"
    @change="onChange($event)"
  >
    <option :value="0">
      EN
    </option>
    <option :value="1">
      KZ
    </option>
    <option :value="2">
      RU
    </option>
  </select>
</template>

<script setup lang="ts">
defineProps({
  modelValue: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:modelValue'])

const onChange = ($event) => {
  emit('update:modelValue', $event.target.value)
}
</script>
