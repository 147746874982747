export const tabs = [
  {
    id: 'confirmed',
    label: 'domains.academic.single-student.course-registration.confirmed-course',
    icon: 'bi-check2-all'
  },
  {
    id: 'temporary',
    label: 'domains.academic.single-student.course-registration.temporary-course',
    icon: 'bi-basket'
  }
]
