<template>
  <start-action v-if="!qrReaderStarted" />
  <qr-action v-else />
</template>
<script lang="ts" setup>
import { useAttendance } from './composable'
import StartAction from './ui/StartAction.vue'
import QrAction from './ui/QrAction.vue'

const { qrReaderStarted } = useAttendance()
</script>
