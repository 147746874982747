<template>
  <Teleport to="#addresses-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :disabled="loader"
            :loader="loader"
            :color="'primary'"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'

import type { ProfileAddressesGetResponse } from '@shared/swagger'
import type { FormField } from '@shared/types'

import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import { objectDeepCopy } from '@shared/utils'

import { useProfile } from '../../../store'

import { toOptionsType } from '../utils'
import { useI18n } from 'vue-i18n'
import { addressTypes } from '../values'

const { t } = useI18n()
const { isActive } = useLoader()
const { error, success } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const store = useProfile()
const resource = useResource()

const loader = isActive('profile/addresses-edit')

const addresses = computed(() => store.getAddresses)
const cities = computed(() => resource.getProfileResourceCities)
const regions = computed(() => resource.getProfileResourceRegions)
const provinces = computed(() => resource.getProfileResourceProvinces)
const countries = computed(() => resource.getProfileResourceCountries)

const formFields = computed<Array<FormField>>(() => {
  const provinceFiltered = provinces.value.filter((el) => el.parent_id === tempValues.value.country)
  const regionsFiltered = regions.value.filter((el) => el.parent_id === Number(tempValues.value.province))
  const cityFiltered = cities.value.filter((el) => el.parent_id === Number(tempValues.value.region))

  let result: Array<FormField> = [
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('domains.profile.addresses.country')}`,
        class: 'fs-6 fw-semibold mb-2 required'
      },
      name: 'country',
      type: 'select',
      value: tempValues.value.country,
      required: true,
      options: toOptionsType(countries.value),
      validator: {
        schema: 'yup',
        rules: [
          'number',
          'required',
          { func: 'label', value: t('domains.profile.addresses.error.country') }
        ]
      }
    },
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('domains.profile.addresses.province')}`,
        class: 'fs-6 fw-semibold mb-2'
      },
      name: 'province',
      type: 'select',
      value: tempValues.value.province || null,
      options: toOptionsType(provinceFiltered),
      validator: {
        schema: 'yup',
        rules: [
          'number',
          { func: 'label', value: t('domains.profile.addresses.error.province') }
        ]
      }
    }
  ]

  if (tempValues.value.province !== null && regionsFiltered.length > 0) {
    result = [
      ...result,
      {
        group: {
          class: 'fv-row mb-4 fv-plugins-icon-container'
        },
        label: {
          text: `${t('domains.profile.addresses.regions')}`,
          class: 'fs-6 fw-semibold mb-2'
        },
        name: 'region',
        type: 'select',
        value: tempValues.value.region || null,
        options: toOptionsType(regionsFiltered),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            { func: 'label', value: t('domains.profile.addresses.error.regions') }
          ]
        }
      }
    ]
  }

  if (tempValues.value.region !== null && cityFiltered.length > 0) {
    result = [
      ...result,
      {
        group: {
          class: 'fv-row mb-4 fv-plugins-icon-container'
        },
        label: {
          text: `${t('domains.profile.addresses.city')}`,
          class: 'fs-6 fw-semibold mb-2'
        },
        name: 'city',
        type: 'select',
        value: tempValues.value.city || null,
        options: toOptionsType(cityFiltered),
        validator: {
          schema: 'yup',
          rules: [
            'number',
            { func: 'label', value: t('domains.profile.addresses.error.city') }
          ]
        }
      }
    ]
  }

  return [
    ...result,
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('domains.profile.addresses.address-line')}`,
        class: 'fs-6 fw-semibold mb-2 required'
      },
      name: 'address_line',
      type: 'textarea',
      value: tempValues.value.address_line || '',
      required: true,
      validator: {
        schema: 'yup',
        rules: [
          'string',
          'required',
          { func: 'label', value: t('domains.profile.addresses.error.address-line') },
          'trim'
        ]
      }
    }
  ]
})

const defaultValues = {
  country: null,
  province: null,
  region: null,
  city: null,
  address_line: '',
  type: ''
}

const tempValues = ref<ProfileAddressesGetResponse>(objectDeepCopy(defaultValues))

const onChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue

  switch (name) {
    case 'country':
      tempValues.value.province = null
      tempValues.value.region = null
      tempValues.value.city = null
      break
    case 'province':
      tempValues.value.region = null
      tempValues.value.city = null
      break
    case 'region':
      tempValues.value.city = null
      break
  }
}

const onSubmit = async ({ values }) => {
  tempValues.value = {
    ...tempValues.value,
    ...values
  }
  if (!modifier.value || !modifier.value.data || !modifier.value.data.addressType) {
    error({ text: 'common.error' })
    return
  }
  const address_type = addressTypes[modifier.value.data.addressType as string]
  let address_id = tempValues.value.country || 1
  address_id = tempValues.value.province ? tempValues.value.province : address_id
  address_id = tempValues.value.region ? tempValues.value.region : address_id
  address_id = tempValues.value.city ? tempValues.value.city : address_id

  const { status, message } = await store.editAddress(address_type, { address_id, title: tempValues.value.address_line })

  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'addresses-modal' })
    store.$reset()
    store.fetchAddresses()
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'addresses-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.addressType) {
      const exactAddress = addresses.value.get(`${newValue.data.addressType}`)
      tempValues.value = objectDeepCopy(exactAddress || defaultValues)
      show()
    }
  }, { immediate: true }
)
</script>
