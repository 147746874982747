import type { StoreType } from '../types'

import { thesisStudentsSortField } from './../../values'

import { useAlert, usePagination } from '@shared/composable'
import { getAcademicAdvisoryThesisStudents } from '@shared/api'
import { convertSortFieldToParams } from '@/shared/utils'
import {
  AcademicAdvisoryThesisStudentsGetRequest,
  AcademicAdvisoryThesisStudentsGetResponse
} from '@shared/swagger'

export default async function fetchThesisStudents (this: StoreType, params: AcademicAdvisoryThesisStudentsGetRequest): Promise<void> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AcademicAdvisoryThesisStudentsGetResponse): void => {
    this.setThesisStudents(data.students || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAcademicAdvisoryThesisStudents({
      ...params,
      ...convertSortFieldToParams(thesisStudentsSortField.value),
      per_page: perPage,
      offset: currentPage
    })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getAcademicAdvisoryThesisStudents({
    ...params,
    ...convertSortFieldToParams(thesisStudentsSortField.value)
  })

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }
}
