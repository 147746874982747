import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManageCategoriesPostRequest, UnknownResponse } from '@shared/swagger'

export default async function postKnowledgeManageCategory (body: KnowledgeManageCategoriesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<KnowledgeManageCategoriesPostRequest, UnknownResponse>({
    url: 'knowledge/manage/categories',
    body,
    loader: 'knowledge/manage/categories'
  })
  return response
}
