<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <pms-card
    id="personalized-system-reports"
    :title="$t('domains.profile.overview.reports.title')"
    :description="$t('domains.profile.overview.reports.description')"
    :options="{body: {class: 'pt-0 pb-2'}}"
  >
    <pms-smart-list
      :items="reports"
      :options="{item: {class:'d-flex align-items-sm-center mb-7'},toggle: {container: {class: 'd-flex align-items-center flex-row-fluid flex-wrap'}}}"
    >
      <template #item="{item}">
        <div class="symbol symbol-50px me-5">
          <pms-kt-icon
            icon-name="graph-up"
            class="fs-3x"
          />
        </div>
        <div class="flex-grow-1 me-2">
          <div class="text-gray-800 fs-6 fw-bolder">
            {{ item.title }}
          </div>
          <span class="text-muted fw-bold d-block fs-7">
            {{ item.description.length > descriptionLength ? item.description.slice(0, descriptionLength) + ' ...'
              : item.description
            }}
          </span>
        </div>
        <router-link
          v-test-id="'link'"
          class="btn btn-sm btn-light fw-bolder my-2"
          :to="{ path: item.link }"
        >
          {{ $t('domains.dashboard.widgets.reports.open') }}
        </router-link>
      </template>
    </pms-smart-list>
    <div
      v-if="reports.length === 0"
      v-test-id="'no-data'"
      class="d-flex align-items-center justify-content-center min-h-150px"
    >
      {{ $t('common.no-data') }}
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useProfile } from '../../../store'

const store = useProfile()

const reports = computed(() => store.getOverviewReports)
const descriptionLength = 25
</script>
