import { I18nOptions } from 'vue-i18n'
import { AvocadoLanguage } from '@avocado/types'

export const buildI18nOptions = (i18n: Array<AvocadoLanguage>): I18nOptions => {
  const currentLocale = window.localStorage.getItem('locale') || process.env.APP_LOCALE
  const locale: string = currentLocale || 'en-EN'
  const messages: Record<string, unknown> = {}

  i18n.forEach((language) => { messages[language.locale] = language.messages })

  window.localStorage.setItem('locale', locale)

  return {
    legacy: false,
    globalInjection: true,
    locale,
    messages: messages as never
  }
}
