<template>
  <service-wrapper>
    <pms-row>
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'content'"
        :filter="true"
      />
      <template v-else>
        <template
          v-for="service in services"
          :key="service.id"
        >
          <pms-grid
            :col="4"
            class="mt-4"
          >
            <service-item :service="service" />
          </pms-grid>
        </template>
      </template>
    </pms-row>
  </service-wrapper>
</template>

<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { services } from './values'

import ServiceWrapper from './ui/ServiceWrapper.vue'
import ServiceItem from './ui/ServiceItem.vue'

const loader = useLoader().isActive('services')
</script>
