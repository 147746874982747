<template>
  <div v-if="examParticipantsList">
    <table
      width="100%"
      border="0"
    >
      <tbody>
        <tr>
          <td>
            <br>

            <table
              style="line-height:17px"
              width="100%"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="100%"
                      border="0"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            width="40%"
                          >
                            <b>SDU UNIVERSITY<br>ЕМТИХАНҒА ҚАТЫСУ ПАРАҒЫ</b>
                          </td>
                          <td align="center">
                            <img src="/sdu.gif">
                          </td>
                          <td
                            align="center"
                            width="40%"
                          >
                            <b>SDU UNIVERSITY<br>EXAM PARTICIPATION LIST</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <table
            width="100%"
            border="0"
            class="mt-3"
          >
            <tbody>
              <tr height="25">
                <td width="130">
                  <b>Пән | Course:</b>
                </td>
                <td colspan="3">
                  {{ examParticipantsList.base_info.course }}
                </td>
              </tr>
              <tr height="25">
                <td><b>Оқытушы | Instructor:</b></td>
                <td>{{ examParticipantsList.base_info.instructor }}</td>
                <td width="150">
                  <b><strong>Қолы | Signature:</strong></b>
                </td>
                <td>
                    &nbsp;
                </td>
              </tr>
              <tr height="25">
                <td><b>Күні | Date:</b></td>
                <td>&nbsp;</td>
                <td width="100">
                  <b>Емтихан түрі | Exam Type:</b>
                </td>
                <td
                  width="120"
                  align="left"
                >
                  {{ examParticipantsList.base_info.exam_type }}
                </td>
              </tr>
              <tr>
                <td><b>Сағаты | Time:</b></td>
                <td>&nbsp;</td>
                <td><b>Орыны | Place:</b></td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </tr>
        <tr>
          <table
            border="1"
            bordercolor="#A0A0A0"
            width="100%"
            style="border-spacing: 0;"
            class="my-3"
          >
            <tbody>
              <tr>
                <td
                  width="5%"
                  rowspan="2"
                  align="center"
                >
                  №
                </td>
                <td
                  width="10%"
                  rowspan="2"
                  align="center"
                >
                  Мамандық<br>Speciality
                </td>
                <td
                  width="12%"
                  rowspan="2"
                  align="center"
                >
                  Студент №<br>Student ID
                </td>
                <td
                  rowspan="2"
                  align="center"
                >
                  Аты жөні<br>Surname, Name
                </td>
                <td
                  :colspan="gradeAssessments.length"
                  align="center"
                >
                  Бағалары<br>Grades
                </td>
                <td
                  width="22%"
                  rowspan="2"
                  align="center"
                >
                  Емтиханға рұқсат<br>Exam Permission
                </td>
                <td
                  width="10%"
                  rowspan="2"
                  align="center"
                >
                  Студенттің қолы<br>Student Signature
                </td>
              </tr>
              <tr>
                <td
                  v-for="assesment in gradeAssessments"
                  :key="assesment.assessment_id"
                  width="15"
                  align="center"
                >
                  {{ assesment.assessment_type }}
                </td>
              </tr>
              <tr
                v-for="(student, index) in examParticipantsList.students"
                :key="student.student_id"
              >
                <td
                  align="center"
                >
                  {{ index+1 }}
                </td>
                <td
                  align="center"

                  title="10107 - Computer Science"
                >
                  {{ student.specialty.split(' ')[0] }}<br>{{ student.specialty.split(' ')[1] }}
                </td>
                <td
                  align="center"
                >
                  {{ student.student_id }}
                </td>
                <td
                  align="left"
                  style="padding:12px"
                >
                  {{ student.surname_name }}
                </td>

                <td
                  v-for="assesment in gradeAssessments"
                  :key="assesment.assessment_id"
                  align="center"
                >
                  {{ getStudentGrade(student, assesment) }}
                </td>

                <td

                  align="center"
                >
                  <div v-html="student.exam_permission" />
                </td>
                <td>
                    &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </tr>
        <tr>
          <td
            colspan="8"
            valign="top"
            align="center"
          >
            Жоғарыда қол қоя отырып, студент емтиханда өзін-өзі ұстау ережесімен танысқандығын растап, оның орындалуын өз міндетіне алады.<br>
            By signing above, the student confirms that he/she read the examination rules for students and agrees to obey them.
            <br><br>
            <table
              id="TabIDFooter"
              width="100%"
              border="0"
            >
              <tbody>
                <tr>
                  <td width="25%">
                    Бақылаушының аты жөні<br>Proctor's name, surname:
                  </td>
                  <td>&nbsp;</td>
                  <td width="5%">
                    &nbsp;
                  </td>
                  <td align="right">
                    Бақылаушының қолы<br>Proctor's signature
                  </td>
                </tr>
                <tr>
                  <td>Пікірлер<br>Comments:</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td align="right">
                    ________________
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { useResource } from '@/shared/stores'
import { AcademicCoursesGradesParticipantsGetResponse, AcademicCoursesResourcesSingleResponse } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const store = useSingleSection()
const resource = useResource()

const { examParticipantsList, currentSection } = storeToRefs(store)
const gradeAssessments = computed(() => resource.getAcademicSingleCoursesResourceAssessments
  .filter((a) => a.section_id === currentSection.value.section_id)
  .sort((a) => {
    if (a.assessment_type === 'FA') return -1
    if (a.assessment_type === 'FIN') return -1
    return 1
  }).filter(a => a.assessment_type !== 'LMS')
)

const getStudentGrade = (student: AcademicCoursesGradesParticipantsGetResponse['students'][0], assesment: AcademicCoursesResourcesSingleResponse['assessments'][0]): string => {
  const grade = student.grades.find(g => g.assessment_id === assesment.assessment_id)
  return grade ? grade.grade + '' : ''
}
</script>
