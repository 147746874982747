<template>
  <a
    class="fs-5 link-primary fw-bold btn btn-link p-0"
    href="javascript:void(0)"
    @click="handleRedirectToPost(props.uuid)"
  >
    {{ $t('common.read-more') }}
  </a>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
const props = defineProps<{
  uuid: string
}>()

const handleRedirectToPost = (uuid: string) => {
  router.push({
    path: `/knowledge-base/manage/article/${uuid}`,
    params: {
      uuid: uuid
    }
  })
}
</script>
