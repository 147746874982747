import { AcademicCoursesResourcesSingleResponse } from '@/shared/swagger'
import { sensitiveObject } from '@shared/utils'

export default (resource: AcademicCoursesResourcesSingleResponse | null): AcademicCoursesResourcesSingleResponse['sections'] => {
  if (!resource) return []

  const { username } = sensitiveObject()
  const { sections } = resource
  const me = resource.instructors.find((instructor) => instructor.username === username)

  if (me) {
    const { sections: mySections } = me
    return sections.filter((section) => mySections.includes(section.section_id))
  }
  return []
}
