import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumTrackRequest, AcademicManageCurriculumTrackResponse } from '@shared/swagger'

export default async function findTrackById (params: AcademicManageCurriculumTrackRequest): Promise<AxiosResponse<AcademicManageCurriculumTrackResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumTrackResponse>({
    url: `/academic/manage/curriculum/tracks/${params.track}`,
    body: { },
    loader: 'academic/manage/curriculum/tracks/:track'
  })

  return response
}
