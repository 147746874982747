<template>
  <pms-container class="mt-10">
    <pms-row>
      <pms-grid :col="12">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-row">
            <button
              class="btn btn-light-light btn-sm pt-1 fs-6 fw-bold"
              @click="goBack()"
            >
              Go back
            </button>
            <h2 class="badge badge-primary fs-2">
              <span class="fw-bolder ms-2 pe-2">{{ path }}</span>
            </h2>
          </div>
          <div class="d-flex flex-row">
            <button
              class="btn btn-success btn-sm me-2"
              @click="saveTranslate()"
            >
              <span class="fs-4 me-2">Save</span>
              <i class="bi bi-save fs-4 p-0 m-0" />
            </button>
            <select
              class="form-select form-select-md w-200px"
              @change="setLocale($event)"
            >
              <option
                v-for="locale in locales"
                :key="locale"
                :value="locale"
                :selected="locale === currentLocale"
              >
                {{ locale }}
              </option>
            </select>
          </div>
        </div>
      </pms-grid>
      <pms-grid
        class="mt-3"
        :col="12"
      >
        <pms-card :header="false">
          <pms-smart-form
            :fields="formFields"
            :options="{
              labelContainer: {
                left: {
                  class: 'col-md-2 col-sm-6'
                },
                right: {
                  class: 'col-md-10'
                }
              }
            }"
            @handleSubmit="() => {}"
            @handleChange="onChange"
          >
            <template #formControls />
          </pms-smart-form>
        </pms-card>
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { SingleContent } from '@shared/types'
import { objectDeepCopy } from '@shared/utils'
import { useAlert } from '@shared/composable'

import { useStore } from '../store'
import { getFields, applyFieldValues } from '../utils'

const { success, error } = useAlert()

const router = useRouter()
const route = useRoute()
const store = useStore()

const path = computed(() => route.params.path)
const locales = computed(() => store.getLocales)
const formFields = computed(() => applyFieldValues(store.getPath?.formConfigs, tempValues.value))

const currentLocale = ref('en-EN')
const tempValues = ref({})

const goBack = () => {
  router.go(-1)
}

const setLocale = ($event) => {
  currentLocale.value = $event.target.value
  store.setContent(tempValues.value)
  const currentLocaleItem = store.getPath?.contents.find((item) => item.locale === $event.target.value)

  tempValues.value = currentLocaleItem
    ? objectDeepCopy(currentLocaleItem)
    : { locale: $event.target.value, ...getFields(store.getPath?.formConfigs) }

  console.log(store.getPath)
}

const saveTranslate = async () => {
  store.setContent(tempValues.value)
  const { ok, message } = await store.saveContentByPath(path.value as string)

  ok
    ? success({ text: message, isToast: true })
    : error({ text: message })
}

const onChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

watch(
  () => store.getPath,
  (newValue: null | SingleContent) => {
    if (newValue) {
      const currentLocaleItem = newValue.contents.find((item) => item.locale === currentLocale.value)

      tempValues.value = currentLocaleItem
        ? objectDeepCopy(currentLocaleItem)
        : { locale: currentLocale.value, ...getFields(newValue.formConfigs) }
    }
  },
  { immediate: true }
)
</script>
