import { useEncrypt, useToken } from '@shared/composable'

const allFeedbacks = [
  'domains.dashboard.feedback.feedback-option-by-role-teacher',
  'domains.dashboard.feedback.feedback-option-by-role-administrator',
  'domains.dashboard.feedback.feedback-option-by-role-expert',
  'domains.dashboard.feedback.feedback-option-by-role-coordinator'
]

const filterFunction = (feedback: string) => {
  let decrypted, roles
  const { decrypt } = useEncrypt()
  const sensitive = window.localStorage.getItem('sensitive')

  if (!sensitive) return false

  try {
    decrypted = decrypt(sensitive || '') as Record<string, unknown>
    roles = decrypted.roles as string[]
  } catch (e) {
    useToken().clearTokens()
  }

  if (roles.length === 0) return true
  const including = roles.reduce((acc: Array<boolean>, role) => {
    return [
      ...acc,
      feedback.includes(role as string)
    ]
  }, [])
  return including.includes(true) || false
}

export default [
  ...allFeedbacks.filter(filterFunction),
  'domains.dashboard.feedback.feedback-option-for-widgets'
]
