import { ProfileOverviewResponse } from '@shared/swagger'

type InfoFormatted = {
    type: 'plain' | 'custom',
    translate: string,
    translateIcon?: boolean,
    translateIconText?: string,
    field: string | Array<string>
}

const leftSideItems: Array<InfoFormatted> = [
  {
    type: 'plain',
    translate: 'fullname',
    field: ['name', 'surname']
  },
  {
    type: 'plain',
    translate: 'native',
    field: ['name_native', 'patronymic_native', 'surname_native']
  },
  {
    type: 'plain',
    translate: 'username',
    translateIcon: true,
    translateIconText: 'username',
    field: 'username'
  },
  {
    type: 'plain',
    translate: 'country',
    field: 'country'
  },
  {
    type: 'custom',
    translate: 'birth-date',
    field: 'birth_date'
  },
  {
    type: 'plain',
    translate: 'gender',
    field: 'gender'
  }
]

const rightSideItems: Array<InfoFormatted> = [
  {
    type: 'plain',
    translate: 'last-log-date',
    field: 'last_log_date'
  },
  {
    type: 'plain',
    translate: 'last-log-ip',
    field: 'last_log_ip'
  },
  {
    type: 'plain',
    translate: 'email',
    field: 'mail'
  },
  {
    type: 'plain',
    translate: 'inner-phone',
    field: 'inner_phone'
  },
  {
    type: 'custom',
    translate: 'positions',
    translateIcon: true,
    translateIconText: 'positions',
    field: 'positions'
  }
]

export default (info: ProfileOverviewResponse['personal'] | null) => {
  if (info == null) return []

  return [
    leftSideItems,
    rightSideItems
  ].map((side) => {
    return side.map((item) => {
      if (item.type === 'plain') {
        return {
          ...item,
          key: `domains.profile.overview.info.${item.translate}`,
          translateIconText: item.translateIcon ? `domains.profile.overview.info.${item.translateIconText}` : undefined,
          value: Array.isArray(item.field)
            ? item.field.map(el => info[el] || '').join(' ')
            : info[item.field] || ''
        }
      }

      if (item.type === 'custom') {
        return {
          ...item,
          key: `domains.profile.overview.info.${item.translate}`,
          translateIconText: item.translateIcon ? `domains.profile.overview.info.${item.translateIconText}` : undefined,
          value: !Array.isArray(item.field) ? info[item.field] || null : null
        }
      }

      return {}
    })
  })
}
