import type { StoreType } from '../types'
export default function setContent (this: StoreType, content: Record<string, unknown>): void {
  if (this.getPath) {
    const index = this.getPath.contents.findIndex((item) => item.locale === content.locale)

    if (index !== -1) {
      this.getPath.contents[index] = content
    } else {
      this.getPath.contents.push(content)
    }
  }
}
