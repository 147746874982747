import type { StoreType } from '../types'
import type { AdministrativeGateEntryRequest } from '@shared/swagger'

import { postAdministrativeGateEntry } from '@shared/api'
import { useAlert } from '@shared/composable'
import { user_data } from '../../values'

export default async function fetchRecords (this: StoreType, body: AdministrativeGateEntryRequest): Promise<void> {
  const { error } = useAlert()

  const { ok, message, data } = await postAdministrativeGateEntry(body)
  if (ok) {
    this.setRecords(data)
    user_data.value = body
  } else {
    error({ text: message })
  }
}
