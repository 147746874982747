import type { StoreType } from '../types'
import type { AcademicSingleCourseGradeRequest } from '@shared/swagger'

import { getAcademicSingleCourseGrades } from '@shared/api'

export default async function fetchGrades (this: StoreType, values: AcademicSingleCourseGradeRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleCourseGrades(values)

  if (ok) {
    this.setGrades(data)
  }
}
