import type { StoreType } from '../types'
import type { ProfileOverviewResponse } from '@shared/swagger'

export default function getOverviewCv (this: StoreType): ProfileOverviewResponse['cv'] {
  return this.overview?.cv || {
    progress: 0,
    filled: [],
    empty: []
  }
}
