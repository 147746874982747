import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileSkillsLanguagesPostRequest } from '@shared/swagger'

export default async function postProfileSkillsLanguages (params: ProfileSkillsLanguagesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileSkillsLanguagesPostRequest, UnknownResponse>({
    url: 'profile/skills/languages',
    body: params,
    loader: 'profile/skills/languages-post'
  })

  return response
}
