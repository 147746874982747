import { SmartTableHeader } from '@/shared/types'

export const registrationCourseTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no'
  },
  {
    title: 'common.table.code',
    field: 'course_code'
  },
  {
    title: 'common.table.lecture-sections',
    field: 'lecture_section'
  },
  {
    title: 'common.table.practice-sections',
    field: 'practice_section'
  },
  {
    title: 'common.table.lab-sections',
    field: 'lab_section'
  },
  {
    title: 'common.table.course-title',
    field: 'course_title'
  },
  {
    title: 'common.table.theory',
    field: 'theory',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.practice',
    field: 'practice',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.credits',
    field: 'credits',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.ects',
    field: 'ects',
    headerClass: 'text-center'
  }
]
