import { ref } from 'vue'
import { RouteSignQuery, SignDecoded } from '@shared/types'
import { useEncrypt, useAlert } from '@shared/composable'

const isValid = ref(false)
const signEncoded = ref<RouteSignQuery | null>(null)
const signDecoded = ref<SignDecoded | null>(null)

const useSign = () => {
  const { decrypt } = useEncrypt()
  const { error } = useAlert()

  const provide = (routeSign: RouteSignQuery) => {
    isValid.value = routeSign.ct !== undefined && routeSign.iv !== undefined && routeSign.s !== undefined
    signEncoded.value = routeSign

    try {
      signDecoded.value = decrypt(JSON.stringify(routeSign)) as unknown as SignDecoded || null
    } catch (e) {
      error({ title: 'common.error', text: 'common.decrypt-error' })
      reset()
    }
  }

  const getEncoded = (): RouteSignQuery | null => signEncoded.value
  const getDecoded = (): SignDecoded | null => signDecoded.value

  const reset = () => {
    isValid.value = false
    signDecoded.value = null
    signEncoded.value = null
  }

  return {
    isValid,
    provide,
    reset,
    getEncoded,
    getDecoded
  }
}

export default useSign
