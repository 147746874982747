import { SelectField } from '@shared/types'
import { AcademicManageSyllabusResourcesResponse } from '@shared/swagger'

export default (data: AcademicManageSyllabusResourcesResponse['teaching_method_types']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((reading) => {
    options.push({
      label: reading.title,
      value: reading.id
    })
  })

  return options
}
