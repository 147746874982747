<template>
  <div class="row">
    <div
      v-for="item in passwordModes"
      :key="item"
      class="col-12 col-md-6"
    >
      <div
        class="p-6 cursor-pointer"
        :class="{
          'border border-primary border-dashed rounded': item === passwordModeName,
        }"
        @click="passwordModeName = item"
      >
        <div class="d-flex align-items-center flex-grow-1 me-2">
          <div class="symbol symbol-50px me-4">
            <span class="symbol-label">
              <span
                class="svg-icon svg-icon-2qx"
                :class="{
                  'svg-icon-primary': item === passwordModeName,
                }"
              >
                <pms-kt-icon
                  :icon-name="'abstract-28'"
                  class="fs-2hx p-0"
                />
              </span>
            </span>
          </div>
          <div class="me-2">
            <span class="text-gray-800 fs-5 fw-bold">
              {{ $t(`domains.profile.settings.password-card.password-mode.${item}`) }}
            </span>
            <span class="text-gray-400 fw-bold d-block fs-7">
              {{ $t(`domains.profile.settings.password-card.password-mode.${item}-description`) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-8 mt-3">
    <div
      v-for="item in passwordModes"
      v-show="item === passwordModeName"
      :key="item"
      class="col-12"
    >
      <div class="notice d-flex bg-light-primary rounded p-6 mt-5">
        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
          <pms-kt-icon
            :icon-name="'abstract-2'"
            class="fs-2hx p-0"
          />
        </span>
        <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <div class="mb-3 mb-md-0 fw-semibold">
            <h4 class="text-gray-900 fw-bold">
              {{ $t(`domains.profile.settings.password-card.send-reset-link-${item}-title`) }}
            </h4>
            <div class="fs-6 text-gray-700 pe-7">
              {{ $t(`domains.profile.settings.password-card.send-reset-link-${item}-description`) }}
            </div>
          </div>
          <a
            href="javascript:void(0);"
            class="btn btn-primary px-6 align-self-center text-nowrap"
            @click="sendResetLink(item)"
          >
            {{ !linkLoader ? $t('domains.profile.settings.password-card.send-reset-link') : $t('common.loading') }}
            <span
              v-if="linkLoader"
              class="spinner-border spinner-border-sm align-middle ms-2"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAlert, useLoader } from '@shared/composable'
import { useProfile } from '../../../store'
import { passwordMode as passwordModeName, passwordModes } from '../values'

const { isActive } = useLoader()
const { error, success } = useAlert()

const store = useProfile()

const linkLoader = isActive('profile/settings/passwords/reset-links-post')

const sendResetLink = async (option) => {
  const { status, message } = await store.sendResetLink({ method: 'email', reset: option })

  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

</script>
