<template>
  <Teleport to="#contacts-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileContactsGetResponse } from '@shared/swagger'
import { useTeleportModal, useLoader, useAlert } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { toOptionsType } from '../utils'

const { t } = useI18n()
const { isActive } = useLoader()
const { success, error } = useAlert()
const { close, modifier, show, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()

const loader = isActive('profile/contacts-edit')

const contactTypes = computed(() => resource.getProfileResource?.contact_types || [])
const contacts = computed(() => store.getContacts)

const defaultTempValues: ProfileContactsGetResponse[0] = {
  id: -1,
  contact: '',
  type_id: -1,
  show_status: false
}
const tempValues = ref<ProfileContactsGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.contacts.type'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'type_id',
    type: 'select',
    value: tempValues.value.type_id || null,
    options: toOptionsType(contactTypes.value),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        { func: 'label', value: t('domains.profile.contacts.error.type') }
      ]
    }

  },
  {
    label: {
      text: t('domains.profile.contacts.contact'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-1'
    },
    name: 'contact',
    type: 'text',
    value: tempValues.value.contact,
    placeholder: t('domains.profile.contacts.contact-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  }
])

const onSubmit = async ({ values }) => {
  const { status, message } =
  tempValues.value.id === -1
    ? await store.addContact({ ...values })
    : await store.editContact(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'contacts-modal' })
    unsetModifier()
    store.fetchContacts()
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'contacts-modal' })
  unsetModifier()
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactContact = contacts.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactContact || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
    }
    show()
  },
  { immediate: true }
)
</script>
