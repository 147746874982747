import { useRouter } from 'vue-router'
import { CourseListItem } from '../types'
import { useManageCourses } from '../store'
import { AcademicCoursesResponse, AcademicManageCoursesDeleteRequest } from '@/shared/swagger'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'
export function useAcademicManageCoursesComposable () {
  const store = useManageCourses()
  const router = useRouter()
  const { success, error, dialog } = useAlert()

  const { t } = useI18n()

  const goToNewCoursePage = () => {
    router.push({
      name: 'AcademicManageCoursesCreatePage',
      params: {
        mode: null
      }
    })
  }

  const goToEditCoursePage = (item: CourseListItem) => {
    router.push({
      name: 'AcademicManageCoursesEditPage',
      params: {
        code: item.code,
        year: item.year
      }
    })
  }

  async function deleteCourse (values:AcademicCoursesResponse['courses'][0]) {
    const request:AcademicManageCoursesDeleteRequest = {
      code: values.code,
      year: values.year
    }

    dialog({
      title: 'common.are-you-sure',
      text: t('domains.academic.manage.course.course.delete-text', { name: values.title_en }),
      onConfirm: async () => {
        const { status, message } = await store.delete_course(request)
        status
          ? success({ text: message, isToast: true })
          : error({ text: message })
      }
    })
  }

  return {
    goToNewCoursePage,
    goToEditCoursePage,
    deleteCourse
  }
}
