import { SmartTableHeader } from '@/shared/types'

export const coursesHeaders: Array<SmartTableHeader> = [
  {
    title: 'no matter what i write',
    field: 'checkbox',
    slotName: 'select-all-checkbox',
    slotCellName: 'checkbox'
  },
  {
    title: 'common.student_id',
    field: 'id'
  },
  {
    title: 'common.table.student-fullname',
    field: 'fullname',
    slotCellName: 'fullname'
  }
]
