<template>
  <pms-card :header="false">
    <pms-search
      v-model:value="search"
      :placeholder="'domains.administrative.message-board.search-placeholder'"
      @handle-search="submitSearch"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { LocationQueryRaw, useRouter } from 'vue-router'

const search = ref<string>('')
const router = useRouter()

const submitSearch = async () => {
  const query : LocationQueryRaw = {}
  if (search.value === '') return

  query.search = search.value

  router.push({
    name: 'KnowledgeManagePostsListing',
    query
  })
}
</script>
