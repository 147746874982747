import { getServiceManageAnnouncementsResources } from '@/shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'

export default async function fetchRuleCategories (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceManageAnnouncementsResources()

  ok && this.setTargets(data)
  return {
    status: ok,
    message
  }
}
