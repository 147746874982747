import { StoreType } from '../types'

import { destroyAcademicManageCourseRequisiteItem, getAcademicManageCourseRequisiteItem, postAcademicManageCourseRequisiteItem } from '@/shared/api'
import { AcademicManageCoursesRequisitesItemsPostRequest, AcademicManageCoursesRequisitesItemsGetRequest, AcademicManageCoursesRequisitesItemsDeleteRequest } from '@/shared/swagger'

export async function create_requisite_item (this: StoreType, request:AcademicManageCoursesRequisitesItemsPostRequest) {
  const { ok, message, data } = await postAcademicManageCourseRequisiteItem(request)

  if (ok) {
    this.requisite_info_response.id = data.requisite_id
  }

  return {
    status: ok,
    message
  }
}
export async function search_requisite_items (this: StoreType, request:AcademicManageCoursesRequisitesItemsGetRequest) {
  const { ok, message, data } = await getAcademicManageCourseRequisiteItem(request)

  if (ok) {
    this.setRequisiteItemsResponse(data)
    this.requisite_items_response.sort((a, b) => {
      if (a.key > b.key) return 1
      else return 0
    })
  }

  return {
    status: ok,
    message
  }
}
export async function delete_requisite_item (this: StoreType, request:AcademicManageCoursesRequisitesItemsDeleteRequest) {
  const { ok, message } = await destroyAcademicManageCourseRequisiteItem(request)

  return {
    status: ok,
    message
  }
}
