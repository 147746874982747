import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumGetCoreCoursesForReferenceRequest, AcademicManageCurriculumGetCoreCoursesForReferenceResponse } from '@shared/swagger'

export default async function fetchCoreCoursesForReference (params: AcademicManageCurriculumGetCoreCoursesForReferenceRequest): Promise<AxiosResponse<AcademicManageCurriculumGetCoreCoursesForReferenceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumGetCoreCoursesForReferenceResponse>({
    url: 'academic/manage/curriculum/references/courses',
    body: { params },
    loader: 'get-academic/manage/curriculum/references/courses'
  })

  return response
}
