import type { StoreType } from '../types'
import type { AdministrativeStaffsRequest, AdministrativeStaffsResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { getAdministrativeStaffs } from '@shared/api'
import { usePagination } from '@shared/composable'

export default async function fetchEmployees (this: StoreType, values: AdministrativeStaffsRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AdministrativeStaffsResponse): void => {
    this.setEmployees(data.employees || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAdministrativeStaffs({ ...values, per_page: perPage, offset: currentPage })

    if (ok) setData(data)
  })

  const { ok, message, data } = await getAdministrativeStaffs(values)

  if (ok) setData(data)
  return {
    status: ok,
    message
  }
}
