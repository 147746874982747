import { destroyProfileEducation } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteEducation (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyProfileEducation(id)

  if (ok) await this.fetchEducations()

  return {
    status: ok,
    message
  }
}
