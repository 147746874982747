
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useDashboard = defineStore<Name, State, Getters, Actions>('Dashboard.useDashboard', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      currentRole: '',
      greetings: [],
      schedules: [],
      courses: [],
      attendances: [],
      facultyStatistics: [],
      rulesAndRegulations: [],
      syllabusStatistics: [],
      posts: [],
      reports: [],
      moodleActivities: [],
      refresh_token: '',
      widgets: {}
    }
  }
})
