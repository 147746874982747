import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicReportGetRequest, AcademicReportGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params:AcademicReportGetRequest): Promise<AxiosResponse<AcademicReportGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicReportGetResponse>({
    url: `academic/information/reports/${params.report}`,
    body: { },
    loader: 'academic/information/reports/single_report'
  })

  return response
}
