import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAnnouncements } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useAnnouncements()
  store.$reset()
  store.bootstrap(String(to.name))
  next()
}

export default {
  layout: 'default',
  path: '/service/manage/announcements',
  children: [
    {
      path: '',
      name: 'AnnouncementsPage',
      component: () => import('./AnnouncementsPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'create',
      name: 'CreateNewAnnouncementPage',
      component: () => import('./features/CreateNewAnnouncement/CreateNewAnnouncementPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: ':id',
      name: 'EditAnnouncementPage',
      component: () => import('./features/EditExistingAnnouncement/EditAnnouncementPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
