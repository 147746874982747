import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ServiceMessageBroadcastGetRequest,
  ServiceMessageBroadcastGetResponse
} from '@shared/swagger'

export default async function getServiceMessageBroadcast (params: ServiceMessageBroadcastGetRequest): Promise<AxiosResponse<ServiceMessageBroadcastGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceMessageBroadcastGetResponse>({
    url: 'service/message-broadcast',
    body: { params },
    loader: 'service/message-broadcast'
  })

  return response
}
