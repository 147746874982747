import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { deleteKnowledgeManageSinglePost } from '@/shared/api'

export default async function deletePost (this: StoreType, uuid: string): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteKnowledgeManageSinglePost(uuid)

  return {
    status: ok,
    message: message
  }
}
