import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  console.log('handleAuthRoute')

  next()
}

const mainPage: RouteRecordWithLayout = {
  layout: 'empty',
  path: '/student/attendance',
  children: [
    {
      path: '',
      name: 'StudentAttendancePage',
      component: () => import('./AttendancePage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
}

export default mainPage
