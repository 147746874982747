<template>
  <pms-container>
    <pms-row
      v-if="loaderSingle || resourceLoader"
      class="mt-6"
    >
      <pms-page-shimmer
        :shimmer="`card`"
        :filter="true"
      />
    </pms-row>

    <pms-row
      v-else
      class="mt-6"
    >
      <pms-grid
        v-if="singleKnowledgeBaseData"
        :col="8"
        :order-mb="1"
        class="mt-3 mt-md-0"
      >
        <pms-card :header="false">
          <div
            class="d-flex justify-content-between mb-4"
          >
            <pms-button
              :variant="'tonal'"
              :size="'sm'"
              :text="'domains.knowledge-base.search.back-to-main'"
              :icon-placement="'start'"
              :icon="'bi bi-arrow-left'"
              @click="backToMain"
            />
            <div class="d-flex align-items-center gap-2">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </div>

          <div id="knowledge-post-single-post">
            <h3 class="fs-2 fw-bold mb-0">
              {{ singleKnowledgeBaseData.title }}
            </h3>
            <KnowledgeBasePost :single-knowledge-base="singleKnowledgeBaseData" />
          </div>
          <ContentSelector />
          <template
            v-for="(content, index) in singleKnowledgeBaseData.contents"
            :key="index"
          >
            <div>
              <TutorialsContent
                v-if="content.type_uuid === '049417af-eb88-4f22-b1aa-dfd5929c6a09' && content.uuid === active"
                :content="content"
              />
              <GuidesContent
                v-if="content.type_uuid === 'cdd11c96-4baf-42d5-a53c-78a2ff1e725e' && content.uuid === active"
                :content="content"
              />
              <FaqContent
                v-if="content.type_uuid === 'c2ca8b39-531c-4b61-b8ee-3a2d9b57007b' && content.uuid === active"
                :content="content"
              />
            </div>
          </template>
        </pms-card>
      </pms-grid>
      <pms-grid
        :col="4"
        class="order-first order-md-last"
      >
        <SearchBar
          id="knowledge-post-search-bar"
        />
        <RelatedPosts
          id="knowledge-post-related-posts"
          class="mt-3 d-none d-md-block"
        />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { router } from '@/router'
import { useLoader, useDriver } from '@shared/composable'

import { useKnowledgeBaseSingle } from './store'
import { active, quickTour } from './values'

import SearchBar from './ui/SearchBar.vue'
import RelatedPosts from './ui/RelatedPosts.vue'
import KnowledgeBasePost from './ui/KnowledgeBasePost.vue'

import ContentSelector from './ui/KnowledgeBaseSingleContentSelector.vue'
import TutorialsContent from './ui/TutorialsContent.vue'
import FaqContent from './ui/FAQContent.vue'
import GuidesContent from './ui/GuidesContent.vue'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loaderSingle = isActive('knowledge/posts/post_uuid')
const resourceLoader = isActive('knowledge/resources')

const store = useKnowledgeBaseSingle()
const singleKnowledgeBaseData = computed(() => store.getSingleKnowledgeBase)

const backToMain = () => {
  router.push({ path: '/knowledge-base' })
}
onMounted(async () => {
  setQuickTourSteps(await quickTour())
})
</script>
