
import type { StatusMessageResponse } from '@/shared/types'
import { putAcademicManageSyllabusDescriptions } from '@shared/api'
import { useAlert } from '@shared/composable'
import { UserEditDescription } from '../../types'
import type { StoreType } from '../types'
import { fetchSyllabusAfterChange } from '../../utils'

export default async function editDescriptions (this: StoreType, values: UserEditDescription): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicManageSyllabusDescriptions({ ...this.requestGetter, ...values })
  const { error } = useAlert()

  if (ok) {
    fetchSyllabusAfterChange(this.year, this.course_code)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
