<template>
  <Teleport to="#email-phone-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :disabled="loader"
            :loader="loader"
            :color="'primary'"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { FormField } from '@shared/types'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { useStaffList } from '../store'
import { search_values } from '../values'
import { storeToRefs } from 'pinia'
import { objectDeepCopy } from '@/shared/utils'

const { isActive } = useLoader()
const { error, success } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const { t } = useI18n()
const store = useStaffList()

const loader = isActive('profile/addresses-edit')

const { employees } = storeToRefs(store)

const defaultValues:{
  email?:string,
  inner_phone?:string
} = {}

const tempValues = ref((defaultValues))

const formFields = computed(():Array<FormField> => {
  return [
    {
      group: {
        class: 'fv-row mb-4 fv-plugins-icon-container'
      },
      label: {
        text: `${t('common.table.inner_phone')}`,
        class: 'fs-6 fw-semibold mb-2 required'
      },
      name: 'inner_phone',
      type: 'text',
      value: tempValues.value.inner_phone ?? '',
      validator: {
        schema: 'yup',
        rules: [
          'number',
          { func: 'label', value: t('common.error.inner_phone') }
        ]
      }
    },
    {
      group: {
        class: 'fv-row fv-plugins-icon-container'
      },
      label: {
        text: `${t('common.table.email')}`,
        class: 'fs-6 fw-semibold mb-2 required'
      },
      name: 'email',
      type: 'text',
      value: tempValues.value.email,
      validator: {
        schema: 'yup',
        rules: [
          'string',
          { func: 'label', value: t('common.error.email') }
        ]
      }
    }
  ]
})

const onChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  tempValues.value = {
    ...tempValues.value,
    ...values
  }
  if (!modifier.value || !modifier.value.data || !modifier.value.data.emp_id) {
    error({ text: 'common.error' })
    return
  }
  const { status, message } = await store.putInnerContacts({ employee_id: +modifier.value.data.emp_id, ...tempValues.value })

  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'email-phone-modal' })
    if (search_values.value) { store.fetchEmployees(search_values.value) }
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'email-phone-modal' })
  unsetModifier()
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.emp_id) {
      const exactPhoneNumber = employees.value?.find(employee => employee.id === newValue?.data?.emp_id)
      tempValues.value = objectDeepCopy({ inner_phone: exactPhoneNumber?.inner_phone ? String(exactPhoneNumber?.inner_phone) : undefined, email: exactPhoneNumber?.outer_email } || defaultValues)
      show()
    }
  }, { immediate: true }
)
</script>
