<template>
  <Toast />
  <ConfirmPopup
    group="delete"
  >
    <template #message="slotProps">
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3 pb-0">
        <i
          :class="slotProps.message.icon"
          class="text-6xl text-primary-500"
        />
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>
  <div
    v-if="hasSelected"
    class="d-flex flex justify-content-start gap-3 mb-5"
  >
    <ElectiveButtonShowEditDialog :elective-type="props.electiveType" />
    <ElectiveButtonShowDeleteDialog :elective-type="props.electiveType" />
  </div>
</template>

<script setup lang="ts">
import ConfirmPopup from 'primevue/confirmpopup'
import ElectiveButtonShowEditDialog from './ui/ElectiveButtonShowEditDialog.vue'
import ElectiveButtonShowDeleteDialog from './ui/ElectiveButtonShowDeleteDialog.vue'
import { computed } from 'vue'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'

const store = useStore()
const { curricula } = storeToRefs(store)

const props = defineProps({
  electiveType: {
    type: String,
    required: true
  }
})

const hasSelected = computed(() => {
  return curricula.value && curricula.value.electives[props.electiveType].some(item => item._isSelected)
})
</script>
