import type { StoreType } from '../types'
import type { AcademicInformationSchedulesResourcesRequest } from '@shared/swagger'

import { getAcademicInformationSchedulesResources } from '@shared/api'

export default async function fetchAcademicInformationScheduleCourseResource (this: StoreType, body: AcademicInformationSchedulesResourcesRequest): Promise<void> {
  const { ok, data } = await getAcademicInformationSchedulesResources(body)

  if (ok) {
    this.setAcademicInformationScheduleCourseResource(data)
  }
}
