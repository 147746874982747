import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useMessageBoard = defineStore<Name, State, Getters, Actions>('Administrative.useMessageBoard', {
  actions: {
    ...actions
  },

  state () {
    return {
      posts: [],
      currentPageView: 'all-posts'
    }
  }
})
