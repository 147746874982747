import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  ServiceManageLanguagesSingleApplicantPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putServiceManageLanguagesSingleApplicant (uuid: string, params: ServiceManageLanguagesSingleApplicantPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceManageLanguagesSingleApplicantPutRequest, UnknownResponse>({
    url: `service/manage/languages/applicants/${uuid}`,
    body: params,
    loader: 'administrative/rules-and-regulations/categories[put]'
  })

  return response
}
