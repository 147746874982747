import { createPage } from '@shared-utils/storybook'
import { useSyllabuses } from './../store/index'
import SyllabusesPage from '../SyllabusesPage.vue'
import { usePagination } from '@shared-composable'

const Template = createPage({
  layout: 'default',
  component: SyllabusesPage,
  setup () {
    const pagination = usePagination()
    const store = useSyllabuses()
    pagination.$reset()
    store.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/SyllabusesPage',
  component: SyllabusesPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
