<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <year-term />
      <pms-driver-button
        class="me-2 ms-2 mt-2 mt-md-0"
      />
      <pms-feedback-modal
        class="mb-0"
        :button-class="'mt-2 mt-md-0'"
        :options="feedback"
      />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-6"
    >
      <pms-page-shimmer
        :shimmer="'table'"
        :filter="true"
      />
    </pms-row>
    <pms-column v-else>
      <pms-grid
        :col="12"
      >
        <ScheduleSearch />
      </pms-grid>
      <pms-grid
        :order-mb="1"
        class="mt-5 sm-0"
        :col="12"
      >
        <individual-schedule-table
          v-if="schedule && schedule.length"
          id="table-block-schedule"
        />
      </pms-grid>
    </pms-column>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'

import { useTour, useLoader, useToolbar, useDriver } from '@shared/composable'
import { tour, header, feedback, quickScheduleSetTourSteps } from './values'
import ScheduleSearch from './ui/ScheduleSearch.vue'
import IndividualScheduleTable from './ui/ScheduleTable.vue'
import YearTerm from './ui/YearTerm.vue'
import { useResource } from '@shared/stores'
import { useAcademicSchedule } from './store'

const resource = useResource()
const store = useAcademicSchedule()

const { setSteps: setQuickTourSteps } = useDriver()
const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()

const loader = isActive('academic/information/resources')
const currentYearTerm = computed(() => store.getCurrentYearTerm)
const schedule = computed(() => store.getScheduleRecords)

watch(() => currentYearTerm.value, () => {
  if (currentYearTerm.value.year && currentYearTerm.value.term) {
    resource.fetchYearTermScheduleHoursResources({ year: currentYearTerm.value.year, term: currentYearTerm.value.term })
  }
}, { immediate: true })

onMounted(async () => {
  setQuickTourSteps(await quickScheduleSetTourSteps())
  setSteps(await tour())
  toggleToolbar()
})
</script>
