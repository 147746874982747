import { useStudent } from '@/shared/stores'
import type { AxiosResponse } from '@/shared/types'

import type { State } from '../types'
import type { StudentSignInRequest, StudentSignInResponse } from '@shared/swagger'

export default async function authenticateUser (this: State, credentials: StudentSignInRequest): Promise<AxiosResponse<StudentSignInResponse>> {
  const student = useStudent()

  return await student.authStudent(credentials)
}
