import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicCoursesGradesParticipantsGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicCoursesGradesParticipants (assesment: number): Promise<AxiosResponse<AcademicCoursesGradesParticipantsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicCoursesGradesParticipantsGetResponse>({
    url: `academic/courses/grades/${assesment}/participants`,
    body: {},
    loader: 'academic/courses/grades/{assesment}/participants'
  })

  return response
}
