import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'

import { useStaffList } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useStaffList()
  const pagination = usePagination()

  pagination.$reset()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/administrative/staff-list',
  children: [
    {
      path: '',
      name: 'StaffListPage',
      component: () => import('./StaffListPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
