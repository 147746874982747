<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-toolbar
          v-if="toolbar"
          v-bind="header"
          :title="title"
          :go-back="{name: route.query.previous_page}"
          @launch="launch"
        >
          <template #action>
            <pms-driver-button class="me-2" />
            <pms-feedback-modal :options="[]" />
          </template>
        </pms-page-toolbar>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>
    <pms-row>
      <pms-grid
        :col="12"
        :order-mb="2"
        class="mb-5 mt-md-0"
      >
        <single-student-sidebar-second-version />
      </pms-grid>
      <pms-grid
        :col="12"
        :order-mb="2"
        class="mt-5 mb-8"
      >
        <horizontal-tab-menu
          v-if="showHorizontalTabMenu"
          :menu-items="(
            menuItems
          ).map(m => ({
            ...m, to: { path: m.to, query: route.query }
          }))"
          tab-buttons-class="w-100 justify-content-between"
        />
      </pms-grid>
    </pms-row>

    <pms-row>
      <pms-grid
        v-if="showHorizontalTabMenu"
        :col="12"
        :order-mb="2"
        class="mt-5 mt-md-0"
      >
        <router-view />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useTour, useToolbar } from '@shared/composable'
import { useResource } from '@shared/stores'

import { header, tour, menuItems, showHorizontalTabMenu } from './values'
import { useRoute } from 'vue-router'

import HorizontalTabMenu from '@/shared/ui/components/HorizontalTabMenu.vue'
import SingleStudentSidebarSecondVersion from './ui/SingleStudentSidebarSecondVersion.vue'

const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()

const resource = useResource()
const route = useRoute()
const studentInformation = computed(
  () => resource.getAcademicSingleStudentResourceInformation
)

const title = computed(() => {
  if (studentInformation.value !== null) {
    return `${studentInformation.value.name} ${studentInformation.value.surname} (${studentInformation.value.id})`
  }
  return route.query?.id
})

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
