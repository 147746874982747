import { useResource } from '@shared/stores'

export default (reading_type_title: string | null): number => {
  if (reading_type_title !== null) {
    const resourceReadings = useResource().getAcademicManageSyllabusResourceReadings
    if (resourceReadings.length > 0) {
      return resourceReadings.find((resource) => resource.title === reading_type_title)?.id ?? -1
    }
  }
  return -1
}
