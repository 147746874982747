import { useToken } from '@shared/composable'
import { useResource } from '@shared/stores'
import type { StoreType } from '../types'
import { AcademicCoursesResourcesResponse } from '@/shared/swagger'
import { ref } from 'vue'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()
  const token = useToken()

  const currentYearTerm = ref<AcademicCoursesResourcesResponse['my_year_terms'][0]>({
    id: 0,
    year: token.getYear(),
    term: token.getTerm(),
    bell_curve_is_active: false
  })

  await resource.fetchAcademicCoursesResources({ modules: ['my-year-terms'] })

  const yearTerms = resource.getAcademicCoursesResourceMyYearTerms
  const hasCurrentYearTerm = yearTerms.find((item) => item.year === currentYearTerm.value.year && item.term === currentYearTerm.value.term)

  if (!hasCurrentYearTerm && yearTerms.length > 0) {
    currentYearTerm.value = { ...yearTerms[0] }
  } else if (hasCurrentYearTerm) {
    currentYearTerm.value = { ...hasCurrentYearTerm }
  }

  await Promise.all([
    this.setCurrentYearTerm({ ...currentYearTerm.value }),
    this.fetchMyCourses({ year: currentYearTerm.value.year, term: currentYearTerm.value.term })
  ])
}
