import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { getServiceManageAnnouncements } from '@/shared/api'

export default async function fetchRules (this: StoreType, year: number): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceManageAnnouncements(year)

  ok && this.setAnnouncements(data)
  return {
    status: ok,
    message
  }
}
