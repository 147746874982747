import { ServiceMessageBroadcastPostRequest } from '@shared/swagger'
import { MessageType } from '../type'
import { message_types } from '../enums'

export default (message: MessageType): ServiceMessageBroadcastPostRequest => {
  const submitValues: ServiceMessageBroadcastPostRequest = {
    subject: message.subject || '',
    content: message.content || '',
    type: message.type || '',
    from: message.from || null
  } as ServiceMessageBroadcastPostRequest

  if (message.type === message_types.course) {
    submitValues.type = message_types.student

    submitValues.students = message.students
  }
  if (message.type === message_types.program) submitValues.programs = message.programs.map((program) => ({ code: program.code, classes: program.classes }))
  if (message.type === message_types.student) submitValues.students = message.students
  if (message.type === message_types.employee) submitValues.employees = message.employees

  return submitValues
}
