import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleStudentScheduleRequest,
  AcademicInformationScheduleResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicSingleStudentSchedule (studentId: string, params: AcademicSingleStudentScheduleRequest): Promise<AxiosResponse<AcademicInformationScheduleResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicInformationScheduleResponse>({
    url: `academic/students/${studentId}/course-schedule`,
    body: { params },
    loader: 'academic/students/course-schedule'
  })

  return response
}
