import type { StoreType } from '../types'
import { ResourceRequest } from '@shared/swagger'

import { getAcademicCoursesResource } from '@shared/api'

export default async function fetchAcademicCoursesResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getAcademicCoursesResource(body)

  if (ok) {
    this.setAcademicCoursesResource(data)
  }
}
