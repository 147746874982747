import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendancesByCourseStudentsGetRequest,
  AcademicSingleCourseAttendancesByCourseStudentsGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicSingleCourseAttendancesByCourseStudentsGetRequest): Promise<AxiosResponse<AcademicSingleCourseAttendancesByCourseStudentsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendancesByCourseStudentsGetResponse>({
    url: 'academic/courses/attendances/by-courses/students',
    body: { params },
    loader: 'academic/courses/attendances/by-courses/students'
  })

  return response
}
