<template>
  <Teleport to="#attendance-modal-content">
    <pms-container>
      <qr-details />
      <qr-students />
    </pms-container>
  </Teleport>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { useTeleportModal } from '@shared/composable'

import { useQr } from '../../composable'

import QrDetails from './qr/QrDetails.vue'
import QrStudents from './qr/QrStudents.vue'

const { mount, unMount } = useQr()
const { modifier } = useTeleportModal()

watch(
  () => modifier.value,
  (newValue) => {
    newValue
      ? mount()
      : unMount()
  },
  { immediate: true }
)
</script>
