<template>
  <pms-card
    class="w-350px w-md-400px w-lg-400px mb-4"
    :header="false"
  >
    <div class="text-center mb-4">
      <h1 class="text-dark fw-bolder mb-2 fs-1 pt-0">
        {{ $t('domains.authenticator.sign-in.title') }}
      </h1>
      <p class="text-gray-500 fw-semibold fs-5">
        {{ $t('domains.authenticator.sign-in.student-description') }}
      </p>
    </div>
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="form-group mt-3">
          <pms-button
            type="submit"
            class="w-100 mt-2"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.sign-in')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { FormField } from '@shared/types'
import { useLoader } from '@shared/composable'

import { onSubmit } from '../utils'

const { t } = useI18n()
const { isActive } = useLoader()

const loader = isActive('student/sign-in')

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: `${t('common.fields.student-id')}:`,
      class: 'mb-1 mt-2 fw-bold'
    },
    name: 'student_id',
    type: 'text',
    required: true
  },
  {
    label: {
      text: `${t('common.fields.password')}:`,
      class: 'mb-1 mt-2 fw-bold'
    },
    name: 'password',
    type: 'password',
    required: true
  }
])
</script>
