<template>
  <Teleport to="#education-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { levelsToOptionType } from '../utils'
import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/education-edit', 'profile/education-post'])

const educations = computed(() => store.getEducations)
const levels = computed(() => resource.getProfileResource?.edu_levels || [])

const defaultTempValues: ProfileEducationGetResponse[0] = {
  id: -1,
  country: '',
  level_id: '',
  university: '',
  faculty: '',
  speciality: '',
  cipher: '',
  registration_no: '',
  diploma_no: '',
  start_date: '',
  end_date: '',
  is_ongoing: false,
  is_honor: false
}

const tempValues = ref<ProfileEducationGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.education.university-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'university',
    type: 'text',
    value: tempValues.value.university,
    required: true,
    placeholder: t('domains.profile.education.university-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.faculty-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'faculty',
    type: 'text',
    value: tempValues.value.faculty || '',
    required: true,
    placeholder: t('domains.profile.education.faculty-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.speciality-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'speciality',
    type: 'text',
    value: tempValues.value.speciality || '',
    required: true,
    placeholder: t('domains.profile.education.speciality-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.level-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'level_id',
    type: 'select',
    value: tempValues.value.level_id,
    required: true,
    options: levelsToOptionType(levels.value)
  },
  {
    label: {
      text: t('domains.profile.education.cipher-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'cipher',
    type: 'text',
    value: tempValues.value.cipher,
    required: true,
    placeholder: t('domains.profile.education.cipher-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.registration-no-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'registration_no',
    type: 'text',
    value: tempValues.value.registration_no,
    required: true,
    placeholder: t('domains.profile.education.registration-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.diploma-no-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'diploma_no',
    type: 'text',
    value: tempValues.value.diploma_no,
    required: true,
    placeholder: t('domains.profile.education.diploma-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.education.start-date-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'start_date',
    type: 'date',
    value: tempValues.value.start_date,
    required: true
  },
  {
    label: {
      text: t('domains.profile.education.end-date-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'end_date',
    type: 'date',
    value: tempValues.value.end_date,
    required: true,
    disabled: tempValues.value.is_ongoing
  },
  {
    label: {
      text: t('domains.profile.education.is-ongoing-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'is_ongoing',
    type: 'radio',
    value: tempValues.value.is_ongoing,
    required: true,
    inline: true,
    options: [
      { id: '1', value: true, label: { text: 'True' } },
      { id: '1', value: false, label: { text: 'False' } }
    ]
  },
  {
    label: {
      text: t('domains.profile.education.is-honor-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-2'
    },
    name: 'is_honor',
    required: true,
    type: 'radio',
    value: tempValues.value.is_honor,
    inline: true,
    options: [
      { id: '3', value: true, label: { text: 'True' } },
      { id: '3', value: false, label: { text: 'False' } }
    ]
  }

])

const closeModal = () => {
  close({ name: 'education-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.level_id) {
    error({ text: t('domains.profile.education.level-id-field-error') })
    return
  }
  if (!values.university) {
    error({ text: t('domains.profile.education.university-field-error') })
    return
  }
  if (!values.faculty) {
    error({ text: t('domains.profile.education.faculty-field-error') })
    return
  }
  if (!values.speciality) {
    error({ text: t('domains.profile.education.speciality-field-error') })
    return
  }
  if (!values.cipher) {
    error({ text: t('domains.profile.education.cipher-field-error') })
    return
  }
  if (!values.registration_no) {
    error({ text: t('domains.profile.education.registration-no-field-error') })
    return
  }
  if (!values.diploma_no) {
    error({ text: t('domains.profile.education.diploma-no-field-error') })
    return
  }
  if (!values.start_date) {
    error({ text: t('domains.profile.education.start-date-field-error') })
    return
  }

  values.is_ongoing = values.is_ongoing === 'true'
  values.is_honor = values.is_honor === 'true'

  const { status, message } =
    tempValues.value.id === -1
      ? await store.addEducation({ ...values })
      : await store.editEducation(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'education-modal' })
    unsetModifier()
    store.fetchEducations()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactEducation = educations.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactEducation || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
