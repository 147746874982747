<template>
  <page-container>
    <pms-loader
      v-if="loader"
      class="fs-5hx"
    />
  </page-container>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useSign, useLoader } from '@shared/composable'
import { useStudentAttendance } from './composable'

import PageContainer from './ui/PageContainer.vue'

const { getDecoded } = useSign()
const { isActive, activate } = useLoader()

const router = useRouter()
const loader = isActive('sign-loading')

onMounted(async () => {
  activate('sign-loading')
  const decoded = await getDecoded()

  if (decoded) {
    switch (decoded.action) {
      case 'student-attendance':
        useStudentAttendance(decoded)
        break
    }
    return
  }

  router.push({ path: '/sign/invalid' })
})
</script>
