import type { StoreType } from '../types'

import { getAcademicSingleStudentResource } from '@shared/api'

export default async function fetchAcademicSingleStudentResource (this: StoreType, id: string): Promise<void> {
  const { ok, data } = await getAcademicSingleStudentResource(id)

  if (ok) {
    this.setAcademicSingleStudentResource(data)
  }
}
