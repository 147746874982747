<template>
  <pms-card
    :header="false"
  >
    <pms-search
      v-model:value="searchValue"
      :placeholder="'domains.administrative.message-board.search-placeholder'"
      @handle-search="submitSearch"
    />
    <page-views class="mt-5" />
  </pms-card>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { usePagination } from '@shared/composable'
import { useMessageBoard } from '../store'

import PageViews from './PageViews.vue'

const { perPage } = usePagination()
const store = useMessageBoard()

const pageView = computed(() => store.currentPageView)
const searchValue = ref('')

const submitSearch = () => {
  store.fetchPosts({
    search: searchValue.value,
    my_posts: store.currentPageView === 'my-posts' ? 1 : 0,
    per_page: perPage.value,
    offset: 1
  })
}

watch(pageView, () => {
  searchValue.value = ''
})
</script>
