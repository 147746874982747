import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  ServiceManageAnnouncementsPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postServiceManageLocks (params: ServiceManageAnnouncementsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ServiceManageAnnouncementsPostRequest, UnknownResponse>({
    url: 'service/manage/announcements',
    body: params,
    loader: 'aservice/manage/announcements/post'
  })

  return response
}
