<template>
  <pms-card
    :title="$t('domains.administrative.gate-entry.search.title')"
    :description="$t('domains.administrative.gate-entry.search.description')"
    :options="{body:{class: 'pt-0'}}"
  >
    <div
      id="search-type-switch"
      class="btn-group w-100 mb-4"
    >
      <pms-button
        class="text-hover-white text-active-white"
        :variant="employeeView === 'my' ? 'default' : 'tonal'"
        @click="setSearchType('my')"
      >
        {{ $t('domains.administrative.gate-entry.search.my-records') }}
      </pms-button>
      <pms-button
        class="text-hover-white text-active-white"
        :variant="employeeView === 'search' ? 'default' : 'tonal'"
        @click="setSearchType('search')"
      >
        {{ $t('common.search') }}
      </pms-button>
    </div>
    <pms-smart-form
      class="mt-4"
      :fields="formFields"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmitForm"
    >
      <template #department>
        <span class="mb-1 mt-2"> {{ `${t('common.department')}:` }}</span>
        <treeselect
          v-model="tempValues.department_code"
          :multiple="false"
          :options="sortedDep"
          placeholder=""
          class="mt-3 styled"
        />
      </template>
      <template #formControls>
        <div class="d-flex flex-end mt-6">
          <pms-button
            :type="'submit'"
            class="w-150px"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.search')"
            :searchable="true"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'
import { useLoader, useAlert } from '@shared/composable'
import { useResource, useUser } from '@shared/stores'
import { useGateEntryStore } from '../store'
import { user_data } from '../values'
import Treeselect from 'vue3-treeselect'
import { FormGroup } from '@/shared/types'
import { entryTypeToOption, fromDueValues, organizeDepartments, departmentsToOption } from '../utils'
const { t } = useI18n()
const { isActive } = useLoader()
const { error } = useAlert()

const user = useUser()
const resource = useResource()
const store = useGateEntryStore()
const { fromValue, dueValue } = fromDueValues()

const loader = isActive('administrative/gate-entry')
const currentUser = computed(() => user.currentUser)

const employeeView = ref('my')
const departments = computed(() => resource.getAdministrativeGateEntryResourceDepartments)
const sortedDep = computed(() => organizeDepartments(departments.value))

const defaultTempValue = {
  user_id: null as number | null,
  department_code: null as string | null,
  department: null as string | null,
  reader_group: 1,
  from_date: null as string | null,
  due_date: null as string | null
}
const tempValues = ref({ ...defaultTempValue })

const users = computed(() => resource.getAdministrativeGateEntryResourceUsers)

const setSearchType = (type: 'my'|'search') => {
  employeeView.value = type
  tempValues.value = { ...defaultTempValue }
  tempValues.value.user_id = employeeView.value === 'my' ? Number(currentUser.value?.id) || null : null
  tempValues.value.due_date = dueValue
  tempValues.value.from_date = dueValue
}

const formFields = computed(():Array<FormGroup> => {
  const result: Array<FormGroup> = []
  result.push(
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.reader_group')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'mb-3 col-12'
          },
          name: 'reader_group',
          type: 'select',
          class: 'form-select form-select-solid',
          value: tempValues.value.reader_group,
          options: entryTypeToOption(resource.getAdministrativeResourceTypes),
          validator: {
            schema: 'yup',
            rules: [
              'mixed',
              {
                func: 'required',
                args: ['An option must be selected']
              },
              {
                func: 'test',
                args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
              }
            ]
          }
        }
      ]
    }
  )
  if (employeeView.value === 'search') {
    result.push(
      {
        class: 'row',
        fields: [
          {
            name: 'department',
            type: 'slot',
            group: {
              class: 'mb-3 col-6'
            }
          },
          {
            label: {
              text: `${t('common.username')}:`,
              class: 'mb-1 mt-2'
            },
            name: 'user_id',
            type: 'select',
            group: {
              class: 'mb-3 col-6'
            },
            value: tempValues.value.user_id || null,
            options: departmentsToOption(users.value)
          }
        ]
      }
    )
  }
  result.push(
    {
      class: 'd-flex row',
      fields: [
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.from_date')}`,
            class: 'mb-1'
          },
          name: 'from_date',
          type: 'date',
          group: {
            class: 'mb-3 col-6'
          },
          value: tempValues.value.from_date,
          class: 'form-control form-control-lg form-control-solid',
          binding: {
            max: moment().add(1, 'days').format('DD-MM-YYYY')
          }
        },
        {
          label: {
            text: `${t('domains.administrative.gate-entry.search.due_date')}`,
            class: 'mb-1'
          },
          name: 'due_date',
          type: 'date',
          group: {
            class: 'mb-3 col-6'
          },
          value: tempValues.value.due_date,
          class: 'form-control form-control-lg form-control-solid',
          binding: {
            min: moment(tempValues.value.from_date).format('DD-MM-YYYY'),
            max: moment().add(1, 'days').format('DD-MM-YYYY')
          }
        }
      ]
    }
  )
  return result
})

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmitForm = async ({ values }) => {
  tempValues.value.department = tempValues.value.department_code
  if (employeeView.value === 'search' && tempValues.value.user_id === -1) {
    tempValues.value.due_date = ''
  }
  if (tempValues.value.department_code === '') {
    error({ text: 'domains.administrative.gate-entry.search.error.usernameOrDepartment' })
    return
  }
  user_data.value = {
    ...tempValues.value,
    ...values,
    user_type: 'P',
    mailer: false
  }
  try {
    if (employeeView.value === 'my') {
      await store.fetchMyRecords({ ...values, mailer: false })
    } else {
      await store.fetchRecords(user_data.value)
    }
  } catch (Error) {
    error({ text: 'domains.administrative.gate-entry.search.error.records' })
  }
}

watch(() => tempValues.value.department_code, async (newValue) => {
  newValue && await resource.fetchAdministrativeGateEntryResourceUsers({ department: newValue, user_type: 'P' })
})

watch(() => tempValues.value.user_id, async () => {
  tempValues.value.user_id ? tempValues.value.from_date = fromValue : tempValues.value.from_date = dueValue
})

onMounted(() => {
  setSearchType('my')
})
</script>
