<template>
  <div>
    <h3 class="fs-2 fw-bold mb-2">
      {{ postWithUuid ? postWithUuid.title : postNew.title }}
    </h3>
    <KnowledgeManagePost
      v-if="postWithUuid"
      :post="postWithUuid"
    />
    <KnowledgeManagePostPreview
      v-if="postNew"
      :category-uuid="postNew.category_uuid"
      :description="postNew.description"
    />
  </div>
  <KnowledgeBaseSingleContentSelector
    class="mb-5"
    :contents="activeContent ? [activeContent] : [postNew.content]"
  />
  <component
    :is="contentComponent"
    :content="activeContent ? activeContent : postNew.content"
  />
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { KnowledgeManagePostsPostRequest, KnowledgeManagePostsSingleGetResponse } from '@shared/swagger'

import KnowledgeManagePostPreview from './KnowledgeManagePostPreview.vue'
import KnowledgeBaseSingleContentSelector from './KnowledgeManagePostContentSelector.vue'
import KnowledgeManagePost from './KnowledgeManagePost.vue'
import FAQContent from './FAQContent.vue'
import GuidesContent from './GuidesContent.vue'
import TutorialsContent from './TutorialsContent.vue'

const props = defineProps({
  postWithUuid: {
    type: Object as PropType<KnowledgeManagePostsSingleGetResponse>,
    default: null
  },
  postNew: {
    type: Object as PropType<KnowledgeManagePostsPostRequest>,
    default: null
  }
})

const activeContent = computed(() => (props.postWithUuid ? props.postWithUuid.contents : []).filter(el => el.active)[0])
const condition = computed(() => activeContent.value ? activeContent.value.type_id : props.postNew.content.type_id)

const contentComponent = computed(() => {
  switch (Number(condition.value)) {
    case 1:
      debugger
      return FAQContent
    case 2:
      debugger
      return GuidesContent
    case 3:
      debugger
      return TutorialsContent
    default:
      return undefined
  }
})
</script>
<style scoped lang="scss"></style>
