import { getProfileEducation } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchEducations (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileEducation()

  if (ok) {
    this.setEducations(data)
  }
}
