import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendancesGetRequest,
  AcademicSingleCourseAttendancesGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicCoursesAttendances (params: AcademicSingleCourseAttendancesGetRequest): Promise<AxiosResponse<AcademicSingleCourseAttendancesGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendancesGetResponse>({
    url: 'academic/courses/attendances',
    body: { params },
    loader: 'academic/courses/attendances/fetch'
  })

  return response
}
