import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import { AcademicManageCoursesRequisitesItemsGetResponse, AcademicManageCoursesRequisitesPostRequest, AcademicManageCoursesSearchResponse } from '@/shared/swagger'
import { useManageCourses } from '@/domains/AcademicCourses/store'
import { useAlert } from '@/shared/composable'

export function useManageRequisiteComposable () {
  const { success, error } = useAlert()
  const store = useManageCourses()
  const courseCode = ref('')

  const { page_params } = storeToRefs(store)

  async function search_course () {
    const { status, message } = await store.search_requisite_courses({ code: courseCode.value.toUpperCase(), offset: 1, per_page: 1000 })
    if (!status) error({ text: message })
  }

  async function add_item (item: AcademicManageCoursesSearchResponse[0]) {
    const values = {
      course_code: page_params.value.course_code || '',
      course_year: page_params.value.course_year || -1,
      item_code: item.code,
      item_year: item.year,
      requisite_type: page_params.value.requisite_type || ''
    }

    const { status, message } = await store.create_requisite_item(values)
    if (status) {
      store.search_requisite_items({ requisite_id: store.requisite_info_response.id })
      success({ text: message, isToast: true })
    } else { error({ text: message }) }
  }

  async function delete_item (item:AcademicManageCoursesRequisitesItemsGetResponse[0]) {
    const { status, message } = await store.delete_requisite_item({ id: item.id + '' })
    if (status) {
      success({ text: message, isToast: true })
      store.search_requisite_items({ requisite_id: store.requisite_info_response.id })
    } else { error({ text: message }) }
  }

  async function manage_requisite_func (values:{expression:string, cr_enabled:number, track?:string}) {
    let has_items = true
    const letters = values.expression.match(/[A-Z]/g) || []
    letters.forEach(letter => {
      if (!store.requisite_items_response.find(item => item.key === letter)) has_items = false
    })
    if (has_items) {
      const request: AcademicManageCoursesRequisitesPostRequest = {
        id: store.requisite_info_response.id,
        expression: values.expression,
        cr_enabled: values.cr_enabled,
        track: values.track,
        program_code: store.page_params.program_code
      }

      const { status, message } = await store.manage_requisite(request)

      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    } else {
      error({ text: 'Item does not exist' })
    }
  }

  const onSubmit = async ({ values }) => {
    manage_requisite_func(values)
  }

  async function delete_requisite () {
    const { status, message } = await store.delete_requisite({ id: store.requisite_info_response.id + '' })

    if (status) {
      success({ text: message, isToast: true })
      history.back()
    } else { error({ text: message }) }
  }

  return {
    courseCode,
    search_course,
    add_item,

    delete_requisite,
    onSubmit,

    delete_item
  }
}
