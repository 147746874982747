<template>
  <Teleport to="#skill-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileSkillsLanguagesGetResponse, ProfileSkillsLanguagesPostRequest } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { languageNamesToOptionsType, languageLevelsToOptionsType } from '../utils'
import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const resource = useResource()
const loader = isActiveOneOf(['profile/skills/languages-edit', 'profile/skills/languages-post'])

const languages = computed(() => store.getLanguages)
const languageLevels = computed(() => resource.getProfileResource?.language_levels || [])
const languageNames = computed(() => resource.getProfileResource?.languages || [])

const defaultTempValues: ProfileSkillsLanguagesGetResponse[0] = {
  id: -1,
  language_code: '',
  level_id: '',
  is_native: false
}

const tempValues = ref<ProfileSkillsLanguagesGetResponse[0]>(objectDeepCopy(defaultTempValues))

const requestBody = ref<ProfileSkillsLanguagesPostRequest>({
  language: '',
  level: -1,
  is_native: false
})

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.skills.languages.language-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'language_code',
    type: 'select',
    value: tempValues.value.language_code || '',
    required: true,
    options: languageNamesToOptionsType(languageNames.value)
  },
  {
    label: {
      text: t('domains.profile.skills.languages.level-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'level_id',
    type: 'select',
    value: tempValues.value.level_id || '',
    required: true,
    options: languageLevelsToOptionsType(languageLevels.value)
  },
  {
    label: {
      text: t('domains.profile.skills.languages.is-native-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'is_native',
    type: 'select',
    value: tempValues.value.is_native || false,
    options: [
      { value: true, label: 'True' },
      { value: false, label: 'False' }
    ]
  }
])

const closeModal = () => {
  close({ name: 'skill-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.language_code) {
    error({ text: t('domains.profile.skills.languages.language-field-error') })
    return
  }
  if (!values.level_id) {
    error({ text: t('domains.profile.skills.languages.level-field-error') })
    return
  }

  requestBody.value = {
    language: values.language_code,
    level: Number(values.level_id),
    is_native: values.is_native === 'true'
  }
  const { status, message } =
         tempValues.value.id === -1
           ? await store.addLanguage({ ...requestBody.value })
           : await store.editLanguage(tempValues.value.id, { ...requestBody.value })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'skill-modal' })
    unsetModifier()
    store.fetchLanguages()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactLanguage = languages.value.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactLanguage || defaultTempValues)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
