import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'PmsScroll',
  props: {
    variant: {
      type: String as PropType<'default' | 'hover'>,
      default: 'default'
    },
    x: {
      type: Boolean,
      default: true
    },
    y: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number] as PropType<'auto' | 'fixed' | string | number>,
      default: '200px'
    },
    width: {
      type: [String, Number] as PropType<string | number>,
      default: '100%'
    },
    fixedHeight: {
      type: String,
      default: '{default: \'300px\', lg: \'500px\'}'
    }
  },
  setup (props, { slots }) {
    return () => (
      <div
        class={{
          scroll: props.variant === 'default',
          'scroll-x': props.variant === 'default' && props.x,
          'scroll-y': props.variant === 'default' && props.y,
          'hover-scroll': props.variant === 'hover',
          'hover-scroll-x': props.variant === 'hover' && props.x,
          'hover-scroll-y': props.variant === 'hover' && props.y
        }}

        style={{
          height: (props.height === 'fixed' || props.height === 'auto')
            ? undefined
            : typeof props.height === 'number'
              ? `${props.height}px`
              : props.height,
          width: typeof props.width === 'number'
            ? `${props.width}px`
            : props.width
        }}

        data-kt-scroll={
          props.height === 'fixed' || props.height === 'auto' ? 'true' : undefined
        }
        data-kt-scroll-height={
          props.height === 'fixed'
            ? props.fixedHeight
            : (
                props.height === 'auto'
                  ? 'auto'
                  : undefined
              )
        }
      >
        { slots.default && slots.default() }
      </div>
    )
  }
})
