import { useResource } from '@shared/stores'

export default (assess_type_title: string | null): string => {
  if (assess_type_title !== null) {
    const assessTypes = useResource().getAcademicManageSyllabusResourceAssessmentTypes
    if (assessTypes.length > 0) {
      return assessTypes.find((resource) => resource.title === assess_type_title)?.key ?? ''
    }
  }
  return ''
}
