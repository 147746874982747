// SectionManagementSectionsSingleSectionGetResponse
import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementSectionsSingleSectionGetResponse
} from '@shared/swagger'

export default async function getSectionManagementSectionsSingleSection (id: number): Promise<AxiosResponse<SectionManagementSectionsSingleSectionGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSectionsSingleSectionGetResponse>({
    url: `academic/manage/sections/${id}`,
    body: { },
    loader: 'academic/manage/sections-single'
  })

  return response
}
