import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesByCourseGetRequest } from '@shared/swagger'

import { getAcademicSingleCourseAttendancesByCourse } from '@shared/api'

export default async function (this: StoreType, params: AcademicSingleCourseAttendancesByCourseGetRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleCourseAttendancesByCourse(params)

  if (ok) {
    this.setAttendanceCourses(data)

    // const [day, month, year, hour, minute] = time.datetime.split(/[\s:-]+/)
  }
}
