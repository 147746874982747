<template>
  <pms-card :header="false">
    <pms-smart-table
      :items="props.categories"
      :headers="tableCategoryHeader"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #posts_count="{ item }">
        {{ item.posts_count }}
      </template>
      <template #action="{ item }">
        <div class="d-flex gap-3 justify-content-center flex-wrap">
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-primary"
            @click="setIdAction(item.id)"
          >
            <pms-kt-icon
              icon-name="notepad-edit"
              class="fs-3 m-0 p-0"
            />
          </a>
          <a
            href="javascript:void(0)"
            :disabled="item.posts_count !== 0"
            class="btn btn-sm btn-icon btn-light-danger"
            @click="deleteAction(item.uuid)"
          >
            <pms-kt-icon
              icon-name="trash"
              class="fs-3 m-0 p-0"
            />
          </a>
        </div>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useTeleportModal, useAlert } from '@shared/composable'
import { KnowledgeManageCategoriesGetResponse } from '@shared/swagger'

import { useKnowledgeCategories } from './../store'
import { tableCategoryHeader } from './../values'

const { t } = useI18n()
const { dialog, error } = useAlert()
const { open, setModifier } = useTeleportModal()

const store = useKnowledgeCategories()

const props = defineProps<{
  categories: KnowledgeManageCategoriesGetResponse['categories']
}>()

const setIdAction = (id: number) => {
  setModifier('categories',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'categories-new-title' : 'categories-edit-title',
      description: id === -1 ? 'categories-new-description' : 'categories-edit-description'
    }
  )
  open({ name: 'knowledge-categories-modal' })
}

const deleteAction = async (uuid: string, title?: string) => {
  dialog({
    title: 'knowledge.manage.categories.are-you-sure',
    text: t('knowledge.manage.categories.category.delete-text', { name: title }),
    onConfirm: async () => {
      const { status, message } = await store.deleteCategory(uuid)
      if (status) {
        store.$reset()
        store.bootstrap()
      } else {
        error({ text: message })
      }
    }
  })
}
</script>
