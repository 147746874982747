import { SmartTableHeader } from '@/shared/types'

export const examDatesTableHeadersEditable: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.course-code',
    field: 'code',
    headerClass: 'mw-200px',
    sortable: true
  },
  {
    title: 'common.table.course-title',
    field: 'title'
  },
  {
    title: 'common.table.course-assessment',
    field: 'assessment'
  },
  {
    title: 'common.table.exam-start-date',
    field: 'exam_date',
    slotCellName: 'exam_date',
    sortable: true
  },
  {
    title: 'common.table.exam-close-date',
    field: 'close_date',
    slotCellName: 'close_date',
    sortable: true
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const examDatesTableHeadersUneditable: Array<SmartTableHeader> = [
  {
    title: 'common.table.course-code',
    field: 'code',
    headerClass: 'mw-200px',
    sortable: true
  },
  {
    title: 'common.table.course-title',
    field: 'title'
  },
  {
    title: 'common.table.course-assessment',
    field: 'assessment'
  },
  {
    title: 'common.table.exam-start-date',
    field: 'exam_date',
    slotCellName: 'exam_date',
    sortable: true
  },
  {
    title: 'common.table.exam-close-date',
    field: 'close_date',
    slotCellName: 'close_date',
    sortable: true
  }
]
