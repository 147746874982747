import { AdministrativeGateEntryResourceResponse } from '@/shared/swagger'

interface OrganizedDepartment {
  id: string;
  label: string;
  children?: OrganizedDepartment[];
}

const getDepartmentChildren = (
  department: AdministrativeGateEntryResourceResponse['departments'][0],
  departments: AdministrativeGateEntryResourceResponse['departments']
) => {
  const hasChildren = departments.some(d => d.parent_id === department.department_id)

  if (!hasChildren) {
    return
  }

  return departments
    .filter(d => d.parent_id === department.department_id)
    .map(child => ({
      id: child.department_code,
      label: child.title,
      children: getDepartmentChildren(child, departments)
    }))
}

export default (departments: AdministrativeGateEntryResourceResponse['departments']): OrganizedDepartment[] => {
  const rootDepartments: OrganizedDepartment[] = departments
    .filter(d => departments.find(dep => dep.parent_id === d.department_id))
    .map(d => ({
      id: d.department_code,
      label: d.title,
      children: getDepartmentChildren(d, departments)
    }))

  return rootDepartments
}
