import type { StoreType } from '../types'
import { AcademicManageSyllabusGetRequest } from '@shared/swagger'

export default function requestGetter (this: StoreType): AcademicManageSyllabusGetRequest {
  return {
    course_code: this.course_code,
    year: this.year,
    language: this.language === 'none' ? undefined : this.language
  }
}
