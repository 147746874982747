import type { StoreType } from '../types'

import { useAlert, usePagination } from '@shared/composable'
import { getServiceManageLanguagesApplicants } from '@shared/api'
import { ServiceManageLanguagesApplicantsGetRequest, ServiceManageLanguagesApplicantsGetResponse } from '@shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchApplicants (this: StoreType, params: ServiceManageLanguagesApplicantsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: ServiceManageLanguagesApplicantsGetResponse): void => {
    this.setApplicants(data.applicants || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getServiceManageLanguagesApplicants({ ...params, per_page: perPage, offset: currentPage })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getServiceManageLanguagesApplicants(params)
  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
