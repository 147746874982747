import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useResource } from '@/shared/stores'
import { TreeNode } from '../type'
import { useMessageBroadcastCompose } from '../store'

export function useProgramsField () {
  const store = useMessageBroadcastCompose()
  const { selectedPrograms } = storeToRefs(store)
  const resourceStore = useResource()
  const { serviceMessageBroadcastResource } = storeToRefs(resourceStore)

  const selectedValue = ref<TreeNode[]>([])
  const treeData = computed<TreeNode[]>(transformData)
  const delimiter = '/_'

  function transformData (): TreeNode[] {
    return serviceMessageBroadcastResource.value?.department_programs.map(department => ({
      id: `department${delimiter}${department.department_code}`,
      label: department.department_title,
      children: department.programs.map(program => ({
        id: `program${delimiter}${program.program_code}`,
        label: program.program_title,
        children: Array.from({ length: +program.classes_count }, (_, index) => ({
          id: `class${delimiter}${program.program_code}${delimiter}${index + 1}`,
          label: `${program.program_title} - ${index + 1}`
        }))
      }))
    })) ?? []
  }

  function addProgram (program:TreeNode) {
    selectedPrograms.value.push({
      code: program.id.split(delimiter)[1],
      classes: (program.children || []).map(child => child.id.split('_')[2])
    })
  }
  function addChildProgram (program:TreeNode) {
    const id = program.id.split(delimiter)[1]
    const clas = program.id.split(delimiter)[2]
    const parent_program = selectedPrograms.value.find(program => program.code === id)
    if (parent_program) {
      if (parent_program.classes?.includes(clas)) {
        parent_program.classes.splice(parent_program.classes.indexOf(clas), 1)
      } else {
        parent_program.classes?.push(clas)
      }
    } else {
      selectedPrograms.value.push({
        code: id,
        classes: [clas]
      })
    }
  }

  return {
    selectedValue,
    selectedPrograms,
    treeData,
    delimiter,
    addProgram,
    addChildProgram
  }
}
