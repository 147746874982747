interface OrganizedDepartment {
  id: string;
  label: string;
  children?: OrganizedDepartment[];
}

interface Department {
  department_code: string;
  title: string;
  department_id: number;
  parent_id: number;
}

const organizedDepartments = (departments: Department[]): OrganizedDepartment[] => {
  const departmentMap: { [id: number]: OrganizedDepartment } = {}
  const roots: OrganizedDepartment[] = []

  // Create a map for quick lookup by department_id
  departments.forEach(department => {
    departmentMap[department.department_id] = {
      id: department.department_code,
      label: department.title
    }
  })

  // Build the tree structure
  departments.forEach(department => {
    const parent = departmentMap[department.parent_id]
    const currentDepartment = departmentMap[department.department_id]

    if (parent) {
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(currentDepartment)
    } else {
      roots.push(currentDepartment)
    }
  })

  return roots
}

export default organizedDepartments
