<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`list`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <syllabus-policy class="mb-5" />
    <pms-card
      v-if="amIEnrolled"
      :title="'domains.academic.editor.syllabus.policies.title'"
      :description="'domains.academic.editor.syllabus.policies.description'"
      class="mb-3"
      :options="{ body: { class: 'pt-0'} }"
    >
      <template #toolbar>
        <button
          v-if="has_permission"
          type="button"
          class="btn btn-light-primary p-2"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addPolicy"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </template>
      <pms-smart-list
        v-if="!noPolicies"
        :items="policies"
        :options="{ item: { class: 'mt-4 mb-4' }, toggle: { container: { class: ''} }}"
      >
        <template #item="item">
          <SyllabusPolicyCard
            :id="item.item.id"
            :order="item.idx"
            :policy="item.item.title"
            @delete:editor="deletePolicy"
            @open:editor="openEditor"
          />
        </template>
      </pms-smart-list>
      <template v-else>
        <pms-no-data />
      </template>
    </pms-card>
  </div>
  <SyllabusPolicyForm v-if="modifier && modifier.name === 'syllabus.policies'" />
</template>

<script setup lang="ts">
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { useStore } from './../store'
import { computed } from 'vue'
import SyllabusPolicyCard from './SyllabusPolicyCard.vue'
import SyllabusPolicyForm from './SyllabusPolicyForm.vue'
import SyllabusPolicy from '../../../ui/SyllabusPolicy.vue'
import { useResource } from '@/shared/stores'
import { has_permission } from '../values'

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus/policies')
const { setModifier, open, modifier } = useTeleportModal()
const alert = useAlert()
const store = useStore()

const policies = computed(() => store.getPolicy)
const noPolicies = computed(() => policies.value.length === 0)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)

function openEditor (id_policy: number) {
  setModifier(
    'syllabus.policies',
    { id: id_policy, mode: 'edit' },
    { title: 'policies.title.edit', description: 'policies.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}
function addPolicy () {
  setModifier(
    'syllabus.policies',
    {},
    { title: 'policies.title.add', description: 'policies.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

async function deletePolicy (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.manage.syllabus.delete-policy',
      onConfirm: async () => {
        await store.deletePolicy(id)
      }
    }
  )
}
</script>
