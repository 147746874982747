<template>
  <Teleport to="#new-request-modal-content">
    <div v-if="!isPostOfferSelected">
      <ProgramDetails />
      <pms-loader v-if="loader" />
      <pms-smart-table
        v-else
        :items="courses"
        :headers="requestCourseTable"
        :sortable="true"
        :options="{ wrapper: { responsive: false }}"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #courseCode="{ item }">
          {{ item.code }}
        </template>
        <template #courseCodeHeader="{ item }">
          <div class="d-flex gap-3 flex-wrap">
            {{ $t(item.title) }}
            <input
              v-model="courseCodeInput"
              name="MyCourcesFormCodeSearch"
              class="form-control"
              type="text"
            >
          </div>
        </template>
        <template #title="{ item }">
          {{ item.title }}
        </template>
        <template #term="{ item }">
          {{ item.term }}
        </template>
        <template #theory="{ item }">
          {{ item.theory }}
        </template>
        <template #practice="{ item }">
          {{ item.practice }}
        </template>
        <template #ects="{ item }">
          {{ item.ects }}
        </template>
        <template #status="{item}">
          <i
            class="fs-4 bi"
            :class="{
              'bi-check-circle text-primary': item.offers.length > 0,
              'bi-x-circle text-danger': item.offers.length === 0
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-kt-initialized="1"
          />
        </template>
        <template #action="{ item }">
          <pms-dropdown
            :group-class="'w-100px order-5'"
            :icon-placement="'end'"
          >
            <div class="w-150px menu-item pt-0 pb-0 text-left">
              <a
                v-if="item.offers.length === 0"
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="selectPostOfferCourse(item)"
              >
                {{ $t('common.table.apply') }}
              </a>
              <a
                v-if="item.offers.length > 0"
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="deleteOffer(item.offers)"
              >
                {{ $t('common.table.remove') }}
              </a>
            </div>
          </pms-dropdown>
        </template>
      </pms-smart-table>
    </div>
    <MyCoursesFormNoteGrid
      v-else
      :note="tempValues.note"
      :course="postingOfferCourse"
      @update:is-post-offer="togglePostOffer"
      @on:change="onChange"
    />
  </Teleport>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useLoader, useTeleportModal, useAlert } from '@shared/composable'

import { objectDeepCopy } from '@shared/utils'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import ProgramDetails from './ProgramDetails.vue'
import { requestCourseTable } from '@domains/SectionManagement/features/NewRequest/values'
import MyCoursesFormNoteGrid from '@domains/SectionManagement/features/NewRequest/ui/MyCoursesFormNoteGrid.vue'
import { SectionManagementRequestCourseGetResponse } from '@shared/swagger'
import { debounce } from '@shared/utils/optimize'

const { t } = useI18n()
const { isActive } = useLoader()
const { show, modifier } = useTeleportModal()
const { dialog, error, success } = useAlert()

const loader = isActive('academic/manage/sections/courses')
const newRequestStore = useNewRequest()
const courseCodeInput = ref('')
const filteredCourses = ref<SectionManagementRequestCourseGetResponse | undefined>([])
const postingOfferCourse = ref<SectionManagementRequestCourseGetResponse[0]>()

const courses = computed(() => {
  if (courseCodeInput.value && (filteredCourses.value && filteredCourses.value.length > 0)) {
    return filteredCourses.value
  }
  return newRequestStore.requestCourses
})

watch(() => courseCodeInput.value, debounce(filterCourses))

function filterCourses () {
  filteredCourses.value = newRequestStore.requestCourses.filter((course) => course.code.includes(courseCodeInput.value))
}

const isPostOfferSelected = ref<boolean>(false)

const defaultValues = {
  note: ''
}

const tempValues = ref(objectDeepCopy(defaultValues))
function selectPostOfferCourse (item: SectionManagementRequestCourseGetResponse[0]) {
  postingOfferCourse.value = item
  tempValues.value = objectDeepCopy(item.offers[0] || {})
  togglePostOffer()
}

async function deleteOffer (item: SectionManagementRequestCourseGetResponse[0]['offers']) {
  if (item[0]) {
    dialog({
      title: 'domains.academic.manage.section.offers.are-you-sure',
      text: t('domains.academic.manage.section.offers.delete-text', { name: 'offer' }),
      onConfirm: async () => {
        const { status, message } = await newRequestStore.removeOffer(item[0].id)
        await newRequestStore.fetchRequestCourses(
          {
            request_by: 'search',
            program_code: newRequestStore.selectedProgram?.code,
            program_year: newRequestStore.selectedProgram?.active_year
          }
        )
        if (!status) {
          error({
            text: message
          })
        } else {
          success({
            text: message,
            isToast: true
          })
        }
      }
    })
  }
}
function togglePostOffer () {
  isPostOfferSelected.value = !isPostOfferSelected.value
}

function onChange (name, newValue) {
  tempValues.value[name] = newValue
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue) {
      tempValues.value = objectDeepCopy(defaultValues)
      show()
    }
  }, { immediate: true }
)
</script>
