<template>
  <pms-card
    :title="'domains.student.dashboard.events.title'"
    :description="'domains.student.dashboard.events.description'"
    :options="{
      body: {
        class: 'pt-0'
      }
    }"
  >
    <template v-if="events.length > 0">
      <div
        v-for="(event, index) in events"
        :key="index"
        class="d-flex align-items-center mb-6"
      >
        <span
          data-kt-element="bullet"
          class="bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4"
          :class="{
            'bg-success': event.isOpen,
            'bg-danger': !event.isOpen
          }"
        />
        <div class="flex-grow-1 me-5">
          <div class="text-gray-800 fw-bold fs-2">
            {{ dateFormatter(event.startDate, dateOptions) }}
            <span class="text-gray-500 fw-semibold fs-7">
              {{ dateFormatter(event.startTime, timeOptions) }}
            </span>
            -
            {{ dateFormatter(event.endDate, dateOptions) }}
            <span class="text-gray-500 fw-semibold fs-7">
              {{ dateFormatter(event.endTime, timeOptions) }}
            </span>
          </div>
          <div class="text-gray-700 fw-semibold fs-5">
            {{ event.title }}
          </div>
        </div>
      </div>
    </template>
    <pms-no-data v-else />
  </pms-card>
</template>

<script lang="ts" setup>
import { useStudent } from '@shared/stores'
import { computed } from 'vue'
import { dateFormatter } from '@shared/utils'

const user = useStudent()
const events = computed(() => user.getEvents)

const dateOptions = { inputFormat: 'YYYY-MM-DD', outputFormat: 'DD/MM/YYYY' }
const timeOptions = { inputFormat: 'HH:mm:ss', outputFormat: 'HH:mm' }
</script>
