import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCoursesRequisitesItemsGetRequest, AcademicManageCoursesRequisitesItemsGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicManageCoursesRequisitesItemsGetRequest): Promise<AxiosResponse<AcademicManageCoursesRequisitesItemsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCoursesRequisitesItemsGetResponse>({
    url: `academic/manage/courses/requisites/${params.requisite_id}/items`,
    body: { params },
    loader: 'academic/manage/courses/requisites/items/search'
  })

  return response
}
