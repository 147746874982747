import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  console.log('handleAuthRoute')

  next()
}

const mainPage: RouteRecordWithLayout = {
  layout: 'auth',
  path: '/student/auth',
  redirect: '/student/auth/sign-in',
  children: [
    {
      path: 'sign-in',
      name: 'StudentSignInPage',
      component: () => import('./SignInPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
}

export default mainPage
