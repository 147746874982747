import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicInformationExamDatesRequest, AcademicInformationExamDatesResponse } from '@shared/swagger'

export default async function getAcademicInformationExamDates (params: AcademicInformationExamDatesRequest): Promise<AxiosResponse<AcademicInformationExamDatesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationExamDatesResponse>({
    url: 'academic/information/exam-dates',
    body: { params: params },
    loader: 'academic/information/exam-dates'
  })

  return response
}
