export const header = {
  title: 'domains.service.message-broadcast.title',
  description: 'domains.service.message-broadcast.description',
  tour: true
}

export const messageListHeader = {
  title: 'domains.service.message-broadcast.messages',
  description: 'domains.service.message-broadcast.messages-description'
}
