import { SmartTableHeader } from '@shared/types'

export const main: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no'
  },
  {
    title: 'common.table.year',
    field: 'payment_year'
  },
  {
    title: 'common.table.term',
    field: 'payment_term'
  },
  {
    title: 'common.table.department-fee',
    field: 'dep_fee'
  },
  {
    title: 'common.table.calculated-fee',
    field: 'calculated_fee'
  },
  {
    title: 'common.table.balance',
    field: 'balance'
  },
  {
    title: 'common.table.charges',
    field: 'charges'
  },
  {
    title: 'common.table.total-balance',
    field: 'total_balance'
  },
  {
    title: 'common.table.payment-amount',
    field: 'payment_amount'
  },
  {
    title: 'common.table.remainder',
    field: 'remainder'
  },
  {
    title: 'common.table.currency',
    field: 'currency'
  },
  {
    title: 'common.table.action',
    field: 'action'
  }
]

export const payments: Array<SmartTableHeader> = [
  {
    title: 'common.table.payment-date',
    field: 'date',
    slotCellName: 'date'
  },
  {
    title: 'common.table.payment-code-title',
    field: 'title'
  },
  {
    title: 'common.table.payment-amount',
    field: 'amount'
  },
  {
    title: 'common.table.payment-remainder',
    field: 'remainder',
    slotCellName: 'remainder'
  }
]

const discounts: Array<SmartTableHeader> = [
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.percentage',
    field: 'percentage',
    slotCellName: 'percentage'
  },
  {
    title: 'common.table.calculated-amount',
    field: 'calculated_amount'
  }
]

export const mainFields = [
  'year',
  'term',
  'department_fee',
  'calculated_fee',
  'balance',
  'charges',
  'total_balance',
  'payment_amount',
  'remainder'
]

export const accountingTableHeaders = {
  main,
  payments,
  discounts,
  charges: discounts
}
