<template>
  <div
    id="profile-education-organization"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.education.organization.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-150px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="organizations"
        :headers="tableHeadersOrganization"
        :sortable="true"
      >
        <template #title="{item}">
          <p class="text-gray-800 fw-bold m-0 fs-4">
            {{ getOrganizationById(item.organization) || item.organization }}
          </p>
        </template>
        <template #start-date="{item}">
          <span class="text-dark fw-bolder d-block mb-1 fs-4">
            {{ formatDateSimple(item.membership_date) }}
          </span>
          <span class="text-muted fw-bold text-muted d-block fs-7">
            {{ formatDate(item.membership_date, $t('common.table.start-date')) }}
          </span>
        </template>
        <template #end-date="{item}">
          <span
            v-if="!item.is_ongoing"
            class="text-dark fw-bolder d-block mb-1 fs-4"
          >
            {{ formatDateSimple(item.end_date) }}
          </span>
          <span
            v-if="!item.is_ongoing"
            class="text-muted fw-bold text-muted d-block fs-7"
          >
            {{ formatDate(item.end_date, $t('common.table.end-date')) }}
          </span>
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <OrganizationForm v-if="modifier && modifier.name === 'organization'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import { dateHelper } from '@/shared/utils'

import { tableHeadersOrganization } from '../values'
import { useProfile } from '../../../store'

import OrganizationForm from './OrganizationForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()
const { agoDateFormatter } = dateHelper('-')

const resource = useResource()
const store = useProfile()

const organizations = computed(() => store.getOrganizations)
const organizationsAll = computed(() => resource.getProfileResource?.organizations || [])

const setIdAction = (id: number) => {
  setModifier('organization',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'organization-new-title' : 'organization-edit-title',
      description: id === -1 ? 'organization-new-description' : 'organization-edit-description'
    }
  )
  open({ name: 'education-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.education.organization.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteOrganization(id)
    }
  })
}

const getOrganizationById = (id: number|string): string => {
  return organizationsAll.value.find((organization) => organization.id === id)?.title || ''
}

const formatDate = (date: string, label: string) => {
  if (!date) return
  const dateSplitted = date.split(' ')[0]

  return agoDateFormatter(dateSplitted, label)
}

const formatDateSimple = (date: string) => {
  if (!date) return
  return date.split(' ')[0]
}
</script>
