import { AcademicManageCurriculumResources } from '@shared/swagger'
import { SelectField } from '@shared/types'

export default (resource: AcademicManageCurriculumResources | null): SelectField['options'] => {
  const options: SelectField['options'] = []

  if (resource) {
    resource.elective_names.forEach((item) => {
      options.push({
        label: item.title,
        value: item.code
      })
    })
  }
  return options
}
