import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { isTranslatable } from '@shared/utils'

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: 'Please, set a title'
    },
    description: {
      type: String as PropType<string>,
      default: 'Please, set a description'
    },
    tour: {
      type: Boolean,
      required: false,
      default: true
    },
    noMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['launch'],
  setup (props, { emit, slots }) {
    const { t } = useI18n()
    const tour = () => emit('launch', true)

    return () => (
      <div class="d-flex flex-column">
        <div
          class={ 'd-flex justify-content-between ' + (props.noMobile ? '' : 'flex-md-row flex-column') }
        >
          <div class="d-flex flex-row align-items-center justify-content-between mb-4 mb-md-0">
            <h1
              class="page-heading text-dark fw-bold fs-1"
              style={{
                marginBottom: '1px'
              }}
            >
              { isTranslatable(props.title) ? t(props.title) : props.title }
            </h1>
            {
              props.tour && (
                <button
                  onClick={ () => tour() }
                  title={ t('phrases.need-help') }
                  type="button"
                  class="btn btn-sm btn-light-primary px-4 py-2"
                  style={{
                    marginLeft: '0.8rem',
                    padding: '0.3rem 0.9rem !important'
                  }}
                >
                  { t('phrases.need-help') }
                </button>
              )
            }
          </div>
          <div class="align-items-center">
            { slots.toolbar ? slots.toolbar() : null }
          </div>
        </div>
        { props.description && (
          <p class="fw-semibold text-gray-700 fs-5 mt-1">
            { isTranslatable(props.description) ? t(props.description) : props.description }
          </p>
        )}
      </div>
    )
  }
})
