<template>
  <thead
    class="table-head-bg text-dark"
    :class="themeMode === 'light' ? 'light-background' : 'dark-background'"
  >
    <tr>
      <th
        class="ms-1 px-4 table-center"
      >
        <span>{{ $t('common.table.no') }}</span>
      </th>
      <template
        v-for="(header, index) in tableHeaders"
        :key="index"
      >
        <th
          class="text-center"
        >
          {{ $t(header.title) }}
        </th>
      </template>
      <th
        class="ms-1 px-4 table-center"
      >
        <div class="d-flex align-items-center">
          <div class="ms-9 me-15">
            <AttendanceTableBodySessionCell
              :student="header_attend"
              :mode-all="true"
            />
          </div>
          <pms-button
            class="py-1"
            variant="outlined"
            color="success"
            @click="saveAll"
          >
            <i class="bi bi-floppy2 p-0" />

            <span class="ms-2">
              {{ $t('common.save_all') }}
            </span>
          </pms-button>
          <pms-button
            class="py-1 ms-3"
            variant="outlined"
            color="danger"
            @click="resetAll"
          >
            <i class="bi bi-trash p-0" />
            <span class="ms-2">
              {{ $t('common.reset_all') }}
            </span>
          </pms-button>

          <pms-button
            class="py-1 ms-3 ms-xl-auto"
            color="secondary"
            @click="cancelAll"
          >
            {{ $t('common.cancel_all') }}
          </pms-button>
        </div>
      </th>
    </tr>
  </thead>
</template>
<script setup lang="ts">
import { themeMode } from '@shared/composable/pattern'
import AttendanceTableBodySessionCell from './AttendanceTableBodySessionCell.vue'
import { tableHeaders } from '../values'
import { header_attend, useAttendanceTable } from '../composable'

const { saveAll, resetAll, cancelAll } = useAttendanceTable()
</script>
<style scoped lang="scss">
.table-head-bg {
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
</style>
