import type { SelectField } from '@/shared/types'
import { useResource } from '@/shared/stores'

export default (): SelectField['options'] => {
  const options: SelectField['options'] = []
  const resource = useResource()

  resource.academicInformationResource?.application_statuses.forEach((application_status) => {
    options.push({
      label: application_status.title,
      value: application_status.id
    })
  })
  return options
}
