import { computed, ref } from 'vue'

import { useRoute } from 'vue-router'
import { useResource } from '@shared/stores'

import { RouteParams } from '../../../types'
import { useSingleSection } from '../../../store'

import { StudentWithSchedule } from '../types'
import { modules } from '../values'
import { scheduleToTableHeaders, scheduleToTableData } from '../utils'
import { useToggler } from '@/shared/composable'

const currentTableHeaderIndex = ref<number>(0)
const tableData = ref<Array<StudentWithSchedule>>([])

export const useAttendance = () => {
  const route = useRoute()
  const store = useSingleSection()
  const resourceStore = useResource()

  const { toggle } = useToggler()

  const resource = computed(() => resourceStore.getAcademicSingleCourseAttendanceResource)
  const tableHeaders = computed(() => scheduleToTableHeaders(resource.value))

  const fetchAttendance = async (year, term, course_code, section) => {
    await store.fetchAttendance({ year, term, course_code, section })
  }

  const getCourseProperties = () => {
    const { year, term, code: course_code, section } = route.query as unknown as Required<RouteParams>
    return { year, term, course_code, section }
  }

  const setTableData = async () => {
    tableData.value = scheduleToTableData(resource.value, store.getAttendance)
  }

  const mount = async () => {
    const { year, term, course_code, section } = getCourseProperties()
    await resourceStore.fetchAcademicSingleCourseAttendanceResource({ course_code, year, term, section, modules })
    await fetchAttendance(year, term, course_code, section)
    await setTableData()

    // for (let index = 0; index < tableHeaders.value.headers.length; index++) {
    //   const elements = tableHeaders.value.headers[index]
    //   if(elements.filter(item=>new Date(item).getWeekNumber()))
    // }

    // currentTableHeaderIndex.value = tableHeaders.value.headers.find(header => {
    //   return header.find(item => new Date(item.date)) != null
    // })

    toggle()
  }

  const nextIndex = () => {
    if (currentTableHeaderIndex.value + 1 !== tableHeaders.value.options.length) {
      currentTableHeaderIndex.value++
    }
  }

  const previousIndex = () => {
    if (currentTableHeaderIndex.value !== 0) {
      currentTableHeaderIndex.value--
    }
  }

  return {
    resource,
    currentTableHeaderIndex,
    tableHeaders,
    tableData,
    mount,
    fetchAttendance,
    setTableData,
    getCourseProperties,
    nextIndex,
    previousIndex
  }
}
