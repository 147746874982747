import type { StoreType } from '../types'
import type { DashboardWidgetsGoogleCalendarPutRequest } from '@shared/swagger'

import { putDashboardWidgetsCalendarEventsTokens } from '@shared/api'
import { useAlert } from '@/shared/composable'

export default async function putCalendarEventsToken (this: StoreType, body: DashboardWidgetsGoogleCalendarPutRequest): Promise<void> {
  const { ok, message } = await putDashboardWidgetsCalendarEventsTokens(body)
  const { error, success } = useAlert()
  if (ok) {
    success({ text: message, isToast: true })
    this.refresh_token = body.refresh_token
  } else {
    error({ text: message, isToast: true })
  }
}
