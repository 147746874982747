import { FormField } from '@/shared/types'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { useResource } from '@/shared/stores'
import { AcademicManageCurriculumElectiveGetResponse, AcademicManageCurriculumElectivePutRequest } from '@/shared/swagger'
import { useAlert } from '@/shared/composable'
import editElectiveSlot from '@/shared/api/AcademicCurricula/editElectiveSlot'

const useElectiveSlotEditor = () => {
  const { t } = useI18n()
  const store = useStore()
  const resource = useResource()
  const { error, success } = useAlert()
  const { curriculumElectiveSlot } = storeToRefs(store)

  type Options = {label: string, value: string}[]

  const terms = ref<number[]>([])

  const formItemClasses = 'formItem me-5'
  const languageItemClasses = 'languageItem me-5'

  const languageOptions = ref<Options>([])
  const electiveNameOptions = ref<Options>([])

  const theoryRate = ref<number>(0)
  const practiceRate = ref<number>(0)
  const labRate = ref<number>(0)

  const isManagingCourses = ref(false)

  enum SectionTypes {
    Lection = 'N',
    Labaratory = 'L',
    Practice = 'P'
  }

  enum ElectiveType {
    AE = 'AE',
    NAE = 'NAE',
    NTE = 'NTE'
  }
  const currentElectiveType = ref<null | ElectiveType>(null)

  type ElectiveCourses = (AcademicManageCurriculumElectiveGetResponse['area_electives'][0] & {electiveType: ElectiveType, isSelected: boolean}) []

  type Nullable<T> = null | T;

  const tableData = ref<{
    electiveCourses: ElectiveCourses,
    electiveCoursesToShow: ElectiveCourses
  }>({
    electiveCourses: [],
    electiveCoursesToShow: []
  })

  const onCheckToogleCourse = (course: ElectiveCourses[0]) => {
    course.isSelected = !course.isSelected
  }

  const toogleManageCourses = () => {
    isManagingCourses.value = !isManagingCourses.value
  }

  const slotSettings = ref<{
    term: Nullable<number>,
    // electiveCode: null | string,
    language_code: null | string,
    title_kz: string,
    title_ru: string,
    title_en: string,
    elective_type: Nullable<ElectiveType>,
    theory: number,
    practice: number,
    lab: number,
    ects: number,
    credits: number

    is_restricted: boolean,
    for_all_tracks: boolean,

    custom_courses_select: boolean,
    custom_selected_course_ids: number[]

    }>({
      term: null,
      // electiveCode: null,
      language_code: null,
      title_kz: '',
      title_en: '',
      title_ru: '',
      elective_type: null,
      theory: 0,
      practice: 0,
      lab: 0,
      ects: 0,
      credits: 0,
      is_restricted: false,
      for_all_tracks: false,

      custom_courses_select: false,
      custom_selected_course_ids: []

    })

  const prepareCourseToShow = () => {
    tableData.value.electiveCoursesToShow = tableData.value.electiveCourses.filter((electiveCourse) => {
      return electiveCourse.electiveType === slotSettings.value.elective_type as ElectiveType
    })
  }

  watch(slotSettings.value, (newValue) => {
    prepareCourseToShow()

    if (newValue.elective_type !== currentElectiveType.value) {
      currentElectiveType.value = newValue.elective_type
    }
  })

  // Set default value
  watch(curriculumElectiveSlot, () => {
    if (curriculumElectiveSlot.value) {
      // slotSettings.value.electiveCode = curriculumElectiveSlot.value.elective_course.code
      currentElectiveType.value = curriculumElectiveSlot.value.elective_course.elective_code as ElectiveType

      slotSettings.value.elective_type = curriculumElectiveSlot.value.elective_course.elective_code as ElectiveType

      slotSettings.value.theory = curriculumElectiveSlot.value.elective_course.theory
      slotSettings.value.practice = curriculumElectiveSlot.value.elective_course.practice
      slotSettings.value.lab = curriculumElectiveSlot.value.elective_course.lab
      slotSettings.value.ects = curriculumElectiveSlot.value.elective_course.ects
      slotSettings.value.term = curriculumElectiveSlot.value.elective_course.term

      slotSettings.value.title_kz = curriculumElectiveSlot.value.elective_course.title_kz
      slotSettings.value.title_en = curriculumElectiveSlot.value.elective_course.title_en
      slotSettings.value.title_ru = curriculumElectiveSlot.value.elective_course.title_ru

      slotSettings.value.is_restricted = curriculumElectiveSlot.value.elective_course.is_restricted
      slotSettings.value.for_all_tracks = curriculumElectiveSlot.value.elective_course.for_all_tracks

      slotSettings.value.language_code = curriculumElectiveSlot.value.elective_course.language_code

      terms.value = Array.from({ length: curriculumElectiveSlot.value.elective_course.track.terms }, (_, i) => i + 1)

      const elective_courses: ElectiveCourses = []
      // todo podumat ob etom 'isSelected: elective_course.in_group'
      curriculumElectiveSlot.value.area_electives.forEach((elective_course) => {
        elective_courses.push({ isSelected: elective_course.in_group, electiveType: ElectiveType.AE, ...elective_course })
      })
      curriculumElectiveSlot.value.non_areaElectives.forEach((elective_course) => {
        elective_courses.push({ isSelected: elective_course.in_group, electiveType: ElectiveType.NAE, ...elective_course })
      })
      curriculumElectiveSlot.value.non_theoretical_electives.forEach((elective_course) => {
        elective_courses.push({ isSelected: elective_course.in_group, electiveType: ElectiveType.NTE, ...elective_course })
      })

      tableData.value.electiveCourses = elective_courses
      prepareCourseToShow()
    }
  })

  watch(() => resource.academicManageCurriculumResource, () => {
    if (resource.academicManageCurriculumResource) {
      languageOptions.value = resource.academicManageCurriculumResource.languages.map((language) => {
        return {
          label: language.title,
          value: language.language_code
        }
      })

      electiveNameOptions.value = resource.academicManageCurriculumResource.elective_names.filter(elective_name => elective_name.is_visible).map((elective_name) => {
        return {
          label: elective_name.title,
          value: elective_name.code
        }
      })

      resource.academicManageCurriculumResource.section_types.forEach((section_type) => {
        if (section_type.key === SectionTypes.Lection) {
          theoryRate.value = section_type.credit_rate
        } else if (section_type.key === SectionTypes.Labaratory) {
          labRate.value = section_type.credit_rate
        } else if (section_type.key === SectionTypes.Practice) {
          practiceRate.value = section_type.credit_rate
        }
      })
    }
  })

  const calculateCredits = () => {
    if (
      slotSettings.value.theory === null ||
      slotSettings.value.practice === null ||
      slotSettings.value.lab === null ||
      !resource.academicManageCurriculumResource
    ) return

    const credits =
          (slotSettings.value.theory * theoryRate.value) +
          (slotSettings.value.practice * practiceRate.value) +
          (slotSettings.value.lab * labRate.value)

    slotSettings.value.credits = credits
  }

  const calculateCreditsAndEcts = () => {
    calculateCredits()
    slotSettings.value.ects = Math.ceil(slotSettings.value.credits * 1.5)
  }

  const onSubmit = async () => {
    if (!slotSettings.value.language_code) {
      error({
        text: t('domains.academic.curricula.elective-slot-edit.error-language-not-selected')
      })
      return
    }
    if (!slotSettings.value.elective_type) {
      error({ text: t('domains.academic.curricula.elective-slot-edit.error-elective-type-not-selected') })
      return
    }
    if (!slotSettings.value.term) {
      error({ text: t('domains.academic.curricula.elective-slot-edit.error-term-not-selected') })
      return
    }

    if (!curriculumElectiveSlot.value) return
    let selectedElectiveCourseIds

    // User can submit selected slots if only enabled managing
    if (isManagingCourses.value) {
      selectedElectiveCourseIds = tableData.value.electiveCourses.filter(electiveCourse => {
        return electiveCourse.isSelected && electiveCourse.electiveType === slotSettings.value.elective_type
      }).map(electiveCourse => electiveCourse.curriculum_id)
    } else {
      selectedElectiveCourseIds = tableData.value.electiveCourses.filter(electiveCourse => {
        return electiveCourse.in_group
      }).map(electiveCourse => electiveCourse.curriculum_id)
    }

    const requestData: AcademicManageCurriculumElectivePutRequest = {
      track_id: curriculumElectiveSlot.value.elective_course.track.id,
      term: slotSettings.value.term,
      course_code: curriculumElectiveSlot.value.elective_course.code,
      title_kz: slotSettings.value.title_kz,
      title_ru: slotSettings.value.title_ru,
      title_en: slotSettings.value.title_en,
      theory: slotSettings.value.theory,
      practice: slotSettings.value.practice,
      lab: slotSettings.value.lab,
      ects: slotSettings.value.ects,
      elective_code: slotSettings.value.elective_type,
      language_code: slotSettings.value.language_code,
      courses_slot: selectedElectiveCourseIds,

      for_all_tracks: slotSettings.value.for_all_tracks === true,
      is_restricted: slotSettings.value.is_restricted === true
    }

    const { status } = await editElectiveSlot(curriculumElectiveSlot.value.elective_course.id, requestData)

    if (status) {
      success({
        text: t('domains.academic.curricula.elective-slot-edit.success-save')
      })
      store.fetchCurriculumElectiveSlot({ id: curriculumElectiveSlot.value.elective_course.id })
      isManagingCourses.value = false
    }
  }

  watch(() => [resource.academicManageCurriculumResource, curriculumElectiveSlot], () => {
    if (resource.academicManageCurriculumResource && curriculumElectiveSlot) {
      calculateCredits()

      watch(() => [slotSettings.value.theory, slotSettings.value.practice, slotSettings.value.lab],
        () => {
          calculateCreditsAndEcts()
        }
      )
    }
  })

  const courseSettingsFormFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: t('common.term'),
        class: 'mb-1'
      },
      name: 'term',
      required: true,
      type: 'select',
      value: slotSettings.value.term || null,
      options: terms.value.map((term) => {
        return {
          label: term.toString(),
          value: term
        }
      }),
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.language'),
        class: 'mb-1'
      },
      name: 'language_code',
      type: 'select',
      required: true,
      validator: {
        schema: 'yup',
        rules: [
          'string',
          {
            func: 'required',
            args: ['An option must be selected']
          },
          {
            func: 'test',
            args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
          }
        ]
      },
      value: slotSettings.value.language_code,
      options: languageOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.course-title-kz'),
        class: 'mb-1'
      },
      name: 'title_kz',
      type: 'text',
      value: slotSettings.value.title_kz || null,
      group: {
        class: languageItemClasses
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.course-title-ru'),
        class: 'mb-1'
      },
      name: 'title_ru',
      type: 'text',
      value: slotSettings.value.title_ru || null,
      group: {
        class: languageItemClasses
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.course-title-en'),
        class: 'mb-1'
      },
      name: 'title_en',
      type: 'text',
      value: slotSettings.value.title_en || null,
      group: {
        class: languageItemClasses
      }
    },

    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.elective-type'),
        class: 'mb-1'
      },
      name: 'elective_type',
      type: 'select',
      value: slotSettings.value.elective_type,
      options: electiveNameOptions.value,
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.elective-restrict'),
        class: 'mb-1 '
      },
      name: 'is_restricted',
      type: 'switch',
      value: slotSettings.value.is_restricted,
      group: {
        class: 'd-flex align-items-center me-5'
      },
      cover: {
        class: 'd-flex flex-column-reverse gap-5 align-items-baseline'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.program-slot'),
        class: 'mb-1'
      },
      name: 'for_all_tracks',
      value: slotSettings.value.for_all_tracks,
      type: 'switch',
      group: {
        class: 'd-flex align-items-center me-5'
      },
      cover: {
        class: 'd-flex flex-column-reverse gap-5 align-items-baseline'
      }
    },

    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: `${t('common.theory')}`,
        class: 'mb-1'
      },
      name: 'theory',
      type: 'number',
      value: slotSettings.value.theory || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.practice'),
        class: 'mb-1'
      },
      name: 'practice',
      type: 'number',
      value: slotSettings.value.practice || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.lab'),
        class: 'mb-1'
      },
      name: 'lab',
      type: 'number',
      value: slotSettings.value.lab || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.ects'),
        class: 'mb-1'
      },
      name: 'ects',
      type: 'number',
      value: slotSettings.value.ects || null,
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('common.credits'),
        class: 'mb-1'
      },
      name: 'credits',
      type: 'number',
      disabled: true,
      value: slotSettings.value.credits || null,
      group: {
        class: formItemClasses
      }
    }
  ]
  )

  return {
    slotSettings,
    courseSettingsFormFields,
    isManagingCourses,
    tableData,
    onCheckToogleCourse,
    toogleManageCourses,
    onSubmit
  }
}

export default useElectiveSlotEditor
