import { RouteRecordWithLayout } from '@shared-types/common'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useKnowledgeManagePost } from './store'
import { usePagination } from '@shared/composable'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const page = to.meta.page as string
  const uuid = to.params.uuid as string

  if (page === ('single' || 'edit') && !uuid) {
    next({ path: '404' })
    return
  }

  const pagination = usePagination()
  const store = useKnowledgeManagePost()
  const search = to.query.search ? String(to.query.search) : undefined
  const category = to.query.category_uuid ? String(to.query.category_uuid) : undefined

  pagination.$reset()
  store.$reset()
  store.bootstrap(page, uuid, search, category)

  next()
}

export default {
  layout: 'default',
  path: '/knowledge-base/manage',
  children: [
    {
      path: 'articles',
      name: 'KnowledgeManagePostsListing',
      component: () => import('./features/PostsListing/PostsListing.vue'),
      beforeEnter: [
        handleRoute
      ],
      meta: {
        page: 'listing'
      }
    },
    {
      path: 'article',
      children: [
        {
          path: 'new',
          name: 'KnowledgeManagePostForm',
          component: () => import('./features/PostForm/PostForm.vue'),
          beforeEnter: [
            handleRoute
          ],
          meta: {
            page: 'new'
          }
        },
        {
          path: 'edit/:uuid',
          name: 'KnowledgeManagePostFormEdit',
          component: () => import('./features/PostForm/PostForm.vue'),
          beforeEnter: [
            handleRoute
          ],
          meta: {
            page: 'edit'
          }
        },
        {
          path: ':uuid',
          name: 'KnowledgeManagePostSingle',
          component: () => import('./features/SinglePost/SinglePost.vue'),
          beforeEnter: [
            handleRoute
          ],
          meta: {
            page: 'single'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
