import { SmartTableHeader } from '@/shared/types'
import { Placement } from '../types'

export const consentTable: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'course_code',
    sortable: true
  },
  {
    title: 'common.table.course-section',
    field: 'section',
    slotCellName: 'section',
    sortable: true
  },
  {
    title: 'common.table.course-title',
    field: 'course_title',
    slotCellName: 'course_title',
    sortable: true
  },
  {
    title: 'common.roles.teacher',
    field: 'teacher',
    slotCellName: 'teacher',
    sortable: true
  },
  {
    title: 'common.table.details',
    field: 'details',
    subHeaders: [
      {
        title: 'domains.academic.consent-request.table.pending',
        field: 'pending_requests_count',
        slotCellName: 'pendings',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'domains.academic.consent-request.table.accepted',
        field: 'accepted_requests_count',
        slotCellName: 'accepteds',
        cellClass: 'text-center',
        sortable: true
      },
      {
        title: 'domains.academic.consent-request.table.rejected',
        field: 'rejected_requests_count',
        slotCellName: 'rejecteds',
        cellClass: 'text-center',
        sortable: true
      }
    ]
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const mainInformationTableHeaders: Array<Placement> = [
  {
    id: 'left',
    rowClass: 'col-md-6',
    tableData: [
      {
        translate: 'photo',
        field: 'photo',
        fields: ['student_id', 'student_name'],
        dateValue: false,
        custom: 'photo'
      },
      {
        translate: 'message',
        field: 'message',
        dateValue: false
      }
    ]
  },
  {
    id: 'right',
    rowClass: 'col-md-6',
    tableData: [
      {
        translate: 'speciality',
        field: 'speciality',
        dateValue: false
      },
      {
        translate: 'type',
        field: 'type',
        dateValue: false
      },
      {
        translate: 'date',
        field: 'date',
        dateValue: true
      },
      {
        translate: 'status',
        field: 'status',
        dateValue: false,
        custom: 'status'
      }
    ]
  }
]
