
import { useRequests } from '@shared/composable'
import type { AcademicManageCurriculumPostLearningOutcomeRequest, UnknownResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function postCurriculaOutcome (data: AcademicManageCurriculumPostLearningOutcomeRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<AcademicManageCurriculumPostLearningOutcomeRequest, UnknownResponse>({
    url: 'academic/manage/curriculum/learning-outcomes',
    body: data,
    loader: 'api-postCurriculumOutcome'
  })

  return response
}
