<template>
  <div>
    <!-- Элемент отображается только если есть разрешение и is_active -->
    <!-- permission is_active === null or is_active === true - значит что perrmission открыт -->
    <slot v-if="hasPermission && (permission.is_active === null || permission.is_active)" />
    <div v-else-if="showNotPermittedCard && permission && permission.is_active!== null">
      <pms-card :header="false">
        <h3 class="text-center">
          {{ $t('common.not-currently-permitted').toUpperCase() }}
        </h3>
      </pms-card>
    </div>
    <div v-else-if="showNotPermittedCard">
      <pms-card :header="false">
        <h3 class="text-center">
          {{ $t('common.not-permitted').toUpperCase() }}
        </h3>
      </pms-card>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useUser from '@/shared/stores/useUser'
const props = defineProps({
  permissionCode: {
    type: String,
    required: true
  },
  showNotPermittedCard: {
    type: Boolean,
    required: false
  }
})

const userStore = useUser()

const permissions = computed(() => userStore.getModulePermissions)
const permission = computed(() => permissions.value.find(permission => permission.code === props.permissionCode))
const hasPermission = computed(() => !!permission.value)
</script>
