import type { StatusMessageResponse } from '@shared/types'
import type { ProfileCertificatesPostRequest } from '@shared/swagger'
import { putProfileCertificates } from '@shared/api'

import type { StoreType } from '../types'

export default async function editCertificate (this:StoreType, id: number, values: ProfileCertificatesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileCertificates(id, values)

  if (ok) await this.fetchCertificates()

  return {
    status: ok,
    message: message
  }
}
