import { SmartTableHeader, SmartTableSortField } from '@/shared/types'
import { ref } from 'vue'

export const allStudentsTable: Array<SmartTableHeader> = [

  {
    title: 'common.table.student-id',
    field: 'name',
    slotCellName: 'studentData',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.class',
    field: 'class',
    slotCellName: 'class',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.academic-status',
    field: 'academic_status',
    slotCellName: 'academicStatus',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    slotCellName: 'speciality',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.entrance-year',
    field: 'entrance_year',
    slotCellName: 'entranceYear',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.financial-lock',
    field: 'financial_lock',
    slotCellName: 'financial-lock',
    headerClass: 'text-center',
    cellClass: 'text-center'
  }
]

export const allStudentsSortField = ref<SmartTableSortField>({ field: '', direction: 'neutral' })

export const resetAllStudentsSortField = () => {
  allStudentsSortField.value = { field: '', direction: 'neutral' }
}
