import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    container: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: 100
    },
    center: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as PropType<'sm' | 'lg' | 'xl' | string>,
      default: 'lg'
    }
  },
  setup (props) {
    if (props.container) {
      return () => {
        <span
          class={{
            'spinner-border': true,
            [`spinner-border-${props.size}`]: true
          }}
        />
      }
    }
    return () => (
      <div
        class={{
          'd-flex': true,
          'justify-content-center align-items-center': props.center
        }}
        style={{
          height: `${props.height}${typeof props.height === 'number' ? 'px' : ''}`
        }}
      >
        <span
          class={{
            'spinner-border': true,
            [`spinner-border-${props.size}`]: true
          }}
        />
      </div>
    )
  }
})
