import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'

export default async function destroyProfileSkillsLanguages (id: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `profile/skills/languages/${id}`,
    body: {},
    loader: 'profile/skills/languages-destroy'
  })

  return response
}
