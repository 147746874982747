import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse
} from '@shared/swagger'

export default async function putAdministrativeMessageBoardPosts (id: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `administrative/message-board/posts/${id}`,
    body: {},
    loader: 'administrative/message-board/posts-delete'
  })

  return response
}
