<template>
  <pms-card
    :title="`${t('domains.academic.manage.curriculum.course-information-title')}`"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <div
      v-else
      class="mb-5"
    >
      <h1>{{ `${t('common.department')}: ` + curriculumElectiveSlot?.elective_course.track.program.department.title }}</h1>

      <span class="fs-1 fw-1">
        <span class="fw-bold">{{ `${t('common.program')}: ` }}</span>{{ curriculumElectiveSlot?.elective_course.track.program.code }}
      </span>
      <br>
      <span class="fs-2">
        <span class="fw-bold">{{ `${t('common.track')}: ` }}</span>{{ curriculumElectiveSlot?.elective_course.track.title }}
      </span>
      <br>
      <span class="fs-3 fw-1">
        <span class="fw-bold">{{ `${t('common.course-code')}:` }}</span>{{ ` ${curriculumElectiveSlot?.elective_course.code}` }}
      </span>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '@/domains/AcademicCurricula/store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'

const { isActive } = useLoader()

const loader = isActive('api-fetchCurriculumElectiveSlot')

const { t } = useI18n()

const store = useStore()
const { curriculumElectiveSlot } = storeToRefs(store)
</script>
