import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationArticlesPostRequest } from '@shared/swagger'

export default async function putProfileEducationArticles (article_id: number, values: ProfileEducationArticlesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileEducationArticlesPostRequest, UnknownResponse>({
    url: `profile/education/articles/${article_id}`,
    body: values,
    loader: 'profile/education/articles-edit'
  })

  return response
}
