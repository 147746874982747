<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { useI18n } from 'vue-i18n'
import { useResource } from '@shared/stores'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { SyllabusFormMode } from './../types'
import { objectDeepCopy } from '@shared/utils'
import { academicSyllabusActivityTitleToId, academicSyllabusActivityToOptions } from './../utils'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const { t } = useI18n()
const resource = useResource()
const store = useStore()
const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['activities'][0] & SyllabusFormMode> = {
  id: -1,
  title: '',
  quantity: 0,
  hour: 0,
  mode: 'add'
}
const tempValue = ref()

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('common.form.type'),
      class: 'mb-1 mt-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'type_id',
    type: 'select',
    options: academicSyllabusActivityToOptions(resource.getAcademicManageSyllabusResourceActivity),
    value: academicSyllabusActivityTitleToId(tempValue.value.title)
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.form.quantity')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'text',
    name: 'quantity',
    value: tempValue.value.quantity || null,
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        'positive'
      ]
    }
  },
  {
    label: {
      class: 'fw-semibold fs-6',
      text: `${t('common.form.hours')}`
    },
    group: {
      class: 'mb-4'
    },
    type: 'text',
    name: 'hour',
    value: tempValue.value.hour || null,
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required',
        'positive'
      ]
    }
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if ((newValue.data as Record<string, unknown>).mode === 'edit' && (newValue.data as Record<string, unknown>).id) {
        const item = store.getActivities.find((item) => item.id === (newValue.data as Record<string, unknown>)?.id)
        tempValue.value = { mode: (newValue.data as Record<string, unknown>).mode, ...(item || defaultValue) }
      }
      if ((newValue.data as Record<string, unknown>).mode === 'add') {
        tempValue.value = objectDeepCopy(defaultValue)
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'add') {
    const hour = values.hour ? Number(values.hour) : 0
    const quantity = values.quantity ? Number(values.quantity) : 0
    const typeId = Number(values.type_id)
    store.addActivity({
      type_id: typeId,
      hour: hour,
      quantity: quantity
    })
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
  if (tempValue.value.mode === 'edit') {
    const hour = values.hour ? Number(values.hour) : 0
    const quantity = values.quantity ? Number(values.quantity) : 0
    const typeId = Number(values.type_id)
    store.editActivity({
      type_id: typeId,
      hour: hour,
      quantity: quantity
    }, tempValue.value.id)
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
}
</script>
