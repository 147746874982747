<template>
  <div
    id="pms_sidebar_drawer"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'100%', 'lg': '450px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#pms_sidebar_drawer_toggle"
    data-kt-drawer-close="#pms_sidebar_drawer_close"
  >
    <div class="card shadow-none w-100">
      <div
        id="pms_sidebar_drawer_header"
        class="card-header"
      >
        <h3 class="card-title fw-bold text-dark">
          {{ $t('layout.drawers.activity.header') }}
        </h3>

        <div class="card-toolbar">
          <button
            id="pms_sidebar_drawer_close"
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <div
        id="pms_sidebar_drawer_body"
        class="card-body position-relative"
      >
        <div>pms - sidebar</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
