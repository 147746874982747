<template>
  <div class="d-flex pt-6">
    <div class="symbol symbol-45px me-5">
      <img
        style="object-fit: cover;"
        :src="comment.user_photo"
        :alt="comment.user_full_name"
      >
    </div>
    <div class="d-flex flex-column">
      <span class="text-gray-800 fw-bold me-6 fs-5">
        {{ comment.user_full_name }}
      </span>
      <span class="text-gray-400 fw-semibold fs-6 me-5">
        {{ comment.created_at }}
      </span>
    </div>
    <div class="d-flex flex-column flex-row-fluid">
      <div class="d-flex flex-wrap mb-0">
        <a
          v-if="username === comment.username"
          href="javascript:void(0)"
          class="text-danger fw-bold fs-6"
          :class="{ 'disabled pe-none': commentDeleteLoader }"
          @click="handleDeleteComment()"
        >
          <span
            v-if="commentDeleteLoader && id === comment.id"
            class="indicator-label"
          >
            {{ $t('phrases.please-wait') }}
            <span class="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
          <span
            v-else
            class="indicator-label"
          >
            {{ $t('common.delete') }}
          </span>

        </a>
      </div>
    </div>
  </div>
  <div class="mt-2 mb-2">
    <p
      class="text-gray-800 fs-5 fw-normal pt-2 mb-0 pb-0"
      v-html="expend ? comment.content : cutContent(comment.content, {length:200, removeHtmlTags: true})"
    />
    <div>
      <button
        v-if="comment.content.length > 200"
        type="button"
        class="btn btn-link text-primary text-left"
        @click="expend = !expend"
      >
        {{ expend ? $t('common.less') : $t('common.more') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue'

import { useLoader } from '@shared/composable'
import { AdministrativeMessageBoardPostsGetResponse } from '@shared/swagger'

import { cutContent } from '@shared/utils/text'
import { useMessageBoard } from '@domains/AdministrativeMessageBoard/store'

const props = defineProps({
  comment: {
    type: Object as PropType<(AdministrativeMessageBoardPostsGetResponse['posts'][0]['comments'][0])>,
    required: true
  },
  username: {
    type: String as PropType<string>,
    required: true
  }
})

const { isActive } = useLoader()
const store = useMessageBoard()

const commentDeleteLoader = isActive('administrative/message-board/comments-delete')
const id = ref<number|null>(null)

const expend = ref<boolean>(false)

const handleDeleteComment = async () => {
  id.value = props.comment.id
  await store.deleteComment(props.comment.id)
}
</script>
