<template>
  <span
    class="fw-bold fs-6"
    :class="{'text-danger': isAbsent(studentId, assessmentId)}"
  >
    {{ isAbsent(studentId, assessmentId)
      ? $t('domains.academic.single-course.grade.absent')
      : getAssessment(assessmentId,studentId)
    }}
  </span>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { isAbsent } from '../utils'
import { AcademicSingleCourseGradeResponse } from '@shared/swagger'

const props = defineProps({
  assessmentId: {
    type: Number as PropType<number>,
    required: true
  },
  studentId: {
    type: String as PropType<string>,
    required: true
  },
  grades: {
    type: Array as PropType<AcademicSingleCourseGradeResponse>,
    required: true
  }
})

const getAssessment = (assess, studentId) => {
  const grade = props.grades.find((g) => g.student_id === studentId)?.grades.find((g) => g.assessment_id === assess)?.grade
  return grade === 0 ? 0 : grade || ''
}
</script>

<style scoped lang="scss">

</style>
