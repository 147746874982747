import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useStore } from './store'

const handleRouting = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  useStore().bootstrap()
  next()
}

const handleSinglePathRouting = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { path } = to.params
  useStore().bootstrapByPath(String(path))
  next()
}

export default {
  layout: 'empty',
  path: '/translate/contents',
  children: [
    {
      path: '',
      name: 'TranslateContents',
      component: () => import('./TranslateContents.vue'),
      beforeEnter: [
        handleRouting
      ]
    },
    {
      path: ':path',
      name: 'TranslateContent',
      component: () => import('./features/EditTranslateContents.vue'),
      beforeEnter: [
        handleSinglePathRouting
      ]
    }
  ]
} as RouteRecordWithLayout
