<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-0'} }"
    >
      <div
        :key="'information.course-description'"
        class="fs-2 fw-bold text-danger text-center my-5"
      >
        {{ $t('domains.academic.single-course.syllabus.moodle-links') }}
      </div>
      <pms-smart-table
        :items="formatInstructors"
        :headers="headers"
        :sortable="false"
        :options="{ design: 'bordered-dashed' }"
      >
        <template #name="{ item }">
          {{ item.full_name }}
        </template>
        <template #degree="{ item }">
          {{ item.degree }}
        </template>
        <template #moodle_link="{ item }">
          <template v-if="item.moodle_link">
            <a
              :href="item.moodle_link"
              target="_blank"
              class="link fs-6 fw-medium font-italic text-decoration-underline"
            >
              {{ item.moodle_link }}
            </a>
          </template>
        </template>
        <template #action="{ item }">
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              v-if="has_permission"
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="openEditor(item)"
            >
              <pms-kt-icon
                v-if="item.moodle_link"
                :icon-name="'click'"
                class="fw-bold fs-3 p-0"
              />
              <pms-kt-icon
                v-else
                :icon-name="'plus'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              v-if="item.moodle_link && has_permission"
              type="button"
              class="btn btn-light-danger p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.courses.tooltip.delete')"
              @click="deleteInstructor(item.id)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
            </button>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <moodle-link-form v-if="modifier && modifier.name === 'syllabus.moodle_link'" />
</template>

<script setup lang="ts">
import { useStore } from '../store'
import { computed } from 'vue'
import { moodleLinksTable, has_permission } from '../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import MoodleLinkForm from './MoodleLinkForm.vue'
import { useResource } from '@/shared/stores'

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus/instructors')
const { setModifier, open, modifier } = useTeleportModal()

const store = useStore()
const alert = useAlert()
const instructors = computed(() => store.getInstructors)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const headers = computed(() => amIEnrolled.value
  ? [...moodleLinksTable,
      {
        title: 'common.action',
        field: 'action',
        headerClass: 'w-50px',
        slotCellName: 'action',
        cellClass: 'text-center'
      }]
  : moodleLinksTable)

const formatInstructors = computed(() => {
  if (instructors.value) {
    return instructors.value.map((instructor) => {
      const contactPhones = (instructor.contacts || [])
        .filter((contact) => (
          contact.type === 'Mobile Phone' ||
          contact.type === 'Ұялы тел.' ||
          contact.type === 'Моб. тел.'
        ))
        .map((contact) => contact.contact).join(', ')

      const contactEmails = (instructor.contacts || [])
        .filter((contact) => (
          contact.type === 'E-mail' ||
          contact.type === 'Эл. пошта' ||
          contact.type === 'Эл. почта'
        ))
        .map((contact) => contact.contact).join(', ')

      return {
        ...instructor,
        contactPhones,
        contactEmails
      }
    })
  }
  return []
})

function openEditor (item) {
  setModifier(
    'syllabus.moodle_link',
    {
      id: item.id,
      moodle_link: item.moodle_link
    },
    { title: 'moodle-link.title.edit', description: 'moodle-link.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

function deleteInstructor (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-moodle-link',
      onConfirm: async () => {
        await store.deleteMoodleLink(id)
      }
    }
  )
}
</script>
../store@/domains/AcademicSingleCourse/features/EditorSyllabus/values
