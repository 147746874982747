import type { StoreType } from '../types'
import type { AcademicResourceRequest } from '@shared/swagger'

import { getAcademicResource } from '@shared/api'

export default async function fetchResource (this: StoreType, body: AcademicResourceRequest): Promise<void> {
  const { ok, data } = await getAcademicResource(body)

  if (ok) {
    this.setAcademicResource(data)
  }
}
