<template>
  <pms-card
    :title="$t('domains.academic.single-course.students.title')"
    :description="$t('domains.academic.single-course.students.description')"
    :options="{
      body: {
        class: 'pt-1',
      },
    }"
  >
    <pms-smart-table
      id="single-course-students-table"
      :items="students"
      :headers="tableHeaders"
      :sortable="true"
      :options="{
        table: {
          class: 'table-bordered table-border-blue',
        },
        headers: {
          columnStyle: 'border-color: #73b5d9 !important',
          columnClass: 'text-uppercase',
        },
        items: {
          style: 'border-bottom-width: 1px;',
        },
      }"
    >
      <template #checkboxHeader>
        <pms-checkbox
          class="d-flex justify-content-center"
          :variant="themeMode == 'dark' ? 'solid' : 'default'"
          :state="
            students.length > 0 && selectedStudents.length === students.length
          "
          :disabled="students.length === 0"
          @on-check="handleSelectAll()"
          @on-uncheck="handleUnselectAll()"
        />
      </template>
      <template #checkbox="{ row }">
        <pms-checkbox
          class="d-flex justify-content-center align-items-center"
          :state="isSelected(row)"
          @on-check="handleSelect(row)"
          @on-uncheck="handleUnselect(row)"
        />
      </template>
      <template #id="{ value, row }">
        <div class="d-flex align-items-center">
          <div
            class="symbol symbol-40px me-3"
            @mouseenter="onImageMouseEnter($event, row)"
            @mouseleave="onImageMouseLeave()"
          >
            <img
              :src="row.photo ? `${row.photo}` : '/user.png'"
              class="rounded"
              :alt="`${row.name} ${row.surname}`"
              style="object-fit: cover; cursor: pointer"
            >
          </div>
          <div class="d-flex justify-content-start flex-column">
            <p class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
              {{ row.name }} {{ row.surname }}
            </p>
            <span class="text-gray-400 fw-semibold d-block fs-7">
              {{ value }}
            </span>
          </div>
        </div>
      </template>
      <template #speciality="{ row }">
        <div
          class="w-100"
          :data-bs-original-title="row.speciality_code"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
        >
          <span
            class="fw-semibold d-block fs-7"
          >
            {{ row.speciality_name }}
          </span>
        </div>
      </template>

      <template #sections="{ value }">
        <div class="d-flex gap-2">
          <pms-badge
            v-for="val in value"
            :key="val.section_id"
            :value="val.section"
          />
        </div>
      </template>

      <template #action="{ row }">
        <router-link
          :to="`/academic/student/${row.id}`"
          class="text-primary opacity-75-hover fs-5 fw-semibold"
        >
          {{ $t("common.view") }}
        </router-link>
      </template>
    </pms-smart-table>

    <action-buttons
      v-show="selectedStudents.length > 0"
      :students="selectedStudents"
    />
  </pms-card>
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { AcademicCoursesStudentsResponse } from '@shared/swagger'

import { tableHeaders } from '../values'
import { getStudentsBySection } from '../utils'
import ActionButtons from './ActionButtons.vue'
import { themeMode } from '@/shared/composable/pattern'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'

const store = useSingleSection()

const students = computed(() =>
  getStudentsBySection(store.getStudents, selectedSection.value)
)
const selectedStudents = ref<AcademicCoursesStudentsResponse>([])
const selectedSection = ref<null | number>(null)

const handleSelect = (student: AcademicCoursesStudentsResponse[0]) => {
  selectedStudents.value.push(student)
}

const handleUnselect = (student: AcademicCoursesStudentsResponse[0]) => {
  selectedStudents.value = selectedStudents.value.filter(
    (s) => s.id !== student.id
  )
}

const handleSelectAll = () => {
  selectedStudents.value = students.value
}

const handleUnselectAll = () => {
  selectedStudents.value = []
}

const isSelected = (student: AcademicCoursesStudentsResponse[0]) => {
  return selectedStudents.value.some((s) => s.id === student.id)
}

const onImageMouseEnter = (event, row) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const image = document.querySelector('.student-image') as any

  if (tooltip && image) {
    image.src = row.photo ? `${row.photo}` : '/user.png'
    tooltip.style.display = 'block'
    tooltip.style.top = `${event.pageY}px`
    tooltip.style.left = `${event.pageX}px`
  }
}

const onImageMouseLeave = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltip = document.querySelector('.student-tooltip') as any

  if (tooltip) {
    tooltip.style.display = 'none'
  }
}

watch(
  () => students,
  () => {
    selectedStudents.value = []
  }
)
</script>
<style lang="scss" scoped>
.student-tooltip {
  position: absolute;
  z-index: 999;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 200px;
  pointer-events: none;
  .student-image {
    width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}
</style>
