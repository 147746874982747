<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'card'"
    :filter="true"
    :full-width="true"
  />
  <template v-else>
    <attendance-selector
      v-if="!hasSection"
      :sections="sections"
    />
    <attendance-card
      v-else
    />
  </template>
  <pms-teleport-modal
    :name="'attendance-modal'"
    :teleport-name="'attendance-modal-content'"
    :title="$t(`domains.academic.single-course.navigation.items.${editor.status}.title`)"
    :description="$t(`domains.academic.single-course.navigation.items.${editor.status}.description`)"
    :keyboard="false"
    :backdrop="false"
    :fullscreen="true"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
    @onClose="() => resetDefault(true)"
  />
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useLoader } from '@shared/composable'
import { useResource } from '@shared/stores'

import { getEnrolledSections } from '../../utils'
import { useAttendanceEditor } from './composable'

import AttendanceCard from './ui/AttendanceCard.vue'
import AttendanceSelector from './ui/AttendanceSelector.vue'

const { isActive } = useLoader()
const { editor, resetDefault } = useAttendanceEditor()

const resource = useResource()
const route = useRoute()

const loader = isActive('academic/courses/resources/single')
const sections = computed(() => getEnrolledSections(resource.getAcademicSingleCoursesResource))
const hasSection = computed(() => 'section' in route.query)
onMounted(() => {
  resetDefault()
})
</script>
