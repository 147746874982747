import { KnowledgeManagePostsPostRequest } from '@/shared/swagger'
import { ref } from 'vue'
export const newPost = ref<KnowledgeManagePostsPostRequest>({
  title: '',
  category_uuid: '',
  description: '',
  published: false,
  tags: [],
  content: {
    type_id: -1,
    version: '',
    image: '',
    video: '',
    video_type: '',
    content: '',
    active: true,
    visible: true,
    alert_type: '',
    alert_text: ''
  }
})
