import type { StatusMessageResponse } from '@shared/types'
import type{ AcademicReportsExecutePostRequest } from '@shared/swagger'
import { postReportExecute } from '@shared/api'

import type { StoreType } from '../types'

export default async function (this: StoreType, request:AcademicReportsExecutePostRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await postReportExecute(request)
  if (ok) {
    this.setReportData(data)
  }

  return {
    status: ok,
    message
  }
}
