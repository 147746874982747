import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ServiceMessageBroadcastPostResponse
} from '@shared/swagger'

export default async function getServiceMessageBroadcastSingle (message: string): Promise<AxiosResponse<ServiceMessageBroadcastPostResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceMessageBroadcastPostResponse>({
    url: `service/message-broadcast/${message}`,
    body: { },
    loader: 'service/message-broadcast-single'
  })

  return response
}
