import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSettingsPrivaciesPutRequest } from '@shared/swagger'
import { putProfileSettingsPrivacies } from '@shared/api'

import type { StoreType } from '../types'

export default async function editPrivacies (this:StoreType, values: ProfileSettingsPrivaciesPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSettingsPrivacies(values)

  return {
    status: ok,
    message: message
  }
}
