import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  AdministrativeMessageBoardCommentsPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postAdministrativeMessageBoardComment (params: AdministrativeMessageBoardCommentsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<AdministrativeMessageBoardCommentsPostRequest, UnknownResponse>({
    url: 'administrative/message-board/comments',
    body: params,
    loader: 'administrative/message-board/comments-post'
  })

  return response
}
