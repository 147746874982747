<template>
  <template
    v-for="(item, index) in items"
    :key="index"
  >
    <template v-if="divider && 'divider' in $slots">
      <slot name="divider" />
    </template>
    <template v-else>
      <pms-divider
        v-if="index !== 0 && divider"
        :margin-y="3"
        :border="1"
        design="dashed"
      />
    </template>
    <smart-list-item
      :item="item"
      :idx="`${index}`"
      :divider="divider"
      :level="0"
      :nestable="nestable"
      :nestable-state="nestedStatusList"
      :options="options"
      @toggleOn="addToNestedStatusList"
      @toggleOff="removeFromNestedStatusList"
    >
      <template
        v-for="(_, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </smart-list-item>
  </template>
</template>
<script lang="ts" setup>
import { PropType, watch, ref, computed } from 'vue'
import { ListOptions, NestedListItem } from '@shared-types/list'
import { handleOptions } from '@shared/utils'

import SmartListItem from './SmartListItem.vue'

const defaultOptions: ListOptions = {
  item: {
    class: 'd-flex justify-content-start mt-4 mb-4',
    toggleMargin: 25
  },

  toggle: {
    container: {
      class: 'd-flex justify-content-start',
      style: ''
    },

    class: 'btn btn-sm btn-icon h-20px w-20px me-2',
    style: ''
  }
}

const props = defineProps({
  items: {
    type: Array as PropType<Array<NestedListItem>>,
    required: true
  },
  divider: {
    type: Boolean,
    default: true
  },
  nestable: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object as PropType<ListOptions>,
    default: () => ({})
  }
})

const emit = defineEmits([
  'update:items',
  'update:divider',
  'update:nestable',
  'handleExpand'
])

const nestedStatusList = ref<Array<string>>([])
const options = computed(() => handleOptions(props.options, defaultOptions))
const addToNestedStatusList = (obj: { idx: string, item: NestedListItem}) => {
  nestedStatusList.value.push(obj.idx)
  emit('handleExpand', { action: 'expand', level: obj.idx.length, item: obj.item })
}

const removeFromNestedStatusList = (obj: { idx: string, item: NestedListItem}) => {
  nestedStatusList.value = nestedStatusList.value.filter((item) => item !== obj.idx)
  emit('handleExpand', { action: 'collapse', level: obj.idx.length, item: obj.item })
}

watch(
  () => [props.items, props.divider, props.nestable],
  (newValue) => {
    emit('update:items', newValue[0])
    emit('update:divider', newValue[1])
    emit('update:nestable', newValue[2])
  }
)
</script>
