import { usePagination } from '@/shared/composable'
import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAdvisorAssignment } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useAdvisorAssignment()
  pagination.$reset()
  store.$reset()

  await store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/advisor-assignment',
  children: [
    {
      path: '',
      name: 'AdvisorAssignment',
      component: () => import('./AdvisorAssignment.vue'),
      beforeEnter: [handleRoute]
    }
  ]
} as RouteRecordWithLayout
