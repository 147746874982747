import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.contacts',
    field: 'contact'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
