import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSkillsInterestsPutRequest } from '@shared/swagger'
import { putProfileSkillsInterests } from '@shared/api'

import type { StoreType } from '../types'

export default async function editInterest (this:StoreType, values: ProfileSkillsInterestsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSkillsInterests(values)

  return {
    status: ok,
    message: message
  }
}
