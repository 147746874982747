import { AcademicSingleCourseAttendancesResourcesResponse } from '@shared/swagger'
import { arrayGroupByProperty } from '@shared/utils'

import { divideBy } from '../values'
import { DateColspan, SessionsOptions } from '../types'

export default (resource: AcademicSingleCourseAttendancesResourcesResponse | null) => {
  const schedule = (resource?.schedule || []).map((item, index) => ({ ...item, session: index + 1 }))
  const dates: Array<DateColspan> = []
  const headers: Array<Array<DateColspan>> = []

  const options: Array<SessionsOptions> = []
  const grouped = arrayGroupByProperty(schedule, 'day')

  for (const [date, dateSessions] of Object.entries(grouped)) {
    dates.push({
      date,
      colspan: dateSessions.length,
      sessions: dateSessions
    })
  }

  for (let i = 0; i < dates.length; i += divideBy) {
    headers.push(dates.slice(i, i + divideBy))
  }

  headers.forEach((dates, index) => {
    options.push({
      value: index,
      label: `${dates[0].date} - ${dates[dates.length - 1].date}`
    })
  })

  return { options, headers }
}
