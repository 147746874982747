import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'auth',
  path: '/auth/sign-out',
  redirect: '/auth/sign-in',
  children: [
    {
      path: '',
      name: 'SignOutPage',
      component: () => import('./SignOutPage.vue')
    }
  ]
} as RouteRecordWithLayout
