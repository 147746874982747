<template>
  <vue-resizable
    class="resizable-widget card"
    :width="widgetSize.width"
    min-width="250"
    :height="widgetSize.height"
    min-height="150"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0">
      <span class="card-title fw-bold text-gray">
        {{ $t('domains.dashboard.widgets.search.search-title') }}
      </span>
    </div>
    <div class="card-body pt-0">
      <pms-search
        v-model:value="searchKeyword"
        :placeholder="$t('domains.dashboard.widgets.search.search-placeholder')"
        @handle-search="redirectOnSubmit"
      />
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import VueResizable from 'vue-resizable'
import { onResize, getSavedWidgetSize } from '../utils'

const router = useRouter()
const searchKeyword = ref<string>('')

const redirectOnSubmit = async () => {
  await router.push({ name: 'AllStudentsPage', query: { student_id: searchKeyword.value } })
}

const widgetId = 'student-search-widget'

const widgetSize = ref({ width: 'auto', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>

<style scoped>
.z-index-1{
  z-index: 1;
}

.borders-combined {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  min-height: 45px !important;
}
</style>
