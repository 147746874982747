<template>
  <pms-row>
    <pms-grid :col="12">
      <div class="d-flex justify-content-center">
        <img
          :src="qrDataUrl"
          class="w-350px"
        >
      </div>
      <attendance-information class="mb-4" />
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { useQr } from '../../../composable'
import AttendanceInformation from '../../AttendanceInformation.vue'

const { qrDataUrl } = useQr()
</script>
