import type { SelectField } from '@/shared/types'
import type { AcademicStudentsResourceResponse } from '@shared/swagger'

export default (data: AcademicStudentsResourceResponse['student_statuses']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((status) => {
    options.push({
      label: status.title,
      value: status.id
    })
  })
  return options
}
