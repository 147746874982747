<template>
  <Teleport to="#skill-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileSkillsInterestsGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'

import { useProfile } from '../../../store'

const { t } = useI18n()
const { isActive } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const store = useProfile()
const loader = isActive('profile/skills/interests-edit')

const interest = computed(() => store.getInterest)

const defaultTempValues: ProfileSkillsInterestsGetResponse = {
  interest: ''
}

const tempValues = ref<ProfileSkillsInterestsGetResponse>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.skills.interest.interest-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'interest',
    type: 'editor',
    value: tempValues.value.interest,
    required: true
  }
])

const closeModal = () => {
  close({ name: 'skill-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  const { status, message } = await store.editInterest({ ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'skill-modal' })
    unsetModifier()
    store.fetchInterest()
  } else {
    error({ text: message })
  }
}

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (interest.value) {
        tempValues.value = objectDeepCopy({ interest: interest.value } || defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
