import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { AcademicManageSyllabusAcademicSkillsPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putAcademicManageSyllabusAcademicSkill (id: number, body: AcademicManageSyllabusAcademicSkillsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusAcademicSkillsPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/academic-skills/${id}`,
    body,
    loader: 'academic/manage/syllabus/academic-skills'
  })
  return response
}
