<template>
  <pms-card :header="false">
    <router-link
      class="btn btn-primary fw-bold w-100 mb-8"
      to="/services/message-broadcast/new-message"
    >
      {{ $t('domains.service.message-service.new-message.title') }}
    </router-link>

    <div class="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary gap-2">
      <div
        v-for="(type,index) in types"
        :key="index"
        class="menu-item"
      >
        <span
          class="menu-link"
          :class="{'active': type === view }"
          @click="updateCurrentView(type)"
        >
          <span class="menu-icon">
            <pms-kt-icon
              v-if="type === 'inbox'"
              :icon-name="'directbox-default'"
              class="me-3 fs-2"
            />
            <pms-kt-icon
              v-if="type === 'sent'"
              :icon-name="'send'"
              class="me-3 fs-2"
            />
          </span>
          <span class="menu-title fw-bold fs-6">
            {{ $t(`domains.service.message-service.labels.${type}`) }}
          </span>
        </span>
      </div>
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useResource } from '@shared/stores'

import { useMessageBroadcast } from '../store'

const store = useMessageBroadcast()
const resource = useResource()

const view = computed(() => store.getCurrentMessageView)
const types = computed(() => resource.getServiceMessageBroadcastResource?.types)

const updateCurrentView = (view: string) => {
  store.updateCurrentMessageView(view)
}
</script>
