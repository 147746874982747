<template>
  <div
    v-if="user"
    class="d-flex flex-column text-center mb-5 px-9 mt-2"
  >
    <div class="symbol symbol-80px symbol-lg-100px mb-4">
      <img
        v-if="user?.photo"
        :src="user?.photo"
        style="object-fit: cover;"
        class="rounded-circle"
        :alt="user?.username"
      >
    </div>
    <div class="text-center">
      <span class="text-gray-800 fw-bold fs-2">
        {{ user?.name +' '+ user?.surname }}
      </span>
      <span class="text-muted d-block fw-semibold fs-5">
        {{ user?.email }}
      </span>
    </div>
  </div>
  <div class="m-0">
    <ul class="nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold">
      <ll
        v-for="(item,index) in menuItems"
        :key="index"
        class="nav-item w-100 mt-5"
      >
        <router-link
          :class="`nav-link text-muted text-active-primary w-auto ms-0 py-0 mx-0 px-9 border-0 ${routeName === item.name ? 'active': ''}`"
          :to="item.route"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="me-2">
              <span class="text-gray-800 text-hover-primary fs-4 fw-bold">
                {{ t(item.label) }}
              </span>
              <span class="text-muted fw-semibold d-block fs-6">
                {{ t(item.description) }}
              </span>
            </div>
            <span class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary min-w-40px h-40px mt-0">
              <span class="svg-icon svg-icon-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="currentColor"
                  />
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </span>
          </div>
          <span class="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
        </router-link>
      </ll>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useUser } from '@shared/stores'

type MenuItem = {
  name: string,
  label: string,
  description: string,
  route: string
}

const menuItems: Array<MenuItem> = [
  {
    name: 'OverviewPage',
    label: 'domains.profile.sidebar.overview',
    description: 'domains.profile.sidebar.overview-description',
    route: '/profile'
  },
  {
    name: 'AddressesPage',
    label: 'domains.profile.sidebar.addresses',
    description: 'domains.profile.sidebar.addresses-description',
    route: '/profile/addresses'
  },
  {
    name: 'AutobiographyPage',
    label: 'domains.profile.sidebar.biography',
    description: 'domains.profile.sidebar.biography-description',
    route: '/profile/biography'
  },
  {
    name: 'ContactsPage',
    label: 'domains.profile.sidebar.contacts',
    description: 'domains.profile.sidebar.contacts-description',
    route: '/profile/contacts'
  },
  {
    name: 'EducationPage',
    label: 'domains.profile.sidebar.education',
    description: 'domains.profile.sidebar.education-description',
    route: '/profile/education'
  },
  {
    name: 'PublicationsPage',
    label: 'domains.profile.sidebar.publications',
    description: 'domains.profile.sidebar.publications-description',
    route: '/profile/publications'
  },
  {
    name: 'ExperiencePage',
    label: 'domains.profile.sidebar.experience',
    description: 'domains.profile.sidebar.experience-description',
    route: '/profile/experience'
  },
  {
    name: 'SkillsPage',
    label: 'domains.profile.sidebar.skills',
    description: 'domains.profile.sidebar.skills-description',
    route: '/profile/skills'
  },
  {
    name: 'SettingsPage',
    label: 'domains.profile.sidebar.settings',
    description: 'domains.profile.sidebar.settings-description',
    route: '/profile/settings'
  }
]

const { t } = useI18n()
const store = useUser()
const user = computed(() => store.currentUser)

const route = useRoute()
const routeName = computed(() => route.name)
</script>
