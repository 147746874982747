
import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumLearningOutcomeRequest, AcademicManageCurriculumLearningOutcomeResponse } from '@shared/swagger'

export default async function fetchCurriculumOutcome (params: AcademicManageCurriculumLearningOutcomeRequest): Promise<AxiosResponse<AcademicManageCurriculumLearningOutcomeResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumLearningOutcomeResponse>({
    url: 'academic/manage/curriculum/learning-outcomes',
    body: {
      params
    },
    loader: 'api-fetchCurriculumOutcome'
  })

  return response
}
