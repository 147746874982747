import { useI18n } from 'vue-i18n'
import { camelCaseToLowerKebabCase } from '@/shared/utils'

import type { SelectField } from '@/shared/types'
import type { AcademicInformationResourcesResponse } from '@shared/swagger'

export default (data: AcademicInformationResourcesResponse['section_types']): SelectField['options'] => {
  const { t } = useI18n()
  const options: SelectField['options'] = []

  data?.forEach((type) => {
    options.push({
      label: t(`common.course-types.${camelCaseToLowerKebabCase(type)}`),
      value: type
    })
  })

  return options
}
