import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useAcademicSchedule } from './store'

const handleAuthRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useAcademicSchedule()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/academic/schedule',
  children: [
    {
      path: '',
      name: 'SchedulePage',
      component: () => import('./SchedulePage.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    }
  ]
} as RouteRecordWithLayout
