<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="back()"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <span class="text-dark fs-3 fw-bold m-0 p-0">
          {{ $t(currentProgram) }}
        </span>
        <span class="fs-6 m-0 p-0">
          {{ $t('domains.academic.manage.section.select-course-description') }}
        </span>
      </div>
    </div>

    <div class="d-flex flex-row flex-end">
      <select
        v-model="store.status"
        class="form-select form-select-solid form-select-lg w-150px"
      >
        <option
          :value="0"
        >
          {{ $t('common.all') }}
        </option>
        <option
          v-for="(item, index) in statuses"
          :key="index"
          :value="item.id"
        >
          {{ item.title }}
        </option>
      </select>
      <button
        class="btn btn-primary btn-lg ms-2"
        @click="next"
      >
        {{ $t('common.next') }}
      </button>
    </div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :full-width="true"
    />
    <pms-smart-table
      v-else
      :items="requests"
      :headers="tableHeader"
      :sortable="true"
      :class="'mt-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #course-code="{item}">
        {{ item.course.code }}
      </template>
      <template #courseCodeHeader="{ item }">
        <div class="d-flex gap-3 flex-wrap w-100px">
          {{ $t(item.title) }}
          <input
            v-model="courseCodeInput"
            name="MyCourcesFormCodeSearch"
            class="form-control"
            type="text"
          >
        </div>
      </template>
      <template #course-title="{item}">
        {{ item.course.title }}
      </template>
      <template #met="{item}">
        {{ item.met ?? 0 }}
      </template>
      <template #status="{item}">
        {{ item.status.title }}
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="openModal(item)"
            >
              {{ $t('common.table.information') }}
            </a>
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="deleteAction(item.id,item.course.code + ' ' + item.course.title)"
            >
              {{ $t('common.table.delete') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
      <template #create_date="{item}">
        {{ dateFormatter(item.create_date) }}
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useLoader, useTeleportModal, useAlert } from '@/shared/composable'
import { useResource } from '@shared/stores'

import { useSentRequests } from '../store'

import { tableHeader, sentRequest } from '../values'
import { SectionManagementRequestGetResponse } from '@/shared/swagger'
import { debounce } from '@/shared/utils/optimize'
import { storeToRefs } from 'pinia'
import { useSectionManagement } from '@/domains/SectionManagement/store'
import { dateFormatter } from '@/shared/utils'

const { t } = useI18n()
const { isActive } = useLoader()
const { setModifier, open } = useTeleportModal()
const { dialog, error, success } = useAlert()

const resource = useResource()
const sectionManagement = useSectionManagement()
const store = useSentRequests()
const { currentProgram } = storeToRefs(store)
const { currentYearTerm } = storeToRefs(sectionManagement)

const loader = isActive('academic/manage/sections/requests')

const courseCodeInput = ref('')
const filteredRequests = ref<SectionManagementRequestGetResponse | undefined>([])
const requests = computed(() => {
  if (courseCodeInput.value && (filteredRequests.value && filteredRequests.value.length > 0)) {
    return filteredRequests.value
  }
  return store.getSentRequests
})
const statuses = computed(() => resource.getSectionManagementResourceRequestStatuses)

watch(() => courseCodeInput.value, debounce(filterRequests))

function filterRequests () {
  filteredRequests.value = store.getSentRequests.filter((req) => req.course.code.includes(courseCodeInput.value))
}
const back = () => {
  store.step = 'program'
}

const next = async () => {
  await store.fetchSentRequests({ ...sentRequest.value, status: store.status === 0 ? undefined : store.status })
}

const openModal = async (item: SectionManagementRequestGetResponse[0]) => {
  await store.fetchSentRequestQuotas(item.id)
  store.request_id = item.id
  setModifier('sent-requests',
    {
      id: item.id
    },
    {
      title: item.course.title + ` (${item.course.code})`,
      description: 'sent-requests-modal-description'
    }
  )
  open({ name: 'sent-requests-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.academic.manage.sections.sent-requests.are-you-sure',
    text: t('domains.academic.manage.sections.sent-requests.delete-text', { name: title }),
    onConfirm: async () => {
      const { status, message } = await store.deleteSentRequest(id)
      status
        ? success({
          text: message
        })
        : error({
          text: message
        })
    }
  })
}
watch(() => currentYearTerm.value, async () => {
  await store.fetchSentRequests({ ...sentRequest.value, year: currentYearTerm.value.year, term: currentYearTerm.value.term })
},
{
  immediate: true,
  deep: true
})
onBeforeUnmount(() => {
  store.setSentRequests([])
  store.status = 0
})
</script>
