<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
      :filter="true"
    />
    <pms-row v-else>
      <contacts-list />
    </pms-row>
    <pms-teleport-modal
      :name="'contacts-modal'"
      :teleport-name="'contacts-modal-content'"
      :title="$t(`domains.profile.contacts.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`domains.profile.contacts.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useDriver, useLoader, useTeleportModal } from '@shared/composable'

import ContactsList from './ui/ContactsList.vue'
import { quickTourSteps } from './values'

const { isActive } = useLoader()
const { modifier } = useTeleportModal()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('profile/contacts')

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
