import type { StoreType } from '../types'
import { useResource } from '@shared/stores'
import { usePagination } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const { perPage } = usePagination()
  const resource = useResource()

  await Promise.all([
    this.fetchKnowledgeManageCategories({
      per_page: perPage.value,
      offset: 1
    }),
    resource.fetchKnowledgeManageResource({ modules: ['types', 'tags', 'languages', 'categories'] })
  ])
}
