import { AddedGrades, BellCurvedGrades } from '../types'

interface Student {
    student_id: string;
    currentGrade: number;
    oldGrade: number;
    absent?: boolean;
  }

function calculateSampleMean (grades: number[]): number {
  const sum = grades.reduce((acc, grade) => (!isNaN(grade) && grade > 10 ? acc + grade : acc), 0)
  return sum / grades.filter(grade => !isNaN(grade) && grade > 10).length
}

function calculateSampleStdDeviation (grades: number[], sampleMean: number): number {
  const squaredDifferencesSum = grades.reduce((acc, grade) => {
    if (!isNaN(grade) && grade > 10) {
      const difference = grade - sampleMean
      return acc + (difference * difference)
    }
    return acc
  }, 0)

  return Math.sqrt(squaredDifferencesSum / (grades.filter(grade => !isNaN(grade) && grade > 10).length - 1))
}

export default function calculateBellCurve (students: AddedGrades, mean: string, stdDeviation: string): BellCurvedGrades {
  const grades: number[] = students.grades.map(student => student.grade)

  const sampleMean = calculateSampleMean(grades)
  const sampleStdDeviation = calculateSampleStdDeviation(grades, sampleMean)

  const zScoredStudents: Student[] = students.grades.map(student => {
    const grade = student.grade

    if (!isNaN(grade) && grade > 10) {
      // Calculate the Z-score using the provided formula
      const zScore = Math.round(((grade - sampleMean) * parseFloat(stdDeviation)) / sampleStdDeviation + parseFloat(mean))

      // Create a new Student object with the original id and the calculated Z-score
      return !isNaN(zScore) ? getStudentType(student, zScore) : getStudentType(student)
    }
    return getStudentType(student)
  })

  return {
    assessment_id: students.assessment_id,
    grades: zScoredStudents
  }
}

const getStudentType = (student: AddedGrades['grades'][0], currentGrade?: number): Student => ({
  student_id: student.student_id,
  absent: student.absent,
  currentGrade: currentGrade || student.grade,
  oldGrade: student.grade
})
