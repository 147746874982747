import { ref } from 'vue'
import { useResource } from '@shared/stores'

import { useSingleSection } from '../../../store'
import { attendanceToQrStudents, attendanceToQrSessionDetails } from '../utils'
import { Session, QrStudent } from '../types'
import useSocket from '@/shared/composable/socket'

import qrCode from 'qrcode'

const search = ref('')
const filter = ref('all')
const qrDataUrl = ref('')
const students = ref<Array<QrStudent>>([])
const session = ref<null | undefined | Omit<Session, 'session'>>(null)

export const useQr = () => {
  const store = useSingleSection()
  const resourceStore = useResource()
  const socket = useSocket()

  const generateQrImage = async () => {
    const canvasElement = document.createElement('canvas')
    qrCode.toCanvas(canvasElement, JSON.stringify({}))

    qrDataUrl.value = canvasElement.toDataURL('image/png')
  }

  const getQrStudents = async (scheduleId) => {
    students.value = attendanceToQrStudents(
      resourceStore.getAcademicSingleCourseAttendanceResource,
      store.getAttendance,
      scheduleId
    )
  }

  const getQrSessionDetails = async (sessionId) => {
    session.value = attendanceToQrSessionDetails(
      resourceStore.getAcademicSingleCourseAttendanceResource,
      sessionId
    )
  }

  const mount = async () => {
    const sessionId = null

    await Promise.all([
      getQrStudents(sessionId),
      getQrSessionDetails(sessionId),
      generateQrImage()
    ])
  }

  const unMount = () => {
    socket.off()
    qrDataUrl.value = ''
    students.value = []
    search.value = ''
    filter.value = 'all'
  }

  return {
    qrDataUrl,
    students,
    search,
    filter,
    session,
    mount,
    unMount
  }
}
