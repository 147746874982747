import type { StoreType } from '../types'
import type { AcademicManageCurriculumViewRequest } from '@/shared/swagger'
import type { QueryParams } from '../../feature/CurriculaViewPage/types'

import { getAcademicManageCurriculumView } from '@shared/api'

export default async function fetchCurricula (this: StoreType, params: Pick<QueryParams, 'track'>): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumView({ track_id: params.track } as AcademicManageCurriculumViewRequest)

  if (ok) {
    this.setAcademicCurriculumView(data)
  }
}
