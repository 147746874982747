import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { addRowspan } from '../utils'
import { TableDataWithRowspan } from '../types'
import { AcademicSingleCourseAttendancesByCoursePutRequest } from '@/shared/swagger'
import { useAlert } from '@/shared/composable'
import { search_values } from '@/domains/AcademicSingleCourse/values'
import { RouteParams } from 'vue-router'
import { router } from '@/router'

export const table_data = ref({} as TableDataWithRowspan)
export const attendances = ref([] as Array<AcademicSingleCourseAttendancesByCoursePutRequest>)
export const header_attend = ref({
  attend: undefined
} as TableDataWithRowspan[0])

export function useAttendanceTable () {
  const store = useSingleCourse()
  const { attendance_students } = storeToRefs(useSingleCourse())

  const { success, error } = useAlert()

  async function onConfirm ({ status, message }) {
    status
      ? success({ text: message, isToast: true })
      : error({ text: message })

    if (status) {
      const params = router.currentRoute.value.query as unknown as RouteParams

      await store.fetchAttendanceStudents({
        ...search_values.value,
        year: +params.year,
        term: +params.term
      })
    }
    cancelAll()
  }

  function setTableData () {
    table_data.value = addRowspan(attendance_students.value)
  }

  function setSingleAttendance (student:TableDataWithRowspan[0], attend_id:number) {
    const attendance = attendances.value.find(item => item.schedule_id === student.course_schedule_id && item.student_id === student.student_id + '')
    if (attendance) attendance.attend_id = attend_id
  }
  function setAllAttendances (attend_id:number) {
    header_attend.value.attend = attend_id
    attendances.value.forEach(attendance => {
      attendance.attend_id = attend_id
    })
  }

  async function saveSingleAttendance (student:TableDataWithRowspan[0]) {
    const request:AcademicSingleCourseAttendancesByCoursePutRequest = attendances.value.find(attendance => attendance.student_id === student.student_id + '' && attendance.schedule_id === student.course_schedule_id) as AcademicSingleCourseAttendancesByCoursePutRequest
    onConfirm(await store.putAttendance([request]))
  }
  async function saveAll () {
    onConfirm(await store.putAttendance(attendances.value))
  }

  async function resetSingleAttendance (student:TableDataWithRowspan[0]) {
    const request:AcademicSingleCourseAttendancesByCoursePutRequest = attendances.value.find(attendance => attendance.student_id === student.student_id + '' && attendance.schedule_id === student.course_schedule_id) as AcademicSingleCourseAttendancesByCoursePutRequest
    request.attend_id = -1
    onConfirm(await store.putAttendance([request]))
  }
  async function resetAll () {
    const request = JSON.parse(JSON.stringify(attendances.value)) as AcademicSingleCourseAttendancesByCoursePutRequest[]
    request.forEach(item => {
      item.attend_id = -1
    })
    onConfirm(await store.putAttendance(request))
  }

  function startEditingSingleAttendance (student:TableDataWithRowspan[0]) {
    attendances.value.push({
      schedule_id: student.course_schedule_id,
      student_id: student.student_id + '',
      attend_id: 1
    })
  }
  function startEditingAll () {
    // for time complexity to be O(n)
    const attendance_dict = Object.assign({}, ...attendances.value.map(attendance => ({ [attendance.schedule_id + '_' + attendance.student_id]: true })))

    table_data.value.forEach(student => {
      if (!student.event_lock && !attendance_dict[student.course_schedule_id + '_' + student.student_id] && student.date_available) {
        attendances.value.push({
          schedule_id: student.course_schedule_id,
          student_id: student.student_id + '',
          attend_id: 1
        })
      }
    })
  }

  function cancelSingleAttendance (student:TableDataWithRowspan[0]) {
    attendances.value = attendances.value.filter(attendance => !(attendance.student_id === student.student_id + '' && attendance.schedule_id === student.course_schedule_id))
  }
  function cancelAll () {
    attendances.value = []
    header_attend.value.attend = undefined
  }

  function openQR () {
    const values = search_values.value
    const params = router.currentRoute.value.query as unknown as RouteParams

    router.push({
      name: 'SingleCourseAttendanceQRPage',
      query: {
        year: values.year ?? params.year,
        term: values.term ?? params.term,
        code: values.course_code ?? params.code
      }
    })
  }

  return {
    setTableData,

    setSingleAttendance,
    setAllAttendances,

    startEditingSingleAttendance,
    startEditingAll,

    saveSingleAttendance,
    saveAll,

    resetSingleAttendance,
    resetAll,

    cancelSingleAttendance,
    cancelAll,

    openQR
  }
}
