import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'

export default async function makeProgramAsMain (section: number, program: number): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownResponse, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}/toggle`,
    body: { },
    loader: 'put-academic/manage/schedules/sections/programs/toggle'
  })

  return response
}
