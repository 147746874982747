import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import getters from './getters'
import { AcademicManageCoursesRequisitesGetResponse, AcademicCoursesRequest, AcademicCoursesResponse, AcademicManageCoursesSearchResponse, AcademicManageCoursesRequisitesItemsGetResponse } from '@/shared/swagger'
import { ManageCourseRequestType } from '../types'

export const useManageCourses = defineStore<Name, State, Getters, Actions>('Academic.useManageCourses', {
  actions: {
    ...actions
  },

  getters: getters,

  state ():State {
    return {
      page_params: {},

      fetch_course_info_response: {} as AcademicManageCoursesSearchResponse[0],
      course_search_request: {
        offset: 1,
        per_page: 20
      },
      course_search_response: null,
      manage_course_request: {
        period_code: 'S'
      } as ManageCourseRequestType,

      description_editable: false,

      requisite_info_response: {} as AcademicManageCoursesRequisitesGetResponse,
      requisite_courses_search_request: {} as AcademicCoursesRequest,
      requisite_courses_search_response: {} as AcademicCoursesResponse['courses'],

      requisite_items_response: {} as AcademicManageCoursesRequisitesItemsGetResponse
    }
  }
})
