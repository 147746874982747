import { useRequests } from '@/shared/composable'

import type { UnknownRequest, ResourceRequest, AcademicManageCoursesResourceResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: ResourceRequest): Promise<AxiosResponse<AcademicManageCoursesResourceResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCoursesResourceResponse>({
    url: 'academic/manage/courses/resources',
    body: { params },
    loader: 'academic/manage/courses/resources'
  })

  return response
}
