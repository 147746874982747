import { SmartTableHeader } from '@shared/types'

export const rulesAndRegulationsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.category',
    field: 'categoryName',
    slotCellName: 'categoryName'
  },
  {
    title: 'common.table.languages',
    field: 'attachments',
    cellClass: 'text-center',
    slotCellName: 'attachments'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action'
  }
]
