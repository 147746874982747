import { createPage } from '@shared-utils/storybook'
import { usePagination } from '@shared/composable'

import StaffListPage from '../StaffListPage.vue'
import { useStaffList } from './../store'

const Template = createPage({
  layout: 'default',
  component: StaffListPage,
  setup () {
    const store = useStaffList()
    const pagination = usePagination()

    store.$reset()
    pagination.$reset()
    store.bootstrap('all')
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Administrative/Staff List',
  component: StaffListPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
