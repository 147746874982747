import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSectionProgramGetTeachingHoursResponse,
  ScheduleManagementSectionProgramGetTeachingHoursRequest
} from '@shared/swagger'

export default async function getTeachingHours (params: ScheduleManagementSectionProgramGetTeachingHoursRequest): Promise<AxiosResponse<ScheduleManagementSectionProgramGetTeachingHoursResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSectionProgramGetTeachingHoursResponse>({
    url: `academic/manage/schedules/sections/${params.section}/programs/${params.program}/teaching-hours`,
    body: { },
    loader: 'get-academic/manage/schedules/sections/programs/teaching-hours'
  })

  return response
}
