import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, ReleaseChangelogResponse } from '@shared/swagger'

export default async function getReleaseChangelog (): Promise<AxiosResponse<ReleaseChangelogResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ReleaseChangelogResponse>({
    url: 'release/changelog',
    body: {},
    configs: { token: false },
    loader: 'release/changelog'
  })

  return response
}
