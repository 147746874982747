import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useToken } from '@shared/composable'

import { useSectionManagement } from './store'
import { RouteParams } from './types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSectionManagement()
  const params = to.query as unknown as RouteParams

  if (!(params.year && params.term)) {
    params.year = useToken().getYear()
    params.term = useToken().getTerm()
    next({ ...to, query: params })
  }
  store.$reset()
  store.bootstrap(params)
  next()
}

export default {
  layout: 'default',
  path: '/academic/manage/section-management',
  children: [
    {
      path: '',
      name: 'SectionManagementPage',
      component: () => import('./SectionManagementPage.vue'),
      beforeEnter: [
        handleRoute
      ],
      children: [
        {
          path: '',
          name: 'NewRequestPage',
          component: () => import('./features/NewRequest/NewRequestPage.vue'),
          meta: {
            page: 'new-request'
          }
        },
        {
          path: 'sent-requests',
          name: 'SentRequestsPage',
          component: () => import('./features/SentRequests/SentRequestsPage.vue'),
          meta: {
            page: 'sent-requests'
          }
        },
        {
          path: 'received-requests',
          name: 'ReceivedRequestsPage',
          component: () => import('./features/ReceivedRequests/ReceivedRequestsPage.vue'),
          meta: {
            page: 'received-requests'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
