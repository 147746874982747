import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { ScheduleManagementProgramApplyPostRequest, UnknownResponse } from '@shared/swagger'

export default async function applyProgram (section: number, program: number, data: ScheduleManagementProgramApplyPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}/apply`,
    body: data,
    loader: 'post-academic/manage/schedules/sections/programs/apply'
  })

  return response
}
