import { SectionManagementRequestsQuotasGetResponse } from '@shared/swagger'

export function getTheorySectionFromRequestData (data: SectionManagementRequestsQuotasGetResponse[0]['sections']) {
  const template = { title: '', instructor: '' }
  const theory = data.find((section) => section.type.id === 'N')
  if (theory && theory.title && theory.instructor?.full_name) {
    template.title = theory.title
    template.instructor = theory.instructor?.full_name
  }
  return template
}

export function getPracticeSectionFromRequestData (data: SectionManagementRequestsQuotasGetResponse[0]['sections']) {
  const template = { title: '', instructor: '' }
  const practice = data.find((section) => section.type.id === 'P')
  if (practice && practice.title && practice.instructor?.full_name) {
    template.title = practice.title
    template.instructor = practice.instructor?.full_name
  }
  return template
}

export function getLaboratorySectionFromRequestData (data: SectionManagementRequestsQuotasGetResponse[0]['sections']) {
  const template = { title: '', instructor: '' }
  const lab = data.find((section) => section.type.id === 'L')
  if (lab && lab.title && lab.instructor?.full_name) {
    template.title = lab.title
    template.instructor = lab.instructor?.full_name
  }
  return template
}
