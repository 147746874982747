import type { StoreType } from '../types'

import { getAcademicStudentsMyResources } from '@shared/api'

export default async function fetchAcademicStudentsMyResourses (this: StoreType): Promise<void> {
  const { ok, data } = await getAcademicStudentsMyResources()

  if (ok) {
    this.setAcademicStudentsMyResources(data)
  }
}
