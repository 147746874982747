import type { StoreType } from '../types'
import type { KnowledgeManageResourceRequest } from '@shared/swagger'
import { getKnowledgeManageResource } from '@shared/api'

export default async function fetchKnowledgeManageResource (this: StoreType, body: KnowledgeManageResourceRequest): Promise<void> {
  const { ok, data } = await getKnowledgeManageResource(body)

  if (ok) {
    this.setKnowledgeManageResource(data)
  }
}
