import { SelectField } from '@/shared/types'
import { AdministrativeResourceResponse } from '@shared/swagger'

export default (data: AdministrativeResourceResponse['positions']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((position) => {
    options.push({
      label: position.title,
      value: position.id
    })
  })

  return options
}
