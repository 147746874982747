import { useAlert, useToken } from '@shared/composable'
import { useStudentAuth } from '@domains/StudentSignIn/store'
import { router } from '@/router'

export default async ({ values }) => {
  const { success, error } = useAlert()
  const { setStudentTokenDetails } = useToken()
  const store = useStudentAuth()

  const { ok, data, message } = await store.authenticate(values)
  ok
    ? success({ text: message, isToast: true })
    : error({ text: message })

  if (ok) {
    setStudentTokenDetails({ ...data })
    router.push({ name: 'StudentDashboardPage' })
  }
}
