import { ref } from 'vue'
import { driver as driverSetup, Driver, Config, DriveStep } from 'driver.js'
import { useLocale } from '@shared/composable'

const refDriver = ref<Driver | null>(null)
const refSteps = ref<Record<string, Array<DriveStep>>>({})
const refLocale = ref<string>('en-EN')
const refOptions = ref<Config | null>({
  showProgress: true,
  showButtons: ['next', 'previous']
})

const messages = {
  'en-EN': {
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
    doneBtnText: 'Done',
    progressText: '{{current}} of {{total}}'
  },
  'kz-KZ': {
    nextBtnText: 'Келесі',
    prevBtnText: 'Алдыңғы',
    doneBtnText: 'Аяқта',
    progressText: '{{current}} / {{total}}'
  },
  'ru-RU': {
    nextBtnText: 'Далее',
    prevBtnText: 'Назад',
    doneBtnText: 'Готово',
    progressText: '{{current}} из {{total}}'
  }
}

const reset = () => {
  refDriver.value = null
}

const setup = () => {
  refDriver.value = driverSetup({
    ...refOptions.value,
    ...messages[refLocale.value],
    steps: refSteps.value[refLocale.value] || []
  })
}

const setLocale = (locale: string) => {
  refLocale.value = locale
}

const useDriver = () => {
  const { locale } = useLocale()

  reset()
  setLocale(locale.value)

  return {
    driver: refDriver,
    setSteps: (steps: Record<string, Array<DriveStep>>) => {
      refSteps.value = steps
    },
    setOptions: (options?: Config) => {
      if (options) refOptions.value = options
    },
    drive: (stepIndex?: number) => {
      if (locale.value !== refLocale.value) setLocale(locale.value)
      setup()

      refDriver.value?.drive(stepIndex)
    },
    reset
  }
}

export default useDriver
