import type { StoreType } from '../types'
import { getAcademicManageSyllabusResource } from '@shared/api'

export default async function fetchAcademicManageSyllabusResource (this: StoreType, language?: string): Promise<void> {
  const { ok, data } = await getAcademicManageSyllabusResource(language)

  if (ok) {
    this.setAcademicManageSyllabusResource(data)
  }
}
