import type { StoreType } from '../types'
import type { AcademicCoursesRequest, AcademicManageCoursesDeleteRequest, AcademicManageCoursesPostRequest, AcademicManageCoursesPutRequest, AcademicManageCoursesSearchRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { destroyAcademicManageCourse, getAcademicCourses, getAcademicManageCourseSearch, postAcademicManageCourse, putAcademicManageCourse } from '@shared/api'
import { usePagination } from '@/shared/composable'

import courseSearchResponseToSingleCourse from '../../utils/courseSearchResponseToSingleCourse'

export async function fetch_course_info (this: StoreType, values: AcademicManageCoursesSearchRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getAcademicManageCourseSearch(values)

  if (ok) {
    this.setCourseSearchinfoResponse(data[0])
    this.setCourseManageRequest(courseSearchResponseToSingleCourse(data[0]))
  }

  return {
    status: ok,
    message
  }
}

export async function search_course (this: StoreType, values: AcademicCoursesRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAcademicCourses({ ...values, per_page: perPage, offset: currentPage })

    if (ok) {
      this.setCourseSearchResponse(data.courses || [])
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    }
  })

  const { ok, message, data } = await getAcademicCourses(values)

  if (ok) {
    this.setCourseSearchResponse(data.courses || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  return {
    status: ok,
    message
  }
}

export async function create_course (this: StoreType, values: AcademicManageCoursesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postAcademicManageCourse(values)
  this.setDescriptionEditable(true)
  document.getElementById('requisites')?.scrollIntoView({ behavior: 'smooth' })

  return {
    status: ok,
    message
  }
}

export async function edit_course (this: StoreType, values: AcademicManageCoursesPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicManageCourse(values)

  return {
    status: ok,
    message
  }
}

export async function delete_course (this: StoreType, values: AcademicManageCoursesDeleteRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyAcademicManageCourse(values)

  return {
    status: ok,
    message
  }
}
