import { dateFormatter } from '@shared/utils'
import { dateOptions } from '@domains/Dashboard/values'
import { DashboardWidgetsResponse } from '@shared/swagger'
import moment from 'moment'

export const getDateMMDD = (date: string) => {
  return date.slice(5, 10)
}

export const getBirthday = (date: string) => {
  return capitalizeFLetter(dateFormatter(getDateMMDD(date), dateOptions))
}

export const getFirstNEvents = (events: DashboardWidgetsResponse['greetings']['data'], n: number) => events.slice(0, n)

export const isToday = (date: string): boolean => {
  const inputDate = moment(date, 'MM-DD')
  return inputDate.isSame(moment(), 'day')
}

export const capitalizeFLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1)
}
