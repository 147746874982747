import { SelectField } from '@shared/types'
import { ServiceEsignResourceGetResponse } from '@shared/swagger'

import { programsMapped } from '../types'

export function programsToProgramOptions (programs?: ServiceEsignResourceGetResponse['programs'], departments?: ServiceEsignResourceGetResponse['departments']): SelectField['options'] {
  const data = departments?.map((department) => {
    return {
      title: department.title,
      value: department.id,
      code: department.code,
      selectable: false,
      programs: programs?.filter((program) => program.department_id === department.id)
        .map((program) => programMapperFunction(program))
    }
  })
  const options: SelectField['options'] = []
  const filterData = data?.filter((el) => el.programs && el.programs.length > 0)

  filterData?.forEach((el) => {
    const newObj:SelectField['options'][0] = { label: el.title, value: el.title, group: [] }
    if (el.programs && el.programs.length > 0) {
      newObj.group = el.programs.map((spec) => ({
        label: spec.title,
        value: spec.value
      }))
    }
    options.push(newObj)
  })
  return options
}

function programMapperFunction (program: ServiceEsignResourceGetResponse['programs'][0]): programsMapped {
  return {
    title: `${program.program_code} - ${program.language} : ${program.speciality_code} ${program.title} [${program.terms}]`,
    value: program.program_code,
    code: program.program_code,
    selectable: true
  }
}
