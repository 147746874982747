import { useRequests } from '@/shared/composable'

import type {
  AcademicSingleCourseIntegrationWithMoodleRequest,
  UnknownRequest,
  UnknownResponse
} from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicSectionIntegrationWithMoodle (params: AcademicSingleCourseIntegrationWithMoodleRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, UnknownResponse>({
    url: 'integration/moodle/grades',
    body: { params },
    loader: 'integration/moodle/grades'
  })

  return response
}
