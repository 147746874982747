<template>
  <Teleport to="#language-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
    <div
      v-if="selectedDatasForModal && selectedDatasForModal.value?.length > 0"
      class="mt-4"
    >
      <pms-loader v-if="loader" />
      <pms-smart-table
        v-else
        id="selected-students-table"
        :items="selectedDatasForModal.value"
        :headers="selectedDataTable"
        :sortable="true"
        :options="{
          wrapper: {
            responsive: true
          }
        }"
      >
        <template #id-number="{ item }">
          {{ item.id }}
        </template>
        <template #full_name="{ item }">
          {{ item.full_name }}
        </template>
      </pms-smart-table>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { levelToOption, completedToOptions, applicantsSearch, studentsSearch } from '../utils'
import type { FormField } from '@shared/types'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import { useAllStudents } from '../store'
import { selectedStudents, selectedDatasForModal, student_language_code, applicant_language_code, selectedDataTable, selectedApplicants } from './../values/'

const { t } = useI18n()
const store = useAllStudents()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const resource = useResource()
const loader = isActiveOneOf([])
const completedValues = [{ title: 'Completed', isTrue: 1 }, { title: 'Not completed', isTrue: 0 }]
const language_levels = computed(() => resource.getServiceManageLanguagesResourcesLanguageLevels)

const defaultTempValues = {
  applicants: [] as string[],
  students: [] as string[],
  language: '',
  level_id: 0,
  completed: true
}

const tempValues = (defaultTempValues)
const formFields = computed(():Array<FormField> => [
  {
    label: {
      text: `${t('common.search-field.level')}:`,
      class: 'mb-1 mt-2'
    },
    name: 'level_id',
    type: 'select',
    value: tempValues.level_id || null,
    options: levelToOption(language_levels.value)
  },
  {
    label: {
      text: `${t('common.search-field.completed')}:`,
      class: 'mb-1 mt-2'
    },
    name: 'completed',
    type: 'select',
    value: tempValues.level_id || null,
    options: completedToOptions(completedValues)
  }
])

const onSubmit = async ({ values }) => {
  let ids

  modifier.value?.data?.student || modifier.value?.data?.students
    ? values.language = student_language_code[0]
    : values.language = applicant_language_code[0]

  modifier.value?.data?.students && (ids = modifier.value?.data?.students.map(student => student.id))
  modifier.value?.data?.applicants && (ids = modifier.value?.data?.applicants.map(applicant => applicant.id.toString()))

  const { status, message } = modifier.value?.data?.student
    ? await store.putSingleStudent(modifier.value.data.student, { ...values })
    : modifier.value?.data?.students
      ? await store.putStudents({ ...values, students: ids })
      : modifier.value?.data?.applicants
        ? await store.putApplicants({ ...values, applicants: ids })
        : await store.putSingleApplicant(modifier.value.data.applicant, { ...values })
  handleResult(status, message)

  function handleResult (status, message) {
    if (status && (modifier.value?.data?.student || modifier.value?.data?.students)) {
      success({ text: message, isToast: true })
      close({ name: 'language-modal' })
      selectedStudents.value = []
      unsetModifier()
      selectedDatasForModal.value = []
      studentsSearch()
    } else if (status && (modifier.value?.data?.applicant || modifier.value?.data?.applicants)) {
      success({ text: message, isToast: true })
      close({ name: 'language-modal' })
      selectedApplicants.value = []
      selectedDatasForModal.value = []
      unsetModifier()
      applicantsSearch()
    } else {
      error({ text: message })
    }
  }
}

const closeModal = () => {
  close({ name: 'language-modal' })
  unsetModifier()
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      show()
    }
  }, { immediate: true }
)
</script>
