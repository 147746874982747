import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { putRulesAndRegulationsAttachment } from '@/shared/api'

export default async function editRuleAttachment (this: StoreType, ruleId: string, attachmentId: string, values: FormData): Promise<StatusMessageResponse> {
  const { ok, message } = await putRulesAndRegulationsAttachment(ruleId, attachmentId, values)

  return {
    status: ok,
    message: message
  }
}
