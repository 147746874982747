export const getSavedWidgetSize = (widgetId) => {
  const savedData = localStorage.getItem(widgetId)
  return savedData ? JSON.parse(savedData) : null
}

export const onResize = (event, widgetId) => {
  const { width, height } = event
  saveResizedWidget(widgetId, width, height)
}

export const saveResizedWidget = (widgetId, width, height) => {
  const resizedWidgetData = {
    id: widgetId,
    width,
    height
  }
  localStorage.setItem(widgetId, JSON.stringify(resizedWidgetData))
}
