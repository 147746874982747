import { createPage } from '@shared-utils/storybook'
import { usePagination } from '@shared/composable'

import ConsentRequestPage from '../../ConsentRequest/ConsentRequestPage.vue'
import { useSingleSection } from '../../../store'

const Template = createPage({
  layout: 'default',
  component: ConsentRequestPage,
  setup () {
    const store = useSingleSection()
    const pagination = usePagination()

    store.$reset()
    pagination.$reset()
    store.bootstrap('consent-requests', { code: 'CSS 358', year: '2022', term: '2', section: '01' })
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Academic/Pages/Single Course/Consent Request',
  component: ConsentRequestPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
