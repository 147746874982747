import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendanceRelocatesGetRequest,
  AcademicSingleCourseAttendanceRelocatesGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicSingleCourseAttendanceRelocatesGetRequest): Promise<AxiosResponse<AcademicSingleCourseAttendanceRelocatesGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendanceRelocatesGetResponse>({
    url: 'academic/courses/attendances/relocates',
    body: { params },
    loader: 'academic/courses/attendances/relocates'
  })

  return response
}
