import { AcademicCoursesStudentsResponse } from '@/shared/swagger'

export const generateHtml = (data: AcademicCoursesStudentsResponse) => {
  let tableRows = ''
  const headers = [
    'Student',
    'Specialty',
    'Sections'
  ]

  data.forEach((row) => {
    tableRows += `
    <tr>
        <td>${row.name} ${row.surname}</td>
        <td>
    `
    row.enrolled_sections.forEach((section) => {
      tableRows += `
                    ${section.section}
        `
    })
    tableRows +=
    `
        </td>
    </tr>
    `
  })

  return `
  <head>
    <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <style>
    .table td, .table th {
        border: 1px solid #000 !important;
    }
    </style>
  </head>
  <body>
    <div class="container">
        <div class="row">
        <div class="col-12">
            <table class="table">
            <thead>
                <tr>
                ${headers.map((header) => `<th>${header}</th>`).join('')}
                </tr>
            </thead>
            <tbody>
                ${tableRows}
            </tbody>
            </table>
        </div>
        </div>
    </div>
  </body>
`
}

export const printSingleCourseStudentsList = (data: AcademicCoursesStudentsResponse): void => {
  const printWindow: Window | null = window.open('', '', 'width=1200,height=600')

  if (printWindow) {
    printWindow.document.write(generateHtml(data))
    printWindow.document.close()
    printWindow.focus()
    setTimeout(() => printWindow.print(), 100)
  }
}
