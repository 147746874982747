<template>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div class="text-dark fw-bold min-w-75px">
      {{ $t('domains.service.message-service.new-message.courses.title') }}:
    </div>
    <div
      v-if="selectedCourses.length!=0"
      class="d-flex align-items-center pe-2"
    >
      <div class="fs-5">
        <div class="row">
          <div
            class="col-12"
          >
            <div class="fs-5 d-flex flex-wrap">
              <Chip
                v-for="(course) in selectedCourses"
                :key="course.course_id"
                v-styler="{ borderRadius: '50px', paddingLeft: '14px', paddingRight: '10px', marginLeft: '7px', marginBottom: '7px' }"
                class="border border-dashed border-gray-500 fw-bold bg-white"
                :label="`${course.title} [${course.type}-${course.section}]`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div class="text-dark fw-bold min-w-75px">
      {{ $t('domains.service.message-service.new-message.course.title') }}:
    </div>
    <div
      v-if="students_list.length==0"
      class="d-flex "
    >
      <span
        class="text-muted ms-2"
      >
        {{ $t('domains.service.message-service.new-message.course.placeholder') }}
      </span>
      <span
        class="badge badge-md badge-primary ms-5 cursor-pointer"
        data-bs-toggle="modal"
        data-bs-target="#coursesModal"
      >
        {{ $t('common.select') }}
      </span>
    </div>
    <div
      v-else
      class="d-flex align-items-center pe-2"
    >
      <div class="fs-5">
        <div class="row">
          <div class="col-12 m-4">
            <span
              class="badge badge-md badge-primary ms-2 cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#coursesModal"
            >
              {{ $t('common.change') }}
            </span>
            <span
              class="badge badge-md badge-danger ms-2 cursor-pointer"
              @click="resetStudents()"
            >
              {{ $t('common.reset') }}
            </span>
          </div>
          <div
            class="col-12 mb-4"
          >
            <div class="fs-5 d-flex flex-wrap">
              <Chip
                v-for="(student,index) in students_list"
                :key="student.id"
                v-styler="{ borderRadius: '50px', paddingLeft: '14px', paddingRight: '10px', marginLeft: '7px', marginBottom: '7px' }"
                class="border border-dashed border-gray-500 fw-bold bg-white"
                :label="`${index+1}. ${student.full_name}`"
                removable
                @remove="removeSelectedStudent(student.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CoursesModal />
</template>
<script setup lang="ts">
import { useCoursesField } from '../composables'
import CoursesModal from './CoursesModal.vue'
import Chip from 'primevue/chip'

const { students_list, selectedCourses, resetStudents, removeSelectedStudent } = useCoursesField()

</script>
