import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { ResourceRequest, AdministrativeResourceResponse, UnknownRequest } from '@shared/swagger'

export default async function getAdministrativeResource (params: ResourceRequest): Promise<AxiosResponse<AdministrativeResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeResourceResponse>({
    url: 'administrative/resources',
    body: { params: params },
    loader: 'administrative/resources'
  })

  return response
}
