<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-3">
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </div>
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid
        :col="12"
      >
        <AllStudentsFilter id="all-students-filter" />
      </pms-grid>
      <pms-grid
        v-if="studentsState.students.value !== null"
        :order-mb="1"
        class="mt-5"
        :col="12"
      >
        <AllStudentsTable />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useToolbar, useTour, useDriver } from '@shared/composable'
import { feedback, header, tour, quickAllStudentsSetTourSteps } from './values'

import AllStudentsTable from './ui/AllStudentsTable.vue'
import AllStudentsFilter from './ui/AllStudentsFilter.vue'
import { useAllStudents } from './store'
import { storeToRefs } from 'pinia'

const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()
const store = useAllStudents()
const studentsState = storeToRefs(store)

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickAllStudentsSetTourSteps())
})
</script>
