/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { DurationInputArg1, DurationInputArg2 } from 'moment/moment'
import { useShadow } from '@shared/utils/shadow'
import { useLocale } from '@shared/composable'

const intervals = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 }
]

const formatLocale = {
  'en-EN': 'en',
  'kz-KZ': 'kk',
  'ru-RU': 'ru'
}

export const dateFormatter = (
  date: string|any,
  options: {
    inputFormat?: string,
    outputFormat?: string,
    actions?: Array<{
      type: 'add'|'substract',
      value: DurationInputArg1,
      unit: DurationInputArg2
    }>
  } = {
    inputFormat: 'YYYY-MM-DD hh:mm:ss A Z',
    outputFormat: 'DD.MM.YYYY'
  }
) => {
  const { locale } = useLocale()
  const formattingDate = moment(date, options.inputFormat)

  formattingDate.locale(formatLocale[locale.value])

  options?.actions?.forEach((action) => {
    if (action.type === 'add') {
      formattingDate.add(action.value, action.unit)
    }
    if (action.type === 'substract') {
      formattingDate.subtract(action.value, action.unit)
    }
  })

  return formattingDate.format(options.outputFormat)
}

export const timeSince = (date: Date): string => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
  const interval = intervals.find(i => i.seconds < seconds)

  if (interval !== undefined) {
    const count = Math.floor(seconds / interval.seconds)
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
  }

  return 'just now'
}

export const dateHelper = (splitter = '-') => {
  const t = useShadow().config.globalProperties.$t

  const formatDate = (format: string): Date => {
    const [date, time] = format.split(' ')
    const [year, month, day] = date.split(splitter)

    if (!time) {
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
    }

    const [hour, minute, seconds] = time.split(':')

    return new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour || '00'),
      parseInt(minute || '00'),
      parseInt(seconds || '00')
    )
  }

  const getDifferencesBetweenDates = (date1: Date, date2: Date): number => {
    const diffTime = Math.abs(date1.getTime() - date2.getTime())
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  const agoMessageForDate = (date: string|null, mode: 'start' | 'end'): string => {
    if (!date) return t('common.no-date')
    const now = new Date()
    const start = formatDate(date)
    const diff = getDifferencesBetweenDates(now, start)

    if (start.getTime() < now.getTime()) {
      return t(`common.date-${mode}-passed`, { days: diff })
    }

    return t(`common.date-${mode}-upcoming`, { days: diff })
  }

  const agoDateFormatter = (date: string, label: string): string => {
    const now = new Date()
    const start = formatDate(date)
    const days = getDifferencesBetweenDates(now, start)

    if (start.getTime() < now.getTime()) {
      return t('phrases.label-passed-date-ago', { days, label })
    }

    return t('phrases.label-going-date-ago', { days, label })
  }

  return { formatDate, getDifferencesBetweenDates, agoMessageForDate, agoDateFormatter }
}
