import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'

import type { DashboardWidgetsGoogleCalendarPutRequest, DashboardWidgetsGoogleCalendarPutResponse } from '@shared/swagger'
import { UnknownRequest } from '@shared/swagger'

export default async function getDashboardWidgetsCalendarEventsTokens (params: DashboardWidgetsGoogleCalendarPutRequest): Promise<AxiosResponse<DashboardWidgetsGoogleCalendarPutResponse>> {
  const { put } = useRequests()

  const response = put<UnknownRequest, DashboardWidgetsGoogleCalendarPutResponse>({
    url: 'dashboard/widgets/google/calendar/tokens',
    body: params,
    loader: 'dashboard/widgets/google/calendar/tokens'
  })

  return response
}
