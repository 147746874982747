<template>
  <div>
    <alert-component
      v-if="content.alert_type && content.alert_text"
      :alert-type="content?.alert_type"
      :alert-text="content?.alert_type"
    />
    <div
      v-if="content?.image"
      class="mb-5"
    >
      <div
        :style="{
          backgroundSize: 'cover',
          backgroundImage: `url(${content?.image})`
        }"
        class="mt-2 bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-350px mb-4"
      />
    </div>
    <a
      v-if="content?.video"
      :href="content?.video"
      target="_blank"
      class="btn btn-light-primary mb-4"
    >
      {{ $t('phrases.open-in-new-window', { src: 'video' }) }} {{ content?.video_type }}
    </a>
    <div
      class="mb-3 fs-5"
      v-html="content?.content"
    />
    <div v-if="!content?.active">
      <div class="alert alert-danger d-flex align-items-center p-5">
        <div class="d-flex flex-column">
          <span>{{ $t('domains.knowledge.single-post.content.is-not-active') }}</span>
        </div>
      </div>
    </div>
    <div class="fw-semibold text-muted d-flex fs-6">
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.single-post.content.type') + ' ' + contentTypeTitle }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.single-post.content.version') + ' ' + content?.version }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useResource } from '@shared/stores'

import { Content } from '../types'
import AlertComponent from './AlertComponent.vue'

const props = defineProps({
  content: {
    type: Object as PropType<Content>,
    required: true
  }
})

const resourсe = useResource()
const types = computed(() => resourсe.getKnowledgeManageResourceTypes)

const contentTypeTitle = computed(() => {
  return types.value.find((type) => type.id === props.content?.type_id)?.translation ?? ''
})

</script>
