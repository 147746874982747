
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useAcademicReports = defineStore<Name, State, Getters, Actions>('Academic.useAcademicReports', {
  actions: {
    ...actions
  },

  getters: {
  },

  state ():State {
    return {
      categories: [],
      reports: [],
      single_report: {
        default_fields: [],
        additional_fields: [],
        id: -1,
        title: '',
        description: '',
        parameters: []
      },
      report_data: []
    }
  }
})
