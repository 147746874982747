import { AcademicStudentsGetRequest } from '@/shared/swagger'
import { ref } from 'vue'

const initialValues = {
  surname: undefined,
  name: undefined,
  speciality: undefined,
  class: undefined,
  student_id: undefined,
  status: undefined,
  iin: undefined
}

export const tempValues = ref<Partial<AcademicStudentsGetRequest>>({
  ...initialValues
})

export const resetTempValues = () => {
  tempValues.value = { ...initialValues }
}
