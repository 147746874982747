import { computed } from 'vue'
import { router } from '@/router'
import { useResource } from '@/shared/stores'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { dayToDate } from '../utils'

export function useAttendanceRelocate () {
  const resource = computed(() => useResource().getAcademicSingleCourseAttendanceResource)
  const modules = ['relocate_types', 'schedule', 'buildings']
  const schedule_id = parseInt(router.currentRoute.value.params.id as string)

  const resourceStore = useResource()
  const store = useSingleSection()

  const page_params = {
    schedule_id,
    ...router.currentRoute.value.query as unknown as {year:number, term:number, code:string, section:string}
  }
  const schedule = computed(() => resource.value?.schedule.find(schedule => schedule.id === schedule_id))

  async function mount () {
    await resourceStore.fetchAcademicSingleCourseAttendanceResource({
      ...page_params,
      course_code: page_params.code,
      modules
    })
    store.fetchAttendanceRelocates({
      building_id: schedule.value?.room.building_id || '',
      date: dayToDate(schedule.value?.day) || ''
    })
  }

  return {
    resource,
    modules,

    page_params,
    schedule,

    mount
  }
}
