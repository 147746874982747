import { createPage } from '@shared-utils/storybook'

import Dashboard from '../DashboardPage.vue'

const Template = createPage({
  layout: 'default',
  component: Dashboard
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Dashboard/Dashboard',
  component: Dashboard,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
