<template>
  <div
    :id="`conference-${type}`"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t(`domains.profile.publications.conference-${type}.header`) }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="conferences"
        :headers="tableHeadersConference"
        :sortable="true"
      >
        <template #title="{item}">
          <span class="text-dark fw-bolder d-block mb-1 fs-4">
            {{ item.title }}
          </span>
          <span class="text-muted fw-bold text-muted d-block fs-7">
            {{ getTitleById(item.conference,conferencesSuggestions) }}
          </span>
        </template>
        <template #authors="{item}">
          <span class="text-dark fw-bolder d-block mb-1 fs-4">
            {{ item.authors }} ({{ $t('common.pages', { pages: item.pages }) }})
          </span>
          <a
            :href="item.url"
            target="_blank"
            class="text-hover-primary d-block fs-6"
          >
            {{ $t('common.open-url') }}
          </a>
        </template>

        <template #date="{item}">
          <span class="text-dark fw-bolder d-block mb-1 fs-5">
            {{ displayDate(item) }}
          </span>
        </template>
        <template #action="{ item }">
          <div class="d-flex align-items-center gap-2 justify-content-end">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <ConferenceForm v-if="modifier && modifier.name === `conference-${type}`" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ProfileEducationConferencesGetResponse } from '@/shared/swagger'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { months, tableHeadersConference } from '../values'
import { getTitleById } from '../utils'
import { useProfile } from '../../../store'

import ConferenceForm from './ConferenceForm.vue'

const props = defineProps({
  conferences: {
    type: Array as PropType<ProfileEducationConferencesGetResponse>,
    default: null
  },
  type: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const conferencesSuggestions = computed(() => resource.getProfileResource?.conferences || [])

const setIdAction = (id: number) => {
  setModifier(`conference-${props.type}`,
    { id: id, action: id === -1 ? 'new' : 'edit', type: props.type },
    {
      title: id === -1 ? 'conference-new-title' : 'conference-edit-title',
      description: id === -1 ? 'conference-new-description' : 'conference-edit-description'
    }
  )
  open({ name: 'publication-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.publications.are-you-sure',
    text: t('domains.profile.publications.conference.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteConference(id)
    }
  })
}

const displayDate = (item): string => {
  return t(months[item.publishedMonth - 1]) + ' ' + item.publishedYear
}
</script>
