import { computed } from 'vue'
import { useResource } from '@/shared/stores'
import { SelectField } from '@/shared/types'
import { storeToRefs } from 'pinia'

const resource = useResource()
const { academicResource } = storeToRefs(resource)

export default computed(() => {
  let options: SelectField['options'] = []
  const instructors = academicResource.value ? academicResource.value.instructors : []
  options = instructors.map((item) => {
    return {
      label: item.full_name,
      value: item.id
    }
  })

  return options.sort((a, b) => a.label.localeCompare(b.label))
})
