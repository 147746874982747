export default function getFormattedDateFromExcel (excelDate) : string {
  const SECONDS_IN_DAY = 24 * 60 * 60
  const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000
  const MAGIC_NUMBER_OF_DAYS = 25569 // Adjusted for the epoch difference

  const delta = excelDate - MAGIC_NUMBER_OF_DAYS
  const parsed = delta * MISSING_LEAP_YEAR_DAY
  const date = new Date(parsed)

  // Format the date as 'YYYY-MM-DD'
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
