import { useRoute } from 'vue-router'
import { defineComponent, nextTick, onBeforeMount, onMounted, watch } from 'vue'

import { contentWidthFluid } from '@shared-composable/pattern'
import { reinitializeComponents, LayoutUtils } from '@shared-utils/pattern'
import { PmsFooter, PmsDrawers, PmsScrollTop, PmsSidebar, PmsHeader } from './default'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    PmsFooter,
    PmsDrawers,
    PmsScrollTop,
    PmsSidebar,
    PmsHeader
  },
  setup (_, { slots }) {
    const route = useRoute()

    onBeforeMount(() => {
      LayoutUtils.init()
    })

    onMounted(() => {
      nextTick(() => reinitializeComponents())
    })

    watch(
      () => route.path,
      () => { nextTick(() => reinitializeComponents()) }
    )

    return () => (
      <>
        <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
              <pms-header />
              <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <pms-sidebar />
                <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div class="d-flex flex-column flex-column-fluid">
                    <div id="pms-toolbar"></div>
                    <div id="kt_app_content" class="app-content flex-column-fluid">
                      <div id="pageHeaderToolbar"></div>
                      <div
                        id="kt_app_content"
                        class="app-content flex-column-fluid"
                      >
                        <div
                          id="kt_app_content_container"
                          class={{
                            'app-container': true,
                            'container-fluid': contentWidthFluid,
                            'container-xxl': !contentWidthFluid
                          }}
                        >
                          { slots.default?.() }
                        </div>
                      </div>
                    </div>
                  </div>
                  <pms-footer />
                </div>
              </div>
          </div>
        </div>
        <pms-drawers />
        <pms-scroll-top />
      </>
    )
  }
})
