export const stepsItems = [
  {
    title: 'domains.knowledge.manage.post-form.step1.title',
    description: 'domains.knowledge.manage.post-form.step1.description'
  },
  {
    title: 'domains.knowledge.manage.post-form.step2.title',
    description: 'domains.knowledge.manage.post-form.step2.description'
  },
  {
    title: 'domains.knowledge.manage.post-form.step3.title',
    description: 'domains.knowledge.manage.post-form.step3.description'
  }
]
