<template>
  <pms-tab
    v-model:active="tab"
    class="mw-100 mt-2"
    :tabs-id="'system-calendar-tabs'"
    :tabs="tabs"
  >
    <template #system-calendar>
      <pms-card
        id="system-calendar"
        :header="false"
        :options="{body: { class: 'pt-3'}}"
      >
        <system-events />
      </pms-card>
    </template>
    <template #permissions>
      <pms-card
        id="permissions"
        :header="false"
        :class="{ 'mt-5': tab === 'all'}"
        :options="{body: { class: 'pt-3'}}"
      >
        <individual-permissions />
      </pms-card>
    </template>
    <template #course-permissions>
      <pms-card
        id="course-permissions"
        :header="false"
        :class="{ 'mt-5': tab === 'all'}"
        :options="{body: { class: 'pt-3'}}"
      >
        <course-permissions />
      </pms-card>
    </template>
  </pms-tab>
</template>
<script setup lang="ts">
import { ref } from 'vue'

import { systemCalendarTabs as tabs } from '../values'

import SystemEvents from './SystemEvents.vue'
import IndividualPermissions from './IndividualPermissions.vue'
import CoursePermissions from './CoursePermissions.vue'

const tab = ref<string>('all')
</script>
