<template>
  <pms-card
    :title="messageListHeader.title"
    :description="messageListHeader.description"
    :options="{body: {class: 'pt-0 px-0'}}"
  >
    <table
      v-if="messages.length > 0"
      class="table table-hover table-row-dashed fs-6 my-0"
    >
      <tbody>
        <template
          v-for="(message, index) in messages"
          :key="index"
        >
          <table-item :message="message" />
        </template>
      </tbody>
    </table>
    <div
      v-else
      class="text-center"
      style="padding-top: 50px; padding-bottom: 50px"
    >
      {{ $t('common.no-data') }}
    </div>

    <pms-pagination
      v-if="messages.length > 0"
      class="p-0 m-0 mt-6 mx-10"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useMessageBroadcast } from '../store'
import { messageListHeader } from '../values'

import TableItem from './TableItem.vue'

const store = useMessageBroadcast()
const messages = computed(() => store.getMessages)
</script>
