import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileExperiencesPostRequest } from '@shared/swagger'

export default async function postProfileExperiences (params: ProfileExperiencesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileExperiencesPostRequest, UnknownResponse>({
    url: 'profile/experiences',
    body: params,
    loader: 'profile/experiences-post'
  })

  return response
}
