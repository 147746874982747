export const views: Array<{ id: 'employee' | 'student' } & Record<string, string>> = [
  {
    id: 'employee',
    image: '/media/icons/duotune/communication/com005.svg',
    description: 'domains.service.manage.language.view-applicants-description',
    title: 'domains.service.manage.language.views.view-applicants-title'
  },
  {
    id: 'student',
    image: '/media/icons/duotune/technology/teh002.svg',
    description: 'domains.service.manage.language.view-student-description', // search form for language levels for students
    title: 'domains.service.manage.language.view-student-title'
  }
]
