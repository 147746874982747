import { useResource } from '@shared/stores'
import type { StoreType } from '../types'

export default async function bootstrap (this: StoreType, page: string): Promise<void> {
  const resource = useResource()

  switch (page) {
    case 'overview':
      await Promise.all([
        this.fetchOverview({ address_type: 'current', article_type: 'international', conference_type: 'international' }),
        resource.fetchProfileResource({ modules: ['provinces', 'regions', 'countries', 'cities', 'companies'] })
      ])
      break
    case 'addresses':
      await Promise.all([
        this.fetchAddresses(),
        resource.fetchProfileResource({ modules: ['provinces', 'regions', 'countries', 'cities'] })
      ])
      break
    case 'biography':
      await Promise.all([
        this.fetchChildren(),
        this.fetchBiography(),
        resource.fetchProfileResource({ modules: ['genders'] })
      ])
      break
    case 'contacts':
      await Promise.all([
        this.fetchContacts(),
        resource.fetchProfileResource({ modules: ['contact_types'] })
      ])
      break
    case 'education':
      await Promise.all([
        this.fetchEducations(),
        this.fetchSupervisions(),
        this.fetchConductedCourses(),
        this.fetchOrganizations(),
        resource.fetchProfileResource({ modules: ['countries', 'edu-levels', 'organizations'] })
      ])
      break
    case 'publications':
      await Promise.all([
        this.fetchArticles(),
        this.fetchBooks(),
        this.fetchConferences(),
        resource.fetchProfileResource({ modules: ['countries', 'journals', 'book_types', 'conferences'] })
      ])
      break
    case 'experience':
      await Promise.all([
        this.fetchExperiences(),
        this.fetchProjects(),
        this.fetchCertificates(),
        resource.fetchProfileResource({ modules: ['countries', 'cities', 'companies', 'experience', 'organizations'] })
      ])
      break
    case 'skills':
      await Promise.all([
        this.fetchLanguages(),
        this.fetchSkills(),
        this.fetchInterest(),
        resource.fetchProfileResource({ modules: ['languages'] })
      ])
      break
    case 'settings':
      await Promise.all([
        this.fetchSettings(),
        resource.fetchProfileResource({ modules: ['contact_types'] })
      ])
      break
  }
}
