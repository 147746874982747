import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.contacts',
    field: 'contact'
  },
  {
    title: 'common.table.type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
export const tableHeadersSupervision: Array<SmartTableHeader> = [
  {
    title: 'common.table.student',
    field: 'student',
    slotCellName: 'student',
    headerClass: 'min-w-200px'
  },
  {
    title: 'common.table.topic',
    field: 'topic',
    slotCellName: 'topic',
    headerClass: 'min-w-300px'
  },
  {
    title: 'common.table.date',
    field: 'date',
    slotCellName: 'date',
    headerClass: 'min-w-100px'

  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
export const tableHeadersConducted: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.level',
    field: 'level',
    slotCellName: 'level'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
export const tableHeadersOrganization: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    headerClass: 'min-w-150px'
  },
  {
    title: 'common.table.start-date',
    field: 'start-date',
    slotCellName: 'start-date',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.end-date',
    field: 'end-date',
    slotCellName: 'end-date',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
