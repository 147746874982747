import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.course-code',
    field: 'code',
    headerClass: 'mw-150px',
    sortable: true
  },
  {
    title: 'common.table.course-title',
    field: 'title',
    sortable: true
  },
  {
    title: 'common.table.course-assessment',
    field: 'assessment',
    sortable: true
  },
  {
    title: 'common.table.exam-start-date',
    field: 'exam_date',
    sortable: true
  },
  {
    title: 'common.table.exam-close-date',
    field: 'close_date',
    sortable: true
  }
]
