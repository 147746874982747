import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicCoursesGradesExamReportsGetRequest,
  AcademicCoursesGradesExamReportsGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicCoursesGradesExamReports (params: AcademicCoursesGradesExamReportsGetRequest): Promise<AxiosResponse<AcademicCoursesGradesExamReportsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicCoursesGradesExamReportsGetResponse>({
    url: 'academic/courses/grades/exam-reports',
    body: { params },
    loader: 'academic/courses/grades/exam-reports'
  })

  return response
}
