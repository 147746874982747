import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.select',
    field: 'select',
    slotCellName: 'select_single',
    slotName: 'select_all'
  },
  {
    title: 'common.table.student_id',
    field: 'student_id'
  },
  {
    title: 'common.table.student',
    field: 'student_name',
    slotCellName: 'student'
  },
  {
    title: 'common.table.class',
    field: 'student_class'
  },
  {
    title: 'common.table.program',
    field: 'program_title'
  },
  {
    title: 'common.table.document',
    field: 'document_type_title',
    slotCellName: 'document_type_title'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]
