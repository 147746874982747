import type { ProfileEducationConferencesGetResponse } from '@/shared/swagger'
import { getProfileEducationConferences } from '@shared/api'

import type { StoreType } from '../types'
import { AxiosResponse } from '@/shared/types'

const conferenceTypes = [
  'international',
  'national'
]

export default async function fetchConferences (this:StoreType): Promise<void> {
  const request: Array<Promise<{type: string, response: AxiosResponse<ProfileEducationConferencesGetResponse>}>> = []

  for (const conferenceType in conferenceTypes) {
    request.push(getProfileEducationConferences({ type: conferenceTypes[conferenceType] }))
  }

  const responses = await Promise.all(request)

  responses.forEach(({ type, response }) => {
    const { ok, data } = response
    if (ok) {
      this.setConferences(type, data)
    }
  })
}
