<template>
  <div :class="{ 'custom-modal': tempValues.title }" />

  <pms-card
    id="new-message-form"
    :header="false"
    :options="{
      body: {
        class: 'py-3'
      }
    }"
    :class="{
      'custom-modal-focus': tempValues.title,
    }"
  >
    <pms-smart-form
      :fields="[formFields]"
      :options="formOptions"
      :loader="loader"
      @handleSubmit="onSubmit"
      @handleChange="onChange"
    >
      <template #title="{ setValue }">
        <div
          class="d-flex align-items-center pt-2"
          :class="{'mb-3': !tempValues.title }"
        >
          <div class="symbol symbol-45px me-5">
            <img
              :src="user?.photo || '/user.png'"
              style="object-fit: cover;"
              :alt="user?.name"
            >
          </div>
          <div class="d-flex flex-row-fluid">
            <input
              v-model="tempValues.title"
              class="form-control bg-transparent border-0 px-3"
              :disabled="loader"
              :placeholder="$t('domains.administrative.message-board.new-message.placeholder')"
              @input="setValue($event.target.value)"
            >
          </div>
        </div>
      </template>
      <template
        v-if="tempValues.title"
        #formControls
      >
        <div class="form-group mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            variant="link"
            color="danger"
            class="me-5"
            type="reset"
            @click="abortNewMessage()"
          >
            {{ $t('common.cancel') }}
          </pms-button>
          <pms-button
            type="submit"
            :text="$t('common.post')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useUser } from '@shared/stores'
import { FormField } from '@shared/types'
import { useMessageBoard } from '../store'
import { useAlert, useLoader } from '@shared/composable'

const { isActive } = useLoader()
const { error } = useAlert()
const store = useMessageBoard()

const loader = isActive('administrative/message-board/posts-post')
const user = computed(() => useUser().currentUser)
const tempValues = ref({
  title: '',
  content: ''
})

const formFields = computed<Array<FormField>>(() => [
  {
    type: 'slot',
    name: 'title',
    value: tempValues.value.title || ''
  },
  {
    group: {
      class: `mt-3 ${tempValues.value.title === '' ? 'd-none' : ''}`
    },
    type: 'editor',
    name: 'content',
    value: tempValues.value.content || ''
  }
])

const formOptions = computed(() => ({
  controls: {
    class: `${tempValues.value.title === '' ? 'd-none' : ''}`
  }
}))

const onChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue

  if (name === 'title' && newValue === '') {
    tempValues.value.content = ''
  }
}

const abortNewMessage = () => {
  tempValues.value = { title: '', content: '' }
}

const onSubmit = async () => {
  const { status, message } = await store.addPost(tempValues.value)

  if (status) abortNewMessage()
  else error({ text: message })
}
</script>

<style lang="scss" scoped>
  .custom-editor-wrapper {
    border: 0.2px solid rgb(234, 227, 227);
    border-radius: 0.475rem;
  }

  html[data-theme="dark"] {
    .custom-editor-wrapper {
      border: 0.2px solid #222f3e !important;
    }
  }

  .custom-modal {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .custom-modal-focus {
    z-index: 1001 !important;
  }
</style>
