import { useRequests } from '@shared/composable'
import type { StatusMessageResponse } from '@shared/types'

export default async function deleteCoreCourseBulk (ids: number[]): Promise<StatusMessageResponse> {
  const { destroy } = useRequests()

  const { ok, message } = await destroy({
    url: '/academic/manage/curriculum/core/bulk',
    body: { data: { ids } },
    loader: 'put-academic/manage/curriculum/core/bulk'
  })

  return {
    status: ok,
    message
  }
}
