import { computed } from 'vue'

import { locales as localeValues } from '@shared-values/locale'
import { useShadow } from '@shared/utils'

export const useLocale = () => {
  const vue = useShadow()
  const i18n = vue.config.globalProperties.$i18n

  const locale = computed(() => i18n.locale)
  const localeShortest = computed(() => i18n.locale.split('-')[0] || 'en')
  const locales = computed(() => localeValues)

  const setLocale = (locale: string) => {
    i18n.locale = locale
    window.localStorage.setItem('locale', locale)
  }

  return {
    locale,
    localeShortest,
    locales,
    setLocale
  }
}

export default useLocale
