import { isTranslatable, useShadow } from '@shared/utils'
import { ToastMessageOptions } from 'primevue/toast'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export type AlertMode = 'success' | 'error' | 'warning' | 'dialog' | 'question' | 'info'

export type AlertOption = {
  title?: string,
  text: string,
  textProperties?: Record<string, string>,
  isToast?: boolean,
  toastOptions?: ToastMessageOptions
}

export type AlertActionOption = AlertOption & {
  onConfirm?: () => void,
  onCancel?: () => void,
}

type SwalFireObject = {
  title?: string,
  text: string,
  icon: AlertMode,
  buttonsStyling: boolean,
  customClass: Record<string, string>,
  showCancelButton?: boolean,
  confirmButtonColor?: string,
  cancelButtonColor?: string,
  confirmButtonText?: string,
  cancelButtonText?: string
}

type SwalDialogResponse = {
  isConfirmed: boolean,
  isDismissed: boolean
}

export const useAlert = () => {
  const vue = useShadow()
  const toast = vue.config.globalProperties.$toast
  const t = vue.config.globalProperties.$t

  const alertSwal = async (mode: AlertMode, option: AlertActionOption) => {
    const fireObject: SwalFireObject = {
      title: option.title
        ? isTranslatable(option.title)
          ? t(option.title, option.textProperties || {})
          : option.title
        : undefined,
      text: isTranslatable(option.text) ? t(option.text, option.textProperties || {}) : option.text,
      icon: mode,
      buttonsStyling: false,
      confirmButtonText: t('common.confirm'),
      customClass: {
        confirmButton: mode === 'error'
          ? 'btn fw-bold btn-sm btn-light-danger'
          : 'btn fw-bold btn-sm btn-light-' + mode,
        content: 'mt-0'
      }
    }

    if (mode === 'dialog') {
      fireObject.title = t('phrases.are-you-sure')
      fireObject.icon = 'question'
      fireObject.showCancelButton = true
      fireObject.confirmButtonText = t('common.yes-continue')
      fireObject.cancelButtonText = t('common.no-cancel')
      fireObject.customClass = {
        content: 'mt-0',
        confirmButton: 'btn fw-bold btn-sm btn-light-success',
        cancelButton: 'btn fw-bold btn-sm btn-light-danger'
      }
    }

    const response: SwalDialogResponse = await Swal.fire(fireObject)

    if (mode === 'dialog') {
      if (response.isConfirmed === true && option.onConfirm) {
        option.onConfirm()
      }

      if (response.isDismissed === true && option.onCancel) {
        option.onCancel()
      }
    }
  }

  const alertToast = (type: ToastMessageOptions['severity'], option: AlertOption) => {
    toast.add({
      severity: type,
      summary: option.title || t(`common.${type}`),
      detail: option.text,
      life: 3000,
      ...option.toastOptions
    })
  }

  const success = async (option: AlertOption) => {
    option.isToast ? alertToast('success', option) : await alertSwal('success', option)
  }

  const error = async (option: AlertOption) => {
    option.isToast ? alertToast('error', option) : await alertSwal('error', option)
  }

  const warning = async (option: AlertOption) => {
    option.isToast ? alertToast('warn', option) : await alertSwal('warning', option)
  }

  const dialog = async (option: AlertActionOption) => {
    await alertSwal('dialog', option)
  }

  const info = async (option: AlertOption) => {
    option.isToast ? alertToast('info', option) : await alertSwal('info', option)
  }
  return {
    success,
    error,
    warning,
    dialog,
    info
  }
}

export default useAlert
