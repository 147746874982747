import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesResourcesRequest } from '@shared/swagger'

import { getAcademicSingleCourseAttendanceResource } from '@shared/api'

export default async function fetchAcademicSingleCourseAttendanceResource (this: StoreType, params: AcademicSingleCourseAttendancesResourcesRequest): Promise<void> {
  const { ok, data } = await getAcademicSingleCourseAttendanceResource(params)

  if (ok) {
    this.setAcademicSingleCourseAttendanceResource(data)
  }
}
