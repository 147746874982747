<template>
  <div class="d-flex flex-column">
    <h1 class="text-dark fs-2 fw-bold mb-4">
      {{ $t('domains.academic.manage.section.received-requests') }}
    </h1>
    <p class="mb-6 fs-5">
      {{ $t('domains.academic.manage.section.received-requests-description') }}
    </p>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'table'"
      :full-width="true"
    />
    <pms-smart-table
      v-else
      :items="departments"
      :headers="programTableHeader"
      :class="'mt-4'"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #department="{item}">
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="item.title"
        >
          {{ item.code }}
        </span>
      </template>
      <template #action="{item}">
        <pms-button
          :size="'sm'"
          :variant="'tonal'"
          class="w-100px"
          @click="() => {
            setSelectedDepartment(item)
            goToCoursesPage(item.code)
          }"
        >
          {{ $t('common.details') }}
          <pms-kt-icon
            :icon-name="'arrow-right'"
            class="m-0 p-0 ms-2"
          />
        </pms-button>
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useLoader } from '@shared/composable'
import { useResource } from '@/shared/stores'

import { useReceivedRequest } from '../store'

import { departmentCode, programTableHeader } from '../values'

const { isActive } = useLoader()

const loader = isActive('academic/manage/sections/resources')
const resource = useResource()

const receivedRequestStore = useReceivedRequest()
const { setSelectedDepartment } = receivedRequestStore

const departments = computed(() => resource.getSectionManagementResourceDepartments)

const goToCoursesPage = async (code: string) => {
  receivedRequestStore.step = 'courses'
  departmentCode.value = code
}
</script>
