import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicSingleStudentResourceResponse
} from '@shared/swagger'

export default async function getAcademicSingleStudentResource (id: string): Promise<AxiosResponse<AcademicSingleStudentResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentResourceResponse>({
    url: `academic/students/resources/${id}`,
    body: {},
    loader: 'academic/students/resources'
  })

  return response
}
