import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'

export default async function destroySectionManagementRequestsQuotasReserves (requestId: number, quotaId: number, reserveId: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/requests/${requestId}/quotas/${quotaId}/reserves/${reserveId}`,
    body: {},
    loader: 'academic/manage/sections/requests/quotas/reserves-destroy'
  })

  return response
}
