import { LocationQueryRaw, useRouter } from 'vue-router'

export default function useRedirectToSingleStudentComposable () {
  const router = useRouter()
  const handleRedirectToStudentInfo = (id: number) => {
    const query : LocationQueryRaw = {}
    query.id = String(id)
    query.previous_page = 'AdvisoryPage'

    router.push({
      name: 'SingleStudentInformationPage',
      query
    })
  }

  return { handleRedirectToStudentInfo }
}
