import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useMessageBroadcast = defineStore<Name, State, Getters, Actions>('Service.useMessageBroadcast', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      messages: [],
      currentMessageView: ''
    }
  }
})
