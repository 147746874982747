import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { useAlert } from '@/shared/composable'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { courseCodeToCourseCodeOptions } from '../utils'
import { RouteParams } from '@/domains/AcademicSingleCourse/types'
import { router } from '@/router'
import moment from 'moment'
import { search_values } from '@/domains/AcademicSingleCourse/values'

export function useSearchForm () {
  const { t } = useI18n()
  const { error } = useAlert()
  const store = useSingleCourse()

  const { attendance_courses } = storeToRefs(store)

  const tempValues = ref({
    course_code: undefined as string|undefined,
    date: new Date().toISOString().split('T')[0],
    student_id: undefined as number | undefined,
    name: undefined as string | undefined,
    surname: undefined as string | undefined
  })

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-8'
          },
          label: {
            text: `${t('common.select-course')}:`,
            class: 'mb-1 required'
          },
          name: 'course_code',
          type: 'select',
          value: tempValues.value.course_code || router.currentRoute.value.query.code,
          options: courseCodeToCourseCodeOptions(attendance_courses.value),
          required: true
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.table.date')}:`,
            class: 'mb-1 required'
          },
          name: 'date',
          type: 'date',
          value: tempValues.value.date,
          required: true
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.student_id')}:`,
            class: 'mb-1'
          },
          name: 'student_id',
          type: 'text',
          value: tempValues.value.student_id
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.name')}:`,
            class: 'mb-1'
          },
          name: 'name',
          type: 'text',
          value: tempValues.value.name
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: `${t('common.surname')}:`,
            class: 'mb-1'
          },
          name: 'surname',
          type: 'text',
          value: tempValues.value.surname
        }
      ]
    }
  ])

  async function search (values) {
    const params = router.currentRoute.value.query as unknown as RouteParams
    values.date = moment(values.date).format('D-MM-YYYY')
    const { status, message } = await store.fetchAttendanceStudents({
      ...values,
      year: params.year,
      term: params.term
    })
    if (!status) error({ text: message })
  }

  async function onSubmit ({ values }) {
    search_values.value = { ...values }
    tempValues.value = { ...values }
    search(values)
  }

  return {
    formFields,
    tempValues,
    onSubmit,
    search
  }
}
