<template>
  <div
    :class="options?.item?.class"
    class="cursor-pointer"
    :style="`margin-left: ${ (level * 20) + (nestable && !item.nestedItems ? (options.item.toggleMargin || 25) : 0 ) }px !important`"
    @click="toggle()"
  >
    <div
      :class="options?.toggle?.container?.class"
      :style="options?.toggle?.container?.style"
    >
      <template v-if="nestable && item.nestedItems && item.nestedItems.length > 0">
        <a
          href="javascript:void(0)"
          :class="options?.toggle?.class"
          :style="options?.toggle?.style"
        >
          <inline-svg
            :src="nestableState.includes(idx)
              ? '/media/icons/duotune/arrows/arr072.svg'
              : '/media/icons/duotune/arrows/arr071.svg'
            "
          />
        </a>
      </template>
      <template v-if="'item' in $slots">
        <slot
          name="item"
          v-bind="{ item, idx, level }"
        />
      </template>
      <template v-else>
        <div>{{ item.title }}</div>
      </template>
    </div>
  </div>
  <template v-if="nestableState.includes(idx) && item.nestedItems && item.nestedItems.length > 0">
    <template
      v-for="(nestedItem, index) in item.nestedItems"
      :key="`${idx}${index}`"
    >
      <smart-list-item
        :item="nestedItem"
        :idx="`${idx}${index}`"
        :nestable="nestable"
        :nestable-state="nestableState"
        :divider="divider"
        :level="level + 1"
        :options="options"
        @toggleOn="addToNestedStatusList"
        @toggleOff="removeFromNestedStatusList"
      >
        <template
          v-for="(_, slot) of $slots"
          #[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </smart-list-item>
    </template>
  </template>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { ListOptions, NestedListItem } from '@shared/types'

const props = defineProps({
  item: {
    type: Object as PropType<NestedListItem>,
    required: true
  },
  idx: {
    type: String,
    required: true
  },
  divider: {
    type: Boolean,
    default: true
  },
  level: {
    type: Number,
    required: true
  },
  nestable: {
    type: Boolean,
    required: true
  },
  nestableState: {
    type: Array as () => Array<string>,
    required: true
  },
  options: {
    type: Object as PropType<ListOptions>,
    default: () => ({})
  }
})

const emit = defineEmits([
  'toggleOn',
  'toggleOff'
])

const toggle = () => {
  emit(
    props.nestableState.includes(props.idx)
      ? 'toggleOff'
      : 'toggleOn',
    { idx: props.idx, item: props.item }
  )
}

const addToNestedStatusList = (obj) => {
  emit('toggleOn', obj)
}

const removeFromNestedStatusList = (obj) => {
  emit('toggleOff', obj)
}
</script>
