<template>
  <div
    v-if="alertConverter"
    :class="'alert d-flex align-items-center p-5 alert-' + alertConverter"
  >
    <div class="d-flex flex-column">
      <h4 class="mb-1 text-dark">
        {{ $t('phrases.need-your-attention') }}
      </h4>
      <span class="fs-6">{{ props.alertText }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  alertType: string,
  alertText: string,
}>()

const alertConverter = computed(() => {
  switch (props.alertType) {
    case 'Success':
      return 'success'
    case 'Warning':
      return 'warning'
    case 'Error':
      return 'danger'
    default:
      return props.alertType
  }
})
</script>
