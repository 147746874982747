<template>
  <pms-card
    :title="$t('domains.service.esign.student-list.title')"
    :description="$t('domains.service.esign.student-list.description')"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <div class="d-flex my-3">
      <pms-button
        v-if="sign_document_request.document_order_ids.length>0"
        @click="signAllStudents"
      >
        {{ $t('domains.service.esign.sign-selected') }}
      </pms-button>
    </div>
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      :items="esign_students.signature_documents"
      :headers="tableHeaders"
      :sortable="true"
    >
      <template #select_all>
        <pms-checkbox
          :state="isAllChecked"
          @on-check="toggleAllCheckboxOn"
          @on-uncheck="toggleAllCheckboxOff"
        />
      </template>
      <template #select_single="{item}">
        <pms-checkbox
          :state="sign_document_request.document_order_ids.some(id=>item.document_order_id==id)"
          @on-check="toggleSingleCheckboxOn(item)"
          @on-uncheck="toggleSingleCheckboxOff(item)"
        />
      </template>
      <template #student="{item}">
        {{ item.student_name }} {{ item.student_surname }}
      </template>
      <template #document_type_title="{item}">
        <pms-button
          :disabled="isActive(`service/manage/esigns/${item.document_order_id}`).value"
          variant="outlined"
          @click="showPDF(item)"
        >
          <span
            class="cursor-pointer text-hover-primary me-2"
          >
            <i class="bi bi-file-earmark-arrow-down text-dark fs-4" />
          </span>
          <span class="text-nowrap">
            {{ item.document_type_title }}
          </span>
        </pms-button>
      </template>
      <template #action="{ item }">
        <PermissionCheck :permission-code="permissionCodes.edit_documents_to_sign_esign.code">
          <template
            v-if="!item.is_signed"
          >
            <pms-button
              class="text-nowrap"
              color="light-primary"
              @click="signSingleStudent(item)"
            >
              <pms-kt-icon
                :icon-name="'pencil'"
                class="fw-bold fs-3 p-0 mx-2"
              />
              {{ $t('domains.service.esign.open_sign_modal') }}
            </pms-button>
          </template>
          <template
            v-else
          >
            <div class="badge badge-lg bg-light-success text-success">
              {{ $t('domains.service.esign.signed') }}
            </div>
          </template>
        </PermissionCheck>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'

import { tableHeaders } from '../values'
import { useServiceEsignStore } from '../store'
import { useSearchList, sign_document_request } from '../composable'
import { storeToRefs } from 'pinia'

import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'

const { isActive } = useLoader()

const { esign_students } = storeToRefs(useServiceEsignStore())
const loader = isActive('service/manage/esigns/get')

const {
  showPDF,
  signSingleStudent,
  signAllStudents,

  isAllChecked,
  toggleAllCheckboxOn,
  toggleAllCheckboxOff,
  toggleSingleCheckboxOn,
  toggleSingleCheckboxOff
} = useSearchList()

</script>
