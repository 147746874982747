import { useRequests } from '@/shared/composable'

import type { AxiosResponse, TourStep } from '@/shared/types'
import type { UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function saveTourTranslateContentByPath (path: string, content: Array<TourStep>): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<UnknownRequest, UnknownResponse>({
    url: `http://localhost:3000/tours/${path}`,
    body: { content },
    loader: 'translates'
  })

  return response
}
