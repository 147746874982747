import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementProgramsGetResponse
} from '@shared/swagger'

export default async function getScheduleManagementPrograms (): Promise<AxiosResponse<ScheduleManagementProgramsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementProgramsGetResponse>({
    url: 'academic/manage/schedules/programs',
    body: {},
    loader: 'academic/manage/schedules/programs'
  })

  return response
}
