import { ref } from 'vue'

const toggler = ref<boolean>(false)

export const useToggler = () => {
  const toggle = (): void => {
    toggler.value = true
  }
  const unToggle = (): void => {
    toggler.value = false
  }

  const setToggle = (value: boolean): void => {
    toggler.value = value
  }

  return {
    toggler,
    toggle,
    unToggle,
    setToggle
  }
}

export default useToggler
