<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'table'"
    :full-width="true"
  />
  <pms-card
    v-else-if="examDates !== null"
    :header="false"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }}"
  >
    <ExamDatesListHeader
      v-if="examDates[0]?.can_edit"
      :course-code="examDates"
    />
    <pms-smart-table
      :headers="examDates[0]?.can_edit? headers : headers_no_edit"
      :items="examDates"
      :sortable="true"
    >
      <template
        v-if="examDates[0]?.can_edit"
        #checkboxHeader
      >
        <pms-checkbox
          class="d-flex justify-content-center"
          :disabled="examDates.length === 0 || !examDates[0]?.can_edit"
          @on-check="handleSelectAll()"
          @on-uncheck="handleUnselectAll()"
        />
      </template>
      <template #checkbox="{ row }">
        <pms-checkbox
          v-if="row.can_edit"
          class="d-flex justify-content-center"
          :state="isSelected(row)"
          :disabled="!row.can_edit"
          @on-check="handleSelect(row)"
          @on-uncheck="handleUnselect(row)"
        />
      </template>
      <template #item-code="{ value, row }">
        <span
          v-styler="{ marginLeft: '15px'}"
          class="fw-bold fs-6"
        >{{ value }}.{{ row.section }}</span>
      </template>
      <template #item-title="{ value, row }">
        <div class="d-flex justify-content-start flex-column">
          <span class="text-gray-800 fw-bold text-primary mb-1 fs-6">
            {{ value }}
            <span
              v-styler="{ marginLeft: '3px' }"
              class="badge badge-light-primary fs-base"
            >
              {{ $t(`common.course-types.${camelCaseToLowerKebabCase(row.type)}`) }}
            </span>
          </span>
          <span class="text-gray-600 fw-semibold d-block fs-6 mt-0">
            {{ row.instructor }}
          </span>
        </div>
      </template>
      <template #item-assessment="{value}">
        <span class="fw-bold fs-6">{{ $t(`common.assessments.${camelCaseToLowerKebabCase(value)}`) }}</span>
      </template>
      <template #exam_date="{row}">
        <input
          v-if="selected === row.id"
          v-model="date"
          class="form-control form-control-sm"
          type="date"
          @input="handleDateInput"
        >
        <input
          v-else-if="uploaded"
          type="date"
          class="form-control form-control-sm"
          :value="getCurrentItemDate(row)"
          @change="($event) => changeSelectedCoursezreqDate($event,row)"
        >
        <div v-else>
          {{ row.exam_date }}
        </div>
      </template>
      <template #close_date="{ row }">
        <div>
          {{ row.close_date }}
        </div>
      </template>
      <template #action="{ row }">
        <div
          v-if="selected === row.id"
          class="d-flex gap-3 justify-content-center"
        >
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn btn-light-success"
            @click="saveExamDate()"
          >
            {{ `${t('common.save')}` }}
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn btn-light-primary"
            @click="cancelExamDate()"
          >
            {{ `${t('common.cancel')}` }}
          </a>
        </div>
        <div
          v-else
          class="d-flex gap-3 justify-content-center"
        >
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-primary"
            @click="setStartDate(row)"
          >
            <pms-kt-icon
              icon-name="notepad-edit"
              class="fs-3 m-0 p-0"
            />
          </a>
        </div>
      </template>
    </pms-smart-table>
    <action-buttons
      v-show="selectedCourses.length > 0"
      :students="selectedCourses"
    />
    <ExcelActionButtonVue v-show="uploaded" />
  </pms-card>
</template>
<script setup lang="ts">
import { useAlert, useLoader } from '@shared/composable'
import { camelCaseToLowerKebabCase } from '@shared/utils'
import ExamDatesListHeader from './ExamDateListHeader.vue'
import { examDatesTableHeadersEditable as headers, examDatesTableHeadersUneditable as headers_no_edit } from '../values'
import { useExamDates } from '../store'
import { useI18n } from 'vue-i18n'
import { AcademicInformationExamDatesResponse } from '@/shared/swagger'
import { selectedCourse, selectedCourses, uploaded, date, selected, selectedCoursesReq } from '../values/refs'
import ActionButtons from './ActionButtons.vue'
import ExcelActionButtonVue from './ExcelActionButton.vue'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const store = useExamDates()
const { error, success } = useAlert()
const { t } = useI18n()
const loader = isActive('academic/information/exam-dates')
const { examDates } = storeToRefs(store)

async function saveExamDate () {
  const { status, message } = await store.putSingleDate(selectedCourse.value, { exam_date: date.value })
  selected.value = -1
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

const handleUnselect = (course: AcademicInformationExamDatesResponse[0]) => {
  selectedCourses.value = selectedCourses.value.filter((s) => s !== course.id)
}

const handleSelectAll = () => {
  selectedCourses.value = examDates.value ? examDates.value.map(course => course.id) : []
}

const handleUnselectAll = () => {
  selectedCourses.value = []
}

const handleSelect = (course: AcademicInformationExamDatesResponse[0]) => {
  selectedCourses.value.push(course.id)
}

const isSelected = (course: AcademicInformationExamDatesResponse[0]) => {
  return selectedCourses.value.some((s) => s === course.id)
}

function handleDateInput (event) {
  if (event.target instanceof HTMLInputElement) {
    const selectedDate = event.target.value
    date.value = selectedDate
  }
}

function changeSelectedCoursezreqDate (event, row) {
  if (event.target instanceof HTMLInputElement) {
    for (const element of selectedCoursesReq.value) {
      if (element.assessment_id === row.id) {
        element.exam_date = event.target.value
        break
      }
    }
  }
}

function getCurrentItemDate (row) {
  for (const element of selectedCoursesReq.value) {
    if (element.assessment_id === row.id) {
      return element.exam_date
    }
  }
  return ''
}

function setStartDate (value?) {
  selected.value = value.id
  date.value = value.exam_date
  selectedCourse.value = selected.value
}

function cancelExamDate () {
  selected.value = -1
}
</script>
