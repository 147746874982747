import { TourStep } from '@/shared/types'
import type { StoreType } from '../types'
import { getTourTranslateContentByPath } from '@shared/api'

export default async function getContentByPath (this: StoreType, path: string): Promise<void> {
  const { ok, data } = await getTourTranslateContentByPath(path)

  if (ok) {
    const { locales, file } = data as { locales: Array<string>, file: Array<TourStep> }

    this.setLocales(locales)
    this.setCurrentPath(file)
  }
}
