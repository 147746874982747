<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        @launch="launch"
      >
        <template #toolbar>
          <div class="d-flex justify-content-between gap-2 mt-3 mb-2 mt-md-0">
            <div>
              <pms-driver-button class="me-2" />
              <pms-feedback-modal :options="[]" />
            </div>
            <year-terms />
          </div>
        </template>
      </pms-page-header>
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </pms-row>
    <pms-row v-if="loader">
      <pms-page-shimmer
        :shimmer="'table'"
        :full-width="true"
      />
    </pms-row>
    <pms-row v-else>
      <event-tabs />
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useDriver, useLoader, useTour } from '@shared/composable'

import { header, tour, quickTourSteps } from './values'

import YearTerms from './ui/YearTerms.vue'
import EventTabs from './ui/EventTabs.vue'

const { isActiveOneOf } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['resources', 'administrative/system-calendar'])

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTourSteps())
})
</script>
