import type { StatusMessageResponse } from '@shared/types'
import type{ ServiceManageLanguagesApplicantsBulkPutRequest } from '@shared/swagger'
import { putServiceManageLanguagesApplicants } from '@shared/api'

import type { StoreType } from '../types'

export default async function putStudents (this: StoreType, values: ServiceManageLanguagesApplicantsBulkPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLanguagesApplicants(values)

  return {
    status: ok,
    message
  }
}
