<template>
  <pms-card
    id="consent-request"
    :header="false"
    :options="{
      body: {class: 'pt-0'}
    }"
  >
    <div class="card-header align-items-center justify-content-between px-0">
      <h3 class="card-title align-items-start flex-column py-2">
        <span class="card-label fw-bold text-dark">
          {{ $t('domains.academic.single-course.navigation.items.concent-request') }}
        </span>
        <span class="text-gray-400 mt-1 fs-6">
          {{ $t('domains.academic.single-course.navigation.items.concent-request-description') }}
        </span>
      </h3>
    </div>
    <pms-smart-table
      :headers="tableHeaders"
      :items="consentRequests"
      :translate-headers="true"
    >
      <template #student="{ row }">
        <p class="text-gray-800 fw-bold mb-1 fs-6 w-200px">
          {{ row.student_name }} ({{ row.student_id }})
        </p>
      </template>
      <template #item-date="{ value }">
        <p class="text-gray-800 fw-bold mb-1 fs-6">
          {{ dateFormatter(value) }}
        </p>
      </template>
      <template #message="{ value }">
        <q>
          {{ value }}
        </q>
      </template>
      <template
        v-for="header in ['level', 'class', 'type']"
        :key="header"
        #[`item-${header}`]="{ value }"
      >
        <span class="fw-bold fs-5">{{ value }}</span>
      </template>
      <template #item-status="{ value }">
        <span
          class="fw-bold fs-5 badge badge-light-"
          :class="{
            'badge-light-danger': value === 3,
            'badge-light-success': value === 2,
            'badge-light-warning': value === 1
          }"
        >
          {{ getStatusById(value) }}
        </span>
      </template>
      <template #item-actions="{ row }">
        <template v-if="row.status === 1">
          <div class="d-flex gap-2">
            <button
              type="button"
              class="btn btn-sm btn-primary px-4 py-2"
              @click="updateStatus(row.status, 2)"
            >
              {{ $t('common.accept') }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger px-4 py-2"
              @click="updateStatus(row.status, 3)"
            >
              {{ $t('common.reject') }}
            </button>
          </div>
        </template>
        <template v-else>
          <span class="fw-semibold fs-7 opacity-20">{{ $t('common.no-action') }}</span>
        </template>
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useResource } from '@shared/stores'
import { dateFormatter } from '@/shared/utils'

import { tableHeaders } from '../values'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'

const resource = useResource()
const store = useSingleSection()

const statuses = computed(() => resource.getAcademicSingleCoursesResourceConsentStatuses)
const consentRequests = computed(() => store.getConsentRequests)

const getStatusById = (id: number): string => {
  return statuses.value.find(status => status.id === id)?.title || ''
}

const updateStatus = (status, status2) => {
  console.log('put status', status, status2)
}

</script>
