import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationSupervisionsPostRequest } from '@shared/swagger'

export default async function postProfileEducationSupervisions (params: ProfileEducationSupervisionsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationSupervisionsPostRequest, UnknownResponse>({
    url: 'profile/education/supervisions',
    body: params,
    loader: 'profile/education/supervisions-post'
  })

  return response
}
