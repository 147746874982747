import { Service } from '../../types'
import icon from './icon'

const library: Service = {
  id: 'library-service',
  title: 'domains.service.services.library-service.title',
  description: 'domains.service.services.library-service.description',
  categories: ['academic'],
  url: 'https://library.sdu.edu.kz/',
  route: '/services/library-service',
  image: '/img/library.png',
  icon,
  contents: async () => (await import('@/contents/services/library.json')).default
}

export default library
