<template>
  <pms-page-shimmer
    v-if="resourceLoader"
    :shimmer="'list'"
    :full-width="true"
  />
  <pms-card
    v-else
    :header="false"
  >
    <NewStep2 v-if="!isEditContent" />
    <EditStep2 v-else />
    <pms-teleport-modal
      :name="'post-content-modal'"
      :teleport-name="'post-content-modal-content'"
      :title="$t(`domains.knowledge.manage.post.modal.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`domains.knowledge.manage.post.modal.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
  </pms-card>
</template>

<script setup lang="ts">
import { useLoader, useTeleportModal } from '@shared/composable'

import NewStep2 from './NewStep2.vue'
import EditStep2 from './EditStep2.vue'

defineProps({
  isEditContent: {
    type: Boolean,
    default: false
  }
})
const { isActive } = useLoader()
const { modifier } = useTeleportModal()

const resourceLoader = isActive('knowledge/manage/resources')
</script>
