<template>
  <div class="d-flex align-items-center">
    <div class="symbol symbol-40px me-3">
      <img
        style="object-fit: cover;"
        class="pb-0 mb-0"
        :src="user?.photo || '/user.png'"
        :alt="user?.name + ' ' + user?.surname"
      >
    </div>
    <div class="position-relative w-100">
      <textarea
        v-model="myComment"
        type="text"
        class="form-control form-control-solid border ps-4"
        :rows="myCommentRows"
        :disabled="commentNewLoader"
      />
      <div class="position-absolute top-0 end-0 mt-1">
        <button
          v-styler="{
            marginRight: commentNewLoader ? '10px' : '20px'
          }"
          type="button"
          class="btn btn-icon btn-sm p-0 me-4"
          :class="{
            'disabled btn-color-gray-500': !myComment,
            'btn-color-primary': myComment,
            'w-25px': !commentNewLoader,
            'w-100px': commentNewLoader
          }"
          @click="handleNewComment()"
        >
          <i
            v-if="!commentNewLoader"
            class="fa fa-paper-plane fs-2"
          />
          <span
            v-if="commentNewLoader"
            class="indicator-label"
          >
            {{ $t('phrases.please-wait') }}
            <span class="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch, PropType } from 'vue'
import { useUser } from '@shared/stores'
import { useLoader } from '@shared/composable'
import { useMessageBoard } from '@domains/AdministrativeMessageBoard/store'

const props = defineProps({
  postId: {
    type: Number as PropType<number>,
    required: true
  }
})

const { isActive } = useLoader()
const store = useMessageBoard()

const commentNewLoader = isActive('administrative/message-board/comments-post')
const user = computed(() => useUser().currentUser)
const myComment = ref<string>('')
const myCommentRows = ref<number>(1)

watch(
  () => myComment.value,
  (val) => {
    const matches = val.match(/\n/g)
    myCommentRows.value = matches ? matches.length : 1
  })

const handleNewComment = async () => {
  await store.addComment({
    content: myComment.value,
    post_id: props.postId
  })

  myComment.value = ''
}
</script>

<style scoped>
textarea {
  overflow: hidden;
  resize: none;
}

img {
  max-width: 100% !important;
}
</style>
