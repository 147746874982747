import { getRulesAndRegulationsSingleRule } from '@/shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'

export default async function fetchSingleRule (this: StoreType, ruleId: string): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getRulesAndRegulationsSingleRule(ruleId)

  ok && this.setSingleRule(data)
  return {
    status: ok,
    message
  }
}
