<template>
  <pms-row>
    <pms-grid
      :col="12"
    >
      <EmployeesSearch />
    </pms-grid>
    <pms-grid
      class="mt-5"
      :col="12"
      :order-mb="2"
    >
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'table'"
        :full-width="true"
      />
      <EmployeesList
        v-else
        id="staff-list"
      />
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import EmployeesSearch from './EmployeesSearch.vue'
import EmployeesList from './EmployeesList.vue'
import { useLoader } from '@shared/composable'

const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf(['administrative/resources', 'administrative/staffs'])
</script>
