<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <history-component v-else />
  </div>
</template>
<script setup lang="ts">
import { useDriver, useLoader } from '@shared/composable'

import HistoryComponent from './ui/HistoryComponent.vue'
import { onMounted } from 'vue'
import { quickTourSteps } from './values'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('academic/courses/history')

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
