<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
      :filter="true"
    />
    <template v-else>
      <pms-row
        class="gap-10"
      >
        <children-list />
        <biography />
      </pms-row>

      <pms-teleport-modal
        :name="'biography-modal'"
        :teleport-name="'biography-modal-content'"
        :title="$t(`domains.profile.biography.${modifier?.translate?.title || 'title'}`)"
        :description="$t(`domains.profile.biography.${modifier?.translate?.description || 'description'}`)"
        :keyboard="false"
        :backdrop="false"
        :options="{
          content: {class: 'rounded'},
          header: {class: 'pb-0 border-0 justify-content-between'},
          body: {class: 'scroll-y pt-0'}
        }"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useDriver, useLoader, useTeleportModal } from '@shared/composable'

import { quickTourSteps } from './values'

import Biography from './ui/BiographyContent.vue'
import ChildrenList from './ui/ChildrenList.vue'

const { isActiveOneOf } = useLoader()
const { modifier } = useTeleportModal()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['profile/biography', 'profile/children'])

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
