import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicInformationSyllabusesGetRequest, AcademicInformationSyllabusesGetResponse
} from '@shared/swagger'

export default async function getAcademicInformationSyllabuses (params: AcademicInformationSyllabusesGetRequest): Promise<AxiosResponse<AcademicInformationSyllabusesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationSyllabusesGetResponse>({
    url: 'academic/information/syllabuses',
    body: { params: params },
    loader: 'academic/information/syllabuses'
  })

  return response
}
