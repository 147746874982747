<template>
  <Editor
    ref="editor"
    v-model="localValue"
    :disabled="disabled"
    :loader="loader"
    :modules="editorModules"
  />
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'
import Editor from 'primevue/editor'
import Quill from 'quill';

// Fix needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2203975395
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Editor as any).methods.renderValue = function renderValue (
  this: { quill?: Quill },
  value: string
) {
  if (this.quill) {
    if (value) {
      const delta = this.quill.clipboard.convert({ html: value })
      this.quill.setContents(delta, 'silent')
    } else {
      this.quill.setText('')
    }
  }
}

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: ''
  },
  loader: {
    type: Boolean,
    required: false
  },
  toolbar: {
    type: Object,
    required: false,
    default: () => (undefined)
  },
  disabled: {
    type: Boolean,
    required: false
  }
})
const localValue = ref(props.modelValue)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editor = ref<any>(null)

const container = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['clean'],
  ['link', 'image']
]

const imageHandler = () => {
  const url = prompt('Enter the image URL')
  if (url) {
    const range = editor.value.quill.getSelection()
    if (range) {
      editor.value.quill.insertEmbed(range.index, 'image', url)
      editor.value.quill.setSelection(range.index + 1)

      const imgElement = editor.value.quill.root.querySelector(`img[src="${url}"]`)
      if (imgElement) {
        imgElement.style.maxWidth = '100%'
      }
    }
  }
}
const editorModules = {
  toolbar: props.toolbar || {
    container: container,
    handlers: {
      image: imageHandler
    }
  }
}

watch(() => localValue.value, (newValue) => {
  emit('update:modelValue', newValue)
})

</script>
