import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LayoutRow',
  setup (_, { slots }) {
    return () => (
      <div class="row">
        { slots.default && slots.default() }
      </div>
    )
  }
})
