import { usePagination, useToggler } from '@/shared/composable'
import { useAllStudents } from '../store'
import { searchTempValues } from '../values'

const { perPage } = usePagination()
const store = useAllStudents()
const { toggle } = useToggler()

export default async function search () {
  const searchStaus = await store.fetchStudents({ ...searchTempValues.value, per_page: perPage.value, offset: 1 })
  if (searchStaus.status) {
    toggle()
  }
}
