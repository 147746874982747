<template>
  <div
    v-for="(post) in posts"
    :key="post.id"
    :ref="el => setPostRef(el as HTMLElement, post.id)"
  >
    <post-item
      :id="post.id"
      class="mt-5"
      :post="post"
    />
  </div>

  <pms-card
    v-if="posts.length === 0"
    :header="false"
    class="mt-5"
  >
    <div class="d-flex align-items-center justify-content-center fw-bold fs-5 h-100px">
      {{ $t('domains.administrative.message-board.no-posts') }}
    </div>
  </pms-card>

  <pms-card
    id="message-board-pagination"
    :header="false"
    class="mt-5"
  >
    <pms-pagination
      class="p-0 m-0"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import { useMessageBoard } from '@domains/AdministrativeMessageBoard/store'

import PostItem from './PostItem.vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const store = useMessageBoard()
const route = useRoute()
const router = useRouter()
const { posts } = storeToRefs(store)

const postRefs = ref<{ [key: number]: HTMLElement | null }>({})

const setPostRef = (el: HTMLElement | null, id: number) => {
  if (el) {
    postRefs.value[id] = el
  } else {
    delete postRefs.value[id]
  }
}

const scrollToPost = (id: number) => {
  const postElement = postRefs.value[id]
  if (postElement) {
    postElement.scrollIntoView({ behavior: 'smooth' })
  } else {
    router.replace({ query: undefined })
    postRefs.value = {}
  }
}

watch([postRefs], () => {
  const postId = parseInt(route.query.postId + '')

  postId && postRefs.value && scrollToPost(postId)
}, { deep: true })

</script>
