import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useMessageBroadcastSingle = defineStore<Name, State, Getters, Actions>('Service.useMessageBroadcastSingle', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      message: null
    }
  }
})
