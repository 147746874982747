<template>
  <vue-resizable
    id="reports-widget"
    class="resizable-widget card card-xl-stretch"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-width="620"
    min-height="520"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-test-id="'title'"
          class="card-label fw-bolder text-dark"
        >{{ $t('domains.dashboard.widgets.reports.title')
        }}</span>
        <span
          v-test-id="'description'"
          class="text-muted mt-1 fw-bold fs-7"
        >{{
          $t('domains.dashboard.widgets.reports.description')
        }}</span>
      </h3>
    </div>
    <div class="card-body pt-5">
      <div
        v-for="(report, index) in reports"
        :key="index"
        v-test-id="'items'"
        class="d-flex align-items-sm-center mb-7"
      >
        <div class="symbol symbol-50px me-5">
          <pms-kt-icon
            :icon-name="'questionnaire-tablet'"
          />
        </div>
        <div class="d-flex align-items-center flex-row-fluid flex-wrap">
          <div class="flex-grow-1 me-2 col-6">
            <div class="text-gray-800 fs-6 fw-bolder">
              {{ report.title }}
            </div>
          </div>
          <router-link
            v-test-id="'link'"
            class="btn btn-sm btn-light fw-bolder my-2"
            :to="`academic/reports/view/${report.report_id}`"
          >
            {{ $t('domains.dashboard.widgets.reports.open') }}
          </router-link>
        </div>
      </div>
      <div
        v-if="reports.length === 0"
        v-test-id="'no-data'"
        class="d-flex align-items-center justify-content-center min-h-150px"
      >
        {{ $t('common.no-data') }}
      </div>
      <div class="d-flex justify-content-center pt-2">
        <router-link
          v-test-id="'all'"
          class="btn btn-light-primary btn-sm"
          :to="{ path: redirectLink }"
        >
          {{ $t('domains.dashboard.widgets.reports.open-all') }}
        </router-link>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useDashboard } from '../store'
import { storeToRefs } from 'pinia'
import VueResizable from 'vue-resizable'
import { onResize, getSavedWidgetSize } from '../utils'
const store = useDashboard()

const { reports } = storeToRefs(store)

const redirectLink = '/academic/reports'

const widgetId = 'reports-widget'

const widgetSize = ref({ width: 'auto', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
