import type { StatusMessageResponse } from '@shared/types'
import type { ProfileContactsPutRequest } from '@shared/swagger'
import { putProfileContacts } from '@shared/api'

import type { StoreType } from '../types'

export default async function editContact (this:StoreType, contact_id: number, values: ProfileContactsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileContacts(contact_id, values)

  return {
    status: ok,
    message: message
  }
}
