<template>
  <div class="d-flex align-items-md-center align-items-center gap-2 mt-2 mt-md-0">
    <p class="fs-5 m-0">
      {{ $t('common.year-terms') }}:
    </p>

    <div
      id="year-term-selector"
      class="d-flex flex-row"
    >
      <select
        class="form-select form-select w-175px"
        @change="setYearTerm($event)"
      >
        <option
          v-for="yearTerm in yearTerms"
          :key="(yearTerm.year + '' + yearTerm.term)"
          :value="yearTerm.year + '-' + yearTerm.term"
          :selected="(yearTerm.year === currentYearTerm.year && yearTerm.term === currentYearTerm.term)"
        >
          {{ getYearTermLabel(yearTerm, t) }}
        </option>
      </select>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { getYearTermLabel } from '@/shared/utils'
import { useResource } from '@shared/stores'
import { useAcademicSchedule } from '../store'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const resource = useResource()
const store = useAcademicSchedule()

const yearTerms = computed(() => resource.getResourceYearTerms)
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const setYearTerm = (event) => {
  store.$reset()
  const [year, term] = event.target.value.split('-')
  store.setCurrentYearTerm({ year, term, id: 0 })
  resource.fetchAcademicResource({ modules: ['departments', 'instructors'], year, term })
}
</script>
