/* eslint-disable @typescript-eslint/no-var-requires */
import { PathFilePaired } from '@avocado/types'

export const loadSharedFiles = (): PathFilePaired[] => {
  const files: PathFilePaired[] = []

  // directives
  require.context(
    '@/shared/directives/',
    true,
    /^((?![\\/][\\/]).)*\.directive.ts$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/shared/directives/' + filename.substring(2)).default }))

  // ui
  require.context(
    '@/shared/ui/shared/',
    true,
    /^((?![\\/][\\/]).)*\.config.ts$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/shared/ui/shared/' + filename.substring(2)).default }))

  require.context(
    '@/shared/ui/shared/',
    true,
    /^((?![\\/][\\/]).)*\.tsx$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/shared/ui/shared/' + filename.substring(2)).default }))

  require.context(
    '@/shared/ui/layouts/',
    true,
    /^((?![\\/][\\/]).)*\.layout.tsx$/
  )
    .keys()
    .forEach(filename => files.push({ path: filename, file: require('@/shared/ui/layouts/' + filename.substring(2)).default }))

  return files
}
