<template>
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
            {{ $t('phrases.system.error-404.title') }}
          </h1>
          <div class="fw-semibold fs-6 text-gray-500 mb-7">
            {{ $t('phrases.system.error-404.message') }}
          </div>
          <div class="mb-3">
            <img
              src="/media/auth/404-error.png"
              class="mw-100 mh-300px theme-light-show"
            >
            <img
              src="/media/auth/404-error-dark.png"
              class="mw-100 mh-300px theme-dark-show"
            >
          </div>
          <div class="mb-0">
            <button
              v-styler="{ marginRight: '10px' }"
              type="button"
              class="btn btn-sm btn-primary"
              @click="goToBackLink()"
            >
              {{ $t('phrases.system.error-404.back') }}
            </button>
            <router-link
              to="/"
              class="btn btn-sm btn-info"
            >
              {{ $t('phrases.system.error-404.back-to-dashboard') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

import { useBodyStore } from '@shared-stores/pattern'
import { themeMode } from '@shared-composable/pattern'
import { LayoutUtils } from '@shared-utils/pattern'
import { useRoute } from 'vue-router'

const store = useBodyStore()
const bgImage = themeMode.value !== 'dark' ? 'bg1.jpg' : 'bg1-dark.jpg'
const goToBackLink = () => window.history.back()

const route = useRoute()

console.log(route)

onMounted(() => {
  LayoutUtils.emptyElementClassesAndAttributes(document.body)

  store.addBodyClassName('bg-body')
  store.addBodyAttribute({
    qualifiedName: 'style',
    value: `background-image: url("/media/auth/${bgImage}")`
  })
})

onUnmounted(() => {
  store.removeBodyClassName('bg-body')
  document.querySelector('body')?.style.removeProperty('background-image')
})

</script>
