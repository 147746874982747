<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <pms-driver-button class="me-2 ms-2 mt-2 mt-md-0" />
      <pms-feedback-modal :options="[]" />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row
      v-if="loader"
      class="mt-6"
    >
      <pms-page-shimmer
        :shimmer="'list'"
        :full-width="true"
      />
      <pms-page-shimmer
        :shimmer="'table'"
        :full-width="true"
      />
    </pms-row>
    <pms-row v-else>
      <SectionSearchComponent />
      <SectionsTable class="mt-5" />
    </pms-row>
  </pms-container>
  <pms-teleport-modal
    :name="'schedule-management-modal'"
    :teleport-name="'schedule-management-modal-content'"
    :title="$t(`domains.schedule-management.${modifier?.translate?.title || 'title'}`)"
    :description="$t(`domains.schedule-management.${modifier?.translate?.description || 'description'}`)"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useTeleportModal, useTour, useToolbar, useDriver, useLoader } from '@shared/composable'
import { header, tour } from './values'
import { useScheduleManagement } from './store'
import SectionsTable from './ui/SectionsTable.vue'
import SectionSearchComponent from './ui/SectionSearchComponent.vue'

const { modifier } = useTeleportModal()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()
const { toolbar, toggleToolbar } = useToolbar()
const { isActive } = useLoader()
const store = useScheduleManagement()

const loader = isActive('academic/manage/schedules/programs')

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await {})
  toggleToolbar()

  store.fetchSectionResources()
})
</script>
