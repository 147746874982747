import type { StoreType } from '../types'
import type { ServiceAdvisorAssignmentResourceGetRequest } from '@shared/swagger'
import getServiceAdvisorAssignmentResource from '@/shared/api/Resource/getServiceAdvisorAssignmentResource'

export default async function fetchServiceAdvisorAssignmentResource (this: StoreType, body: ServiceAdvisorAssignmentResourceGetRequest): Promise<void> {
  const { ok, data } = await getServiceAdvisorAssignmentResource(body)

  if (ok) {
    this.setServiceAdvisorAssignmentResource(data)
  }
}
