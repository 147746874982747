import { createPage } from '@shared-utils/storybook'
import { useUser } from '@shared/stores'

import { useProfile } from '../../../store'

import ProfileExperience from '../ExperiencePage.vue'

const Template = createPage({
  layout: 'default',
  component: ProfileExperience,
  setup () {
    const store = useProfile()
    const user = useUser()
    user.authMe()

    store.$reset()
    store.bootstrap('experience')
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Profile/Experience',
  component: ProfileExperience,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
