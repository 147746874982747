import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  ScheduleManagementHoursRequest,
  ScheduleManagementHoursResponse,
  UnknownRequest
} from '@shared/swagger'

export default async function getYearTermScheduleHours (params: ScheduleManagementHoursRequest): Promise<AxiosResponse<ScheduleManagementHoursResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementHoursResponse>({
    url: 'academic/manage/schedules/hours',
    body: {
      params
    },
    loader: 'get-academic/manage/schedules/hours'
  })

  return response
}
