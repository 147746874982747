import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  ServiceManageSingleAnnouncementPublishRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putServiceManageLanguagesApplicants (announcementId: number, params: ServiceManageSingleAnnouncementPublishRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceManageSingleAnnouncementPublishRequest, UnknownResponse>({
    url: `service/manage/announcements/${announcementId}/publish`,
    body: params,
    loader: 'service/manage/announcements/single/publish'
  })

  return response
}
