<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div
        :key="'information.weekly-course-plan'"
        class="fs-2 fw-bold text-danger text-center mb-5"
      >
        {{ getMessage('content.weekly-course-plan') }}
      </div>
      <pms-smart-table
        :items="weeklyTemplate"
        :headers="header"
        :sortable="false"
        :options="{
          design: 'bordered-dashed'
        }"
      >
        <template #week="{ item }">
          {{ item.week_no }}
        </template>
        <template #subject="{ item }">
          <span class="fs-5 fw-bold">{{ item.subject }}</span>
        </template>
        <template #activity="{ item }">
          {{ item.activity }}
        </template>
        <template #action="{ item }">
          <div
            v-if="has_permission"
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              v-if="item.id === -1"
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="addWeeklyPlan(item.week_no)"
            >
              <pms-kt-icon
                :icon-name="'plus'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              v-else
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="openEditor(item.week_no)"
            >
              <pms-kt-icon
                :icon-name="'click'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              type="button"
              class="btn btn-light-danger p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :disabled="isDisabled(item)"
              :data-bs-original-title="$t('domains.academic.manage.syllabus.tooltip.delete')"
              @click="deleteWeeklyPlan(item.id)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
            </button>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <SyllabusWeeklyPlansForm v-if="modifier && modifier.name === 'syllabus.weekly-plan'" />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { ref, watch, computed } from 'vue'
import { weeklyPlans, type WeeklyPlans, weeklyPlansTable } from './../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { nullifyWeeklyTable } from './../utils'
import SyllabusWeeklyPlansForm from './SyllabusWeeklyPlansForm.vue'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'
import { has_permission } from '../values'

const { isActive } = useLoader()
const alert = useAlert()
const loader = isActive('academic/manage/syllabus/weekly-plans')
const { setModifier, open, modifier } = useTeleportModal()

const store = useStore()
const weeklyTemplate = ref<WeeklyPlans[]>(weeklyPlans)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...weeklyPlansTable,
      {
        title: 'common.action',
        field: 'action',
        slotCellName: 'action',
        headerClass: 'w-50px',
        cellClass: 'text-center'
      }]
  : weeklyPlansTable)

watch(() => store.getWeeklyPlans, (weeklyPlans) => {
  nullifyWeeklyTable(weeklyTemplate.value)
  weeklyPlans.forEach((item) => {
    const index = weeklyTemplate.value.findIndex((el) => el.week_no === item.week_no)
    if (index !== -1) {
      weeklyTemplate.value[index].id = item.id
      weeklyTemplate.value[index].subject = item.subject !== null ? item.subject : ''
      weeklyTemplate.value[index].activity = item.activity !== null ? item.activity : ''
    }
  })
}, { immediate: true })

function isDisabled (week_plan: WeeklyPlans) : boolean {
  return week_plan.id === -1
}

function addWeeklyPlan (add_week_no: number) {
  setModifier(
    'syllabus.weekly-plan',
    { week_no: add_week_no, mode: 'add' },
    { title: 'weekly-plan.title.add', description: 'weekly-plan.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

function openEditor (edit_week_no: number) {
  const editingWeek = store.getWeeklyPlans.find((plan) => plan.week_no === edit_week_no)
  if (editingWeek) {
    setModifier(
      'syllabus.weekly-plan',
      {
        id: editingWeek.id,
        week_no: editingWeek.week_no,
        subject: editingWeek.subject,
        activity: editingWeek.activity,
        count: editingWeek.count,
        mode: 'edit'
      },
      { title: 'weekly-plan.title.edit', description: 'weekly-plan.description.edit' }
    )
    open({ name: 'syllabus-form-content-modal' })
  }
}

async function deleteWeeklyPlan (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-weekly-plan',
      onConfirm: async () => {
        await store.deleteWeeklyPlan(id)
      }
    }
  )
}
</script>
