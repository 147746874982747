<template>
  <thead>
    <tr
      class="fw-bolder bg-light fs-5 align-middle"
    >
      <th
        :style="{
          width: toggler ? '6%' : '8%',
          borderTopLeftRadius: '0.4rem !important'
        }"
      >
        <p class="m-0 fs-7 ms-4">
          {{ $t('domains.academic.schedule.table.hour') }}
        </p>
      </th>
      <th
        v-for="day in scheduleWeekDays"
        :key="day.id"
        style="width: 15%"
        :style="{
          borderTopLeftRadius : day.id === scheduleWeekDays.length ? '0.4rem !important' : '',
          width: `${(100 - (toggler ? 6 : 8)) / scheduleWeekDays.length}%`
        }"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-kt-initialized="1"
          :data-bs-original-title="$t(day.title)"
          :aria-label="$t(day.title)"
        >
          {{ $t(day.abbreviation) }}
        </span>
      </th>
    </tr>
  </thead>
</template>
<script setup lang="ts">
import { useToggler } from '@shared/composable'
import { scheduleWeekDays } from '../values'

const { toggler } = useToggler()
</script>
