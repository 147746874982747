import { TabElement } from '@shared/types'

export const systemCalendarTabs: Array<TabElement> = [
  {
    id: 'system-calendar',
    label: 'domains.administrative.system-calendar.tabs.system-calendar'
  },
  {
    id: 'permissions',
    label: 'domains.administrative.system-calendar.tabs.permissions'
  },
  {
    id: 'course-permissions',
    label: 'domains.administrative.system-calendar.tabs.course-permissions'
  }
]
