import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusAssessmentsPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusAssessments (body: AcademicManageSyllabusAssessmentsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageSyllabusAssessmentsPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/assessments',
    body,
    loader: 'academic/manage/syllabus/assessments'
  })
  return response
}
