import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'empty',
  path: '/changelog',
  children: [
    {
      path: '',
      name: 'ReleaseChangelog',
      component: () => import('./ChangelogPage.vue')
    }
  ]
} as RouteRecordWithLayout
