<template>
  <div class="row">
    <div class="col-5">
      <div>
        {{ $t('domains.academic.sections.attendance.all-teaching-hours.indicator') }}
      </div>
      <div>
        {{ $t('domains.academic.sections.attendance.conducted-teaching-hours.indicator') }}
      </div>
    </div>
    <div class="col-5">
      <div class="d-flex align-items-center">
        <span class="opacity-0">0</span>
        <pms-progress
          :height="'8px'"
          :width="'800px'"
          :percentage="resource?.percentage_from_total_hours"
          :bg-color="'light-success'"
          :percentage-color="'success'"
        />
      </div>
      <div class="d-flex align-items-center">
        <span class="opacity-0">0</span>
        <pms-progress
          :height="'8px'"
          :width="'800px'"
          :percentage="resource?.percentage_from_conducted_hours"
          :bg-color="'light-primary'"
          :percentage-color="'primary'"
        />
      </div>
    </div>
    <div class="col-2">
      <div>
        {{ resource?.percentage_from_total_hours }}%
        <span class="ms-3">
          {{ `${resource?.filled}/${resource?.total_hours}` }}
        </span>
      </div>
      <div>
        {{ resource?.percentage_from_conducted_hours }}%
        <span class="ms-3">
          {{ `${resource?.filled}/${resource?.conducted_hours}` }}
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useResource } from '@/shared/stores'
import { computed } from 'vue'

const resource = computed(() => useResource().getAcademicSingleCourseAttendanceResource?.filling_indicator)
</script>
