import { Service } from '../../types'
import icon from './icon'

const moodle: Service = {
  id: 'moodle-service',
  title: 'domains.service.services.moodle-service.title',
  description: 'domains.service.services.moodle-service.description',
  categories: ['academic'],
  url: 'https://moodle.sdu.edu.kz/',
  route: '/services/moodle-service',
  image: '/img/moodle.png',
  icon,
  contents: async () => (await import('@/contents/services/moodle.json')).default
}

export default moodle
