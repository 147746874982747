import { AvocadoInitResults } from '@avocado/types'
import { useParser } from '@avocado/parser'
import { buildRoutes, buildI18nOptions, buildPlugin } from '@avocado/utils/builder'

export const useBuilder = () => {
  return {
    buildInitFromParser: (): AvocadoInitResults => {
      const parser = useParser()
      const { routes, directives, i18n, layouts, scripts, ui } = parser.getAll()

      return {
        routes: buildRoutes(routes, layouts),
        i18nOptions: buildI18nOptions(i18n),
        plugin: buildPlugin(scripts, directives, ui)
      }
    }
  }
}
