<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'table'"
    :full-width="true"
  />
  <pms-card
    v-else-if="records !== null"
    id="gate-entry-records"
    :title="$t('domains.administrative.gate-entry.search.result-title')"
    :description="$t('domains.administrative.gate-entry.search.result-description')"
    :options="{ body: { class: 'pt-0' }}"
  >
    <template
      v-if="records.length !== 0 && user_data.user_id"
      #toolbar
    >
      <p class="text-gray-400 m-0 pb-3 fw-bold fs-4">
        <span>
          {{ $t('domains.administrative.gate-entry.table.total-time') }}:
        </span>
        <span
          v-styler="{paddingLeft: '5px'}"
          class="text-dark"
        >
          {{ totalTime }}
        </span>
      </p>
    </template>
    <records-table :records="records" />
    <action-buttons />
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@shared/composable'

import { useGateEntryStore } from '../store'
import { calculateWorkTimeTotal } from '../utils'
import { user_data } from '../values'
import RecordsTable from './RecordsTable.vue'
import ActionButtons from './ActionButtons.vue'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { isActive } = useLoader()

const store = useGateEntryStore()
const dataState = storeToRefs(store)

const records = dataState.records
const loader = isActive('administrative/gate-entry')

const totalTime = computed(() => {
  const { hours, minutes } = calculateWorkTimeTotal(records.value ? records.value : [])

  return t('domains.administrative.gate-entry.search.result-duration', {
    hour: hours,
    minute: minutes
  })
})
</script>
