import type { StoreType } from '../types'
import type { KnowledgeManagePostsContentsPostRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { postKnowledgeManagePostsContents } from '@/shared/api'

export default async function createContent (this: StoreType, uuid: string, values: KnowledgeManagePostsContentsPostRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await postKnowledgeManagePostsContents(uuid, values)
  this.newContent = data
  return {
    status: ok,
    message: message
  }
}
