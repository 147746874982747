<template>
  <pms-container>
    <pms-row class="mt-10">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'text'"
        :full-width="true"
      />
      <template v-else>
        <single-message />
      </template>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { useLoader } from '@shared/composable'
import SingleMessage from '@domains/ServiceMessageBroadcastSingle/ui/SingleMessage.vue'
import { watch } from 'vue'
import { router } from '@/router'
import { useMessageBroadcastSingle } from './store'

const { isActive } = useLoader()
const loader = isActive('service/message-broadcast-single')

watch(() => router.currentRoute.value.params.id, (newValue) => {
  useMessageBroadcastSingle().bootstrap(newValue as string)
})
</script>
