import { RouteRecordWithLayout } from '@shared/types'
import { IAvocadoParser, AvocadoLanguage, AvocadoScript, AvocadoDirective, AvocadoLayout, AvocadoUI } from '@avocado/types'

import { loadRouteFiles, loadSharedFiles, loadLanguageFiles, loadScriptFiles } from '@avocado/utils/loaders'
import { getModuleNameFromPath, convertToRoute, convertToI18n, convertToScript, convertToDirective, convertToLayout, convertToUi } from '@avocado/utils/transform'

export const useParser = (): IAvocadoParser => {
  const routes: Array<RouteRecordWithLayout> = []
  const i18n: Array<AvocadoLanguage> = []
  const scripts: Array<AvocadoScript> = []
  const directives: Array<AvocadoDirective> = []
  const layouts: Array<AvocadoLayout> = []
  const ui: Array<AvocadoUI> = []

  const files = [
    ...loadRouteFiles(),
    ...loadSharedFiles(),
    ...loadScriptFiles(),
    ...loadLanguageFiles()
  ]

  files.forEach(({ path, file }) => {
    if (getModuleNameFromPath(path) === 'route') routes.push(convertToRoute(file))
    if (getModuleNameFromPath(path) === 'i18n') i18n.push(convertToI18n(file))
    if (getModuleNameFromPath(path) === 'script') scripts.push(convertToScript(file))
    if (getModuleNameFromPath(path) === 'directive') directives.push(convertToDirective(file))
    if (getModuleNameFromPath(path) === 'layout') layouts.push(convertToLayout(path, file))
    if (getModuleNameFromPath(path) === 'ui') ui.push(convertToUi(path, file))
  })

  return {
    getRoutes: () => routes,
    getI18n: () => i18n,
    getScripts: () => scripts,
    getDirectives: () => directives,
    getLayouts: () => layouts,
    getUi: () => ui,
    getAll: () => ({ routes, i18n, scripts, directives, layouts, ui })
  }
}
