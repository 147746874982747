import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { AcademicSingleStudentPostOpinionRequest, AcademicSingleStudentPostOpinionResponse } from '@shared/swagger'

export default async function postAcademicSingleStudentAdvisorOpinion (student: string, body: AcademicSingleStudentPostOpinionRequest): Promise<AxiosResponse<AcademicSingleStudentPostOpinionResponse>> {
  const { post } = useRequests()

  const response = post<AcademicSingleStudentPostOpinionRequest, AcademicSingleStudentPostOpinionResponse>({
    url: `academic/students/${student}/information/opinions`,
    body,
    loader: 'academic/students/information/opinions'
  })
  return response
}
