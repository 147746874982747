import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileEducationSupervisionsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationSupervisions (): Promise<AxiosResponse<ProfileEducationSupervisionsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileEducationSupervisionsGetResponse>({
    url: 'profile/education/supervisions',
    body: {},
    loader: 'profile/education/supervisions'
  })
  return response
}
