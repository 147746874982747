import { PropType, defineComponent } from 'vue'

const ProgressComponent = defineComponent({
  name: 'ProgressComponent',
  props: {
    width: {
      type: [String, Number] as PropType<string | number>,
      default: '24px'
    },
    height: {
      type: [String, Number] as PropType<string | number>,
      default: '5px'
    },
    bgColor: {
      type: String as PropType<'light' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark'>,
      default: 'primary'
    },
    percentage: {
      type: Number,
      default: 25
    },
    percentageColor: {
      type: String as PropType<'light' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark'>,
      default: 'primary'
    },
    striped: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: false
    },
    progressClass: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    return () => (
      <div class={[
        'progress',
        `bg-${props.bgColor}`,
        `${props.progressClass}`]}
          style={[
          `width:${typeof props.width === 'number' ? `${props.width}px` : props.width};`,
          `height: ${typeof props.height === 'number' ? `${props.height}px` : props.height};`
          ]}>
        <div class={[
          'progress-bar',
          `${props.striped && 'progress-bar-striped'}`,
          `${props.striped && props.animated && 'progress-bar-animated'}`,
          `bg-${props.percentageColor}`
        ]}
          role="progressbar"
          style={[
            `width:${props.percentage}%;`
          ]}
          aria-valuenow={`${props.percentage}`}
          aria-valuemin="0"
          aria-valuemax="100">
          </div>
      </div>
    )
  }

})
export default ProgressComponent
