<template>
  <PermissionCheck :permission-code="permissionCodes.show_course_schedule.code">
    <div>
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'list'"
        :filter="true"
        :full-width="true"
      />
      <ScheduleInfo v-else />
    </div>
  </permissioncheck>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'
import { quickTourSchedule } from '../../values'

import ScheduleInfo from './ui/ScheduleInfo.vue'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['academic/courses/schedules', 'academic/courses/resources/single', '/1.0.0/me'])

onMounted(async () => {
  setQuickTourSteps(await quickTourSchedule())
})
</script>
