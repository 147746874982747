import type { StoreType } from '../types'
import type { KnowledgeManagePostsPostRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { postKnowledgeManagePost } from '@/shared/api'

export default async function createPost (this: StoreType, values: KnowledgeManagePostsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postKnowledgeManagePost(values)

  return {
    status: ok,
    message: message
  }
}
