/* eslint-disable @typescript-eslint/no-explicit-any */
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import useRequests from '@/shared/composable/requests'

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}

const useSocket = () => {
  const on = () => {
    window.Pusher = Pusher

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.PUSHER_APP_KEY,
      cluster: process.env.PUSHER_CLUSTER,
      forceTLS: false,
      encrypted: true,
      wsHost: process.env.PUSHER_HOST,
      httpHost: process.env.PUSHER_HTTP_HOST,
      wsPort: process.env.PUSHER_PORT,
      disableStats: true,
      enabledTransports: ['ws'],
      authorizer: (channel) => {
        return {
          authorize: (socketId: number, callback) => {
            const { setHeaders, post } = useRequests()
            setHeaders({
              'x-app-id': process.env.PUSHER_APP_ID as string
            })
            post({
              url: '/broadcasting/auth',
              body: {
                socket_id: socketId,
                channel_name: channel.name
              }
            })
              .then(response => {
                callback(null, response)
              })
              .catch(error => {
                callback(error)
              })
          }
        }
      }
    })
  }

  const off = () => window.Echo.disconnect()

  return {
    on,
    off
  }
}

export default useSocket
