import type { StoreType } from '../types'
import type { AcademicMySectionsGetRequest } from '@shared/swagger'

import { useAlert } from '@shared/composable'
import { getAcademicSectionsMy } from '@/shared/api/AcademicMySections'

export default async function fetchMySections (this: StoreType, yearTerm: AcademicMySectionsGetRequest): Promise<void> {
  const { error } = useAlert()
  const { ok, message, data } = await getAcademicSectionsMy({ year: yearTerm.year, term: yearTerm.term })
  ok
    ? this.setMySections(data)
    : error({ text: message })
}
