import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { ForgotPasswordRequest, ForgotPasswordResponse } from '@shared/swagger'

export default async function postUserForgotPassword (credentials: ForgotPasswordRequest): Promise<AxiosResponse<ForgotPasswordResponse>> {
  const { post } = useRequests()

  const response = post<ForgotPasswordRequest, ForgotPasswordResponse>({
    url: 'forgot-password',
    body: credentials,
    configs: { token: false },
    loader: 'forgot-password'
  })
  return response
}
