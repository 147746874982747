import type { StatusMessageResponse } from '@shared/types'
import type { ProfileProjectsPostRequest } from '@shared/swagger'
import { putProfileProjects } from '@shared/api'

import type { StoreType } from '../types'

export default async function editProject (this:StoreType, id: number, values: ProfileProjectsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileProjects(id, values)

  return {
    status: ok,
    message: message
  }
}
