import { useTeleportModal } from '@/shared/composable'
import { RoomHourMatrix } from '../types'
import { useSingleSection } from '@/domains/AcademicSingleSection/store'

import { storeToRefs } from 'pinia'

import { ref, computed } from 'vue'

export const useAttendanceRelocateTable = () => {
  const { setModifier, open, show } = useTeleportModal()
  const { attendance_relocates } = storeToRefs(useSingleSection())

  const room_filter = ref()

  const room_hour_matrix = computed(() => {
    let matrix: RoomHourMatrix = []
    for (let room_idx = 0; room_idx < attendance_relocates.value.rooms.length; room_idx++) {
      const room = attendance_relocates.value.rooms[room_idx]
      matrix.push({
        room,
        hours: []
      })
      for (let hour_idx = 0; hour_idx < attendance_relocates.value.hours.length; hour_idx++) {
        const hour = attendance_relocates.value.hours[hour_idx]
        const lesson = attendance_relocates.value.lessons.find(lesson => lesson.room_id === room.id && lesson.lesson_hour_id === hour.id)
        matrix[room_idx].hours?.push(
          {
            hour_value: `${hour.start_time} - ${hour.end_time}`,
            hour_id: hour.id,
            room_name: room.title,
            room_id: room.id,
            lesson
          }
        )
      }
    }
    if (room_filter.value) {
      matrix = matrix.filter(room => room.room?.id === room_filter.value)
    }
    return matrix
  })
  function openModal (hour:NonNullable<RoomHourMatrix[0]['hours']>[0]) {
    setModifier('relocate-modal', { hour })
    open({ name: 'relocate-modal' })
    show()
  }
  return {
    room_filter,
    room_hour_matrix,
    openModal
  }
}
