<template>
  <pms-card :header="false">
    <p class="fs-2 fw-bold m-0 p-0">
      {{ $t('domains.academic.single-course.attendance.selector.title') }}
    </p>
    <p class="fs-5 fs-semibold mb-2">
      {{ $t('domains.academic.single-course.attendance.selector.description') }}
    </p>
    <div class="d-flex align-content-between flex-row mt-4">
      <span class="fs-6 mt-3 me-2">
        {{ $t('domains.academic.single-course.grades.selector.select-section') }}:
      </span>
      <select
        class="form-select w-100 w-md-250px"
        @change="onChange($event)"
      >
        <option
          :value="null"
          :selected="!route.query.section"
        >
          {{ $t('common.not-selected') }}
        </option>
        <option
          v-for="section in allSections"
          :key="section.section"
          :value="section.value"
          :selected="section.value === route.query.section"
        >
          {{ section.section }}
        </option>
      </select>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'
import { useRoute } from 'vue-router'

import { AcademicCoursesResourcesSingleResponse } from '@/shared/swagger'
import { RouteParams } from '../../../types'
import { router } from '@/router'

const props = defineProps({
  sections: {
    type: Array as PropType<AcademicCoursesResourcesSingleResponse['sections']>,
    required: true
  }
})

const route = useRoute()

const allSections = computed(
  () => props.sections.map((s) => ({ ...s, value: s.section.split('-')[0] }))
)

const onChange = ($event) => {
  const { year, term, code } = route.query as unknown as RouteParams
  router.push({
    name: 'SingleSectionAttendancePage',
    query: {
      year,
      term,
      code,
      section: $event.target.value
    }
  })
}
</script>
