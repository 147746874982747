import { useStore } from '@/domains/AcademicCurricula/store'
import deleteCoreCourseBulk from '@/shared/api/AcademicCurricula/deleteCoreCourseBulk'
import { useAlert } from '@/shared/composable'
import { AcademicManageCurriculumViewResponse } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const useElectiveDeleter = (electiveType: string) => {
  const { error, success } = useAlert()
  const route = useRoute()
  const store = useStore()
  const { curricula } = storeToRefs(store)
  const selectedCourses = computed<AcademicManageCurriculumViewResponse['electives']['area']>(() => curricula.value?.electives[electiveType].filter(item => item._isSelected))

  const handleDeleteElectives = async () => {
    if (!curricula.value) return

    const { message, status } = await deleteCoreCourseBulk(selectedCourses.value.map(e => e.id))

    if (status) {
      success({ text: message, isToast: true })

      if (route.query.track) store.fetchCurricula({ track: +route.query.track })
    } else {
      error({ text: message })
    }
  }

  return {
    handleDeleteElectives
  }
}

export default useElectiveDeleter
