import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    content: {
      type: String,
      default: ''
    },
    design: {
      type: String as PropType<'dotted' | 'dashed' | ''>,
      default: ''
    },
    border: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>,
      default: 2
    },
    color: {
      type: String,
      default: ''
    },
    marginY: {
      type: Number as PropType<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>,
      default: 2
    }
  },
  setup (props) {
    return () => (
      <div
        class={{
          separator: true,
          'separator-content': props.content !== '',
          [`my-${props.marginY}`]: true,
          [`separator-${props.design}`]: props.design !== '',
          [`border-${props.border}`]: true,
          [`border-${props.color}`]: props.color !== ''
        }}
      >
        { props.content !== '' && (props.content)}
      </div>
    )
  }
})
