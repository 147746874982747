<template>
  <pms-container>
    <pms-row
      class="mt-10"
    >
      <rule-form
        :title="$t('domains.rules-and-regulations.create-rule.title')"
        :description="$t('domains.rules-and-regulations.create-rule.description')"
        @save="handleRuleFormSave"
      />
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { useAlert } from '@/shared/composable'
import { useRulesAndRegulations } from '../../store'
import RuleForm from '../../ui/RuleForm.vue'
import { RulesAndRegulationsPostRequest } from '@/shared/swagger'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const store = useRulesAndRegulations()
const { t } = useI18n()
const { success, error, info } = useAlert()

const { singleRule } = storeToRefs(store)

const handleRuleFormSave = async (values: RulesAndRegulationsPostRequest) => {
  const { status, message } = await store.createNewRule(values)
  if (status) {
    success({ text: message, isToast: true })
    info({ text: t('domains.rules-and-regulations.create-rule.success-info'), isToast: true, toastOptions: { life: 5000 } })
    singleRule.value && router.push({ name: 'EditRulePage', params: { id: singleRule.value.id } })
  } else {
    error({ text: message, isToast: true, toastOptions: { life: undefined } })
  }
}
</script>
