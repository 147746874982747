<template>
  <pms-loader
    v-if="loader"
    :size="'lg'"
    :container="false"
    :height="'100%'"
    class="me-4 mt-2"
  />
  <div class="d-flex flex-row">
    <pms-button
      class="p-0 m-0 px-2 me-1"
      :variant="'tonal'"
      :color="'secondary'"
      :disabled="loader"
      @click="previousIndex"
    >
      <pms-kt-icon
        :icon-name="'arrow-left'"
        class="fs-2 m-0 p-0 text-primary"
      />
    </pms-button>
    <select
      v-model="currentTableHeaderIndex"
      class="form-select form-select-sm w-200px"
    >
      <template
        v-for="item in tableHeaders.options"
        :key="item"
      >
        <option :value="item.value">
          {{ item.label }}
        </option>
      </template>
    </select>
    <pms-button
      class="p-0 m-0 px-2 ms-1"
      :variant="'tonal'"
      :color="'secondary'"
      :disabled="loader"
      @click="nextIndex"
    >
      <pms-kt-icon
        :icon-name="'arrow-right'"
        class="fs-2 m-0 p-0 text-primary"
      />
    </pms-button>
  </div>
</template>
<script setup lang="ts">
import { useLoader } from '@shared/composable'
import { useAttendance } from '../composable'
import { watch } from 'vue'
import { getWeekNumber } from '../utils/getWeekNumber'

const { isActiveOneOf } = useLoader()
const { currentTableHeaderIndex, tableHeaders, nextIndex, previousIndex } = useAttendance()

const loader = isActiveOneOf(['academic/courses/attendances', 'academic/courses/attendances/reset'])

watch(tableHeaders.value.headers, () => {
  const current_week_num = getWeekNumber(new Date())
  for (let i = 0; i < tableHeaders.value.headers.length; i++) {
    let found = false
    for (let j = 0; j < tableHeaders.value.headers[i].length; j++) {
      if (getWeekNumber(new Date(tableHeaders.value.headers[i][j].date)) === current_week_num) {
        found = true
        break
      }
    }
    if (found) {
      currentTableHeaderIndex.value = i
      break
    }
  }
}, { immediate: true })
</script>
