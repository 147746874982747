import { SelectField } from '@/shared/types'
import { KnowledgeManageResourceResponse } from '@shared/swagger'

export default (data: KnowledgeManageResourceResponse['languages']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((language) => {
    options.push({
      label: `${language.short}`,
      value: language.id
    })
  })

  return options
}
