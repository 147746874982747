<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="back()"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <h1 class="text-dark fs-2 fw-bold mb-1">
          {{ $t('domains.academic.manage.curricula.select-track', { program: `${currentProgram?.title} (${currentProgram?.code})`}) }}
        </h1>
      </div>
    </div>
    <p class="fs-6 mt-3 mb-4">
      {{ $t('domains.academic.manage.curricula.select-track-description') }}
    </p>

    <pms-alert>
      <template #title>
        <div>
          {{ $t('common.selected-department') }}:
          <span
            v-if="currentDepartment"
            class="text-decoration-underline fw-bold"
          >
            {{ currentDepartment.title }} ({{ currentDepartment.code }})
          </span>
        </div>
        <div class="mt-1">
          {{ $t('common.selected-program') }}:
          <span
            v-if="currentProgram"
            class="text-decoration-underline fw-semibold"
          >
            {{ currentProgram.title }} ({{ currentProgram.code }} - {{ currentProgram.cipher }})
          </span>
        </div>
      </template>
    </pms-alert>

    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      :items="tracks"
      :headers="headers"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #program_type="{item}">
        {{ item.type }}
      </template>
      <template #title="{ item }">
        <span class="fs-5 fw-bold">{{ item.title }}</span>
      </template>
      <template #program="{ item }">
        <span>
          {{ item.program.type }} ({{ item.program.code }})
        </span>
      </template>
      <template #action="{ item }">
        <div class="d-flex gap-5">
          <PermissionCheck :permission-code="permissionCodes.show_track_curricula.code">
            <router-link
              class="btn btn-sm btn-light-primary w-150px"
              :to="{
                name: 'AcademicCurriculaViewPage',
                query: {
                  year: store.getYear,
                  department: store.getDepartment,
                  program: store.getProgram,
                  track: item.id
                }
              }"
            >
              {{ $t('common.view-curricula') }}
              <pms-kt-icon
                icon-name="arrow-right"
                class="ms-2 m-0 p-0"
              />
            </router-link>
          </PermissionCheck>
          <PermissionCheck :permission-code="permissionCodes.show_working_curriculla_curriculla.code">
            <router-link
              class="btn btn-sm btn-light-primary w-150px"
              :to="{
                name: 'WorkingCurriculaPage',
                params: {
                  trackId: item.id
                }
              }"
            >
              {{ $t('domains.academic.curricula.working-curricula-link') }}
              <pms-kt-icon
                icon-name="arrow-right"
                class="ms-2 m-0 p-0"
              />
            </router-link>
          </PermissionCheck>
        </div>
      </template>
    </pms-smart-table>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useLoader } from '@shared/composable'

import { useStore } from '../store'
import { tracksTable as headers, trackStepQuickTourSteps } from '../values'
import permissionCodes from '@/shared/values/permissionCodes'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const store = useStore()

const loader = isActive('academic/manage/curriculum/tracks')
const tracks = computed(() => store.getTracks)

const currentDepartment = computed(() => store.getDepartments.find((dep) => dep.code === store.getDepartment))
const currentProgram = computed(() => store.getPrograms.find((prog) => prog.code === store.getProgram))
const back = () => {
  store.setCurrentStep('program')
}

onMounted(async () => {
  setQuickTourSteps(await trackStepQuickTourSteps())
})
</script>
