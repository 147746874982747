<template>
  <div>
    <PermissionCheck :permission-code="permissionCodes.show_syllabus.code">
      <syllabus-component />
    </PermissionCheck>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useDriver } from '@shared/composable'

import SyllabusComponent from './ui/SyllabusComponent.vue'
import { quickTourSteps } from './values'

import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'

const { setSteps: setQuickTourSteps } = useDriver()

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
