import type { StoreType } from '../types'
import type { SectionManagementRequestGetRequest } from '@shared/swagger'

import { getSectionManagementRequests } from '@shared/api'

export default async function fetchSentRequests (this: StoreType, values: SectionManagementRequestGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementRequests(values)

  if (ok) {
    this.setSentRequests(data)
  } else {
    this.setSentRequests([])
  }
}
