<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div class="w-100 position-relative mb-5">
        <div
          :key="'information.course-description'"
          class="fs-2 fw-bold text-danger text-center flex-fill"
        >
          {{ getMessage('information.instructors') }}
        </div>
        <button
          v-if="amIEnrolled && has_permission"
          type="button"
          class="btn btn-light-primary p-2 position-absolute top-0 end-0"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addInstructor"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </div>
      <pms-smart-table
        :items="instructors"
        :headers="header"
        :sortable="false"
        :options="{ design: 'bordered-dashed' }"
      >
        <template
          v-for="(title, index) in instructorsTable.map((el) => el.slotName)"
          :key="index"
          #[title]="{ item }"
        >
          {{ getMessage(item.title) }}
        </template>
        <template #name="{ item }">
          {{ item.full_name }}
        </template>
        <template #degree="{ item }">
          {{ item.degree }}
        </template>
        <template #item-contacts="{ item }">
          <span class="fw-bold">
            {{ item }}
          </span>
        </template>
        <template #action="{ item }">
          <button
            v-if="has_permission"
            type="button"
            class="btn btn-light-danger px-2 py-2"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-dismiss-="click"
            :data-bs-original-title="$t('domains.academic.editor.courses.tooltip.delete')"
            @click="deleteInstructor(item.id)"
          >
            <pms-kt-icon
              :icon-name="'trash'"
              class="fw-bold fs-3 p-0"
            />
          </button>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <StaffListSearch v-if="modifier && modifier.name === 'syllabus.instructors'" />
  <SyllabusMoodleLinks />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { computed } from 'vue'
import { instructorsTable, has_permission } from '../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import StaffListSearch from './../features/StaffListSearch/StaffListSearch.vue'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'
import SyllabusMoodleLinks from './SyllabusMoodleLinks.vue'

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus/instructors')
const { setModifier, open, modifier } = useTeleportModal()

const store = useStore()
const alert = useAlert()
const instructors = computed(() => store.getInstructors)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...instructorsTable, {
      title: 'common.action',
      field: 'action',
      slotCellName: 'action',
      headerClass: 'w-50px',
      cellClass: 'text-center'
    }]
  : instructorsTable)

function addInstructor () {
  setModifier(
    'syllabus.instructors',
    { mode: 'add' },
    { title: 'instructors.title.add', description: 'instructors.description.add' }
  )

  open({ name: 'syllabus-form-content-modal' })
}
function deleteInstructor (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-reading',
      onConfirm: async () => {
        await store.deleteInstructor({ instructor_id: id })
      }
    }
  )
}
</script>
