import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManageCategoriesPostRequest, UnknownResponse } from '@shared/swagger'

export default async function putKnowledgeManageCategory (uuid: string, body: KnowledgeManageCategoriesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<KnowledgeManageCategoriesPostRequest, UnknownResponse>({
    url: `knowledge/manage/categories/${uuid}`,
    body,
    loader: 'knowledge/manage/categories/edit-category'
  })
  return response
}
