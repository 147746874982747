import type { StoreType } from '../types'
import { getAcademicSingleStudentAdvisorOpinion } from '@shared/api'

export default async function fetchAdvisorOpinion (this: StoreType, student: string, opinion: string): Promise<void> {
  const { data, ok } = await getAcademicSingleStudentAdvisorOpinion(student, opinion)

  if (ok) {
    this.setAdvisorOpinion(data)
  }
}
