import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useRulesAndRegulations } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useRulesAndRegulations()
  await store.bootstrap(to)
  next()
}

export default {
  layout: 'default',
  path: '/administrative/rules-and-regulations',
  children: [
    {
      path: '',
      name: 'RulesAndRegulationsPage',
      component: () => import('./RulesAndRegulationsPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'create',
      name: 'CreateNewRulePage',
      component: () => import('./features/CreateNewRule/CreateNewRulePage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: ':id',
      name: 'EditRulePage',
      component: () => import('./features/EditExistingRule/EditRulePage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
