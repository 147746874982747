import type { StatusMessageResponse } from '@shared/types'
import type{ AcademicInformationExamDatesAssessmentPutRequest } from '@shared/swagger'
import { putAcademicInformationExamDatesAssessment } from '@shared/api'
import type { StoreType } from '../types'

export default async function putSingleDate (this: StoreType, id: number, values: AcademicInformationExamDatesAssessmentPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicInformationExamDatesAssessment(id, values)
  ok && this.fetchExamDates(this.selectedDepartment)

  return {
    status: ok,
    message
  }
}
