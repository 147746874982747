import { SmartTableHeader, SmartTableOptions } from '@/shared/types'

export const additional_info_table_headers: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.advisor',
    field: 'advisor',
    slotCellName: 'advisor'
  },
  {
    title: 'common.table.student-id',
    field: 'id'
  },
  {
    title: 'common.table.student-fullname',
    field: 'full_name'
  },
  {
    title: 'common.table.class',
    field: 'class'
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    slotCellName: 'speciality'
  }

]

export const additional_info_table_options: SmartTableOptions = {
  table: {
    class: 'table-bordered'
  },
  headers: {
    style: 'background-color:none!important'
  }
}
