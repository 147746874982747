import { useRequests } from '@shared/composable'

import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumPostReferenceRequest } from '@shared/swagger'

export default async function addReference (params: AcademicManageCurriculumPostReferenceRequest): Promise<StatusMessageResponse> {
  const { post } = useRequests()

  const { ok, message } = await post({
    url: 'academic/manage/curriculum/references',
    body: params,
    loader: 'post-academic/manage/curriculum/references'
  })

  return {
    status: ok,
    message
  }
}
