<template>
  <div
    v-if="displaySidebar"
    id="kt_app_sidebar"
    ref="sidebarRef"
    class="app-sidebar flex-column"
    data-kt-drawer="true"
    data-kt-drawer-name="app-sidebar"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="350px"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
  >
    <SidebarLogo :sidebar-ref="sidebarRef" />
    <SidebarMenu />
    <SidebarFooter />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { displaySidebar } from '@shared-composable/pattern'

import SidebarLogo from './ui/SidebarLogo.vue'
import SidebarMenu from './ui/SidebarMenu.vue'
import SidebarFooter from './ui/SidebarFooter.vue'

const sidebarRef = ref<HTMLFormElement | null>(null)
</script>
