import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCurriculumProgramsRequest, AcademicManageCurriculumProgramsResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicManageCurriculumPrograms (params: AcademicManageCurriculumProgramsRequest): Promise<AxiosResponse<AcademicManageCurriculumProgramsResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumProgramsResponse>({
    url: 'academic/manage/curriculum/programs',
    body: { params },
    loader: 'academic/manage/curriculum/programs'
  })

  return response
}
