import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicStudentsMyResourcesGetResponse
} from '@shared/swagger'

export default async function getAcademicStudentsMyResources (): Promise<AxiosResponse<AcademicStudentsMyResourcesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicStudentsMyResourcesGetResponse>({
    url: 'academic/students/my/resources',
    body: {},
    loader: 'academic/students/my/resources'
  })

  return response
}
