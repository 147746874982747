<template>
  <div
    v-for="(item, index) in props.items"
    :key="index"
    class="row"
  >
    <pms-divider
      v-if="index !== 0"
      :design="'dashed'"
      :margin-y="4"
      class="mt-6"
    />
    <div class="col-12 col-md-1">
      <span
        v-test-id="'version'"
        class="fs-3 badge fw-bolder px-2 badge-primary text-hover-primary shadow-sm mr-4 mb-4"
      >{{ item.version }}</span>
    </div>
    <div class="col-12 col-md-10">
      <h4 class="milestone">
        <span
          v-test-id="'title'"
          class="text-dark fw-bolder pb-3 h3"
        >{{ item.title }}</span>
      </h4>
      <h6 class="fw-bolder text-dark mb-4 mt-4">
        {{ $t("domains.release.changelog.version-summary") }}
      </h6>
      <!-- eslint-disable vue/no-v-html -->
      <p
        v-test-id="'content'"
        v-html="item.content"
      />
      <pms-row>
        <pms-grid :col="6">
          <h6 class="fw-bolder text-dark mb-2">
            {{ $t("domains.release.changelog.developers") }}
          </h6>
          <ul class="fs-6 fw-bold">
            <li>
              <span
                v-test-id="'author'"
                class="text-gray-700"
              >
                <strong>{{ $t("domains.release.changelog.author") }}: </strong> {{ item.author }}
              </span>
            </li>
            <li class="my-2">
              <span
                v-test-id="'contributors'"
                class="text-gray-700"
              >
                <strong>{{ $t("domains.release.changelog.contributors") }}: </strong> {{ item.contributors }}
              </span>
            </li>
          </ul>
        </pms-grid>
        <pms-grid :col="6">
          <h6 class="fw-bolder text-dark mb-2">
            {{ $t("domains.release.changelog.log-history.title") }}
          </h6>
          <ul class="fs-6 fw-bold">
            <li>
              <span
                v-test-id="'created'"
                class="text-gray-700"
              >
                <strong>{{ $t("domains.release.changelog.log-history.published") }}: </strong>
                {{ item.created_at }}
              </span>
            </li>
            <li class="my-2">
              <span
                v-test-id="'updated'"
                class="text-gray-700"
              >
                <strong>{{ $t("domains.release.changelog.log-history.last-updated") }}: </strong>
                {{ item.updated_at }}
              </span>
            </li>
          </ul>
        </pms-grid>
      </pms-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ReleaseChangelogResponse } from '@shared/swagger'

const props = defineProps<{
  items: ReleaseChangelogResponse
}>()
</script>
