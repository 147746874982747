<template>
  <pms-card
    :title="'domains.knowledge.all-posts.popular.title'"
    :description="'domains.knowledge.all-posts.popular.description'"
    :options="{ body: { class: 'pt-0' }}"
  >
    <template
      v-for="(post, index) in popularContent"
      :key="post.uuid"
    >
      <div :class="{'mt-4': index !== 0 }">
        <p class="fs-5 fw-bold mb-0">
          {{ post.title }}
        </p>
        <p class="fs-5 text-muted mb-1">
          {{ cutContent(post.description, { endWith: '...', length: 100, removeHtmlTags: true }) }}
        </p>
        <KnowledgeBasePostLink :uuid="post.uuid" />
      </div>
    </template>
    <pms-no-data v-if="popularContent.length === 0" />
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useResource } from '@shared/stores'
import { cutContent } from '@shared/utils'

import KnowledgeBasePostLink from './KnowledgeBasePostLink.vue'

const resource = useResource()
const popularContent = computed(() => resource.getKnowledgeResourcePopular.slice(0, 5))
</script>
