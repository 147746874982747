import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'
import api from '@/shared/api/ScheduleManagement/makeProgramAsMain'

export default async function makeProgramAsMain (this: StoreType, section: number, program: number): Promise<StatusMessageResponse> {
  const { ok, message } = await api(section, program)

  if (ok) await this.fetchSingleSectionPrograms(section.toString())

  return {
    status: ok,
    message
  }
}
