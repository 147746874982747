import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue'

const autoCompleteStyle = `
.autocomplete {
  position: relative;
}
.autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 100%;
  right: 0;
  margin-top: 5px;
}
.autocomplete-items div {
  padding: 7px;
  cursor: pointer;
}
.autocomplete-items div:hover {
  color: var(--kt-menu-link-color-hover);
  background-color: var(--kt-menu-link-bg-color-hover);
}

.autocomplete-active {
  background-color: DodgerBlue !important;
  border-bottom: none !important;
  color: #ffffff !important;
}

.auto-item {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--bs-gray-500);
}

.auto-item:last-child {
  border-bottom: none;
}
`
const InputAutocomplete = defineComponent({
  name: 'InputAutocomplete',
  props: {
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    inputClass: {
      type: String,
      default: 'form-control'
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    suggestionClass: {
      type: String,
      default: 'mh-200px'
    },
    suggestionItemClass: {
      type: String,
      default: ''
    },
    showBurger: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const currentFocus = ref(-1)
    const isListActive = ref(false)

    const clearItems = () => {
      const autoDiv = document.getElementById(`${props.name}-auto`)
      autoDiv?.childNodes.forEach((node) => {
        if (node.nodeName === 'DIV') {
          autoDiv.removeChild(node)
          isListActive.value = false
        }
      })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateValue = (e: any) => {
      if (e.inputType) {
        const value = e.target.value
        clearItems()
        emit('update:modelValue', value)

        if (!value) return false
        currentFocus.value = -1
        const autoDiv = document.getElementById(`${props.name}-auto`)
        const divEl = document.createElement('div')

        divEl.setAttribute('id', props.name + '-suggestion-list')
        divEl.setAttribute('class', `autocomplete-items card shadow-sm scroll-y ${props.suggestionClass}`)

        autoDiv?.appendChild(divEl)
        isListActive.value = true

        props.suggestions.forEach((item: string) => {
          if (item.toLowerCase().includes(value.toLowerCase())) {
            const div = document.createElement('div')
            const starIndex = item.toLowerCase().indexOf(value.toLowerCase())
            const start = item.substr(0, starIndex)
            const middle = item.substr(starIndex, value.length)
            const end = item.substr(starIndex + value.length)

            div.setAttribute('class', `auto-item fs-6 px-6 py-3 ${props.suggestionItemClass}`)
            div.innerHTML = `${start}<strong>${middle}</strong>${end}`

            div.addEventListener('click', () => {
              emit('update:modelValue', item)
              clearItems()
            })
            divEl.appendChild(div)
          }
        })
      }
    }

    const onBurgerClick = (e) => {
      e.stopPropagation()
      if (!isListActive.value) {
        drawAllSuggestions()
      } else {
        clearItems()
        isListActive.value = false
      }
    }

    const drawAllSuggestions = () => {
      clearItems()

      currentFocus.value = -1
      const autoDiv = document.getElementById(`${props.name}-auto`)
      const divEl = document.createElement('div')

      divEl.setAttribute('id', props.name + '-suggestion-list')
      divEl.setAttribute('class', `autocomplete-items card shadow-sm scroll-y ${props.suggestionClass}`)

      autoDiv?.appendChild(divEl)
      isListActive.value = true

      props.suggestions.forEach((item: string) => {
        const div = document.createElement('div')
        div.setAttribute('class', `auto-item fs-6 px-6 py-3 ${props.suggestionItemClass}`)
        div.innerHTML = `${item}`
        div.addEventListener('click', () => {
          emit('update:modelValue', item)
          clearItems()
        })
        divEl.appendChild(div)
      })
    }

    const keyDownEvent = (e) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let x: any = document.getElementById(props.name + '-suggestion-list')
      if (e.keyCode === 40 && !x) drawAllSuggestions()
      if (x) x = x.getElementsByTagName('div')

      if (e.keyCode === 40) {
        currentFocus.value++
        addActive(x)
      } else if (e.keyCode === 38) {
        currentFocus.value--
        addActive(x)
      } else if (e.keyCode === 13) {
        e.preventDefault()
        if (currentFocus.value > -1) {
          if (x) {
            x[currentFocus.value].click()
          }
        }
      }
    }

    const addActive = (x) => {
      if (!x) return false

      removeActive(x)
      if (currentFocus.value >= x.length) currentFocus.value = 0
      if (currentFocus.value < 0) currentFocus.value = (x.length - 1)

      x[currentFocus.value]?.classList.add('autocomplete-active')
      x[currentFocus.value]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    const removeActive = (x) => {
      for (let i = 0; i < x.length; i++) {
        x[i].classList.remove('autocomplete-active')
      }
    }

    onMounted(() => {
      document.addEventListener(
        'click',
        function () { clearItems() }
      )
    })

    onUnmounted(() => {
      document.removeEventListener(
        'click',
        function () { clearItems() }
      )
    })

    return () => (
      <div>
        <style>{autoCompleteStyle}</style>
        <div class="autocomplete" id={`${props.name}-auto`}>
          <input
            id={`${props.name}-input`}
            class={props.inputClass} type="text"
            placeholder={props.placeholder}
            value={props.modelValue}
            name={props.name}
            autocomplete="off"
            onInput={updateValue}
            onKeydown={keyDownEvent}
            disabled={props.disabled}
          />
          {
            (props.showBurger && props.suggestions.length > 0) && (
              <a
                href='javascript:void(0)'
                class="position-absolute top-0 end-0 me-3 mt-3"
                onClick={onBurgerClick}
              >
                <i class="bi bi-list fs-1"></i>
              </a>
            )
          }
        </div>
      </div>
    )
  }
})

export default InputAutocomplete
