<template>
  <Teleport to="#schedule-management-modal-content">
    <div>
      <div>
        <strong>{{ t('domains.schedule-management.program') }}: </strong><span>{{ program?.title }}</span>
      </div>
      <div>
        <strong>{{ t('domains.schedule-management.section-program.program-date-range') }}: </strong>{{ program?.start_date + ' - ' + program?.end_date }}<span />
      </div>
    </div>
    <pms-tab
      v-model:active="activeTab"
      :all="false"
      :tabs="scheduleFormTabs"
      class="mw-100 mt-2"
    >
      <template #weekly>
        <!-- filter -->
        <pms-row>
          <pms-grid :col="6">
            <div
              class="d-flex align-items-center border-bottom min-h-50px"
            >
              <div class="text-dark fw-bold min-w-75px">
                {{ $t('building') }}
              </div>
              <div class="d-flex align-items-center pe-2">
                <select
                  class="form-select form-select w-175px"
                  @change="setSelectedBuilding(($event.target as any ).value)"
                >
                  <option :value="0">
                    {{ t('common.not-selected') }}
                  </option>
                  <option
                    v-for="building in buildings"
                    :key="(building.key)"
                    :value="building.key"
                    :selected="selectedBuilding?.key === building.key"
                  >
                    {{ building.title }}
                  </option>
                </select>
              </div>
            </div>
          </pms-grid>
          <pms-grid :col="6">
            <div
              class="d-flex align-items-center border-bottom min-h-50px"
            >
              <div class="text-dark fw-bold min-w-75px">
                {{ $t('room') }}
              </div>
              <div class="d-flex align-items-center pe-2">
                <select
                  class="form-select form-select w-175px"
                  @change="onChangeRoom(($event.target as any ).value)"
                >
                  <option :value="0">
                    {{ t('common.not-selected') }}
                  </option>
                  <option
                    v-for="room in rooms"
                    :key="room.id"
                    :selected="selectedRoomId !== null && +room.id === +selectedRoomId"
                    :value="room.id"
                  >
                    {{ room.room }}
                  </option>
                </select>
              </div>
            </div>
          </pms-grid>
        </pms-row>
        <pms-divider />
        <!--  -->
        <pms-page-shimmer
          v-if="loaderWeekly"
          :shimmer="'table'"
          :full-width="true"
        />
        <div
          v-else
          :header="false"
          :options="{
            card: {class: 'rounded-3 shadow-sm'},
            body: {class: 'p-0 rounded-3'}
          }"
        >
          <div
            v-if="selectedBuilding && selectedRoomId && selectedRoomId > 0"
            class="table-responsive"
          >
            <ScheduleTable />
          </div>
        </div>
      </template>
      <template #hour>
        <pms-loader v-if="loaderHours" />
        <HourTable
          v-else
        />
      </template>
    </pms-tab>
  </Teleport>
</template>
<script setup lang="ts">
import { watch, ref, onMounted, onUnmounted } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import { scheduleFormTabs } from '../../values'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { ScheduleManagementSingleSectionProgramsGetResponse } from '@/shared/swagger'
import ScheduleTable from '../ScheduleTable/ScheduleTable.vue'
import HourTable from './ui/HourTable/HourTable.vue'

const { show, modifier } = useTeleportModal()

onUnmounted(() => {
  selectedRoomId.value = null
})

const { isActive } = useLoader()
const loaderWeekly = isActive('get-academic/manage/schedules/sections-single/programs-single/hours')
const loaderHours = isActive('')

const store = useScheduleManagement()
const { singleSection, selectedRoomId, singleSectionPrograms, teachingHours } = storeToRefs(store)
const buildings = store.buildings

const { t } = useI18n()

const program = ref<ScheduleManagementSingleSectionProgramsGetResponse[0] | undefined>()
const selectedBuilding = ref<typeof buildings[0] | null>(null)
const setSelectedBuilding = (buildingKey: typeof buildings[0]['key']) => {
  selectedBuilding.value = buildings.find((building) => building.key === buildingKey) ?? null
}

const onChangeRoom = (roomId: typeof buildings[0]['rooms'][0]['id']) => {
  selectedRoomId.value = roomId
  if (!singleSection.value || roomId.toString() === '0' || !program.value) return

  store.fetchScheduleManagementSectionProgramHours({
    program: program.value.id,
    room_id: roomId,
    section: singleSection.value.id
  })
}

const rooms = ref<typeof buildings[0]['rooms']>([])

watch(() => selectedBuilding.value, () => {
  rooms.value = selectedBuilding.value ? selectedBuilding.value.rooms : []
})

const activeTab = ref<string>('weekly')

onMounted(() => {
  if (singleSection.value?.id && program.value) {
    store.fetchTeachingHours({
      section: singleSection.value.id,
      program: program.value.id
    })
  }
})

watch(() => modifier.value, () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  program.value = singleSectionPrograms.value.find(item => item.id === (modifier.value as any).data.id)
  selectedRoomId.value = null
  show()
},
{ immediate: true }
)
</script>
