<template>
  <div class="text-dark fw-bold min-w-75px">
    {{ $t('domains.service.message-service.new-message.program.title') }}:
  </div>
  <div class="d-flex align-items-center border-bottom min-h-50px col-12 w-100">
    <treeselect
      v-model="selectedValue"
      value-format="object"
      :multiple="true"
      :options="treeData"
      class="my-2 styled"
    />
  </div>
</template>
<script setup lang="ts">
import Treeselect from 'vue3-treeselect'
import { useProgramsField } from '../composables'
import { watch } from 'vue'

const { selectedValue, selectedPrograms, treeData, addChildProgram, addProgram, delimiter } = useProgramsField()

watch(selectedValue, () => {
  selectedPrograms.value = []
  selectedValue.value.forEach(program => {
    const type = program.id.split(delimiter)[0]
    if (type === 'department') {
      program.children?.forEach(addProgram)
    } else if (type === 'program') {
      addProgram(program)
    } else {
      addChildProgram(program)
    }
  })
})

</script>
