import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function deleteAcademicManageSyllabusAssessments (id: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: `academic/manage/syllabus/assessments/${id}`,
    body: {},
    loader: 'academic/manage/syllabus/assessments'
  })
  return response
}
