import type { StoreType } from '../types'
import type { AcademicSingleCourseIntegrationWithMoodleRequest } from '@shared/swagger'

import { useAlert } from '@shared/composable'
import { getAcademicSectionIntegrationWithMoodle } from '@/shared/api'

export default async function integrateSectionWithMoodle (this: StoreType, params: AcademicSingleCourseIntegrationWithMoodleRequest): Promise<void> {
  const { error, success } = useAlert()
  const { ok, message } = await getAcademicSectionIntegrationWithMoodle(params)
  ok
    ? success({ text: message, isToast: true })
    : error({ text: message })
}
