import type {
  AcademicStudentsResourceResponse
} from '@/shared/swagger'
import { SpecialtyMapped } from '../types'

export default (specialities: AcademicStudentsResourceResponse['specialities']): SpecialtyMapped => {
  const departmentMap = new Map()

  specialities.forEach(speciality => {
    const dep = speciality.department
    if (!departmentMap.has(dep.id)) {
      departmentMap.set(dep.id, {
        id: dep.id,
        label: dep.title,
        key: dep.code,
        children: []
      })
    }

    departmentMap.get(dep.id).children.push({
      id: speciality.speciality_code,
      label: `${speciality.program_code} - ${speciality.language_code} : ${speciality.speciality_code} ${speciality.title} [${speciality.terms}]`,
      key: speciality.program_code
    })
  })
  const specialtyMapped = Array.from(departmentMap.values())
  return specialtyMapped
}
