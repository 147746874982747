import type { StoreType } from '../types'
import type { AcademicManageSyllabusGetRequest } from '@shared/swagger'

import { getAcademicManageSyllabus } from '@shared/api'
import { useStore } from '../../features/SyllabusPage/features/EditorSyllabus/store'

export default async function fetchSyllabus (this: StoreType, values: AcademicManageSyllabusGetRequest): Promise<void> {
  const editorSyllabusStore = useStore()
  const { data, ok, message } = await getAcademicManageSyllabus(values)

  if (ok) {
    this.setSyllabus(data)
    editorSyllabusStore.fetchSyllabus(message)
  }
}
