import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileSettingsPrivaciesContactPutRequest } from '@shared/swagger'

export default async function putProfileSettingsPrivaciesContact (values: ProfileSettingsPrivaciesContactPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileSettingsPrivaciesContactPutRequest, UnknownResponse>({
    url: 'profile/settings/privacies/contacts',
    body: values,
    loader: 'profile/settings/privacies/contact-edit'
  })

  return response
}
