import type { StoreType } from '../types'

import { useAlert } from '@shared/composable'
import { getSingleReport } from '@shared/api'
import { AcademicReportGetRequest } from '@/shared/swagger'

export default async function (this: StoreType, params:AcademicReportGetRequest): Promise<void> {
  const { error } = useAlert()
  const { ok, message, data } = await getSingleReport(params)
  ok
    ? this.setSingleReport(data)
    : error({ text: message })
}
