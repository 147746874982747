import { Placement } from '../types'

export const tablePlacement: Array<Placement> = [
  {
    id: 'left',
    rowName: 'info',
    translate: 'information',
    rowClass: 'col-md-6',
    tableData: [
      {
        field: 'courseCode',
        translate: 'course-code',
        custom: 'courseCode'
      },
      {
        field: 'equCode',
        translate: 'equ-code'
      },
      {
        field: 'title',
        translate: 'title',
        custom: 'title'
      },
      {
        field: 'department',
        translate: 'department',
        custom: 'department'
      },
      {
        field: 'periodCode',
        translate: 'period-code'
      },
      {
        field: 'term',
        translate: 'semester'
      },
      {
        field: 'createDate',
        translate: 'create-date',
        custom: 'createDate'
      }
    ]
  },
  {
    id: 'right',
    rowName: 'details',
    translate: 'details',
    rowClass: 'col-md-6',
    tableData: [
      {
        field: 'credit',
        translate: 'credit',
        custom: 'credit'
      },
      {
        field: 'language',
        translate: 'language',
        custom: 'language'
      },
      {
        field: 'type',
        translate: 'type'
      },
      {
        field: 'gradingType',
        translate: 'grading-type'
      }
    ]
  }
]
