<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { useResource } from '@shared/stores'
import { academicSyllabusReadingToOptions, academicSyllabusReadingTitleToId } from './../utils'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { SyllabusFormMode } from './../types'
import { useI18n } from 'vue-i18n'
import { objectDeepCopy } from '@shared/utils'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const { t } = useI18n()
const store = useStore()
const resource = useResource()

const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['readings'][0] & SyllabusFormMode> = {
  id: -1,
  type: '',
  title: '',
  authors: '',
  publish_year: null,
  isbn: '',
  website: '',
  mode: 'add'
}

const tempValue = ref()

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('common.form.type'),
      class: 'mb-1 mt-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'type_id',
    type: 'select',
    options: academicSyllabusReadingToOptions(resource.getAcademicManageSyllabusResourceReadings),
    value: academicSyllabusReadingTitleToId(tempValue.value.type)
  },
  {
    label: {
      text: t('common.form.authors'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'authors',
    type: 'textarea',
    value: tempValue.value.authors
  },
  {
    label: {
      text: t('common.form.title'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'textarea',
    value: tempValue.value.title
  },
  {
    label: {
      text: t('common.form.publisher'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'publisher',
    type: 'textarea',
    value: tempValue.value.publisher
  },
  {
    label: {
      text: t('common.form.publish_year'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'publish_year',
    type: 'textarea',
    value: tempValue.value.publish_year
  },
  {
    label: {
      text: t('common.form.isbn'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'isbn',
    type: 'textarea',
    value: tempValue.value.isbn
  },
  {
    label: {
      text: t('common.form.website'),
      class: 'mb-1'
    },
    group: {
      class: 'mb-4'
    },
    name: 'url',
    type: 'textarea',
    value: tempValue.value.website
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (newValue.data.mode === 'edit' && newValue.data.id) {
        const item = store.getReadings.find((item) => item.id === newValue.data?.id)
        tempValue.value = { mode: newValue.data.mode, ...(item || defaultValue) }
      }
      if (newValue.data.mode === 'add') {
        tempValue.value = objectDeepCopy(defaultValue)
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'edit') {
    store.editReading({
      type_id: values.type_id,
      authors: values.authors,
      publisher: values.publisher,
      publish_year: values.publish_year,
      isbn: values.isbn,
      url: values.url,
      title: values.title
    }, tempValue.value.id)
  }
  if (tempValue.value.mode === 'add') {
    store.addReading({
      type_id: values.type_id,
      authors: values.authors,
      publisher: values.publisher,
      publish_year: values.publish_year,
      isbn: values.isbn,
      url: values.url,
      title: values.title
    })
  }
  close({ name: 'syllabus-form-content-modal' })
  unsetModifier()
}
</script>
