import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicSingleCourseAttendancesDeleteRequest, UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function destroyAcademicCoursesAttendances (params: AcademicSingleCourseAttendancesDeleteRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownRequest, UnknownResponse>({
    url: 'academic/courses/attendances',
    body: { params },
    loader: 'academic/courses/attendances[delete]'
  })

  return response
}
