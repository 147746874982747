import { ref } from 'vue'
import { GradesDetailsGraph } from '../types'
import { addedGrades, bellCurvedGrades } from './refs'

export const gradesGraphOptions = ref({
  chart: {
    fontFamily: 'inherit',
    type: 'bar',
    height: 350,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      vertical: true,
      distributed: true,
      barHeight: '40%',
      dataLabels: {
        position: 'top',
        margin: 0,
        padding: 0
      }
    }
  },
  dataLabels: {
    enabled: true,
    offsetX: 0,
    offsetY: 0,
    formatter: function (val) {
      return val
    },
    style: {
      fontSize: '14px',
      fontWeight: '600',
      align: 'center'
    }
  },
  legend: {
    show: false
  },
  colors: ['#3E97FF', '#3E97FF', '#3E97FF', '#3E97FF', '#3E97FF'],
  yaxis: {
    labels: {
      formatter: function (val) {
        return val
      },
      style: {
        colors: ['var(--bs-gray-800)'],
        fontSize: '14px',
        fontWeight: '600',
        align: 'left'
      }
    },
    axisBorder: {
      show: false
    }
  },
  xaxis: {
    categories: [] as string[],
    labels: {
      formatter: function (val) {
        return val
      },
      style: {
        colors: 'var(--bs-gray-800)',
        fontSize: '14px',
        fontWeight: '600'
      },
      offsetY: 2,
      align: 'left'
    }
  },
  grid: {
    borderColor: 'rgba(0,0,0,0.2)',
    yaxis: {
      lines: {
        show: true
      }
    },
    xaxis: {
      lines: {
        show: false
      }
    },
    strokeDashArray: 4
  },
  tooltip: {
    style: {
      fontSize: '14px'
    },
    y: {
      formatter: function (val) {
        return val + '%'
      }
    }
  }
})

export const gradesGrapData = ref<GradesDetailsGraph>({
  series: {
    data: [],
    name: 'Grades'
  },
  letters: [],
  ranges: []
})

export const setGradesGrapData = (gradesGrap: GradesDetailsGraph): void => {
  gradesGrapData.value = gradesGrap
}

export const updateCategories = (
  value: GradesDetailsGraph,
  isLetter = false
) => {
  gradesGraphOptions.value = {
    ...gradesGraphOptions.value,
    xaxis: {
      ...gradesGraphOptions.value.xaxis,
      categories: isLetter ? value.letters : value.ranges
    }
  }
}

export const getGrades = (): number[] => {
  return (
    bellCurvedGrades.value.assessment_id
      ? bellCurvedGrades.value.grades.map((g) => g.currentGrade)
      : addedGrades.value.grades.map((g) => g.grade)
  ).filter((g) => g || g === 0)
}
