import { SmartTableHeader } from '@/shared/types'

export const courseDetails: Array<SmartTableHeader> = [
  {
    title: 'common.code',
    field: 'course_code',
    slotCellName: 'code'
  },
  {
    title: 'common.year',
    field: 'year',
    slotCellName: 'year'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.credits',
    field: 'credits',
    slotCellName: 'credits'
  },
  {
    title: 'common.ects',
    field: 'ects',
    slotCellName: 'ects'
  }
]

export const courseInfo: Array<SmartTableHeader> = [
  {
    title: 'common.language',
    field: 'language_title',
    slotCellName: 'language'
  },
  {
    title: 'common.grading_type',
    field: 'grading_type',
    slotCellName: 'grading_type'
  },
  {
    title: 'common.grading',
    field: 'grading',
    slotCellName: 'grading'
  }
]

export const instructorsTable : Array<SmartTableHeader> = [
  {
    title: 'table-fields.full-name',
    slotName: 'fullName',
    field: 'full_name',
    sortable: true
  },
  {
    title: 'table-fields.degree',
    slotName: 'degree',
    field: 'degree',
    sortable: true
  },
  {
    title: 'table-fields.contacts',
    slotName: 'contacts',
    field: 'email'
  }
]

export const moodleLinksTable : Array<SmartTableHeader> = [
  {
    title: 'common.name',
    field: 'full_name',
    slotCellName: 'name'
  },
  {
    title: 'common.link',
    field: 'moodle_link',
    slotCellName: 'moodle_link'
  }
]

export const academicSkillsTable : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  }

]

export const subjectSkillsTable : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  }
]

export const weeklyPlansTable : Array<SmartTableHeader> = [
  {
    title: 'common.week',
    field: 'week_no',
    slotCellName: 'week',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'common.subject',
    field: 'subject',
    slotCellName: 'subject'
  },
  {
    title: 'common.activity',
    field: 'activity',
    slotCellName: 'activity'
  }
]

export const learningOutcomes : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'table-fields.active-verb',
    slotName: 'action',
    field: 'action',
    sortable: true
  },
  {
    title: 'table-fields.what-will-be-done-produced',
    slotName: 'subject',
    field: 'subject',
    sortable: true
  },
  {
    title: 'table-fields.how-this-learning-outcome-will-be-achieved',
    slotName: 'outcome',
    field: 'outcome',
    sortable: true
  }

]

export const teachingMethodsTable : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },

  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  }

]

export const readingListTable : Array<SmartTableHeader> = [
  {
    title: 'common.type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.authors',
    field: 'authors',
    slotCellName: 'authors'
  },
  {
    title: 'common.publisher',
    field: 'publisher',
    slotCellName: 'publisher'
  },
  {
    title: 'common.publish_year',
    field: 'publish_year',
    slotCellName: 'publish_year'
  },
  {
    title: 'common.isbn',
    field: 'isbn',
    slotCellName: 'isbn'
  },
  {
    title: 'common.website',
    field: 'website',
    slotCellName: 'website'
  }
]

export const assessmentsTable : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'common.common.assessments',
    field: 'assessments',
    slotCellName: 'assessments'
  },
  {
    title: 'common.description',
    field: 'description',
    slotCellName: 'description'
  },
  {
    title: 'common.quantity',
    field: 'quantity',
    slotCellName: 'quantity'
  },
  {
    title: 'common.percentage',
    field: 'percentage',
    slotCellName: 'percentage'
  }
]

export const workflowTable : Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    headerClass: 'w-50px',
    cellClass: 'text-center'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.quantity',
    field: 'quantity',
    slotCellName: 'quantity',
    cellClass: 'text-center'
  },
  {
    title: 'common.hour',
    field: 'hour',
    slotCellName: 'hour',
    cellClass: 'text-center'
  }
]
