<template>
  <pms-page-tour
    :launched="status"
    :steps="steps"
    @launch="launch"
  />
  <pms-container>
    <pms-row class="mt-6">
      <pms-page-header
        v-bind="header"
        @launchTour="launch"
      >
        <template #toolbar>
          <div class="d-flex gap-3 justify-content-between mb-2 mt-md-0">
            <pms-driver-button />
            <pms-feedback-modal :options="[]" />
          </div>
        </template>
      </pms-page-header>
    </pms-row>
    <pms-row>
      <pms-grid :col="9">
        <pms-page-shimmer
          v-if="loader"
          :shimmer="'table'"
          :full-width="true"
        />
        <template v-else>
          <message-list id="service-message-list" />
        </template>
      </pms-grid>
      <pms-grid
        :col="3"
      >
        <message-panel id="service-message-operator" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useTour, useDriver } from '@shared/composable'

import { header, tour, quickTourMessageBroadcast } from './values'

import MessageList from '@domains/ServiceMessageBroadcast/ui/MessageList.vue'
import MessagePanel from '@domains/ServiceMessageBroadcast/ui/MessagePanel.vue'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()
const { status, steps, launch, setSteps } = useTour([])

const loader = isActiveOneOf(['service/message-broadcast', 'service/message-broadcast/resources'])

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTourMessageBroadcast())
})
</script>
