import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordWithLayout } from '@shared-types/common'

import { usePagination } from '@shared/composable'
import { useMessageBroadcast } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useMessageBroadcast()
  const pagination = usePagination()

  pagination.$reset()
  store.$reset()

  const view_mode = to.query.mode as string
  await store.bootstrap(view_mode)

  next()
}

export default {
  layout: 'default',
  path: '/services/message-broadcast',
  children: [
    {
      path: '',
      name: 'MessageBroadcastPage',
      component: () => import('./MessageBroadcastPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
