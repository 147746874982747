<template>
  <pms-card :title="'Total of courses'">
    <pms-smart-table
      class="mt-5"
      :headers="totalHeaders"
      :items="total"
    />
  </pms-card>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { totalHeaders } from '../../CurriculaViewPage/values'
import { ref, watch } from 'vue'
import { useStore } from '@/domains/AcademicCurricula/store'
import { TotalCourse } from '../../CurriculaViewPage/types'
import { getTotalCourse } from '../../CurriculaViewPage/utils'

const store = useStore()
const { coreCoursesByTerm } = storeToRefs(store)

const total = ref<TotalCourse[]>([])

watch(() => coreCoursesByTerm.value, () => {
  if (coreCoursesByTerm.value) {
    total.value = getTotalCourse(
      coreCoursesByTerm.value?.map((coreCourse) => {
        return {
          id: coreCourse.id,
          code: coreCourse.code,
          track: coreCourse.track,
          term: coreCourse.term,
          title: coreCourse.title,
          practice: coreCourse.practice,
          lab: coreCourse.lab,
          credits: coreCourse.credits,
          ects: coreCourse.ects,
          is_active: coreCourse.is_active,
          is_theoretical: coreCourse.is_theoretical,
          requisites: coreCourse.requisites,
          references: coreCourse.references,
          theory: coreCourse.theory
        }
      }
      )
    )
  }
})
</script>
