import { getCSSVariableValue } from '@/assets/ts/_utils'
import { SmartTableHeader } from '@shared/types'

export const attendanceTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code'
  },
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.credits',
    field: 'credits'
  },
  {
    title: 'common.table.ects',
    field: 'ects'
  },
  {
    title: 'common.table.statistics',
    field: 'statistics',
    subHeaders: [
      {
        title: 'common.table.present',
        slotName: 'presentHeader',
        field: 'attended_count'
      },
      {
        title: 'common.table.absent',
        slotName: 'absentHeader',
        field: 'absence_count'
      },
      {
        title: 'common.table.permitted',
        slotName: 'permittedHeader',
        field: 'permitted_count'
      }
    ]
  },
  {
    title: 'common.table.action',
    field: 'action'
  }
]
export const sideBarDynamicsOptions = {
  plotOptions: {
    pie: {
      donut: {
        size: '50%',
        labels: {
          value: {
            fontSize: '10px'
          }
        }
      }
    }
  },
  colors: [
    getCSSVariableValue('--bs-primary'),
    getCSSVariableValue('--bs-danger'),
    getCSSVariableValue('--bs-info'),
    getCSSVariableValue('--bs-light')
  ],
  labels: [],
  legend: {
    show: false,
    fontSize: '12px',
    position: 'right',
    labels: {
      useSeriesColors: true
    },
    markers: {
      size: 0
    },
    formatter: function (seriesName, opts) {
      const percentage: number = opts.w.globals.series[opts.seriesIndex]
      return `${percentage.toFixed(0)}% ${seriesName}`
    },
    itemMargin: {
      vertical: 1
    }
  },
  stroke: { width: 0 },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        show: false
      }
    }
  }]
}
export const detailsTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no'
  },
  {
    title: 'common.table.lesson-date',
    field: 'date',
    slotCellName: 'date'
  },
  {
    title: 'common.table.lesson-start',
    field: 'hour'
  },
  {
    title: 'common.table.room',
    field: 'room'
  },
  {
    title: 'common.table.attendance.title',
    field: 'track'
  }
]
