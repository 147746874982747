<template>
  <template v-if="loader">
    <pms-loader />
  </template>
  <pms-card
    v-else
    :header="false"
    :options="{ body: { class: 'pt-0'} }"
  >
    <div class="my-5">
      <syllabus-information-delivery-types
        :delivery-types="deliveryTypes"
      />
    </div>
    <pms-smart-form
      v-if="amIEnrolled"
      :fields="formFields"
      :options="formOptions"
      @handle-change="onChange"
    >
      <template #formControls />
    </pms-smart-form>
  </pms-card>
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { FormOptions, FormGroup } from '@shared/types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@shared/composable'
import SyllabusInformationDeliveryTypes from '../../../ui/SyllabusInformationDeliveryTypes.vue'
import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { useResource } from '@/shared/stores'
import { has_permission } from '../values'

const { t } = useI18n()
const store = useStore()
const { isActive } = useLoader()

const loader = isActive('academic/manage/syllabus/delivery-types')
const isOnCampus = computed(() => store.getDeliveryTypes.find((type) => type.id === 1 && type.value))
const isOnline = computed(() => store.getDeliveryTypes.find((type) => type.id === 2 && type.value))
const isHybrid = computed(() => store.getDeliveryTypes.find((type) => type.id === 3 && type.value))

const singleCourseStore = useSingleCourse()

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)

const deliveryTypes = computed(() => singleCourseStore.getSyllabusDeliveryTypes)

const formOptions : FormOptions = {
  labelContainer: {
    left: {
      class: 'col-md-4 col-sm-6'
    },
    right: {
      class: 'col-md-8'
    }
  },
  group: {
    class: 'mb-4'
  }
}

const formFields = computed<Array<FormGroup>>(() => {
  return [
    {
      label: {
        class: 'fw-semibold',
        text: `${t('common.fields.on-campus')}`,
        subText: {
          text: t('domains.academic.syllabus.editor.mode-of-delivery.hints.on-campus'),
          class: 'fs-7 text-muted'
        }
      },
      fields: [
        {
          name: 'on-campus',
          type: 'switch',
          class: 'mt-3',
          value: isOnCampus.value?.value || false,
          disabled: has_permission.value
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold',
        text: `${t('common.fields.hybrid')}`,
        subText: {
          text: t('domains.academic.syllabus.editor.mode-of-delivery.hints.hybrid'),
          class: 'fs-7 text-muted'
        }
      },
      fields: [
        {
          name: 'hybrid',
          type: 'switch',
          class: 'mt-3',
          value: isHybrid.value?.value || false,
          disabled: has_permission.value
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold ',
        text: `${t('common.fields.online')}`,
        subText: {
          text: t('domains.academic.syllabus.editor.mode-of-delivery.hints.online'),
          class: 'fs-7 text-muted'
        }
      },
      fields: [
        {
          name: 'online',
          type: 'switch',
          class: 'mt-3',
          value: isOnline.value?.value || false,
          disabled: has_permission.value
        }
      ]
    }
  ]
})

async function onChange (values) {
  if (values.name === 'on-campus') {
    await store.editModeDelivery({ type: 1, value: values.newValue as boolean })
  }
  if (values.name === 'online') {
    await store.editModeDelivery({ type: 2, value: values.newValue as boolean })
  }
  if (values.name === 'hybrid') {
    await store.editModeDelivery({ type: 3, value: values.newValue as boolean })
  }
}
</script>
../store
../store
