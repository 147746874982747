import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManagePostsPostRequest, UnknownResponse } from '@shared/swagger'

export default async function postKnowledgeManagePost (body: KnowledgeManagePostsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<KnowledgeManagePostsPostRequest, UnknownResponse>({
    url: 'knowledge/manage/posts',
    body,
    loader: 'knowledge/manage/posts'
  })
  return response
}
