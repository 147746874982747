export type WeeklyPlans = {
  id: number,
  week_no: number,
  subject: string,
  activity: string
}

export const weeklyPlans : Array<WeeklyPlans> = [
  {
    id: -1,
    week_no: 1,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 2,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 3,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 4,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 5,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 6,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 7,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 8,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 9,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 10,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 11,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 12,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 13,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 14,
    subject: '',
    activity: ''
  },
  {
    id: -1,
    week_no: 15,
    subject: '',
    activity: ''
  }
]
