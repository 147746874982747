import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'
import * as actions from './actions'
import * as getters from './getters'

export const useStore = defineStore<Name, State, Getters, Actions>('Academic.useSyllabusForm', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      academic_skills: [],
      assessments: [],
      activities: [],
      policies: [],
      overview: null,
      subject_skills: [],
      description: {
        objective: '',
        content: '',
        components: '',
        work_placement: ''
      },
      delivery_types: [],
      instructors: [],
      weekly_plan: [],
      learning_outcomes: [],
      teaching_methods: [],
      readings: [],
      editorMode: false,
      module: 'basic',
      course_code: '',
      year: 0,
      language: 'none'
    }
  }
})
