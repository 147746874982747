<template>
  <h3 class="card-title align-items-start flex-column mt-6">
    <span class="card-label fw-bold text-dark">{{ $t('domains.knowledge.all-posts.title') }}</span>
    <span
      v-styler="{'padding-left': '10px'}"
      class="text-gray-400 mt-1 fw-semibold fs-6"
    >{{ $t('domains.knowledge.manage.all-posts-total', { total: total }) }}</span>
  </h3>
</template>

<script setup lang="ts">
import { usePagination } from '@shared/composable'

const { total } = usePagination()
</script>
