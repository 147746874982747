import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { router } from '@/router'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  router.push({ name: 'SignInPage' })
  next()
}

export default {
  layout: 'empty',
  path: '/',
  children: [
    {
      path: '',
      name: 'MainPage',
      component: () => import('./MainPage.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    }
  ]
} as RouteRecordWithLayout
