<template>
  <pms-page-shimmer
    v-if="resourceLoader"
    :shimmer="'list'"
    :full-width="true"
  />
  <pms-card
    v-else
    :header="false"
  >
    <pms-smart-form
      :fields="formFields"
      :options="formOptions"
      :custom-validator="customValidatorForContent"
      @handle-change="onChange"
      @handleSubmit="nextStep"
    >
      <template #formControls>
        <div class="form-group mt-3 col-12 d-flex justify-content-end">
          <pms-button
            :disabled="store.isLoading"
            type="submit"
            :size="'lg'"
            :text="!isEditContent ? $t('common.next') : $t('common.submit')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormGroup } from '@shared/types'
import { useAlert, useLoader } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useKnowledgeManagePost } from '../../../store'
import { knowledgeManageResourcesCategoriesToOptions } from '../../../utils'

import { customValidatorForContent, formatSinglePostToPutRequest } from '../utils'
import { newPost, formOptions } from '../values'

const props = defineProps({
  isEditContent: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const { error, success } = useAlert()
const { isActive } = useLoader()

const store = useKnowledgeManagePost()
const resource = useResource()

const resourceLoader = isActive('knowledge/manage/resources')

const post = computed(() => store.getPost)
const resourceCategory = computed(() => resource.getKnowledgeManageResourceCategories)

const formFields = computed<Array<FormGroup>>(() => {
  return [
    {
      label: {
        class: 'fw-semibold fs-5 required',
        text: `${t('common.table.title')}`
      },
      fields: [
        {
          name: 'title',
          type: 'text',
          value: newPost.value.title || post.value?.title,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5 required',
        text: `${t('common.table.category')}`
      },
      fields: [
        {
          type: 'select',
          name: 'category_uuid',
          options: knowledgeManageResourcesCategoriesToOptions(resourceCategory.value),
          value: newPost.value.category_uuid || post.value?.category_uuid,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5 required',
        text: `${t('common.table.description')}`
      },
      fields: [
        {
          name: 'description',
          type: 'editor',
          value: newPost.value.description || post.value?.description
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5 mt-5 required',
        text: `${t('common.table.published')}`
      },
      fields: [
        {
          group: {
            class: 'mt-5'
          },
          class: 'w-60px h-35px',
          name: 'published',
          type: 'switch',
          value: !!newPost.value.published || !!post.value?.published
        }
      ]
    }
  ]
})

const onChange = ({ name, newValue }) => {
  if (props.isEditContent && post.value) {
    post.value[name] = newValue
  } else {
    newPost.value[name] = newValue
  }
}

const nextStep = async ({ valid }) => {
  if (valid === false) {
    error({ text: t('common.fields.required.not-filled') })
    return
  }

  if (props.isEditContent && post.value) {
    const res = formatSinglePostToPutRequest(post.value)
    const { status, message } = await store.editPost(post.value.uuid, res)
    status ? success({ text: message, isToast: true }) : error({ text: message })
  } else {
    store.setStepper(2)
  }
}
</script>
