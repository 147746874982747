import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileSettingsPasswordsPutRequest } from '@shared/swagger'

export default async function putProfileSettingsPasswords (values: ProfileSettingsPasswordsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileSettingsPasswordsPutRequest, UnknownResponse>({
    url: 'profile/settings/passwords',
    body: values,
    loader: 'profile/settings/passwords-edit'
  })

  return response
}
