import type { StoreType } from '../types'
import { useResource } from '@shared/stores'
import { useAlert } from '@shared/composable'

import { currentLanguage } from '@/domains/AcademicSingleCourse/features/SyllabusPage/values'
import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchSyllabus (this: StoreType, message: StatusMessageResponse['message']): Promise<void> {
  const resource = useResource()
  const singleCourseStore = useSingleCourse()
  const { error } = useAlert()
  await resource.fetchAcademicManageSyllabusResource(currentLanguage.value)
  const data = singleCourseStore.syllabus

  if (data) {
    this.overview = data.overview
    this.delivery_types = data.delivery_types
    this.academic_skills = data.academic_skills
    this.weekly_plan = data.weekly_plan
    this.teaching_methods = data.teaching_methods
    this.instructors = data.instructors
    this.activities = data.activities
    this.readings = data.readings
    this.policies = data.policies
    this.learning_outcomes = data.learning_outcomes
    this.subject_skills = data.subject_skills
    this.description = data.description
    this.assessments = data.assessments
  } else {
    error({ text: message })
  }
}
