<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :filter="true"
    :full-width="true"
  />
  <pms-grid v-else>
    <YearTerm />
    <attendance-component />
  </pms-grid>
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourAttendance } from '../../values'
import { useSingleStudent } from '../../store'

import { sideBarState } from './values'
import AttendanceComponent from './ui/AttendanceComponent.vue'

import YearTerm from '../../ui/YearTerm.vue'
import { storeToRefs } from 'pinia'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const store = useSingleStudent()

const loader = isActiveOneOf(['academic/students/resources', 'academic/students/attendance'])

const { currentYearTerm } = storeToRefs(store)

watch(() => currentYearTerm.value, async (newVal) => {
  sideBarState.value = false
  await store.fetchAttendance(store.getStudentId, { year: newVal.year, term: newVal.term })
})

onMounted(async () => {
  setQuickTourSteps(await quickTourAttendance())
})
</script>
