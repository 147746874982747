import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementRequestsQuotasReservesPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postSectionManagementRequestsQuotasReserves (requestId: number, quotaId: number, params: SectionManagementRequestsQuotasReservesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: `academic/manage/sections/requests/${requestId}/quotas/${quotaId}/reserves`,
    body: params,
    loader: 'academic/manage/sections/requests/quotas/reserves-post'
  })

  return response
}
