<template>
  <div class="w-75 mt-4">
    <div>
      <h2>  {{ $t('domains.profile.cv.cv') }}</h2>
    </div>
    <div class="w-100 bg-gradient p-2 d-flex flex-row align-items-center">
      <h5>
        <i
          class="bi bi-people"
          style="color: black; font-size: 16px; margin-right: 10px;"
        />
        {{ $t('domains.profile.cv.personal-information') }}
      </h5>
    </div>
    <div class="d-flex flex-column w-100 mt-3 mb-3">
      <label class="d-flex justify-content-start">
        <span class="col-6 fw-bolder">{{ $t('common.full-name') }}</span>
        <span class="col-6">{{ cvInfo?.personal.name }} {{ cvInfo?.personal.surname }}</span>
      </label>
      <label class="d-flex justify-content-start">
        <span class="col-6 fw-bolder">{{ $t('common.address') }}</span>
        <span class="col-6">{{ getResourceTitleById(profileResource?.countries, cvInfo?.address.country) }} {{ getResourceTitleById(profileResource?.provinces, cvInfo?.address.province) }} {{ getResourceTitleById(profileResource?.regions, cvInfo?.address.region) }} {{ cvInfo?.address.address_line }}</span>
      </label>
      <label class="d-flex justify-content-start">
        <span class="col-6 fw-bolder">{{ $t('common.phone') }}</span>
        <span class="col-6">{{ cvInfo?.contacts.map(contact => contact.contact).join(', ') }}</span>
      </label>
      <label class="d-flex justify-content-start">
        <span class="col-6 fw-bolder">{{ $t('common.email') }}</span>
        <span class="col-6">{{ cvInfo?.personal.mail }}</span>
      </label>
    </div>
    <div v-if="cvInfo?.biography.biography">
      <div class="w-100 bg-gradient p-2 d-flex flex-row align-items-center">
        <h5>
          <i
            class="bi bi-person-circle"
            style="color: black; font-size: 16px; margin-right: 10px;"
          />
          {{ $t('domains.profile.cv.biography') }}
        </h5>
      </div>
      <div class="col-12 mt-2 d-flex justify-content-start">
        <span>{{ cvInfo.biography.biography }}</span>
      </div>
    </div>
    <div
      v-if="cvInfo?.education"
      class="d-flex flex-column justify-content-center"
    >
      <div class="w-100 bg-gradient p-2 d-flex flex-row align-items-center">
        <h5>
          <i
            class="bi bi-book"
            style="color: black; font-size: 16px; margin-right: 10px;"
          />
          {{ $t('domains.profile.cv.education') }}
        </h5>
      </div>
      <div class="mt-3 mb-3">
        <div
          v-for="edu in cvInfo.education"
          :key="edu.university"
        >
          <i class="bi bi-building p-3" />
          <span class="fw-bolder">{{ edu.university }}</span> {{ edu.speciality }} ({{ getYear(edu.start_date) }}-{{ getYear(edu.end_date) }})
        </div>
      </div>
    </div>
    <div
      v-if="cvInfo?.skills"
      class="d-flex flex-column justify-content-center"
    >
      <div class="w-100 bg-gradient p-2 d-flex flex-row align-items-center">
        <h5>
          <i
            class="bi bi-lightbulb"
            style="color: black; font-size: 16px; margin-right: 10px;"
          />
          {{ $t('domains.profile.cv.skills') }}
        </h5>
      </div>
      <div class="mt-3 mb-3 d-flex flex-row flex-wrap">
        <div
          v-if="cvInfo?.skills.hard_skills"
          class="d-flex col-6 mt-2 mb-2 flex-column"
        >
          <span class="fw-bolder">{{ $t('domains.profile.cv.hard-skills') }}</span>
          <span>
            <span
              v-for="skill in groupHardSkills(cvInfo.skills.hard_skills)"
              :key="skill"
              class="badge p-2 text-wrap m-2"
            >{{ skill }}</span>
          </span>
        </div>
        <div
          v-if="cvInfo.skills.interests"
          class="d-flex col-6 mt-2 mb-2 flex-column"
        >
          <span class="fw-bolder">{{ $t('domains.profile.cv.interests') }}</span>
          <span>
            <span class="badge p-2 text-wrap m-2">{{ cvInfo.skills.interests.interest }}</span>
          </span>
        </div>
        <div
          v-if="cvInfo.skills.languages"
          class="d-flex col-6 mt-2 mb-2 flex-column"
        >
          <span class="fw-bolder">{{ $t('domains.profile.cv.languages') }}</span>
          <span>
            <span
              v-for="language in cvInfo.skills.languages"
              :key="language.language_code"
              class="badge p-2 text-wrap m-2"
            >{{ language.language_code }}</span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="cvInfo?.experiences"
      class="w-100 bg-gradient p-2 d-flex flex-row align-items-center"
    >
      <h5>
        <i
          class="bi bi-suitcase-lg"
          style="color: black; font-size: 16px; margin-right: 10px;"
        />
        {{ $t('domains.profile.cv.experiences') }}
      </h5>
    </div>
    <label class="w-100 d-flex flex-column justify-content-start mt-3 mb-3">
      <div
        v-for="job in cvInfo?.experiences"
        :key="job.title"
      >
        <span class="col-12 mt-1 mb-1 d-flex flex-row">
          <span class="fw-bolder mr-2">{{ job.title }}</span> {{ getResourceTitleById(profileResource?.companies, job.company_id) }} ({{ job.start_year }} - {{ job.end_year ? job.end_year : $t('common.current_time') }})
        </span>
      </div>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ProfileSkillsHardGetResponse } from '@/shared/swagger'
import { computed } from 'vue'
import { useProfile } from '../../../store'
import { getResourceTitleById } from '../utils'
import { useResource } from '@/shared/stores'
import { storeToRefs } from 'pinia'

const store = useProfile()
const resource = useResource()

const cvInfo = computed(() => store.getOverview)
const { profileResource } = storeToRefs(resource)

const getYear = (dateString: string) => {
  return dateString.split('-')[0]
}

const groupHardSkills = (skills: ProfileSkillsHardGetResponse) => {
  return skills.flatMap(skill => skill.skill.split(','))
}
</script>
