
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useStore = defineStore<Name, State, Getters, Actions>('Academic.useAcademicMyScheduleStore', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      currentYearTerm: { id: 0, year: 0, term: 0 },
      scheduleRecords: []
    }
  }
})
