import { RouteRecordWithLayout } from '@shared/types'
import { useServiceEsignStore } from './store'
import { RouteLocationNormalized } from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function handleRoute (from:RouteLocationNormalized, to:RouteLocationNormalized, next:any) {
  await useServiceEsignStore().bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/service/e-sign',
  children: [
    {
      path: '',
      name: 'ServiceEsign',
      component: () => import('./ServiceEsign.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
