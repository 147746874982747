import { StoreType } from '../types'
import { DashboardWidgetsResponse } from '@/shared/swagger'
import GreetingWidget from '../../ui/GreetingWidget.vue'
import AttendanceWidget from '../../ui/AttendanceWidget.vue'
import QuickLinksWidget from '../../ui/QuickLinksWidget.vue'
import StudentSearchWidget from '../../ui/StudentSearchWidget.vue'
import FacultyWidget from '../../ui/FacultyWidget.vue'
import MessageBoardWidget from '../../ui/MessageBoardWidget.vue'
import ReportsWidget from '../../ui/ReportsWidget.vue'
import RulesAndRegulationsWidget from '../../ui/RulesAndRegulationsWidget.vue'
import ScheduleWidget from '../../ui/ScheduleWidget.vue'
import SyllabusWidget from '../../ui/SyllabusWidget.vue'
import CoursesWidget from '../../ui/CoursesWidget.vue'

export default function createWidgets (this: StoreType, response: DashboardWidgetsResponse) {
  const widgets = {}
  for (const key in response) {
    const widgetId = response[key].id
    switch (key) {
      case 'greetings': {
        widgets[widgetId] = GreetingWidget
        break
      }
      case 'schedules': {
        widgets[widgetId] = ScheduleWidget
        break
      }
      case 'courses': {
        widgets[widgetId] = CoursesWidget
        break
      }
      case 'attendances': {
        widgets[widgetId] = AttendanceWidget
        break
      }
      case 'faculty_statistics': {
        widgets[widgetId] = FacultyWidget
        break
      }
      case 'rules_and_regulations': {
        widgets[widgetId] = RulesAndRegulationsWidget
        break
      }
      case 'syllabus_statistics': {
        widgets[widgetId] = SyllabusWidget
        break
      }
      case 'posts': {
        widgets[widgetId] = MessageBoardWidget
        break
      }
      case 'reports': {
        widgets[widgetId] = ReportsWidget
        break
      }
      case 'quick_links': {
        widgets[widgetId] = QuickLinksWidget
        break
      }
      case 'student_search': {
        widgets[widgetId] = StudentSearchWidget
        break
      }
    }
  }
  this.widgets = widgets
}
