import { PropType, defineComponent } from 'vue'

import { handleOptions, uuid, isTranslatable } from '@shared/utils'
import { ModalOptions } from '@shared/types'
import { useI18n } from 'vue-i18n'
import { useTeleportModal } from '@/shared/composable'

const defaultOptions: ModalOptions = {
  header: {
    class: 'pt-2 pb-2 '
  },
  title: {
    class: 'd-flex align-items-start flex-column'
  }
}

export default defineComponent({
  props: {
    name: {
      type: String,
      default: uuid()
    },
    teleportName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Please, set a title'
    },
    description: {
      type: String,
      default: 'Please, set a description'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: false
    },
    dividers: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as PropType<'sm' | 'lg' | 'xl' | string>,
      default: 'lg'
    },
    centered: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object as PropType<ModalOptions>,
      default: () => defaultOptions
    }
  },
  emits: ['onClose'],
  setup (props, { slots, emit }) {
    const { t } = useI18n()
    const { showContent, hide, unsetModifier } = useTeleportModal()

    const options = handleOptions(props.options, defaultOptions)
    const isDefaultSize = props.size === 'lg' || props.size === 'sm' || props.size === 'xl'

    const close = (): void => {
      unsetModifier()
      hide()
      emit('onClose')
    }

    return () => (
      <div
        class="modal fade"
        tabindex="-1"
        id={props.name}
        data-bs-backdrop={props.backdrop ? 'static' : undefined }
        data-bs-keyboard={props.backdrop || props.keyboard ? 'false' : undefined }
      >
        <div
          class={{
            'modal-dialog': true,
            [options?.dialog?.class || '']: true,
            [props.size === '' ? '' : isDefaultSize ? `modal-${props.size}` : props.size]: true,
            'modal-dialog-centered': props.centered,
            'modal-fullscreen': props.fullscreen
          }}
          style={options?.dialog?.style}
        >
          <div
            class={{
              'modal-content': true,
              [options?.content?.class || '']: true
            }}
            style={options?.content?.style}
          >
            <div
              class={{
                'modal-header': true,
                [options?.header?.class || '']: true,
                'border-bottom-0': !props.dividers
              }}
              style={options?.header?.style}
            >
              <div
                class={{
                  'modal-title': true,
                  [options?.title?.class || '']: true
                }}
                style={options?.title?.style}
              >
                { slots.header && slots.header() }
                {
                  !('header' in slots) &&
                  (
                    <>
                      { props.header && (<span class="fw-bold text-gray-800 fs-5 m-0 p-0">{ isTranslatable(props.title) ? t(props.title) : props.title }</span>) }
                      { props.header && props.description && (<span class="text-muted m-0 p-0">{ isTranslatable(props.description) ? t(props.description) : props.description }</span>) }
                    </>
                  )
                }
              </div>

              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={ close }
              >
                <pms-kt-icon
                  iconName={'cross'}
                  class={ 'fs-1' }
                />
              </div>
            </div>

            <div
              class={{
                'modal-body pt-2': true,
                [options?.body?.class || '']: true
              }}
              style={options?.body?.style}
            >
              { !showContent.value && <pms-loader /> }
              <div
                style={{ display: showContent.value ? 'inherit' : 'none' }}
                id={props.teleportName}
              />
            </div>

            { props.footer &&
              (
                <div
                  class={{
                    'modal-footer': true,
                    [options?.footer?.class || '']: true,
                    'border-top-0': !props.dividers
                  }}
                  style={options?.footer?.style}
                >
                  { slots.footer && slots.footer() }
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
})
