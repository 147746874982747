<template>
  <pms-card :title="t('domains.schedule-management.section-program.programs-title')">
    <div class="d-flex flex-column">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'table'"
        :full-width="true"
      />
      <pms-smart-table
        v-else
        :items="singleSectionPrograms"
        :headers="singleSectionHeader"
        :class="'mt-4 mb-4'"
        :options="{
          wrapper: { responsive: false}
        }"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #is_main="{ item }">
          <i
            v-if="item.is_main"
            class="bi bi-star-fill"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="t('domains.schedule-management.section-program.is-main-program-tooltip')"
          />
        </template>
        <template #dateRange="{item}">
          {{ item.start_date }} - {{ item.end_date }}
        </template>
        <template #hour="{item}">
          {{ item.schedules.hour }}
        </template>
        <template #applied="{item}">
          {{ item.schedules.applied }}
        </template>
        <template #rooms="{item}">
          <template
            v-for="(item1, index) in item.rooms"
            :key="index"
          >
            {{ item1 }}<br>
          </template>
        </template>
        <template #action="{item}">
          <pms-dropdown
            :group-class="'w-100px order-5'"
            :icon-placement="'end'"
          >
            <div class="w-150px menu-item pt-0 pb-0 text-left">
              <a
                v-if="item.is_applied"

                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="handleDisapplyProgram(item)"
              >
                {{ $t('common.table.discard-apply') }}
              </a>
              <a
                v-else
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                :data-bs-target="'#modal1'"
                :data-bs-toggle="'modal'"
                @click="handleApplyProgram(item)"
              >
                {{ $t('common.table.apply') }}
              </a>

              <a
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="openScheduleModal(item.id)"
              >
                {{ $t('domains.schedule-management.section-program.manage-schedule') }}
              </a>
              <a
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="openPropertyModal(item.id)"
              >
                {{ $t('common.table.edit-properties') }}
              </a>
              <a
                v-if="!item.is_main || singleSectionPrograms.length>1"
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="handleMakeAsMain(item)"
              >
                {{ $t('domains.shedule-management.program.make-as-main') }}
              </a>
              <a
                v-if="!item.is_applied"
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="handleClearProgram(item.id)"
              >
                {{ $t('domains.schedule-management.section-program.clear') }}
              </a>
              <a
                href="javascript:void(0)"
                class="menu-link px-3 text-left"
                @click="openDeleteModal(item.id)"
              >
                {{ $t('common.table.delete') }}
              </a>
            </div>
          </pms-dropdown>
        </template>
      </pms-smart-table>
    </div>
  </pms-card>
</template>
<script lang="ts" setup>
import { useScheduleManagement } from '@/domains/ScheduleManagement/store'
import { useAlert, useLoader, useTeleportModal } from '@/shared/composable'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { singleSectionHeader } from '@domains/ScheduleManagement/features/SectionPage/values'
import { storeToRefs } from 'pinia'

const store = useScheduleManagement()
const { isActive } = useLoader()
const { dialog, error, success } = useAlert()
const { t } = useI18n()

const { selectedProgram } = storeToRefs(store)
const loader = isActive('academic/manage/schedules/sections-single')
const singleSectionPrograms = computed(() => store.getSingleSectionPrograms)
const { open, setModifier } = useTeleportModal()

const openPropertyModal = (id: number) => {
  setModifier('schedule-management-property-modal',
    { id },
    { title: 'title', description: 'description' }
  )
  open({ name: 'schedule-management-modal' })
}

const handleApplyProgram = (program: typeof selectedProgram.value) => {
  selectedProgram.value = program
}

const handleDisapplyProgram = (program: typeof selectedProgram.value) => {
  dialog({
    text: 'domains.schedule-management.section.program.discard-applied-text',
    onConfirm: async () => {
      if (!store.singleSection || !program) return
      const response = await store.discardAppiedProgram({ program: program.id, section: store.singleSection.id })

      if (response.status) {
        success({
          text: response.message
        })
      } else {
        error({
          text: response.message
        })
      }
    }
  })
}

const openScheduleModal = (id: number) => {
  setModifier('schedule-management-schedule-modal',
    { id },
    { title: 'title', description: 'description' }
  )
  open({ name: 'schedule-management-modal' })
}

const handleMakeAsMain = (program: typeof selectedProgram.value) => {
  dialog({
    text: t('domains.schedule-management.section.program.make-as-main'),
    onConfirm: async () => {
      if (!store.singleSection || !program) return

      const response = await store.makeProgramAsMain(store.singleSection.id, program.id)

      if (response.status) {
        success({
          text: response.message
        })
      } else {
        error({
          text: response.message
        })
      }
    }
  })
}

const handleClearProgram = async (id: number, title?: string) => {
  dialog({
    title: t('domains.schedule-management.are-you-sure'),
    text: t('domains.schedule-management.program.clear-text', { name: title }),
    onConfirm: async () => {
      if (!store.singleSection) return
      const response = await store.clearProgram(store.singleSection.id, id)

      if (response.status) {
        success({
          text: response.message
        })
      } else {
        error({
          text: response.message
        })
      }
    }
  })
}

const openDeleteModal = async (id: number, title?: string) => {
  dialog({
    title: t('domains.schedule-management.are-you-sure'),
    text: t('domains.schedule-management.program.delete-text', { name: title }),
    onConfirm: async () => {
      if (!store.singleSection) return
      const response = await store.deleteProgram(store.singleSection.id, id)

      if (response.status) {
        success({
          text: response.message
        })
      } else {
        error({
          text: response.message
        })
      }
    }
  })
}
</script>
