<template>
  <pms-smart-form
    :fields="formFields"
    :loader="loader"
    @handleSubmit="onSubmit"
  >
    <template #credits_separator_s>
      <pms-divider
        :content="$t('common.table.credits')"
        design="dotted"
        color="primary"
        margin-y="5"
      />
    </template>
    <template #credits_separator_e>
      <pms-divider
        content="."
        design="dotted"
        color="primary"
        margin-y="5"
      />
    </template>
  </pms-smart-form>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useLoader } from '@/shared/composable'
import { useManageCourseComposable, useManageCourseFormComposable } from '../composable'

const { formFields } = useManageCourseFormComposable()
const { onSubmit } = useManageCourseComposable()
const loader = computed(() => useLoader().isActiveOneOf['academic/manage/courses'])

</script>
