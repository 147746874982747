export const postItemCardOptions = {
  header: {
    class: 'pt-5'
  },
  footer: {
    class: 'pt-0'
  },
  body: {
    class: 'pt-4 pb-4'
  }
}
