import { RouteRecordWithLayout } from '@shared/types'
import { AvocadoDirective, AvocadoLanguage, AvocadoLayout, AvocadoScript, AvocadoUI } from '@/avocado/types'

export const getModuleNameFromPath = (path: string): string => {
  if (path.includes('.route.ts') || path.includes('routing.ts')) return 'route'
  if (path.endsWith('.lang.ts')) return 'i18n'
  if (path.endsWith('.directive.ts')) return 'directive'
  if (path.includes('.layout.tsx')) return 'layout'
  if (path.includes('.script.ts')) return 'script'
  if (path.includes('.tsx') || path.includes('shared.config.ts')) return 'ui'
  return 'unknown'
}

const getFileName = (path: string) => {
  const pathArray = path.split('/')
  return pathArray[pathArray.length - 1]
}

export const convertToRoute = (file: unknown): RouteRecordWithLayout => file as RouteRecordWithLayout
export const convertToI18n = (file: unknown): AvocadoLanguage => file as AvocadoLanguage
export const convertToScript = (file: unknown): AvocadoScript => file as AvocadoScript
export const convertToDirective = (file: unknown): AvocadoDirective => file as AvocadoDirective

export const convertToLayout = (path: string, file: unknown): AvocadoLayout => {
  const [name] = getFileName(path).split('.')
  return {
    name,
    component: file
  }
}

export const convertToUi = (path: string, file: unknown): AvocadoUI => {
  const filename = getFileName(path)

  return {
    name: filename,
    type: filename === 'shared.config.ts' ? 'config' : 'component',
    component: file
  }
}
