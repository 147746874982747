<template>
  <div
    id="experience"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.experience.experience.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div
        v-for="(experience, index) in experiencesForDisplay"
        :key="index"
      >
        <ExperienceParent
          v-if="experience.experience.id > 0"
          :experience="experience.experience"
          :country="country"
          :cities="cities"
          :companies="companies"
          @set-id="setIdAction"
          @delete-id="deleteAction"
        />
        <ExperienceChild
          v-else-if="experience.experienceChildren.length>0"
          :experience="experience"
          :country="country"
          :cities="cities"
          :companies="companies"
          @set-id="setIdAction"
          @delete-id="deleteAction"
        />
      </div>

      <pms-no-data v-if="experiences.length ===0" />
      <ExperienceForm v-if="modifier && modifier.name === 'experience'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { ExperienceForDisplay } from '../types'
import ExperienceForm from './ExperienceForm.vue'
import ExperienceParent from './ExperienceParent.vue'
import ExperienceChild from './ExperienceChild.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const country = computed(() => resource.getProfileResource?.countries || [])
const companies = computed(() => resource.getProfileResource?.companies || [])
const cities = computed(() => resource.getProfileResource?.cities || [])
const experiences = computed(() => store.getExperiences)

const setIdAction = (id: number) => {
  setModifier('experience',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'experience-new-title' : 'experience-edit-title',
      description: id === -1 ? 'experience-new-description' : 'experience-edit-description'
    }
  )
  open({ name: 'experience-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.experience.are-you-sure',
    text: t('domains.profile.experience.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteExperience(id)
    }
  })
}

const experiencesForDisplay = computed<Array<ExperienceForDisplay>>(() => {
  const experiencesForDisplay: Array<ExperienceForDisplay> = []
  const experiencesByCompany = {}

  experiences.value.forEach(experience => {
    if (experience.company_id) {
      if (experiencesByCompany[experience.company_id]) {
        experiencesByCompany[experience.company_id].push(experience)
      } else {
        experiencesByCompany[experience.company_id] = [experience]
      }
    }
  })

  for (const key in experiencesByCompany) {
    if (experiencesByCompany[key].length > 1) {
      experiencesForDisplay.push({
        experienceChildren: experiencesByCompany[key],
        company: parseInt(key),
        experience: {
          id: -1,
          title: '',
          company_id: -1,
          start_month: -1,
          start_year: -1,
          end_month: -1,
          end_year: -1,
          country_id: -1,
          city_id: -1,
          description: '',
          industry: '',
          employment_type: -1,
          location_id: -1,
          is_current_position: false
        }
      })
    } else {
      experiencesForDisplay.push({
        experience: experiencesByCompany[key][0],
        company: -1,
        experienceChildren: []
      })
    }
  }

  return experiencesForDisplay
})
</script>
