import { deleteAcademicSingleStudentAdvisorOpinion } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteAdvisorOpinion (this: StoreType, student: string, opinion: string): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteAcademicSingleStudentAdvisorOpinion(student, opinion)
  ok && this.fetchInformation(student)

  return {
    status: ok,
    message
  }
}
