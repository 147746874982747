<template>
  <ActionTableHeaderButtons />
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'table'"
    :full-width="true"
  />
  <pms-card
    v-else
    id="shedule-table"
    :header="false"
    :options="{
      card: {class: 'rounded-3 shadow-sm'},
      body: {class: 'p-0 rounded-3'}
    }"
  >
    <div class="table-responsive">
      <table
        id="singleCourseSchedulePrint"
        class="table table-row-bordered align-middle text-center gy-4 mb-0 pb-0"
      >
        <table-head />
        <table-body
          :schedule="schedule"
          :schedule-hours="scheduleHours"
        />
      </table>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@/shared/composable'
import { useAcademicSchedule } from '../store'
import TableHead from './TableHead.vue'
import TableBody from './TableBody.vue'
import ActionTableHeaderButtons from './ActionTableHeaderButtons.vue'
import { useResource } from '@/shared/stores'

const resource = useResource()
const { isActive } = useLoader()

const loader = isActive('academic/information/schedules')
const store = useAcademicSchedule()
const schedule = computed(() => store.getScheduleRecords)

const scheduleHours = computed(() => {
  return resource.scheduleManagementSectionProgramHours ?? []
})

</script>

<style lang="scss" scoped>
.hover-bg-active:hover {
  background-color: var(--kt-secondary);
}
.course-hover-bg:hover {
  background-color: #e4f2fd;
}
</style>
