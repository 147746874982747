<template>
  <div
    id="fixed-menu"
    class="card shadow"
  >
    <div class="card-body p-4 align-items-center">
      <span class="fw-bold fs-5 pe-0">
        {{ $t('common.action') }}:
      </span>
      <pms-button
        class="ms-2 py-2 px-4 pe-0"
        @Click="handleGoToReferencesPage"
      >
        <span>{{ $t('domains.academic.curricula.view.reference') }}</span>
        <i class="bi bi-link-45deg" />
      </pms-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { router } from '@/router'
import { useRoute } from 'vue-router'

const route = useRoute()
const trackId = route.query.track

const props = defineProps({
  courses: {
    type: Array as unknown as PropType<string[]>,
    required: true,
    default: () => []
  }
})

const handleGoToReferencesPage = () => {
  if (typeof trackId === 'string') {
    router.push({
      name: 'CoreCourseReferensePage',
      params: {
        trackId
      },
      query: {
        courses: props.courses
      }
    })
  }
}

</script>

<style scoped lang="scss">
#fixed-menu {
  position: fixed;
  height: 60px;
  width: inherit;
  bottom: 0px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
