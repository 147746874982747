import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  AdministrativeMessageBoardPostsGetRequest,
  AdministrativeMessageBoardPostsGetResponse
} from '@shared/swagger'
import { UnknownRequest } from '@shared/swagger'

export default async function getAdministrativeMessageBoardPosts (params: AdministrativeMessageBoardPostsGetRequest, loader = 'administrative/message-board'): Promise<AxiosResponse<AdministrativeMessageBoardPostsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeMessageBoardPostsGetResponse>({
    url: 'administrative/message-board/posts',
    body: { params: params },
    loader: loader
  })

  return response
}
