import type { StoreType } from '../types'
import { AcademicSingleStudentAttendanceGetRequest } from '@shared/swagger'
import { getAcademicSingleStudentAccountingDetails } from '@shared/api'

export default async function fetchAccountingDetails (this: StoreType, id: string, params: AcademicSingleStudentAttendanceGetRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleStudentAccountingDetails(id, params)

  if (ok) {
    this.setAccountingDetails(data)
  }
}
