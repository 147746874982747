export const editorOptions = {
  width: '100%',
  height: 500,
  menubar: false,
  toolbar_location: 'top',
  skin: 'naked',
  statusbar: false,
  plugins: [
    'advlist autolink emoticons wordcount lists link image charmap searchreplace visualblocks code fullscreen insertdatetime media table paste autoresize'
  ],
  toolbar: 'formatselect | bold italic | alignleft aligncenter alignright alignjustify | table link emoticons image | bullist numlist | removeformat'
}
