<template>
  <div
    :id="`article-${type}`"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t(`domains.profile.publications.article-${type}.header`) }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="articles"
        :headers="tableHeadersArticle"
        :sortable="true"
      >
        <template #author="{item}">
          <div>
            <div>
              <span class="text-gray-800 fw-bold m-0 lh-1 fs-4">{{ item.title }}</span>
            </div>
            <div>
              <span class="fs-6 lh-1 me-1">{{ item.authors }}</span>
              <a
                :href="item.url"
                target="_blank"
                class="text-hover-primary d-block fs-6"
              >
                {{ $t('common.open-url') }}
              </a>
            </div>
          </div>
        </template>
        <template #journal="{item}">
          <div class="d-flex flex-column align-items-start">
            <p
              class="m-0 fw-bold fs-6"
            >
              {{ getTitleById(item.journal_id,journals) }}
            </p>
            <p class="text-muted fw-bold d-block fs-7 m-0">
              <span class="text-dark">
                {{ $t('domains.profile.education.publications.articles.table.degree') + ': ' }}
              </span>
              {{ item.degree }}
            </p>
            <p class="text-muted fw-bold d-block fs-7 m-0">
              <span class="text-dark">
                {{ $t('domains.profile.education.publications.articles.table.series') + ': ' }}
              </span>
              {{ item.series }}
            </p>
            <p class="text-muted fw-bold d-block fs-7 m-0">
              <span class="text-dark">
                {{ $t('domains.profile.education.publications.articles.table.pages') + ': ' }}
              </span>
              {{ item.pages }}
            </p>
          </div>
        </template>
        <template #country="{item}">
          <span
            v-if="item.country"
            class="text-gray-800 fw-bold m-0 fs-4"
          >
            {{ getTitleById(item.country,countries) }}
          </span>
        </template>
        <template #date="{item}">
          <span class="text-gray-800 fw-bold m-0 fs-4">
            {{ displayDate(item) }}
          </span>
        </template>
        <template #action="{ item }">
          <div class="d-flex align-items-center gap-2 justify-content-end">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <ArticleForm v-if="modifier && modifier.name === `article-${type}`" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ProfileEducationArticlesGetResponse } from '@/shared/swagger'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { months, tableHeadersArticle } from '../values'
import { getTitleById } from '../utils'
import { useProfile } from '../../../store'

import ArticleForm from './ArticleForm.vue'

const props = defineProps({
  articles: {
    type: Array as PropType<ProfileEducationArticlesGetResponse>,
    default: null
  },
  type: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const countries = computed(() => resource.getProfileResource?.countries || [])
const journals = computed(() => resource.getProfileResource?.journals || [])

const setIdAction = (id: number) => {
  setModifier(`article-${props.type}`,
    { id: id, action: id === -1 ? 'new' : 'edit', type: props.type },
    {
      title: id === -1 ? 'article-new-title' : 'article-edit-title',
      description: id === -1 ? 'article-new-description' : 'article-edit-description'
    }
  )
  open({ name: 'publication-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.publications.are-you-sure',
    text: t('domains.profile.publications.article.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteArticle(id)
    }
  })
}

const displayDate = (item: ProfileEducationArticlesGetResponse[0]): string => {
  return t(months[item.published_month - 1]) + ' ' + item.published_year
}
</script>
