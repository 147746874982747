import { dashboardWidgets } from '../values'

export const saveOrder = () => {
  localStorage.setItem('dashboardWidgetsOrder', JSON.stringify(dashboardWidgets.value))
}

export const loadOrder = () => {
  const savedOrder = localStorage.getItem('dashboardWidgetsOrder')
  const map = new Map()
  const order = savedOrder && JSON.parse(savedOrder)
  if (order) {
    order.forEach((item, index) => {
      map.set(item.id, index)
    })

    dashboardWidgets.value.sort((a, b) => map.get(a.id) - map.get(b.id))
  }
}
