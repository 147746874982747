import { computed, defineComponent, PropType, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { SidebarModalPosition } from '@shared/types'
import { isTranslatable } from '@shared/utils'

const positionMapper = {
  start: 'right',
  end: 'left',
  bottom: 'top',
  top: 'bottom'
}

const ModalSidebar = defineComponent({
  name: 'ModalSidebar',
  props: {
    modal: {
      type: String,
      required: true
    },
    state: {
      type: Boolean,
      required: true
    },
    resize: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number] as PropType<string | number>,
      default: 300
    },
    position: {
      type: String as PropType<SidebarModalPosition>,
      default: 'end'
    },
    scrollOut: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    backdrop: {
      type: Boolean,
      default: false
    },
    currentWidth: {
      type: Number,
      default: -1
    }
  },
  emits: ['update:state', 'update:currentWidth'],
  setup (props, { slots, emit }) {
    const BORDER_SIZE = 4
    let M_POS
    const C_POS = ref<number>(-1)

    const { t } = useI18n()
    const expandStatus = ref<boolean>(false)

    const expandPanel = () => {
      const panel = document.getElementById(`${props.modal}SimpleSidebar`)
      if (!panel) return

      panel.style.width = expandStatus.value
        ? (C_POS.value !== -1 ? C_POS.value : initialWith.value) + 'px'
        : '100%'
      expandStatus.value = !expandStatus.value
    }

    const onResize = (e) => {
      const panel = document.getElementById(`${props.modal}SimpleSidebar`)
      if (!panel) return

      let size
      const dx = M_POS - e.x
      M_POS = e.x

      if (props.position === 'start') {
        size = (parseInt(getComputedStyle(panel, '').width) - dx)
      } else if (props.position === 'end') {
        size = (parseInt(getComputedStyle(panel, '').width) + dx)
      }

      if (size >= initialWith.value) {
        C_POS.value = size
        panel.style.width = size + 'px'
      }
    }

    const onMouseDown = (e) => {
      if (!props.resize) return

      if (props.position === 'start' && e.offsetX > BORDER_SIZE) {
        M_POS = e.x
        document.addEventListener('mousemove', onResize, false)
      } else if (props.position === 'end' && e.offsetX < BORDER_SIZE) {
        M_POS = e.x
        document.addEventListener('mousemove', onResize, false)
      }
    }

    document.addEventListener('mouseup', function () {
      document.removeEventListener('mousemove', onResize, false)
    }, false)

    const modalSize = computed(() => {
      let sideType = 'height'
      if (['start', 'end'].includes(props.position)) { sideType = 'width' }
      return `${sideType}: ${props.width}${typeof props.width === 'number' ? 'px' : ''};`
    })

    const initialWith = computed<number>(() => {
      if (typeof props.width === 'number') return props.width
      return parseInt(props.width.replace('px', ''))
    })

    watch(
      () => props.state,
      (newVal) => {
        if (newVal) document.getElementById(`${props.modal}SimpleSidebarBtn`)?.click()
      },
      { immediate: true }
    )

    watch(
      () => C_POS.value,
      () => { emit('update:currentWidth', C_POS.value) },
      { immediate: true }
    )

    return () => (
      <>
        <style>
          { props.resize && ['start', 'end'].includes(props.position) && `
            #${props.modal}SimpleSidebar::after {
              content: '';
              background-color: var(--bs-body-bg);
              position: absolute;
              ${positionMapper[props.position]}: 0 !important;
              width: ${BORDER_SIZE}px;
              height: 100%;
              cursor: ew-resize;
            }
          `}
        </style>
        <button
          id={`${props.modal}SimpleSidebarBtn`}
          class="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target={`#${props.modal}SimpleSidebar`}
          aria-controls={`${props.modal}SimpleSidebar`}
          style={{ display: 'none' }}
        ></button>
        <div
          id={`${props.modal}SimpleSidebar`}
          data-bs-scroll={props.scrollOut}
          aria-labelledby={`${props.modal}SimpleSidebarLabel`}
          style={modalSize.value}
          class={`offcanvas shadow-lg offcanvas-${props.position}`}
          onMousedown={onMouseDown}
          data-bs-backdrop={ props.backdrop ? 'static' : 'false' }
          data-bs-keyboard="true"
          tabindex="-1"
        >
          <div class="offcanvas-header pb-4">
            <h5
              class="offcanvas-title align-items-start d-flex flex-column"
              id={`${props.modal}SimpleSidebarLabel`}
            >
              { props.title !== '' && (
                <span class="card-label fw-bold text-dark fs-5">
                  {isTranslatable(props.title) ? t(props.title) : props.title}
                </span>
              )}
              { props.description !== '' && (
                <span class="text-muted fw-bold mt-0 fs-7">
                  {isTranslatable(props.description) ? t(props.description) : props.description}
                </span>
              )}
            </h5>
            <div
              style="min-width: 6.5rem;"
              class="text-end"
            >
              {
                props.resize && (
                  <div
                    class="btn btn-icon btn-bg-light btn-sm btn-active-light-primary"
                    onClick={expandPanel}
                  >
                    <i
                      class={ `fs-4 bi ${!expandStatus.value ? 'bi-arrows-angle-expand' : 'bi-arrows-angle-contract'}` }
                    ></i>
                  </div>
                )
              }
              <div
                class="btn btn-icon btn-bg-light btn-sm btn-active-light-primary ms-3"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => emit('update:state', false)}
                style="margin-right: 0.05rem;"
              >
                <pms-kt-icon
                  iconName={'cross'}
                  class={ 'fs-1' }
                />
              </div>
            </div>
          </div>
          <div class="offcanvas-body">
            <div
              class="scroll hover-scroll-x"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#simple_sidebar_content"
              data-kt-scroll-offset="50px"
            >
              <div id="simple_sidebar_content">
                {slots.default && slots.default()}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
})

export default ModalSidebar
