import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementRequestGetRequest,
  SectionManagementRequestGetResponse
} from '@shared/swagger'

export default async function getSectionManagementRequests (params: SectionManagementRequestGetRequest): Promise<AxiosResponse<SectionManagementRequestGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementRequestGetResponse>({
    url: 'academic/manage/sections/requests',
    body: { params: params },
    loader: 'academic/manage/sections/requests'
  })

  return response
}
