import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageAnnouncementsGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesApplicants (year: number): Promise<AxiosResponse<ServiceManageAnnouncementsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ServiceManageAnnouncementsGetResponse>({
    url: 'service/manage/announcements',
    body: { params: { year } },
    loader: 'service/manage/announcements'
  })

  return response
}
