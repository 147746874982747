/* eslint-disable vue/one-component-per-file */
import { PropType, defineComponent } from 'vue'

import { useI18n } from 'vue-i18n'

import { isTranslatable } from '../../utils'
import { DropdownMenuItem, DropdownSubMenuItem } from '../../types'

const DividerMenuItem = defineComponent({
  name: 'DividerMenuItem',
  setup () {
    return () => (
      <div class="separator my-2"></div>
    )
  }
})

const MenuLink = defineComponent({
  name: 'LinkMenuItem',
  props: {
    item: {
      type: Object as PropType<DropdownMenuItem>,
      required: true
    },
    subMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const handleClick = () => {
      props.item.action && props.item.action()
    }

    return () => (
      <a
        href="javascript:void(0)"
        class="menu-link px-5"
        onClick={ () => handleClick() }
      >
        { props.item.menuIcon && (<span style="padding-right: 10px;"><i class={ `bi ${props.item.menuIconClass}`}></i></span>) }
        <span class="menu-title">{ isTranslatable(props.item.label) ? t(props.item.label) : props.item.label }</span>
        { props.subMenu && (<span class="menu-arrow"></span>) }
      </a>
    )
  }
})

const SlotMenuItem = defineComponent({
  name: 'SlotMenuItem',
  props: {
    menuItemClass: {
      type: String,
      required: false,
      default: 'px-5'
    },
    menuItemStyle: {
      type: [String, Object],
      required: false,
      default: ''
    }
  },
  setup (props, { slots }) {
    return () => (
      <div
        class={ `menu-item ${props.menuItemClass}`}
        style={ props.menuItemStyle }
      >
        { slots.default && slots.default() }
      </div>
    )
  }
})

const PmsDropdown = defineComponent({
  name: 'PmsDropdown',
  components: {
    DividerMenuItem,
    MenuLink,
    SlotMenuItem
  },
  props: {
    variant: {
      type: String as PropType<'default' | 'outlined' | 'outlined-dashed' | 'tonal' | 'link'>,
      required: false,
      default: 'default'
    },
    color: {
      type: String as PropType<'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'>,
      required: false,
      default: 'primary'
    },
    btnText: {
      type: String,
      default: 'common.action'
    },
    btnClass: {
      type: String,
      default: 'btn btn-sm btn-light-primary fs-6 fw-bold mb-2'
    },
    contentClass: {
      type: String,
      default: 'menu menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-bold '
    },
    contentBoxClass: {
      type: String,
      default: 'p-3 pe-4 ps-4'
    },
    placement: {
      type: String as PropType<'start' | 'end'>,
      default: 'end'
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconPlacement: {
      type: String as PropType<'start' | 'end'>,
      default: 'start'
    },
    items: {
      type: Array as PropType<DropdownMenuItem[]>,
      required: false,
      default: () => []
    },
    menuClass: {
      type: String,
      required: false,
      default: 'w-200px mt-6'
    },
    menuStyle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    menuItemClass: {
      type: String,
      required: false,
      default: ''
    },
    menuItemStyle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    subMenuClass: {
      type: String,
      required: false,
      default: 'w-200px pt-4 pb-4'
    },
    subMenuStyle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    showContent: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { slots }) {
    const { t } = useI18n()

    const handleMouseAction = (event, state) => {
      const icon = event.target.querySelector('i')
      if (!props.showIcon) return

      if (state && icon) {
        icon?.classList.remove('bi-chevron-down')
        icon?.classList.add('bi-chevron-up')
      } else {
        icon?.classList.remove('bi-chevron-up')
        icon?.classList.add('bi-chevron-down')
      }
    }

    return () => (
      <>
        <style>{'.dropdown-toggle::after { display: none;}'}</style>
        <div
          class={`dropdown ${props.groupClass}`}
          onMouseenter={ (e) => handleMouseAction(e, true) }
          onMouseleave={ (e) => handleMouseAction(e, false) }
        >
          <span
            class={!slots.button &&
              [
                'btn',
                `${props.variant === 'default' ? `btn-${props.color}` : ''}`,
                `${props.variant === 'tonal' ? `btn-light-${props.color}` : ''}`,
                `${props.variant === 'outlined' ? `btn-outline btn-outline-${props.color} btn-active-light-${props.color}` : ''}`,
                `${props.variant === 'outlined-dashed' ? `btn-outline btn-outline-dashed btn-outline-${props.color} btn-active-light-${props.color}` : ''}`,
                `${props.variant === 'link' ? `btn-link btn-color-${props.color} btn-active-color-primary` : ''}`,
                props.btnClass
              ]
            }>
            { slots.button ? slots.button() : null }
            { !slots.button && props.showIcon && (
              <>
                { props.iconPlacement === 'start' && <i class={`bi bi-chevron-down pb-1 ${props.btnText !== '' ? 'pe-2' : 'pe-0'}`}></i> }
                { isTranslatable(props.btnText) ? t(props.btnText) : props.btnText }
                { props.iconPlacement === 'end' && <i class="bi bi-chevron-down pt-0 pe-0 ps-2"></i> }
              </>
            )}
          </span>
          {
            props.showContent && <div class={`dropdown-content ${props.contentClass} ${props.contentBoxClass} dropdown-${props.placement}`}>
              { slots.default
                ? slots.default()
                : <>
                  {
                    slots.header && (
                      <>
                        <div
                          class={`menu-item mb-4 ${props.menuItemClass}`}
                          style={props.menuItemStyle}
                        >
                          {slots.header()}
                        </div>
                        <div class="separator mb-3 opacity-75"></div>
                      </>
                    )
                  }
                  {
                    props.items.map((item: DropdownMenuItem) => {
                      if (item.menuType === 'divider') {
                        return (
                          <divider-menu-item/>
                        )
                      }

                      if (item.menuType === 'text') {
                        return (
                          <slot-menu-item
                            menuItemClass={props.menuItemClass}
                            menuItemStyle={props.menuItemStyle}
                          >
                            {isTranslatable(item.label) ? t(item.label) : item.label}
                          </slot-menu-item>
                        )
                      }

                      if (item.menuType === 'slot') {
                        return (
                          <slot-menu-item
                            menuItemClass={props.menuItemClass}
                            menuItemStyle={props.menuItemStyle}
                          >
                            {item.slotName && slots[item.slotName]?.()}
                          </slot-menu-item>
                        )
                      }

                      if (item.menuType === 'link') {
                        return (
                          <slot-menu-item
                            menuItemClass={props.menuItemClass}
                            menuItemStyle={props.menuItemStyle}
                          >
                            <menu-link item={item}/>
                          </slot-menu-item>
                        )
                      }

                      if (item.menuType === 'sub-menu') {
                        return (
                          <slot-menu-item
                            menuItemClass={props.menuItemClass}
                            menuItemStyle={props.menuItemStyle}
                            data-kt-menu-trigger={item.subDropdownTrigger || 'hover'}
                            data-kt-menu-placement={item.subDropdownPlacement || 'left-start'}
                            data-kt-menu-flip="center, top"
                          >
                            <menu-link
                              item={item}
                              sub-menu={true}
                            />
                            <div
                              class={`menu-sub menu-sub-dropdown m-2 ${props.subMenuClass}`}
                              style={props.subMenuStyle}
                            >
                              {
                                slots[`sub-header-${item.subDropdownName}`] && (
                                  <>
                                    <div
                                      class={`menu-item mb-4 ${props.menuItemClass}`}
                                      style={props.menuItemStyle}
                                    >
                                      {slots[`sub-header-${item.subDropdownName}`]?.()}
                                    </div>
                                    <div class="separator mb-3 opacity-75"></div>
                                  </>
                                )
                              }
                              {
                                item.subDropdown?.map((subItem: DropdownSubMenuItem) => {
                                  if (subItem.menuType === 'divider') {
                                    return (
                                      <divider-menu-item/>
                                    )
                                  }

                                  if (subItem.menuType === 'text') {
                                    return (
                                      <slot-menu-item
                                        menuItemClass={props.menuItemClass}
                                        menuItemStyle={props.menuItemStyle}
                                      >
                                        {isTranslatable(subItem.label) ? t(subItem.label) : subItem.label}
                                      </slot-menu-item>
                                    )
                                  }

                                  if (subItem.menuType === 'slot') {
                                    return (
                                      <slot-menu-item
                                        menuItemClass={props.menuItemClass}
                                        menuItemStyle={props.menuItemStyle}
                                      >
                                        {subItem.slotName && slots[subItem.slotName]?.()}
                                      </slot-menu-item>
                                    )
                                  }

                                  if (subItem.menuType === 'link') {
                                    return (
                                      <slot-menu-item
                                        menuItemClass={props.menuItemClass}
                                        menuItemStyle={props.menuItemStyle}
                                      >
                                        <menu-link item={subItem}/>
                                      </slot-menu-item>
                                    )
                                  }

                                  return (
                                    <slot-menu-item
                                      menuItemClass={props.menuItemClass}
                                      menuItemStyle={props.menuItemStyle}
                                      dangerouslySetInnerHTML={isTranslatable(subItem.label) ? t(subItem.label) : subItem.label}
                                    >
                                    </slot-menu-item>
                                  )
                                })
                              }
                              {
                                slots[`sub-footer-${item.subDropdownName}`] && (
                                  <>
                                    <div
                                      class={`menu-item mb-4 ${props.menuItemClass}`}
                                      style={props.menuItemStyle}
                                    >
                                      {slots[`sub-footer-${item.subDropdownName}`]?.()}
                                    </div>
                                    <div class="separator mb-3 opacity-75"></div>
                                  </>
                                )
                              }
                            </div>
                          </slot-menu-item>
                        )
                      }

                      return (
                        <slot-menu-item
                          menuItemClass={props.menuItemClass}
                          menuItemStyle={props.menuItemStyle}
                          dangerouslySetInnerHTML={isTranslatable(item.label) ? t(item.label) : item.label}
                        >
                        </slot-menu-item>
                      )
                    })
                  }
                  {
                    slots.footer && (
                      <>
                        <div class="separator mb-4 mt-2 opacity-75"></div>
                        <div
                          class={`menu-item ${props.menuItemClass}`}
                          style={props.menuItemStyle}
                        >
                          {slots.footer()}
                        </div>
                      </>
                    )
                  }
                </>
              }
            </div>
          }
        </div>
      </>
    )
  }
})

export default PmsDropdown
