import type { StoreType } from '../types'
import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType, view_mode:string): Promise<void> {
  const resource = useResource()
  resource.fetchServiceMessageBroadcastResource()

  this.currentMessageView = view_mode ?? resource.serviceMessageBroadcastResource?.types[0] ?? 'inbox'
  this.fetchMessages(this.currentMessageView)
}
