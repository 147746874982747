<template>
  <div
    id="change-password"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.settings.user-card.header') }}
      </span>
    </div>
    <pms-card
      :header="false"
      class="mt-4 pb-5"
      :options="{
        body: {
          class: 'pb-3'
        }
      }"
    >
      <div class="d-flex flex-wrap align-items-center">
        <div>
          <div class="fs-4 fw-bold mb-1">
            {{ $t('domains.profile.settings.password-card.password') }}
          </div>
          <div class="fw-semibold text-gray-600">
            ************
          </div>
        </div>
        <div class="ms-auto">
          <pms-button
            :size="'sm'"
            :class="{
              'btn-active-light-primary': !editable,
              'btn-active-light-warning': editable
            }"
            @click="editable = !editable"
          >
            {{ $t(!editable ? 'common.change' : 'common.cancel') }}
          </pms-button>
        </div>
      </div>
      <div
        v-if="editable"
        class="flex-row-fluid mt-4"
      >
        <PasswordModes />
        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
        >
          <template #formControls>
            <div class="d-flex justify-content-end mb-3 mt-6">
              <button
                class="btn btn-light btn-sm btn-active-light-primary me-2"
                type="button"
                @click="discardChanges()"
              >
                {{ $t('common.discard') }}
              </button>
              <button
                class="btn btn-primary btn-sm"
                type="submit"
              >
                {{ !passwordLoader ? $t('phrases.save-changes') : $t('phrases.saving') }}
                <span
                  v-if="passwordLoader"
                  class="spinner-border spinner-border-sm align-middle ms-2"
                />
              </button>
            </div>
          </template>
        </pms-smart-form>
      </div>
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { useLoader, useAlert } from '@shared/composable'
import { FormField } from '@shared/types'

import { useProfile } from '../../../store'
import { passwordMode, editable } from '../values'

import PasswordModes from './PasswordModes.vue'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const { isActive } = useLoader()
const router = useRouter()
const { error, success } = useAlert()

const store = useProfile()

const passwordLoader = isActive('profile/settings/passwords-edit')

const discardChanges = () => {
  passwordMode.value = 'system'
  editable.value = false
}

const onSubmit = async ({ values }) => {
  if (!values.current_password) {
    error({ text: 'domains.authenticator.form.current-password-error' })
    return
  }
  if (!values.new_password) {
    error({ text: 'domains.authenticator.form.new-password-error' })
    return
  }
  if (!values.confirm_password) {
    error({ text: 'domains.authenticator.form.confirm-password-error' })
    return
  }
  const { status, message } = await store.editPassword({ reset: passwordMode.value, ...values })

  if (status) {
    success({ text: t('domains.profile.settings.password-success') })

    if (passwordMode.value === 'system') router.push({ path: '/auth/sign-out' })
  } else error({ text: message })
}

const formFields: Array<FormField> = [
  {
    type: 'password',
    name: 'current_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.current-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'current-password'
    },
    placeholder: t('domains.authenticator.form.current-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.current-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'new_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.new-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'new-password'
    },
    placeholder: t('domains.authenticator.form.new-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.new-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'confirm_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.confirm-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'confirm-password'
    },
    placeholder: t('domains.authenticator.form.confirm-password-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.confirm-password') },
        { func: 'min', value: 6 },
        { func: 'max', value: 30 }
      ]
    }
  }
]
</script>
