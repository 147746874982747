import { AcademicSingleCourseAttendancesByCourseStudentsGetResponse } from '@/shared/swagger'
import { TableDataWithRowspan } from '../types'
import { current_course } from '@/domains/AcademicSingleCourse/values'

export function addRowspan (array:AcademicSingleCourseAttendancesByCourseStudentsGetResponse):TableDataWithRowspan {
  const students = {}
  const sections = {}
  let rownum = 0;
  (array as TableDataWithRowspan).forEach(student => {
    if (!students[student.student_id]) {
      students[student.student_id] = 1
      student.student_rowspan = 1
    } else {
      students[student.student_id] += 1
      student.student_rowspan = 0
    }
    if (!sections[student.student_id]) {
      sections[student.student_id] = { [student.section]: { [student.lesson_date]: 1 } }
      student.section_rowspan = 1
    } else {
      if (!sections[student.student_id][student.section]) {
        sections[student.student_id][student.section] = { [student.lesson_date]: 0 }
      }
      if (sections[student.student_id][student.section][student.lesson_date] > 0) {
        student.section_rowspan = 0
      } else {
        student.section_rowspan = 1
      }
      if (!sections[student.student_id][student.section][student.lesson_date]) {
        sections[student.student_id][student.section][student.lesson_date] = 0
      }
      sections[student.student_id][student.section][student.lesson_date] += 1
    }
  });
  (array as TableDataWithRowspan).forEach(student => {
    if (student.student_rowspan === 1) {
      student.student_rowspan = students[student.student_id]
      student.rownum = ++rownum
    }
    if (student.section_rowspan === 1) {
      student.section_rowspan = sections[student.student_id][student.section][student.lesson_date]
    }
    const lession_date = new Date(`${student.lesson_date.split('-')[1]}-${student.lesson_date.split('-')[0]}-${student.lesson_date.split('-')[2]}`)
    if (lession_date <= new Date() || current_course.value.grading_type === 'S') {
      student.date_available = true
    } else {
      student.date_available = false
    }
  })
  return array as TableDataWithRowspan
}
