<template>
  <div
    class="form-group"
    :class="field?.group?.class"
    :style="field?.group?.style"
  >
    <label
      v-if="field.label && field.label.text"
      :for="field.name"
      :class="field.label.class"
      :style="field.label.style"
    >
      {{ field.label.text }}
    </label>
    <div class="input-group">
      <input
        :id="field.name"
        v-bind="field.binding || {}"
        :type="field.type || 'text'"
        :name="field.name"
        class="form-control"
        :class="field.class"
        :style="field.style"
        :placeholder="field.placeholder"
        :value="field.value"
        :required="field.required"
        :disabled="field.disabled"
        @input="updateValue"
      >
      <button
        :class="field?.button?.class"
        :style="field?.button?.style"
        :type="field?.button?.type ?? 'button'"
        @click="clickEvent"
      >
        {{ field?.button?.text }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { FormField } from '@shared-types/form'
import { WithButton } from '../types'
const emit = defineEmits(['click', 'update', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: String || Number,
    required: false,
    default: ''
  },
  field: {
    type: Object as PropType<FormField & WithButton>,
    required: true
  }
})
const updateValue = ($event) => {
  emit('update:modelValue', $event.target.value)
  emit('update', $event.target.value)
  props.field.value = $event.target.value
}
const clickEvent = () => {
  if (props.field?.button?.action) {
    props.field.button.action(props.field)
  } else {
    emit('click', props.field)
  }
}
</script>
