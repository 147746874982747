import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  DashboardWidgetsPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postDashboardWidgets (params: DashboardWidgetsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<DashboardWidgetsPostRequest, UnknownResponse>({
    url: 'dashboard/widgets',
    body: params,
    loader: '/1.0.0/dashboard/widgets'
  })

  return response
}
