import type { StoreType } from '../types'
import type { QueryParams } from '../../feature/CurriculaViewPage/types'

import { useResource } from '@/shared/stores'

export default async function bootstrapSingleCurricula (this: StoreType, params: QueryParams): Promise<void> {
  const resource = useResource()

  await Promise.all([
    resource.fetchAcademicManageCurriculumResource({ modules: [] }),
    resource.fetchResource({ modules: ['year-terms'] }),
    this.fetchCurricula(params)
  ])
}
