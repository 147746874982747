import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, ResourceResponse, ResourceRequest } from '@shared/swagger'

export default async function getResource (params: ResourceRequest): Promise<AxiosResponse<ResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ResourceResponse>({
    url: 'resources',
    body: { params: params },
    loader: 'resources'
  })

  return response
}
