<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
      :filter="true"
    />
    <pms-row
      v-else
      class="gap-10"
    >
      <ArticleCard
        v-for="(item, index) in articles.keys()"
        :key="index"
        :type="item"
        :articles="articles.get(item)"
      />
      <ConferenceCard
        v-for="(item, index) in conferences.keys()"
        :key="index"
        :type="item"
        :conferences="conferences.get(item)"
      />
      <BookCard />
    </pms-row>
    <pms-teleport-modal
      :name="'publication-modal'"
      :teleport-name="'publication-modal-content'"
      :title="$t(`domains.profile.publications.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`domains.profile.publications.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'

import { useDriver, useLoader, useTeleportModal } from '@shared/composable'

import { useProfile } from '../../store'

import ArticleCard from './ui/ArticleCard.vue'
import ConferenceCard from './ui/ConferenceCard.vue'
import BookCard from './ui/BookCard.vue'
import { quickTourSteps } from './values'

const { isActiveOneOf } = useLoader()

const store = useProfile()

const loader = isActiveOneOf(['profile/skills/languages', 'profile/skills/interests', 'profile/skills/hard-skills'])
const { modifier } = useTeleportModal()
const { setSteps: setQuickTourSteps } = useDriver()

const articles = computed(() => store.getArticles)
const conferences = computed(() => store.getConferences)

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
