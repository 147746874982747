import type { StoreType } from '../types'

import { getProfileCertificates } from '@shared/api'

export default async function fetchCertificates (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileCertificates()

  if (ok) {
    this.setCertificate(data)
  }
}
