import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.full-name',
    field: 'fullName',
    slotCellName: 'fullName'
  },
  {
    title: 'common.table.birth-date-gender',
    field: 'birthDateGender',
    slotCellName: 'birthDateGender'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
