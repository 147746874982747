import type { StoreType } from '../types'

import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType, student_id? : string): Promise<void> {
  if (student_id) {
    this.view = 'student'
    this.searchStudent = Number(student_id)
  }
  const resource = useResource()

  await Promise.all([
    resource.fetchAdministrativeResource({ modules: ['gate-entry-types', 'departments'] }),
    resource.fetchAdministrativeGateEntryResource({})
  ])
}
