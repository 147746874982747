import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationConferencesPostRequest } from '@shared/swagger'

export default async function postProfileEducationConferences (params: ProfileEducationConferencesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationConferencesPostRequest, UnknownResponse>({
    url: 'profile/education/conferences',
    body: params,
    loader: 'profile/education/conferences-post'
  })

  return response
}
