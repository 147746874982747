<template>
  <div>Signing out...</div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useUser } from '@shared/stores'
import { useToken } from '@/shared/composable'

const user = useUser()

onMounted(() => {
  user.setUser(null)

  useToken().clearTokens()
})
</script>
