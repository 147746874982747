import { RouteRecordWithLayout } from '@shared/types'

export default {
  layout: 'system',
  path: '/errors',
  redirect: '/404',
  children: [
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    },
    {
      name: 'page404',
      path: '/404',
      component: () => import('./features/ErrorNotFound.vue')
    },
    {
      name: 'page500',
      path: '/500',
      component: () => import('./features/ErrorInternalServerError.vue')
    }
  ]
} as RouteRecordWithLayout
