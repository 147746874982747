import { defineStore } from 'pinia'
import * as actions from './actions'
import type { Name, State, Getters, Actions } from './types'

export const useRulesAndRegulations = defineStore<Name, State, Getters, Actions>('Administrative.useRulesAndRegulations', {
  actions: {
    ...actions
  },

  state () {
    return {
      rules: [],
      singleRule: null,
      categories: [],
      languages: []
    }
  }
})
