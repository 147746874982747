import type { SelectField } from '@/shared/types'
import type { AcademicResourceResponse } from '@shared/swagger'

export default (data: AcademicResourceResponse['instructors']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((editor) => {
    options.push({
      label: editor.full_name,
      value: editor.id
    })
  })
  return options
}
