import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManagePostsContentsPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putKnowledgeManagePostsContents (uuid: string, body: KnowledgeManagePostsContentsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<KnowledgeManagePostsContentsPutRequest, UnknownResponse>({
    url: `knowledge/manage/posts/${uuid}/contents`,
    body,
    loader: 'knowledge/manage/posts/edit-contents'
  })
  return response
}
