<template>
  <div
    id="user-information-settings"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.settings.user-card.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        :class="{
          'btn-primary': !editable,
          'btn-active-light-primary': editable
        }"
        @click="editable = !editable"
      >
        <span class="fw-bold fs-6">
          {{ $t(!editable ? 'common.edit' : 'common.cancel') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-row class="mb-6">
        <label class="col-lg-4 fw-semibold fs-6">
          {{ $t('domains.profile.settings.user-card.photo') }}
        </label>
        <div class="col-lg-8">
          <div
            class="image-input image-input-outline"
            style="background-image: url('/media/svg/avatars/blank.svg'); object-fit: cover; background-repeat: no-repeat; background-position: center;"
          >
            <div
              v-styler="{ backgroundImage: `url(${currentUser?.photo})` }"
              class="image-input-wrapper w-125px h-125px"
            />
            <label
              v-if="editable"
              class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="modal"
              data-bs-target="#avatar_modal"
              :title="$t('domains.profile.settings.user-card.photo-tooltip')"
              @click="getCropperWidth()"
            >
              <i class="bi bi-pencil-fill fs-7" />
            </label>
            <avatar-editor
              v-if="currentUser?.photo"
              :avatar="currentUser.photo"
              :cropper-width="cropperWidth"
              @uploadAvatar="uploadAvatar"
            />
          </div>
          <div
            v-if="editable"
            class="form-text"
          >
            {{ $t('domains.profile.settings.user-card.photo-allowed') }}
          </div>
        </div>
      </pms-row>
      <pms-smart-form
        :fields="formFields"
        :options="formOptions"
      >
        <template #formControls />
      </pms-smart-form>
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUser } from '@shared/stores'
import { FormGroup, FormOptions } from '@/shared/types'
import { useProfile } from '../../../store'

import { cropperWidth, getCropperWidth } from '../utils'
import AvatarEditor from './AvatarEditor.vue'

const { t } = useI18n()
const user = useUser()
const store = useProfile()

const currentUser = computed(() => user.currentUser)
const editable = ref(false)

const uploadAvatar = async (details: { photo: string, callback }) => {
  const avatar = details.photo.replace(/^data:image\/[a-z]+;base64,/, '')
  const { status } = await store.editPhoto({ photo: avatar })
  if (status) {
    window.location.reload()
  }
}

const formOptions: FormOptions = {
  labelContainer: {
    left: {
      class: 'col-lg-4'
    },
    right: {
      class: 'col-lg-8 fv-row'
    }
  },
  group: {
    class: 'mb-6'
  }
}

const formFields = computed<Array<FormGroup>>(() => [
  {
    label: {
      text: t('domains.profile.settings.user-card.name'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'name',
      type: 'text',
      value: currentUser.value?.name,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.surname'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'surname',
      type: 'text',
      value: currentUser.value?.surname,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.native-name'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'nativeName',
      type: 'text',
      value: currentUser.value?.nativeName,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.native-surname'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'nativeSurname',
      type: 'text',
      value: currentUser.value?.nativeSurname,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.birth-date'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'birthDate',
      type: 'text',
      value: currentUser.value?.birthDate,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.username'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      name: 'username',
      type: 'text',
      value: currentUser.value?.username,
      disabled: true
    }]
  },
  {
    label: {
      text: t('domains.profile.settings.user-card.email'),
      class: 'required fw-semibold fs-6'
    },
    fields: [{
      class: 'col-4',
      name: 'email',
      type: 'text',
      value: currentUser.value?.email,
      disabled: true
    }]
  }
])

</script>
