import { ProfileOverviewRequest } from '@/shared/swagger'
import type { StoreType } from '../types'

import { getProfileOverview } from '@shared/api'

export default async function fetchOverview (this: StoreType, params: ProfileOverviewRequest): Promise<void> {
  const { ok, data } = await getProfileOverview(params)

  if (ok) {
    this.setOverview(data)
  }
}
