import { ServiceManageLocksGetResponse } from '@/shared/swagger'
import { FilteredStudent } from '../values'

export default function resFilter (students: ServiceManageLocksGetResponse['students']) {
  const result: FilteredStudent[] = []
  for (const item of students) {
    for (const lock of item.locks) {
      result.push({ id: item.id, name: item.name, surname: item.surname, lock_id: lock.id, start_date: lock.start_date, end_date: lock.end_date, requestedBy: lock.requester_department_title, is_active: lock.is_active })
    }
  }

  return result
}
