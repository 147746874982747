import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  AcademicSingleCourseGradePutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAdministrativeMessageBoardPosts (assessment: number | string, values: AcademicSingleCourseGradePutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<UnknownResponse, UnknownResponse>({
    url: `academic/courses/grades/${assessment}`,
    body: values,
    loader: 'academic/courses/grades-put'
  })

  return response
}
