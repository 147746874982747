import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useStore } from './store'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useStore()

  store.$reset()
  store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/academic/courses/my-schedule',
  children: [
    {
      path: '',
      name: 'AcademicCoursesMySchedule',
      component: () => import('./MySchedulePage.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    }
  ]
} as RouteRecordWithLayout
