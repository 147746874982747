import { useRequests } from '@/shared/composable'

import type { AxiosResponse } from '@/shared/types'
import type { StudentMeResponse, UnknownRequest } from '@shared/swagger'

export default async function getStudentMe (): Promise<AxiosResponse<StudentMeResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, StudentMeResponse>({
    url: 'student/me',
    body: {},
    loader: 'student/me',
    camelize: true,
    configs: {
      userType: 'student'
    }
  })

  return response
}
