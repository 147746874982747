import type { StoreType } from '../types'

import { ServiceEsignDocumentGetRequest } from '@/shared/swagger'
import { useLoader } from '@/shared/composable'
import axios, { AxiosResponse } from 'axios'

export default async function fetchEsignDocument (this: StoreType, params: ServiceEsignDocumentGetRequest): Promise<Blob> {
  const loader = useLoader()
  loader.activate(`service/manage/esigns/${params.documentOrder}`)
  const response = await axios.get<ServiceEsignDocumentGetRequest, AxiosResponse<Blob>>(`service/manage/esigns/${params.documentOrder}`, {
    responseType: 'blob'
  }).then((response) => {
    return response.data
  }).finally(() => {
    loader.deactivate(`service/manage/esigns/${params.documentOrder}`)
  })
  return response
}
