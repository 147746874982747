import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import { ServiceEsignGetResponse } from '@/shared/swagger'

export const useServiceEsignStore = defineStore<Name, State, Getters, Actions>('Service.useServiceEsignStore', {
  actions: {
    ...actions
  },

  state () {
    return {
      esign_students: {
        signature_documents: []
      } as unknown as ServiceEsignGetResponse
    }
  }
})
