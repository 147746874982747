import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicManageCoursesRequisitesItemsDeleteRequest, UnknownResponse } from '@shared/swagger'

export default async function (body: AcademicManageCoursesRequisitesItemsDeleteRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<AcademicManageCoursesRequisitesItemsDeleteRequest, UnknownResponse>({
    url: `academic/manage/courses/requisites/items/${body.id}`,
    body,
    loader: 'academic/manage/courses/requisites/items'
  })

  return response
}
