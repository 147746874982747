import { RouteRecordWithLayout } from '@shared/types'
import { useAcademicReports } from './store'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useAcademicReports()
  store.bootstrap(to)
  next()
}

export default {
  layout: 'default',
  path: '/academic/reports',
  children: [
    {
      path: '',
      name: 'AcademicReports',
      component: () => import('./AcademicReports.vue'),
      beforeEnter: [
        handleRoute
      ],
      children: [
        {
          path: '',
          name: 'AcademicReportsSearchPage',
          component: () => import('./features/SearchPage/SearchPage.vue'),
          meta: {
            page: 'search'
          }
        },
        {
          path: 'view/:report_id',
          name: 'AcademicReportViewPage',
          component: () => import('./features/ViewPage/ViewPage.vue'),
          meta: {
            page: 'view'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
