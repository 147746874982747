<template>
  <div
    id="table-component"
    class="m-0"
  >
    <h2
      class="align-items-start flex-column mt-2 mb-4 pb-2 ml-2 text-center"
    >
      <span
        id="status-title"
        class="d-block fw-bolder fs-2"
      >
        {{ $t('domains.administrative.system-calendar.views.permissions') }}
      </span>
      <span
        id="status-description"
        class="text-muted fw-bold fs-7"
      >
        {{ $t('domains.administrative.system-calendar.views.permissions-description') }}
      </span>
    </h2>
    <pms-smart-table
      :headers="headers"
      :items="permissions"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #title="{ value }">
        <span class="fs-5 fw-bold">{{ value }}</span>
      </template>
      <template #permitted="{ value }">
        <span class="fs-5 fw-bold">{{ value }}</span>
      </template>
      <template #startDate="{ value }">
        <template v-if="value">
          <span class="text-dark fw-bolder d-block mb-1 fs-4">
            {{ value }}
          </span>
          <span class="text-muted fw-bold text-muted d-block fs-7">
            {{ agoMessageForDate(value, 'start') }}
          </span>
        </template>
      </template>
      <template #endDate="{ value }">
        <template v-if="value">
          <span class="text-dark fw-bolder d-block mb-1 fs-4">
            {{ value }}
          </span>
          <span class="text-muted fw-bold text-muted d-block fs-7">
            {{ agoMessageForDate(value, 'end') }}
          </span>
        </template>
      </template>
      <template #status="{value}">
        <span
          class="badge me-2 fs-5"
          :class="{
            'badge-light-success': value,
            'badge-light-danger': !value
          }"
        >
          {{ $t(`domains.administrative.system-calendar.table.status-${value}`) }}
        </span>
      </template>
    </pms-smart-table>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

import { dateHelper } from '@shared/utils'

import { useSystemCalendar } from '../store'
import { individualPermissionsTable as headers } from '../values'

const { agoMessageForDate } = dateHelper('-')

const store = useSystemCalendar()
const permissions = computed(() => store.getIndividualPermissions)
</script>
