import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { putServiceManageAnnouncementPublish } from '@/shared/api'
import { ServiceManageSingleAnnouncementPublishRequest } from '@shared/swagger'
import { tempValues } from '../../values'

export default async function editRuleAttachment (this: StoreType, announcementId: number, values: ServiceManageSingleAnnouncementPublishRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageAnnouncementPublish(announcementId, values)
  ok && await this.fetchAnnouncements(tempValues.value.year.getFullYear())
  return {
    status: ok,
    message: message
  }
}
