<template>
  <p class="fs-5 fw-bold p-0 mb-2">
    {{ $t('domains.academic.manage.sections.request-program-component') }}
  </p>
  <div class="d-flex gap-4 mb-4">
    <div
      class="d-flex gap-3 align-items-center"
    >
      <p class="fs-5 m-0">
        {{ $t('common.year') }}
      </p>
      <select
        class="form-select form-select-solid form-select-sm w-80px"
        @change="setSelectYear($event)"
      >
        <option :value="null" />
        <option
          v-for="year in years"
          :key="year"
          :value="year"
        >
          {{ year }}
        </option>
      </select>
    </div>
    <div
      class="d-flex gap-3 align-items-center"
    >
      <p class="fs-5 m-0">
        {{ $t('common.term') }}
      </p>
      <select
        class="form-select form-select-solid form-select-sm w-80px"
        @change="setSelectTerm($event)"
      >
        <option
          :value="null"
          :disabled="selecteds.year === null"
        />
        <option
          v-for="term in terms"
          :key="term"
          :value="term"
          :disabled="selecteds.year === null"
        >
          {{ term }}
        </option>
      </select>
    </div>
    <pms-dropdown
      :btn-class="'btn btn-light-primary btn-sm p-2 px-4'"
      :icon-placement="'start'"
      :placement="'start'"
      :items="dropdownItems"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { DropdownMenuItem } from '@shared/types'
import { useNewRequest } from '../store'
import { useRoute } from 'vue-router'
import { useAlert } from '@/shared/composable'

const route = useRoute()
const newRequestStore = useNewRequest()
const { error } = useAlert()
const years = computed(() => newRequestStore.getSelectedProgramsYears)
const terms = computed(() => newRequestStore.getSelectedProgramsTerms)
const selecteds = ref({
  year: null,
  term: null
})

const dropdownItems : DropdownMenuItem[] = [
  {
    label: 'domains.academic.manage.section.new-request.show-core',
    menuType: 'link',
    async action () {
      newRequestStore.isFetched = true
      newRequestStore.program_request_term = selecteds.value.term
      newRequestStore.program_request_year = selecteds.value.year
      const { status, message } = await newRequestStore.fetchRequestCourses(
        {
          request_by: newRequestStore.requestType || '',
          year: Number(route.query.year) || undefined,
          term: Number(route.query.term) || undefined,
          period_number: selecteds.value.term || undefined,
          program_year: selecteds.value.year || undefined,
          program_code: newRequestStore.selectedProgram?.code,
          course_type: 'C'
        }
      )
      if (!status) {
        error({
          text: message
        })
      }
      newRequestStore.program_request_type = 'C'
    }
  },
  {
    label: 'domains.academic.manage.section.new-request.show-elective',
    menuType: 'link',
    async action () {
      newRequestStore.isFetched = true
      newRequestStore.program_request_term = selecteds.value.term
      newRequestStore.program_request_year = selecteds.value.year
      const { status, message } = await newRequestStore.fetchRequestCourses(
        {
          request_by: newRequestStore.requestType || '',
          year: Number(route.query.year) || undefined,
          period_number: selecteds.value.term || undefined,
          term: Number(route.query.term) || undefined,
          program_code: newRequestStore.selectedProgram?.code,
          program_year: selecteds.value.year || undefined,
          course_type: 'E'
        }
      )
      if (!status) {
        error({
          text: message
        })
      }
      newRequestStore.program_request_type = 'E'
    }
  }
]

function setSelectYear ($event) {
  if ($event.target.value) {
    selecteds.value.year = $event.target.value
  } else {
    selecteds.value.year = null
  }
}
function setSelectTerm ($event) {
  if ($event.target.value) {
    selecteds.value.term = $event.target.value
  } else {
    selecteds.value.term = null
  }
}
</script>
