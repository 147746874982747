import type { StoreType } from '../types'

import { getAdministrativeStaffsWorkloadResources } from '@shared/api'

export default async function fetchAdministrativeStaffsWorkloadResources (this: StoreType): Promise<void> {
  const { ok, data } = await getAdministrativeStaffsWorkloadResources()

  if (ok) {
    this.setAdministrativeStaffsWorkloadResources(data)
  }
}
