import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagement1Section1ProgramGetResponse
} from '@shared/swagger'

export default async function getScheduleManagement1Section1Program (section: string, program: string): Promise<AxiosResponse<ScheduleManagement1Section1ProgramGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagement1Section1ProgramGetResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}`,
    body: {},
    loader: 'academic/manage/schedules/sections-single/programs-single'
  })

  return response
}
