<template>
  <div
    id="kt_app_sidebar_logo"
    class="app-sidebar-logo px-6"
  >
    <a
      href="https://sdu.edu.kz/"
      target="_blank"
    >
      <img
        v-if="
          layout === 'dark-sidebar' ||
            (themeMode === 'dark' && layout === 'light-sidebar')
        "
        src="/new-logo-light-sidebar.png"
        class="h-60px app-sidebar-logo-default"
      >
      <img
        v-if="themeMode === 'light' && layout === 'light-sidebar'"
        src="/new-logo-light-sidebar.png"
        class="h-25px app-sidebar-logo-default"
      >
      <img
        src="/new-logo-light-sidebar.png"
        class="h-20px app-sidebar-logo-minimize"
      >
    </a>
    <div
      v-if="sidebarToggleDisplay"
      id="kt_app_sidebar_toggle"
      ref="toggleRef"
      class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
      data-kt-toggle="true"
      data-kt-toggle-state="active"
      data-kt-toggle-target="body"
      data-kt-toggle-name="app-sidebar-minimize"
    >
      <span class="svg-icon svg-icon-2 rotate-180">
        <pms-kt-icon
          icon-name="double-left"
          icon-class="fs-2 rotate-180"
        />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { ToggleComponent } from '@/assets/ts/components'
import { layout, themeMode, sidebarToggleDisplay } from '@shared-composable/pattern'

interface IProps {
  sidebarRef: HTMLElement | null;
}

const props = defineProps<IProps>()
const toggleRef = ref<HTMLFormElement | null>(null)

onMounted(() => {
  setTimeout(() => {
    const toggleObj = ToggleComponent.getInstance(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toggleRef.value!
    ) as ToggleComponent | null

    if (toggleObj === null) {
      return
    }

    // Add a class to prevent sidebar hover effect after toggle click
    toggleObj.on('kt.toggle.change', function () {
      // Set animation state
      props.sidebarRef?.classList.add('animating')

      // Wait till animation finishes
      setTimeout(function () {
        // Remove animation state
        props.sidebarRef?.classList.remove('animating')
      }, 300)
    })
  }, 1)
})

</script>
