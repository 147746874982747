import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, UnknownRequest } from '@shared/swagger'

export default async function clearProgram (section: number, program: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownRequest, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs/${program}/clear`,
    body: { },
    loader: 'clear-academic/manage/schedules/sections/program'
  })

  return response
}
