<template>
  <div class="d-flex justify-content-end mb-2">
    <export-button />
  </div>
  <pms-card
    :header="false"
    :options="{
      card: {class: 'rounded-3 shadow-sm'},
      body: {class: 'p-0 rounded-3'}
    }"
  >
    <div class="table-responsive">
      <table
        id="singleCourseSchedulePrint"
        class="table table-row-bordered align-middle text-center gy-4 mb-0 pb-0"
      >
        <table-head />
        <table-body
          :schedule="schedule"
          :schedule-hours="scheduleHours"
        />
      </table>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '../store'

import ExportButton from './ExportButton.vue'
import TableHead from '@domains/AcademicSchedule/ui/TableHead.vue'
import TableBody from '@domains/AcademicSchedule/ui/TableBody.vue'
import { useResource } from '@/shared/stores'

const resource = useResource()

const store = useStore()
const schedule = computed(() => store.getScheduleRecords)
const scheduleHours = computed(() => {
  return resource.scheduleManagementSectionProgramHours ?? []
})

</script>
<style lang="scss" scoped>
.hover-bg-active:hover {
  background-color: var(--kt-secondary);
}
.course-hover-bg:hover {
  background-color: #e4f2fd;
}
</style>
