import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordWithLayout } from '@shared/types'

import { useGateEntryStore } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const student_id = to.query.student_id ? String(to.query.student_id) : undefined
  const store = useGateEntryStore()
  store.$reset()

  if (!student_id) {
    store.bootstrap()
    next()
  }
  store.bootstrap(student_id)
  next()
}

export default {
  layout: 'default',
  path: '/administrative/gate-entry',
  children: [
    {
      path: '',
      name: 'GateEntryPage',
      component: () => import('./GateEntryPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
