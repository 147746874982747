import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useSentRequests = defineStore<Name, State, Getters, Actions>('SectionManagement.features.useSentRequests', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      sentRequests: [],
      sentRequestQuotas: null,
      sectionsCourses: [],
      step: 'program',
      request_id: -1,
      quota_id: -1,
      status: 0,
      currentProgram: 'common.all-programs'
    }
  }
})
