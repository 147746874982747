<template>
  <div class="row mt-3">
    <div class="col-6 d-flex align-items-center">
      <div class="me-3">
        {{ $t('common.table.section') }}:
      </div>
      <select
        v-model="choosen.section"
        class="form-select"
      >
        <option :value="null">
          {{ $t('domains.academic.single-course.attendance-qr.choose-section') }}
        </option>
        <option
          v-for="section,index in sections"
          :key="index"
          :value="section"
        >
          {{ section }}
        </option>
      </select>
    </div>
  </div>

  <div class="d-flex align-items-center mt-3">
    <template
      v-for="time,index in times"
      :key="index"
    >
      <pms-button
        :variant="choosen.hour === time.datetime ? 'default':'outlined'"
        color="primary"
        class="me-2"
        @click="chooseTime(time)"
      >
        {{ time.datetime }}
      </pms-button>
    </template>
  </div>
</template>
<script setup lang="ts">
import { useAttendanceQR } from '../composable'
const {
  times,
  sections,
  choosen,
  chooseTime
} = useAttendanceQR()
</script>
