<template>
  <pms-card
    :header="false"
    :options="{body: {class: 'px-0 py-6'}}"
  >
    <div class="menu menu-rounded fs-5 menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold">
      <ContentLink
        :title="$t('domains.academic.manage.syllabus.content.gen-info')"
        class="border-3 border-start"
        :class="{ 'border-primary': isOpenInformation }"
        module="basic"
        @click="informationToggle"
      />
      <pms-accordion
        class="border-3 border-start"
        :class="{ 'border-primary': isOpenDescription || description}"
        :items="[
          { title: $t('domains.academic.manage.syllabus.content.description'), slot: 'description' }
        ]"
        :open="isOpenDescription || description"
        :options="{
          body: {
            class: 'py-0 px-0'
          },
          title: {
            class: 'fs-5 fw-bold text-gray-700 py-4 ps-5'
          },
          item: {
            class: 'border-0 border-top'
          }
        }"
        @onOpen="descriptionToggle"
      >
        <template #description>
          <template
            v-for="content in descriptionColumn"
            :key="content.module"
          >
            <ContentLink
              :title="$t(content.title)"
              :module="content.module"
              :secondary="true"
            />
          </template>
        </template>
      </pms-accordion>

      <pms-accordion
        class="border-3 border-start"
        :class="{ 'border-primary': isOpenContents || contents }"
        :items="[
          { title: $t('domains.academic.manage.syllabus.content.contents'), slot: 'contents' }
        ]"
        :open="isOpenContents || contents"
        :options="{
          body: {
            class: 'py-0 px-0'
          },
          title: {
            class: 'fs-5 fw-bold text-gray-700 py-4 ps-5'
          },
          item: {
            class: 'border-0 border-top'
          }
        }"
        @onOpen="contentsToggle"
      >
        <template #contents>
          <template
            v-for="content in contentsColumn"
            :key="content.module"
          >
            <ContentLink
              :title="$t(content.title)"
              :module="content.module"
              :secondary="true"
            />
          </template>
        </template>
      </pms-accordion>

      <pms-accordion
        class="border-3 border-start"
        :class="{ 'border-primary': isOpenOthers || others }"
        :items="[
          { title: $t('domains.academic.manage.syllabus.content.others'), slot: 'others' }
        ]"
        :open="isOpenOthers || others"
        :options="{
          body: {
            class: 'py-0 px-0'
          },
          title: {
            class: 'fs-5 fw-bold text-gray-700 py-4 ps-5'
          },
          item: {
            class: 'border-0 border-top'
          }
        }"
        @onOpen="othersToggle"
      >
        <template #others>
          <template
            v-for="content in othersColumn"
            :key="content.module"
          >
            <ContentLink
              :title="$t(content.title)"
              :module="content.module"
              :secondary="true"
            />
          </template>
        </template>
      </pms-accordion>

      <pms-accordion
        class="border-3 border-start"
        :class="{ 'border-primary': isOpenPolicies || policies }"
        :items="[
          { title: $t('domains.academic.manage.syllabus.content.policies'), slot: 'policies' }
        ]"
        :open="isOpenPolicies || policies"
        :options="{
          body: {
            class: 'py-0 px-0'
          },
          title: {
            class: 'fs-5 fw-bold text-gray-700 py-4 ps-5'
          },
          item: {
            class: 'border-0 border-top'
          }
        }"
        @onOpen="policiesToggle"
      >
        <template #policies>
          <ContentLink
            :title="$t(coursePolicyColumn.title)"
            :module="coursePolicyColumn.module"
            :secondary="true"
          />
        </template>
      </pms-accordion>
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import ContentLink from './ContentLink.vue'

import { contentsColumn, descriptionColumn, othersColumn, coursePolicyColumn } from './../values'
import { useStore } from '../store'
import { computed, ref } from 'vue'

const store = useStore()

const isOpenInformation = computed(() => store.module === 'basic')
const isOpenDescription = computed(() => descriptionColumn.map((item) => item.module).includes(store.module))
const isOpenContents = computed(() => contentsColumn.map((item) => item.module).includes(store.module))
const isOpenOthers = computed(() => othersColumn.map((item) => item.module).includes(store.module))
const isOpenPolicies = computed(() => coursePolicyColumn.module === store.module)

const description = ref<boolean>(false)
const contents = ref<boolean>(false)
const others = ref<boolean>(false)
const policies = ref<boolean>(false)

const informationToggle = () => {
  description.value = false
  contents.value = false
  others.value = false
  policies.value = false
}

const descriptionToggle = () => {
  description.value = !description.value
  contents.value = false
  others.value = false
  policies.value = false
}

const contentsToggle = () => {
  description.value = false
  contents.value = !contents.value
  others.value = false
  policies.value = false
}

const othersToggle = () => {
  description.value = false
  contents.value = false
  others.value = !others.value
  policies.value = false
}

const policiesToggle = () => {
  description.value = false
  contents.value = false
  others.value = false
  policies.value = !policies.value
}

</script>
