<template>
  <pms-row>
    <pms-grid
      :col="12"
      class="fs-4"
    >
      {{ $t('domains.profile.publications.article.authors-placeholder') }}: <span class="fw-bold">{{ rule.author_full_name }}</span>
    </pms-grid>
    <pms-grid
      v-if="rule.description"
      :col="12"
      class="fs-5 mb-5"
    >
      {{ $t('domains.profile.publications.article.description') }}: <span>{{ rule.description }}</span>
    </pms-grid>
    <pms-grid :col="12">
      <div class="d-flex flex-column gap-2">
        <span class="fs-4">{{ $t('common.attachments') }}:</span>
        <pms-row>
          <pms-grid
            v-if="rule.attachments.length === 0"
            class="text-center fs-4 mt-12 mb-12"
          >
            {{ $t('common.no-data') }}
          </pms-grid>
          <pms-grid
            v-for="attachment in rule.attachments"
            :key="attachment.id"
            :col="2"
          >
            <single-attachment-card

              :attachment="attachment"
              without-menu
            />
          </pms-grid>
        </pms-row>
      </div>
    </pms-grid>
    <PermissionCheck :permission-code="permissionCodes.edit_rules_and_regulations.code">
      <pms-grid :col="12">
        <div class="d-flex m-5 align-items-center gap-1 justify-content-end w-100">
          <button
            class="btn btn-light-primary  fw-bold"
            @click="handleEditClick"
          >
            <pms-kt-icon
              :icon-name="'pencil'"
              class="fw-bold fs-4 p-0"
            />
            {{ $t('common.edit') }}
          </button>
          <button
            class="btn btn-light-danger  fw-bold"
            @click="handleDeleteClick"
          >
            <pms-kt-icon
              :icon-name="'trash'"
              class="fw-bold fs-4 p-0"
            />
            {{ $t('common.delete') }}
          </button>
        </div>
      </pms-grid>
    </permissioncheck>
  </pms-row>
</template>

<script setup lang="ts">
import { useAlert } from '@/shared/composable'
import { RulesAndRegulationsGetResponse } from '@shared/swagger'
import { useRulesAndRegulations } from '../store'
import { useI18n } from 'vue-i18n'

import SingleAttachmentCard from './SingleAttachmentCard.vue'
import { useRouter } from 'vue-router'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

interface SingleRuleProps {
    rule: RulesAndRegulationsGetResponse[0]
}

const props = defineProps<SingleRuleProps>()

const { dialog, success, error } = useAlert()
const store = useRulesAndRegulations()
const { t } = useI18n()
const router = useRouter()

const handleEditClick = () => {
  router.push({ name: 'EditRulePage', params: { id: props.rule.id } })
}
const handleDeleteClick = () => {
  dialog({
    title: 'common.are-you-sure',
    text: t('domains.rules-and-regulations.delete-rule-text'),
    onConfirm: async () => {
      const { status, message } = await store.deleteExistingRule(props.rule.id + '')
      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    }
  })
}
</script>
