import { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: '',
    field: 'key',
    slotCellName: 'key'
  },
  {
    title: '',
    field: 'value',
    slotCellName: 'value'
  }
]
