import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useStore = defineStore<Name, State, Getters, Actions>('Academic.useManageCurricula', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      currentStep: 'year',
      year: new Date().getFullYear(),
      department: null,
      program: null,

      navigation: [],
      departments: [],
      programs: [],
      tracks: [],
      track: null,
      curricula: null,
      workingCurriculum: null,
      curriculumCore: null,
      curriculumElectiveSlot: null,

      // крусы страницы edit term
      coreCoursesByTerm: [],
      // курсы при добавлений (пойск)
      foundCourses: [],
      coursesOfferedOtherDepartment: [],
      selectedCourseForAddToTerm: null,
      // reference functional
      coreCoursesForReference: null,

      outcome: null
    }
  }
})
