import { FormOptions, SmartTableHeader } from '@/shared/types'

export const tableContentHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.table.version',
    field: 'version',
    sortable: true
  },
  {
    title: 'common.table.active',
    field: 'active',
    sortable: true,
    slotCellName: 'active'
  },
  {
    title: 'common.table.type',
    field: 'type_id',
    sortable: true,
    slotCellName: 'type'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const formOptions : FormOptions = {
  labelContainer: {
    left: {
      class: 'col-md-3 col-sm-6'
    },
    right: {
      class: 'col-md-9'
    }
  },
  group: {
    class: 'mb-4'
  }
}
