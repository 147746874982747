import { destroyProfileChildren } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteChildren (this: StoreType, childId: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyProfileChildren(childId)

  if (ok) await this.fetchChildren()

  return {
    status: ok,
    message
  }
}
