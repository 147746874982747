import { SmartTableHeader } from '@shared/types'
// todo add translate
export const trackTermCoursesTableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    sortable: true
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    sortable: true
  },
  {
    title: 'common.theory',
    field: 'theory',
    slotCellName: 'theory',
    sortable: true
  },
  {
    title: 'common.practice',
    field: 'practice',
    slotCellName: 'practice',
    sortable: true
  },
  {
    title: 'common.credits',
    field: 'credits',
    slotCellName: 'credits',
    sortable: true
  },
  {
    title: 'common.ects',
    field: 'ects',
    slotCellName: 'ects',
    sortable: true
  },
  {
    title: 'common.actions',
    field: 'actions',
    slotCellName: 'actions',
    sortable: false
  }
]

// When search course to add into term
export const foundCourseTableHeaders = [
  {
    title: 'common.department',
    field: 'department',
    slotCellName: 'department',
    sortable: false
  },
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    sortable: false
  },
  {
    title: 'common.year',
    field: 'year',
    slotCellName: 'year',
    sortable: false
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    sortable: false
  },
  {
    title: 'equicode',
    field: 'year',
    slotCellName: 'year',
    sortable: false
  },
  {
    title: 'common.theory',
    field: 'theory',
    slotCellName: 'theory',
    sortable: true
  },
  {
    title: 'common.practice',
    field: 'practice',
    slotCellName: 'practice',
    sortable: true
  },
  {
    title: 'common.credits',
    field: 'credits',
    slotCellName: 'credits',
    sortable: true
  },
  {
    title: 'common.ects',
    field: 'ects',
    slotCellName: 'ects',
    sortable: true
  }
]
