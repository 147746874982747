<template>
  <div
    id="book"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t(`domains.profile.publications.book.header`) }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="books"
        :headers="tableHeadersBook"
        :sortable="true"
      >
        <template #author="{item}">
          <div>
            <div>
              <span class="text-gray-800 fw-bold m-0 lh-1 fs-4">{{ item.title }}</span>
            </div>
            <div>
              <span class="fs-6 lh-1 me-1">{{ item.authors }}</span>
            </div>
          </div>
        </template>
        <template #type="{item}">
          <div class="d-flex flex-column align-items-start">
            <p
              class="text-gray-800 badge badge-light-primary mb-1 px-3 py-2 fs-6"
            >
              {{ getTitleById(item.bookType, bookTypes) }}
            </p>
            <p class="text-gray-800 fw-bold badge badge-light-success m-0 p-2 fs-6">
              {{ item.pages }}
            </p>
          </div>
        </template>
        <template #publisher="{item}">
          <div>
            <div>
              <span class="text-gray-800 fw-bold m-0 lh-1 fs-4">{{ item.publisher }}</span>
            </div>
            <div>
              <span class="fs-6 lh-1 me-1">{{ getTitleById(item.country, countries) }}</span>
            </div>
          </div>
        </template>
        <template #date="{item}">
          <p class="text-gray-800 m-0 fs-5">
            {{ displayDate(item) }}
          </p>
        </template>
        <template #action="{ item }">
          <div class="d-flex align-items-center gap-2 justify-content-end">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.topic)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
      <BookForm v-if="modifier && modifier.name === 'book'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ProfileEducationBooksGetResponse } from '@/shared/swagger'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { months, tableHeadersBook } from '../values'
import { getTitleById } from '../utils'

import BookForm from './BookForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const bookTypes = computed(() => resource.getProfileResource?.book_types || [])
const countries = computed(() => resource.getProfileResource?.countries || [])
const books = computed(() => store.getBooks)

const setIdAction = (id: number) => {
  setModifier('book',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'book-new-title' : 'book-edit-title',
      description: id === -1 ? 'book-new-description' : 'book-edit-description'
    }
  )
  open({ name: 'publication-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.publications.are-you-sure',
    text: t('domains.profile.publications.book.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteBook(id)
    }
  })
}
const displayDate = (item: ProfileEducationBooksGetResponse[0]): string => {
  return t(months[item.published_month - 1]) + ' ' + item.published_year
}
</script>
