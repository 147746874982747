import { ref } from 'vue'
import { ServiceManageLocksGetRequest } from '@shared/swagger'

export interface FilteredStudent {
  id: number;
  name: string;
  surname: string;
  lock_id: number;
  start_date: string;
  end_date: string;
  requestedBy: string;
  is_active: boolean;
}

export const selectedStudents = ref<FilteredStudent[]>([])
export const selectedRequestedBy = ['']
export const lockIdsForModal = ref<FilteredStudent[]>([])
export const searchTempValues = ref<Partial<
  Pick<ServiceManageLocksGetRequest, 'student_id' | 'name' | 'surname' | 'speciality_code' | 'class'>>
  & Pick<ServiceManageLocksGetRequest, 'department_code' | 'requested_by' | 'entrance_year'>
  >({
    student_id: '',
    name: undefined,
    surname: undefined,
    // locked: false,
    department_code: '',
    speciality_code: undefined,
    requested_by: '',
    class: undefined,
    entrance_year: new Date().getFullYear()
  })
