import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSettingsPrivaciesContactPutRequest } from '@shared/swagger'
import { putProfileSettingsPrivaciesContact } from '@shared/api'

import type { StoreType } from '../types'

export default async function editPrivaciesContact (this:StoreType, values: ProfileSettingsPrivaciesContactPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSettingsPrivaciesContact(values)

  return {
    status: ok,
    message: message
  }
}
