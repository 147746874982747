import type { AxiosResponse } from '@/shared/types'
import type { StoreType } from '../types'

import { saveMessageTranslateContentByPath } from '@shared/api'
import { UnknownResponse } from '@/shared/swagger'

export default async function saveContentByPath (this: StoreType, path: string, content: Record<string, unknown>): Promise<AxiosResponse<UnknownResponse>> {
  const response = await saveMessageTranslateContentByPath(path, content)

  return response
}
