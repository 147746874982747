import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { isTranslatable } from '@shared/utils'

const SearchComponent = defineComponent({
  name: 'SearchComponent',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'common.search'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loader: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:loader', 'update:disabled', 'handleSearch', 'update:value'],
  setup: function (props, { emit }) {
    const { t } = useI18n()
    const search = ref('')

    const onInputSearch = (e) => {
      search.value = e.target.value
      emit('update:value', search.value)
    }

    const onKeydownSearch = (e) => {
      if (e.key === 'Enter') {
        handleSearch()
      }
    }

    const handleSearch = () => {
      emit('handleSearch', search.value)
    }

    watch(() => props.value, (newVal) => {
      search.value = newVal
    })

    return () => (
      <div class="position-relative">
        <input
          type="text"
          value={props.value}
          class="form-control form-control-solid z-1 h-100"
          placeholder={isTranslatable(props.placeholder) ? t(props.placeholder) : props.placeholder}
          onInput={(e) => onInputSearch(e)}
          onKeydown={onKeydownSearch}
        />
        <pms-button
          class="p-3 z-2 position-absolute top-0 end-0 rounded-start-0 h-100 d-flex align-items-center justify-content-center"
          color="primary"
          disabled={props.disabled}
          loader={props.loader}
          onClick={() => handleSearch()}
        >
          <pms-kt-icon icon-name="magnifier" class="fs-2 p-0 px-2" />
        </pms-button>
      </div>
    )
  },
  watch: {
    loader: {
      handler (newVal) {
        this.$emit('update:loader', newVal)
      },
      immediate: true
    },
    disabled: {
      handler (newVal) {
        this.$emit('update:disabled', newVal)
      },
      immediate: true
    },
    value: {
      handler (newVal) {
        this.$emit('update:value', newVal)
      }
    }
  }
})

export default SearchComponent
