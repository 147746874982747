
export const informationHeaders: Array<Array<{ label: string, value: Array<string> | string, optional?: string }>> = [
  [
    {
      label: 'year-term',
      value: ['year', 'term'],
      optional: 'code'
    },
    {
      label: 'course-code',
      value: ['code'],
      optional: 'default'
    },
    {
      label: 'course-title',
      value: ['title'],
      optional: 'default'
    },
    {
      label: 'department',
      value: ['department_title', 'department_code'],
      optional: 'code'
    },
    {
      label: 'faculty',
      value: ['faculty_title', 'faculty_code'],
      optional: 'name'
    }
  ],
  [
    {
      label: 'language',
      value: ['language_title', 'language_code'],
      optional: 'name'
    },
    {
      label: 'credits',
      value: ['ects', 'theory', 'practice', 'lab'],
      optional: 'credits'
    }
  ]
]

export const statisticsHeaders: Array<{label: string, value: string, fields:Array<{label: string, value: string}>}> = [
  {
    label: 'sections',
    value: 'section',
    fields: [
      {
        label: 'opened-sections',
        value: 'opened_sections'
      },
      {
        label: 'average-students-enrolled',
        value: 'average_students_enrolled'
      },
      {
        label: 'minimum-students-enrolled',
        value: 'minimum_students_enrolled'
      },
      {
        label: 'maximum-students-enrolled',
        value: 'maximum_students_enrolled'
      },
      {
        label: 'average-opened-quota',
        value: 'average_opened_quota'
      }
    ]
  },
  {
    label: 'grades',
    value: 'grade',
    fields: [
      {
        label: 'average',
        value: 'average'
      },
      {
        label: 'minimum',
        value: 'minimum'
      },
      {
        label: 'maximum',
        value: 'maximum'
      },
      {
        label: 'median',
        value: 'median'
      },
      {
        label: 'mode',
        value: 'mode'
      }
    ]
  },
  {
    label: 'students',
    value: 'student',
    fields: [
      {
        label: 'enrolled-students',
        value: 'enrolled_students'
      },
      {
        label: 'course-failed',
        value: 'course_failed'
      },
      {
        label: 'course-passed',
        value: 'course_passed'
      }
    ]
  },
  {
    label: 'instructors',
    value: 'instructors',
    fields: [
      {
        label: 'type',
        value: 'type'
      },
      {
        label: 'instructors',
        value: 'instructors'
      }
    ]
  },
  {
    label: 'attendance',
    value: 'attendance',
    fields: [
      {
        label: 'less-absence',
        value: 'less_absence'
      },
      {
        label: 'more-absence',
        value: 'more_absence'
      },
      {
        label: 'without-absence',
        value: 'without_absence'
      },
      {
        label: 'over-limit-absence',
        value: 'over_limit_absence'
      }
    ]
  }
]
