<template>
  <thead
    class="table-head-bg text-dark"
    :class="themeMode === 'light' ? 'light-background' : 'dark-background'"
  >
    <tr>
      <th
        class="ms-1 px-4 table-center"
        style="max-width: 50px !important;"
        rowspan="3"
      >
        <span>{{ $t('common.table.no') }}</span>
      </th>
      <th
        class="ms-1 px-4"
        rowspan="3"
      >
        <span>{{ $t('common.table.student-information') }}</span>
      </th>
      <template
        v-for="(header, index) in tableHeaders.headers[currentTableHeaderIndex]"
        :key="index"
      >
        <th
          :colspan="header.colspan"
          class="text-center"
        >
          {{ header.date.split(',')[0] }}
        </th>
      </template>
      <th
        class="text-center"
        colspan="3"
      >
        <span>{{ $t('common.table.total') }}</span>
      </th>
      <th
        class="text-center"
        colspan="2"
      >
        <span>{{ $t('common.table.absence-rate') }}</span>
      </th>
    </tr>
    <tr>
      <template
        v-for="(date, index) in tableHeaders.headers[currentTableHeaderIndex]"
        :key="index"
      >
        <template
          v-for="(session, sessionIndex) in date.sessions"
          :key="sessionIndex"
        >
          <th class="text-center">
            {{ session.time }}
          </th>
        </template>
      </template>
      <th
        class="text-center px-4"
        rowspan="2"
      >
        <pms-kt-icon
          :icon-name="'verify'"
          :title="$t('common.table.total-attended')"
          style="letter-spacing: 0px; margin: 0px;"
          class="fs-2 text-success m-0"
        />
      </th>
      <th
        class="text-center px-4"
        rowspan="2"
      >
        <pms-kt-icon
          :icon-name="'faceid'"
          :title="$t('common.table.total-absent')"
          style="letter-spacing: 0px; margin: 0px;"
          class="fs-2 text-danger m-0"
        />
      </th>
      <th
        class="text-center px-4"
        rowspan="2"
      >
        <pms-kt-icon
          :icon-name="'book-open'"
          :title="$t('common.table.total-permitted')"
          style="letter-spacing: 0px; margin: 0px;"
          class="fs-2 text-primary m-0"
        />
      </th>
      <th
        class="text-center px-4 text-center"
        rowspan="2"
      >
        {{ $t('common.table.section') }}
      </th>
      <th
        class="text-center px-4"
        rowspan="2"
      >
        <span>{{ $t('common.table.course') }}</span>
      </th>
    </tr>
    <tr>
      <template
        v-for="(date, index) in tableHeaders.headers[currentTableHeaderIndex]"
        :key="index"
      >
        <template
          v-for="(session) in date.sessions"
          :key="session.id"
        >
          <th
            class="ms-1 px-4 text-center"
          >
            <attendance-table-header-session-action :session="session" />
          </th>
        </template>
      </template>
    </tr>
  </thead>
</template>
<script setup lang="ts">
import { themeMode } from '@shared/composable/pattern'
import { useAttendance } from '../composable'

import AttendanceTableHeaderSessionAction from './AttendanceTableHeaderSessionAction.vue'

const { tableHeaders, currentTableHeaderIndex } = useAttendance()
</script>
<style scoped lang="scss">
// th {
//  border: 1px solid red !important;
// }

.table-head-bg {
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
</style>
