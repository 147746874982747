import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicCoursesConsentRequestsPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putAcademicCoursesConsentRequests (id: number, body: AcademicCoursesConsentRequestsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<AcademicCoursesConsentRequestsPutRequest, UnknownResponse>({
    url: `academic/courses/consent-requests/${id}`,
    body: body,
    loader: 'academic/courses/consent-requests-edit'
  })

  return response
}
