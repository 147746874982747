import { AdministrativeResourceResponse } from '@shared/swagger'

export default
(
  positions: AdministrativeResourceResponse['positions'],
  department?: AdministrativeResourceResponse['departments'][0],
  sub_department?:boolean
): AdministrativeResourceResponse['positions'] => {
  let depPositions:number[]|undefined
  if (department) {
    depPositions = []

    if (sub_department) {
      function addAllPositions (depPositions:number[], department:AdministrativeResourceResponse['departments'][0]) {
        if (department.positions) { department.positions.forEach(position => depPositions.push(position)) }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (department.children) { department.children.forEach(dep => addAllPositions(depPositions, dep as any)) }
      }
      addAllPositions(depPositions, department)
    } else {
      depPositions = depPositions.concat(department.positions)
    }
  }

  return depPositions
    ? positions.filter((position) => depPositions?.includes(position.id)).sort((a, b) => sort(a, b))
    : positions.sort((a, b) => sort(a, b))
}

const sort = (a: AdministrativeResourceResponse['positions'][0], b:AdministrativeResourceResponse['positions'][0]) => {
  if (a.title < b.title) {
    return -1
  }
  if (a.title > b.title) {
    return 1
  }
  return 0
}
