import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, KnowledgeBaseSingleResponse } from '@shared/swagger'

export default async function getKnowledgeBaseSingle (uuid: string): Promise<AxiosResponse<KnowledgeBaseSingleResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeBaseSingleResponse>({
    url: `knowledge/posts/${uuid}`,
    body: {},
    loader: 'knowledge/posts/post_uuid'
  })

  return response
}
