import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useResource } from '@shared/stores'
import { FormGroup } from '@/shared/types'

import { useManageCourses } from '@domains/AcademicCourses/store'

import { yearTermsToYearsOptions } from '@domains/AcademicCourses/utils'
import { typeToTypeOptions, gradingTypeToGradingTypeOptions, termsToTermsOptions, departmentsToDepartmentOptions } from '../utils'

import { ManageCourseRequestType } from '@/domains/AcademicCourses/types'
import { storeToRefs } from 'pinia'
export function useManageCourseFormComposable () {
  const store = useManageCourses()
  const resource = useResource()
  const { t } = useI18n()

  const { manage_course_request } = storeToRefs(store)
  const { academicManageCourseResource, academicManageCourseDepartmentsResource, resource: mainResource } = storeToRefs(resource)

  const commonGroupClass = 'row mb-3'

  const courseData = computed(() => manage_course_request.value)
  const department = computed(() =>
    academicManageCourseDepartmentsResource.value.find(
      item => item.code === courseData.value.department_code
    )
  )
  const courseCodeAutocompleteItems = computed(() => ((department.value
    ? department.value.course_code_prefixes
    : academicManageCourseDepartmentsResource.value
      .map(item => item.course_code_prefixes).flat()))
    .map((prefix) => ({ value: prefix, label: prefix }))
  )

  const tempValues = computed<ManageCourseRequestType>(() => ({
    code: courseData.value.code,
    title: courseData.value.title,
    year: courseData.value.year,
    language: courseData.value.language,
    title_en: courseData.value.title_en,
    title_ru: courseData.value.title_ru,
    title_kz: courseData.value.title_kz,
    department_code: courseData.value.department_code,
    theory: courseData.value.theory,
    practice: courseData.value.practice,
    lab: courseData.value.lab,
    credits: courseData.value.credits,
    ects: courseData.value.ects,
    local_code: courseData.value.local_code,
    grading_type: courseData.value.grading_type,
    desctiption_en: courseData.value.desctiption_en,
    desctiption_ru: courseData.value.desctiption_ru,
    desctiption_kz: courseData.value.desctiption_kz,
    period_code: courseData.value.period_code,
    term: courseData.value.term,
    equicode: courseData.value.equicode,
    type: courseData.value.type,

    course_code_prefix: courseData.value.course_code_prefix,
    course_code_code: courseData.value.course_code_code,

    ar_requisite: courseData.value.ar_requisite,
    pr_requisite: courseData.value.pr_requisite,
    cr_requisite: courseData.value.cr_requisite
  }))

  const formFields = computed(():Array<FormGroup> => [
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.year'),
            class: 'required'
          },
          name: 'year',
          type: 'select',
          value: tempValues.value.year,
          options: yearTermsToYearsOptions(mainResource.value?.year_terms ?? []),
          required: true,
          disabled: manage_course_request.value.code !== '',
          validator: {
            schema: 'yup',
            rules: [
              'number',
              'required',
              { func: 'label', value: t('common.table.year') }
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.department'),
            class: 'required'
          },
          name: 'department_code',
          type: 'select',
          value: tempValues.value.department_code,
          options: departmentsToDepartmentOptions(academicManageCourseDepartmentsResource.value),
          required: true,
          disabled: manage_course_request.value.code !== '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.department') },
              'trim'
            ]
          }
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-5 col-md-2'
          },
          label: {
            text: t('common.table.code'),
            class: 'required'
          },
          name: 'course_code_prefix',
          type: 'select',
          value: tempValues.value.course_code_prefix,
          options: courseCodeAutocompleteItems.value || [],
          required: true,
          disabled: manage_course_request.value.code !== ''
        },
        {
          group: {
            class: 'col-7 col-md-4 mt-6'
          },
          label: {
            text: ' '
          },
          name: 'course_code_code',
          type: 'text',
          value: tempValues.value.course_code_code,
          required: true,
          disabled: manage_course_request.value.code !== '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.code') },
              'trim'
            ]
          },

          vMask: { mask: '###' }

        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.local_code')
          },
          name: 'local_code',
          type: 'text',
          value: tempValues.value.local_code,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.local_code') },
              'trim'
            ]
          },
          vMask: {
            mask: '#########################'
          }
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.title_en'),
            class: 'required'
          },
          name: 'title_en',
          type: 'text',
          value: tempValues.value.title_en,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.title_en') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.title_ru'),
            class: 'required'
          },
          name: 'title_ru',
          type: 'text',
          value: tempValues.value.title_ru,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.title_ru') },
              'trim'
            ]
          }

        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.title_kz'),
            class: 'required'
          },
          name: 'title_kz',
          type: 'text',
          value: tempValues.value.title_kz,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.title_kz') },
              'trim'
            ]
          }
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12'
          },
          name: 'credits_separator_s',
          type: 'slot'
        }
      ]
    },
    {
      class: 'd-flex flex-wrap flex-md-nowrap mb-3',
      fields: [
        {
          group: {
            class: 'w-100'
          },
          label: {
            text: t('common.table.theory'),
            class: 'required'
          },
          name: 'theory',
          type: 'text',
          value: tempValues.value.theory,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.theory') },
              'trim'
            ]
          },
          vMask: { mask: '##' }
        },
        {
          group: {
            class: 'w-100 ms-md-3'
          },
          label: {
            text: t('common.table.practice'),
            class: 'required'
          },
          name: 'practice',
          type: 'text',
          value: tempValues.value.practice,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.practice') },
              'trim'
            ]
          },
          vMask: { mask: '##' }
        },
        {
          group: {
            class: 'w-100 ms-md-3'
          },
          label: {
            text: t('common.table.lab'),
            class: 'required'
          },
          name: 'lab',
          type: 'text',
          value: tempValues.value.lab,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.lab') },
              'trim'
            ]
          },
          vMask: { mask: '##' }
        },
        {
          group: {
            class: 'w-100 ms-md-3'
          },
          label: {
            text: t('common.table.ects'),
            class: 'required'
          },
          name: 'ects',
          type: 'text',
          value: tempValues.value.ects,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.ects') },
              'trim'
            ]
          },
          vMask: {
            mask: 'A',
            tokens: {
              A: {
                pattern: /[0-9]/,
                multiple: true
              }
            }
          }
        },
        {
          group: {
            class: 'w-100 ms-md-3'
          },
          label: {
            text: t('common.table.credits')
          },
          name: 'credits',
          type: 'text',
          value: tempValues.value.credits,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.credits') },
              'trim'
            ]
          },
          disabled: true
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-12'
          },
          name: 'credits_separator_e',
          type: 'slot'
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.period_code'),
            class: 'required'
          },
          name: 'period_code',
          type: 'select',
          options: [
            {
              label: t('common.table.semester'),
              value: 'S'
            },
            {
              label: t('common.table.year'),
              value: 'Y'
            },
            {
              label: t('common.table.quarter'),
              value: 'Q'
            }
          ],
          value: tempValues.value.period_code,
          required: true,
          disabled: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.period_code') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.recommended_term')
          },
          name: 'term',
          type: 'select',
          value: tempValues.value.term,
          options: termsToTermsOptions(academicManageCourseResource.value?.terms ?? []),
          validator: {
            schema: 'yup',
            rules: [
              'number',
              { func: 'label', value: t('common.table.term') },
              { func: 'min', value: 0 }
            ]
          }
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.equicode')
          },
          name: 'equicode',
          type: 'text',
          value: tempValues.value.equicode,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.equicode') },
              'trim'
            ]
          },
          vMask: { mask: '##########' }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.language'),
            class: 'required'
          },
          name: 'language',
          type: 'autocomplete',
          value: tempValues.value.language,
          suggestions: {
            // on create or update need a language formatter
            itemField: 'title',
            items: academicManageCourseResource.value?.languages ?? [],
            class: 'w-100'
          },
          required: true
        }
      ]
    },
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.type'),
            class: 'required'
          },
          name: 'type',
          type: 'select',
          value: tempValues.value.type,
          options: typeToTypeOptions(academicManageCourseResource.value?.types ?? []),
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.language') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('common.table.grading_type'),
            class: 'required'
          },
          name: 'grading_type',
          type: 'select',
          value: tempValues.value.grading_type,
          options: gradingTypeToGradingTypeOptions(academicManageCourseResource.value?.grading_types ?? []),
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.grading_type') },
              'trim'
            ]
          }
        }
      ]
    },
    {
      fields: [
        {
          group: {
            class: commonGroupClass
          },
          name: 'requisites',
          type: 'slot'
        }
      ]
    }
  ])

  const onHandleChange = ({ name, newValue }) => {
    courseData.value[name] = newValue
    if (['practice', 'theory', 'lab'].includes(name)) {
      const theoryRate = academicManageCourseResource.value?.section_types.find(item => item.section_key === 'N')?.rate || 0
      const labRate = academicManageCourseResource.value?.section_types.find(item => item.section_key === 'L')?.rate || 0
      const practiceRate = academicManageCourseResource.value?.section_types.find(item => item.section_key === 'P')?.rate || 0
      courseData.value.credits = (courseData.value.theory || 0) * theoryRate + (courseData.value.lab || 0) * labRate + (courseData.value.practice || 0) * practiceRate
      courseData.value.ects = Math.ceil(courseData.value.credits * 1.5)
    }
  }

  return {
    formFields,
    onHandleChange
  }
}
