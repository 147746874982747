import type { AcademicResourceResponse } from '@shared/swagger'

export default (data: AcademicResourceResponse['instructors'], department: number|null): Array<Record<string, string | number>> => {
  const options: Array<Record<string, string | number>> = []

  if (department) {
    data.forEach((instructor) => {
      if (instructor.departments.includes(department)) {
        options.push({
          full_name: instructor.full_name,
          id: instructor.id
        })
      }
    })
  }
  return options
}
