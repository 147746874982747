import { ServiceMessageBroadcastResourceResponse } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { useMessageBroadcastCompose } from '../store'
import { computed } from 'vue'
import { useResource } from '@/shared/stores'

export function useCoursesField () {
  const store = useMessageBroadcastCompose()
  const { selectedStudents, selectedCourses } = storeToRefs(store)

  function removeSelectedStudent (id:string) {
    selectedStudents.value = selectedStudents.value.filter(selected_student_id => selected_student_id !== id)
  }

  function resetStudents () {
    selectedStudents.value = []
    selectedCourses.value = []
  }

  const students_list = computed(() => {
    let results:ServiceMessageBroadcastResourceResponse['courses'][0]['students'] = []
    useResource().getServiceMessageBroadcastResource?.courses.forEach(course => {
      results = [...results, ...course.students.filter(st => selectedStudents.value.includes(st.id) && !results.map(st => st.id).includes(st.id))]
    })
    return results
  })

  return {
    students_list,
    selectedCourses,
    removeSelectedStudent,
    resetStudents
  }
}
