import type { StoreType } from '../types'

import { useAlert } from '@shared/composable'
import { getReportCategories } from '@shared/api'

export default async function (this: StoreType): Promise<void> {
  const { error } = useAlert()
  const { ok, message, data } = await getReportCategories()
  ok
    ? this.setCategories(data)
    : error({ text: message })
}
