import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { RouteParams } from '../../types'
import { header } from '../../values'

export default async function bootstrap (this: StoreType, params: RouteParams): Promise<void> {
  const resource = useResource()
  const { code, year, term } = params

  await Promise.all([
    this.setCourse(params),
    resource.fetchAcademicSingleCoursesResource({ code, year, term }),
    resource.fetchAcademicResource({ modules: ['departments'], year, term })
  ])
  const data = resource.getAcademicSingleCoursesResource
  const currentSection = data?.sections.find(s => s.section.includes(params.section))

  currentSection && this.setCurrentSection(currentSection)

  data && (header.title = `${data.information.code} - ${data.information.title} - [${currentSection?.section}]`)
}
