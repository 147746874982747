import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementRequestsQuotasGetResponse
} from '@shared/swagger'

export default async function getSectionManagementRequestQuotas (id: number): Promise<AxiosResponse<SectionManagementRequestsQuotasGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementRequestsQuotasGetResponse>({
    url: `academic/manage/sections/requests/${id}/quotas`,
    body: {},
    loader: 'academic/manage/sections/requests/quotas'
  })

  return response
}
