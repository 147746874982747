/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { LayoutConfigTypes } from '@/shared/types/pattern'

import objectPath from 'object-path'
import layoutConfig from '@shared-values/layout'

export const LS_CONFIG_NAME_KEY = 'config_' + process.env.APP_NAME

export const useConfigStore = defineStore('config', () => {
  const config = ref<LayoutConfigTypes>(layoutConfig)
  const initial = ref<LayoutConfigTypes>(layoutConfig)

  function getLayoutConfig (path: string, defaultValue?: string) {
    return objectPath.get(config.value, path, defaultValue)
  }

  function setLayoutConfigProperty (property: string, value: any) {
    objectPath.set(config.value, property, value)
    localStorage.setItem(LS_CONFIG_NAME_KEY, JSON.stringify(config.value))
  }

  function resetLayoutConfig () {
    config.value = Object.assign({}, initial.value)
  }

  function overrideLayoutConfig () {
    config.value = initial.value = Object.assign(
      {},
      initial.value,
      JSON.parse(window.localStorage.getItem(LS_CONFIG_NAME_KEY) || '{}')
    )
  }

  return {
    config,
    getLayoutConfig,
    setLayoutConfigProperty,
    resetLayoutConfig,
    overrideLayoutConfig
  }
})
