import type {
  AcademicInformationSchedulesResourcesResponse
} from '@/shared/swagger'
import { SpecialtyMapped } from '../types'

export default (specialties: AcademicInformationSchedulesResourcesResponse['specialities'], departments: AcademicInformationSchedulesResourcesResponse['departments']) => {
  return departments.map((department) => {
    return {
      title: department.title,
      value: department.id,
      code: department.code,
      selectable: false,
      specialties: specialties
        .filter((specialty) => specialty.department_id === department.id)
        .map((specialty) => specialtyMapperFunction(specialty))
    }
  })
}

function specialtyMapperFunction (specialty: AcademicInformationSchedulesResourcesResponse['specialities'][0]): SpecialtyMapped {
  return {
    title: `${specialty.program_code} - ${specialty.title} (${specialty.language}) [${specialty.terms}]`,
    value: specialty.program_code,
    code: specialty.program_code,
    selectable: true
  }
}
