import { ref } from 'vue'
import { FilterRules } from '../types'

export const tempValues = ref<FilterRules>({
  category_id: 0,
  keyword: undefined
})

export const setTempValues = (values: Partial<FilterRules>) => {
  tempValues.value = { ...tempValues.value, ...values }
}
