<template>
  <div>
    <div class="d-flex align-items-center mt-5">
      <router-link
        :to="'/academic/courses/consent-requests'"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </router-link>
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ `${query.course_code as string}-${query.course_title as string}` }}
      </span>
    </div>
    <pms-loader
      v-if="loader"
    />
    <div
      v-else
      class="row"
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="p-3 col-6"
      >
        <pms-card
          class="h-100"
          :header="false"
        >
          <template v-if="item">
            <div class="d-flex flex-column justify-content-between h-100">
              <main-information :information="item" />
              <PermissionCheck
                :permission-code="permissionCodes.edit_consent_request.code"
              >
                <action-buttons :item="item" />
              </PermissionCheck>
            </div>
          </template>
        </pms-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader } from '@shared/composable'
import { useConsentRequest } from '../store'
import { useRoute } from 'vue-router'
import ActionButtons from './ActionButtons.vue'
import MainInformation from './MainInformation.vue'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

const store = useConsentRequest()
const { isActive } = useLoader()
const { query } = useRoute()
const items = computed(() => store.getConsentRequestsSections)

const loader = isActive('/academic/courses/consent-requests/request' +
    `?section=${query.section}&course_code=${query.course_code}&course_title=${query.course_title}`)

onMounted(async () => {
  const section = Array.isArray(query.section) ? query.section[0] : query.section

  if (section !== null && section !== undefined) {
    await store.fetchConsentRequestsSections({ section: Number(section) })
  }
})

</script>
