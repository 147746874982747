<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :filter="true"
      :full-width="true"
    />
    <ExamDatesInfo
      v-else
      id="single-course-exam-dates"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'

import { quickTourExamDates } from '../../values'

import ExamDatesInfo from './ui/ExamDatesInfo.vue'

const { isActiveOneOf } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['academic/information/exam-dates', 'academic/courses/resources/single'])

onMounted(async () => {
  setQuickTourSteps(await quickTourExamDates())
})
</script>
