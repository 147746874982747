import { router } from '@/router'
import { useAcademicReports } from '../store'
import { computed } from 'vue'
import { Category_type, TreeNode } from '../types'

export function useReportsComposable () {
  const store = useAcademicReports()
  async function search_by_category (node:TreeNode) {
    store.setReportData([])
    if (node.id.includes('report_')) {
      router.push({
        name: 'AcademicReportViewPage',
        params: {
          report_id: node.id.split('_')[1]
        }
      })
    } else {
      await store.fetchReports({
        category_id: +node.id
      })
      router.push({
        name: 'AcademicReportsSearchPage'
      })
    }
  }
  const categories_to_show = computed(() => {
    const categories:TreeNode[] = []
    function addChild (category:Category_type) {
      const child:TreeNode = {
        id: category.id + '',
        label: category.title
      }
      if (category.children.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        child.children = category.children.map(child => (addChild(child as any)))
      }
      if (category.reports.length) {
        const reports = category.reports.map(report =>
          (
            addChild({
              id: 'report_' + report.report_id,
              title: report.title,
              reports: [],
              children: []
            })
          )
        )
        child.children = child.children ? child.children.concat(reports) : reports
      }
      return child
    }
    store.categories.forEach(category => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      categories.push(addChild(category as any))
    })
    return categories
  })

  return {
    categories_to_show,
    search_by_category
  }
}
