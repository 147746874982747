<template>
  <div
    class="menu-item"
  >
    <span
      class="fs-5 fw-bold menu-link py-3 w-100 rounded-0"
      :class="{ 'active': store.module === props.module, 'ps-5': module === 'basic', 'ps-8': module !== 'basic' }"

      @click="handleContentlinkClick"
    >
      {{ props.title }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useStore } from './../store'

const store = useStore()

const props = defineProps<{
  module: string,
  title: string,
  secondary?: boolean
}>()

const handleContentlinkClick = () => {
  store.module = props.module
}
</script>
<style lang="scss" scoped>
.a-dashed {
  border: 1px dashed #009ef7;
  border-radius: 20px;
  padding: 10px;
}
</style>
