import { getProfileSkillsLanguages } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchLanguages (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileSkillsLanguages()

  if (ok) {
    this.setLanguages(data)
  }
}
