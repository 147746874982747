import type { StoreType } from '../types'
import type { RulesAndRegulationsGetRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { getRulesAndRegulations } from '@/shared/api'

export default async function fetchRules (this: StoreType, values: RulesAndRegulationsGetRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getRulesAndRegulations(values)

  ok && this.setRules(data)
  return {
    status: ok,
    message
  }
}
