import { AdministrativeStaffsInnerInnerContactsPutRequest } from '@/shared/swagger'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'
import { putAdministrativeStaffsInnerContacts } from '@/shared/api'

export default async function putInnerContacts (this: StoreType, values: AdministrativeStaffsInnerInnerContactsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAdministrativeStaffsInnerContacts(values)

  return {
    status: ok,
    message
  }
}
