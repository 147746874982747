<template>
  <div class="mt-6 mb-0">
    <select
      class="form-select w-250px"
      disabled
    >
      <template
        v-for="(content, index) in contents"
        :key="index"
      >
        <option
          :value="null"
          :selected="!content.active"
        >
          {{ $t('common.not-selected') }}
        </option>
        <option
          :value="index"
          :selected="content.active"
        >
          {{ content.version }} ({{ contentTypeTitle(content) }}) {{ content.active ? ` - ${$t('common.active')} ` : '' }}
        </option>
      </template>
    </select>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'

import { Content } from '../types'
import { useResource } from '@shared/stores'

defineProps({
  contents: {
    type: Object as PropType<Array<Content>>,
    required: true
  }
})

const resource = useResource()

const types = computed(() => resource.getKnowledgeManageResourceTypes)

const contentTypeTitle = (content: Content) => {
  return types.value.find((type) => type.id === Number(content.type_id))?.translation ?? ''
}
</script>
