<template>
  <pms-row>
    <pms-grid
      :col="12"
    >
      <WorkloadSearch />
    </pms-grid>
    <pms-grid
      class="mt-5"
      :col="12"
      :order-mb="2"
    >
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'table'"
        :full-width="true"
      />
      <WorkloadList
        v-else
        id="staff-list"
      />
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import WorkloadSearch from './WorkloadSearch.vue'
import WorkloadList from './WorkloadList.vue'
import { useLoader } from '@shared/composable'

const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf(['administrative/staffs/workload/resources', 'administrative/staffs/workload'])
</script>
