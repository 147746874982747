import {
  AcademicSingleCourseAttendancesGetResponse,
  AcademicSingleCourseAttendancesResourcesResponse
} from '@shared/swagger'

export default (resource: AcademicSingleCourseAttendancesResourcesResponse | null, records: AcademicSingleCourseAttendancesGetResponse, schedule_id: number | undefined) => {
  if (!resource || !schedule_id) return []

  return resource.students.map((item) => {
    return {
      ...item,
      schedule: records.find((item) => item.schedule_id === schedule_id) || null
    }
  })
}
