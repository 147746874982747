import { createPage } from '@shared-utils/storybook'
import ChangelogPage from './../ChangelogPage.vue'

const Template = createPage({
  layout: 'empty',
  component: ChangelogPage
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Release/Changelog',
  component: ChangelogPage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
