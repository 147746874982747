import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileSkillsHardPostRequest } from '@shared/swagger'

export default async function postProfileSkillsHard (params: ProfileSkillsHardPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileSkillsHardPostRequest, UnknownResponse>({
    url: 'profile/skills/hard-skills',
    body: params,
    loader: 'profile/skills/hard-skills-post'
  })

  return response
}
