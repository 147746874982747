<template>
  <pms-container class="mt-10">
    <pms-row>
      <pms-grid :col="12">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-row">
            <button
              class="btn btn-light-light btn-sm pt-1 fs-6 fw-bold"
              @click="goBack()"
            >
              Go back
            </button>
            <h2 class="badge badge-primary fs-2">
              <span class="fw-bolder ms-2 pe-2">{{ formatPath(path) }}</span>
            </h2>
          </div>
          <div class="d-flex flex-row">
            <button
              class="btn btn-success btn-sm me-2"
              @click="saveTranslate()"
            >
              <span class="fs-4 me-2">Save</span>
              <i class="bi bi-save fs-4 p-0 m-0" />
            </button>
            <button
              class="btn btn-primary btn-sm me-2"
              @click="createTranslate()"
            >
              <span class="fs-4 me-2">New</span>
              <i class="bi bi-plus-circle fs-4 p-0 m-0" />
            </button>
            <select
              class="form-select w-200px"
              @change="setLocale($event)"
            >
              <option
                v-for="locale in locales"
                :key="locale"
                :value="locale"
                :selected="locale === currentLocale"
              >
                {{ locale }}
              </option>
            </select>
          </div>
        </div>
      </pms-grid>
      <pms-grid
        :col="12"
        class="mt-4"
      >
        <div
          id="translateAccordion"
          class="accordion accordion-flush"
        >
          <template
            v-for="(item, index) in currentTranslates"
            :key="index"
          >
            <div
              v-show="item.locale === currentLocale"
              class="accordion-item"
            >
              <h2
                :id="`flush-heading-${index}`"
                class="accordion-header"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#flush-collapse-${index}`"
                  :aria-controls="`flush-collapse-${index}`"
                  aria-expanded="false"
                >
                  {{ index + 1 }}) {{ item.title }}
                  <button
                    class="btn btn-danger btn-sm ms-2"
                    style="padding: 5px; padding-top: 2px; padding-bottom: 2px;"
                    @click="deleteTranslate(index)"
                  >
                    Delete
                  </button>
                </button>
              </h2>
              <div
                :id="`flush-collapse-${index}`"
                class="accordion-collapse collapse"
                :aria-labelledby="`flush-heading-${index}`"
                data-bs-parent="#translateAccordion"
              >
                <div class="accordion-body">
                  <pms-row>
                    <pms-grid
                      :col="6"
                      class="border border-2 border-light rounded p-6 ps-6"
                    >
                      <div class="input-group mb-2">
                        <label class="form-label">Title:</label>
                        <input
                          v-model="item.title"
                          type="text"
                          class="form-control w-100"
                          placeholder="Enter title"
                        >
                      </div>
                      <div class="input-group mb-2">
                        <label class="form-label">Description:</label>
                        <textarea
                          v-model="item.description"
                          class="form-control w-100"
                          placeholder="Enter description"
                          rows="4"
                        />
                      </div>
                      <div class="input-group mb-2">
                        <label class="form-label">Image:</label>
                        <input
                          v-model="item.preview"
                          type="text"
                          class="form-control w-100"
                          placeholder="Enter preview image"
                        >
                      </div>
                      <div class="input-group mb-2 d-flex flex-column">
                        <label class="form-label">Content:</label>
                        <div
                          class="custom-editor-wrapper d-block"
                        >
                          <EditorComponent
                            v-model="item.content"
                          />
                        </div>
                      </div>
                    </pms-grid>
                    <pms-grid
                      :col="6"
                      class="border border-2 border-light rounded p-6 pt-0 ps-0 pe-0"
                    >
                      <div class="d-flex flex-column pt-0">
                        <img
                          :src="item.preview"
                          class="w-100 rounded pt-0"
                          style="height: 250px; object-fit: cover;"
                        >
                        <h2 class="mt-4 ps-6 pe-6">
                          {{ item.title }}
                        </h2>
                        <p class="fs-6 mb-4 ps-6 pe-6 pt-0">
                          {{ item.description }}
                        </p>
                        <div
                          class="ps-6 pe-6"
                          v-html="item.content"
                        />
                      </div>
                    </pms-grid>
                  </pms-row>
                </div>
              </div>
            </div>
          </template>
        </div>
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { TourStep } from '@shared/types'
import { useAlert } from '@shared/composable'

import { useStore } from './../store'
import { formatPath } from './../utils'

import EditorComponent from '@/shared/ui/components/EditorComponent.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()

const { success, error } = useAlert()

const currentLocale = ref('en-EN')
const currentTranslates = ref<Array<TourStep>>([])

const path = computed(() => route.params.path as string)
const translates = computed(() => store.getCurrentPath)
const locales = computed(() => store.getLocales)

const goBack = () => {
  router.go(-1)
}

const setLocale = ($event) => {
  currentLocale.value = $event.target.value
}

const createTranslate = () => {
  currentTranslates.value.push({
    locale: currentLocale.value,
    title: 'No Title',
    description: '',
    preview: '',
    content: ''
  })
}

const deleteTranslate = (index: number) => {
  currentTranslates.value.splice(index, 1)
}

const saveTranslate = async () => {
  let hasEmpties = false
  currentTranslates.value.forEach((item) => {
    if (!item.title || !item.description || !item.preview || !item.content) hasEmpties = true
  })

  if (hasEmpties) {
    error({ text: 'Please fill all fields' })
  } else {
    const { ok, message } = await store.saveContentByPath(path.value, currentTranslates.value)

    if (ok) {
      success({ text: message, isToast: true })
    } else {
      error({ text: message })
    }
  }
}

onMounted(() => {
  store.getContentByPath(path.value)
})

watch(
  () => translates.value,
  (newValue) => {
    currentTranslates.value = newValue
  }
)
</script>
<style lang="scss" scoped>

.custom-editor-wrapper {
  border: 0.2px solid rgb(234, 227, 227);
  border-radius: 0.475rem;
}

html[data-theme="dark"] {
  .custom-editor-wrapper {
    border: 0.2px solid #222f3e !important;
  }
}
</style>
