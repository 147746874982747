<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-rows align-items-center">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="back()"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <h1 class="text-dark fs-2 fw-bold mb-1">
          {{ $t('domains.academic.manage.curricula.select-department') }}
        </h1>
      </div>
    </div>
    <p class="fs-6 mt-3 mb-4">
      {{ $t('domains.academic.manage.curricula.select-department-description') }}
    </p>

    <pms-loader v-if="loader" />
    <div
      v-else
      id="departments-table"
    >
      <pms-smart-table
        :items="departments"
        :headers="headers"
        :sortable="true"
      >
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #title="{ item }">
          <span class="fs-5 fw-bold">{{ item.title }}</span>
        </template>
        <template #coursePrefixes="{ item }">
          <template
            v-for="prefix in item.course_prefixes"
            :key="prefix"
          >
            <span class="badge badge-light-primary me-2 mb-2">
              {{ prefix }}
            </span>
          </template>
        </template>
        <template #responsiblePersons="{ item }">
          <template
            v-for="(person, index) in item.responsible_persons"
            :key="person"
          >
            <span class="text-dark fw-semibold fs-6 me-2 text-underline">
              {{ person }}{{ index < item.responsible_persons.length - 1 ? ',' : '' }}
            </span>
          </template>
        </template>
        <template #action="{ item }">
          <button
            class="btn btn-sm btn-light-primary w-100px"
            @click="next(item.year, item.code)"
          >
            {{ $t('common.select') }}
            <pms-kt-icon
              icon-name="arrow-right"
              class="ms-2 m-0 p-0"
            />
          </button>
        </template>
      </pms-smart-table>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useLoader, useToggler } from '@shared/composable'

import { useStore } from '../store'
import { departmentStepQuickTourSteps, departmentTable as headers } from '../values'

const store = useStore()

const { isActive } = useLoader()
const { unToggle } = useToggler()
const { setSteps: setQuickTourSteps } = useDriver()

const departments = computed(() => store.getDepartments)
const loader = isActive('academic/manage/curriculum/departments')

const back = () => {
  store.setCurrentStep('year')
  unToggle()
}

const next = (year: number, code: string) => {
  store.setDepartment(code)
  store.setCurrentStep('program')
  store.fetchPrograms(year, code)
}

onMounted(async () => {
  setQuickTourSteps(await departmentStepQuickTourSteps())
})
</script>
<style scoped lang="scss">
.text-underline:hover {
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
}
</style>
