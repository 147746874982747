<template>
  <div class="d-flex gap-5 flex-column mt-5">
    <HeadInfo />
    <ActionButtons />
    <TrackTermCoursesTable />
    <TotalCourseTable />
  </div>
</template>

<script setup lang="ts">
import { useStore } from '../../store'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import TrackTermCoursesTable from './ui/TrackTermCoursesTable.vue'
import ActionButtons from './ui/ActionButtons.vue'
import TotalCourseTable from './ui/TotalCourseTable.vue'
import { useQueryHelper } from '../AddCourseToTrackPage/composable/useQueryHelper'
import HeadInfo from './ui/HeadInfo.vue'

const route = useRoute()
const store = useStore()
const queryHelper = useQueryHelper()

onMounted(() => {
  if (queryHelper.params.trackId && queryHelper.query.term) {
    store.fetchAcademicalCoreCoursesByTerm({
      track_id: +route.params.trackId,
      term: +route.params.term
    })
  }
})
</script>
