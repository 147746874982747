import type { StoreType } from '../types'
import type { KnowledgeManagePostsContentsPutRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { putKnowledgeManagePostsContents } from '@/shared/api'

export default async function editContent (this: StoreType, uuid: string, values: KnowledgeManagePostsContentsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putKnowledgeManagePostsContents(uuid, values)

  return {
    status: ok,
    message: message
  }
}
