import type { StoreType } from '../types'
import type { AcademicSingleStudentPostOpinionRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { postAcademicSingleStudentAdvisorOpinion } from '@shared/api'

export default async function postAdvisorOpinion (this: StoreType, student: string, params: AcademicSingleStudentPostOpinionRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postAcademicSingleStudentAdvisorOpinion(student, params)
  ok && this.fetchInformation(student)

  return {
    status: ok,
    message
  }
}
