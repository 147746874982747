<template>
  <div id="course-history-statistics">
    <h3 class="py-2">
      <span class="card-label fw-bold text-dark fs-3">
        {{ $t('domains.academic.single-course.history.statistics.title') }}
      </span>
    </h3>
    <div
      v-if="statistics && Object.keys(statistics).length > 0"
      class="row"
    >
      <template
        v-for="(headers, index) in statisticsHeaders"
        :key="index"
      >
        <div class="col-12 col-md-6 mb-5">
          <span class="card-label fw-bold text-danger fs-4 text-center">
            {{ $t(`domains.academic.single-course.history.statistics.${headers.label}`) }}
          </span>
          <div class="table-responsive">
            <table class="table table-bordered table-border-blue">
              <tbody>
                <template
                  v-for="(item, fIndex) in headers.fields"
                  :key="fIndex"
                >
                  <template v-if="headers.value !== 'instructors'">
                    <tr style="vertical-align: middle;">
                      <td
                        class="fs-5 fw-bold"
                      >
                        {{ $t(`domains.academic.single-course.history.statistics.${item.label}`) }}
                      </td>
                      <td class="fs-5 fw-semibold">
                        {{ statistics?.[headers.value][item.value] }}
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        class="fs-5 fw-bold"
                      >
                        {{ $t(`domains.academic.single-course.history.statistics.type`) }}
                      </td>
                      <td
                        v-for="(subItem, qIndex) in statistics?.[headers.value]"
                        :key="qIndex"
                        class="fs-5 fw-semibold"
                      >
                        {{ subItem.type }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="fs-5 fw-bold"
                      >
                        {{ $t(`domains.academic.single-course.history.statistics.instructors`) }}
                      </td>
                      <td
                        v-for="(subItem, sIndex) in statistics?.[headers.value]"
                        :key="sIndex"
                        class="fs-5 fw-semibold"
                      >
                        {{ subItem.instructors }}
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '../../../store'
import { statisticsHeaders } from '../values'

const store = useSingleCourse()
const statistics = computed(() => store.getHistoryStatistics)

</script>
