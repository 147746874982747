<template>
  <pms-container class="mt-10">
    <pms-row>
      <pms-grid :col="12">
        <div class="d-flex justify-content-between align-content-between">
          <div class="d-flex flex-row">
            <button
              class="btn btn-light-light btn-sm pt-0 fs-6 fw-bold"
              @click="goBack()"
            >
              Go back
            </button>
            <h2 class="badge badge-primary fs-2">
              <span class="fw-bolder ms-2 pe-2">{{ path }}</span>
            </h2>
          </div>
          <div class="d-flex flex-row">
            <input
              v-model="search"
              class="form-control w-200px me-3"
              placeholder="Search..."
            >
            <div
              class="btn-group me-3"
              role="group"
            >
              <button
                type="button"
                :class="{
                  'btn': true,
                  'btn-primary': view === 'plain',
                  'btn-secondary': view !== 'plain'
                }"
                @click="view = 'plain'"
              >
                Plain
              </button>
              <button
                type="button"
                :class="{
                  'btn': true,
                  'btn-primary': view === 'tree',
                  'btn-secondary': view !== 'tree'
                }"
                @click="view = 'tree'"
              >
                Tree
              </button>
            </div>
            <button
              class="btn btn-info btn-sm me-2"
              type="button"
              @click="modal = modal === true ? false : true"
            >
              <span class="fs-4 me-2">New</span>
              <i class="bi bi-plus-circle-fill fs-4 p-0 m-0" />
            </button>
            <button
              class="btn btn-success btn-sm me-2"
              @click="saveTranslate()"
            >
              <span class="fs-4 me-2">Save</span>
              <i class="bi bi-save fs-4 p-0 m-0" />
            </button>
          </div>
        </div>
      </pms-grid>
      <pms-grid
        v-if="view === 'plain'"
        :col="12"
        class="mt-8"
      >
        <template
          v-for="(item, index) in currentTranslates"
          :key="index"
        >
          <pms-row
            v-show="index.includes(search)"
            class="mb-3"
          >
            <pms-grid :col="4">
              <span class="fs-4">
                {{ index }}
              </span>
            </pms-grid>
            <pms-grid :col="8">
              <div
                class="d-flex flex-row"
                style="align-items: center;"
              >
                <pms-dropdown placement="start">
                  <template #button>
                    <button
                      class="btn btn-sm btn-primary rounded me-3"
                      style="padding: 10px;"
                    >
                      <i class="bi bi-three-dots-vertical fs-4 p-0 m-0" />
                    </button>
                  </template>
                  <div class="w-100px menu-item pt-0 pb-0">
                    <template
                      v-for="(inputType, inputIndex) in ['input', 'textarea', 'editor', 'Delete']"
                      :key="inputIndex"
                    >
                      <a
                        href="#"
                        class="menu-link px-3 py-2"
                        :class="{ 'active': getInputType(index) === inputType, 'text-danger': inputType === 'Delete' }"
                        @click="setInputType(index, inputType)"
                      >
                        {{ inputType }}
                      </a>
                    </template>
                  </div>
                </pms-dropdown>
                <template v-if="getInputType(index) === 'input'">
                  <input
                    v-model="currentTranslates[index]"
                    class="form-control"
                  >
                </template>
                <template v-if="getInputType(index) === 'textarea'">
                  <textarea
                    v-model="currentTranslates[index]"
                    class="form-control"
                    rows="4"
                  />
                </template>
                <template v-if="getInputType(index) === 'editor'">
                  <div class="custom-editor-wrapper w-100">
                    <EditorComponent
                      v-model="currentTranslates[index]"
                    />
                  </div>
                </template>
              </div>
            </pms-grid>
          </pms-row>
        </template>
      </pms-grid>
    </pms-row>
    <pms-row>
      <new-message-modal
        :modal="modal"
        @newMessage="newTranslate"
      />
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAlert } from '@shared/composable'
import { objectToDotNotation, dotNotationToObject } from '@shared/utils'
import { useStore } from './../store'

import NewMessageModal from './NewMessageModal.vue'
import EditorComponent from '@/shared/ui/components/EditorComponent.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { success, error } = useAlert()

const search = ref<string>('')
const modal = ref<boolean>(false)
const view = ref<'plain' | 'tree'>('plain')
const currentTranslates = ref<Record<string, string>>(objectToDotNotation({}))
const editorContainer = ref<Array<string>>([])
const textareaContainer = ref<Array<string>>([])

const path = computed<string>(() => route.params.path as string)
const translates = computed(() => store.getCurrentPath)

const goBack = () => {
  router.go(-1)
}

const setInputType = (index: string, input: string) => {
  if (input === 'Delete') {
    deleteTranslate(index)
    return
  }

  editorContainer.value = editorContainer.value.filter((item) => item !== index)
  textareaContainer.value = textareaContainer.value.filter((item) => item !== index)

  if (input === 'editor') {
    editorContainer.value.push(index)
  } else if (input === 'textarea') {
    textareaContainer.value.push(index)
  }
}

const getInputType = (index: string) => {
  if (editorContainer.value.includes(index)) return 'editor'
  if (textareaContainer.value.includes(index)) return 'textarea'
  return 'input'
}

const newTranslate = ({ key, value }: { key: string, value: string }) => {
  currentTranslates.value[key] = value
}

const deleteTranslate = (key: string) => {
  delete currentTranslates.value[key]
}

const saveTranslate = async () => {
  const { ok, message } = await store.saveContentByPath(path.value, dotNotationToObject(currentTranslates.value))

  ok
    ? success({ text: message, isToast: true })
    : error({ text: message })
}

onMounted(() => {
  store.getContentByPath(path.value)
})

watch(
  () => translates.value,
  (newValue) => {
    currentTranslates.value = objectToDotNotation(newValue)
  }
)
</script>
<style lang="scss" scoped>
.custom-editor-wrapper {
  border: 0.2px solid rgb(234, 227, 227);
  border-radius: 0.475rem;
}

html[data-theme="dark"] {
  .custom-editor-wrapper {
    border: 0.2px solid #222f3e !important;
  }
}
</style>
