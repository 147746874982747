<template>
  <pms-card :header="false">
    <pms-button
      class="btn d-flex align-items-center mt-2 mb-2"
      @click="printWorkload(department.faculty, department.title, year, term)"
    >
      <i class="bi bi-printer fs-4" />
      <span>{{ $t('common.print') }}</span>
    </pms-button>
    <pms-smart-table
      id="wokloadTable"
      :items="sortedWorkload"
      :headers="workloadHeaders"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #instructor="{ row }">
        <span>{{ row.instructor }}</span>
      </template>
      <template #course="{ row }">
        <div>
          <table>
            <tbody>
              <tr
                v-for="(course, idx) in row.courses"
                :key="idx"
              >
                <div>{{ course }}</div>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #section="{ item }">
        <div>
          <table>
            <tbody>
              <tr
                v-for="(section, idx) in item.sections"
                :key="idx"
              >
                <div>{{ section }}</div>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #student_count="{ item }">
        <div>
          <table>
            <tbody>
              <tr
                v-for="(students, idx) in item.student_count"
                :key="idx"
              >
                <div>{{ `[ ${students} ]` }}</div>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #ect="{ item }">
        <div>
          <table>
            <tbody>
              <tr
                v-for="(ect, idx) in item.ects"
                :key="idx"
              >
                <div>{{ ect }}</div>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #credit="{ item }">
        <div>
          <table>
            <tbody>
              <tr
                v-for="(credit, idx) in item.credits"
                :key="idx"
              >
                <div>{{ credit }}</div>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #course_count="{ item }">
        <span class="text-align-center">{{ item.course_count }}</span>
      </template>
      <template #total_students="{ item }">
        <span>{{ item.total_students }}</span>
      </template>
      <template #total_credits="{ item }">
        <span>{{ item.total_credits }}</span>
      </template>
      <template #thesis_advisor="{ item }">
        <span>{{ item.thesis_advisor }}</span>
      </template>
      <template #hours_per_week="{ item }">
        <span>{{ item.hours_per_week }}</span>
      </template>
      <template #total_hour="{ item }">
        <span>{{ item.total_hour }}</span>
      </template>
    </pms-smart-table>
    <pms-pagination class="p-0 m-0 mt-6" />
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { workloadHeaders } from '../values'
import { useStaffList } from '../store'
import { storeToRefs } from 'pinia'
import { printWorkload, sortWorkload } from '../utils'

const store = useStaffList()
const { department, year, term, workload } = storeToRefs(store)
const sortedWorkload = computed(() => sortWorkload(workload.value))
</script>
