import { IAvocado } from '@avocado/types'
import { useBuilder } from '@/avocado/builder'

export const createAvocado = (): IAvocado => {
  return {
    init: () => {
      return useBuilder()
        .buildInitFromParser()
    }
  }
}
