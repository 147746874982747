import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendanceRelocatesResourceGetRequest } from '@shared/swagger'

import { getAcademicSingleCourseAttendanceRelocatesResource } from '@shared/api'

export default async function fetchAcademicSingleCourseAttendanceResource (this: StoreType, params: AcademicSingleCourseAttendanceRelocatesResourceGetRequest): Promise<void> {
  const { ok, data } = await getAcademicSingleCourseAttendanceRelocatesResource(params)

  if (ok) {
    this.setAcademicSingleCourseAttendanceRelocateResource(data)
  }
}
