import type { StoreType } from '../types'
import { getAcademicManageCurriculumPrograms } from '@shared/api'

export default async function fetchPrograms (this: StoreType, year: number, department: string): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumPrograms({ year, department_code: department })

  if (ok) {
    this.setPrograms(data)
  }
}
