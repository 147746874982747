import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ServiceMessageBroadcastEmployeeGetRequest,
  ServiceMessageBroadcastEmployeeGetResponse
} from '@shared/swagger'

export default async function (params: ServiceMessageBroadcastEmployeeGetRequest): Promise<AxiosResponse<ServiceMessageBroadcastEmployeeGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceMessageBroadcastEmployeeGetResponse>({
    url: 'service/message-broadcast/employee',
    body: { params },
    loader: 'service/message-broadcast/employee'
  })

  return response
}
