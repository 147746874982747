import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicInformationResourcesRequest,
  AcademicInformationResourcesResponse
} from '@shared/swagger'

export default async function getAcademicInformationResource (params: AcademicInformationResourcesRequest): Promise<AxiosResponse<AcademicInformationResourcesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationResourcesResponse>({
    url: 'academic/information/resources',
    body: { params: params },
    loader: 'academic/information/resources'
  })

  return response
}
