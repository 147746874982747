<template>
  <template v-if="editor.sessions == sessionId">
    <pms-dropdown
      :items="dropItems"
      :menu-item-class="'w-150px'"
    >
      <template #button>
        <input
          :checked="true"
          type="checkbox"
          class="form-check-input"
          disabled
        >
      </template>
    </pms-dropdown>
  </template>
  <session-lock-icon v-else />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useAttendanceEditor } from '../../composable'
import SessionLockIcon from './SessionLockIcon.vue'

const { editor, resetDefault } = useAttendanceEditor()

defineProps({
  sessionId: {
    type: Number,
    required: true
  }
})

const dropItems = computed(() => [
  {
    menuType: 'link',
    label: 'common.cancel',
    action: () => {
      resetDefault()
    }
  }
])
</script>
