<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
    data-kt-menu="true"
  >
    <div
      class="d-flex flex-column bgi-no-repeat rounded-top"
      style="background-image: url(/media/auth/bg3.jpg); background-repeat: no-repeat;"
    >
      <h3 class="text-white fw-semobold px-9 mt-10 mb-6">
        {{ $t('layout.menus.notifications.title') }}
      </h3>
      <ul
        class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semobold px-9"
      >
        <li class="nav-item">
          <a
            :class="{ 'active' : tab === 'notifications' }"
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="javascript:;"
            @click="tab = 'notifications'"
          >
            {{ $t('layout.menus.notifications.updates') }}
          </a>
        </li>

        <li class="nav-item">
          <a
            :class="{ 'active' : tab === 'messages' }"
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="javascript:;"
            @click="tab = 'messages'"
          >
            {{ $t('layout.menus.notifications.announcements') }}
          </a>
        </li>

        <li class="nav-item">
          <a
            :class="{ 'active' : tab === 'new_messages' }"
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="javascript:;"
            @click="tab = 'new_messages'"
          >
            {{ $t('layout.menus.notifications.new_messages') }}
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div
        :class="{ 'show active': tab === 'notifications' }"
        class="tab-pane fade"
      >
        <div class="d-flex flex-column px-9">
          <div class="pt-10 pb-10">
            <h3 class="text-dark text-center fw-bold">
              {{ $t('layout.menus.notifications.no-notifications') }}
            </h3>
          </div>
        </div>
      </div>

      <div
        :class="{ 'show active': tab === 'messages' }"
        class="tab-pane fade"
      >
        <div class="scroll-y mh-325px my-5 px-8">
          <template
            v-for="(item, index) in messages"
            :key="index"
          >
            <div class="pb-2">
              <h6> {{ item.title }} </h6>
              <p
                class="text-muted p-0 mb-1"
                v-html="removeHtmlTags(getFirstNCharacters(item.content, 100))"
              />
              <strong> - {{ item.full_name }}</strong> ({{ item.create_date }})
            </div>
            <div
              v-if="index !== messages.length - 1"
              class="separator separator-dashed mb-2"
            />
          </template>
          <p
            v-if="messages.length === 0"
            class="text-center mt-8 mb-6"
          >
            {{ $t('phrases.no-messages') }}
          </p>
        </div>
        <div class="py-3 text-center border-top">
          <router-link
            to="/administrative/message-board"
            class="btn btn-bg-body text-primary"
          >
            {{ $t('phrases.view-all') }}
          </router-link>
        </div>
      </div>

      <div
        :class="{ 'show active': tab === 'new_messages' }"
        class="tab-pane fade"
      >
        <div class="scroll-y mh-325px my-5 px-8">
          <template
            v-for="(message,index) in new_messages"
            :key="index"
          >
            <a
              class="d-flex align-items-center text-dark text-hover-primary"
              href="javascript:void(0)"
              @click="handleRedirectToMessage(message.message_id)"
            >
              <div class="symbol symbol-35px me-3">
                <div
                  class="symbol-label bg-light-primary"
                >
                  <span
                    class="text-primary"
                  >{{ getAuthorFirstLetters(message.from_employee) }}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="fw-semibold">{{ message.from_employee }}, {{ message.receive_date.split(' ')[0] }}</div>
                <div class="text-muted">{{ message.subject }}</div>
              </div>
              <div class="ms-auto">
                <i class="fs-2 bi bi-arrow-right-circle-fill" />
              </div>
            </a>
            <div
              v-if="index !== messages.length - 1"
              class="separator separator-dashed my-2"
            />
          </template>
          <p
            v-if="new_messages.length === 0"
            class="text-center mt-8 mb-6"
          >
            {{ $t('phrases.no-new-messages') }}
          </p>
        </div>
        <div class="py-3 text-center border-top">
          <router-link
            :to="{
              name:'MessageBroadcastPage',
              query:{
                mode:'inbox'
              }
            }"
            class="btn btn-bg-body text-primary"
          >
            {{ $t('phrases.view-all') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useUser } from '@shared/stores'
import { getFirstNCharacters, removeHtmlTags } from '@shared-utils/text'
import { getAuthorFirstLetters } from '@/domains/ServiceMessageBroadcast/utils'
import { router } from '@/router'

const user = useUser()
const tab = ref<'notifications'|'messages'|'new_messages'>('new_messages')
const messages = computed(() => user.getMessages)
const new_messages = computed(() => user.getNewInboxMessages)
function handleRedirectToMessage (message_id) {
  router.push({
    path: `/services/message-broadcast/${message_id}`,
    params: {
      id: message_id
    }
  })
}
</script>
