import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AdministrativeStaffsRequest, AdministrativeStaffsResponse } from '@shared/swagger'

export default async function getAdministrativeStaffs (params: AdministrativeStaffsRequest): Promise<AxiosResponse<AdministrativeStaffsResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeStaffsResponse>({
    url: 'administrative/staffs',
    body: { params: params },
    loader: 'administrative/staffs'
  })

  return response
}
