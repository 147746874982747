import { SelectField } from '@shared/types'

export default (data): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((el) => {
    options.push({
      label: el.title,
      value: el.type_id
    })
  })
  return options
}
