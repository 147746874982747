import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileExperiencesPostRequest } from '@shared/swagger'
import { postProfileExperiences } from '@shared/api'

import type { StoreType } from '../types'

export default async function addExperience (this: StoreType, values: ProfileExperiencesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileExperiences(values)

  if (ok) await this.fetchExperiences()

  return {
    status: ok,
    message
  }
}
