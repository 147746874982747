import { useRequests } from '@/shared/composable'

import type { UnknownRequest, ResourceRequest, AcademicManageCurriculumResources } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicManageCurriculumResource (params: ResourceRequest): Promise<AxiosResponse<AcademicManageCurriculumResources>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumResources>({
    url: 'academic/manage/curriculum/resources',
    body: { params },
    loader: 'academic/manage/curriculum/resources'
  })

  return response
}
