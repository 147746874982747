/* eslint-disable vue/require-default-prop */
import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
const BadgeComponent = defineComponent({
  name: 'BadgeComponent',
  props: {
    translatable: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String as PropType<'default' | 'square' | 'circle'>,
      required: false,
      default: 'default'
    },
    colorVariant: {
      type: String as PropType<'default' | 'outlined' | 'tonal'>
    },
    color: {
      type: String as PropType<'light' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark'>,
      required: false,
      default: 'primary'
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg' >,
      required: false,
      default: 'md'
    }
  },
  setup (props, { slots }) {
    const { t } = useI18n()

    return () => (
        <span class={[
          'badge',
          `badge${props.colorVariant === 'tonal' ? '-light' : ''}-${props.color}`,
          `${props.colorVariant === 'outlined' ? 'badge-outline' : ''}`,
          `${props.variant === 'default' ? '' : `badge-${props.variant}`}`,
          `${props.size === 'md' ? '' : `badge-${props.size}`}`
        ]}>
          { slots.default && slots.default()}
          { !slots.default && props.translatable ? t(props.value) : props.value}
        </span>
    )
  }
})

export default BadgeComponent
