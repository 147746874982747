import type { StoreType } from '../types'
import { getMessageTranslateContentByPath } from '@shared/api'

export default async function getContentByPath (this: StoreType, path: string): Promise<void> {
  const { ok, data } = await getMessageTranslateContentByPath(path)

  if (ok) {
    const { file } = data as { locales: Array<string>, file: Record<string, unknown> }

    this.setCurrentPath(file)
  }
}
