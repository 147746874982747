import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AdministrativeSystemCalendarRequest,
  AdministrativeSystemCalendarResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAdministrativeSystemCalendar (params: AdministrativeSystemCalendarRequest): Promise<AxiosResponse<AdministrativeSystemCalendarResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AdministrativeSystemCalendarResponse>({
    url: 'administrative/system-calendar',
    body: { params },
    loader: 'administrative/system-calendar'
  })

  return response
}
