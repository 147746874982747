<template>
  <div
    class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center p-12"
    :class="{
      'p-10': device === 'desktop',
      'p-2': device === 'tablet' || device === 'mobile',
    }"
  >
    <div class="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
      <div class="w-md-400px w-100">
        <div class="text-center mb-10">
          <div class="text-center mb-2">
            <img
              class="theme-light-show mw-100 w-175px"
              src="/new-logo-light.svg"
            >
            <img
              class="theme-dark-show mw-100 w-175px"
              src="/new-logo-dark.png"
            >
          </div>
          <h1 class="text-dark fw-bolder mb-3 pt-4">
            {{ $t('domains.authenticator.reset-password.title') }}
          </h1>
          <div class="text-gray-500 fw-semibold fs-6">
            {{ $t('domains.authenticator.reset-password.description') }}
            <router-link
              class="btn btn-link fw-bold mb-0 pb-0"
              to="/auth/sign-in"
            >
              {{ $t('domains.authenticator.reset-password.back-to-sign-in') }}
            </router-link>
          </div>
        </div>

        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
        >
          <template #formControls>
            <div class="d-grid mb-10">
              <button
                v-test-id="'submit'"
                type="submit"
                class="btn btn-lg btn-primary w-100"
              >
                <span
                  v-if="!loader"
                  class="indicator-label"
                >
                  {{ $t('common.submit') }}
                </span>

                <span
                  v-if="loader"
                  class="indicator-label"
                >
                  {{ $t('phrases.please-wait') }} <span class="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </button>
            </div>
          </template>
        </pms-smart-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { deviceType } from '@shared/utils'
import { FormField } from '@/shared/types'
import { useAlert, useLoader } from '@shared/composable'

import { useAuth } from '../store'

const { t } = useI18n()
const { isActive } = useLoader()
const { success, error } = useAlert()

const store = useAuth()
const route = useRoute()
const router = useRouter()

const device = deviceType()
const loader = isActive('/1.0.0/reset-password')

const formFields: Array<FormField> = [
  {
    type: 'password',
    name: 'new_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.new-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'new-password'
    },
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.new-password') },
        { func: 'min', value: 8 },
        { func: 'max', value: 30 }
      ]
    }
  },
  {
    type: 'password',
    name: 'confirm_password',
    group: {
      class: 'mb-4'
    },
    label: {
      text: t('domains.authenticator.form.confirm-password'),
      class: 'fs-6 fw-semibold text-dark mb-1'
    },
    binding: {
      autocomplete: 'confirm-password'
    },
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required',
        { func: 'label', value: t('domains.authenticator.form.confirm-password') },
        { func: 'min', value: 8 },
        { func: 'max', value: 30 }
      ]
    }
  }
]

const onSubmit = async ({ values }) => {
  console.log(values)
  if (values.new_password !== values.confirm_password) {
    return error({
      text: t('domains.authenticator.form.errors.passwords-not-match')
    })
  }

  const { ok, message } = await store.resetPassword({ ...values, token: route.params.token })
  ok
    ? success({ text: message, isToast: true })
    : error({ text: message })

  if (!ok) {
    router.push({ name: 'SignInPage' })
  }
}
</script>
