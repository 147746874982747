import type { StoreType } from '../types'
import type{ ResourceResponse } from '@shared/swagger'

import { useResource } from '@shared/stores'

export default async function setCurrentYearTerm (this: StoreType, yearTerm: ResourceResponse['year_terms'][0]): Promise<void> {
  const resource = useResource()

  Promise.all([
    this.currentYearTerm = yearTerm,
    resource.fetchAcademicResource({ modules: ['departments', 'instructors'], year: yearTerm.year, term: yearTerm.term })
  ])
}
