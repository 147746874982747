<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="store.page_params.course_code? edit_course_header:new_course_header"
    :breadcrumbs="[
      {
        text: 'Search courses',
        route: {
          name: 'AcademicCoursesPage'
        }
      },
      {
        text:store.page_params.course_code ? 'Edit course' : 'New Course',
      }
    ]"
    @launch="launch"
  >
    <template #action>
      <pms-feedback-modal :options="feedback" />
    </template>
  </pms-page-toolbar>
  <pms-page-tour
    :launched="status"
    :steps="steps"
    @launch="launch"
  />

  <pms-container v-if="loader">
    <pms-page-shimmer
      :shimmer="'card'"
    />
  </pms-container>

  <pms-container v-else>
    <div class="card">
      <div class="card-body">
        <pms-smart-form
          :fields="formFields"
          :loader="button_loader"
          @handleChange="onHandleChange"
          @handleSubmit="onSubmit"
        >
          <template #credits_separator_s>
            <pms-divider
              :content="$t('common.table.credits')"
              design="dotted"
              color="primary"
              margin-y="5"
            />
          </template>
          <template #credits_separator_e>
            <pms-divider
              content="."
              design="dotted"
              color="primary"
              margin-y="5"
            />
          </template>
          <template #formControls>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="loader"
            >
              <span
                v-if="!loader"
                class="indicator-label"
              >
                {{ $t('common.save') }}
              </span>
            </button>
          </template>
        </pms-smart-form>
      </div>
    </div>
    <div class="mt-5">
      <div
        v-if="store.description_editable"
      >
        <DescriptionRequsitesComponent />
      </div>
    </div>
  </pms-container>
</template>

<script setup lang = "ts">
import DescriptionRequsitesComponent from './ui/DescriptionRequsitesComponent.vue'
import { onMounted } from 'vue'
import { useToolbar, useLoader, useTour } from '@shared/composable'
import { new_course_header, edit_course_header, tour, feedback } from './values'
import { useManageCourses } from '../../store'
import { useManageCourseComposable, useManageCourseFormComposable } from './composable'

const { formFields, onHandleChange } = useManageCourseFormComposable()
const { onSubmit } = useManageCourseComposable()

const store = useManageCourses()

const button_loader = useLoader().isActiveOneOf(['academic/manage/courses'])

const loader = useLoader().isActiveOneOf(['academic/manage/courses/resources', 'resources', 'academic/resources', 'academic/manage/courses/search'])

const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
