import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import type { FormField } from '@shared/types'
import { useAlert, useTeleportModal } from '@shared/composable'
import { useRulesAndRegulations } from '../store'
import { storeToRefs } from 'pinia'
import { ICustomAttachment } from '../types'
import { SingleRuleGetResponse } from '@/shared/swagger'
import { attachmentFormValidation } from '../utils'

const useAttachmentModalForm = () => {
  const { t } = useI18n()
  const { close, unsetModifier } = useTeleportModal()
  const { error, success } = useAlert()
  const toast = useToast()
  const store = useRulesAndRegulations()

  const { languages, singleRule } = storeToRefs(store)

  const attachments = computed<SingleRuleGetResponse['attachments']>(() => singleRule.value?.attachments || [])

  const defaultTempValues = {
    id: 0,
    url: '',
    version: '' + new Date().getFullYear(),
    language_name: '',
    file: null
  }

  const tempValues = ref<ICustomAttachment['attachment']>(defaultTempValues)
  const formFields = computed(():Array<FormField> => [
    {
      label: {
        text: `${t('common.select-language')}:`,
        class: 'fs-6 fw-semibold mb-2 required'
      },
      name: 'language_name',
      type: 'select',
      value: tempValues.value.language_name,
      required: true,
      options: languages.value.map(l => ({ label: l.long, value: l.short })),
      validator: {
        schema: 'yup',
        rules: [
          'string',
          'required',
          { func: 'label', value: t('domains.rules-and-regulations.attachment-form.language-required') }
        ]
      }
    },
    {
      label: {
        text: `${t('domains.knowledge.single-post.content.version')}:`,
        class: 'fs-6 fw-semibold mb-2 mt-3'
      },
      name: 'version',
      type: 'text',
      value: tempValues.value.version
    },
    {
      name: 'file',
      type: 'slot'
    }
  ])

  const onHandleChange = ({ name, newValue }) => {
    tempValues.value[name] = newValue
  }

  const onSubmit = async () => {
    toast.removeAllGroups()
    const { errors, formData } = attachmentFormValidation(tempValues.value, attachments.value, languages.value)

    if (errors.length > 0) {
      errors.forEach(err => {
        error({ text: t(err), isToast: true, toastOptions: { life: undefined } })
      })
    } else {
      const { message, status } = await (tempValues.value.id
        ? store.editRuleAttachment(singleRule.value?.id + '', tempValues.value.id + '', formData)
        : store.createRuleAttachment(singleRule.value?.id + '', formData)
      )

      closeModal()

      if (status) {
        success({ text: message, isToast: true })
        store.fetchSingleRule(singleRule.value?.id + '')
      } else {
        error({ text: message, isToast: true, toastOptions: { life: undefined } })
      }
    }
  }

  const closeModal = () => {
    close({ name: 'attachment-modal' })
    unsetModifier()
  }
  const onSelect = async (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0]
      tempValues.value = { ...tempValues.value, url: file.name, file }
    }
  }

  return {
    formFields,
    tempValues,
    closeModal,
    onSubmit,
    onHandleChange,
    onSelect
  }
}

export default useAttachmentModalForm
