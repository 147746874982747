import type { StoreType } from '../types'
import type { AcademicManageCurriculumCoreGetRequest } from '@/shared/swagger'

import api from '@/shared/api/AcademicCurricula/fetchCurriculumCore'

export default async function fetchCurriculumCore (this: StoreType, params: AcademicManageCurriculumCoreGetRequest): Promise<void> {
  const { data, ok } = await api(params)

  if (ok) {
    this.setCurriculumCore(data)
  }
}
