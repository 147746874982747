<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            text="common.submit"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { useStore } from './../store'
import { objectDeepCopy } from '@shared/utils'
import { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import { SyllabusFormMode } from './../types'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const store = useStore()

const defaultValue: NonNullable<AcademicManageSyllabusGetResponse['academic_skills'][0] & SyllabusFormMode> = {
  id: -1,
  title: '',
  count: -1,
  mode: 'add'
}

const tempValue = ref(objectDeepCopy(defaultValue))

const formFields = computed<Array<FormField>>(() => [
  {
    type: 'textarea',
    name: 'academic_skills',
    value: tempValue.value.title || ''
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (newValue.data.mode === 'edit') {
        const item = store.getAcademicSkills.find((item) => item.id === newValue.data?.id)
        tempValue.value = { mode: newValue.data.mode, ...(item || defaultValue) }
      } else {
        tempValue.value = objectDeepCopy(defaultValue)
      }
    }
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (tempValue.value.mode === 'edit') {
    store.editAcademicSkill({
      title: values.academic_skills
    }, tempValue.value.id)
  }
  if (tempValue.value.mode === 'add') {
    store.addAcademicSkill({
      title: values.academic_skills
    })
  }
  close({ name: 'syllabus-form-content-modal' })
  unsetModifier()
}
</script>
