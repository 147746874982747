import { useRequests } from '@/shared/composable'

import type { AxiosResponse } from '@/shared/types'
import type { MeResponse } from '@shared/swagger'

type MeRequest = object

export default async function getUserMe (): Promise<AxiosResponse<MeResponse>> {
  const { get } = useRequests()

  const response = get<MeRequest, MeResponse>({
    url: 'me',
    body: {},
    loader: '/1.0.0/me'
  })

  return response
}
