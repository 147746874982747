import { useRequests } from '@shared/composable'
import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumElectivePutRequest } from '@shared/swagger'

export default async function editElectiveSlot (id: number, body: AcademicManageCurriculumElectivePutRequest): Promise<StatusMessageResponse> {
  const { put } = useRequests()

  const { ok, message } = await put({
    url: `/academic/manage/curriculum/electives/${id}`,
    body: body,
    loader: 'put-academic/manage/curriculum/electives'
  })

  return {
    status: ok,
    message
  }
}
