<template>
  <div
    id="newMessageModal"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
    aria-hidden="true"
    :style="{
      display: modalState ? 'block' : 'none'
    }"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-group">
            <label class="mb-2 ms-2 fw-bold">Key: (Enter with dot notations)</label>
            <input
              v-model="key"
              type="text"
              class="form-control"
              placeholder="Enter key"
            >
          </div>
          <div class="form-group mt-3 d-flex flex-column">
            <label class="mb-2 ms-2 fw-bold">Value</label>
            <div
              class="btn-group"
              role="group"
            >
              <template
                v-for="(item, index) in ['input', 'textarea', 'editor']"
                :key="index"
              >
                <button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': inputType === item,
                    'btn-secondary': inputType !== item
                  }"
                  @click="inputType = item"
                >
                  {{ item }}
                </button>
              </template>
            </div>
            <input
              v-if="inputType === 'input'"
              v-model="value"
              type="text"
              class="form-control mt-4"
              placeholder="Enter value"
            >
            <textarea
              v-if="inputType === 'textarea'"
              v-model="value"
              class="form-control mt-4"
              placeholder="Enter value"
              rows="6"
            />
            <div
              v-if="inputType === 'editor'"
              class="custom-editor-wrapper w-100 mt-4"
            >
              <EditorComponent
                v-model="value"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer pb-3 pt-3 border-0">
          <button
            type="button"
            class="btn btn-secondary"
            @click="modalState = false"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="save()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'

import EditorComponent from '@/shared/ui/components/EditorComponent.vue'
const props = defineProps({ modal: Boolean })
const emit = defineEmits(['newMessage', 'update:modal'])

const key = ref<string>('')
const value = ref<string>('')
const modalState = ref<boolean>(props.modal)
const inputType = ref<string>('input')

const save = () => {
  emit('newMessage', { key: key.value, value: value.value })
  key.value = ''
  value.value = ''
  modalState.value = false
}

watch(
  () => props.modal,
  () => { modalState.value = true }
)
</script>
<style lang="scss" scoped>
.custom-editor-wrapper {
  border: 0.2px solid rgb(234, 227, 227);
  border-radius: 0.475rem;
}

html[data-theme="dark"] {
  .custom-editor-wrapper {
    border: 0.2px solid #222f3e !important;
  }
}
</style>
