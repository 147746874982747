import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicResourceResponse, AcademicResourceRequest } from '@shared/swagger'

export default async function getAcademicResource (params: AcademicResourceRequest): Promise<AxiosResponse<AcademicResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicResourceResponse>({
    url: 'academic/resources',
    body: { params: params },
    loader: 'academic/resources'
  })

  return response
}
