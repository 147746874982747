import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'

import { getKnowledgeManageCategories } from '@shared/api'
import { usePagination } from '@shared/composable'
import { KnowledgeManageCategoriesGetRequest } from '@shared/swagger'

export default async function fetchKnowledgeManageCategories (this: StoreType, values: KnowledgeManageCategoriesGetRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getKnowledgeManageCategories({ ...values, per_page: perPage, offset: currentPage })

    if (ok) {
      this.setKnowledgeCategories(data.categories || [])
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    }
  })
  const { ok, message, data } = await getKnowledgeManageCategories(values)

  if (ok) {
    this.setKnowledgeCategories(data.categories || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  return {
    status: ok,
    message
  }
}
