import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { uuid, isTranslatable } from '@shared/utils'
import type { StepperItem } from '@shared/types'

export default defineComponent({
  name: 'StepperComponent',
  props: {
    name: {
      type: String,
      default: uuid()
    },
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Array as () => StepperItem[],
      required: true
    },
    center: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String as () => 'horizontal' | 'vertical',
      default: 'horizontal'
    }
  },
  setup (props) {
    const { t } = useI18n()
    const currentStep = ref(1)

    watch(
      () => props.step,
      (value) => { currentStep.value = value },
      { immediate: true }
    )

    return () => (
      <div class="stepper stepper-pills" id={props.name}>
        <div
          class={{
            'stepper-nav': true,
            'd-flex flex-column': props.direction === 'vertical',
            'flex-center flex-wrap': props.direction === 'horizontal' && props.center
          }}
        >
            {
              props.steps.map((item, index) => (
                <div
                  class={{
                    'stepper-item mx-8 my-4': true,
                    current: currentStep.value === index + 1,
                    completed: currentStep.value > index + 1
                  }}
                  data-kt-stepper-element="nav"
                >
                  <div class="stepper-wrapper d-flex align-items-center">
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">{ index + 1 }</span>
                    </div>
                    <div class="stepper-label">
                      <h3
                        class={{
                          'stepper-title': true,
                          'text-dark': currentStep.value === index + 1,
                          'text-gray-700': currentStep.value !== index + 1
                        }}
                        innerHTML={ isTranslatable(item.title) ? t(item.title) : item.title }
                      />

                      <div
                        class={{
                          'stepper-desc cut-text-150': true,
                          'text-gray-800': currentStep.value === index + 1,
                          'text-gray-700': currentStep.value !== index + 1
                        }}
                        innerHTML={ item.description && isTranslatable(item.description) ? t(item.description) : item.description }
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-dismiss-="click"
                        data-bs-original-title={ item.description && isTranslatable(item.description) ? t(item.description) : item.description }
                        data-kt-initialized="1"

                      />
                    </div>
                  </div>
                </div>
              ))
            }
        </div>
      </div>
    )
  }
})
