import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentAccountingGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentAccounting (id: string): Promise<AxiosResponse<AcademicSingleStudentAccountingGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentAccountingGetResponse>({
    url: `academic/students/${id}/accounting`,
    body: { },
    loader: 'academic/students/accounting'
  })

  return response
}
