import { useRequests } from '@shared/composable'

import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumAddCourseRequest } from '@shared/swagger'

export default async function addCourseToTrack (params: AcademicManageCurriculumAddCourseRequest): Promise<StatusMessageResponse> {
  const { post } = useRequests()

  const { ok, message } = await post({
    url: 'academic/manage/curriculum/core',
    body: params,
    loader: 'academic/manage/curriculum/core'
  })

  return {
    status: ok,
    message
  }
}
