export default (): void => {
  const table = document.getElementById('singleCourseSchedulePrint')

  if (table) {
    const html = table.outerHTML.replaceAll(': ', '')
    const url = 'data:application/vnd.ms-excel,' + escape(html)

    window.open(url, '_blank')
  }
}
