import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useKnowledgeBase } from './store'

import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const pagination = usePagination()
  const store = useKnowledgeBase()

  pagination.$reset()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/knowledge-base',
  children: [
    {
      path: '',
      name: 'KnowledgeBaseListing',
      component: () => import('./KnowledgeBaseListing.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
