import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentGradesDetailsGetResponse, AcademicSingleStudentAttendanceDetailsGetRequest } from '@shared/swagger'

export default async function getAcademicSingleStudentGradesDetails (id: string, params: AcademicSingleStudentAttendanceDetailsGetRequest): Promise<AxiosResponse<AcademicSingleStudentGradesDetailsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentGradesDetailsGetResponse>({
    url: `academic/students/${id}/grades/details`,
    body: { params: params },
    loader: 'academic/students/grades/details'
  })

  return response
}
