import { SmartTableHeader } from '@shared/types'

export const tableHeadersStudentInformation: Array<SmartTableHeader> = [
  {
    title: 'common.table.student-information',
    field: 'studentInformation',
    slotCellName: 'studentInformation'
  },
  {
    title: 'common.table.specialty',
    field: 'specialty',
    slotCellName: 'specialty'
  },
  {
    title: 'common.table.absence-rate',
    field: 'absence_rate',
    slotCellName: 'absence_rate',
    cellClass: 'text-center',
    sortable: true
  }
]

export const tableHeadersStudentGradeInformation: Array<SmartTableHeader> = [
  {
    title: 'common.table.average',
    field: 'total_grade',
    slotCellName: 'total_grade',
    cellClass: 'text-center',
    sortable: true
  },
  {
    title: 'common.table.letter-grade',
    field: 'letter_grade',
    slotCellName: 'letter_grade',
    cellClass: 'text-center'
  }
]

export default {
  left: tableHeadersStudentInformation,
  right: tableHeadersStudentGradeInformation
}
