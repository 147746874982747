<template>
  <pms-loader v-if="loader" />
  <div v-else>
    <div class="d-flex flex-row justify-content-between gap-3">
      <span class="fw-bold fs-4">
        {{ `${receivedRequestStore.selectedRequest?.program.title} (${receivedRequestStore.selectedRequest?.program.code})` }}
      </span>
      <button
        v-if="receivedRequestStore.selectedRequest?.status.id!==4"
        type="button"
        class="btn btn-success btn-sm"
        @click="openMetModal"
      >
        {{ $t('phrase.section-management.met-command') }}
      </button>
    </div>
    <pms-smart-table
      :items="requests"
      :headers="receivedZoomDetailsHeader"
      :class="'mt-4'"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #programCode="{ item }">
        {{ item.program ? item.program.code : '' }}
      </template>
      <template #courseCode>
        {{ receivedRequestStore.selectedCourse?.code ?? '' }}
      </template>
      <template #theory="{ item }">
        <div class="d-flex flex-column gap-3 text-nowrap text-center">
          <p class="p-0 m-0">
            {{ getTheorySectionFromRequestData(item.sections).title }}
          </p>
          <p class="p-0 m-0">
            {{ getTheorySectionFromRequestData(item.sections).instructor }}
          </p>
        </div>
      </template>
      <template #practice="{ item }">
        <div class="d-flex flex-column gap-3 text-nowrap text-center">
          <p class="p-0 m-0">
            {{ getPracticeSectionFromRequestData(item.sections).title }}
          </p>
          <p class="p-0 m-0">
            {{ getPracticeSectionFromRequestData(item.sections).instructor }}
          </p>
        </div>
      </template>
      <template #laboratory="{ item }">
        <div class="d-flex flex-column gap-3 text-nowrap text-center">
          <p class="p-0 m-0">
            {{ getLaboratorySectionFromRequestData(item.sections).title }}
          </p>
          <p class="p-0 m-0">
            {{ getLaboratorySectionFromRequestData(item.sections).instructor }}
          </p>
        </div>
      </template>
      <template #students="{ item }">
        {{ item.students }}
      </template>
      <template #met="{ item, idx }">
        <input
          v-if="selected === idx"
          v-model="inputQuota"
          type="text"
          @focusout="focusOutInput"
          @input="handleInput"
        >
        <div v-else>
          {{ item.total_quota }}
        </div>
      </template>
      <template #action="{ idx, item }">
        <div class="d-flex gap-3 justify-content-center">
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-primary"
            @click="selectEditQuota(item.id, idx)"
          >
            <pms-kt-icon
              v-if="selected !== idx"
              icon-name="notepad-edit"
              class="fs-3 m-0 p-0"
            />
            <span v-else> {{ $t('common.save') }} </span>
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-danger"
            @click="deleteQuota(item.id)"
          >
            <pms-kt-icon
              icon-name="trash"
              class="fs-3 m-0 p-0"
            />
          </a>
        </div>
      </template>
    </pms-smart-table>
  </div>
  <ReceivedDetailsMetForm v-if="modifier && modifier.name === 'new-met-content'" />
</template>
<script setup lang="ts">
import { useAlert, useLoader, useTeleportModal } from '@/shared/composable'

import { useSectionManagement } from '@domains/SectionManagement/store'

import { receivedZoomDetailsHeader } from '../values'
import { computed, ref } from 'vue'

import {
  getTheorySectionFromRequestData,
  getLaboratorySectionFromRequestData,
  getPracticeSectionFromRequestData
} from '@domains/SectionManagement/features/ReceivedRequests/utils'

import { useReceivedRequest } from '../store'
import ReceivedDetailsMetForm from './ReceivedDetailsMetForm.vue'
import { useI18n } from 'vue-i18n'
import { SectionManagementRequestsQuotasGetResponse } from '@shared/swagger'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const { setModifier, open, modifier } = useTeleportModal()
const { error, success, dialog } = useAlert()
const { t } = useI18n()

const store = useSectionManagement()
const receivedRequestStore = useReceivedRequest()
const { currentYearTerm } = storeToRefs(store)

const inputQuota = ref<number | null>(null)
const selectedQuota = ref<SectionManagementRequestsQuotasGetResponse[0] | undefined>()

const selected = ref<number>(-1)
const loader = isActive('academic/manage/sections/requests/quotas')
const requests = computed(() => receivedRequestStore.getRequestQuotas)

function openMetModal () {
  setModifier('new-met-content',
    {},
    {
      title: 'academic.manage.section.received-requests.met-modal-title',
      description: 'academic.manage.section.received-requests.met-modal-description'
    }
  )
  open({ name: 'received-requests-modal' })
}

async function focusOutInput () {
  if (!selectedQuota.value?.id || !selectedQuota.value) return
  await dialog({
    title: 'domains.academic.manage.sections.edit-quota.are-you-sure',
    text: t('domains.academic.manage.sections.edit-quota.text'),
    onConfirm: async () => {
      const { status, message } = await receivedRequestStore.editQuota(
        receivedRequestStore.selectedRequest?.id || 0,
        selectedQuota.value?.id || -1,
        { quota: inputQuota.value || -1 },
        selectedQuota.value?.is_reserved || false
      )
      if (status) {
        success({ text: message, isToast: true })
        await receivedRequestStore.fetchReceivedRequests(
          {
            type: 'received',
            course_code: receivedRequestStore.selectedCourse?.code ?? '',
            course_year: receivedRequestStore.selectedCourse?.year ?? -1,
            term: store.currentYearTerm.term
          }
        )
        debugger
        selectedQuota.value && selectedQuota.value?.is_reserved
          ? await receivedRequestStore.fetchReservedProgramsQuotas({
            course_code: receivedRequestStore.selectedCourse?.code,
            course_year: receivedRequestStore.selectedCourse?.year,
            term: currentYearTerm.value.term,
            program_code: selectedQuota.value.program.code
          })
          : await receivedRequestStore.fetchRequestsQuotasById(receivedRequestStore.selectedRequest?.id || 0)
      } else {
        error({ text: message })
      }
    }
  })
  selected.value = -1
  inputQuota.value = -1
}

function selectEditQuota (id: number, idx: number) {
  if (selected.value === idx) {
    focusOutInput()
    return
  }
  selectedQuota.value = receivedRequestStore.selectedRequestsQuotas.find((quota) => quota.id === id)
  inputQuota.value = selectedQuota.value?.total_quota || 0
  selected.value = idx
}

function handleInput (event) {
  if (event.target instanceof HTMLInputElement) {
    const content = event.target.value.replace(/[^0-9]/g, '')
    inputQuota.value = Number.isFinite(Number(content)) ? Number(content) : null
  }
}

async function deleteQuota (quotaId: number) {
  dialog({
    title: 'domains.academic.manage.sections.quota-delete.are-you-sure',
    text: t('domains.academic.manage.sections.quota-delete.text'),
    onConfirm: async () => {
      const { status, message } = await receivedRequestStore.deleteQuota(
        receivedRequestStore.selectedRequest?.id || 0,
        quotaId,
        selectedQuota.value?.is_reserved || false
      )
      if (status) {
        success({ text: message, isToast: true })
      } else {
        error({ text: message })
      }
    }
  })
}
</script>
