<template>
  <pms-card
    v-if="students_list"
    :title="$t( 'domains.services.advisory-assignment.students.table.title')"
    :description="$t('domains.services.advisory-assignment.students.table.description')"
    class="mt-5"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <pms-smart-table
      :options="additional_info_table_options"
      :headers="additional_info_table_headers"
      :items="students_list"
    >
      <template #advisor="{item}">
        {{ item.advisor?.full_name }}
      </template>
      <template
        #checkbox="{ item }"
      >
        <div>
          <input
            :checked="isSelected(item)"
            type="checkbox"
            @change="toogleSelectStudent(item)"
          >
        </div>
      </template>
      <template #speciality="{item}">
        <span
          class="fs-6"
          :data-bs-original-title="`${item.speciality.program_code} - ${item.speciality.speciality_code} - ${item.speciality.language}` "
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
        >
          {{ item.speciality.title }}
        </span>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
</template>
<script setup lang="ts">
import useAdvisorAssignmentTable from './composable/useAdvisorAssignmentTable'
import { additional_info_table_headers, additional_info_table_options } from './values'
import { computed } from 'vue'
import { studentsArray } from '@/domains/AdvisorAssignment/store/types'

const isSelected = (student: studentsArray[0]): boolean => {
  return !!student._selected
}
const { students, toogleSelectStudent } = useAdvisorAssignmentTable()

const students_list = computed(() => students.value)
</script>
