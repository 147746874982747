import type { StoreType } from '../types'

import { useAlert, usePagination } from '@shared/composable'
import { getAcademicStudentsInformation } from '@shared/api'
import { convertSortFieldToParams } from '@shared/utils'
import { AcademicStudentsGetRequest, AcademicStudentsGetResponse } from '@shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

import { allStudentsSortField, tempValues } from '../../values'

export default async function fetchStudents (this: StoreType, params: AcademicStudentsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AcademicStudentsGetResponse): void => {
    this.setStudents(data.students || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getAcademicStudentsInformation({
      ...params,
      ...convertSortFieldToParams(allStudentsSortField.value),
      ...tempValues.value,
      per_page: perPage,
      offset: currentPage
    })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getAcademicStudentsInformation({
    ...params,
    ...convertSortFieldToParams(allStudentsSortField.value),
    ...tempValues.value
  })

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
