import { FormGroup } from '@/shared/types'
import { computed, ref } from 'vue'
import { departmentsToDepartmentOptions, editorsToEditorIdOptions, yearTermsToYearsOptions, statusOptions } from '../utils'
import { useResource } from '@/shared/stores'
import { useI18n } from 'vue-i18n'
import { useAlert, usePagination } from '@/shared/composable'
import { useSyllabuses } from '../store'

export const useSyllabusesSearch = () => {
  const resource = useResource()
  const { t } = useI18n()
  const { error } = useAlert()
  const { perPage } = usePagination()
  const store = useSyllabuses()
  const restForm = ref<boolean>(false)

  const tempValues = ref({
    year: null,
    department_id: null,
    course_code: null,
    course_title: null,
    status: null,
    editor: null
  })

  const formFields = computed<Array<FormGroup>>(() => [
    {
      class: 'row mb-3',
      fields: [
        {
          label: {
            text: `${t('common.select-year')}`,
            class: 'mb-1 mt-2 required'
          },
          group: {
            class: 'col-2'
          },
          name: 'year',
          type: 'select',
          required: true,
          value: tempValues.value.year || null,
          options: yearTermsToYearsOptions(resource.getResourceYearTerms)
        },
        {
          label: {
            text: `${t('common.select-department')}`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: `${restForm.value ? 'col-7' : 'd-none'}`
          },
          name: 'department_id',
          type: 'select',
          value: tempValues.value.department_id || null,
          options: departmentsToDepartmentOptions(resource.getAcademicResourceDepartments)
        },
        {
          label: {
            text: `${t('common.select-editor')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: `${restForm.value ? 'col-3' : 'd-none'}`
          },
          name: 'editor',
          type: 'select',
          value: tempValues.value.editor || null,
          options: editorsToEditorIdOptions(resource.getAcademicResourceInstructors)
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        {
          label: {
            text: `${t('common.enter-course-code')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: `${restForm.value ? 'col-3' : 'd-none'}`
          },
          name: 'course_code',
          type: 'text',
          value: tempValues.value.course_code || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.course-code') },
              'trim'
            ]
          }
        },
        {
          label: {
            text: `${t('common.common.table.course-title')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: `${restForm.value ? 'col-7' : 'd-none'}`
          },
          name: 'course_title',
          type: 'text',
          value: tempValues.value.course_title || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.course-title') },
              'trim'
            ]
          }
        },

        {
          label: {
            text: `${t('common.common.table.status')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: `${restForm.value ? 'col-2' : 'd-none'}`
          },
          name: 'status',
          type: 'select',
          value: tempValues.value.status || null,
          options: statusOptions()
        }

      ]
    }
  ])

  function nullForms () {
    restForm.value = false
    tempValues.value.department_id = null
  }

  const onChange = async (value) => {
    if (value.name === 'year') {
      tempValues.value.department_id = null
      value.newValue
        ? restForm.value = true
        : nullForms()
      store.syllabuses = null
      await resource.fetchAcademicResource({ modules: ['departments', 'instructors'], year: Number(value.newValue) as number })
      tempValues.value.year = value.newValue
    }
  }

  const onSubmit = async ({ values }) => {
    tempValues.value = {
      ...tempValues.value,
      ...values
    }

    const { status, message } = await store.fetchSyllabuses({ ...values, per_page: perPage.value, offset: 1 })
    if (!status) error({ text: message })
  }

  return {
    restForm,
    tempValues,
    formFields,
    onChange,
    onSubmit
  }
}
