import type { State } from '../types'
import { AdministrativeStaffsWorkloadResourcesResponse } from '@/shared/swagger'

export default function setDepartment (this: State, depCode: string, departments: AdministrativeStaffsWorkloadResourcesResponse['departments']) : void {
  departments.forEach(dep => {
    if (dep.department_code === depCode) {
      this.department = dep
    }
  })
}
