<template>
  <Teleport to="#attachment-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #file>
        <div class="mt-3">
          <label
            for="formFile"
            class="fs-6 fw-semibold mb-2 required"
          >
            {{ $t('common.choose-file') }}:
          </label>
          <input
            id="formFile"
            :data-bs-original-title="tempValues.id ? $t('domains.rules-and-regulations.attachment-form.file-select') :''"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-trigger="tempValues.id ? 'hover' : ''"
            class="form-control"
            type="file"
            accept="application/pdf, doc, docx"
            :required="!tempValues.id ? true: false"
            @change="onSelect"
          >
        </div>
      </template>
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { useLoader, useTeleportModal } from '@shared/composable'
import { SingleRuleGetResponse } from '@/shared/swagger'

import { useAttachmentModalForm } from '../composables'

const { isActiveOneOf } = useLoader()
const { show, modifier } = useTeleportModal()
const loader = isActiveOneOf(['academic/information/rules/single/attachments/create', 'academic/information/rules/single/attachments/single/edit-contents'])

const { onHandleChange, onSelect, onSubmit, tempValues, formFields, closeModal } = useAttachmentModalForm()

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.name === 'attachment-modal') {
      if (newValue.data?.attachment) {
        const attachment = newValue.data.attachment as SingleRuleGetResponse['attachments'][0]
        tempValues.value = { ...attachment, file: null }
      }
      show()
    }
  }, { immediate: true }
)
</script>
