<template>
  <div
    id="fixed-menu"
    class="card shadow"
  >
    <div class="card-body p-4 align-items-center">
      <span class="fw-bold fs-5 pe-0">
        {{ $t('common.action') }}:
      </span>
      <pms-button
        class="ms-2 py-2 px-4 pe-0"
        @click="goToGmail(studentEmails, courseDetails)"
      >
        <span>{{ $t('domains.academic.single-course.students.compose-email') }}</span>
        <pms-kt-icon
          :icon-name="'send'"
          class="mx-2 pb-1"
        />
      </pms-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import { goToGmail } from '../../../utils'

const props = defineProps({
  studentIds: {
    type: Array as PropType<Array<string>>,
    required: true
  },
  information: {
    type: Object,
    required: true
  }
})

const studentEmails = computed(() => props.studentIds.map(id => id + '@stu.sdu.edu.kz').join(', '))
const courseDetails = computed<string>(() => {
  return props.information ? `${props.information.code} - ${props.information.title} Grade` : ''
})
</script>

<style scoped lang="scss">
#fixed-menu {
  position: fixed;
  height: 60px;
  width: inherit;
  bottom: 0px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
