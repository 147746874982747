import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicInformationConsentRequestGetRequest,
  AcademicInformationConsentRequestGetResponse
} from '@shared/swagger'

export default async function getAcademicInformationConsentRequest (params: AcademicInformationConsentRequestGetRequest): Promise<AxiosResponse<AcademicInformationConsentRequestGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationConsentRequestGetResponse>({
    url: 'academic/information/consent-requests/sections',
    body: { params: params },
    loader: 'academic/information/consent-requests/sections'
  })

  return response
}
