<template>
  <vue-resizable
    id="greeting-widget"
    class="card card-flush"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-width="360"
    min-height="320"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-body py-6 px-8">
      <div class="row">
        <div class="col-12 px-10 py-0">
          <div class="d-flex flex-column">
            <div class="mt-0">
              <h4 class="text-gray-700 w-bolder mb-4">
                {{ $t('domains.dashboard.widgets.greeting.upcoming-events') }}
                <span
                  v-if="difference > 0"
                  class="ml-2 badge badge-light-success fs-8 fw-bolder"
                >
                  {{ $t('domains.dashboard.widgets.greeting.upcoming-difference-events', { difference: difference }) }}
                </span>
              </h4>
              <p
                v-for="(birth, index) in getFirstNEvents(greetings, BIRTH_DAY_LIMIT)"
                :key="index"
                v-test-id="'greeting-upcoming-events'"
                class="text-gray-700 fw-bold fs-4 me-3"
              >
                {{ getBirthday(birth.birth_date) }} - {{ birth.name }} {{ birth.surname }} {{ isToday(getDateMMDD(birth.birth_date)) ? '🎊': '' }}
              </p>
              <p
                v-if="greetings.length === 0"
                v-test-id="'no-data'"
                class="text-gray-700 text-center fw-bolder fs-4 me-3 pt-3"
              >
                {{ $t('domains.dashboard.widgets.greeting.no-upcoming-events') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { deviceType } from '@shared/utils'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { getDateMMDD, getBirthday, getFirstNEvents, isToday, onResize, getSavedWidgetSize } from '../utils'

const store = useDashboard()
const device = deviceType()

const BIRTH_DAY_LIMIT = 6

const { greetings } = storeToRefs(store)

const difference = computed(() => greetings.value.length - BIRTH_DAY_LIMIT)

const widgetId = 'greeting-widget'

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '33%', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
