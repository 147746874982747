import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentGradesGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentGrades (id: string): Promise<AxiosResponse<AcademicSingleStudentGradesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentGradesGetResponse>({
    url: `academic/students/${id}/grades`,
    body: {},
    loader: 'academic/students/grades'
  })

  return response
}
