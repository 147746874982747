
import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumGetWorkingCurriculumResponse } from '@shared/swagger'

export default async function fetchWorkingCurriculum (trackId: number): Promise<AxiosResponse<AcademicManageCurriculumGetWorkingCurriculumResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumGetWorkingCurriculumResponse>({
    url: `academic/manage/curriculum/working-curriculum/${trackId}`,
    body: {},
    loader: 'get-academic/manage/curriculum/working-curriculum'
  })

  return response
}
