<template>
  <div>
    <template v-if="selectedCourses">
      <label
        class="btn btn-primary btn-sm px-4 me-1"
        for="upload-excel"
      >
        <pms-kt-icon
          :icon-name="'arrow-up'"
          class="m-0 fs-4 p-0"
        />
        {{ $t('common.upload-excel') }}
      </label>
      <input
        id="upload-excel"
        type="file"
        accept=".xlsx, .xls"
        class="d-none"
        @change="uploadExcel"
      >
    </template>
    <pms-button
      :title="'common.download-template'"
      :size="'sm'"
      class="px-3"
      @click="downloadExel"
    >
      <pms-kt-icon
        :icon-name="'file-down'"
        class="m-0 fs-4 p-0"
      />
      {{ $t('common.download') }}
    </pms-button>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { read, utils, writeFile } from 'xlsx'
import { getFormattedDateFromExcel } from '../utils'
import { useAlert } from '@shared/composable'
import { selectedCourses, selectedCoursesReq, uploaded } from '../values'
import { AcademicInformationExamDatesResponse } from '@/shared/swagger'

const props = defineProps({
  courseCode: {
    type: Array as PropType<AcademicInformationExamDatesResponse>,
    required: true
  }
})

const { error } = useAlert()

const uploadExcel = (event) => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async (e) => {
      let workbook
      const data = e.target?.result

      try {
        workbook = read(data, { type: 'binary' })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        error({
          title: 'common.error',
          text: 'common.file.wrong-file-selected',
          textProperties: { message: e.message }
        })
        return
      }

      const firstSheet = workbook.SheetNames[0]
      const excelRows = workbook.Sheets[firstSheet]

      const rowA = Object.keys(excelRows).filter((key) => key.includes('A'))
      const rowE = Object.keys(excelRows).filter((key) => key.includes('E'))

      for (const index in rowA) {
        if (parseInt(index) === 0) {
          continue
        }

        const idRow = rowA[index]
        const examDateRow = rowE[index]

        const idCellValue = excelRows[idRow]?.v
        const examDateCellValue = excelRows[examDateRow]?.v

        if (idCellValue !== undefined && examDateCellValue !== undefined) {
          const id = parseInt(idCellValue)
          const examDate = getFormattedDateFromExcel(examDateCellValue)
          selectedCoursesReq.value.push({
            assessment_id: id,
            exam_date: examDate
          })
        }
      }

      uploaded.value = true
    }
  } else {
    error({
      title: 'common.error',
      text: 'common.file.no-file-selected'
    })
  }
}

const downloadExel = () => {
  const wb = utils.book_new()
  const wsData = props.courseCode.map((examDates) => [
    `${examDates.id}`,
    `${examDates.code}.${examDates.section}`,
    `${examDates.title}`,
    `${examDates.assessment}`,
    `${examDates.exam_date === null ? 'YYYY-MM-DD' : examDates.exam_date}`
  ])

  const ws = utils.aoa_to_sheet([['ID', 'Course Code', 'Course Title', 'Course Assessment', 'Exam Date'], ...wsData])

  ws['!cols'] = [{ wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 15 }]

  utils.book_append_sheet(wb, ws, 'Exam Dates')
  writeFile(wb, 'ExamDates.xlsx')
}

</script>
