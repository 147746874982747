import { useUser } from '@/shared/stores'
import { AxiosResponse } from '@/shared/types'

import type { State } from '../types'
import type { ResetPasswordRequest, ResetPasswordResponse } from '@shared/swagger'

export default async function resetPassword (this: State, credentials: ResetPasswordRequest): Promise<AxiosResponse<ResetPasswordResponse>> {
  const user = useUser()
  return await user.resetPassword(credentials)
}
