<template>
  <pms-card
    :title="$t('domains.knowledge-base.manage.search.search')"
    :description="$t('domains.knowledge-base.manage.search.search-description')"
    :options="{ body: { class: 'pt-0' }}"
  >
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="form-group mt-3">
          <pms-button
            type="submit"
            class="w-100"
            :text="$t('common.search')"
            :loader="searchLoader"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { LocationQueryRaw, useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import { useLoader, usePagination, useAlert } from '@shared/composable'
import { useResource } from '@shared/stores'

import { knowledgeManageResourcesCategoriesToOptions } from '../../../utils'
import { useKnowledgeManagePost } from '../../../store'

const { t } = useI18n()
const { error } = useAlert()
const { isActive } = useLoader()
const { perPage } = usePagination()

const resource = useResource()
const router = useRouter()
const route = useRoute()

const store = useKnowledgeManagePost()
const searchLoader = isActive('knowledge/manage/resources')

const tempValues = ref<{
  search: string | null
  category: string | null
}>({
  search: null,
  category: null
})

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('common.form.title'),
      class: 'mb-1'
    },
    name: 'search',
    type: 'text',
    value: tempValues.value.search || ''
  },
  {
    label: {
      text: t('common.form.category'),
      class: 'mb-1 mt-2'
    },
    name: 'category',
    type: 'select',
    options: knowledgeManageResourcesCategoriesToOptions(resource.getKnowledgeManageResourceCategories),
    value: tempValues.value.category || null
  }
])

const onSubmit = async ({ values }) => {
  const query : LocationQueryRaw = {}

  query.search = values.search
  query.category = values.category

  router.push({
    name: 'KnowledgeManagePostsListing',
    query
  })

  const { status, message } = await store.fetchPosts({ ...values, offset: 1, per_page: perPage.value })
  if (!status) {
    error({ text: message })
  }
}

onMounted(() => {
  if (route.query.search) {
    tempValues.value.search = route.query.search ? String(route.query.search) : null
  }
  if (route.query.category) {
    tempValues.value.category = route.query.category ? String(route.query.category) : null
  }
})
</script>
