import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileSkillsInterestsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileSkillsInterests (): Promise<AxiosResponse<ProfileSkillsInterestsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileSkillsInterestsGetResponse>({
    url: 'profile/skills/interests',
    body: {},
    loader: 'profile/skills/interests'
  })
  return response
}
