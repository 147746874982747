/* eslint-disable no-return-assign */

const applyStyle = (el, value) => Object.keys(value).forEach(key => el.style[key] = value[key])

const styler = {
  mounted: (el, binding) => applyStyle(el, binding.value),
  updated: (el, binding) => applyStyle(el, binding.value)
}

export default {
  name: 'styler',
  directive: styler
}
