import { useRequests } from '@/shared/composable'

import type {
  AcademicMySectionsGetRequest,
  AcademicMySectionsGetResponse,
  UnknownRequest
} from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicSectionsMy (params: AcademicMySectionsGetRequest): Promise<AxiosResponse<AcademicMySectionsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicMySectionsGetResponse>({
    url: 'academic/courses/sections',
    body: { params },
    loader: 'academic/courses/sections'
  })

  return response
}
