<template>
  <pms-loader v-if="loader" />
  <div v-else>
    <h5 class="fs-6 fw-semibold m-0 p-0">
      {{ $t('domains.academic.manage.sections.course-statistics') }}
    </h5>
    <pms-smart-table
      :items="statistics"
      :headers="courseStatisticsTable"
      :sortable="false"
    >
      <template #class="{ item }">
        {{ item.class }}
      </template>
      <template #enrolled="{ item }">
        {{ item.enrolled }}
      </template>
      <template #passed="{ item }">
        {{ item.passed }}
      </template>
      <template #failed="{ item }">
        {{ item.failed }}
      </template>
      <template #wishList="{ item }">
        <span class="fs-5 fw-bold">{{ item.wish_list }}</span>
      </template>
      <template #willTake="{ item }">
        <span class="fs-5 fw-bold">{{ item.will_take }}</span>
      </template>
    </pms-smart-table>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useNewRequest } from './../store'
import { courseStatisticsTable } from './../values'
import { SectionManagementCoursesStatisticsGetRequest } from '@shared/swagger'
import { useLoader } from '@shared/composable'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const loader = isActive('academic/manage/sections/courses/statistics')
const store = useNewRequest()
const { selectedProgram } = storeToRefs(store)

const props = defineProps<{
  equicode: number
}>()

const newRequestStore = useNewRequest()
const statistics = computed(() => newRequestStore.requestedCourseStatistics)

onMounted(async () => {
  const params : SectionManagementCoursesStatisticsGetRequest = {
    program_code: parseInt(selectedProgram.value?.code + '') || -1,
    equicode: props.equicode
  }
  await newRequestStore.fetchCourseStatistics(params)
})
</script>
