import { SmartTableHeader } from '@/shared/types'

export const headers: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no'
  },
  {
    title: 'common.full-name',
    field: 'fullName',
    slotCellName: 'fullName',
    sortable: true
  },
  {
    title: 'common.action',
    field: 'action',
    slotCellName: 'action'
  }
]
