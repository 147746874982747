export const signIn = {
  title: 'domains.authenticator.sign-in.aside.title',
  description: 'domains.authenticator.sign-in.aside.description',
  imageClass: 'w-lg-650px',
  darkImage: '/img/sign-in.png',
  lightImage: '/img/sign-in.png'
}

export const forgotPassword = {
  title: 'domains.authenticator.forgot-password.aside.title',
  description: 'domains.authenticator.forgot-password.aside.description',
  imageClass: 'w-150px w-lg-300px',
  darkImage: '/media/auth/agency.png',
  lightImage: '/media/auth/agency-dark.png'
}

export const resetPassword = {
  title: 'domains.authenticator.reset-password.aside.title',
  description: 'domains.authenticator.reset-password.aside.description',
  imageClass: 'w-150px w-lg-300px',
  darkImage: '/media/auth/agency.png',
  lightImage: '/media/auth/agency-dark.png'
}
