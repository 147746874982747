import type { StoreType } from '../types'
import { getAcademicManageCurriculumTracks } from '@shared/api'

export default async function fetchTracks (this: StoreType, year: number, department: string, program: string): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumTracks({ year, department_code: department, program_code: program })

  if (ok) {
    this.setTracks(data)
  }
}
