<template>
  <pms-card title="EditForm">
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <pms-smart-form
      v-else
      class="d-flex flex-wrap"
      :fields="[courseSettingsFormFields]"
      :options="{
        controls: {class: 'w-100 mt-5'}
      }"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <br>
        <div class="form-group mt-3 w-100 mt-2 d-flex gap-5">
          <pms-button
            type="button"
            :disabled="saveLoader"
            :class="isManagingCourses ? 'btn-warning' : ''"
            @Click="toogleManageCourses()"
          >
            {{ isManagingCourses ? t('domains.academic.curricula.view.cancel-manage-courses-button') : t('domains.academic.curricula.view.manage-courses-button') }}
          </pms-button>
          <pms-button
            type="submit"
            :disabled="saveLoader"
            :text="$t('common.submit')"
          />
        </div>
      </template>
    </pms-smart-form>
    <pms-divider />
    <pms-smart-table
      v-if="isManagingCourses || tableData.electiveCourses.filter((item) => item.in_group).length > 0"
      id="courses-table"
      :headers="headers"
      :items="isManagingCourses ? tableData.electiveCoursesToShow : tableData.electiveCourses.filter((item) => item.in_group)"
      :sortable="true"
    >
      <template #checkbox="{item}">
        <pms-checkbox
          class="d-flex justify-content-center"
          :state="item.isSelected"
          :disabled="false"
          @on-check="onCheckToogleCourse(item)"
          @on-uncheck="onCheckToogleCourse(item)"
        />
      </template>
      <template #code="{item}">
        {{ item.course_code }}
      </template>
      <template #title="{item}">
        {{ item.title }}
      </template>
      <template #credits="{item}">
        {{ item.credits }}
      </template>
      <template #ects="{item}">
        {{ item.ects }}
      </template>
      <template #language_code="{item}">
        {{ item.language_code }}
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script lang="ts" setup>
import useCoreEditor from '../composable/useElectiveSlotEditor'
import { useLoader } from '@/shared/composable'
import { headers } from '../values/index'
import { useI18n } from 'vue-i18n'

const { isActive } = useLoader()
const loader = isActive('api-fetchCurriculumElectiveSlot')
const saveLoader = isActive('put-academic/manage/curriculum/electives')
const { t } = useI18n()

const { slotSettings, courseSettingsFormFields, tableData, onCheckToogleCourse, onSubmit, isManagingCourses, toogleManageCourses } = useCoreEditor()

const onHandleChange = ({ name, newValue }) => {
  slotSettings.value[name] = newValue
}
</script>
<style scope>
.formItem {
  width: 200px
}
</style>
