<template>
  <vue-resizable
    id="calendar-widget"
    class="resizable-widget card card-flush"
    :width="widgetSize.width"
    min-width="380"
    min-height="300"
    :height="widgetSize.height"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5 flex-nowrap">
      <h3 class="card-title flex-nowrap align-items-start flex-column">
        <span
          class="card-label fw-bolder text-dark"
          test="calendar-title"
        >
          {{ $t('domains.dashboard.widgets.calendar.title') }}
        </span>
        <span
          class="text-muted mt-1 fw-bold fs-7"
          test="calendar-description"
        >
          {{ $t('domains.dashboard.widgets.calendar.description') }}
        </span>
      </h3>
    </div>
    <div class="card-body pt-2">
      <table class="table gx-2 text-center mb-0 pb-0">
        <tr
          v-for="(column, i) in calendar"
          :key="i"
          class="border-0 text-center"
          :class="{
            'd-none': hideChecker(column)
          }"
        >
          <td
            v-for="(row, j) in column"
            :key="j"
            :class="row.tdClass"
            class="text-center align-middle"
          >
            <span
              :day="row.day"
              :class="`${row.class}`"
              :active="row.active"
              v-html="row.content"
            />
          </td>
        </tr>
      </table>
      <div
        v-if="comEvents.length !== 0"
        class="d-flex align-items-center bg-light-danger rounded p-5 mt-4"
      >
        <span class="svg-icon svg-icon-danger me-5">
          <span class="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                fill="currentColor"
              />
              <path
                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </span>
        <div
          class="flex-grow-1 me-2"
        >
          <span class="fw-bold text-muted fs-6 mb-2">
            {{ $t('domains.dashboard.widgets.calendar.important-alerts') }}
          </span>
          <span
            v-for="(item, index) in comEvents"
            :key="index"
            class="fw-bolder d-block fs-4 mt-2"
            test="calendar-item"
          >
            - {{ $t(`domains.dashboard.widgets.calendar.important-alerts-content-${item.passed ? 'passed': 'upcoming'}`, {content: item.content, end: item.endDate, difference: item.difference}) }}
          </span>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>

<script lang="ts" setup>
import { useUser } from '@shared/stores'
import { computed, ref, onMounted } from 'vue'
import vueResizable from 'vue-resizable'
import { generateCalendar, hideChecker, eventsCompiled, onResize, getSavedWidgetSize } from '../utils'
import { DashboardCompiledEvent } from '../types'

const widgetId = 'calendar-widget'

const widgetSize = ref({ width: 'auto', height: 'auto' })

const user = useUser()

const maxDay = 7
const currentDate = new Date()
const calendar = generateCalendar(currentDate)
const events = computed(() => user.getEvents)
const comEvents: DashboardCompiledEvent[] = eventsCompiled(events.value, maxDay)

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
