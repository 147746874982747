<template>
  <div>
    <p class="fs-2 fw-bold mb-0">
      {{ props.post.title }}
    </p>
    <div class="fw-semibold text-muted d-flex fs-6">
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.category') }}: {{ props.post.category_title }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.views') }}: {{ props.post.views }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.posted-at') }}: {{ props.post.created_at }}
      </p>
    </div>
    <div
      class="fs-5 mb-1"
      v-html="props.post.description"
    />
    <div class="d-flex justify-content-between">
      <KnowledgeManagePostLink :uuid="props.post.uuid" />
      <KnowledgeManagePostAction :uuid="props.post.uuid" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { KnowledgeManagePostsGetResponse } from '@shared/swagger'
import KnowledgeManagePostLink from './KnowledgeManagePostLink.vue'
import KnowledgeManagePostAction from './KnowledgeManagePostAction.vue'

const props = defineProps<{
  post: KnowledgeManagePostsGetResponse['posts'][0]
}>()
</script>
