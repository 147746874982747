import { DepartmentPlain } from '../type'
import { ServiceMessageBroadcastResourceResponse } from '@shared/swagger'

const formatDepartments = (programs?: ServiceMessageBroadcastResourceResponse['department_programs'] | null): Array<DepartmentPlain> => {
  const depsUnique: ServiceMessageBroadcastResourceResponse['department_programs'] = []
  const depPlain: Array<DepartmentPlain> = []
  let count = 10

  if (!programs) return depPlain

  programs.forEach((dep) => {
    const index = depsUnique.findIndex((d) => d.department_code === dep.department_code)
    if (index === -1) depsUnique.push(dep)
  })

  depsUnique.forEach((dep) => {
    dep.programs.forEach((program) => {
      depPlain.push({
        departmentCode: dep.department_code,
        programCode: program.program_code,
        programTitle: program.program_title,
        educationLanguage: program.education_language,
        educationLevel: program.education_level,
        zeroClass: true,
        firstClass: true,
        secondClass: true,
        thirdClass: true,
        fourthClass: true,
        fifthClass: true,
        uuid: count++
      })
    })
  })

  return depPlain
}

export default formatDepartments
