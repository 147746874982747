import type { AcademicManageSyllabusGetResponse } from '@shared/swagger'
import type { FormattedDescription } from '../types'

export default function convertDescriptionsToUserFormat (data: AcademicManageSyllabusGetResponse['description']): FormattedDescription {
  return {
    objective: data.objective === null ? '' : data.objective,
    content: data.content === null ? '' : data.content,
    components: data.components === null ? '' : data.components,
    work_placement: data.work_placement === null ? '' : data.work_placement
  }
}
