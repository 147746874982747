<template>
  <div
    id="attendance-history"
    class="mt-4"
  >
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h3 class="py-2">
        <span class="card-label fw-bold text-dark fs-3">
          {{ $t('domains.academic.single-course.history.attendance-title') }}
        </span>
      </h3>
      <section-selector />
    </div>
    <div
      v-if="currentSection !== null && attendanceDetailsForGraph && Object.keys(attendanceDetailsForGraph).length === 0"
    >
      <pms-no-data class="mt-20 fs-4 fw-bold" />
    </div>
    <div v-else-if="attendanceDetailsForGraph">
      <pms-tab
        v-model:active="activeTab"
        class="mt-4"
        :tabs="attendanceDetailsForGraph.hourTabs"
        :icon="false"
        :all="false"
      >
        <template
          v-for="hour in attendanceDetailsForGraph.hours"
          :key="hour.hour"
          #[`${hour.hour}`]
        >
          <apexchart
            type="area"
            height="350"
            :options="attendanceGraphOptions"
            :series="hour.series"
          />
        </template>
      </pms-tab>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useSingleCourse } from '../../../store'
import { formatAttendanceForGraphs } from '../utils'
import { currentSection, attendanceGraphOptions } from '../values'
import SectionSelector from './SectionSelector.vue'

const store = useSingleCourse()

const attendanceDetailsForGraph = computed(() => {
  return formatAttendanceForGraphs(store.getHistoryAttendance, currentSection.value)
})
const activeTab = ref <string | number | null>(attendanceDetailsForGraph.value?.activeHour ?? null)
attendanceGraphOptions.value.xaxis.categories = attendanceDetailsForGraph.value?.dates ?? []

watch(() => attendanceDetailsForGraph,
  (newValue) => {
    attendanceGraphOptions.value.xaxis.categories = newValue.value?.dates ?? []
    activeTab.value = newValue.value?.activeHour ?? null
  }
)

</script>
