import { SmartTableHeader } from '@/shared/types'

export const transcriptTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.course-code',
    field: 'courseCode'
  },
  {
    title: 'common.table.course-title',
    field: 'title'
  },
  {
    title: 'common.table.internal-credits',
    field: 'credits'
  },
  {
    title: 'common.table.credit-ects',
    field: 'ects'
  },
  {
    title: 'common.table.grade',
    field: 'grade'
  },
  {
    title: 'common.table.grade-letter',
    field: 'gradeLetter'
  },
  {
    title: 'common.table.grade-point',
    field: 'gradePoint'
  },
  {
    title: 'common.table.grade-traditional',
    field: 'gradeTraditional'
  }
]
