import { SingleCourseSidebarItem } from '../types'

export const menuItems: Array<SingleCourseSidebarItem> = [
  {
    to: '/academic/course',
    routeName: 'SingleCourseOverviewPage',
    label: 'domains.academic.single-course.navigation.items.overview'
  },
  {
    to: '/academic/course/attendance',
    routeName: 'SingleCourseAttendancePage',
    label: 'domains.academic.single-course.navigation.items.attendance'
  },
  {
    to: '/academic/course/syllabus',
    routeName: 'SingleCourseSyllabusPage',
    label: 'domains.academic.single-course.navigation.items.syllabus'
  },
  {
    to: '/academic/course/schedule',
    routeName: 'SingleCourseSchedulePage',
    label: 'domains.academic.single-course.navigation.items.schedule'
  },
  {
    to: '/academic/course/exam-dates',
    routeName: 'SingleCourseExamDatesPage',
    label: 'domains.academic.single-course.navigation.items.exam-dates'
  },
  {
    to: '/academic/course/history',
    routeName: 'SingleCourseHistoryPage',
    label: 'domains.academic.single-course.navigation.items.history'
  }

]
