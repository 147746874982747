import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationPostRequest } from '@shared/swagger'

export default async function postProfileEducation (params: ProfileEducationPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileEducationPostRequest, UnknownResponse>({
    url: 'profile/education',
    body: params,
    loader: 'profile/education-post'
  })

  return response
}
