<template>
  <template
    v-for="(key) in addresses.keys()"
    :key="key"
  >
    <pms-grid :col="12">
      <address-card
        :address-type="key"
        :item="addresses.get(key)"
      />
    </pms-grid>
  </template>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useProfile } from '../../../store'

import AddressCard from './AddressCard.vue'

const store = useProfile()
const addresses = computed(() => store.getAddresses)
</script>
