
import type { StatusMessageResponse } from '@/shared/types'
import postDashboardWidgets from '@/shared/api/Dashboard/postDashboardWidgets'
import { useAlert } from '@shared/composable'
import { DashboardWidgetsPostRequest } from '@/shared/swagger'
import { StoreType } from '../types'

export default async function addWidgets (this: StoreType, values: DashboardWidgetsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postDashboardWidgets(values)
  const { error } = useAlert()

  await this.bootstrap()

  if (!ok) {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
