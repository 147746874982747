import { Service } from '../../types'
import icon from './icon'

const mail: Service = {
  id: 'mail-service',
  title: 'domains.service.services.mail-service.title',
  description: 'domains.service.services.mail-service.description',
  categories: ['general'],
  url: 'https://mail.google.com/',
  route: '/services/mail-service',
  image: 'https://images.fonearena.com/blog/wp-content/uploads/2022/07/A-Unified-Gmail.png',
  icon,
  contents: async () => (await import('@/contents/services/mail.json')).default
}

export default mail
