import { useI18n } from 'vue-i18n'
import { camelCaseToLowerKebabCase } from '@shared/utils'

import type { SelectField } from '@shared/types'
import type { AcademicInformationResourcesResponse } from '@shared/swagger'

const allowed = ['FIN', 'FA']

export default (data: AcademicInformationResourcesResponse['assess_types']): SelectField['options'] => {
  const { t } = useI18n()
  const options: SelectField['options'] = []
  data
    .filter((assess) => allowed.includes(assess))
    .forEach((assess) => {
      options.push({
        label: t(`common.assessments.${camelCaseToLowerKebabCase(assess)}`),
        value: assess
      })
    })

  return options
}
