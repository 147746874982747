<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <toolbar-actions />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row
      class="mt-4"
    >
      <section-list class="mb-2" />
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'

import { useTour, useToolbar } from '@shared/composable'
import { tour, header } from './values'

import ToolbarActions from './ui/ToolbarActions.vue'
import SectionList from './ui/SectionList.vue'

const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
