<template>
  <div class="card">
    <div class="card-body">
      <div>
        <pms-button
          @click="goBack"
        >
          <i class="bi bi-arrow-left" />
          {{ $t('common.back') }}
        </pms-button>
      </div>
      <div
        v-if="loader"
      >
        <pms-page-shimmer
          :shimmer="'table'"
        />
        <pms-page-shimmer
          :shimmer="'table'"
        />
      </div>
      <div
        v-else
      >
        <div
          class="text-center mt-3"
        >
          <h3>
            {{ single_report.title }} ({{ single_report.id }})
          </h3>
          <span class="fs-5 text-gray-500">
            {{ single_report.description }}
          </span>
        </div>
        <form
          class="mt-3 pb-3 border rounded rounded-lg overflow-hidden"
          @submit.prevent="execute(report_id)"
        >
          <div>
            <SearchFields v-if="single_report.default_fields.length>0 || single_report.additional_fields.length>0" />
          </div>
          <div>
            <InputSelectParams v-if="single_report.parameters.length>0" />
          </div>
          <div class="d-flex justify-content-center">
            <pms-button
              type="submit"
              :disabled="execute_loader"
            >
              {{ execute_loader ? $t('common.loading') + '...' : $t('domains.academic.reports.execute') }}
            </pms-button>
            <pms-button
              class="ms-2"
              :disabled="export_loader"
              @click="exportToPDF(report_id)"
            >
              {{ export_loader ? $t('common.loading') + '...' : $t('domains.academic.reports.export') }}
            </pms-button>
          </div>
        </form>
        <div
          v-if="report_data.length>0"
          class="table-responsive mt-3"
        >
          <ReportTable />
        </div>
        <div
          v-else-if="report_data && report_data.length==0"
          class="text-center mt-3"
        >
          {{ $t('common.no-data') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { router } from '@/router'
import { useLoader } from '@/shared/composable'
import { computed, watch } from 'vue'
import { useViewPageComposable } from './composable'
import { storeToRefs } from 'pinia'
import { useAcademicReports } from '../../store'

import SearchFields from './ui/SearchFields.vue'
import InputSelectParams from './ui/InputSelectParams.vue'
import ReportTable from './ui/ReportTable.vue'

const loader = useLoader().isActiveOneOf(['academic/information/reports/single_report'])

const store = useAcademicReports()

const { fetchSingleReport, execute, exportToPDF } = useViewPageComposable()
const { single_report, report_data } = storeToRefs(store)
const report_id = computed(() => router.currentRoute.value.params.report_id as string)

function goBack () {
  router.back()
}

const execute_loader = useLoader().isActive('academic/information/reports/execute')
const export_loader = useLoader().isActive('academic/information/reports/export')

watch(report_id, (newValue) => {
  if (newValue) {
    fetchSingleReport(newValue)
  }
}, {
  immediate: true
})
</script>
