import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  AdministrativeStaffsInnerInnerContactsPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAdministrativeStaffsInnerContacts (values: AdministrativeStaffsInnerInnerContactsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<AdministrativeStaffsInnerInnerContactsPutRequest, UnknownResponse>({
    url: 'administrative/staffs/inner-contacts',
    body: values,
    loader: 'administrative/staffs/inner-contacts'
  })

  return response
}
