import { useAlert } from '@/shared/composable'
import type { StoreType } from '../types'
import { getAcademicSingleStudentAccounting } from '@shared/api'

export default async function fetchAccounting (this: StoreType, id: string): Promise<void> {
  const { data, ok, message } = await getAcademicSingleStudentAccounting(id)
  const { error } = useAlert()

  if (ok) {
    this.setAccounting(data)
  } else {
    error({ text: message, isToast: true })
  }
}
