import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'
import * as actions from './actions'
import * as getters from './getters'

export const useSearchKnowledgeBase = defineStore<Name, State, Getters, Actions>('KnowledgeBase.useSearchKnowledgeBase', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      searchedKnowledgeBase: []
    }
  }
})
