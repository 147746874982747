import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useStaffList = defineStore<Name, State, Getters, Actions>('Administrative.useStaffList', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      employees: null,
      workload: [],
      step: 'employee',
      department: {
        department_code: '',
        title: '',
        faculty: '',
        faculty_code: ''
      },
      year: 0,
      term: 0
    }
  }
})
