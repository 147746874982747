import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceEsignGetResponse, ServiceEsignGetRequest, UnknownRequest } from '@shared/swagger'

export default async function getServiceEsigns (params: ServiceEsignGetRequest): Promise<AxiosResponse<ServiceEsignGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceEsignGetResponse>({
    url: 'service/manage/esigns',
    body: { params: params },
    loader: 'service/manage/esigns/get'
  })

  return response
}
