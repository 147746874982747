import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { CheckTokenRequest, CheckTokenResponse } from '@shared/swagger'

export default async function postUserCheckToken (body: CheckTokenRequest): Promise<AxiosResponse<CheckTokenResponse>> {
  const { post } = useRequests()

  const response = post<CheckTokenRequest, CheckTokenResponse>({
    url: 'check-token',
    body,
    loader: '/1.0.0/check-token'
  })
  return response
}
