
const generateHtml = (element: HTMLElement) => {
  return `
      <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Inter:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
          <title>Exam participation list</title>
          <style type="text/css">
        
    
        td {
            padding-left:2px; 
            font-family:Arial, Helvetica, sans-serif
        }
        @media print {
            .more { 
                page-break-after: always;
            } 
        } 

        </style>
      </head>
      <body>
      ${element.innerHTML}
      </body> 
    `
}

const printParticipationList = (element: HTMLElement) => {
  const printWindow: Window | null = window.open('', '', 'height=400,width=500')

  if (!printWindow) return
  printWindow.document.write(generateHtml(element))
  printWindow.document.close()
  printWindow.focus()
  setTimeout(() => printWindow.print(), 100)
}

export default printParticipationList
