<template>
  <pms-card
    :header="false"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      :headers="headers"
      :items="requests"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #course_code="{ item }">
        <pms-badge
          :value="item.course_code"
          :size="'md'"
          class="me-2 "
        />
      </template>
      <template #course_title="{ item }">
        {{ item.course_title }}
      </template>
      <template #section="{ item }">
        <pms-badge
          :value="item.section"
          :size="'md'"
          class="me-2 "
        />
      </template>
      <template #teacher="{ item }">
        {{ item.teacher }}
      </template>
      <template #pendings="{ item }">
        {{ item.pending_requests_count }}
      </template>
      <template #accepteds="{ item }">
        {{ item.accepted_requests_count }}
      </template>
      <template #rejecteds="{ item }">
        {{ item.rejected_requests_count }}
      </template>
      <template #action="{ item }">
        <router-link
          :to="openContentRequest(item)"
          class="btn btn-sm btn-light-primary"
          @click="setSection(item)"
        >
          {{ $t('common.details') }}
        </router-link>
      </template>
    </pms-smart-table>
    <pms-pagination class="p-0 m-0 mt-6" />
  </pms-card>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader, usePagination } from '@shared/composable'

import { consentTable as headers, selectedSection } from '../values'
import { useConsentRequest } from '../store'
import { AcademicInformationConsentRequestGetResponse } from '@/shared/swagger'

const { isActive } = useLoader()
const { perPage } = usePagination()

const store = useConsentRequest()

const loader = isActive('academic/information/consent-requests/sections')
const requests = computed(() => store.getConsentRequests)
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const openContentRequest = (item: AcademicInformationConsentRequestGetResponse['sections'][0]): string => {
  const section = item.id

  return '/academic/courses/consent-requests/request' +
    `?section=${section}&course_code=${item.course_code}&course_title=${item.course_title}`
}

const setSection = (item: AcademicInformationConsentRequestGetResponse['sections'][0]) => {
  if (item.id) {
    selectedSection.value.id = item.id
  }
}

onMounted(async () => {
  await store.fetchConsentRequests({
    year: currentYearTerm.value.year,
    term: currentYearTerm.value.term,
    per_page: perPage.value,
    offset: 1
  })
})
</script>
