import { SmartTableHeader, SmartTableOptions } from '@/shared/types'

export const additional_info_table_headers:Array<SmartTableHeader> = [
  {
    title: 'common.table.program_code',
    field: 'program_code',
    slotCellName: 'program_code'
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    slotCellName: 'speciality'
  },
  {
    title: 'common.table.reqiusite_expression',
    field: 'requisite_expression'
  },
  {
    title: 'common.table.consent_status',
    field: 'consent_status'
  }
]

export const additional_info_table_options:SmartTableOptions = {
  table: {
    class: 'table-bordered'
  },
  headers: {
    style: 'background-color:none!important'
  }
}

export const courses_table_headers:Array<SmartTableHeader> = [
  {
    title: 'common.table.course_code',
    field: 'code'
  },
  {
    title: 'common.table.year',
    field: 'year'
  },
  {
    title: 'common.table.course_title',
    field: 'title_en'
  },
  {
    title: 'common.table.equicode',
    field: 'equicode'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const courses_table_options:SmartTableOptions = {
  table: {
    class: 'table-bordered'
  },
  headers: {
    style: 'background-color:none!important'
  }
}

export const items_table_headers:Array<SmartTableHeader> = [
  {
    title: 'common.table.code',
    field: 'key'
  },
  {
    title: 'common.table.course_code',
    field: 'course_code'
  },
  {
    title: 'common.table.course_title',
    field: 'title'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action'
  }
]

export const items_table_options:SmartTableOptions = {
  table: {
    class: 'table-bordered'
  },
  headers: {
    style: 'background-color:none!important'
  }
}
