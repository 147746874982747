import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementOfferPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postSectionManagementOffer (params: SectionManagementOfferPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: 'academic/manage/sections/offers',
    body: params,
    loader: 'academic/manage/sections/offers'
  })

  return response
}
