<template>
  <pms-dropdown
    :btn-class="'btn btn-light-primary btn-sm p-2 px-4 text-nowrap'"
    :icon-placement="'start'"
    :placement="'end'"
  >
    <template
      v-for="(item, index) in dropdownItems"
      :key="index"
    >
      <div
        v-if="item.type === 'link' && (
          item.link === '/grades'
            ? course.type === 'N'
              ? true
              : false
            : true
        )"
        class="menu-item w-175px"
      >
        <router-link
          :to="'/academic/courses/section' + item.link + `?year=${currentYearTerm.year}&term=${currentYearTerm.term}&code=${course.code}&section=${course.section}&previous_page=AcademicCoursesMySections`"
          class="menu-link px-3 py-2"
        >
          <span
            class="menu-icon"
            data-kt-element="icon"
          >
            <i
              class="bi"
              :class="item.icon"
            />
          </span>
          <span class="menu-title">{{ $t(item.label) }}</span>
        </router-link>
      </div>
      <div
        v-if="item.type === 'separator'"
        class="separator my-1"
      />
      <span
        v-if="item.label === 'bell_curve' && course.type === 'N'"
        class="btn btn-sm mx-0 menu-item"
        :class="[
          { 'disabled' : !currentYearTerm.bell_curve_is_active },
          course.bell_curve_enabled ? 'btn-light-danger' : 'btn-light-success'
        ]"
        @click="handleBellCurveAtionButtonClick(course)"
      >
        {{ course.bell_curve_enabled ? 'Disable bell curve' : 'Enable bell curve' }}
      </span>
      <span
        v-if="item.label === 'moodle_sync' "
        class="btn btn-sm mx-0 menu-item btn-light-info d-flex gap-2 align-items-center justify-content-center"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-kt-initialized="1"
        :data-bs-original-title="course.last_moodle_sync_date && `${$t('domains.academic.single-section.last-sync-date')}:  ${course.last_moodle_sync_date.split(' ')[0]}`"
        @click="handleMoodleSyncClick(course)"
      >
        <div
          v-if="loader"
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        {{ 'Sync with moodle' }}
      </span>
    </template>
  </pms-dropdown>
</template>

<script setup lang="ts">
import { useAlert, useLoader } from '@/shared/composable'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'
import { AcademicMySectionsGetResponse } from '@/shared/swagger'
import { dropdownItems } from '../values'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { dialog } = useAlert()
const { isActive } = useLoader()
const { t } = useI18n()
const loader = isActive('integration/moodle/grades')
const { currentYearTerm } = storeToRefs(store)

defineProps({
  course: {
    type: Object as PropType<AcademicMySectionsGetResponse[0]>,
    required: true
  }
})

const handleBellCurveAtionButtonClick = (item: AcademicMySectionsGetResponse[0]) => {
  dialog({
    text: `${t('common.dialog')} ` + (item.bell_curve_enabled ? t('common.disable') : t('common.enable')) + ` ${t('common.bell-curve')}?`,
    onConfirm: () => {
      store.putBellCurveEnabled({
        section_id: item.id,
        enable: !item.bell_curve_enabled
      })
    }
  })
}
const handleMoodleSyncClick = (item: AcademicMySectionsGetResponse[0]) => {
  store.integrateSectionWithMoodle({
    course_code: item.code,
    section_id: item.id,
    section: item.section + '' + item.type,
    year: currentYearTerm.value.year,
    term: currentYearTerm.value.term
  })
}
</script>
