import type { StoreType } from '../types'
import { getStudentMe } from '@shared/api'
import { StudentMeResponse } from '@shared/swagger'
import { useToken } from '@/shared/composable'

export default async function fetchStudent (this: StoreType): Promise<void> {
  const { ok, status, data } = await getStudentMe()

  if (status === 400) {
    this.setUser(null)
    useToken().clearStudentTokens()
    return
  }

  if (ok) {
    const { information, events } = data

    this.setUser(information as unknown as StudentMeResponse['information'])
    this.setEvents(events as unknown as StudentMeResponse['events'])
    this.setAuthenticated(true)
  }
}
