import { defineComponent, ref } from 'vue'

const youtube_parser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}

export default defineComponent({
  name: 'YoutubeWrapper',
  props: {
    src: {
      type: String,
      required: true
    },
    cover: {
      type: String,
      required: true
    },
    height: {
      type: [Number, String],
      default: '450'
    },
    width: {
      type: [Number, String],
      default: '100%'
    }
  },
  setup (props) {
    const videoId = youtube_parser(props.src)
    const showVideo = ref(false)

    return () => (
      <>
        {
          !showVideo.value && (
            <div
              class="d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-450px mb-5"
              style={ `background-image:url('${props.cover}')` }
            >
              <img
                src="/media/svg/misc/video-play.svg"
                class="position-absolute top-50 start-50 translate-middle cursor-pointer"
                onClick={ () => { showVideo.value = true } }
              />
            </div>
          )
        }
        {
          showVideo.value && (
            <iframe
              class="embed-responsive-item card-rounded"
              width={props.width}
              height={props.height}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&origin=${window.location.origin}`}
              frameborder="0"
              allowfullscreen
            />
          )
        }
      </>
    )
  }
})
