<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'table'"
    :full-width="true"
  />
  <template v-else>
    <ListView
      :courses="courses"
      :current-year-term="currentYearTerm"
    />
  </template>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLoader } from '@shared/composable'

import { useStore } from '../store'
import ListView from './ListView.vue'

const store = useStore()
const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf(['academic/courses/sections', 'academic/courses/resources'])
// eslint-disable-next-line dot-notation, vue/no-side-effects-in-computed-properties
const courses = computed(() => store.getMySections.sort((a, b) => parseInt(a.section) - parseInt(b.section)))
const currentYearTerm = computed(() => store.getCurrentYearTerm)
</script>
