import { MeResponse } from '@shared/swagger'
import { DashboardCompiledEvent } from '../types/calendar'

const eventsCompiled = (events: MeResponse['events'], maxDays: number): DashboardCompiledEvent[] => {
  const eventsCompiled: DashboardCompiledEvent[] = []

  for (let i = 0; i < events.length; i++) {
    const event = events[i]
    const [dy, mth, yr] = event.end_date.split('.')
    const [hr, min, sec] = event.end_time.split(':')
    const end = new Date(
      parseInt(yr),
      parseInt(mth) - 1,
      parseInt(dy),
      parseInt(hr || '00'),
      parseInt(min || '00'),
      parseInt(sec || '00')
    )

    const diffTime = Math.abs(new Date().getTime() - end.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const passed: boolean = new Date().getTime() > end.getTime()

    if (diffDays <= maxDays && event.is_open) {
      eventsCompiled.push({
        content: event.title,
        endDate: end.toLocaleDateString(),
        difference: diffDays,
        passed: passed
      })
    }
  }

  return eventsCompiled
}

export default eventsCompiled
