import type { StatusMessageResponse } from '@shared/types'
import type { ServiceManageLocksReschedulePutRequest } from '@shared/swagger'
import { putServiceManageLocksReschedule } from '@shared/api'

import type { StoreType } from '../types'

export default async function editLocks (this:StoreType, values: ServiceManageLocksReschedulePutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLocksReschedule(values)

  return {
    status: ok,
    message: message
  }
}
