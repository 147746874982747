import { SmartTableHeader } from '@/shared/types'

export const allStudentsTable: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.student-id',
    field: 'id',
    slotCellName: 'id-number',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.full_name',
    field: 'full_name',
    slotCellName: 'full_name',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.class',
    field: 'class',
    slotCellName: 'class',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    slotCellName: 'speciality',
    sortable: false
  },
  {
    title: 'common.table.language_level',
    field: 'language_level',
    slotCellName: 'language_level',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const allApplicantsTable: Array<SmartTableHeader> = [
  {
    title: 'common.select',
    field: 'checkbox',
    slotCellName: 'checkbox',
    slotName: 'checkboxHeader'
  },
  {
    title: 'common.table.applicant-id',
    field: 'id',
    slotCellName: 'iin-number',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.full_name',
    field: 'full_name',
    slotCellName: 'full_name',
    sortable: false,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.entrance-year',
    field: 'entrance_year',
    slotCellName: 'entrance_year',
    sortable: true,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    slotCellName: 'speciality',
    sortable: false
  },
  {
    title: 'common.table.language_level',
    field: 'language_level',
    slotCellName: 'language_level',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    cellClass: 'text-center'
  }
]

export const selectedDataTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.student-card-id',
    field: 'id',
    slotCellName: 'id-number',
    sortable: true
  },
  {
    title: 'common.table.student-name-surname',
    field: 'full_name',
    slotCellName: 'full_name',
    sortable: true
  }
]
