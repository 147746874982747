import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { WithButton } from '../types'
import { FormGroup, FormField } from '@/shared/types'
import { useManageCourses } from '@/domains/AcademicCourses/store'

export function useManageDescriptionFormComposable () {
  const store = useManageCourses()

  const tempValues = computed(() => ({
    desctiption_en: null || store.manage_course_request.desctiption_en,
    desctiption_ru: null || store.manage_course_request.desctiption_ru,
    desctiption_kz: null || store.manage_course_request.desctiption_kz
  }))

  const { t } = useI18n()
  const commonGroupClass = 'row mb-3'

  const fields = computed(():Array<FormGroup> => [
    {
      class: commonGroupClass,
      fields: [
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.description_en'),
            class: 'required'
          },
          name: 'description_en',
          type: 'textarea',
          value: tempValues.value.desctiption_en,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.description_en') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.description_ru'),
            class: 'required'
          },
          name: 'description_ru',
          type: 'textarea',
          value: tempValues.value.desctiption_ru,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.description_ru') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-4'
          },
          label: {
            text: t('common.table.description_kz'),
            class: 'required'
          },
          name: 'description_kz',
          type: 'textarea',
          value: tempValues.value.desctiption_kz,
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('common.table.description_kz') },
              'trim'
            ]
          }
        }
      ]
    }
  ])

  const inputGroupFields = computed(():Array<FormField & WithButton> => [
    {
      label: {
        text: 'AR'
      },
      name: 'ar',
      type: 'text',
      disabled: true,
      group: {
        class: 'col-12 col-md-4'
      },
      value: store.manage_course_request.ar_requisite,
      button: {
        text: '+',
        class: 'btn btn-primary'
      }
    },
    {
      label: {
        text: 'CR'
      },
      name: 'cr',
      type: 'text',
      disabled: true,
      group: {
        class: 'col-12 col-md-4'
      },
      value: store.manage_course_request.cr_requisite,
      button: {
        text: '+',
        class: 'btn btn-primary'
      }
    },
    {
      label: {
        text: 'PR'
      },
      name: 'pr',
      type: 'text',
      disabled: true,
      value: store.manage_course_request.pr_requisite,
      group: {
        class: 'col-12 col-md-4'
      },
      button: {
        text: '+',
        class: 'btn btn-primary'
      }
    }
  ])
  return {
    fields,
    inputGroupFields
  }
}
