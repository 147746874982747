<template>
  <input
    v-show="isInputShown"
    :checked="isSessionActive"
    type="checkbox"
    class="form-check-input cursor-pointer"
    :disabled="editor.status==='set-multiple'&&editor.started"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-html="true"
    :data-bs-original-title="session.room.title"
    @change="setMultipleAttendance(props.session.id, isSessionActive? 'remove' : 'add')"
  >
  <!-- lock icon -->
  <session-set-attendance-multiple-active
    v-if="editor.status === 'set-multiple' && editor.started"
    :session-id="session.id"
  />
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'

import { Session } from '../types'
import { useAttendanceEditor } from '../composable'

import SessionSetAttendanceMultipleActive from './headers/SessionSetAttendanceMultipleActive.vue'

const {
  editor,
  setMultipleAttendance
} = useAttendanceEditor()

const props = defineProps({
  session: {
    type: Object as PropType<Session>,
    required: true
  }
})

const isInputShown = computed(() => (
  (editor.value.status == null) ||
  (editor.value.status === 'set-multiple' && !editor.value.started) ||
  ((editor.value.sessions as number[]).includes(props.session.id) && editor.value.status === 'set-multiple' && editor.value.started)
))

const isSessionActive = computed(() => (Array.isArray(editor.value?.sessions) ? editor.value?.sessions : []).includes(props.session.id))
</script>
