<template>
  <pms-row>
    <pms-grid :col="9">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'list'"
        :filter="true"
        :full-width="true"
      />
      <EditorSyllabusModule v-else />
    </pms-grid>
    <pms-grid :col="3">
      <EditorSyllabusContentColumn />
    </pms-grid>
  </pms-row>
  <pms-teleport-modal
    :header="false"
    :name="'syllabus-form-content-modal'"
    :teleport-name="'syllabus-form-content'"
    :keyboard="false"
    :backdrop="false"
    :options="{
      header: {class: 'border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  >
    <template #header>
      <span class="fw-bold text-gray-800 fs-5 m-0 p-0">
        {{ $t(`domains.editor.${modifier?.name}.${modifier?.translate?.title || 'title'}`) }}
      </span>
      <span class="text-muted m-0 p-0">
        {{ $t(`domains.editor.${modifier?.name}.${modifier?.translate?.description || 'description'}`) }}
      </span>
    </template>
  </pms-teleport-modal>
</template>

<script setup lang="ts">
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { useStore } from './store'
import { onUnmounted, provide } from 'vue'

import EditorSyllabusModule from './ui/EditorSyllabusModule.vue'
import EditorSyllabusContentColumn from './ui/EditorSyllabusContentColumn.vue'
import bus from './bus'
import { useI18n } from 'vue-i18n'
const { modifier, close, unsetModifier } = useTeleportModal()
const { error, success } = useAlert()
const { t } = useI18n()

const store = useStore()
const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus')

provide('EditorSyllabus', {
  bus,
  domainStore: store
})

bus.on('employeeShowId', checkEmployeePresence)

onUnmounted(() => {
  bus.off('employeeShowId', checkEmployeePresence)
})

async function checkEmployeePresence (event_id: number) : Promise<void> {
  const validation = !!store.getInstructors.find((instructor) => instructor.id === event_id)
  if (!validation) {
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
    bus.emit('resetStaffStore')
    const { message } = await store.addInstructor({ instructor_id: event_id })
    success({ text: message, isToast: true })
  } else {
    error({ text: t('domains.academic.editor.syllabus.instructor.validation-fail') })
  }
}
</script>
