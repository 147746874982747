import { SmartTableHeader } from '@/shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.title',
    field: 'title',
    cellClass: 'fw-bold'
  },
  {
    title: 'common.table.start-date',
    field: 'start_date',
    slotCellName: 'start-date',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.end-date',
    field: 'end_date',
    slotCellName: 'end-date',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.publish',
    field: 'publish',
    cellClass: 'text-center',
    slotCellName: 'publish',
    headerClass: 'text-center'
  },
  {
    title: 'common.table.action',
    field: 'action',
    cellClass: 'text-center',
    slotCellName: 'action',
    headerClass: 'text-center'
  }
]
