import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

const TogglerComponent = defineComponent({
  name: 'TogglerComponent',
  props: {
    toggler: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:toggler'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const changeToggler = () => {
      emit('update:toggler', !props.toggler)
    }

    return () => (
            <button
                class="btn btn-sm btn-light-primary pe-3 ps-3"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-dismiss-="click"
                data-bs-original-title={t(`phrases.toggler-${props.toggler ? 'hide' : 'show'}`)}
                onClick={changeToggler}
            >
        <span class="btn-label">
          <pms-kt-icon
              icon-name={props.toggler ? 'burger-menu-4' : 'burger-menu-3'}
              class="fw-semibold fs-2 p-0"
          />
        </span>
            </button>
    )
  }
})

export default TogglerComponent
