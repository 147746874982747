<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <pms-card
    :title="$t('domains.profile.overview.activities.title')"
    :description="$t('domains.profile.overview.activities.description')"
    :options="{body: {class: 'pt-0'}}"
  >
    <div class="scroll h-400px px-5">
      <pms-smart-list
        :items="activities"
      >
        <template #item="{item}">
          <div class="d-flex align-items-center mb-3">
            <span
              data-kt-element="bullet"
              class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
            />
            <div class="flex-grow-1 me-5">
              <div class="text-gray-800 fw-semibold fs-5">
                {{ item.created_at }}
              </div>
              <div class="text-gray-700 fw-semibold fs-6">
                {{ item.title }}
              </div>
            </div>
          </div>
        </template>
      </pms-smart-list>
      <div
        v-if="activities.length === 0"
        class="text-center mt-14 mb-14"
      >
        {{ $t('common.no-data') }}
      </div>
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useProfile } from '../../../store'

const store = useProfile()

const activities = computed(() => store.getOverviewLogs)

</script>
