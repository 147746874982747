import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import type { KnowledgeBaseSingleResponse } from '@shared/swagger'

import { getKnowledgeBaseSingle } from '@shared/api'
import { active } from '../../values'

function findFirstActiveInContents (contents: KnowledgeBaseSingleResponse['contents']) {
  const indexContent = contents.findIndex((content) => content.active)
  if (indexContent !== -1) {
    return contents[indexContent].uuid
  }
  return null
}

export default async function fetchKnowledgeBaseSingle (this: StoreType, uuid: string): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getKnowledgeBaseSingle(uuid)

  if (ok) {
    this.setSingleKnowledgeBase(data)
    active.value = findFirstActiveInContents(data.contents) || ''
  }

  return {
    status: ok,
    message
  }
}
