const hideChecker = (row): boolean => {
  let count = 0
  row.forEach(el => {
    if (el.content === '') {
      count++
    }
  })
  return row.length === count
}

export default hideChecker
