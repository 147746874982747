import type { StoreType } from '../types'

import { getSectionManagementSections } from '@shared/api'
import { SectionManagementSectionsGetRequest } from '@shared/swagger'

export default async function fetchRequestSections (this: StoreType, params: SectionManagementSectionsGetRequest): Promise<void> {
  const { data, ok } = await getSectionManagementSections(params)

  if (ok) {
    this.selectedRequestsSections = data
  } else {
    this.selectedRequestsSections = []
  }
}
