import { useRequests } from '@shared/composable'
import { SingleContent } from '@shared/types'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest } from '@shared/swagger'

type SingleContentResponse = {
  locales: Array<string>;
  file: SingleContent
}

export default async function getContentTranslateByPath (path: string): Promise<AxiosResponse<SingleContentResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, SingleContentResponse>({
    url: `http://localhost:3000/contents/${path}`,
    body: {},
    loader: 'translates'
  })

  return response
}
