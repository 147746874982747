<template>
  <div :style="'color: ' + getCourseColor(course.details)">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { AcademicSingleStudentCurriculumGetResponse } from '@/shared/swagger'
import { getCourseColor } from '../utils'

interface IColoredTextProps {
    course: (AcademicSingleStudentCurriculumGetResponse['groups'][0])['items'][0]
}

const { course } = defineProps<IColoredTextProps>()
</script>
