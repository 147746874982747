<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          class="mt-3"
          :title="$t('knowledge.manage.post.single.title')"
          :description="$t('knowledge.manage.post.single.description')"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex gap-3 justify-content-between mb-2 mt-md-0">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>

    <pms-row v-if="loaderSingle || resourceLoader">
      <pms-page-shimmer
        :shimmer="`card`"
        :filter="true"
        :full-width="true"
      />
    </pms-row>

    <pms-row v-else>
      <pms-grid
        v-if="managePost"
        :col="8"
      >
        <pms-card
          id="knowledge-manage-post-single"
          :header="false"
        >
          <single-post-component
            :post-with-uuid="managePost"
          />
        </pms-card>
      </pms-grid>
      <pms-grid :col="4">
        <SearchBar id="search-bar" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useDriver, useLoader, useTour } from '@shared/composable'

import { useKnowledgeManagePost } from '../../store'
import SearchBar from './ui/SearchBar.vue'
import SinglePostComponent from '../../ui/SinglePostComponent.vue'

import { tour, quickTourSteps } from './values'

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const loaderSingle = isActive('knowledge/manage/posts/single')
const resourceLoader = isActive('knowledge/manage/resource')

const store = useKnowledgeManagePost()
const managePost = computed(() => store.getPost)

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTourSteps())
})
</script>
