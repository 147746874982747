<template>
  <div :id="`${addressType}-address`">
    <div class="d-flex justify-content-between align-items-center">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t(`domains.profile.addresses.address-title-${addressType}`) }}
      </span>
      <pms-button
        class="w-50px w-sm-100px"
        :size="'sm'"
        :text="'common.edit'"
        @click="onClickEdit(addressType)"
      />
    </div>
    <pms-card
      :header="false"
      class="mt-4"
    >
      <template v-if="item">
        <table class="table align-center table-row-dashed fs-5 dataTable no-footer">
          <tbody class="fw-bold text-gray-600">
            <tr>
              <td class="text-gray-400 fw-bold w-25">
                {{ $t('domains.profile.addresses.country') }}
              </td>
              <td class="text-dark">
                <template v-if="resourceLoader">
                  <pms-loader
                    :center="false"
                    :size="'sm'"
                    :height="20"
                  />
                </template>
                <template v-else>
                  {{ getResourceTitleById(countries, item.country) }}
                </template>
              </td>
            </tr>
            <tr v-if="item.province">
              <td class="text-gray-400 fw-bold w-25">
                {{ $t('domains.profile.addresses.province') }}
              </td>
              <td class="text-dark">
                <template v-if="resourceLoader">
                  <pms-loader
                    :center="false"
                    :size="'sm'"
                    :height="20"
                  />
                </template>
                <template v-else>
                  {{ getResourceTitleById(provinces, item.province) }}
                </template>
              </td>
            </tr>
            <tr v-if="item.region">
              <td class="text-gray-400 fw-bold w-25">
                {{ $t('domains.profile.addresses.region') }}
              </td>
              <td class="text-dark">
                <template v-if="resourceLoader">
                  <pms-loader
                    :center="false"
                    :size="'sm'"
                    :height="20"
                  />
                </template>
                <template v-else>
                  {{ getResourceTitleById(regions, item.region) }}
                </template>
              </td>
            </tr>
            <tr v-if="item.city">
              <td class="text-gray-400 fw-bold w-25">
                {{ $t('domains.profile.addresses.city') }}
              </td>
              <td class="text-dark">
                <template v-if="resourceLoader">
                  <pms-loader
                    :center="false"
                    :size="'sm'"
                    :height="20"
                  />
                </template>
                <template v-else>
                  {{ getResourceTitleById(cities, item.city) }}
                </template>
              </td>
            </tr>
            <tr>
              <td class="text-gray-400 fw-bold">
                {{ $t('domains.profile.addresses.address-line') }}
              </td>
              <td
                :class="{
                  'text-dark': item.address_line,
                  'text-gray-400': !item.address_line
                }"
              >
                {{ item.address_line || $t('common.no-data') }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <pms-no-data />
      </template>
    </pms-card>
  </div>
  <address-form v-if="modifier && modifier.name===`addresses-${addressType}-modal`" />
</template>
<script setup lang="ts">
import { PropType, computed } from 'vue'

import type { ProfileAddressesGetResponse } from '@shared/swagger'
import { useLoader, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { getResourceTitleById } from '../utils'
import AddressForm from '@domains/Profile/features/Addresses/ui/AddressForm.vue'

defineProps({
  addressType: {
    type: String,
    required: true
  },
  item: {
    type: Object as PropType<ProfileAddressesGetResponse>,
    default: null
  }
})

const { isActive } = useLoader()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()

const resourceLoader = isActive('profile/resources')
const cities = computed(() => resource.getProfileResourceCities)
const regions = computed(() => resource.getProfileResourceRegions)
const provinces = computed(() => resource.getProfileResourceProvinces)
const countries = computed(() => resource.getProfileResourceCountries)

const onClickEdit = async (addressType: string) => {
  setModifier(`addresses-${addressType}-modal`,
    { addressType },
    { title: `${addressType}-title`, description: `${addressType}-description` }
  )
  open({ name: 'addresses-modal' })
}
</script>
