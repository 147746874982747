import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  SectionManagementRequestPostRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function postSectionManagementRequest (params: SectionManagementRequestPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<UnknownResponse, UnknownResponse>({
    url: 'academic/manage/sections/requests',
    body: params,
    loader: 'academic/manage/sections/requests-post'
  })

  return response
}
