import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useReceivedRequest = defineStore<Name, State, Getters, Actions>('SectionManagement.features.ReceivedRequests', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      selectedRequestsQuotas: [],
      selectedRequestsSections: [],
      singleSection: null,
      sectionsCourses: [],
      receivedRequests: [],
      step: 'department',
      selectedRequest: null,
      selectedCourse: null,
      selectedDepartment: null
    }
  }
})
