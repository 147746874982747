import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementResourceGetRequest, SectionManagementResourceGetResponse
} from '@shared/swagger'

export default async function getSectionManagementResource (params: SectionManagementResourceGetRequest): Promise<AxiosResponse<SectionManagementResourceGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementResourceGetResponse>({
    url: 'academic/manage/sections/resources',
    body: { params: params },
    loader: 'academic/manage/sections/resources'
  })

  return response
}
