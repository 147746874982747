import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout, RouteSignQuery } from '@shared/types'
import { useSign } from '@shared/composable'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { ct, iv, s } = to.query as RouteSignQuery
  const { isValid, provide } = useSign()
  provide({ ct, iv, s })

  if (!isValid.value) {
    next({ name: 'InvalidSignPage' })
    return
  }

  next()
}

const routeResetSign = async () => {
  useSign().reset()
}

export default {
  layout: 'empty',
  path: '/sign',
  beforeEnter: [
    routeResetSign
  ],
  children: [
    {
      path: '',
      name: 'SignPage',
      component: () => import('./SignPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'invalid',
      name: 'InvalidSignPage',
      component: () => import('./InvalidSignPage.vue')
    }
  ]
} as RouteRecordWithLayout
