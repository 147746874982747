<template>
  <pms-card :title="t('domains.academic.curricula.add-elective-slot-form')">
    <pms-loader v-if="loader" />
    <div v-else>
      <pms-smart-form
        class="d-flex flex-wrap"
        :fields="[ formFields]"
        @handleChange="onHandleChange"
        @handleSubmit="onSubmit"
      />
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from '../../../store'
import { useQueryHelper } from '../composable/useQueryHelper'
import useElectiveSlotForm from '../composable/useElectiveSlotForm'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'

const { formFields, onHandleChange, onSubmit } = useElectiveSlotForm()

const { isActive } = useLoader()

const store = useStore()
const { t } = useI18n()
const loader = isActive('academic/manage/curriculum/resources')
const { params } = useQueryHelper()

onMounted(() => {
  if (params.trackId) {
    store.findTrack({ track: params.trackId })
  }
})

</script>
<style scope>
.formItem {
  width: 200px
}
.separator {
  width: 100%
}
</style>
