export default (formFields, values) => {
  if (!formFields) return []

  for (const fields of formFields) {
    for (const subFields of fields) {
      subFields.value = values[subFields.name] || null
    }
  }

  return formFields
}
