
import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicManageCurriculumElectiveGetResponse, AcademicManageCurriculumElectiveGetRequest } from '@shared/swagger'

export default async function fetchCurriculumElectiveSlot (params: AcademicManageCurriculumElectiveGetRequest): Promise<AxiosResponse<AcademicManageCurriculumElectiveGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicManageCurriculumElectiveGetResponse>({
    url: `academic/manage/curriculum/electives/${params.id}`,
    body: {},
    loader: 'api-fetchCurriculumElectiveSlot'
  })

  return response
}
