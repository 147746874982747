<template>
  <div id="syllabus-information">
    <pms-tab
      v-model:active="activeTab"
      :tabs-id="'syllabus-tabs'"
      :tabs="tabs"
      :all="false"
      :icon="true"
      :translatable="false"
      class="mw-100"
    >
      <template
        v-for="(tabEl, index) in tabs"
        :key="index"
        #[`${tabEl.id}-title`]="{tab, onChange}"
      >
        <pms-button
          class="fw-bold py-2 px-5 m-1 rounded-pill"
          :color="tab.id == activeTab ? 'primary' : 'secondary'"
          @click="onChange(tab.id)"
        >
          <i
            v-if="tab.icon"
            class="bi pb-1"
            :class="tab.icon"
          />
          {{ getMessage(tab.label) }}
        </pms-button>
      </template>
      <template #policy>
        <syllabus-policy />
      </template>
      <template #advanced>
        <syllabus-advanced />
      </template>
      <template #content>
        <syllabus-content />
      </template>
      <template #information>
        <syllabus-information />
      </template>
    </pms-tab>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { syllabusTabs as tabs } from '../values'
import { getMessage } from '../utils'

import SyllabusPolicy from './SyllabusPolicy.vue'
import SyllabusAdvanced from './SyllabusAdvanced.vue'
import SyllabusContent from './SyllabusContent.vue'
import SyllabusInformation from './SyllabusInformation.vue'

const activeTab = ref<string>('information')

</script>

<style scoped lang="scss">

</style>
