<template>
  <pms-search
    v-model:value="keyword"
    class="h-40px w-250px border border-secondary rounded"
    :loader="loader"
    @handle-search="onSearch"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useLoader } from '@shared/composable'
import { setTempValues, tempValues } from '../values'

const { isActive } = useLoader()

const keyword = ref<string>(tempValues.value.keyword || '')

const loader = isActive('academic/students/my')

const onSearch = async () => {
  setTempValues({ keyword: keyword.value })
}
</script>
