<template>
  <div
    id="profile-children"
    class="col-12"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.autobiography.children.title') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="children"
        :headers="tableHeaders"
        :sortable="true"
      >
        <template #fullName="{item}">
          <span class="text-gray-800 fw-bold text-hover-primary mb-1 fs-5">
            {{ item.name }} {{ item.surname }}
          </span>
        </template>
        <template #birthDateGender="{item}">
          <div class="d-flex justify-content-start flex-column">
            <span class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
              {{ dateFormatter(item.birthdate) }}
            </span>
            <span class="text-gray-400 fw-semibold d-block fs-7">
              {{ getGenderById(item.gender) }}
            </span>
          </div>
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, `${item.name} ${item.surname}`)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <ChildrenForm v-if="modifier && modifier.name==='children'" />
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import { dateFormatter } from '@shared/utils'

import { useProfile } from '../../../store'
import { tableHeaders } from '../values'

import ChildrenForm from './ChildrenForm.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const genders = computed(() => resource.getProfileResource?.genders || [])
const children = computed(() => store.getChildren)

const setIdAction = (childId: number) => {
  setModifier('children',
    { id: childId, action: childId === -1 ? 'new' : 'edit' },
    { title: childId === -1 ? 'children-new-title' : 'children-edit-title', description: childId === -1 ? 'children-new-description' : 'children-edit-description' }
  )
  open({ name: 'biography-modal' })
}

const deleteAction = async (childId: number, title: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.autobiography.children.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteChildren(childId)
    }
  })
}

const getGenderById = (id: string|number) => {
  return genders.value.find(g => g.id === id)?.gender
}
</script>
