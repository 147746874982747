<template>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div class="text-dark fw-bold min-w-75px">
      {{ $t('domains.service.message-service.new-message.employees.title') }}:
    </div>
    <input
      v-model="newEmployee"
      type="text"
      class="form-control form-control-transparent border-0 focus-border-0"
      :placeholder="$t('domains.service.message-service.new-message.employees.placeholder')"
      @keyup.space="onNewEmployee()"
      @change="onNewEmployee()"
    >
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAlert } from '@shared/composable'
import { useMessageBroadcastCompose } from '../store'

const { selectedEmployee } = storeToRefs(useMessageBroadcastCompose())

const newEmployee = ref('')

const alert = useAlert()

const isInvalidEmployee = (employee: string) => {
  return !employee.includes('.') || (employee.match(/\./g) || '').length > 1 || (employee[0] === '.' || employee[employee.length - 1] === '.') || selectedEmployee.value?.includes(employee)
}

function onNewEmployee () {
  if (isInvalidEmployee(newEmployee.value)) {
    alert.error({
      text: 'domains.service.message-service.new-message.errors.invalid-employee',
      textProperties: { employee: newEmployee.value }
    })
    return
  }

  selectedEmployee.value.push(newEmployee.value)
  newEmployee.value = ''
}

</script>
<style scoped>
.form-control:focus {
  box-shadow: none;
}
</style>
