import { createPage } from '@shared-utils/storybook'

import SignIn from './../SignInPage.vue'

const Template = createPage({
  layout: 'auth',
  component: SignIn
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Authentication/SignIn',
  component: SignIn,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
