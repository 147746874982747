<template>
  <div>
    <pms-smart-form
      :fields="formFields"
      :options="formOptions"
      :custom-validator="customValidatorForContent"
      @handle-change="onChange"
      @handle-submit="onSubmit"
    >
      <template #formControls>
        <div class="mt-10 d-flex justify-content-between">
          <pms-button
            :disabled="store.isLoading"
            :text="'common.previous'"
            size="lg"
            @click="previousStep"
          />
          <pms-button
            type="submit"
            size="lg"
            :text="$t('common.next')"
          />
        </div>
      </template>
    </pms-smart-form>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert } from '@shared/composable'
import { useResource } from '@shared/stores'
import type { FormGroup } from '@shared/types'

import { useKnowledgeManagePost } from '../../../store'

import { customValidatorForContent, knowledgeManageResourcesTypesToOptions } from '../utils'
import { newPost, formOptions } from '../values'

const { t } = useI18n()
const { error } = useAlert()

const resource = useResource()
const store = useKnowledgeManagePost()

function onChange ({ name, newValue }) {
  newPost.value.content[name] = newValue
}

const typeResources = computed(() => resource.getKnowledgeManageResourceTypes)

const formFields = computed<Array<FormGroup>>(() => {
  let result: Array<FormGroup> = [
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.version')}`
      },
      fields: [
        {
          name: 'version',
          type: 'text',
          value: newPost.value.content.version,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.type')}`
      },
      fields: [
        {
          type: 'select',
          name: 'type_id',
          options: knowledgeManageResourcesTypesToOptions(typeResources.value),
          value: Number(newPost.value.content.type_id) || null,
          validator: {
            schema: 'yup',
            rules: [
              'number',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.content')}`
      },
      fields: [
        {
          type: 'text',
          name: 'content',
          value: newPost.value.content.content,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.alert-type')}`
      },
      fields: [
        {
          type: 'select',
          name: 'alert_type',
          options: [
            {
              label: 'Success',
              value: 'Success'
            },
            {
              label: 'Warning',
              value: 'Warning'
            },
            {
              label: 'Error',
              value: 'Error'
            }
          ],
          value: newPost.value.content.alert_type || null,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.alert-text')}`
      },
      fields: [
        {
          name: 'alert_text',
          type: 'text',
          value: newPost.value.content.alert_text,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    }
  ]
  if ((Number(newPost.value.content.type_id) === 2) || (Number(newPost.value.content.type_id) === 3)) {
    result = [
      ...result,

      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.image')}`
        },
        fields: [
          {
            type: 'text',
            name: 'image',
            value: newPost.value.content.image,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      }
    ]
  }
  if (Number(newPost.value.content.type_id) === 3) {
    result = [
      ...result,
      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.video')}`
        },
        fields: [
          {
            type: 'text',
            name: 'video',
            value: newPost.value.content.video,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      },
      {
        label: {
          class: 'fw-semibold fs-5',
          text: `${t('common.fields.video_type')}`
        },
        fields: [
          {
            type: 'text',
            name: 'video_type',
            value: newPost.value.content.video_type,
            validator: {
              schema: 'yup',
              rules: [
                'string',
                'required'
              ]
            }
          }
        ]
      }
    ]
  }
  return result
})

function previousStep () {
  store.setStepper(1)
}

function onSubmit ({ valid }) {
  if (valid === false) {
    error({ text: t('common.fields.required.not-filled') })
    return
  }
  store.setStepper(3)
}
</script>
