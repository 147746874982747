import type { StoreType } from '../types'
import { getScheduleManagementPrograms } from '@shared/api'

export default async function fetchPrograms (this: StoreType): Promise<void> {
  const { data, ok } = await getScheduleManagementPrograms()

  if (ok) {
    this.setPrograms(data)
  }
}
