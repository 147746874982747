import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusAcademicSkillsPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusAcademicSkill (body: AcademicManageSyllabusAcademicSkillsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageSyllabusAcademicSkillsPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/academic-skills',
    body,
    loader: 'academic/manage/syllabus/academic-skills'
  })
  return response
}
