import { getCurrentInstance, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import StoryBookLayout from '@shared-ui/layouts/empty.layout'
import DefaultLayout from '@shared-ui/layouts/storybook-default.layout'
import AuthLayout from '@shared-ui/layouts/storybook-auth.layout'

export const createPrototype = () => {
  return (args, { globals: { theme } }) => ({
    template: `
      <router-view v-bind="args" />
      <div
        class="badge badge-primary"
        style="position: fixed; bottom: 20px; left: 20px; z-index: 9999;"
      >
        <span
          class="fw-bold"
        >
          Current path:
        </span>&nbsp;&nbsp; {{currentPath }} ({{ currentPage }})
      </div>
    `,
    setup () {
      const vm = getCurrentInstance()
      const router = useRouter()
      const route = useRoute()

      const currentPath = computed(() => route.path)
      const currentPage = computed(() => route.name)

      vm?.appContext.config.globalProperties.$themeMode(theme.toLowerCase())

      const currentPageInLocalStorage = window.localStorage.getItem('storybook_current_page') || '/'

      router.push({
        path: currentPageInLocalStorage
      })

      watch(
        () => route.path,
        (neePath) => {
          window.localStorage.setItem('storybook_current_page', neePath)
        }
      )

      return {
        args,
        currentPath,
        currentPage
      }
    }
  })
}

type UiComponent = {
  template?: string;
  setup?: () => Record<string, unknown>
  components?: Record<string, unknown>
}

export const createUi = (component: UiComponent) => {
  const template = component.template || 'Please, provide template for UI component'
  const components = component.components || {}

  return (args, { globals: { theme } }) => ({
    components,
    template,
    setup () {
      const vm = getCurrentInstance()
      vm?.appContext.config.globalProperties.$themeMode(theme.toLowerCase())

      const setup = component.setup || (() => ({}))

      const setupProperties = setup()
      return {
        args,
        ...setupProperties
      }
    }
  })
}

type PageComponent = {
  layout: string,
  component: unknown,
  setup (): Record<string, unknown>
}

const layoutMap = {
  storybook: StoryBookLayout,
  default: DefaultLayout,
  auth: AuthLayout
}

export const createPage = (component: PageComponent) => {
  const layout = component.layout || 'storybook'
  const pageComponent = component.component || {}

  return (args, { globals: { theme } }) => ({
    components: {
      CurrentPage: pageComponent,
      CurrentLayout: layoutMap[layout]
    },
    template: `
      <current-layout>
        <current-page v-bind="args" />
      </current-layout>
    `,
    setup () {
      const vm = getCurrentInstance()
      vm?.appContext.config.globalProperties.$themeMode(theme.toLowerCase())

      const setup = component.setup || (() => ({}))
      const setupProperties = setup()
      return {
        args,
        ...setupProperties
      }
    }
  })
}
