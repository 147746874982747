import { getRulesAndRegulationsLanguages } from '@/shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'

export default async function fetchRuleLanguages (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getRulesAndRegulationsLanguages()

  ok && this.setLanguages(data)
  return {
    status: ok,
    message
  }
}
