import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { destroyKnowledgeManagePostsContents } from '@/shared/api'
import { KnowledgeManagePostsContentsDeleteRequest } from '@/shared/swagger'

export default async function deleteContent (this: StoreType, uuid: string, values: KnowledgeManagePostsContentsDeleteRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyKnowledgeManagePostsContents(uuid, values)

  return {
    status: ok,
    message: message
  }
}
