import { AdministrativeStaffsWorkloadResourcesResponse } from '@/shared/swagger'
import { OrganizedDepartmentsByFaculties } from '../types'

export default (departments: AdministrativeStaffsWorkloadResourcesResponse['departments']) : OrganizedDepartmentsByFaculties[] => {
  const departmentByFaculties : OrganizedDepartmentsByFaculties[] = []
  const faculties = getAllFaculties(departments)
  faculties.forEach(faculty => {
    departmentByFaculties.push({ id: faculty, label: faculty, key: faculty, children: getDepartments(faculty, departments) })
  })
  return departmentByFaculties.map(faculty => ({ ...faculty, disabled: faculty.children && faculty.children.length }))
}

const getDepartments = (faculty : string, departments: AdministrativeStaffsWorkloadResourcesResponse['departments']) : OrganizedDepartmentsByFaculties[] => {
  const childDep : OrganizedDepartmentsByFaculties[] = []
  departments?.forEach(dep => {
    if (dep.faculty === faculty) {
      childDep.push({ id: dep.department_code, label: dep.title, key: dep.department_code })
    }
  })
  return childDep
}

const getAllFaculties = (departments: AdministrativeStaffsWorkloadResourcesResponse['departments']): string[] => {
  const faculties: string[] = []
  departments.forEach(dep => {
    if (!faculties.includes(dep.faculty)) {
      faculties.push(dep.faculty)
    }
  })
  return faculties
}
