<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <pms-card
    v-if="locsState.students.value !== null"
    :header="false"
  >
    <action-buttons
      v-if="selectedStudents.length > 0"
      :students="selectedStudents"
    />
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="all-students-table"
      :items="students"
      :headers="allStudentsTable"
      :sortable="true"
      :options="{
        wrapper: {
          responsive: true
        }
      }"
    >
      <template #checkboxHeader>
        <pms-checkbox
          class="d-flex justify-content-center"
          :variant="themeMode == 'dark' ? 'solid' : 'default'"
          :disabled="students.length === 0"
          @on-check="handleSelectAll"
          @on-uncheck="handleUnselectAll"
        />
      </template>
      <template #checkbox="{ row }">
        <pms-checkbox
          class="d-flex justify-content-center"
          :state="isSelected(row)"
          @on-check="handleSelect(row)"
          @on-uncheck="handleUnselect(row)"
        />
      </template>
      <template #id-number="{ item }">
        {{ item.id }}
      </template>
      <template #id="{ item }">
        <div>
          {{ item.name }} {{ item.surname }}
        </div>
      </template>
      <template #start-date="{item}">
        <div>
          {{ item.start_date }}
        </div>
      </template>
      <template #end-date="{item}">
        <div>
          {{ item.end_date }}
        </div>
      </template>
      <template #locked-by="{item}">
        <div>
          {{ item.requestedBy }}
        </div>
      </template>
      <template #status="{ item }">
        <div
          v-if="item.is_active"
          :title="'Lock'"
        >
          <pms-kt-icon
            :icon-name="'lock'"
            class="fs-4 text-danger opacity-75"
          />
        </div>
        <div
          v-else
        >
          <pms-kt-icon
            :icon-name="'lock'"
            class="fs-4 text-success opacity-75"
          />
        </div>
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              v-if="item.is_active"
              ref="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="unlockStudents(item)"
            >
              {{ $t('common.unlock') }}
            </a>
            <a
              v-if="!(item.is_active)"
              class="menu-link px-3 text-left"
              @click="toggleLockAction(item)"
            >
              {{ $t('common.lock') }}
            </a>
            <a
              v-if="item.is_active"
              class="menu-link px-3 text-left"
              @click="editLockAction(item)"
            >
              {{ $t('common.edit-period') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
    </pms-smart-table>
    <LockModal v-if="modifier" />
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
  <pms-lock-modal />
</template>
<script setup lang="ts">
import { useLoader, useTeleportModal, useAlert } from '@/shared/composable'
import { useAllStudents } from './../store'
import { selectedStudents, allStudentsTable, FilteredStudent, lockIdsForModal } from './../values/'
import { themeMode } from '@/shared/composable/pattern'
import { computed } from 'vue'
import ActionButtons from './ActionButtons.vue'
import { search, resFilter } from '../utils'
import LockModal from './LockModal.vue'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const { success, error } = useAlert()
const store = useAllStudents()
const locsState = storeToRefs(store)
const loader = isActive('service/manage/locks')
const { modifier, setModifier, open } = useTeleportModal()

const response = locsState.students
const students = computed(() => resFilter(response.value ? response.value : []))

const toggleLockAction = (item: FilteredStudent) => {
  lockIdsForModal.value = []
  setModifier('locks-modal',
    { lock: [item.lock_id], action: 'toggle' },
    { title: 'common.lock-students', description: 'common.toggle-students' }
  )
  open({ name: 'locks-modal' })
}

const editLockAction = (item: FilteredStudent) => {
  lockIdsForModal.value = []
  setModifier('locks-modal',
    { lock: [item.lock_id], action: 'edit' },
    { title: 'common.edit-locks', description: 'common.edit-students' }
  )
  open({ name: 'locks-modal' })
}

const handleSelect = (student: FilteredStudent) => {
  selectedStudents.value.push(student)
}

const unlockStudents = async (item : FilteredStudent) => {
  const values = { locks: [] as number[], is_active: true }
  if (item) {
    values.locks.push(item.lock_id)
    values.is_active = false
  }

  const { status, message } = await store.unlock(values)

  if (status) {
    success({ text: message, isToast: true })
    search()
  } else {
    error({ text: message })
  }
}

const handleUnselect = (student: FilteredStudent) => {
  selectedStudents.value = selectedStudents.value.filter((s) => s.lock_id !== student.lock_id)
}

const handleSelectAll = () => {
  selectedStudents.value = students.value ? students.value : []
}

const handleUnselectAll = () => {
  selectedStudents.value = []
}

const isSelected = (student: FilteredStudent) => {
  return selectedStudents.value.some((s) => s.lock_id === student.lock_id)
}

</script>

  <style scoped>
  .student-tooltip {
    position: absolute;
    z-index: 999;
    display: none;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
    width: 200px;
    pointer-events: none;

    .student-image {
      width: 100%;
      height: 100%;
      min-height: 250px;
      object-fit: cover;
    }
  }
  </style>
