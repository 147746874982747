<template>
  <div
    v-if="yearTerms.length > 0"
    class="d-flex align-items-center gap-2 mt-2 mt-md-0"
  >
    <p class="fs-5 m-0">
      {{ $t('common.year-terms') }}:
    </p>

    <div class="d-flex flex-row">
      <select
        class="form-select form-select w-175px"
        @change="setYearTerm($event)"
      >
        <option
          v-for="yearTerm in yearTerms"
          :key="(yearTerm.year + '' + yearTerm.term)"
          :value="yearTerm.year + '-' + yearTerm.term"
          :selected="(yearTerm.year === currentYearTerm.year && yearTerm.term === currentYearTerm.term)"
        >
          {{ getYearTermLabel(yearTerm, t) }}
        </option>
      </select>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useResource } from '@shared/stores'
import { usePagination } from '@shared/composable'
import { useConsentRequest } from '../store'
import { YearTerm } from '../values'
import { getYearTermLabel } from '@/shared/utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { perPage } = usePagination()
const store = useConsentRequest()
const resource = useResource()

const yearTerms = computed(() => resource.getAcademicCoursesResourceMyYearTerms)
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const setInitialYearTerm = () => {
  const firstYearTerm = yearTerms.value[0]
  if (firstYearTerm) {
    YearTerm.value.year = firstYearTerm.year
    YearTerm.value.term = firstYearTerm.term
    YearTerm.value.per_page = perPage.value
    YearTerm.value.offset = 1
    store.setCurrentYearTerm({ year: firstYearTerm.year, term: firstYearTerm.term })
    store.fetchConsentRequests(YearTerm.value)
  }
}

const setYearTerm = ($event) => {
  const [year, term] = $event.target.value.split('-')
  YearTerm.value.year = year.value
  YearTerm.value.term = term.value
  YearTerm.value.per_page = perPage.value
  YearTerm.value.offset = 1
  store.setCurrentYearTerm({ year, term })
  store.fetchConsentRequests(YearTerm.value)
}

onMounted(setInitialYearTerm)
</script>
