import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { ResetPasswordRequest, ResetPasswordResponse } from '@shared/swagger'

export default async function postUserResetPassword (body: ResetPasswordRequest): Promise<AxiosResponse<ResetPasswordResponse>> {
  const { post } = useRequests()

  const response = post<ResetPasswordRequest, ResetPasswordResponse>({
    url: 'reset-password',
    body,
    configs: { token: false },
    loader: '/1.0.0/reset-password'
  })
  return response
}
