import { PropType, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { isTranslatable } from '@shared/utils'
import { BreadcrumbElement } from '@shared/types'

const BreadcrumbsComponent = defineComponent({
  name: 'BreadcrumbsComponent',
  props: {
    breadcrumbs: {
      type: Array as PropType<Array<BreadcrumbElement>>,
      required: true
    },
    mode: {
      type: String as PropType<'default' | 'line' | 'dot'>,
      required: false,
      default: 'default'
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    },
    itemClass: {
      type: String,
      required: false,
      default: ''
    },
    itemActiveClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:breadcrumbs'],
  setup: function (props, { slots }) {
    const { t } = useI18n()

    const onClickAction = (breadcrumb: BreadcrumbElement) => {
      if (breadcrumb.action) {
        breadcrumb.action()
      }
    }

    return () => (
      <ol class={
        `breadcrumb breadcrumb-${props.mode} text-muted fw-semibold ${props.containerClass}`
      }>
        {
          props.breadcrumbs.map((breadcrumb) => (
            <li
              class={`breadcrumb-item ${props.itemClass}`}
            >
              {
                breadcrumb.action && (
                  <a
                    href="javascript:void(0)"
                    class={props.itemActiveClass}
                    style="padding-right: 6px;"
                    onClick={() => onClickAction(breadcrumb)}
                  >
                    {breadcrumb.slot ? slots[breadcrumb.slot]?.() : isTranslatable(breadcrumb.label) ? t(breadcrumb.label) : breadcrumb.label}
                  </a>
                )
              }
              {
                !breadcrumb.action && (
                  <span
                    style="padding-right: 6px;"
                  >
                    {breadcrumb.slot ? slots[breadcrumb.slot]?.() : isTranslatable(breadcrumb.label) ? t(breadcrumb.label) : breadcrumb.label}
                  </span>
                )
              }
            </li>
          ))
        }
      </ol>
    )
  },
  watch: {
    breadcrumbs: {
      handler (newValue: Array<BreadcrumbElement>) {
        this.$emit('update:breadcrumbs', newValue)
      },
      immediate: true
    }
  }
})

export default BreadcrumbsComponent
