import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ScheduleManagementSingleSectionProgramsPostRequest } from '@shared/swagger'

export default async function postScheduleManagementSingleSectionPrograms (section: string, params: ScheduleManagementSingleSectionProgramsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ScheduleManagementSingleSectionProgramsPostRequest, UnknownResponse>({
    url: `academic/manage/schedules/sections/${section}/programs`,
    body: params,
    loader: 'academic/manage/schedules/sections-single/programs-post'
  })

  return response
}
