import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useAlert, usePagination } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useSingleSection } from './store'
import { RouteParams } from './types'
import { sensitivePages } from './values'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSingleSection()
  const pagination = usePagination()
  const params = to.query as unknown as RouteParams

  store.$reset()
  pagination.$reset()

  if (params.year && params.term && params.code) {
    await store.bootstrap(params)
    next()
  } else {
    next({ path: '/academic/sections' })
  }
}

const handlePageRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const params = to.query as unknown as RouteParams
  const store = useSingleSection()
  const resource = useResource()

  const { error } = useAlert()
  const { page } = to.meta as { page: string }

  if (params.year && params.term && params.code) {
    const theSection = resource.getAcademicSingleCoursesResource

    if (theSection && !theSection.enrolled && sensitivePages.includes(page)) {
      error({ title: 'common.section-error', text: 'common.section-not-enrolled-error-message' })
      next({ path: '/academic/courses/section', query: { ...params } })
      return
    }

    store.bootstrapByPage(page, params)
    next()
  } else {
    error({ title: 'common.section-error', text: 'common.Section-general-params-error-message' })
    next({ path: '/academic/courses' })
  }
}

export default {
  layout: 'default',
  path: '/academic/courses/section',
  children: [
    {
      path: '',
      name: 'AcademicSingleSectionPage',
      component: () => import('./SingleSection.vue'),
      beforeEnter: [
        handleRoute
      ],
      children: [
        {
          path: '',
          name: 'SingleSectionStudentsPage',
          component: () => import('@domains/AcademicSingleSection/features/StudentsPage/StudentsPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'students'
          }
        },
        {
          path: 'grades',
          name: 'SingleSectionGradesPage',
          component: () => import('@domains/AcademicSingleSection/features/GradesPage/GradesPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'grades'
          }
        },
        {
          path: 'attendance',
          name: 'SingleSectionAttendancePage',
          component: () => import('./features/Attendance/AttendancePage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'attendance'
          }
        },
        {
          name: 'AttendanceRelocate',
          path: 'attendance/relocate/:id',
          component: () => import('./features/Attendance/features/AttendanceRelocate/AttendanceRelocate.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'attendance-relocate'
          }
        },
        {
          path: 'consent-requests',
          name: 'SingleSectionConsentRequestPage',
          component: () => import('./features/ConsentRequest/ConsentRequestPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'consent-requests'
          }
        },
        {
          path: 'exam-dates',
          name: 'SingleSectionExamDatesPage',
          component: () => import('./features/ExamDates/ExamDatesPage.vue'),
          beforeEnter: [
            handlePageRoute
          ],
          meta: {
            page: 'exam-dates'
          }
        }
      ]
    }
  ]
} as RouteRecordWithLayout
