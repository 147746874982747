import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSectionsResourcesResponse
} from '@shared/swagger'

export default async function getScheduleManagementSectionResources (): Promise<AxiosResponse<ScheduleManagementSectionsResourcesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSectionsResourcesResponse>({
    url: 'academic/manage/schedules/sections/resources',
    body: { },
    loader: 'academic/manage/schedules/sections/resources'
  })

  return response
}
