<template>
  <div
    id="projects"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.experience.projects.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div
        v-for="(item, index) in projects"
        :key="index"
      >
        <div
          v-if="index !== 0"
          class="separator my-5"
        />
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-column mb-2">
            <h3 class="m-0">
              {{ item.title }}
            </h3>
          </div>
          <div class="d-flex align-items-center gap-2 justify-content-end">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.title)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </div>
        <p
          class="text-muted fs-6 m-0"
        >
          <span v-if="getTitleById(item.sponsor, companies) !== ''">
            {{ getTitleById(item.sponsor, companies) }} -
          </span>
          <span v-if="item.position !== ''">
            {{ item.position }}
          </span>
        </p>
        <p class="text-muted fs-6 m-0">
          {{ displayDate(item) }}
        </p>
        <a
          :href="item.project_link"
          class="btn btn-sm my-3 btn-outline btn-outline-primary"
          target="_blank"
        >
          {{ $t('common.learn-more') }}
        </a>
        <p v-html="item.description" />
      </div>
      <pms-no-data v-if="projects.length ===0" />
      <ProjectForm v-if="modifier && modifier.name === 'project'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { getTitleById, displayDate } from '../utils'
import { useProfile } from '../../../store'

import ProjectForm from './ProjectForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const companies = computed(() => resource.getProfileResource?.companies || [])
const projects = computed(() => store.getProjects)

const setIdAction = (id: number) => {
  setModifier('project',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'project-new-title' : 'project-edit-title',
      description: id === -1 ? 'project-new-description' : 'project-edit-description'
    }
  )
  open({ name: 'experience-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.experience.are-you-sure',
    text: t('domains.profile.experience.project.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteProject(id)
    }
  })
}
</script>
