<template>
  <p><strong>{{ t('domains.schedule-management.program') }}: </strong>{{ selectedProgram?.title }}</p>
  <pms-smart-form
    :fields="formFields"
    @handleSubmit="onSubmit"
    @handleChange="onHandleChange"
  >
    <template #formControls>
      <div class="form-group mt-3">
        <pms-button
          type="submit"
          class="mt-2"
          :loading="applyProccessing"
          :disabled="applyProccessing"
          :text="$t('common.apply')"
        />
      </div>
    </template>
  </pms-smart-form>
</template>
<script setup lang="ts">
import { FormGroup } from '@/shared/types'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAlert, useLoader } from '@/shared/composable'
import { Modal } from 'bootstrap'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const { error, success } = useAlert()
const store = useScheduleManagement()
const { isActive } = useLoader()

const { selectedProgram } = storeToRefs(store)
const applyProccessing = isActive('post-academic/manage/schedules/sections/programs/apply')

const tempValues = ref({
  top: null,
  bottom: null
})

const onSubmit = async () => {
  if (!store.singleSection || !selectedProgram.value) return
  const response = await store.applyProgram(store.singleSection.id, selectedProgram.value.id, {
    top: tempValues.value.top ?? undefined,
    bottom: tempValues.value.bottom ?? undefined
  })
  if (response.status) {
    success({
      text: response.message
    })
    tempValues.value = {
      top: null,
      bottom: null
    }
    // Hide modal
    const modalElement = document.getElementById('modal1')
    const myModal = Modal.getInstance(modalElement)
    myModal?.hide()
  } else {
    error({
      text: response.message
    })
  }
}

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row gx-5',
    fields: [
      {
        group: {
          class: 'col-4'
        },
        label: {
          text: `${t('common.schedule-management.program.top')}`,
          class: 'mb-1 mt-2'
        },
        name: 'top',
        type: 'number',
        value: tempValues.value.top || null
      },
      {
        group: {
          class: 'col-4'
        },
        label: {
          text: `${t('common.schedule-management.program.bottom')}`,
          class: 'mb-1 mt-2'
        },
        name: 'bottom',
        type: 'number',
        value: tempValues.value.bottom || null

      }
    ]
  }
])
</script>
