import { PropType, defineComponent } from 'vue'

import { ButtonGroupItem } from '../../types'

const ButtonGroupComponent = defineComponent({
  name: 'ButtonGroupComponent',
  props: {
    variant: {
      type: String as PropType<'default' | 'outlined' | 'outlined-dashed' | 'tonal' | 'link'>,
      required: false,
      default: 'default'
    },
    color: {
      type: String as PropType<'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'>,
      required: false,
      default: 'primary'
    },
    direction: {
      type: String as PropType<'horizontal' | 'vertical'>,
      required: false,
      default: 'horizontal'
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md'
    },
    items: {
      type: Array as PropType<Array<ButtonGroupItem>>,
      required: true
    }
  },
  emits: ['update:toggle'],
  setup: function (props) {
    return () => (
      <div
        role="group"
        class={[
          `btn-group${props.direction === 'vertical' ? '-vertical' : ''}`,
          `${props.size !== 'md' ? `btn-group-${props.size}` : ''}`,
          `${props.variant === 'link' ? 'gap-7' : ''}`
        ]}
      >
        {props.items.map((item) => (
            <>
              {
                item.type === 'button' && (
                  <pms-button
                    variant={props.variant}
                    color={props.color}
                    text={item.text}
                    disabled={item.disabled}
                  />
                )
              }
              {
                item.type === 'dropdown' && (
                  <pms-dropdown
                    btnText={item.text}
                    groupClass="btn-group"
                    btnClass="dropdown-toggle"
                    variant={props.variant}
                    color={props.color}
                    items={item.dropdownItems}
                    placement={item.dropdownContentPlacement}
                    showIcon={item.dropdownShowIcon}
                    iconPlacement={item.iconPlacement}
                    menuClass={item.dropdownContentClass}
                  />
                )
              }
            </>
        ))}
      </div>
    )
  }
})

export default ButtonGroupComponent
