import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { AdministrativeGateEntryResourceUsersResponse, UnknownRequest } from '@shared/swagger'

export default async function getAdministrativeGateEntryResourceUsers (params: UnknownRequest): Promise<AxiosResponse<AdministrativeGateEntryResourceUsersResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AdministrativeGateEntryResourceUsersResponse>({
    url: 'administrative/gate-entry/resources/users',
    body: { params: params },
    loader: 'administrative/gate-entry/resources/users'
  })

  return response
}
