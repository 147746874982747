import { defineStore } from 'pinia'

import * as actions from './actions'
import * as getters from './getters'

import type { Actions, Getters, Name, State } from './types'

export default defineStore<Name, State, Getters, Actions>('shared.useResource', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      academicStudentsResource: null,
      academicManageCourseResource: null,
      academicManageCourseDepartmentsResource: [],
      resource: null,
      academicResource: null,
      administrativeResource: null,
      academicManageCurriculumResource: null,
      knowledgeResource: null,
      knowledgeManageResource: null,
      academicCoursesResource: null,
      profileResource: null,
      syllabusResource: null,
      serviceMessageBroadcastResource: null,
      academicSingleCoursesResource: null,
      academicInformationResource: null,
      administrativeRulesEditorResource: null,
      singleStudentResource: null,
      academicSingleCourseAttendanceResource: null,
      academicSingleCourseAttendanceRelocateResource: null,
      serviceLockResource: null,
      sectionManagementResource: null,
      serviceManageLanguageResource: null,
      gateEntryResource: null,
      gateEntryResourceUsers: null,
      serviceEsignResource: null,
      academicStudentsMyResources: null,
      academicInformationSchedulesResource: null,
      staffsWorkloadResource: null,
      serviceAdvisorAssignmentResource: null,
      academicManageCourseRequisitesResource: null,
      scheduleManagementSectionProgramHours: null
    }
  }
})
