import type { StatusMessageResponse } from '@shared/types'
import type { ProfileEducationBooksPostRequest } from '@shared/swagger'
import { putProfileEducationBooks } from '@shared/api'

import type { StoreType } from '../types'

export default async function editBook (this:StoreType, id: number, values: ProfileEducationBooksPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileEducationBooks(id, values)

  if (ok) await this.fetchBooks()

  return {
    status: ok,
    message: message
  }
}
