import { SelectField } from '@shared/types'
import { AcademicManageSyllabusResourcesResponse } from '@shared/swagger'

export default (data: AcademicManageSyllabusResourcesResponse['assess_types']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((assessment) => {
    options.push({
      label: assessment.title,
      value: assessment.key
    })
  })

  return options
}
