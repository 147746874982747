<template>
  <pms-smart-table
    id="table-reference-courses"
    :headers="headers"
    :items="referenceCourses"
    :sortable="true"
  >
    <template #code="{item}">
      {{ item.code }}
    </template>
    <template #title="{item}">
      {{ item.code }}
    </template>
    <template #actions="{item}">
      <pms-button
        type="button"
        class="btn btn-danger"
        :variant="'outline'"
        @Click="handleOnDelete(item)"
      >
        <i class="bi bi-trash" />
      </pms-button>
    </template>
  </pms-smart-table>
</template>
<script lang="ts" setup>
import { SmartTableHeader } from '@/shared/types'
import { referenceCourses } from '../values'

const handleOnDelete = (item: typeof referenceCourses.value[0]) => {
  referenceCourses.value = [...referenceCourses.value.filter(referenceCourse => referenceCourse.equicode !== item.equicode)]
}

const headers: Array<SmartTableHeader> = [
  {
    title: 'common.code',
    field: 'code',
    slotCellName: 'code',
    cellClass: 'w-50px'
  },
  {
    title: 'common.title',
    field: 'title',
    slotCellName: 'title',
    cellClass: 'w-50px'
  },
  {
    title: 'common.actions',
    field: 'actions',
    slotCellName: 'actions',
    cellClass: 'w-10px'
  }
]
</script>
