<template>
  <div class="d-flex flex-column mt-4 mt-md-0 flex-md-row align-items-md-center gap-1">
    <div
      v-if="myYearTerms.length > 0"
      class="d-flex"
    >
      <ToolbarYearTerms id="year-term-selector" />
      <div class="bullet bg-secondary h-0px h-md-35px w-1px mx-3" />
      <ToolbarFilter id="filter-by" />
      <div class="bullet bg-secondary h-0px h-md-35px w-1px mx-3" />
    </div>
    <div class="d-flex gap-3">
      <pms-driver-button />
      <pms-feedback-modal
        :options="feedback"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ToolbarYearTerms from './ToolbarYearTerms.vue'
import ToolbarFilter from './ToolbarFilter.vue'
import { feedback, quickMyCoursesSetTourSteps } from './../values'
import { useDriver } from '@shared/composable'
import { computed, onMounted } from 'vue'
import { useResource } from '@/shared/stores'

const resource = useResource()
const { setSteps: setQuickTourSteps } = useDriver()

const myYearTerms = computed(() => resource.getAcademicCoursesResourceMyYearTerms)

onMounted(async () => {
  setQuickTourSteps(await quickMyCoursesSetTourSteps())
})
</script>
