import type { StoreType } from '../types'
import { getAcademicManageCurriculumDepartments } from '@shared/api'

export default async function fetchNavigation (this: StoreType, year: number): Promise<void> {
  const { data, ok } = await getAcademicManageCurriculumDepartments({ year })

  if (ok) {
    this.setDepartments(data)
  }
}
