<template>
  <pms-card
    :title="'domains.academic.single-course.overview.title'"
    :description="'domains.academic.single-course.overview.description'"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <pms-row>
      <template
        v-for="(placement, index) in tablePlacement"
        :key="index"
      >
        <pms-grid
          :col="6"
          :col-mb="12"
        >
          <h3
            v-if="false"
            :class="{
              'mb-6': placement.translate === 'details'
            }"
          >
            {{ placement.translate === 'details' ? '' : $t(`domains.academic.single-course.overview.${placement.translate}`) }}
          </h3>
          <div class="table-responsive">
            <table class="table table-bordered table-border-blue">
              <tbody>
                <template
                  v-for="data in placement.tableData"
                  :key="data.field"
                >
                  <tr
                    style="vertical-align: middle;"
                  >
                    <td
                      v-if="!(data.custom && data.custom === 'credit')"
                      class="text-start w-150px"
                    >
                      <span
                        class="text-gray-800 d-block fs-4"
                      >
                        {{ $t(`domains.academic.single-course.overview.${data.translate}`) }}:
                      </span>
                    </td>
                    <td
                      v-else
                      rowspan="4"
                    >
                      <span
                        class="text-gray-800 d-block fs-4"
                      >
                        {{ $t(`domains.academic.single-course.overview.${data.translate}`) }}:
                      </span>
                    </td>

                    <td class="text-start pe-5">
                      <span class="text-gray-800 fw-bold d-block fs-4">
                        <template v-if="data.custom && data.custom === 'courseCode'">
                          {{ overview?.[data.field].code }} ({{ overview?.[data.field].year }})
                        </template>
                        <template v-else-if="data.custom && data.custom === 'department'">
                          [{{ overview?.[data.field].code }}] - {{ overview?.[data.field].title }}
                        </template>
                        <template v-else-if="data.custom && data.custom === 'createDate'">
                          {{ overview?.[data.field].time && dateFormatter(overview?.[data.field].time, timeOptions) }} -
                          {{ overview?.[data.field].date && dateFormatter(overview?.[data.field].date, dateOptions) }}
                        </template>
                        <template v-else-if="data.custom && data.custom === 'language'">
                          {{ overview?.[data.field].code }} - {{ overview?.[data.field].title }}
                        </template>
                        <template v-else-if="data.custom && data.custom === 'credit'">
                          {{ $t(`domains.academic.single-course.overview.theory`) }} - {{ overview?.[data.field]['theory'] }}
                        </template>
                        <template v-else>
                          {{ overview?.[data.field] }}
                        </template>
                      </span>
                    </td>
                  </tr>
                  <template v-if="data.custom && data.custom === 'credit'">
                    <tr
                      v-for="credit in ['practice', 'lab', 'ects']"
                      :key="credit"
                    >
                      <td>
                        <span class="text-gray-800 fw-bold fs-4">
                          {{ $t(`domains.academic.single-course.overview.${credit}`) }} -
                          {{ overview?.[data.field][credit] }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </pms-grid>
      </template>
    </pms-row>
  </pms-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '@domains/AcademicSingleCourse/store'
import { dateFormatter } from '@shared/utils'

import { tablePlacement } from '../values'
import { formatTableData } from '../utils'

const store = useSingleCourse()

const overview = computed(() => formatTableData(store.getOverview))

const dateOptions = {
  inputFormat: 'YYYY-MM-DD',
  outputFormat: 'Do MMMM YYYY'
}

const timeOptions = {
  inputFormat: 'HH:mm:ss',
  outputFormat: 'HH:mm'
}

</script>
