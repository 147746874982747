import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicStudentsResourceRequest, AcademicStudentsResourceResponse
} from '@shared/swagger'

export default async function getAcademicStudentsResource (params: AcademicStudentsResourceRequest): Promise<AxiosResponse<AcademicStudentsResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicStudentsResourceResponse>({
    url: 'academic/students/resources',
    body: { params: params },
    loader: 'academic/students/resources'
  })

  return response
}
