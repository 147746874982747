<template>
  <vue-resizable
    id="quick-links-widget"
    class="resizable-widget card card-flush"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-width="250"
    min-height="150"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-test-id="'links-title'"
          class="card-label fw-bolder text-dark"
        >
          {{ $t('domains.dashboard.widgets.quick-link.title') }}
        </span>
        <span
          v-if="$t('domains.dashboard.widgets.quick-link.description') !== ''"
          v-test-id="'links-description'"
          class="text-muted mt-1 fw-bold fs-7"
        >
          {{ $t('domains.dashboard.widgets.quick-link.description') }}
        </span>
      </h3>
    </div>
    <div class="card-body pt-0">
      <div class="me-2">
        <a
          v-for="(item, index) in formattedLinks"
          :key="index"
          v-test-id="'quick-link'"
          class="text-dark text-hover-primary btn btn-link pb-0 pt-2"
          @click="goto(item.link)"
        >
          <span
            class="d-flex align-items-center fs-4 fw-bolder"
            style="padding-right: 15px;"
          >
            <span class="svg-icon svg-icon-6 svg-icon-primary me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="5"
                  fill="currentColor"
                />
                <path
                  d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            {{ $t(item.title) }}
          </span>
        </a>
      </div>
      <div
        v-if="links.length === 0"
        class="min-h-150px h-150 d-flex justify-content-center align-items-center"
      >
        <div class="text-center">
          <span
            v-test-id="'no-links'"
            class="text-dark fw-bolder fs-4"
          >
            {{ $t('domains.dashboard.widgets.quick-link.no-quick-links') }}
          </span>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import VueResizable from 'vue-resizable'
import { quickLinks } from '../values'
import { QuickLinkWithRoles } from '../types'
import { useDashboard } from '../store'
import { onResize, getSavedWidgetSize } from '../utils'

const router = useRouter()
const store = useDashboard()

const currentRole = computed(() => store.getCurrentRole)
const links = quickLinks()

const formattedLinks: QuickLinkWithRoles[] = links.filter(link => link.role === currentRole.value || link.role === '*')

const goto = (path: string) => {
  router.push({ path: path })
}

const widgetId = 'quick-links-widget'

const widgetSize = ref({ width: 'auto', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
