import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileChildrenPostRequest } from '@shared/swagger'
import { postProfileChildren } from '@shared/api'

import type { StoreType } from '../types'

export default async function addChildren (this: StoreType, values: ProfileChildrenPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileChildren(values)

  return {
    status: ok,
    message
  }
}
