<template>
  <div>
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
      :filter="true"
    />
    <pms-row
      v-else
      class="gap-10"
    >
      <ExperienceCard />
      <ProjectCard />
      <CertificateCard />
    </pms-row>
    <pms-teleport-modal
      :name="'experience-modal'"
      :teleport-name="'experience-modal-content'"
      :title="$t(`domains.profile.experience.${modifier?.translate?.title || 'title'}`)"
      :description="$t(`domains.profile.experience.${modifier?.translate?.description || 'description'}`)"
      :keyboard="false"
      :backdrop="false"
      :options="{
        content: {class: 'rounded'},
        header: {class: 'pb-0 border-0 justify-content-between'},
        body: {class: 'scroll-y pt-0'}
      }"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'

import { useDriver, useLoader, useTeleportModal } from '@shared/composable'

import ExperienceCard from './ui/ExperienceCard.vue'
import ProjectCard from './ui/ProjectCard.vue'
import CertificateCard from './ui/CertificateCard.vue'

import { quickTourSteps } from './values'

const { isActiveOneOf } = useLoader()
const { modifier } = useTeleportModal()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActiveOneOf(['profile/experiences', 'profile/projects', 'profile/certificates'])

onMounted(async () => {
  setQuickTourSteps(await quickTourSteps())
})
</script>
