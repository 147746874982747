import type { StoreType } from '../types'

import { getAdministrativeGateEntryResource } from '@shared/api'
import { AdministrativeGateEntryResourceRequest } from '@shared/swagger'

export default async function fetchAdministrativeGateEntryResource (this: StoreType, dat: AdministrativeGateEntryResourceRequest): Promise<void> {
  const { ok, data } = await getAdministrativeGateEntryResource(dat)
  if (ok) {
    this.setAdministrativeGateEntryResource(data)
  }
}
