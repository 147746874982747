import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicSingleCourseGradeResponse, AcademicSingleCourseGradeRequest } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicInformationSchedule (params: AcademicSingleCourseGradeRequest): Promise<AxiosResponse<AcademicSingleCourseGradeResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseGradeResponse>({
    url: 'academic/courses/grades',
    body: { params },
    loader: 'academic/courses/grades'
  })

  return response
}
