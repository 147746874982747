import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { AcademicStudentsGetRequest, AcademicStudentsGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getAcademicStudentsInformation (params: AcademicStudentsGetRequest): Promise<AxiosResponse<AcademicStudentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicStudentsGetResponse>({
    url: 'academic/students',
    body: { params: params },
    loader: 'academic/students'
  })

  return response
}
