import { RulesWidget } from '../types/rules'
import { DashboardWidgetsResponse } from '@shared/swagger'

const formatRules = (rules: DashboardWidgetsResponse['rules_and_regulations']['data']): Array<RulesWidget> => {
  return rules.map((item) => {
    return {
      uuid: item.id,
      title: item.title,
      description: item.description,
      categoryName: item.category_title,
      attachments: item.attachments.map((att) => {
        return {
          title: att.id,
          language: att.language_name,
          version: att.version,
          path: att.url
        }
      })
    }
  }).filter((item) => item.title !== '') as unknown as Array<RulesWidget>
}

export default formatRules
