<template>
  <PreSelectedRequestBlock v-if="currentRequest === null" />
  <ProgramRequestComponent v-if="currentRequest === 'program'" />
  <SuggestedRequestComponent v-if="currentRequest === 'suggested'" />
  <SearchRequestComponent v-if="currentRequest === 'search'" />
</template>

<script setup lang="ts">
import PreSelectedRequestBlock from './PreSelectedRequestBlock.vue'
import SuggestedRequestComponent from './SuggestedRequestComponent.vue'
import SearchRequestComponent from './SearchRequestComponent.vue'
import ProgramRequestComponent from './ProgramRequestComponent.vue'

import { computed } from 'vue'
import { useNewRequest } from '../store'

const newRequestStore = useNewRequest()
const currentRequest = computed(() => newRequestStore.requestType)
</script>
