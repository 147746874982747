<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div class="w-100 position-relative mb-5">
        <div
          :key="'content.course-learning-outcomes'"
          class="fs-2 fw-bold text-danger text-center flex-fill"
        >
          {{ getMessage('advanced.reading-list') }}
        </div>
        <button
          v-if="amIEnrolled && has_permission"
          type="button"
          class="btn btn-light-primary p-2 position-absolute top-0 end-0"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addReading"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </div>

      <pms-smart-table
        :items="readingItems"
        :headers="header"
        :sortable="false"
        :options="{
          design: 'bordered-dashed'
        }"
      >
        <template #type="{ item }">
          {{ item.type }}
        </template>
        <template #authors="{ item }">
          {{ item.authors }}
        </template>
        <template #title="{ item }">
          <span class="fs-5 fw-bold">{{ item.title }}</span>
        </template>
        <template #publisher="{ item }">
          {{ item.publisher }}
        </template>
        <template #publish_year="{ item }">
          {{ item.publish_year }}
        </template>
        <template #isbn="{ item }">
          {{ item.isbn }}
        </template>
        <template #website="{ item }">
          {{ item.website }}
        </template>
        <template #action="{ item }">
          <div
            v-if="has_permission"
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="openEditor(item)"
            >
              <pms-kt-icon
                :icon-name="'click'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              type="button"
              class="btn btn-light-danger p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.delete')"
              @click="deleteReading(item.id)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
            </button>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <SyllabusReadingsForm v-if="modifier && modifier.name === 'syllabus.readings'" />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { computed } from 'vue'
import { readingListTable, has_permission } from './../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { AcademicManageSyllabusReadingsGetResponse } from '@shared/swagger'
import SyllabusReadingsForm from './SyllabusReadingsForm.vue'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'

const { isActive } = useLoader()
const loader = isActive('academic/manage/syllabus/readings')
const alert = useAlert()

const { setModifier, open, modifier } = useTeleportModal()

const store = useStore()
const readingItems = computed(() => store.getReadings)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...readingListTable,
      {
        title: 'common.action',
        field: 'action',
        slotCellName: 'action',
        headerClass: 'w-50px',
        cellClass: 'text-center'
      }]
  : readingListTable)

function openEditor (reading: AcademicManageSyllabusReadingsGetResponse[0]) {
  setModifier(
    'syllabus.readings',
    {
      id: reading.id,
      mode: 'edit'
    },
    { title: 'readings.title.edit', description: 'readings.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

function addReading () {
  setModifier(
    'syllabus.readings',
    { mode: 'add' },
    { title: 'readings.title.add', description: 'readings.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

async function deleteReading (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-reading',
      onConfirm: async () => {
        await store.deleteReading(id)
      }
    }
  )
}
</script>
