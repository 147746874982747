import { MappedChildren, SpecialtyMapped } from '../types'

export default (data: SpecialtyMapped[]): MappedChildren[] => {
  const options: MappedChildren[] = []
  const filterData = data.filter((el) => el.specialties && el.specialties.length > 0)

  filterData.forEach((el) => {
    const newObj = { key: el.code, label: el.title, children: [] as MappedChildren[] }
    if (el.specialties && el.specialties.length > 0) {
      newObj.children = el.specialties.map((spec) => ({
        key: spec.code,
        label: spec.title
      }))
    }
    options.push(newObj)
  })
  return options
}
