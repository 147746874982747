<template>
  <div :class="{'app-dark': themeStore.theme === 'dark'}">
    <sign-in-modal v-if="modalShown" />
    <pms-button
      class="btn-fixed text-white btn px-0 pb-4"
      :color="'danger'"
      :text="'common.old-version'"
      @click="openOldVersion"
    />

    <router-view
      :class="{'pe-none':modalShown}"
      class="h-100"
    />
    <notifications :position="'bottom center'" />
    <Toast />
  </div>
</template>
<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted } from 'vue'

import { useConfigStore, useThemeStore, useBodyStore } from '@shared-stores/pattern'
import { initializeComponents } from '@shared-utils/pattern'
import { useWindow } from '@shared/composable'

import SignInModal from '@shared/ui/components/SignInModal.vue'
import Toast from 'primevue/toast'
import { useUser } from './shared/stores'
import { router } from './router'
import { noSignInModalPages } from './shared/values'

const configStore = useConfigStore()
const themeStore = useThemeStore()
const bodyStore = useBodyStore()
const { onWindowMounted, onWindowUnMounted } = useWindow()

const modalShown = computed(() => {
  return !useUser().isAuthenticated && !noSignInModalPages.includes(router.currentRoute.value.name as string)
})

const openOldVersion = () => {
  window.open(process.env.APP_OLD_PMS_URI, '_blank')
}

onBeforeMount(() => {
  configStore.overrideLayoutConfig()
  configStore.setLayoutConfigProperty('general.mode', 'light')
  themeStore.setTheme('light')
})

onMounted(() => {
  onWindowMounted()
  nextTick(() => {
    initializeComponents()
    bodyStore.removeBodyClassName('page-loading')
  })
})

onUnmounted(() => {
  onWindowUnMounted()
})
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";

// Main style scss
@import "assets/fonticon/fonticon.css";
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/plugins";
@import "assets/sass/style";
@import "assets/sass/primevue";

#app {
  display: contents;
}

@mixin darkMode() {
  background-color: #222f3e !important;
  color: #fff;
}

@mixin textLight() {
  color: #fff !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid rgb(142, 142, 143);
}

.breadcrumb-item {
  &:after {
    padding-left: .5rem !important;
  }
}

.styled .vue-treeselect__value-container{
  box-sizing: content-box !important;
  padding-top: 11px !important;
  padding-bottom:10px !important;
}

.styled .vue-treeselect__single-value, .vue-treeselect__placeholder{
  display: flex;
  align-items: center;
}

.table-border-blue{
  border-color:#73b5d9 !important;
}

.p-datepicker-panel {
  z-index: 1060 !important; /* Установите нужный вам z-index */
}

.vue-treeselect__input{
  border-color: transparent !important;
}

.app-dark .vue-treeselect--single .vue-treeselect__option--selected, .app-dark .vue-treeselect--single .vue-treeselect__option--selected:hover{
  background-color: #2a2a3c;
}

.dark-background{
  background-color: #474761;
}

.light-background{
  background-color: #f3f6f9;
}

</style>
