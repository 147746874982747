import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { AcademicManageSyllabusSubjectSkillsPutRequest, UnknownResponse } from '@shared/swagger'

export default async function putAcademicManageSyllabusSubjectSkill (id: number, body: AcademicManageSyllabusSubjectSkillsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusSubjectSkillsPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/subject-skills/${id}`,
    body,
    loader: 'academic/manage/syllabus/subject-skills'
  })
  return response
}
