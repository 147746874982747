import type { StoreType } from '../types'
import type { AcademicCoursesHistoryRequest } from '@shared/swagger'

import { getAcademicCoursesHistory } from '@shared/api'

export default async function fetchHistory (this: StoreType, values: AcademicCoursesHistoryRequest): Promise<void> {
  const { data, ok } = await getAcademicCoursesHistory(values)

  if (ok) {
    this.setHistory(data)
  }
}
