<template>
  <pms-row id="single-student-course-registration">
    <pms-grid
      :col="12"
    >
      <div class="d-flex justify-content-end">
        <span class="fs-6 fw-bold text-gray-700 flex-shrink-0 p-3">
          {{ $t('common.form-fields.track') }}:
        </span>
        <select
          v-if="tracks"
          v-model="activeTrack"
          class="form-select form-select-md w-200px"
        >
          <template
            v-for="(track, index) in tracks"
            :key="index"
          >
            <option
              :value="track.track"
            >
              {{ track.title }}
            </option>
          </template>
        </select>
      </div>
    </pms-grid>
    <pms-grid
      class="mt-4"
      :col="12"
    >
      <pms-card
        v-if="courses"
        :title="'domains.academic.single-student.navigation.items.course-registration'"
        :description="'domains.academic.single-student.navigation.items.course-registration-title.description'"
        :options="{
          body: {class: 'pt-0'}
        }"
      >
        <template
          #toolbar
        >
          <span>
            {{ $t('domains.academic.single-student.course-registration.registration-status') }}:
          </span>
          <span
            v-if="courses.confirm_status !== null"
            class="ms-1"
            :class="{
              'text-success': [1, 2].includes(courses.confirm_status?.id),
              'text-danger': ![1, 2].includes(courses.confirm_status?.id)
            }"
          >
            {{ courses?.confirm_status.title }}
          </span>
          <span
            v-else
            class="ms-1 text-danger"
          >
            {{ $t('domains.academic.single-student.course-registration.not-registered') }}
          </span>
        </template>
        <course-registration-header
          :course-registration-status="courses.course_registration_status"
          :confirm-statuses="courses.confirm_status"
        />
        <pms-tab
          v-model:active="activeTab"
          class="mt-8"
          :tabs="tabs"
          :translatable="true"
          :icon="true"
          :all="false"
        >
          <template #confirmed>
            <pms-smart-table
              :headers="registrationCourseTableHeaders"
              :items="courses?.confirmed_courses || []"
            >
              <template #item-no="{ idx }">
                <span class="fs-5"> {{ idx + 1 }}</span>
              </template>
              <template #item-title="{ row }">
                {{ row[courseTitleField] }}
              </template>
            </pms-smart-table>
          </template>
          <template #temporary>
            <pms-smart-table
              :headers="registrationCourseTableHeaders"
              :items="courses?.temporary_courses || []"
              :translate-headers="true"
            >
              <template #item-no="{ idx }">
                <span class="fs-5"> {{ idx + 1 }}</span>
              </template>
              <template #item-title="{ row }">
                {{ row[courseTitleField] }}
              </template>
            </pms-smart-table>
          </template>
        </pms-tab>
      </pms-card>
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useResource } from '@shared/stores'

import { useSingleStudent } from '../../../store'
import { tabs, registrationCourseTableHeaders } from '../values'

import CourseRegistrationHeader from './CourseRegistrationHeader.vue'

const i18n = useI18n()

const resource = useResource()
const store = useSingleStudent()

const activeTab = ref<string>('confirmed')

const tracks = computed(() => resource.getAcademicSingleStudentResourceTracks)
const courses = computed(() => store.getCourseRegistration)
const courseTitleField = computed(() => ({
  'en-EN': 'courseTitleEn',
  'kz-KZ': 'courseTitleKz',
  'ru-RU': 'courseTitleRu'
}[i18n.locale.value] || 'courseTitleEn'))

const activeTrack = ref(tracks.value[0].track || '')
</script>
