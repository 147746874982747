import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { getKnowledgeManagePosts } from '@shared/api'
import { usePagination } from '@shared/composable'
import { KnowledgeManagePostsGetRequest } from '@shared/swagger'

export default async function fetchPosts (this: StoreType, values: KnowledgeManagePostsGetRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getKnowledgeManagePosts({ ...values, per_page: perPage, offset: currentPage })

    if (ok) {
      this.setPosts(data.posts || [])
      setPaginate(data.per_page, data.current_page, data.last_page, data.total)
    }
  })

  const { ok, message, data } = await getKnowledgeManagePosts(values)

  if (ok) {
    this.setPosts(data.posts || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  return {
    status: ok,
    message
  }
}
