import { Service } from '../../types'

import helpdesk from './helpdesk'
import dms from './dms'
import library from './library'
import moodle from './moodle'
import website from './website'
import mail from './mail'

export const services: Array<Service> = [
  website,
  helpdesk,
  mail,
  dms,
  library,
  moodle
]
