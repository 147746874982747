import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageLanguagesResourcesResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesResources (params: UnknownRequest): Promise<AxiosResponse<ServiceManageLanguagesResourcesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageLanguagesResourcesResponse>({
    url: 'service/manage/languages/resources',
    body: { params: params },
    loader: 'service/manage/languages/resource'
  })

  return response
}
