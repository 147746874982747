import { useRequests } from '@/shared/composable'

import type {
  AcademicCoursesMyRequest,
  AcademicCoursesMyResponse,
  UnknownRequest
} from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicCoursesMy (params: AcademicCoursesMyRequest): Promise<AxiosResponse<AcademicCoursesMyResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicCoursesMyResponse>({
    url: 'academic/courses/my',
    body: { params },
    loader: 'academic/courses/my'
  })

  return response
}
