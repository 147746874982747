import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageLanguagesApplicantsGetResponse, ServiceManageLanguagesApplicantsGetRequest, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesApplicants (params: ServiceManageLanguagesApplicantsGetRequest): Promise<AxiosResponse<ServiceManageLanguagesApplicantsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageLanguagesApplicantsGetResponse>({
    url: 'service/manage/languages/applicants',
    body: { params: params },
    loader: 'service/manage/languages/applicants'
  })

  return response
}
