import type { StatusMessageResponse } from '@shared/types'
import type { ProfileSettingsPasswordsPutRequest } from '@shared/swagger'
import { putProfileSettingsPasswords } from '@shared/api'

import type { StoreType } from '../types'

export default async function editPassword (this:StoreType, values: ProfileSettingsPasswordsPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileSettingsPasswords(values)

  return {
    status: ok,
    message: message
  }
}
