<template>
  <div class="col-12 col-md-4 mb-3">
    <pms-card
      :header="false"
      :options="{
        body: {class: 'pb-2'}
      }"
    >
      <div class="d-block overlay mb-4">
        <div
          class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
          :style="`background-image:url('${imageMap.P}')`"
        />
        <div class="overlay-layer bg-dark card-rounded bg-opacity-25">
          <i class="ki-duotone ki-eye fs-2x text-white" />
        </div>
      </div>
      <div class="mb-2">
        <router-link
          :to="`/academic/course?year=${currentYearTerm.year}&term=${currentYearTerm.term}&code=${course.code}`"
          class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary"
        >
          {{ course.code }} {{ course[`title_${localeShortest}`] }}
        </router-link>
        <p
          class="mt-1 mb-4 fs-6 text-break"
          style="text-align: justify;"
          v-html="cutContent(
            course.syllabus ? course.syllabus.description : '',
            { length: 200, removeHtmlTags: true, endWith: '...'}
          )"
        />
      </div>
      <div class="d-flex flex-stack flex-wrap mb-4">
        <my-course-actions :course="course" />
      </div>
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { useLocale } from '@shared/composable'
import type { AcademicCoursesMyResponse, AcademicCoursesResourcesResponse } from '@shared/swagger'
import { imageMap } from '../values'
import { cutContent } from '@/shared/utils'
import MyCourseActions from './MyCourseActions.vue'

const { localeShortest } = useLocale()

const { course, currentYearTerm } = defineProps({
  course: {
    type: Object as PropType<AcademicCoursesMyResponse[0]>,
    required: true
  },
  currentYearTerm: {
    type: Object as PropType<AcademicCoursesResourcesResponse['my_year_terms'][0]>,
    required: true
  }
})
</script>
