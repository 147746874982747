<template>
  <div class="card mt-5">
    <div class="card-body">
      <pms-smart-form
        :fields="fields"
        :loader="loader"
        @handleSubmit="onSubmit"
      >
        <template #formControls>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="loader"
          >
            <span
              v-if="!loader"
              class="indicator-label"
            >
              {{ $t('common.save') }}
            </span>
          </button>
        </template>
      </pms-smart-form>
    </div>
  </div>

  <div class="card mt-5">
    <div class="card-body">
      <pms-divider
        id="requisites"
        :content="$t('common.table.requisites')"
        design="dotted"
        color="primary"
        margin-y="5"
      />
      <div class="row mb-3">
        <template
          v-for="(field,key) in inputGroupFields"
          :key="key"
        >
          <input-group
            :field="field"
            @click="goToRequisitesPage"
          />
        </template>
      </div>
      <pms-divider
        content="."
        design="dotted"
        color="primary"
        margin-y="5"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { FormField } from '@/shared/types'
import { useLoader } from '@/shared/composable'

import { WithButton } from '../types'

import InputGroup from './InputGroup.vue'

import { useManageDescriptionComposable, useManageDescriptionFormComposable } from '../composable'
import { useManageCourses } from '@/domains/AcademicCourses/store'

const router = useRouter()

const { onSubmit } = useManageDescriptionComposable()
const { fields, inputGroupFields } = useManageDescriptionFormComposable()

const loader = computed(() => useLoader().isActiveOneOf['academic/manage/courses'])

const store = useManageCourses()

const goToRequisitesPage = function (field: FormField & WithButton) {
  router.push({
    name: 'AcademicCoursesManageRequisitePage',
    params: {
      requisite_type: field.label?.text,
      code: store.fetch_course_info_response.code,
      year: store.fetch_course_info_response.year
    },
    query: {
      track: store.page_params.track
    }
  })
}
</script>
