import { AcademicManageCoursesPostRequest, AcademicManageCoursesPutRequest } from '@shared/swagger'
import { useResource } from '@/shared/stores'
import { ManageCourseRequestType } from '@/domains/AcademicCourses/types'

const resource = useResource()

const getLanguageCodeFromLanguage = (language:ManageCourseRequestType['language']) => {
  return resource.getAcademicManageCourseResource?.languages.find((lang) => lang.title === language)?.code
}

export const createCourseFormat = (values:ManageCourseRequestType):AcademicManageCoursesPostRequest => {
  return {
    code: values.course_code_prefix + ' ' + values.course_code_code,
    year: values.year,
    title_kz: values.title_kz,
    title_ru: values.title_ru,
    title_en: values.title_en,
    department_code: values.department_code,
    period_code: values.period_code,
    term: values.term,
    theory: values.theory,
    practice: values.practice,
    lab: values.lab,
    ects: values.ects,
    equicode: values.equicode,
    language_code: getLanguageCodeFromLanguage(values.language) || values.language,
    type: values.type,
    grading_type: values.grading_type,
    local_code: values.local_code
  }
}

export const editCourseFormat = (values:ManageCourseRequestType):AcademicManageCoursesPutRequest => {
  return {
    code: values.course_code_prefix + ' ' + values.course_code_code,
    year: values.year,
    title_kz: values.title_kz,
    title_ru: values.title_ru,
    title_en: values.title_en,
    department_code: values.department_code,
    period_code: values.period_code,
    term: values.term,
    theory: values.theory,
    practice: values.practice,
    lab: values.lab,
    ects: values.ects,
    equicode: values.equicode,
    language_code: getLanguageCodeFromLanguage(values.language) || values.language,
    type: values.type,
    grading_type: values.grading_type,
    local_code: values.local_code
  }
}
