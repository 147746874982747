import { AcademicSingleCourseGradeStatisticsGetResponse } from '@/shared/swagger'

export default (grades: string[]): AcademicSingleCourseGradeStatisticsGetResponse => {
  const studentCount = grades.length

  const letterGrades = ['A', 'A-', 'B', 'B+', 'B-', 'C', 'C+', 'C-', 'D', 'D+', 'FX', 'F', 'IP', 'NP', 'P']

  const result: AcademicSingleCourseGradeStatisticsGetResponse = []

  letterGrades.forEach(letterGrade => {
    let count = 0

    grades.forEach(element => {
      if (element === letterGrade) {
        count++
      }
    })
    const percent = count !== 0 ? parseFloat((count / studentCount * 100).toFixed(2)) : 0

    result.push({
      letter: letterGrade,
      percent,
      range: ''
    })
  })

  return result
}
