import type { StoreType } from '../types'
import type { AcademicManageCurriculumGetCoreCoursesForReferenceRequest } from '@/shared/swagger'

import api from '@/shared/api/AcademicCurricula/fetchCoreCoursesForReference'

export default async function fetchCoreCoursesForReference (this: StoreType, params: AcademicManageCurriculumGetCoreCoursesForReferenceRequest): Promise<void> {
  const { data, ok } = await api(params)

  if (ok) {
    this.setCoreCoursesForReference(data)
  }
}
