<template>
  <pms-button
    :variant="'outlined'"
    @Click="handleAddCourse(trackId)"
  >
    <i class="bi bi-plus-lg" />
    {{ t('domains.academic.curricula.add-course-button') }}
  </pms-button>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  term: {
    type: Number,
    required: false
  },
  trackId: {
    required: true,
    type: Number
  }
})

const handleAddCourse = (trackId?: number) => {
  if (trackId) {
    router.push({
      name: 'AddCourseToTrackPage',
      params: {
        trackId
      },
      query: {
        term: props.term
      }
    })
  }
}
</script>
