<template>
  <div
    class="d-flex align-items-center round-it border-dashed px-2 ps-0 my-1 bg-light-success"
    :title="student.id+''"
    :class="{
      'mx-2': index !== 0,
      'me-2': index === 0
    }"
  >
    <div class="symbol symbol-30px symbol-circle me-3 border-4 border border-success">
      <img
        :src="student.photo"
        :alt="student.full_name"
        style="object-fit: cover;"
      >
    </div>
    <span class="fw-bold text-gray-800 fs-5 me-2"> {{ student.full_name }} </span>
    <div class="me-2">
      <pms-dropdown
        :show-icon="false"
        :items="[]"
      >
        <template #button>
          <pms-button
            :variant="'tonal'"
            :size="'sm'"
            :color="'success'"
            class="p-1 pt-0"
          >
            <pms-kt-icon
              :icon-name="'down-square'"
              class="fs-4 m-0 p-0"
            />
          </pms-button>
        </template>

        <template
          v-for="(action, i) in (student.schedule ? dropdownItemsOnCancel : dropdownItems)"
          :key="i"
        >
          <div
            class="menu-item w-75px"
          >
            <a
              href="javascript:;"
              class="menu-link px-3 py-2"
              @click="action.action()"
            >
              {{ $t(action.title) }}
            </a>
          </div>
        </template>
      </pms-dropdown>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { QrStudent } from '../../../types'

defineProps({
  student: {
    type: Object as PropType<QrStudent>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const dropdownItems = [
  {
    title: 'common.table.attendance.attend',
    action: () => { console.log('attend') }
  },
  {
    title: 'common.table.attendance.absent',
    action: () => { console.log('absent') }
  }
]

const dropdownItemsOnCancel = [
  {
    title: 'common.cancel',
    action: () => { console.log('cancel') }
  }
]
</script>
<style scoped lang="scss">
.round-it {
  border-radius: 25px;
}
</style>
