<template>
  <pms-card :header="false">
    <div class="mb-4">
      <router-link
        to="/services/message-broadcast"
        class="text-primary opacity-75-hover fs-5 fw-semibold d-flex align-items-center"
      >
        <pms-kt-icon
          :icon-name="'arrow-left'"
          class="text-primary fs-2 me-2"
        />
        <span>
          {{ $t('common.back') }}
        </span>
      </router-link>
    </div>

    <template v-if="message">
      <div class="d-flex flex-wrap gap-2 flex-stack">
        <div class="d-flex align-items-start">
          <div
            v-styler="{ paddingRight: '5px'}"
            class="symbol symbol-50px me-3"
          >
            <div
              class="symbol-label"
              :class="{
                'bg-light-primary': !message.from,
                'bg-light-warning': message.from,
              }"
            >
              <span
                :class="{
                  'text-primary': !message.from,
                  'text-warning': message.from,
                }"
              >{{ getAuthorFirstLetters(message.author) }}</span>
            </div>
          </div>
          <div class="pe-5">
            <div class="d-flex align-items-center flex-wrap gap-1">
              <span class="fw-bold text-dark fs-5">
                {{ message.author }}
                <span
                  v-styler="{ marginLeft: '10px'}"
                  class="fw-semibold text-muted text-end me-3 fs-5"
                >{{ getSentDate(message.receives) }}</span>
              </span>
              <span
                v-if="message.from"
                class="badge badge-light-warning me-2 fs-7"
              > {{ message.from }} </span>
            </div>
            <div class="mt-2">
              <span
                v-styler="{ marginRight: '10px' }"
                class="fw-bold text-muted fs-6"
              >{{ $t('common.to') }}:</span>
              <span
                v-for="(rec, index) in getFirstFiveReceivers(message.receives, 25)"
                :key="index"
                v-styler="{marginRight: '5px', marginBottom: '5px'}"
                class="badge badge-primary fs-6"
              >
                {{ rec.full_name }} {{ rec.student_id ? `(${rec.student_id})` : '' }}
              </span>
              <span v-if="message.receives.length > 30">
                <span
                  v-styler="{marginRight: '5px', marginBottom: '5px'}"
                  class="mt-2 fs-7"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                >
                  + {{ message.receives.length - 5 }} {{ $t('common.more') }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <h2 class="fw-semibold my-1 mt-6">
        {{ message.subject }}
      </h2>
      <p
        class="fs-5 mt-4 p-0 mb-2"
        v-html="formatContent(message.content)"
      />
    </template>
  </pms-card>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import { useMessageBroadcastSingle } from '../store'
import { formatContent, getAuthorFirstLetters, getFirstFiveReceivers, getSentDate } from '../utils'
import { useUser } from '@/shared/stores'

const store = useMessageBroadcastSingle()
const user_store = useUser()
const new_inbox_messages = computed(() => user_store.getNewInboxMessages)
const message = computed(() => store.getMessage)

watchEffect(() => {
  if (new_inbox_messages.value.length > 0 && message.value) {
    user_store.setNewInboxMessages(new_inbox_messages.value.filter(inbox => inbox.message_id !== message.value?.id))
  }
})
</script>
