import { RouteRecordWithLayout } from '@shared/types'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useKnowledgeBaseSingle } from './store'

const handleRoute = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const uuid = to.params.uuid ? String(to.params.uuid) : undefined
  if (!uuid) {
    next({ path: '404' })
    return
  }

  const store = useKnowledgeBaseSingle()

  store.$reset()
  store.bootstrap(uuid)
  next()
}

export default {
  layout: 'default',
  path: '/knowledge-base/post/:uuid',
  children: [
    {
      path: '',
      name: 'KnowledgeBaseSingle',
      component: () => import('./KnowledgeBaseSingle.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
