import { PropType, defineComponent } from 'vue'
import { handleOptions, isTranslatable } from '@shared/utils'
import { AlertOptions } from '@shared/types'
import { useI18n } from 'vue-i18n'

const defaultOptions: AlertOptions = {
  container: {
    bg: 'alert-primary',
    border: 'border rounded border-primary',
    class: 'p-5',
    style: ''
  },
  title: {
    class: 'fw-bold mb-0 p-0',
    style: ''
  },
  content: {
    class: 'fs-5',
    style: ''
  },
  close: {
    color: 'primary',
    class: 'fs-1',
    style: ''
  },
  icon: {
    color: 'text-primary',
    class: 'fs-2tx me-4',
    style: ''
  }
}
export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: 'Please, set a title prop'
    },
    rich: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object as PropType<object>,
      default: () => ({})
    },
    icon: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String as PropType<string>,
      default: 'info'
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { slots }) {
    const { t } = useI18n()
    const options = handleOptions<AlertOptions>(props.options, defaultOptions)
    if (props.rich) {
      return () => (
        <div
          class={{
            'alert d-flex flex-column flex-center': true,
            'alert-dismissible': props.close,
            [options?.container?.bg || '']: true,
            [options?.container?.class || '']: true,
            [options?.container?.border || '']: true
          }}
          style={ options?.container?.style }
        >
          {
            props.close && (
              <button
                type="button"
                class={{
                  'position-absolute top-0 end-0 m-2 btn btn-icon': true,
                  [options?.close?.class || '']: true,
                  [`btn-icon-${options?.close?.color || 'primary'}`]: true
                }}
                data-bs-dismiss="alert"
              >
                <pms-kt-icon
                  icon-name="cross"
                  class="fs-2"
                />
              </button>
            )
          }
          {
            props.icon && (
              <pms-kt-icon
                icon-name={ props.iconName }
                class={{
                  'ki-duotone': true,
                  [options?.icon?.color || '']: true,
                  [options?.icon?.class || '']: true
                }}
                style={ options?.icon?.style }
              />
            )
          }

          <div class="text-center">
            <h1
              class={{ [options?.title?.class || '']: true }}
              style={ options?.title?.style }
            >
              { slots.title ? slots.title() : props.title }
            </h1>
            <div
              class={{ [options?.content?.class || '']: true }}
              style={ options?.content?.style }
            >
              { slots.default?.() }
            </div>
          </div>
        </div>
      )
    }

    return () => (
      <div
        class={{
          'alert d-flex align-items-center': true,
          'alert-dismissible': props.close,
          [options?.container?.bg || '']: true,
          [options?.container?.class || '']: true,
          [options?.container?.border || '']: true
        }}
        style={ options?.container?.style }
      >
        {
          props.icon && (
            <pms-kt-icon
              icon-name={ props.iconName }
              class={{
                'ki-duotone': true,
                [options?.icon?.color || '']: true,
                [options?.icon?.class || '']: true
              }}
              style={ options?.icon?.style }
            />
          )
        }
        <div class="d-flex flex-column">
            <h4
              class={{
                [options?.title?.class || '']: true
              }}
              style={ options?.title?.style }
            >
              { slots.title ? slots.title() : isTranslatable(props.title) ? t(props.title) : props.title }
            </h4>
            {
              slots.default && (
                <span
                  class={{ [options?.content?.class || '']: true }}
                  style={ options?.content?.style }
                >
                  { slots.default?.() }
                </span>
              )
            }
        </div>
        {
          props.close && (
            <button
              type="button"
              class={{
                'position-absolute top-0 end-0 m-2 btn btn-icon': true,
                [options?.close?.class || '']: true,
                [`btn-icon-${options?.close?.color || 'primary'}`]: true
              }}
              data-bs-dismiss="alert"
            >
              <pms-kt-icon
                icon-name="cross"
                class="fs-2"
              />
            </button>
          )
        }
      </div>
    )
  }
})
