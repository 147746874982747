import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useKnowledgeManagePost = defineStore<Name, State, Getters, Actions>('KnowledgeBase.useKnowledgeManagePost', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      posts: [],
      post: null,

      postUuid: '',
      postContents: [],
      newContent: null,

      stepper: 1,
      isLoading: false
    }
  }
})
