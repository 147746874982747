<template>
  <pms-card
    :title="'domains.schedule-management.section-details-title'"
    :description="'domains.schedule-management.course-details-description'"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0 pb-2'},header: {class: 'mt-2'}}"
  >
    <pms-page-shimmer
      v-if="loading"
      :shimmer="'list'"
      :full-width="true"
    />
    <div
      v-else
      class="table-responsive"
    >
      <table class="table table-row-dashed">
        <tbody>
          <template
            v-for="data in tableData"
            :key="data.index"
          >
            <tr style="vertical-align: middle;">
              <td class="text-start w-150px">
                <span
                  class="text-gray-800 d-block fs-4"
                >
                  {{ $t(`domains.schedule-management.sidebar.${data.translate}`) }}:
                </span>
              </td>

              <td class="text-start pe-5">
                <span class="text-gray-800 fw-bold d-block fs-4">
                  <template v-if="data.custom && data.custom === 'code' && section">
                    {{ section.course.code }}
                  </template>
                  <template v-if="data.custom && data.custom === 'section' && section">
                    {{ section.section }}
                  </template>
                  <template v-else-if="data.custom && data.custom === 'title' && section">
                    {{ section.course.title }}
                  </template>
                  <template v-else-if="data.custom && data.custom === 'credits' && section">
                    [{{ section.course.theory }}+{{ section.course.practice }}+{{ section.course.lab }}]
                  </template>
                  <template v-else-if="data.custom && data.custom === 'type' && section">
                    {{ section.type.key }} - {{ section.type.title }}
                  </template>
                  <template v-else-if="data.custom && data.custom === 'teacher' && section">
                    {{ section.teacher.full_name }}
                  </template>
                  <template v-else-if="data.custom && data.custom === 'attendance' && section">
                    {{ section.attendance_type.type }}
                  </template>
                  <template v-else>
                    {{ section?.[data.field] }}
                  </template>
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { useLoader } from '@/shared/composable'

const store = useScheduleManagement()

const { isActive } = useLoader()
const loading = isActive('academic/manage/schedules/sections-single')

const section = computed(() => store.getSingleSection)
const tableData = [
  {
    field: 'code',
    translate: 'course-code',
    custom: 'code'
  },
  {
    field: 'section',
    translate: 'section',
    custom: 'section'
  },
  {
    field: 'title',
    translate: 'title',
    custom: 'title'
  },
  {
    field: 'credits',
    translate: 'credits',
    custom: 'credits'
  },
  {
    field: 'quotas',
    translate: 'quota'
  },
  {
    field: 'type',
    translate: 'type',
    custom: 'type'
  },
  {
    field: 'teacher',
    translate: 'teacher',
    custom: 'teacher'
  },
  {
    field: 'credits',
    translate: 'section-credits'
  },
  {
    field: 'weeks',
    translate: 'weeks'
  },
  {
    field: 'hours',
    translate: 'teaching-hours'
  },
  {
    field: 'attendance',
    translate: 'attendance',
    custom: 'attendance'
  }
]
</script>
