<template>
  <h2 class="mb-3">
    {{ programTitle }}
  </h2>
</template>

<script setup lang="ts">
import { useNewRequest } from './../store'
import { computed } from 'vue'

const newRequestStore = useNewRequest()
const programTitle = computed(() => newRequestStore.selectedProgram?.title || '')
</script>
