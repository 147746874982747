import { TabElement } from '@shared/types'

export const syllabusTabs: Array<TabElement> = [
  {
    id: 'information',
    label: 'information.title',
    icon: 'bi-info-circle'
  },
  {
    id: 'content',
    label: 'content.title',
    icon: 'bi-blockquote-right'
  },
  {
    id: 'advanced',
    label: 'advanced.title',
    icon: 'bi-diagram-3'
  },
  {
    id: 'policy',
    label: 'policy.title',
    icon: 'bi-shield-check'
  }
]
