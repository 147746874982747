
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useAuth = defineStore<Name, State, Getters, Actions>('Authenticator.userAuth', {
  actions: {
    ...actions
  },

  getters: {},

  state () {
    return { }
  }
})
