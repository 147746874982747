import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, UnknownRequest, ScheduleManagementDiscardAppliedProgramRequestParams } from '@shared/swagger'

export default async function discardAppiedProgram (data: ScheduleManagementDiscardAppliedProgramRequestParams): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownRequest, UnknownResponse>({
    url: `academic/manage/schedules/sections/${data.section}/programs/${data.program}/cancel`,
    body: { },
    loader: 'discard-appy-academic/manage/schedules/sections/program'
  })

  return response
}
