import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownResponse,
  SectionManagementRequestsQuotasPutRequest
} from '@shared/swagger'

export default async function putSectionManagementRequestsQuota (requestId: number, quotaId: number, params: SectionManagementRequestsQuotasPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<SectionManagementRequestsQuotasPutRequest, UnknownResponse>({
    url: `academic/manage/sections/requests/${requestId}/quotas/${quotaId}`,
    body: params,
    loader: 'academic/manage/sections/requests/{id}/quotas/{id}'
  })
  return response
}
