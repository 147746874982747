import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentCourseRegistrationGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentCourseRegistration (id: string): Promise<AxiosResponse<AcademicSingleStudentCourseRegistrationGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentCourseRegistrationGetResponse>({
    url: `academic/students/${id}/course-registration`,
    body: {},
    loader: 'academic/students/course-registration'
  })

  return response
}
