<template>
  <pms-card
    :title="t('domains.academic.curricula.term.courses-card')"
  >
    <pms-smart-table
      id="trackTermCoursesTable"
      :headers="trackTermCoursesTableHeaders"
      :items="coreCoursesByTerm"
      :sortable="true"
      class="mt-3"
    >
      <template #code="{item}">
        <p>{{ item.code }}</p>
      </template>
      <template #title="{item}">
        <p>{{ item.title }}</p>
      </template>
      <template #theory="{item}">
        {{ item.theory }}
      </template>

      <template #practice="{item}">
        {{ item.practice }}
      </template>
      <template #credits="{item}">
        {{ item.credits }}
      </template>
      <template #ects="{item}">
        {{ item.ects }}
      </template>
      <template #actions="{item}">
        <div class="d-flex gap-4">
          <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
            <pms-button @Click="handleEdit(item)">
              <i class="bi bi-pencil" /> {{ t('common.edit') }}
            </pms-button>
          </PermissionCheck>
          <PermissionCheck :permission-code="permissionCodes.edit_curricula.code">
            <pms-button
              class="btn btn-danger"
              @Click="handleDeleteF(item)"
            >
              {{ t('common.delete') }}
            </pms-button>
          </PermissionCheck>
        </div>
      </template>
    </pms-smart-table>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '../../../store'
import { storeToRefs } from 'pinia'
import { trackTermCoursesTableHeaders } from '../values/table'
import { AcademicManageCurriculumCoreCoursesResponse } from '@/shared/swagger'
import { useI18n } from 'vue-i18n'
import useCourseEditor from '@domains/AcademicCurricula/shared/composable/useCourseEditor'
import useCurriculumDeleter from '@/domains/AcademicCurricula/shared/composable/useCurriculumCoreDeleter'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import permissionCodes from '@/shared/values/permissionCodes'

const store = useStore()
const { coreCoursesByTerm, track } = storeToRefs(store)

const { t } = useI18n()
const { handleEditCourse } = useCourseEditor()
const curriculumDeleter = useCurriculumDeleter()
const deleteCurriculum = curriculumDeleter.handleDelete

const handleEdit = (item: Pick<AcademicManageCurriculumCoreCoursesResponse[0], 'is_slot' | 'id'>) => {
  if (!track.value) return
  handleEditCourse({
    id: item.id,
    is_slot: item.is_slot,
    trackId: track.value.id
  })
}

const handleDeleteF = (item: typeof coreCoursesByTerm.value[0]) => {
  deleteCurriculum(item, () => {
    if (track.value) {
      store.fetchAcademicalCoreCoursesByTerm({
        track_id: track.value.id,
        term: item.term
      })
    }
  })
}
</script>
