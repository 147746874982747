<template>
  <div class="mb-10">
    <div class="d-flex justify-content-between mb-4">
      <h3 class="card-title align-items-start flex-column mb-0">
        <span class="card-label fw-bold text-gray-800">
          {{ $t("domains.academic.single-section.bell-curve.title") }}
        </span>
        <span class="text-gray-400 mt-1 fw-semibold fs-6 d-block">
          {{ $t("domains.academic.single-section.bell-curve.description") }}
        </span>
      </h3>
    </div>
    <pms-smart-form
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-4 d-flex justify-content-end gap-5">
          <pms-button
            type="button"
            :text="$t('common.revert')"
            :size="'sm'"
            :disabled="!bellCurvedGrades.assessment_id"
            @click="handleRevertClick"
          />
          <pms-button
            type="submit"
            :text="$t('common.apply')"
            :size="'sm'"
            :disabled="
              bellCurvedGrades.assessment_id ||
                !addedGrades.assessment_id ||
                !addedGradeExist
            "
          />
        </div>
      </template>
    </pms-smart-form>
  </div>
</template>

<script setup lang="ts">
import { FormGroup } from '@/shared/types'
import { computed } from 'vue'
import type { BellCurveForm } from '../types'
import { addedGrades, bellCurvedGrades } from '../values'
import { useAlert } from '@/shared/composable'
import { useI18n } from 'vue-i18n'

interface BellCurveEmits {
  (e: 'submit', values: BellCurveForm): void;
  (e: 'revert'): void;
}

const { error } = useAlert()
const { t } = useI18n()
const emit = defineEmits<BellCurveEmits>()

const formFields = computed((): Array<FormGroup> => {
  return [
    {
      class: 'row ',
      fields: [
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('domains.academic.single-section.bell-curve.deviation'),
            class: 'mb-1 required'
          },
          name: 'deviation',
          value: '10',
          required: true,
          type: 'number',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              {
                func: 'test',
                args: [
                  'Validation',
                  t(
                    'domains.academic.single-section.bell-curve.deviation-validation'
                  ),
                  (value) => value >= 7 && value <= 15
                ]
              }
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-6'
          },
          label: {
            text: t('domains.academic.single-section.bell-curve.mean'),
            class: 'mb-1 required'
          },
          name: 'mean',
          value: '75',
          required: true,
          type: 'number',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              {
                func: 'test',
                args: [
                  'Validation',
                  t(
                    'domains.academic.single-section.bell-curve.mean-validation'
                  ),
                  (value) => value >= 70 && value <= 80
                ]
              }
            ]
          }
        }
      ]
    }
  ]
})

const addedGradeExist = computed(
  () =>
    addedGrades.value.assessment_id &&
    addedGrades.value.grades.some((g) => !!g.grade)
)

const onSubmit = ({ values }) => {
  values.deviation && values.mean
    ? emit('submit', values)
    : error({ text: t('common.required') })
}

const handleRevertClick = () => {
  emit('revert')
}
</script>

<style scoped></style>
