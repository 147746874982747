import { computed } from 'vue'

import { useResource } from '@/shared/stores'

const resource = useResource()

const contactTypes = computed(() => resource.getProfileResource?.contact_types || [])

export const getContactTypeTitle = (id: number): string => {
  const contactType = contactTypes.value.find((type) => type.type_id === id)
  return contactType ? contactType.title : ''
}
