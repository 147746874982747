<template>
  <div class="card-header p-0">
    <div class="d-flex">
      <div class="symbol symbol-50px me-5 mt-3">
        <div class="d-flex align-items-center justify-content-center rounded w-50px h-50px fs-5 bg-light text-dark  ">
          {{ showInitials(experience.title) }}
        </div>
      </div>

      <div class="flex-grow-1 ">
        <span class="text-gray-800 fs-3 fw-bold d-block lh-sm">{{ experience.title }}</span>
        <span class="text-gray-800 fs-4 fw-semibold d-block">{{ getTitleById(experience.company_id,companies) + ',' + getTitleById(experience.country_id,country) }}</span>

        <span class="text-gray-400 d-block">{{ displayDate(experience) }}</span>
        <span class="text-gray-400 d-block">{{ getTitleById(experience.country_id,country) + ',' + getTitleById(experience.city_id,cities) }}</span>
      </div>
    </div>

    <div class="d-flex gap-2 mt-5">
      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-icon btn-light-primary"
        @click="emit('set-id',experience.id)"
      >
        <pms-kt-icon
          icon-name="notepad-edit"
          class="fs-3 m-0 p-0"
        />
      </a>

      <a
        href="javascript:void(0)"
        class="btn btn-sm btn-icon btn-light-primary"
        @click="emit('delete-id',experience.id, experience.title)"
      >
        <pms-kt-icon
          icon-name="trash"
          class="fs-3 m-0 p-0"
        />
      </a>
    </div>
  </div>

  <div class="card-body p-0 mt-3">
    <div class="border border-primary" />
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'

import { ExperienceForDisplay } from '../types'
import { showInitials, displayDate, getTitleById } from '../utils'
import { ProfileResourceResponse } from '@/shared/swagger'

defineProps({
  experience: {
    type: Object as PropType<ExperienceForDisplay['experience']>,
    required: true
  },
  country: {
    type: Array as PropType<ProfileResourceResponse['countries']>,
    required: true
  },
  companies: {
    type: Array as PropType<ProfileResourceResponse['companies']>,
    required: true
  },
  cities: {
    type: Array as PropType<ProfileResourceResponse['cities']>,
    required: true
  }
})
const emit = defineEmits(['set-id', 'delete-id'])
</script>
