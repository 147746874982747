<template>
  <Teleport to="#schedule-management-modal-content">
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      class="mt-4"
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmitForm"
    >
      <template #formControls>
        <div class="d-flex flex-center mt-6">
          <pms-button
            :type="'submit'"
            class="w-100"
            :text="$t('common.save')"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, watch, ref } from 'vue'
import { useTeleportModal, useLoader, useAlert } from '@shared/composable'
import type { FormField } from '@shared/types'

import { useScheduleManagement } from '@domains/ScheduleManagement/store'
import { objectDeepCopy } from '@/shared/utils'

const { t } = useI18n()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const { isActive } = useLoader()
const { success, error } = useAlert()

const loader = isActive('academic/manage/schedules/sections-single/programs-single')

const store = useScheduleManagement()

const section = computed(() => store.getSingleSection)
const program = computed(() => store.getSingleSection1Program)

const onSubmitForm = async () => {
  const { status, message } =
        Number(modifier.value?.data?.id) === -1
          ? await store.addProgram(String(section.value?.id), { ...tempValues.value, is_main: false })
          : await store.editProgram(String(section.value?.id), String(program.value?.id), { ...tempValues.value })

  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'schedule-management-modal' })
    unsetModifier()
    await store.fetchSingleSectionPrograms(String(section.value?.id))
  } else {
    error({ text: message })
  }
}

const defaultTempValue = {
  title: '',
  start_date: '',
  end_date: ''
}

const tempValues = ref({ ...defaultTempValue })

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: `${t('domains.schedule-management.property.title')}`,
      class: 'mb-1'
    },
    name: 'title',
    type: 'text',
    group: {
      class: 'mb-3'
    },
    value: tempValues.value.title,
    class: 'form-control form-control-lg form-control-solid',
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: `${t('domains.schedule-management.property.start-date')}`,
      class: 'mb-1'
    },
    name: 'start_date',
    type: 'date',
    group: {
      class: 'mb-3'
    },
    value: tempValues.value.start_date,
    class: 'form-control form-control-lg form-control-solid'
  },
  {
    label: {
      text: `${t('domains.schedule-management.property.end-date')}`,
      class: 'mb-1'
    },
    name: 'end_date',
    type: 'date',
    group: {
      class: 'mb-3'
    },
    value: tempValues.value.end_date,
    class: 'form-control form-control-lg form-control-solid'
  }
])
watch(
  () => modifier.value,
  async (newValue) => {
    if (newValue && newValue.data && newValue.data.id) {
      if (newValue.data.id !== -1) {
        await store.fetchSingleSection1Program(String(section.value?.id), newValue.data.id as string)
        tempValues.value = objectDeepCopy(store.getSingleSection1Program || defaultTempValue)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValue)
      }
    }
    show()
  },
  { immediate: true }
)
</script>
