import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  ScheduleManagementSingleSectionProgramsGetResponse
} from '@shared/swagger'

export default async function getScheduleManagementSingleSectionPrograms (section: string): Promise<AxiosResponse<ScheduleManagementSingleSectionProgramsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ScheduleManagementSingleSectionProgramsGetResponse>({
    url: `academic/manage/schedules/sections/${section}/programs`,
    body: {},
    loader: 'academic/manage/schedules/sections-single/programs'
  })

  return response
}
