import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileEducationConductedGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationConducted (): Promise<AxiosResponse<ProfileEducationConductedGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileEducationConductedGetResponse>({
    url: 'profile/education/conducted-courses',
    body: {},
    loader: 'profile/education/conducted-courses'
  })
  return response
}
