import { getProfileEducationSupervisions } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchSupervisions (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileEducationSupervisions()

  if (ok) {
    this.setSupervisions(data)
  }
}
