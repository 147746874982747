import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { useAlert } from '@shared/composable'

import { useStore } from './store'
import { QueryParams } from './feature/CurriculaViewPage/types'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useStore()
  store.$reset()
  store.bootstrap()

  next()
}

const handleViewRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const params = to.query as unknown as QueryParams
  const { error } = useAlert()

  if (!('track' in params)) {
    error({ title: 'common.not-found', text: 'common.errors.curricula-not-found' })
    next({ path: '/academic/curricula' })
    return
  }

  const store = useStore()
  store.$reset()
  store.bootstrapSingleCurricula(params)

  next()
}

export default {
  layout: 'default',
  path: '/academic/curricula',
  children: [
    {
      path: '',
      name: 'AcademicCurriculaPage',
      component: () => import('./CurriculaPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'outcome/:program_code/:program_year',
      name: 'AcademicCurriculaOutcomePage',
      component: () => import('./feature/CurriculaOutcomePage/CurriculaOutcomePage.vue'),
      beforeEnter: [
        handleRoute
      ]
    },
    {
      path: 'view',
      name: 'AcademicCurriculaViewPage',
      component: () => import('./feature/CurriculaViewPage/CurriculaViewPage.vue'),
      beforeEnter: [
        handleViewRoute
      ]
    },
    {
      path: ':trackId/edit-term/:term',
      name: 'EditCurriculumTermPage',
      component: () => import('./feature/EditCurriculumTermPage/EditCurriculumTermPage.vue'),
      beforeEnter: []
    },
    {
      path: ':trackId/add-course',
      name: 'AddCourseToTrackPage',
      component: () => import('./feature/AddCourseToTrackPage/AddCourseToTrackPage.vue'),
      beforeEnter: []
    },
    {
      path: ':trackId/add-elective-slot',
      name: 'AddElectiveSlotPage',
      component: () => import('./feature/AddElectiveSlotPage/AddElectiveSlotPage.vue'),
      beforeEnter: []
    },
    {
      path: ':trackId/edit-core/:courseId',
      name: 'EditCoreCourse',
      component: () => import('./feature/EditCoreCoursePage/EditCoreCoursePage.vue'),
      beforeEnter: []
    },
    {
      path: ':trackId/edit-elective-slot/:courseId',
      name: 'EditElectiveSlot',
      component: () => import('./feature/EditElectiveSlotPage/EditElectiveSlotPage.vue'),
      beforeEnter: []
    },
    {
      path: ':trackId/reference',
      name: 'CoreCourseReferensePage',
      component: () => import('./feature/CoreCourseReferensePage/CoreCourseReferensePage.vue'),
      beforeEnter: []
    },
    {
      path: 'working-curriculum/:trackId',
      name: 'WorkingCurriculaPage',
      component: () => import('./feature/WorkingCurriculaPage/WorkingCurriculaPage.vue'),
      beforeEnter: []
    }
  ]
} as RouteRecordWithLayout
