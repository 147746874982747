import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useStore } from './store'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  useStore().bootstrap()
  next()
}

export default {
  layout: 'empty',
  path: '/translate/tours',
  children: [
    {
      path: '',
      name: 'TranslateTours',
      component: () => import('./TranslateTours.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    },
    {
      path: ':path',
      name: 'TranslateTour',
      component: () => import('./features/EditTourContents.vue'),
      beforeEnter: []
    }
  ]
} as RouteRecordWithLayout
