<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            :text="$t('common.edit')"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { isString } from './../utils'
import { useStore } from './../store'
import { ModalDescriptionTransform } from './../types'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const store = useStore()
const tempValue = ref<ModalDescriptionTransform>({
  component: '',
  item: ''
})

const formFields = computed<Array<FormField>>(() => [
  {
    type: 'editor',
    name: tempValue.value.component,
    value: tempValue.value.item || ''
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) tempValue.value = newValue.data as ModalDescriptionTransform
    show()
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  if (isString(values[tempValue.value.component])) {
    const updatedDescription = {
      ...store.description,
      [tempValue.value.component]: values[tempValue.value.component] as string
    }

    store.editDescriptions(updatedDescription)
    close({ name: 'syllabus-form-content-modal' })
    unsetModifier()
  }
}
</script>
../utils../store../types
../utils../store../types
