<template>
  <pms-loader v-if="loader" />
  <div
    v-else
    class="d-flex flex-column"
  >
    <div class="d-flex flex-row flex-end">
      <button
        class="btn btn-primary btn-lg ms-2"
        @click="openModal(-1)"
      >
        {{ $t('common.add') }}
      </button>
    </div>
    <pms-smart-table
      :items="sections"
      :headers="sectionsZoomTableHeader"
      :class="'mt-4'"
      :options="{
        wrapper: { responsive: false}
      }"
    >
      <template #no="{idx}">
        {{ idx + 1 }}
      </template>
      <template #course_code>
        {{ store.selectedCourse?.code ?? '' }}
      </template>
      <template #teacher="{item}">
        {{ item.instructor?.full_name }}
      </template>
      <template #grade_submitter="{item}">
        {{ item.grade_submitter?.full_name }}
      </template>
      <template #type="{item}">
        {{ item.type.id }}
      </template>
      <template #students="{item}">
        {{ item.students_count }}
      </template>
      <template #action="{item}">
        <pms-dropdown
          :group-class="'w-100px order-5'"
          :icon-placement="'end'"
        >
          <div class="w-150px menu-item pt-0 pb-0 text-left">
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="openModal(item.id)"
            >
              {{ $t('common.table.edit') }}
            </a>
            <a
              href="javascript:void(0)"
              class="menu-link px-3 text-left"
              @click="deleteAction(item.id,item.section)"
            >
              {{ $t('common.table.delete') }}
            </a>
          </div>
        </pms-dropdown>
      </template>
    </pms-smart-table>
  </div>
  <SectionsModal v-if="modifier && modifier.name === 'received-requests-section'" />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useLoader, useTeleportModal, useAlert } from '@/shared/composable'

import { useReceivedRequest } from '../store'

import { sectionsZoomTableHeader } from '../values'

import SectionsModal from './SectionsModal.vue'

const { t } = useI18n()
const { isActive } = useLoader()
const { setModifier, open, modifier } = useTeleportModal()
const { dialog, success, error } = useAlert()

const store = useReceivedRequest()

const loader = isActive('academic/manage/sections')

const sections = computed(() => store.getSelectedSections)

const openModal = (id: number) => {
  setModifier('received-requests-section',
    { id: id },
    {
      title: store.selectedCourse?.title + ` (${store.selectedCourse?.code})`,
      description: t('common.table.credits') + ': ' + store.selectedCourse?.credits
    }
  )
  open({ name: 'received-requests-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.academic.manage.sections.received-requests.are-you-sure',
    text: t('domains.academic.manage.sections.received-requests.delete-text', { name: title }),
    onConfirm: async () => {
      const { status, message } = await store.deleteSection(id)
      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    }
  })
}
</script>
