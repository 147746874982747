<template>
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
    data-kt-menu="true"
    data-kt-element="theme-mode-menu"
  >
    <div class="menu-item px-3 my-0">
      <a
        href="javascript:void(0);"
        :class="{ active: themeMode === 'light' }"
        class="menu-link px-3 py-2"
        @click="setMode('light')"
      >
        <span
          class="menu-icon"
          data-kt-element="icon"
        >
          <span class="svg-icon svg-icon-3">
            <pms-kt-icon
              icon-name="night-day"
              icon-class="fs-2"
            />
          </span>
        </span>
        <span class="menu-title">{{ $t('layout.mode.light') }}</span>
      </a>
    </div>
    <div class="menu-item px-3 my-0">
      <a
        href="javascript:void(0);"
        :class="{ active: themeMode === 'dark' }"
        class="menu-link px-3 py-2"
        @click="setMode('dark')"
      >
        <span
          class="menu-icon"
          data-kt-element="icon"
        >
          <span class="svg-icon svg-icon-3">
            <pms-kt-icon
              icon-name="moon"
              icon-class="fs-2"
            />
          </span>
        </span>
        <span class="menu-title">{{ $t('layout.mode.dark') }}</span>
      </a>
    </div>
    <div class="menu-item px-3 my-0">
      <a
        href="javascript:void(0);"
        :class="{ active: themeMode === 'system' }"
        class="menu-link px-3 py-2"
        @click="setMode('system')"
      >
        <span
          class="menu-icon"
          data-kt-element="icon"
        >
          <span class="svg-icon svg-icon-3">
            <pms-kt-icon
              icon-name="screen"
              icon-class="fs-2"
            />
          </span>
        </span>
        <span class="menu-title">{{ $t('layout.mode.auto') }}</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useConfigStore, useThemeStore } from '@shared-stores/pattern'

const storeTheme = useThemeStore()
const storeConfig = useConfigStore()

const themeMode = computed(() => storeTheme.theme)

const setMode = (mode: 'dark' | 'light' | 'system') => {
  const configMode = mode

  storeConfig.setLayoutConfigProperty('general.mode', configMode)

  storeTheme.setTheme(configMode)
}
</script>
