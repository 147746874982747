<template>
  <div class="d-flex flex-lg-row-fluid">
    <div
      class="d-flex flex-column flex-center pb-0 pb-lg-10 w-100"
      :class="{
        'p-10': device == 'desktop',
        'pt-4': device == 'tablet' || device == 'mobile'
      }"
    >
      <img
        :class="imageClass"
        class="theme-light-show mx-auto mw-100 mb-10 mb-lg-20 d-none d-sm-block"
        :src="lightImage"
        :alt="title"
      >
      <img
        :class="imageClass"
        class="theme-dark-show mx-auto mw-100 mb-10 mb-lg-20 d-none d-sm-block"
        :src="darkImage"
        :alt="title"
      >
      <h1
        v-test-id="'aside-title'"
        class="text-gray-800 fs-2qx fw-bold text-center mb-5 d-none d-sm-block"
      >
        {{ title.startsWith('domains.') ? $t(title) : title }}
      </h1>
      <div
        v-test-id="'aside-description'"
        class="text-gray-600 fs-4 text-center fw-semibold w-650px d-none d-sm-block"
      >
        {{ description.startsWith('domains.') ? $t(description) : description }}
      </div>
      <div
        class="d-flex flex-center flex-wrap px-5"
        :class="{ 'p-8': device.includes('desktop'), 'pb-8': !device.includes('desktop') }"
      >
        <div
          v-if="footerLinks.length > 0"
          class="d-flex fw-semibold text-primary fs-base"
        >
          <template
            v-for="(link, i) in footerLinks"
            :key="i"
          >
            <a
              v-test-id="'aside-link-' + (i + 1)"
              :href="link.url"
              class="px-5"
              target="_blank"
            >
              {{ link.label.startsWith('domains.') ? $t(link.label) : link.label }}
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { deviceType } from '@shared-utils/index'

defineProps({
  title: {
    type: String,
    default: 'Personnel Management System'
  },
  description: {
    type: String,
    default: 'domains.authentication.sign-in.aside.description'
  },
  imageClass: {
    type: String,
    default: 'w-lg-650px'
  },
  darkImage: {
    type: String,
    default: '/img/sign-in.png'
  },
  lightImage: {
    type: String,
    default: '/img/sign-in.png'
  }
})

const device = deviceType()

const footerLinks = [
  {
    label: 'domains.authenticator.aside.link-titles.privacy',
    url: '/privacy-policy'
  },
  {
    label: 'domains.authenticator.aside.link-titles.terms',
    url: '/terms'
  },
  {
    label: 'domains.authenticator.aside.link-titles.support',
    url: '/support'
  }
]
</script>
