import type { StoreType } from '../types'

import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType): Promise<void> {
  const modules = ['departments', 'positions']
  const resource = useResource()

  await Promise.all([
    resource.fetchAdministrativeResource({ modules }),
    resource.fetchAdministrativeStaffsWorkloadResources()
  ])
}
