<template>
  <pms-card
    :title="t('domains.academic.manage.curriculum.term.information-title')"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'text'"
      :full-width="true"
    />
    <div
      v-else
      class="mb-5"
    >
      <h1>{{ `${t('common.department')}: ` + track?.program.department.title }}</h1>

      <h2>{{ `${t('common.program')}: ` + track?.program.code }}</h2>
      <h3>{{ `${t('common.track')}: ` + track?.title }}</h3>
      <h4>{{ `${t('common.term')}: ` + term }} </h4>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useStore } from '@/domains/AcademicCurricula/store'
import { useQueryHelper } from '../../AddCourseToTrackPage/composable/useQueryHelper'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoader } from '@/shared/composable'
const { t } = useI18n()

const route = useRoute()

const store = useStore()
const queryHelper = useQueryHelper()
const term = route.params.term
const { track } = storeToRefs(store)
const { isActive } = useLoader()
const loader = isActive('academic/manage/curriculum/tracks/:track')

onMounted(() => {
  if (queryHelper.params.trackId) {
    store.findTrack({ track: queryHelper.params.trackId })
  }
})
</script>
