<template>
  <div class="d-flex flex-row align-items-center">
    <p class="fs-7 fw-bold text-gray-700 me-3 m-0">
      {{ $t('common.year-terms') }}:
    </p>
    <select
      class="form-select w-md-175px w-300px"
      @change="setYearTerm($event)"
    >
      <option
        v-for="yearTerm in yearTerms"
        :key="(yearTerm.year + '' + yearTerm.term)"
        :value="yearTerm.year + '-' + yearTerm.term"
        :selected="(yearTerm.year === currentYearTerm.year && yearTerm.term === currentYearTerm.term)"
      >
        {{ getYearTermLabel(yearTerm, t) }}
      </option>
    </select>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { getYearTermLabel } from '@/shared/utils'
import { useI18n } from 'vue-i18n'
import { useToken } from '@/shared/composable'
import { useResource } from '@shared/stores'
import { useStore } from '../store'

const { t } = useI18n()
const resource = useResource()
const store = useStore()
const token = useToken()

const year = token.getYear()
const term = token.getTerm()
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const yearTerms = computed(() => {
  const hasCurrentYearTerm = resource.getAcademicCoursesResourceMyYearTerms.find((item) => item.year === year && item.term === term)

  return [
    ...(!hasCurrentYearTerm ? [{ year, term, id: 0 }] : []),
    ...resource.getAcademicCoursesResourceMyYearTerms
  ]
})

const setYearTerm = (event) => {
  const [year, term] = event.target.value.split('-')
  store.setCurrentYearTerm({ year, term, id: 0 })
  store.fetchMyCourses({ year, term, id: 0 })
}
</script>
