<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :filter="true"
    :full-width="true"
  />
  <pms-row
    v-else
    class="menu menu-rounded fs-5 menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-semibold"
  >
    <pms-grid
      v-for="(item, index) in myCourse ? menuItems : menuItems.filter(i => menu.includes(i.id))"
      :key="index"
      :col="4"
    >
      <div
        class="menu-item mb-1"
      >
        <span
          class="menu-link py-3"
          :class="{ 'active': item.routeName === routeName }"
          @click="handleRedirectToItemPage(item)"
        >
          <i
            v-if="item.iconType === 'bi'"
            v-styler="{ marginRight: '0.5rem' }"
            class="bi fw-bolder fs-4 px-2 me-1"
            :class="{
              'text-primary': item.routeName === routeName,
              [item.icon]: true
            }"
          />
          {{ isTranslatable(item.title) ? $t(item.title) : item.title }}
        </span>
      </div>
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { LocationQueryRaw, useRoute } from 'vue-router'
import { router } from '@/router'

import { useResource } from '@shared/stores'
import { isTranslatable } from '@shared/utils'

import { useSingleCourse } from '../store'
import { SingleCourseSidebarItem } from '../types'
import { menuItems } from '../values'
import { useLoader, useToggler } from '@shared/composable'

const menu: Array<string> = [
  'overview',
  'attendance',
  'syllabus',
  'exam-dates',
  'schedule',
  'consent-requests',
  'history'
]

const { isActive } = useLoader()
const { toggle, unToggle } = useToggler()
const resource = useResource()
const store = useSingleCourse()
const route = useRoute()

const loader = isActive('academic/courses/resources/single')
const routeName = computed(() => route.name)
const myCourse = computed(() => resource.getAcademicSingleCoursesResourceEnrolled)

const course = computed(() => store.getCourse)

const handleRedirectToItemPage = (item: SingleCourseSidebarItem) => {
  const { year, term, code, section } = course?.value || {}
  const query : LocationQueryRaw = {}

  if (item.sectionEnabled && section !== '') {
    query.section = section
  }
  query.year = year
  query.term = term
  query.code = code

  item.toggle
    ? toggle()
    : unToggle()

  router.push({
    path: `/academic/course/${item.routePath}`,
    query
  })
}

</script>
