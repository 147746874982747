import type { AcademicResourceResponse } from '@shared/swagger'

interface OrganizedDepartment {
  id: number;
  label: string;
  children?: OrganizedDepartment[];
}

const organizedDepartments = (departments: AcademicResourceResponse['departments']): OrganizedDepartment[] => {
  const departmentMap: { [id: number]: OrganizedDepartment } = {}
  const roots: OrganizedDepartment[] = []

  // Create a map for quick lookup by id
  departments.forEach(department => {
    departmentMap[department.id] = {
      id: department.id,
      label: department.title
    }
  })

  // Build the tree structure
  departments.forEach(department => {
    const parent = departmentMap[department.parent_id]
    const currentDepartment = departmentMap[department.id]

    if (parent) {
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(currentDepartment)
    } else {
      roots.push(currentDepartment)
    }
  })

  return roots
}

export default (data: AcademicResourceResponse | null): OrganizedDepartment[] => {
  const options: OrganizedDepartment[] = organizedDepartments(data?.departments ?? [])

  return options
}
