import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LayoutContainer',
  setup (_, { slots }) {
    return () => (
      <div class="container">
        { slots.default && slots.default() }
      </div>
    )
  }
})
