import type { StatusMessageResponse } from '@shared/types'
import type{ AcademicInformationExamDatesPutRequest } from '@shared/swagger'
import { putAcademicInformationExamDates } from '@shared/api'
import type { StoreType } from '../types'

export default async function putExamDates (this: StoreType, values: AcademicInformationExamDatesPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicInformationExamDates(values)
  ok && this.fetchExamDates(this.selectedDepartment)

  return {
    status: ok,
    message
  }
}
