<template>
  <pms-row>
    <pms-grid
      id="single-student-attendance"
      class="mt-4 mb-4"
    >
      <pms-card
        :title="'domains.academic.single-student.navigation.items.attendance'"
        :description="'domains.academic.single-student.navigation.items.attendance-description'"
        :options="{
          headers: { textAlignment: 'center' },
          body: { class: 'pt-0' }
        }"
      >
        <pms-smart-table
          :headers="attendanceTableHeader"
          :items="attendance?.attendance || []"
        >
          <template #item-no="{ idx }">
            <span class="fs-5"> {{ idx + 1 }}</span>
          </template>
          <template
            v-for="(header, index) in totalHeaders"
            :key="index"
            #[`${header.label}Header`]
          >
            <i
              class="fs-4 bi"
              :class="{
                'bi-check-circle text-primary': header.id === 1,
                'bi-p-circle text-info': header.id === 2,
                'bi-x-circle text-danger': header.id === 0
              }"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-kt-initialized="1"
              :data-bs-original-title="$t('domains.academic.single-student.attendance.statuses.' + header.label)"
            />
          </template>
          <template #item-action="{ row }">
            <div class="text-center">
              <button
                class="btn btn-sm btn-light-primary w-100px"
                :class="sideBarState && sideBarTitle == `${row.course_code} - ${row.title}` ? 'active': ''"
                @click="() => openDetails(row.course_code, `${row.course_code} - ${row.title}`)"
              >
                {{ $t('common.details') }}
                <pms-kt-icon
                  icon-name="arrow-right"
                  class="ms-2 m-0 p-0"
                />
              </button>
            </div>
          </template>
        </pms-smart-table>
      </pms-card>
    </pms-grid>
    <attendance-details :side-bar-data="sideBarData" />
  </pms-row>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { attendanceTableHeader, sideBarTitle, sideBarState } from '../values'
import { useSingleStudent } from '../../../store'

import AttendanceDetails from './AttendanceDetails.vue'

const store = useSingleStudent()

const studentId = computed(() => store.getStudentId)
const attendance = computed(() => store.getAttendance)
const currentYearTerm = computed(() => store.getCurrentYearTerm)
const sideBarData = computed(() => store.getAttendanceDetails)

const openDetails = async (courseCode: string, title: string) => {
  sideBarTitle.value = title
  sideBarState.value = !sideBarState.value
  await store.fetchAttendanceDetails(studentId.value, {
    course_code: courseCode,
    year: currentYearTerm.value.year,
    term: currentYearTerm.value.term
  })
}
const totalHeaders = [
  { id: 1, label: 'present' },
  { id: 0, label: 'absent' },
  { id: 2, label: 'permitted' }
]
</script>
