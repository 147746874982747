import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  UnknownResponse,
  AcademicManageSyllabusActivitiesPostRequest
} from '@shared/swagger'

export default async function postAcademicManageSyllabusActivities (body: AcademicManageSyllabusActivitiesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicManageSyllabusActivitiesPostRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/activities',
    body,
    loader: 'academic/manage/syllabus/activities'
  })
  return response
}
