<template>
  <WorkingCurricula />
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@domains/AcademicCurricula/store'
import WorkingCurricula from './ui/WorkingCurricula.vue'

const route = useRoute()
const store = useStore()

const trackId = route.params.trackId

onMounted(() => {
  if (!trackId) return
  store.fetchWorkingCurriculum(+trackId)
})

</script>
