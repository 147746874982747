import { AcademicManageCurriculumViewResponse } from '@shared/swagger'
import { TotalCourse } from '../types'

export default (courses: (AcademicManageCurriculumViewResponse['groups'][0])['items']): Array<TotalCourse> => {
  const credits: TotalCourse = {
    title: '',
    theoreticalCourse: 0,
    nonTheoreticalCourse: 0,
    sum: 0
  }
  const ects: TotalCourse = {
    title: '',
    theoreticalCourse: 0,
    nonTheoreticalCourse: 0,
    sum: 0
  }
  courses.forEach((course) => {
    credits.title = 'domains.editor.curricula.readonly.local-credits'
    credits.theoreticalCourse += course.is_theoretical ? course.credits : 0
    credits.nonTheoreticalCourse += !course.is_theoretical ? course.credits : 0
    credits.sum += course.credits

    ects.title = 'domains.editor.curricula.readonly.ects'
    ects.theoreticalCourse += course.is_theoretical ? course.ects : 0
    ects.nonTheoreticalCourse += !course.is_theoretical ? course.ects : 0
    ects.sum += course.ects
  })
  return [credits, ects]
}
