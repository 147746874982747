import { SmartTableHeader } from '@shared/types'

export const systemEventsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.start-date',
    field: 'start_date',
    slotCellName: 'startDate',
    sortable: true
  },
  {
    title: 'common.table.end-date',
    field: 'end_date',
    slotCellName: 'endDate',
    sortable: true
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status',
    sortable: true
  }
]

export const coursePermissionsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.course-code',
    field: 'course_code',
    slotCellName: 'courseCode',
    sortable: true
  },
  {
    title: 'common.table.assessment',
    field: 'assessment',
    sortable: true
  },
  {
    title: 'common.table.start-date',
    field: 'start_date',
    slotCellName: 'startDate',
    sortable: true
  },
  {
    title: 'common.table.end-date',
    field: 'end_date',
    slotCellName: 'endDate',
    sortable: true
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status',
    sortable: true
  }
]

export const individualPermissionsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.permitted',
    field: 'permitted',
    slotCellName: 'permitted'
  },
  {
    title: 'common.table.start-date',
    field: 'start_date',
    slotCellName: 'startDate',
    sortable: true
  },
  {
    title: 'common.table.end-date',
    field: 'end_date',
    slotCellName: 'endDate',
    sortable: true
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'status',
    sortable: true
  }
]
