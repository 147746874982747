import type { StoreType } from '../types'
import type {
  AcademicCoursesStudentsRequest
} from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { getAcademicCoursesStudents } from '@shared/api'

export default async function fetchStudents (this: StoreType, values: AcademicCoursesStudentsRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getAcademicCoursesStudents(values)

  if (ok) this.setStudents(data)
  return {
    status: ok,
    message
  }
}
