<template>
  <div
    id="certificate"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.experience.certificate.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div
        v-for="(item, index) in certificates"
        :key="index"
      >
        <div
          v-if="index !== 0"
          class="separator my-10"
        />
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-end justify-content-start gap-2 mb-2">
            <h3 class="m-0">
              {{ item.title }}
            </h3>
            <p class="text-muted fs-6 m-0">
              {{ getTitleById(item.organization_id, companies) }}
            </p>
          </div>
          <div class="d-flex align-items-center gap-2 justify-content-end">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.title)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </div>
        <p class="text-muted fs-5 m-0">
          {{ displayDate(item) }}
        </p>
        <a
          v-if="item.credential_url"
          :href="item.credential_url"
          class="btn btn-sm my-3 btn-outline btn-outline-primary"
          target="_blank"
        >
          {{ $t('common.learn-more') }}
        </a>
        <p v-if="item.credential_id">
          ID: {{ item.credential_id }}
        </p>
      </div>
      <pms-no-data v-if="certificates.length ===0" />
      <CertificateForm v-if="modifier && modifier.name === 'certificate'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'
import type { ProfileCertificatesGetResponse } from '@/shared/swagger'

import { getTitleById } from '../utils'
import { useProfile } from '../../../store'

import CertificateForm from './CertificateForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const companies = computed(() => resource.getProfileResource?.companies || [])
const certificates = computed(() => store.getCertificates)

const displayDate = (item: ProfileCertificatesGetResponse[0]): string => {
  let dateLine = ''
  const eDate = new Date(item.expired_date)

  dateLine += new Date(item.certified_date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  if (item.expired_date) {
    dateLine += ' - '
    dateLine += eDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  return dateLine
}

const setIdAction = (id: number) => {
  setModifier('certificate',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'certificate-new-title' : 'certificate-edit-title',
      description: id === -1 ? 'certificate-new-description' : 'certificate-edit-description'
    }
  )
  open({ name: 'experience-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.experience.are-you-sure',
    text: t('domains.profile.experience.certificate.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteCertificate(id)
    }
  })
}
</script>
