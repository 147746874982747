<template>
  <template
    v-for="(module,index) in modulesContent"
    :key="index"
  >
    <PermissionCheck
      v-if="store.module == module.title"
      :permission-code="module.permission_code"
    >
      <component
        :is="module.component"
        class="mb-16"
      />
    </PermissionCheck>
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from './../store'

import SyllabusBasicModule from './SyllabusBasicModule.vue'
import SyllabusDescription from './SyllabusDescription.vue'
import SyllabusAcademicSkills from './SyllabusAcademicSkills.vue'
import SyllabusSubjectSkills from './SyllabusSubjectSkills.vue'
import SyllabusWeeklyPlans from './SyllabusWeeklyPlans.vue'
import SyllabusLearningOutcomes from './SyllabusLearningOutcomes.vue'
import SyllabusTeachingMethods from './SyllabusTeachingMethods.vue'
import SyllabusReadingList from './SyllabusReadingList.vue'
import SyllabusAssessments from './SyllabusAssessments.vue'
import SyllabusWorkLoad from './SyllabusWorkLoad.vue'
import SyllabusInstructors from './SyllabusInstructors.vue'
import SyllabusPolicies from './SyllabusPolicies.vue'
import { permissionCodes } from '@/shared/values'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'

const store = useStore()

const modulesContent = computed(() => [
  {
    title: 'basic',
    component: SyllabusBasicModule,
    permission_code: permissionCodes.show_syllabus.code
  },
  {
    title: 'course-description',
    component: SyllabusDescription,
    permission_code: permissionCodes.show_description_syllabus.code
  },
  {
    title: 'instructors',
    component: SyllabusInstructors,
    permission_code: permissionCodes.show_lecturers_description_syllabus.code
  },
  {
    title: 'academic-skills',
    component: SyllabusAcademicSkills,
    permission_code: permissionCodes.show_contents_syllabus.code
  },
  {
    title: 'subject-skills',
    component: SyllabusSubjectSkills,
    permission_code: permissionCodes.show_contents_syllabus.code
  },
  {
    title: 'weekly-plans',
    component: SyllabusWeeklyPlans,
    permission_code: permissionCodes.show_weekly_course_plan_contents_syllabus.code
  },
  {
    title: 'learning-outcomes',
    component: SyllabusLearningOutcomes,
    permission_code: permissionCodes.show_learning_outcomes_contents_syllabus.code
  },
  {
    title: 'teaching-methods',
    component: SyllabusTeachingMethods,
    permission_code: permissionCodes.show_teaching_method_others_syllabus.code
  },
  {
    title: 'readings',
    component: SyllabusReadingList,
    permission_code: permissionCodes.show_materials_used_in_the_textbook_others_syllabus.code
  },
  {
    title: 'assessments',
    component: SyllabusAssessments,
    permission_code: permissionCodes.show_assessment_methods_and_criteria_others_syllabus.code
  },
  {
    title: 'work-load',
    component: SyllabusWorkLoad,
    permission_code: permissionCodes.show_syllabus.code
  },
  {
    title: 'course-policy',
    component: SyllabusPolicies,
    permission_code: permissionCodes.show_syllabus.code
  }
])
</script>
