import type { StoreType } from '../types'

import { getServiceMessageBroadcastResource } from '@shared/api'

export default async function fetchServiceMessageBroadcastResource (this: StoreType): Promise<void> {
  const { ok, data } = await getServiceMessageBroadcastResource()

  if (ok) {
    this.setServiceMessageBroadcastResource(data)
  }
}
