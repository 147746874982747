import { destroySectionManagementRequestsQuotasReserves } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteSentRequestReserve (this: StoreType, requestId: number, quotaId: number, reserveId: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroySectionManagementRequestsQuotasReserves(requestId, quotaId, reserveId)

  return {
    status: ok,
    message
  }
}
