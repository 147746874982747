import type { StoreType } from '../types'
import type { AcademicCoursesBellCurvePutRequest } from '@shared/swagger'

import { useAlert } from '@shared/composable'
import { putAcademicCoursesBellCurve } from '@shared/api'

export default async function putBellCurveEnabled (this: StoreType, params: AcademicCoursesBellCurvePutRequest): Promise<void> {
  const { error, success } = useAlert()
  const { ok, message } = await putAcademicCoursesBellCurve(params)
  ok
    ? Promise.all([
      this.fetchMySections(this.currentYearTerm),
      success({ text: message, isToast: true })
    ])
    : error({ text: message })
}
