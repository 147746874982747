import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicManageSyllabusResourcesResponse
} from '@shared/swagger'

export default async function getAcademicManageSyllabusResource (language): Promise<AxiosResponse<AcademicManageSyllabusResourcesResponse>> {
  const { get } = useRequests()
  const params = language !== undefined ? { language: language } : {}
  const response = await get<UnknownRequest, AcademicManageSyllabusResourcesResponse>({
    url: 'academic/manage/syllabus/resources',
    body: { params: params },
    loader: 'academic/manage/syllabus/resources'
  })

  return response
}
