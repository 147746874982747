import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicAdvisoryThesisStudentsGetRequest,
  AcademicAdvisoryThesisStudentsGetResponse
} from '@shared/swagger'

export default async function getAcademicAdvisoryStudents (params: AcademicAdvisoryThesisStudentsGetRequest): Promise<AxiosResponse<AcademicAdvisoryThesisStudentsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicAdvisoryThesisStudentsGetResponse>({
    url: 'academic/students/thesis-students',
    body: { params },
    loader: 'academic/students/thesis'
  })

  return response
}
