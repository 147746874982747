export function dayToDate (dateString:string|undefined) {
  if (dateString) {
    const parts = dateString.split(' ')
    const month = parts[0]
    const day = parseInt(parts[1].replace(',', ''), 10)
    const year = parseInt(parts[2], 10)

    // Convert month abbreviation to numeric representation
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const monthIndex = monthNames.indexOf(month)

    const dateObject = new Date(year, monthIndex, day + 1)

    return dateObject.toISOString().split('T')[0]
  }
}
