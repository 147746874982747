<template>
  <div
    v-if="alertConverter"
    :class="'alert d-flex align-items-center p-5 alert-' + alertConverter"
  >
    <div class="d-flex flex-column">
      <h4 class="mb-1 text-dark">
        {{ $t('phrases.need-your-attention') }}
      </h4>
      <span class="fs-6">{{ alertText }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'

const props = defineProps({
  alertText: {
    type: Object as PropType<string | undefined>,
    required: true
  },
  alertType: {
    type: Object as PropType<string | undefined>,
    required: true
  }
})

const alertConverter = computed(() => {
  switch (props.alertType) {
    case 'Success':
      return 'success'
    case 'Warning':
      return 'warning'
    case 'Error':
      return 'danger'
    default:
      return false
  }
})

</script>
