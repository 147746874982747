<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<template>
  <Teleport to="#locks-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
    <div
      v-if="lockIdsForModal.length > 0"
      class="mt-4"
    >
      <pms-loader v-if="loader" />
      <pms-smart-table
        v-else
        id="selected-students-table"
        :items="lockIdsForModal"
        :headers="selectedStudentsTable"
        :sortable="true"
        :options="{
          wrapper: {
            responsive: true
          }
        }"
      >
        <template #id-number="{ item }">
          {{ item.id }}
        </template>
        <template #full_name="{ item }">
          <div>
            {{ item.name }} {{ item.surname }}
          </div>
        </template>
      </pms-smart-table>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment/moment'
import { search } from '../utils'
import type { FormField } from '@shared/types'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { useAllStudents } from '../store'
import { selectedStudents, selectedRequestedBy, selectedStudentsTable, lockIdsForModal } from './../values/'

const { t } = useI18n()
const store = useAllStudents()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const loader = isActiveOneOf([])

const defaultTempValues = {
  start_date: '',
  end_date: '',
  is_active: true,
  locks: [] as number[]
}

const tempValues = (defaultTempValues)

const formFields = computed<Array<FormField>>(() => {
  let result: Array<FormField> = []

  if (modifier.value?.data?.action === 'edit') {
    result = [
      {
        label: {
          text: t('common.start_date'),
          class: 'fs-6 fw-semibold mb-2'
        },
        group: {
          class: 'mb-1'
        },
        name: 'start_date',
        type: 'date',
        value: tempValues.start_date,
        binding: {
          max: moment().format('YYYY-MM-DD')
        }
      },
      {
        label: {
          text: t('common.end_date'),
          class: 'fs-6 fw-semibold mb-2'
        },
        group: {
          class: 'mb-1'
        },
        name: 'end_date',
        type: 'date',
        value: tempValues.end_date,
        binding: {
          max: moment().format('YYYY-MM-DD')
        }
      }
    ]
  }
  if (modifier.value?.data?.action === 'toggle') {
    result = [
      {
        label: {
          text: `${t('common.search-field.locked')}:`,
          class: 'mb-1 mt-2'
        },
        name: 'is_active',
        type: 'switch',
        class: 'mt-2',
        cover: {
          class: 'flex-row-reverse gap-4 justify-content-end'
        },
        value: tempValues.is_active
      }
    ]
  }
  return result
})

const onSubmit = async ({ values }) => {
  if (modifier.value?.data?.lock) {
    values.locks = Array.isArray(modifier.value.data.lock)
      ? [...modifier.value.data.lock]
      : []
  } else if (selectedStudents.value && Array.isArray(selectedStudents.value)) {
    values.locks = selectedStudents.value.map(student => student.lock_id)
  }

  values.requester_department_code = selectedRequestedBy[1]
  const { status, message } = modifier.value?.data?.action === 'toggle'
    ? await store.toggleLocks({ ...values })
    : await store.editLocks({ ...values })

  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'locks-modal' })
    selectedStudents.value = []
    unsetModifier()
    search()
  } else {
    error({ text: message })
  }
}

const closeModal = () => {
  close({ name: 'locks-modal' })
  unsetModifier()
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.lock) {
      show()
    }
  }, { immediate: true }
)
</script>
