import type { StoreType } from '../types'
import { AcademicSingleStudentAttendanceDetailsGetRequest } from '@shared/swagger'
import { getAcademicSingleStudentGradesDetails } from '@shared/api'

export default async function fetchGradesDetails (this: StoreType, id: string, params: AcademicSingleStudentAttendanceDetailsGetRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleStudentGradesDetails(id, params)

  if (ok) {
    this.setGradesDetails(data)
  }
}
