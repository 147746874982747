import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { deleteKnowledgeManageCategory } from '@/shared/api'

export default async function deleteCategory (this: StoreType, uuid: string): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteKnowledgeManageCategory(uuid)

  return {
    status: ok,
    message: message
  }
}
