import type { StoreType } from '../types'
import { getScheduleManagementSingleSection } from '@shared/api'

export default async function fetchSingleSection (this: StoreType, section: string): Promise<void> {
  const { data, ok } = await getScheduleManagementSingleSection(section)

  if (ok) {
    this.setSingleSection(data)
  }
}
