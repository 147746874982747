<template>
  <pms-card :header="false">
    <div>
      <single-post-component
        :post-new="newPost"
      />
      <div class="form-group mt-10 col-12 d-flex justify-content-between gap-2">
        <pms-button
          :disabled="store.isLoading"
          size="lg"
          @click="previousStep"
        >
          {{ $t('common.previous') }}
        </pms-button>
        <pms-button
          :disabled="store.isLoading"
          size="lg"
          :text="$t('common.submit')"
          @click="handleSubmit"
        />
      </div>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useAlert } from '@shared/composable'
import { KnowledgeManagePostsPostRequest } from '@shared/swagger'

import { useKnowledgeManagePost } from '../../../store'
import SinglePostComponent from '../../../ui/SinglePostComponent.vue'

import { newPost } from '../values'

const { dialog } = useAlert()
const router = useRouter()
const store = useKnowledgeManagePost()

const { error, success } = useAlert()

function previousStep () {
  store.setStepper(2)
}

async function handleSubmit () {
  dialog(
    {
      text: 'knowledge.manage.post-form.title.confirmation-to-submit',
      onConfirm: async () => {
        if (validOutput(newPost.value)) {
          const { status, message } = await store.createPost(newPost.value)

          if (status) {
            success({ text: message, isToast: true })
            router.push({
              path: '/knowledge-base/manage/articles'
            })
          } else {
            error({ text: message })
          }
          return
        }
        error({ text: 'incorrect data' })
      }
    }
  )
}

function validOutput (output: KnowledgeManagePostsPostRequest): boolean {
  const excludedProperties = ['details', 'tags', 'meta']
  for (const [key, value] of Object.entries(output)) {
    if (excludedProperties.includes(key)) {
      continue
    }
    if (Array.isArray(value) && value.length === 0) {
      return false
    }
    if (value === '' || value === undefined || value === null) {
      return false
    }
  }
  return true
}
</script>
