import { destroyProfileExperiences } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import type { StoreType } from '../types'

export default async function deleteExperience (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyProfileExperiences(id)

  if (ok) await this.fetchExperiences()

  return {
    status: ok,
    message
  }
}
