import { SmartTableHeader } from '@/shared/types'

export const headers: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.full-name',
    field: 'fullName',
    slotCellName: 'fullName',
    sortable: true
  },
  {
    title: 'common.table.department-position',
    field: 'departmentPosition',
    slotCellName: 'departmentPosition'
  },
  {
    title: 'common.table.staff',
    field: 'staff'
  },
  {
    title: 'common.table.working-status',
    field: 'staff_status',
    slotCellName: 'staffStatus',
    sortable: true
  },
  {
    title: 'common.table.working-email',
    field: 'outer_email',
    slotCellName: 'outerEmail',
    sortable: true
  },
  {
    title: 'common.table.inner-phone',
    field: 'inner_phone',
    slotCellName: 'innerPhone',
    sortable: true
  }
]

export const workloadHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.no',
    field: 'no',
    slotCellName: 'no',
    sortable: true
  },
  {
    title: 'common.instructor',
    field: 'instructor',
    slotCellName: 'instructor',
    sortable: true
  },
  {
    title: 'common.course',
    field: 'course',
    slotCellName: 'course'
  },
  {
    title: 'common.section',
    field: 'section',
    slotCellName: 'section'
  },
  {
    title: 'common.student-count',
    field: 'student_count',
    slotCellName: 'student_count'
  },
  {
    title: 'common.ect',
    field: 'ect',
    slotCellName: 'ect'
  },
  {
    title: 'common.credit',
    field: 'credit',
    slotCellName: 'credit'
  },
  {
    title: 'common.course-count',
    field: 'course_count',
    slotCellName: 'course_count'
  },
  {
    title: 'common.total-students',
    field: 'total_students',
    slotCellName: 'total_students'
  },
  {
    title: 'common.total-credits',
    field: 'total_credits',
    slotCellName: 'total_credits'
  },
  {
    title: 'common.thesis-advisor',
    field: 'thesis_advisor',
    slotCellName: 'thesis_advisor'
  },
  {
    title: 'common.hours-per-week',
    field: 'hours_per_week',
    slotCellName: 'hours_per_week'
  },
  {
    title: 'common.total-hour',
    field: 'total_hour',
    slotCellName: 'total_hour'
  }
]
