<template>
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <div class="d-flex flex-column flex-center text-center p-10">
      <pms-card
        :header="false"
        class="w-lg-650px py-5"
      >
        <slot />
      </pms-card>
    </div>
  </div>
</template>
