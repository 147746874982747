import { EventAttributes, createEvents } from 'ics'

import { dateFormatter, typeMapperFromArray } from '@shared/utils'
import { IcsEvent } from '../types'

export const toIcs = (
  events: Array<IcsEvent>,
  options: {
      startDate?: string;
      dateFormat?: string;
      directURL: boolean;
      filename?: string;
    }
) => {
  const applyingOptions = {
    ...options,
    startDate: options?.startDate || new Date().toISOString().slice(0, 10),
    dateFormat: options?.dateFormat || 'YYYY-MM-DD',
    directURL: options?.directURL && true,
    filename: options?.filename || 'Schedule.ics'
  }

  const formattedEvents: Array<EventAttributes> = typeMapperFromArray<IcsEvent, EventAttributes>(events, [
    {
      from: 'start',
      to: 'start',
      apply: (start) => {
        return dateFormatter(`${applyingOptions.startDate} ${start}`, {
          inputFormat: applyingOptions.dateFormat + ' hh:mm:ss',
          outputFormat: 'YYYY-M-D-H-m'
        }).split('-').map(Number)
      }
    },
    {
      from: 'end',
      to: 'end',
      apply: (end: string) => dateFormatter(`${applyingOptions.startDate} ${end}`, {
        inputFormat: applyingOptions.dateFormat + ' hh:mm:ss',
        outputFormat: 'YYYY-M-D-H-m'
      }).split('-').map(Number)
    },
    { from: 'title', to: 'title' },
    { from: 'description', to: 'description', apply: (data) => data || '' },
    { from: 'location', to: 'location' },
    { from: 'recurrence', to: 'recurrenceRule', apply: (weekDay) => `FREQ=WEEKLY;BYDAY=${weekDay[weekDay]};INTERVAL=1;COUNT=15` },
    { from: 'htmlContent', to: 'htmlContent', apply: (data) => data || '' }
  ])

  const { value } = createEvents(formattedEvents)

  if (!value) return

  const file = new File([value], applyingOptions.filename, { type: 'plain/text' })
  const url = URL.createObjectURL(file)
  if (applyingOptions.directURL) {
    return url
  } else {
    const link = document.createElement('a')
    link.href = url
    link.download = applyingOptions.filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
