import type { SmartTableHeader } from '@shared/types'

export const tableHeadersArticle: Array<SmartTableHeader> = [
  {
    title: 'domains.profile.education.article.table.author-title',
    field: 'author',
    slotCellName: 'author',
    headerClass: 'min-w-150px'
  },
  {
    title: 'domains.profile.education.publications.articles.table.journal',
    field: 'journal',
    slotCellName: 'journal',
    headerClass: 'min-w-200px'
  },
  {
    title: 'domains.profile.education.publications.articles.table.country',
    field: 'country',
    slotCellName: 'country',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.date',
    field: 'date',
    slotCellName: 'date',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
export const tableHeadersBook: Array<SmartTableHeader> = [
  {
    title: 'domains.profile.education.books.table.author-title',
    field: 'author',
    slotCellName: 'author',
    headerClass: 'min-w-150px'
  },
  {
    title: 'domains.profile.education.books.table.pages-and-type',
    field: 'type',
    slotCellName: 'type'
  },
  {
    title: 'domains.profile.education.books.table.country-and-publisher',
    field: 'publisher',
    slotCellName: 'publisher',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.date',
    field: 'date',
    slotCellName: 'date',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
export const tableHeadersConference: Array<SmartTableHeader> = [
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title',
    headerClass: 'min-w-150px'
  },
  {
    title: 'common.table.authors',
    field: 'authors',
    slotCellName: 'authors',
    headerClass: 'min-w-150px'
  },
  {
    title: 'common.table.date',
    field: 'date',
    slotCellName: 'date',
    headerClass: 'min-w-100px'
  },
  {
    title: 'common.table.action',
    field: 'action',
    slotCellName: 'action',
    headerClass: 'w-60px w-md-150px'
  }
]
