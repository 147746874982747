import type { StatusMessageResponse } from '@shared/types'
import type { ProfileExperiencesPostRequest } from '@shared/swagger'
import { putProfileExperiences } from '@shared/api'

import type { StoreType } from '../types'

export default async function editExperience (this:StoreType, id: number, values: ProfileExperiencesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileExperiences(id, values)

  return {
    status: ok,
    message: message
  }
}
