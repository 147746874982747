import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  AcademicManageSyllabusActivitiesPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusActivities (id: number, body: AcademicManageSyllabusActivitiesPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusActivitiesPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/activities/${id}`,
    body,
    loader: 'academic/manage/syllabus/activities'
  })
  return response
}
