import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicManageCurriculumDepartmentsRequest, AcademicManageCurriculumDepartmentsResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicManageCurriculumDepartments (params: AcademicManageCurriculumDepartmentsRequest): Promise<AxiosResponse<AcademicManageCurriculumDepartmentsResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicManageCurriculumDepartmentsResponse>({
    url: 'academic/manage/curriculum/departments',
    body: { params },
    loader: 'academic/manage/curriculum/departments'
  })

  return response
}
