<template>
  <vue-resizable
    id="schedule-widget"
    class="resizable-widget card"
    :width="widgetSize.width"
    min-width="300"
    :height="widgetSize.height"
    min-height="200"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5 flex-nowrap">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-test-id="'title'"
          class="card-label fw-bolder text-dark"
        >{{
          $t('domains.dashboard.widgets.schedule.title') }}</span>
        <span
          v-test-id="'description'"
          class="text-muted mt-1 fw-bold fs-7"
        >{{
          $t('domains.dashboard.widgets.schedule.description') }}</span>
      </h3>
      <template v-if="refresh_token == undefined">
        <div class="ms-auto">
          <span
            class="d-flex align-items-center border border-secondary rounded-3 py-2 px-3 cursor-pointer"
            @click="login"
          >
            <span class="me-2">
              <i class="bi bi-google text-success fs-3" />
            </span>
            <div>
              {{ $t('domains.dashboard.login_with_google') }}
            </div>
          </span>
        </div>
      </template>
    </div>
    <div
      class="card-body pt-4 overflow-auto"
      :style="{ height: `calc(100% - 90px)` }"
    >
      <template
        v-for="(subject, index) in schedule"
        :key="index"
      >
        <div
          v-test-id="'schedule-item'"
          class="d-flex"
          :class="{
            'mb-8': index !== schedule.length - 1,
          }"
        >
          <div
            class="bullet bullet-vertical height-unset"
            :class="subject.is_google ? 'bg-success' : 'bg-primary'"
          />
          <div class="flex-grow-1 px-4">
            <div class="text-gray-800 fw-bolder fs-6">
              {{ subject.start_time + ' - ' + subject.end_time }}
            </div>
            <div class="text-gray-800 fw-bolder fs-6">
              {{ subject.title }}
            </div>
            <span
              v-if="subject.room"
              class="text-muted fw-bold d-block"
            >{{
              $t('domains.dashboard.widgets.schedule.room') + ' ' + subject.room }}</span>
          </div>
          <span
            v-if="subject.is_google"
            class="me-2 d-flex align-items-center"
          >
            <i class="bi bi-google text-success fs-3" />
          </span>
        </div>
      </template>
      <div
        v-if="schedule.length === 0"
        class="text-center mt-4 mb-4"
      >
        <span v-test-id="'no-data'">
          {{ $t('domains.dashboard.widgets.schedule.no-schedule') }}
        </span>
      </div>
    </div>
  </vue-resizable>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useDashboard } from '../store'
import { formatSchedules, onResize, getSavedWidgetSize } from '../utils'
import VueResizable from 'vue-resizable'
import { deviceType } from '@/shared/utils'
import { storeToRefs } from 'pinia'
import { GoogleCalendarEventsResponse, ScheduleType } from '../types'
import { useAlert } from '@/shared/composable'

const store = useDashboard()
const device = deviceType()
const { refresh_token, schedules } = storeToRefs(store)

const schedule = ref<ScheduleType>(formatSchedules(schedules.value))
sortSchedule()

const { error } = useAlert()

function login () {
  try {
    window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.APP_GOOGLE_CLIENT_ID ?? '',
      scope: 'https://www.googleapis.com/auth/calendar.events.readonly',
      callback: async (response) => {
        const tokens = await exchangeCodeForTokens({ access_code: response.code })
        if (tokens) {
          getEvents(tokens.access_token)
          store.putCalendarEventsToken({ refresh_token: tokens.refresh_token })
        }
      }
    }).requestCode()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    error({ text: e.message, isToast: true })
    console.error(e)
  }
}

function exchangeCodeForTokens (token:{access_code?: string, refresh_token?:string}):Promise<{access_token: string, refresh_token:string}|undefined> {
  const params = new URLSearchParams({
    client_id: process.env.APP_GOOGLE_CLIENT_ID ?? '',
    client_secret: process.env.APP_GOOGLE_CLIENT_SECRET ?? ''
  })
  if (token.access_code) {
    params.append('code', token.access_code)
    params.append('grant_type', 'authorization_code')
    params.append('redirect_uri', process.env.APP_GOOGLE_REDIRECT_URI ?? '')
    params.append('access_type', 'offline')
  } else if (token.refresh_token) {
    params.append('refresh_token', token.refresh_token)
    params.append('grant_type', 'refresh_token')
  }

  return fetch('https://oauth2.googleapis.com/token', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: params
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(`Error ${response.status}: ${errorData.error}`)
        })
      }
      return response.json()
    })
    .then(data => data)
    .catch(() => {
      refresh_token.value = undefined
    })
}

function sortSchedule () {
  schedule.value = schedule.value.sort((a, b) => a.start_time < b.start_time ? -1 : 1)
}

async function getEvents (access_token: string) {
  // get calendar events
  let google_calendar_response: GoogleCalendarEventsResponse

  try {
    // set google apis params
    function getTodayDateRange (): { timeMin: string; timeMax: string } {
      const now = new Date()
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
      return { timeMin: startOfDay.toISOString(), timeMax: endOfDay.toISOString() }
    }
    const { timeMin, timeMax } = getTodayDateRange()
    const url = new URL('https://www.googleapis.com/calendar/v3/calendars/primary/events')
    url.searchParams.append('timeMin', timeMin)
    url.searchParams.append('timeMax', timeMax)
    url.searchParams.append('singleEvents', 'true')

    google_calendar_response = await fetch(url.toString(), {
      method: 'GET',
      headers: { Authorization: `Bearer ${access_token}` }
    })
      .then(response => response.json())
      .then(data => data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    error({ text: e.message, isToast: true })
    console.error('Error fetching events:', e)
  }

  // helping functions
  function getTime (dateTime?: string) {
    if (dateTime) {
      try {
        const times = dateTime.split('T')[1].split(':')
        return `${times[0]}:${times[1]}`
      } catch (e) {
        return 'All day'
      }
    } else {
      return ''
    }
  }

  // filter events - get events that are scheduled for today
  if (google_calendar_response && google_calendar_response.items) {
    const events = google_calendar_response?.items.filter(item => item.eventType === 'default')
    // add events to the schedule
    events?.forEach(event => {
      schedule.value.push({
        week_day: new Date().getDay(),
        title: event.summary,
        end_time: getTime(event.end?.dateTime ?? event.end?.date),
        start_time: getTime(event.start?.dateTime ?? event.start?.date),
        room: event.location,
        is_google: true
      })
    })
    // sort schedule
    sortSchedule()
  }
}

onMounted(async () => {
  // get access token. if it exists then get google events
  // if it doesn't exists then login button should appear
  const refresh_token = await store.getCalendarEventsToken()
  if (refresh_token) {
    const tokens = await exchangeCodeForTokens({ refresh_token })
    if (tokens) { getEvents(tokens.access_token) }
  }

  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})

const widgetId = 'schedule-widget'

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '31%', height: '400px' })
</script>

<style scoped>
.height-unset {
  height: unset;
}
</style>
