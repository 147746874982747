import { createPage } from '@shared-utils/storybook'

import { useProfile } from '../../../store'
import { useUser } from '@shared/stores'

import ProfileOverview from '../OverviewPage.vue'

const Template = createPage({
  layout: 'default',
  component: ProfileOverview,
  setup () {
    const store = useProfile()
    const user = useUser()
    user.authMe()

    store.$reset()
    store.bootstrap('overview')
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Profile/OverviewPage',
  component: ProfileOverview,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }

  }
}
