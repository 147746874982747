<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="mt-3 col-12 d-flex justify-content-end gap-2">
          <pms-button
            type="submit"
            :text="$t('common.add')"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { useTeleportModal } from '@shared/composable'
import { FormField } from '@shared/types'
import { academicSyllabusTeachingMethodsToOptions } from './../utils'
import { useStore } from './../store'
import { useI18n } from 'vue-i18n'
import { useResource } from '@shared/stores'

const { show, modifier, close, unsetModifier } = useTeleportModal()
const { t } = useI18n()
const resource = useResource()
const store = useStore()

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('common.form.type'),
      class: 'mb-1 mt-2'
    },
    name: 'type_id',
    type: 'select',
    options: academicSyllabusTeachingMethodsToOptions(resource.getAcademicManageSyllabusResourceTeachings),
    value: null
  }
])

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue) {
      show()
    }
  },
  { immediate: true }
)

function onSubmit ({ values }) {
  store.addTeachingMethod({
    type_id: values.type_id
  })
  close({ name: 'syllabus-form-content-modal' })
  unsetModifier()
}
</script>
