import type { StatusMessageResponse } from '@shared/types'
import type{ ServiceManageLocksPostRequest } from '@shared/swagger'
import { postServiceManageLocks } from '@shared/api'

import type { StoreType } from '../types'

export default async function addLocks (this: StoreType, values: ServiceManageLocksPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postServiceManageLocks(values)

  return {
    status: ok,
    message
  }
}
