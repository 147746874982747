import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicReportsGetRequest, AcademicReportsGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params:AcademicReportsGetRequest): Promise<AxiosResponse<AcademicReportsGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicReportsGetResponse>({
    url: 'academic/information/reports',
    body: { params },
    loader: 'academic/information/reports'
  })

  return response
}
