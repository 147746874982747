import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  KnowledgeManageCategoriesGetRequest,
  KnowledgeManageCategoriesGetResponse
} from '@shared/swagger'

export default async function getKnowledgeManageCategories (params: KnowledgeManageCategoriesGetRequest): Promise<AxiosResponse<KnowledgeManageCategoriesGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeManageCategoriesGetResponse>({
    url: 'knowledge/manage/categories',
    body: { params: params },
    loader: 'knowledge/manage/categories'
  })

  return response
}
