import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileEducationSupervisionsPostRequest } from '@shared/swagger'

export default async function putProfileEducationSupervisions (id: number, values: ProfileEducationSupervisionsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ProfileEducationSupervisionsPostRequest, UnknownResponse>({
    url: `profile/education/supervisions/${id}`,
    body: values,
    loader: 'profile/education/supervisions-edit'
  })

  return response
}
