import { useResource } from '@/shared/stores'
import { classOptions, specialityOptions } from '../../values'
import type { StoreType } from '../types'
import { usePagination } from '@shared/composable'
import { storeToRefs } from 'pinia'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()
  const { academicStudentsMyResources } = storeToRefs(resource)
  const { perPage, currentPage } = usePagination()

  await (this.mode === 'advisory' ? this.fetchMyStudents : this.fetchThesisStudents)({
    per_page: perPage.value,
    offset: currentPage.value
  })
  await resource.fetchAcademicStudentsMyResourses()

  if (academicStudentsMyResources.value) {
    classOptions.value = [...academicStudentsMyResources.value.classes]
    specialityOptions.value = [...academicStudentsMyResources.value.specialities.map(item => item.code + ' - ' + item.language)]
  }
}
