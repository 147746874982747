<template>
  <pms-container>
    <pms-row class="mt-6 mb-6">
      <pms-grid :col="12">
        <pms-page-header
          :title="$t('knowledge.manage.post-form.title')"
          :description="$t('knowledge.manage.post-form.description')"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex gap-3 justify-content-between mb-2 mt-md-0">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>
    <pms-row>
      <pms-grid
        v-if="!isEditComponent"
        :col="3"
      >
        <pms-card
          :header="false"
          :options="{ body: { class: 'p-1 py-4'} }"
        >
          <pms-stepper
            id="stepper"
            :step="stepper"
            :steps="stepsItems"
            direction="vertical"
          />
        </pms-card>
      </pms-grid>
      <pms-tab
        v-else
        :id="'form-template'"
        v-model:active="tab"
        :tabs-id="'form-tab'"
        class="mw-100 mt-2"
        :tabs="tabs"
        :all="false"
      >
        <template #details>
          <PostFormStep1 :is-edit-content="true" />
        </template>
        <template #contents>
          <PostFormStep2 :is-edit-content="true" />
        </template>
      </pms-tab>
      <pms-grid
        v-if="!isEditComponent"
        id="stepper-form"
        :col="9"
        class="order-1"
      >
        <keep-alive>
          <component :is="stepComponent" />
        </keep-alive>
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useDriver, useTour } from '@shared/composable'

import type { TabElement } from '@/shared/types'

import { stepsItems, tour, quickTourSteps, quickTourStepsWithStepper } from './values'
import { useKnowledgeManagePost } from '../../store'

import PostFormStep1 from './ui/PostFormStep1.vue'
import PostFormStep2 from './ui/PostFormStep2.vue'
import PostFormStep3 from './ui/PostFormStep3.vue'

const { t } = useI18n()
const route = useRoute()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const store = useKnowledgeManagePost()
const stepper = computed(() => store.getStepper)

const isEditComponent = computed(() => route.path.includes('edit'))

const tab = ref<string>('')

const tabs: Array<TabElement> = [
  {
    id: 'details',
    label: t('knowledge.manage.post-form.details')
  },
  {
    id: 'contents',
    label: t('knowledge.manage.post-form.contents')
  }
]

const stepComponent = computed(() => {
  switch (stepper.value) {
    case 1:
      return PostFormStep1
    case 2:
      return PostFormStep2
    case 3:
      return PostFormStep3
    default:
      return PostFormStep1
  }
})

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(isEditComponent.value ? await quickTourSteps() : await quickTourStepsWithStepper())
})
</script>
