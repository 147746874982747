import type { StatusMessageResponse } from '@shared/types'
import type { AcademicSingleStudentPutOpinionRequest } from '@shared/swagger'
import { putAcademicSingleStudentAdvisorOpinion } from '@shared/api'

import type { StoreType } from '../types'

export default async function putAdvisorOpinion (this:StoreType, student: string, opinion: string, values: AcademicSingleStudentPutOpinionRequest['content']): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicSingleStudentAdvisorOpinion(student, opinion, values)
  ok && this.fetchInformation(student)

  return {
    status: ok,
    message: message
  }
}
