import { getProfileSkillsHard } from '@shared/api'

import type { StoreType } from '../types'

export default async function fetchSkills (this:StoreType): Promise<void> {
  const { ok, data } = await getProfileSkillsHard()

  if (ok) {
    this.setSkills(data)
  }
}
