import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileSettingsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileSettings (): Promise<AxiosResponse<ProfileSettingsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileSettingsGetResponse>({
    url: 'profile/settings',
    body: {},
    loader: 'profile/settings'
  })
  return response
}
