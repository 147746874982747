<template>
  <pms-page-shimmer
    v-if="searchLoader"
    :shimmer="`table`"
    :full-width="true"
  />
  <pms-row v-else>
    <pms-grid
      :col="12"
      class="mb-4"
    >
      <pms-card
        title=""
        :options="{
          body: {
            class: 'mt-0 pt-0'
          }
        }"
      >
        <template #toolbar>
          <div class="w-100 d-flex justify-content-end">
            <announcements-search-by-year />
          </div>
        </template>

        <pms-smart-table
          id="announcements-table"
          :items="announcements"
          :headers="tableHeaders"
          :options="{
            wrapper: {
              responsive: false
            }
          }"
        >
          <template #no="{idx}">
            {{ idx + 1 }}
          </template>
          <template
            #publish="{item}"
          >
            <single-announcement-publish-form :announcement="item" />
          </template>
          <template #start-date="{ item }">
            <span
              class="cursor-pointer"
            >
              {{ dateFormatter(item.start_date) }}
            </span>
          </template>
          <template #end-date="{ item }">
            <span
              class="cursor-pointer"
            >
              {{ dateFormatter(item.end_date) }}
            </span>
          </template>

          <template #action="{item}">
            <pms-dropdown
              :group-class="'w-100px'"
              :icon-placement="'end'"
              :placement="'end'"
            >
              <button
                class="btn btn-light-primary btn-sm mb-2 fw-bold w-100px"
                @click="router.push({ name: 'EditAnnouncementPage', params: { id: item.id } })"
              >
                <pms-kt-icon
                  :icon-name="'pencil'"
                  class="fw-bold  p-0"
                />
                {{ $t('common.edit') }}
              </button>

              <pms-button
                class="w-100px"
                size="sm"
                variant="tonal"
                color="danger"
                :disabled="deleteLoader"
                :loading="deleteLoader"
                @click="handleDeleteAnnouncement(item.id)"
              >
                <pms-kt-icon
                  :icon-name="'trash'"
                  class="fw-bold  p-0"
                />
                {{ $t('common.delete') }}
              </pms-button>
            </pms-dropdown>
          </template>
        </pms-smart-table>
      </pms-card>
    </pms-grid>
  </pms-row>
</template>

<script setup lang="ts">
import { useAlert, useLoader } from '@shared/composable'

import { useAnnouncements } from './../store'
import { tempValues, tableHeaders } from './../values'
import { storeToRefs } from 'pinia'

import AnnouncementsSearchByYear from './AnnouncementsSearchByYear.vue'
import SingleAnnouncementPublishForm from './SingleAnnouncementPublishForm.vue'
import { watch } from 'vue'
import { dateFormatter } from '@/shared/utils'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const { isActive, isActiveOneOf } = useLoader()
const router = useRouter()
const { dialog, success, error } = useAlert()
const store = useAnnouncements()
const { announcements } = storeToRefs(store)
const searchLoader = isActiveOneOf(['service/manage/announcements', 'service/manage/announcements/single/publish'])
const deleteLoader = isActive('service/manage/announcements/single/delete')

const handleDeleteAnnouncement = async (announcementId: number) => {
  dialog({
    title: 'common.are-you-sure',
    text: t('domains.service.announcements.delete-announcement'),
    onConfirm: async () => {
      const { status, message } = await store.deleteExistingAnnouncement(announcementId)
      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    }
  })
}

watch(() => tempValues.value, async (newValue, oldValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    await store.fetchAnnouncements(tempValues.value.year.getFullYear())
  }
}, { deep: true })

</script>

<style lang="scss" scoped>
.accordion-item{
  &:last-child{
    border: none;
  }
}
</style>
