import type { StoreType } from '../types'
import type { AcademicInformationResourcesRequest } from '@shared/swagger'

import { getAcademicInformationResource } from '@shared/api'

export default async function fetchAcademicInformationResource (this: StoreType, body: AcademicInformationResourcesRequest): Promise<void> {
  const { ok, data } = await getAcademicInformationResource(body)

  if (ok) {
    this.setAcademicInformationResource(data)
  }
}
