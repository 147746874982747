<template>
  <Teleport to="#publication-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-end mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormField } from '@shared/types'
import type { ProfileEducationConferencesGetResponse } from '@shared/swagger'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { months, yearSuggestions } from '../values'
import { getTitleById, monthToOptionsType, toOptionsType } from '../utils'
import { getIdByTitle } from '../../Experience/utils'

const { t } = useI18n()
const { isActive } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()
const loader = isActive('profile/skills/interests-edit')

const selectedConference = ref('')

const countriesSuggestions = computed(() => resource.getProfileResource?.countries || [])
const conferenceSuggestions = computed(() => resource.getProfileResource?.conferences || [])
const conferences = computed(() => store.getConferences)

const defaultTempValues: ProfileEducationConferencesGetResponse[0] = {
  id: -1,
  title: '',
  conference_id: 0,
  authors: '',
  pages: '',
  url: '',
  country: -1,
  publishedMonth: -1,
  publishedYear: -1
}

const tempValues = ref<ProfileEducationConferencesGetResponse[0]>(objectDeepCopy(defaultTempValues))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: t('domains.profile.publications.conference.title-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'title',
    type: 'text',
    value: tempValues.value.title,
    placeholder: t('domains.profile.publications.conference.title-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.country-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'country',
    type: 'select',
    value: tempValues.value.country,
    required: true,
    options: toOptionsType(countriesSuggestions.value),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.conference-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'conference_id',
    type: 'autocomplete',
    required: true,
    value: selectedConference.value,
    suggestions: {
      itemField: 'title',
      items: conferenceSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.publications.conference.conference-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.authors-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'authors',
    type: 'text',
    value: tempValues.value.authors,
    placeholder: t('domains.profile.publications.conference.authors-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.pages-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'pages',
    type: 'number',
    value: Number(tempValues.value.pages),
    placeholder: t('domains.profile.publications.conference.pages-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'number',
        'required'
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.url-field'),
      class: 'fs-6 fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'url',
    type: 'url',
    value: tempValues.value.url,
    placeholder: t('domains.profile.publications.conference.url-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  },

  {
    label: {
      text: t('domains.profile.publications.article.published-month-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_month',
    type: 'select',
    value: tempValues.value.publishedMonth,
    required: true,
    options: monthToOptionsType(months),
    validator: {
      schema: 'yup',
      rules: [
        'mixed',
        {
          func: 'required',
          args: ['An option must be selected']
        },
        {
          func: 'test',
          args: ['Validation: is null option', 'An option must be selected', (value) => value !== null && value !== 'common.not-selected']
        }
      ]
    }
  },
  {
    label: {
      text: t('domains.profile.publications.conference.published-year-field'),
      class: 'fs-6 required fw-semibold mb-2'
    },
    group: {
      class: 'mb-4'
    },
    name: 'published_year',
    type: 'autocomplete',
    value: tempValues.value.publishedYear === -1 ? '' : tempValues.value.publishedYear,
    required: true,
    suggestions: {
      itemField: 'year',
      items: yearSuggestions.value,
      class: ''
    },
    placeholder: t('domains.profile.publications.conference.published-year-placeholder'),
    validator: {
      schema: 'yup',
      rules: [
        'string',
        'required'
      ]
    }
  }

])

const closeModal = () => {
  close({ name: 'publication-modal' })
  unsetModifier()
}

const onHandleChange = ({ name, newValue }) => {
  if (name === 'conference_id') {
    selectedConference.value = newValue
  }
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  values.conference_id = getIdByTitle(selectedConference.value, conferenceSuggestions.value)
  values.type = modifier.value?.data?.type
  if (!values.published_year) {
    error({ text: t('domains.profile.publications.conference.published-year-field-error') })
    return
  }
  if (!values.published_month) {
    error({ text: t('domains.profile.publications.conference.published-month-field-error') })
    return
  }
  if (!values.country) {
    error({ text: t('domains.profile.publications.conference.country-field-error') })
    return
  }
  if (!values.type) {
    error({ text: t('domains.profile.publications.conference.type-field-error') })
    return
  }
  if (!values.conference_id) {
    error({ text: t('domains.profile.publications.conference.conference-field-error') })
    return
  }
  const { status, message } =
             tempValues.value.id === -1
               ? await store.addConference({ ...values })
               : await store.editConference(tempValues.value.id, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'publication-modal' })
    unsetModifier()
    store.fetchConferences()
  } else {
    error({ text: message })
  }
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action && newValue.data.type) {
      if (newValue.data.action === 'edit' && typeof newValue.data.type === 'string') {
        const exactBook = conferences.value.get(newValue.data.type)?.find((item) => item.id === newValue.data?.id)
        tempValues.value = objectDeepCopy(exactBook || defaultTempValues)
        selectedConference.value = getTitleById(tempValues.value.conference_id, conferenceSuggestions.value)
      } else {
        tempValues.value = objectDeepCopy(defaultTempValues)
      }
      show()
    }
  }, { immediate: true }
)
</script>
