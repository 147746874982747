import type { StatusMessageResponse } from '@shared/types'
import type{ ScheduleManagementSectionProgramHoursGetRequest } from '@shared/swagger'

import type { StoreType } from '../types'
import deleteProgramSectionHour from '@/shared/api/ScheduleManagement/deleteProgramSectionHour'

export default async function deleteProgramSectionHourAction (this: StoreType, section: number, program: number, schedule: number): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteProgramSectionHour(section, program, schedule)

  if (ok) await this.fetchScheduleManagementSectionProgramHours({ section, program, room_id: this.selectedRoomId } as ScheduleManagementSectionProgramHoursGetRequest)

  return {
    status: ok,
    message
  }
}
