<template>
  <div class="p-3 px-8 bg-gray-200 d-flex align-items-center">
    <h4 class="mb-0">
      {{ $t('domains.academic.reports.fields') }}:
    </h4>
    <div class="d-flex align-items-center ms-4">
      <pms-checkbox
        :state="includesAllFields"
        @onCheck="toggleAllFieldsOn"
        @onUncheck="toggleAllFieldsOff"
      />
      <h5 class="mb-0">
        {{ $t('common.select-all') }}
      </h5>
    </div>

    <pms-button
      class="ms-5"
      variant="outlined"
      color="primary"
      @click="toggleAllDefaultsOn"
    >
      {{ $t('domains.academic.reports.default_fields') }}
    </pms-button>
  </div>
  <div class="row flex-wrap mt-2 px-8">
    <div
      v-for="(field,index) in all_fields"
      :key="index"
      class="d-flex col-2 mt-2"
    >
      <pms-checkbox
        :state="fields.includes(field)"
        @onCheck="toggleFieldOn(field)"
        @onUncheck="toggleFieldOff(field)"
      />
      <label>
        {{ field }}
      </label>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useViewPageComposable } from '../composable'

const { fields, all_fields, toggleFieldOn, toggleFieldOff, toggleAllDefaultsOn, includesAllFields, toggleAllFieldsOn, toggleAllFieldsOff } = useViewPageComposable()

</script>
