import { ref } from 'vue'
import { AddedGrades, BellCurvedGrades } from '../types'
import { gradesGrapData, setGradesGrapData, updateCategories } from './gradeStatistics'

export const test = () => {
  return true
}

export const selectedStudentIds = ref<Array<string>>([])
export const selectedAssessment = ref<number | null>(null)
export const selectedLmsAssessment = ref<number | null>(null)
export const isEdited = ref<boolean>(false)
export const addedGrades = ref<AddedGrades>({
  assessment_id: 0,
  grades: []
})
export const bellCurvedGrades = ref<BellCurvedGrades>({
  assessment_id: 0,
  grades: []
})

export const clearAllGradesValues = () => {
  selectedAssessment.value = null
  addedGrades.value = {
    assessment_id: 0,
    grades: []
  }
  bellCurvedGrades.value = {
    assessment_id: 0,
    grades: []
  }
  isEdited.value = false
  selectedLmsAssessment.value = null
  setGradesGrapData({
    series: {
      data: [],
      name: 'Grades'
    },
    letters: [],
    ranges: []
  })
  updateCategories(gradesGrapData.value)
}

export const clearBellCurvedGrades = () => {
  bellCurvedGrades.value = {
    assessment_id: 0,
    grades: []
  }
}

export const clearAddedGrades = () => {
  addedGrades.value = {
    assessment_id: 0,
    grades: []
  }
}
