<template>
  <div
    id="profile-contacts"
    class="col-12"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.contacts.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <pms-smart-table
        :items="contacts"
        :headers="tableHeaders"
        :sortable="true"
      >
        <template #type="{ item }">
          {{ getContactType(item.type_id) }}
        </template>
        <template #action="{ item }">
          <div class="d-flex gap-3 justify-content-center flex-wrap">
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="setIdAction(item.id)"
            >
              <pms-kt-icon
                icon-name="notepad-edit"
                class="fs-3 m-0 p-0"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-sm btn-icon btn-light-primary"
              @click="deleteAction(item.id, item.contact)"
            >
              <pms-kt-icon
                icon-name="trash"
                class="fs-3 m-0 p-0"
              />
            </a>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <ContactsForm v-if="modifier && modifier.name==='contacts'" />
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import { tableHeaders } from '../values'

import ContactsForm from './ContactsForm.vue'
const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const contacts = computed(() => store.getContacts)
const contactTypes = computed(() => resource.getProfileResource?.contact_types || [])

const setIdAction = (contact_id: number) => {
  setModifier('contacts',
    {
      id: contact_id,
      action: contact_id === -1 ? 'new' : 'edit'
    },
    {
      title: contact_id === -1 ? 'contacts-new-title' : 'contacts-edit-title',
      description: contact_id === -1 ? 'contacts-new-description' : 'contacts-edit-description'
    }
  )
  open({ name: 'contacts-modal' })
}

const deleteAction = async (contact_id: number, title: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.contacts.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteContact(contact_id)
    }
  })
}
const getContactType = (id: number|string): string => {
  return contactTypes.value.find((contactType) => contactType.type_id === id)?.title || ''
}
</script>
