import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { AcademicManageSyllabusInstructorsPostRequest, UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function deleteAcademicManageSyllabusInstructor (body: AcademicManageSyllabusInstructorsPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = destroy<UnknownRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/instructors',
    body: { params: body },
    loader: 'academic/manage/syllabus/instructors'
  })
  return response
}
