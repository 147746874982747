import type { StoreType } from '../types'
import { AcademicManageCourseRequisitesResourceGetRequest } from '@shared/swagger'

import { getAcademicManageCourseRequisitesResouce } from '@shared/api'

export default async function fetchAcademicManageCourseRequisitesResource (this: StoreType, body: AcademicManageCourseRequisitesResourceGetRequest): Promise<void> {
  const { ok, data } = await getAcademicManageCourseRequisitesResouce(body)

  if (ok) {
    this.setAcademicManageCourseRequisitesResource(data)
  }
}
