import type { StoreType } from '../types'

import { getSectionManagementRequestQuotas } from '@shared/api'

export default async function fetchRequestsQuotasById (this: StoreType, id: number): Promise<void> {
  const { data, ok } = await getSectionManagementRequestQuotas(id)

  if (ok) {
    this.selectedRequestsQuotas = data
  } else {
    this.selectedRequestsQuotas = []
  }
}
