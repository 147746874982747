import { ScheduleWeekDay, ScheduleHour } from '../types'

export const scheduleWeekDays: Array<ScheduleWeekDay> = [
  {
    id: 1,
    title: 'common.week-days.monday',
    abbreviation: 'domains.academic.schedule.table.week-days.monday.abbreviation'
  },
  {
    id: 2,
    title: 'common.week-days.tuesday',
    abbreviation: 'domains.academic.schedule.table.week-days.tuesday.abbreviation'
  },
  {
    id: 3,
    title: 'common.week-days.wednesday',
    abbreviation: 'domains.academic.schedule.table.week-days.wednesday.abbreviation'
  },
  {
    id: 4,
    title: 'common.week-days.thursday',
    abbreviation: 'domains.academic.schedule.table.week-days.thursday.abbreviation'
  },
  {
    id: 5,
    title: 'common.week-days.friday',
    abbreviation: 'domains.academic.schedule.table.week-days.friday.abbreviation'
  },
  {
    id: 6,
    title: 'common.week-days.saturday',
    abbreviation: 'domains.academic.schedule.table.week-days.saturday.abbreviation'
  }
]
