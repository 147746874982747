import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { AdministrativeGateEntryRequest, AdministrativeGateEntryResponse } from '@shared/swagger'

export default async function postAdministrativeGateEntry (body: AdministrativeGateEntryRequest): Promise<AxiosResponse<AdministrativeGateEntryResponse>> {
  const { post } = useRequests()

  const response = post<AdministrativeGateEntryRequest, AdministrativeGateEntryResponse>({
    url: 'administrative/gate-entry',
    body,
    loader: 'administrative/gate-entry'
  })
  return response
}
