import type { StatusMessageResponse } from '@shared/types'
import type{ ScheduleManagementSectionProgramHourPostRequest, ScheduleManagementSectionProgramHoursGetRequest } from '@shared/swagger'

import type { StoreType } from '../types'
import postProgramSectionHour from '@/shared/api/ScheduleManagement/postProgramSectionHour'

export default async function addProgramSectionHour (this: StoreType, section: number, program: number, values: ScheduleManagementSectionProgramHourPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProgramSectionHour(section, program, values)

  if (ok) await this.fetchScheduleManagementSectionProgramHours({ section, program, room_id: values.room_id } as ScheduleManagementSectionProgramHoursGetRequest)

  return {
    status: ok,
    message
  }
}
