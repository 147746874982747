<template>
  <div class="d-flex align-items-center border-bottom min-h-50px">
    <div
      class="btn-group w-100 m-4"
    >
      <pms-button
        class="text-hover-white text-active-white"
        :variant="username_view ? 'default' : 'tonal'"
        @click="setSearchType('by_username')"
      >
        {{ $t('domains.service.message-service.new-message.employee.by_username') }}
      </pms-button>
      <pms-button
        class="text-hover-white text-active-white"
        :variant="department_view ? 'default' : 'tonal'"
        @click="setSearchType('by_department')"
      >
        {{ $t('domains.service.message-service.new-message.employee.by_department') }}
      </pms-button>
    </div>
  </div>
  <div>
    <PersonalByUsername v-if="username_view" />
    <PersonalByDepartment v-if="department_view" />
  </div>
  <div
    v-if="selectedEmployee.length>0"
    class="d-flex flex-wrap align-items-center pb-2 border-bottom min-h-50px"
  >
    <template
      v-for="(employee,index) in selectedEmployee"
      :key="index"
    >
      <Chip
        class="border border-dashed border-pill border-gray-500 fw-bold bg-white text-nowrap px-4 mt-2 me-2"
        :label="employee"
        removable
      >
        <template #removeicon>
          <i
            class="bi bi-x-circle ms-2 text-gray-900 cursor-pointer"
            @click="removeSelectedEmployee(employee)"
          />
        </template>
      </Chip>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import Chip from 'primevue/chip'
import { storeToRefs } from 'pinia'
import { EmployeeSearchType } from '../type'
import PersonalByDepartment from './PersonalByDepartment.vue'
import PersonalByUsername from './PersonalByUsername.vue'
import { useMessageBroadcastCompose } from '../store'

const employee_view = ref('by_username')
const department_view = computed(() => employee_view.value === 'by_department')
const username_view = computed(() => employee_view.value === 'by_username')
const { selectedEmployee } = storeToRefs(useMessageBroadcastCompose())

function setSearchType (type:EmployeeSearchType) {
  employee_view.value = type
}

function removeSelectedEmployee (employee:string) {
  selectedEmployee.value = selectedEmployee.value.filter(emp => emp !== employee)
}

</script>
<style scoped>
.form-control:focus {
  box-shadow: none;
}
</style>
