<template>
  <pms-dropdown
    :content-box-class="'mt-3 p-3 pe-4 ps-4'"
    :group-class="'mt-1'"
  >
    <template #button>
      <span class="menu-title w-200px ms-4">
        <span class="fs-8 rounded bg-light px-4 py-3 pb-4">
          {{ currentLocale.locale }}
          <img
            class="w-15px h-15px rounded-1 ms-2"
            :src="currentLocale.flag"
            :alt="currentLocale.locale"
          >
        </span>
      </span>
    </template>
    <template
      v-for="(localeItem, index) in locales"
      :key="index"
    >
      <div class="menu-item px-0">
        <a
          href="javascript:void(0)"
          class="menu-link d-flex px-3"
          :class="{ active: locale === localeItem.short }"
          @click="setLocale(localeItem.short)"
        >
          <span class="symbol symbol-20px me-4">
            <img
              class="rounded-1"
              :src="localeItem.flag"
              :alt="localeItem.locale"
            >
          </span>
          {{ localeItem.locale }}
        </a>
      </div>
    </template>
  </pms-dropdown>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useLocale } from '@shared/composable'

const { locale, locales, setLocale } = useLocale()
const currentLocale = computed(() => locales.value.find((item) => item.short === locale.value) || locales.value[0])
</script>
