import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { RulesAndRegulationsPostRequest, RulesAndRegulationsPostResponse } from '@shared/swagger'

export default async function postRulesAndRegulations (body: RulesAndRegulationsPostRequest): Promise<AxiosResponse<RulesAndRegulationsPostResponse>> {
  const { post } = useRequests()

  const response = post<RulesAndRegulationsPostRequest, RulesAndRegulationsPostResponse>({
    url: 'academic/information/rules',
    body,
    loader: 'academic/information/rules'
  })
  return response
}
