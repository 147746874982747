
import { tempValues } from '../../values'
import type { StoreType } from '../types'

export default async function bootstrap (this: StoreType, pageName: string): Promise<void> {
  switch (pageName) {
    case 'AnnouncementsPage':
      await Promise.all([
        this.fetchAnnouncements(tempValues.value.year.getFullYear())
      ])
      break
    case 'CreateNewAnnouncementPage':
    case 'EditAnnouncementPage':
      await Promise.all([
        this.fetchTargets()
      ])
      break
  }
}
