import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicCoursesRequest, AcademicCoursesResponse } from '@shared/swagger'

export default async function getAcademicCourses (params: AcademicCoursesRequest): Promise<AxiosResponse<AcademicCoursesResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicCoursesResponse>({
    url: 'academic/courses',
    body: { params: params },
    loader: 'academic/courses'
  })

  return response
}
