<template>
  <div class="card">
    <div class="card-body row justify-content-center">
      <div class="col-6 row align-items-center">
        <span class="col-2 text-end">
          {{ $t('common.table.category') }}
        </span>
        <div class="col-8">
          <treeselect
            :multiple="false"
            :options="categories_to_show"
            placeholder=""
            @select="search_by_category"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Treeselect from 'vue3-treeselect'
import { useReportsComposable } from '../composable'

const { categories_to_show, search_by_category } = useReportsComposable()
</script>
