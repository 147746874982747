<template>
  <div class="d-flex flex-column mt-4 mt-md-0 flex-md-row align-items-md-center gap-1">
    <div class="d-flex gap-3">
      <pms-driver-button />
      <pms-feedback-modal
        :options="feedback"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { feedback, quickSetTourSteps } from './../values'
import { useDriver } from '@shared/composable'
import { onMounted } from 'vue'

const { setSteps } = useDriver()

onMounted(async () => {
  setSteps(await quickSetTourSteps())
})
</script>
