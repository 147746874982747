
<template>
  <pms-button
    :variant="'outlined'"
    :size="'sm'"
    @Click="handleAddElectiveSlot()"
  >
    <i class="bi bi-plus-lg" />
    {{ t('domains.academic.curricula.add-elective-slot-button') }}
  </pms-button>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  term: {
    required: false,
    type: Number
  },
  trackId: {
    required: true,
    type: Number
  }
})

const handleAddElectiveSlot = () => {
  if (props.trackId) {
    router.push({
      name: 'AddElectiveSlotPage',
      params: {
        trackId: props.trackId
      },
      query: {
        term: props.term
      }
    })
  }
}
</script>
