import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useStore } from './store'

const handleAuthRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  useStore().bootstrap()
  next()
}

export default {
  layout: 'empty',
  path: '/translate/messages',
  children: [
    {
      path: '',
      name: 'TranslateMessages',
      component: () => import('./TranslateMessages.vue'),
      beforeEnter: [
        handleAuthRoute
      ]
    },
    {
      path: ':path',
      name: 'TranslateMessage',
      component: () => import('./features/EditMessageContents.vue'),
      beforeEnter: []
    }
  ]
} as RouteRecordWithLayout
