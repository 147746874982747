import { useRequests } from '@shared/composable'

import type { StatusMessageResponse } from '@shared/types'

export default async function deleteCurriculumCoreCourse (id: number): Promise<StatusMessageResponse> {
  const { destroy } = useRequests()

  const { ok, message } = await destroy({
    url: `/academic/manage/curriculum/core/${id}`,
    body: {},
    loader: 'delete-academic/manage/curriculum/core'
  })

  return {
    status: ok,
    message
  }
}
