import { usePagination } from '@/shared/composable'
import { useAllStudents } from '../store'
import { studentsSearchValues } from '../values'

const { perPage } = usePagination()
const store = useAllStudents()

export default async function studentsSearch () {
  let no_level: 0|1 = 0
  if (studentsSearchValues.value.no_level) no_level = 1

  await store.fetchStudents({ ...studentsSearchValues.value, no_level, per_page: perPage.value, offset: 1 })
}
