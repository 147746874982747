import { getRulesAndRegulationsCategories } from '@/shared/api'
import { StatusMessageResponse } from '@/shared/types'
import { StoreType } from '../types'

export default async function fetchRuleCategories (this: StoreType): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getRulesAndRegulationsCategories()

  ok && this.setCategories(data)
  return {
    status: ok,
    message
  }
}
