import { PropType, defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { TabElement } from '@shared/types'
import { isTranslatable } from '@shared/utils'

const TabComponent = defineComponent({
  name: 'TabComponent',
  props: {
    tabs: {
      type: Array as PropType<Array<TabElement>>,
      required: true
    },
    tabsId: {
      type: String,
      required: false,
      default: ''
    },
    activeTabClass: {
      type: String,
      required: false,
      default: ''
    },
    defaultTabClass: {
      type: String,
      required: false,
      default: 'nav-link text-muted text-active-primary ms-0 py-4 mx-0 px-8 border-0 text-nowrap fs-5 fw-bold'
    },
    tabClass: {
      type: String,
      required: false,
      default: 'text-gray-700'
    },
    activeTabStyle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    tabStyle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    tabContainerClass: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    iconClass: {
      type: String,
      required: false,
      default: 'pb-1'
    },
    iconStyle: {
      type: [String, Object],
      required: false,
      default: 'padding-right: 7px;'
    },
    all: {
      type: Boolean,
      required: false,
      default: true
    },
    allLabel: {
      type: String,
      required: false,
      default: 'All'
    },
    active: {
      type: String,
      required: false,
      default: ''
    },
    tabButtonsClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:active', 'update:tabs'],
  setup (props, { emit, slots }) {
    const { t } = useI18n()

    const changeTab = (id: string) => {
      emit('update:active', id)
    }

    onMounted(() => {
      if (props.active === '' && props.all) {
        emit('update:active', 'all')
        return
      }

      if (props.active === '' && props.tabs.length > 0) {
        emit('update:active', props.tabs[0].id)
      }
    })

    return () => (
      <div class={ props.tabContainerClass }>
        <div class={`d-flex mb-4 flex-wrap ${props.tabButtonsClass}`} id={props.tabsId}>
          {
            props.all && (
              <div class="position-relative">
                <button
                  class={
                    `${props.defaultTabClass} ` + (props.active === 'all' ? ` ${props.activeTabClass} active ` : ` ${props.tabClass} `)
                  }
                  style={
                    props.active === 'all' ? props.activeTabStyle : props.tabStyle
                  }
                  onClick={() => changeTab('all')}
                >
                  { isTranslatable(props.allLabel) ? t('common.all') : props.allLabel }
                </button>
                <span
                  class={
                    `position-absolute start-0 bottom-0 end-0 h-3px bg-primary rounded ${props.active === 'all' ? 'd-block' : 'd-none'}`
                  }
                ></span>
              </div>
            )
          }
          {
            props.tabs.map((tab) => {
              if (slots[`${tab.id}-title`]) {
                return slots[`${tab.id}-title`]?.({ onChange: changeTab, tab })
              }
              return (
                <div class="position-relative">
                  <button
                    class={
                      `${props.defaultTabClass} ` + (props.active === tab.id ? ` ${props.activeTabClass} active ` : ` ${props.tabClass} `)
                    }
                    style={
                      props.active === tab.id ? props.activeTabStyle : props.tabStyle
                    }
                    onClick={() => changeTab(tab.id)}
                  >
                    { tab.icon && props.icon ? <i class={ `bi ${tab.icon} ${props.iconClass}`} style={props.iconStyle}></i> : null }
                    { isTranslatable(tab.label) ? t(tab.label) : tab.label }
                  </button>
                  <span
                    class={
                      `position-absolute start-0 bottom-0 end-0 h-3px bg-primary rounded ${props.active === tab.id ? 'd-block' : 'd-none'}`
                    }
                  ></span>
                </div>
              )
            })
          }
        </div>
        <div class="d-flex flex-column">
          {
            props.all && props.active === 'all'
              ? props.tabs.map((tab) => (slots[tab.id] ? slots[tab.id]?.() : null))
              : null
          }
          {
            props.active !== 'all' && props.tabs.map((tab) => (
              props.active === tab.id && slots[tab.id]
                ? slots[tab.id]?.()
                : null
            ))
          }
        </div>
      </div>
    )
  },
  watch: {
    tabs: {
      handler (newValue: Array<TabElement>) {
        this.$emit('update:tabs', newValue)

        if (this.all) {
          this.$emit('update:active', 'all')
        } else {
          if (newValue.length > 0) { this.$emit('update:active', newValue[0].id) }
        }
      },
      immediate: true
    },
    active: {
      handler (newValue: string) {
        this.$emit('update:active', newValue)
      },
      immediate: true
    }
  }
})

export default TabComponent
