import { useRequests } from '@/shared/composable'

import type { AxiosResponse } from '@/shared/types'
import type { UnknownRequest, UnknownResponse } from '@shared/swagger'

export default async function saveMessageTranslateContentByPath (path: string, content: Record<string, unknown>): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<UnknownRequest, UnknownResponse>({
    url: `http://localhost:3000/messages/${path}`,
    body: { content },
    loader: 'translates'
  })

  return response
}
