<template>
  <div>
    <p class="fs-2 fw-bold mb-0">
      {{ props.knowledgeBaseSinglePost.title }}
    </p>
    <div class="fw-semibold text-muted d-flex fs-6">
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.language') }}: {{ props.knowledgeBaseSinglePost.language_title }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.category') }}: {{ props.knowledgeBaseSinglePost.category_title }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.views') }}: {{ props.knowledgeBaseSinglePost.views }}
      </p>
      <p class="pe-5 mb-1">
        {{ $t('domains.knowledge.all-posts.posted-at') }}: {{ dateFormatter(props.knowledgeBaseSinglePost.created_at) }}
      </p>
    </div>
    <p class="fs-5 mb-1">
      {{ cutContent(props.knowledgeBaseSinglePost.description, { endWith: '...', length: 300, removeHtmlTags: true }) }}
    </p>
    <KnowledgeBasePostLink :uuid="props.knowledgeBaseSinglePost.uuid" />
  </div>
</template>
<script setup lang="ts">
import { KnowledgeBaseResponse } from '@shared/swagger'
import { dateFormatter, cutContent } from '@shared/utils'

import KnowledgeBasePostLink from '@domains/KnowledgeBaseListing/ui/KnowledgeBasePostLink.vue'

const props = defineProps<{
  knowledgeBaseSinglePost: KnowledgeBaseResponse['posts'][0],
}>()
</script>
