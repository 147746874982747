<template>
  <div class="d-flex justify-content-center my-5 gap-5 fs-5">
    <span :style="'color: #ff0000'">
      {{ $t('common.failed') }}
    </span> <span class="text-muted">
      |
    </span> <span :style="'color: #0a0ace'">
      {{ $t('common.not-taken') }}
    </span> <span class="text-muted">
      |
    </span> <span :style="'color: #1ba018'">
      {{ $t('common.passed') }}
    </span><span class="text-muted">
      |
    </span> <span :style="'color: #ffad4c'">
      {{ $t('common.taken') }}
    </span>
  </div>
</template>
