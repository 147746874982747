import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicInformationExamDatesAssessmentPutRequest, AcademicInformationExamDatesAssessmentPutResponse } from '@shared/swagger'

export default async function putAcademicInformationExamDatesAssessment (id: number, body: AcademicInformationExamDatesAssessmentPutRequest): Promise<AxiosResponse<AcademicInformationExamDatesAssessmentPutResponse>> {
  const { put } = useRequests()

  const response = await put<AcademicInformationExamDatesAssessmentPutRequest, AcademicInformationExamDatesAssessmentPutResponse>({
    url: `academic/information/exam-dates/${id}`,
    body: body,
    loader: 'put/academic/information/exam-dates'
  })

  return response
}
