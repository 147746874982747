import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest
} from '@/shared/swagger'
import { ServiceAdvisorAssignmentAssginStudentsToAdvisorRequest } from '@/shared/swagger/entities/AdvisoryAssignment/Request'
import type { AxiosResponse, StatusMessageResponse } from '@shared/types'

export default async function assignStudentsToAdvisor (data: ServiceAdvisorAssignmentAssginStudentsToAdvisorRequest): Promise<AxiosResponse<StatusMessageResponse>> {
  const { put } = useRequests()

  const response = put<UnknownRequest, StatusMessageResponse>({
    url: 'service/manage/advisors/bulk',
    body: data,
    loader: 'put-service/manage/advisors/bulk'
  })

  return response
}
