import { AcademicManageCoursesSearchResponse } from '@shared/swagger'
import { ManageCourseRequestType } from '../types'
import { useResource } from '@/shared/stores'

type TextAndSelect = {
    prefix:string,
    code:string
}

function courseCodeToTextAndSelect (course_code:AcademicManageCoursesSearchResponse[0]['code']): TextAndSelect {
  const splitted = course_code.split(' ')
  return {
    prefix: splitted[0],
    code: splitted[1]
  }
}
function findLanguageTitleById (language:AcademicManageCoursesSearchResponse[0]['language']): string {
  const resource = useResource().academicManageCourseResource
  const res = resource?.languages.find(lang => lang.code === language)?.title || ''
  return res
}

export default (data: AcademicManageCoursesSearchResponse[0]): ManageCourseRequestType => {
  return {
    code: data.code,
    title: data.title,
    year: data.year,
    language: findLanguageTitleById(data.language),
    title_en: data.title_en,
    title_ru: data.title_ru,
    title_kz: data.title_kz,
    department_code: data.department_code,
    theory: data.theory,
    practice: data.practice,
    lab: data.lab,
    credits: data.credits,
    ects: data.ects,
    local_code: data.local_code,
    grading_type: data.grading_type,
    desctiption_en: data.description_en,
    desctiption_ru: data.description_ru,
    desctiption_kz: data.description_kz,
    period_code: data.period_code || 'S',
    term: data.term,
    equicode: data.equicode,
    type: data.type,

    course_code_prefix: courseCodeToTextAndSelect(data.code).prefix,
    course_code_code: courseCodeToTextAndSelect(data.code).code,

    ar_requisite: data.ar_requisite,
    pr_requisite: data.pr_requisite,
    cr_requisite: data.cr_requisite
  }
}
