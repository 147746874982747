import type { StoreType } from '../types'
import type { AcademicSingleCourseGradePutRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@shared/types'

import { putAcademicSingleCourseGrade } from '@shared/api'

export default async function fetchGrades (this: StoreType, assessment: number | string, values: AcademicSingleCourseGradePutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putAcademicSingleCourseGrade(assessment, values)

  return {
    status: ok,
    message
  }
}
