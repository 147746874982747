<template>
  <div id="knowledge-manage-post-list">
    <template
      v-for="post in knowledgeManagePosts"
      :key="post.uuid"
    >
      <pms-card
        :header="false"
        class="mb-3"
      >
        <knowledge-manage-post
          :post="post"
          class="mb-3"
        />
      </pms-card>
    </template>

    <pms-card
      v-if="knowledgeManagePosts.length === 0"
      :header="false"
      class="mt-3"
    >
      <pms-no-data />
    </pms-card>
  </div>

  <pms-card
    :header="false"
    class="mt-3"
  >
    <pms-pagination />
  </pms-card>
</template>
<script setup lang="ts">

import KnowledgeManagePost from './KnowledgeManagePost.vue'
import { useKnowledgeManagePost } from '../../../store'
import { computed } from 'vue'

const store = useKnowledgeManagePost()
const knowledgeManagePosts = computed(() => store.getPosts)
</script>
