import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'

import { useManageCourses } from './store'

const handleRoutes = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useManageCourses()

  store.bootstrap(to)
  next()
}

export default {
  layout: 'default',
  path: '/academic/courses',
  children: [
    {
      path: '',
      name: 'AcademicCoursesPage',
      beforeEnter: [
        handleRoutes
      ],
      component: () => import('./SearchCoursesPage.vue'),
      meta: {
        page: 'main'
      }
    },
    {
      path: 'new',
      name: 'AcademicManageCoursesCreatePage',
      component: () => import('./features/ManageCoursePage/ManageCoursePage.vue'),
      beforeEnter: [
        handleRoutes
      ],
      meta: {
        page: 'new'
      }
    },
    {
      path: 'edit/:code/:year',
      name: 'AcademicManageCoursesEditPage',
      component: () => import('./features/ManageCoursePage/ManageCoursePage.vue'),
      props: true,
      beforeEnter: [
        handleRoutes
      ],
      meta: {
        page: 'edit'
      }
    },
    {
      path: 'edit/:code/:year/:requisite_type',
      name: 'AcademicCoursesManageRequisitePage',
      component: () => import('./features/ManageRequisitePage/ManageRequisitePage.vue'),
      props: true,
      beforeEnter: [
        handleRoutes
      ],
      meta: {
        page: 'requisite'
      }
    }
  ]
} as RouteRecordWithLayout
