import type { StoreType } from '../types'
import type { AcademicCoursesOverviewRequest } from '@shared/swagger'

import { getAcademicCoursesOverview } from '@shared/api'

export default async function fetchOverview (this: StoreType, values: AcademicCoursesOverviewRequest): Promise<void> {
  const { data, ok } = await getAcademicCoursesOverview(values)

  if (ok) {
    this.setOverview(data)
  }
}
