<template>
  <div
    id="skill"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.skills.skill.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div
        v-if="skills.length > 0"
        class="d-flex flex-wrap"
      >
        <div
          v-for="(skill,index) in skills"
          :key="index"
          class="bg-secondary rounded p-3 px-9 position-relative m-3"
        >
          {{ skill.skill }}
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-primary ms-3"
            @click="setIdAction(skill.id)"
          >
            <pms-kt-icon
              icon-name="notepad-edit"
              class="fs-3 m-0 p-0"
            />
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-sm btn-icon btn-light-primary ms-3"
            @click="deleteAction(skill.id, skill.skill)"
          >
            <pms-kt-icon
              icon-name="trash"
              class="fs-3 m-0 p-0"
            />
          </a>
        </div>
      </div>
      <pms-no-data v-else />
      <SkillForm v-if="modifier && modifier.name === 'skill'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'

import { useProfile } from '../../../store'
import SkillForm from './SkillForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const store = useProfile()

const skills = computed(() => store.getSkills)

const setIdAction = (id: number) => {
  setModifier('skill',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'skill-new-title' : 'skill-edit-title',
      description: id === -1 ? 'skill-new-description' : 'skill-edit-description'
    }
  )
  open({ name: 'skill-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.skills.are-you-sure',
    text: t('domains.profile.skills.skill.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteSkill(id)
    }
  })
}
</script>
