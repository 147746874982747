<template>
  <div class="m-0">
    <ul
      class="nav nav-tabs border-transparent overflow-auto flex-nowrap"
      :class="tabButtonsClass"
      role="tablist"
    >
      <li
        v-for="(item,index) in menuItems"
        :key="index"
        class="nav-item position-relative my-0 py-0"
        :class="{'bg-light-primary':routeName === item.routeName}"
      >
        <router-link
          class="nav-link text-muted text-active-primary  ms-0 py-4 mx-0 px-8 border-0 text-nowrap"
          :to="item.to"
        >
          <span
            class=" fs-6 fw-bold"
            :class="routeName === item.routeName ?'text-primary':'text-gray-700'"
          >
            {{ t(item.label) }}
          </span>

          <span
            v-if="routeName === item.routeName"
            class="bullet-custom position-absolute start-0 bottom-0 end-0 w-100 h-3px bg-primary rounded"
          />
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute } from 'vue-router'

type MenuItem = {
    routeName: string,
    label: string,
    to: RouteLocationRaw
}

type HorizontalTabMenuProps = {
    menuItems: MenuItem[]
    tabButtonsClass?: string | Array<unknown> | Record<string, unknown>;
}

const route = useRoute()
const routeName = computed(() => route.name)
const { t } = useI18n()

const { menuItems, tabButtonsClass } = defineProps<HorizontalTabMenuProps>()

</script>
