import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { getServiceMessageBroadcastSingle } from '@shared/api'

export default async function fetchEmployees (this: StoreType, id: string): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getServiceMessageBroadcastSingle(id)
  if (ok) this.setMessage(data)

  return {
    status: ok,
    message
  }
}
