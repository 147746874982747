<template>
  <Teleport to="#exam-date-modal-content">
    <pms-smart-form
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="text-start mt-4">
          <pms-button
            type="submit"
            class="me-3"
            :loader="loader"
            :disabled="loader"
            :text="'common.submit'"
          />
          <pms-button
            :color="'light'"
            :text="'common.cancel'"
            data-bs-dismiss="modal"
            @click="closeModal()"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import type { FormField } from '@shared/types'
import { useLoader, useAlert, useTeleportModal } from '@shared/composable'
import { useExamDates } from '../store'
import { selectedCourses, selectedCoursesReq } from './../values/'

const { t } = useI18n()
const store = useExamDates()
const { isActiveOneOf } = useLoader()
const { success, error } = useAlert()
const { close, show, modifier, unsetModifier } = useTeleportModal()
const loader = isActiveOneOf([])

const defaultTempValues = {
  exam_date: ''
}

const tempValues = (defaultTempValues)
const formFields = computed(():Array<FormField> => [
  {
    label: {
      text: `${t('domains.academic.information.exam-dates.exam-date')}:`,
      class: 'fs-6 fw-semibold mb-2'
    },
    name: 'exam_date',
    type: 'date',
    value: tempValues.exam_date
  }
])

const onSubmit = async ({ values }) => {
  selectedCourses.value.forEach(element => {
    selectedCoursesReq.value.push({ assessment_id: element, exam_date: values.exam_date })
    closeModal()
  })

  const { status, message } = await store.putExamDates(selectedCoursesReq.value)
  if (status) {
    success({ text: message, isToast: true })
    unsetModifier()
    selectedCourses.value = []
    selectedCoursesReq.value = []
  } else {
    error({ text: message })
    unsetModifier()
    selectedCourses.value = []
    selectedCoursesReq.value = []
  }
}

const closeModal = () => {
  close({ name: 'exam-date-modal' })
  unsetModifier()
}
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      show()
    }
  }, { immediate: true }
)
</script>
