import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordWithLayout } from '@shared-types/common'
import { useSystemCalendar } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useSystemCalendar()
  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/administrative/system-calendar',
  children: [
    {
      path: '',
      name: 'SystemCalendarPage',
      component: () => import('./SystemCalendarPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
