import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useSingleStudent = defineStore<Name, State, Getters, Actions>('Academic.useSingleStudent', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      studentId: '',
      information: null,
      courseRegistration: null,
      attendance: null,
      attendanceDetails: null,
      currentYearTerm: { id: 0, year: 0, term: 0 },
      accounting: [],
      accountingDetails: null,
      grades: [],
      gradesDetails: null,
      schedule: [],
      curricula: null,
      transcript: null,
      advisorOpinion: null
    }
  }
})
