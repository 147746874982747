<template>
  <pms-card
    :title="'common.search'"
    :description="$t('domains.academic.all-students.search.description')"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <div class="mt-3 d-flex justify-content-end">
          <pms-button
            type="submit"
            class="mt-2"
            :disabled="searchLoader"
            :loader="searchLoader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { FormGroup } from '@shared/types'
import { useLoader, usePagination } from '@shared/composable'
import { useResource } from '@/shared/stores'

import { useAllStudents } from '../store'

import { toOptioonType, requestedByToOption } from '../utils'
import { searchTempValues, selectedRequestedBy } from '../values'

const { t } = useI18n()
const { isActive } = useLoader()
const { perPage } = usePagination()
const store = useAllStudents()
const resource = useResource()

const loader = isActive('service/manage/locks/resources')
const searchLoader = isActive('aservice/manage/locks')

const departments = computed(() => resource.getServiceLockResourceDepartment)
const specialties = computed(() => resource.getServiceLockResourceSpecialities)
const requestedBy = computed(() => resource.getServiceLockResourceRequestedBy)

const formFields = computed<Array<FormGroup>>(() => {
  return [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.search-field.student-id')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'student_id',
          type: 'text',
          value: searchTempValues.value.student_id || ''
        },
        {
          label: {
            text: `${t('common.search-field.name')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'name',
          type: 'text',
          value: searchTempValues.value.name || ''
        },
        {
          label: {
            text: `${t('common.search-field.surname')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'surname',
          type: 'text',
          value: searchTempValues.value.surname || ''
        },
        {
          label: {
            text: `${t('common.department')}:`,
            class: 'mb-1 mt-2 required'
          },
          group: {
            class: 'col-4'
          },
          name: 'department_code',
          type: 'select',
          required: true,
          value: searchTempValues.value.department_code || null,
          options: toOptioonType(departments.value)
        },
        {
          label: {
            text: `${t('common.search-field.select-speciality')}:`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'speciality',
          type: 'select',
          value: searchTempValues.value.speciality_code || null,
          options: toOptioonType(specialties.value)
        },
        {
          label: {
            text: `${t('common.search-field.select-requested-by')}:`,
            class: 'mb-1'
          },
          group: {
            class: 'col-4'
          },
          name: 'requestedBy',
          type: 'select',
          value: searchTempValues.value.requested_by || null,
          options: requestedByToOption(requestedBy.value)
        },
        {
          label: {
            text: `${t('common.search-field.class')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'class',
          type: 'text',
          value: searchTempValues.value.class || ''
        },
        {
          label: {
            text: `${t('common.search-field.entrance-year')}:`,
            class: 'mb-1 mt-2'
          },
          group: {
            class: 'col-4'
          },
          name: 'entrance_year',
          type: 'text',
          value: searchTempValues.value.entrance_year || ''
        }
      ]
    }
  ]
})

const onHandleChange = ({ name, newValue }) => {
  searchTempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  let locked: 0|1 = 0
  if (values.locked) locked = 1

  values.value = {
    ...searchTempValues.value,
    ...values
  }
  selectedRequestedBy.push(values.requestedBy)
  await store.fetchStudents({ ...values, locked, per_page: perPage.value, offset: 1 })
}

</script>
