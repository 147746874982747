import type { StatusMessageResponse } from '@shared/types'
import type{ ServiceManageLocksTogglePutRequest } from '@shared/swagger'
import { putServiceManageLocksToggle } from '@shared/api'

import type { StoreType } from '../types'

export default async function toggleLocks (this: StoreType, values: ServiceManageLocksTogglePutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceManageLocksToggle(values)

  return {
    status: ok,
    message
  }
}
