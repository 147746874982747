import type { StoreType } from '../types'
import { AcademicSingleStudentAttendanceDetailsGetRequest } from '@shared/swagger'
import { getAcademicSingleStudentAttendanceDetails } from '@shared/api'

export default async function fetchAttendanceDetails (this: StoreType, id: string, params: AcademicSingleStudentAttendanceDetailsGetRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleStudentAttendanceDetails(id, params)

  if (ok) {
    this.setAttendanceDetails(data)
  }
}
