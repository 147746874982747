<template>
  <pms-modal-sidebar
    v-model:current-width="currentWidth"
    v-model:state="gradeSideBarState"
    :title="sideBarTitle"
    :description="sidebarDescription"
    :modal="'GradesDetails'"
    :width="'450px'"
    :position="'end'"
    :scroll-out="false"
    :resize="true"
    :backdrop="true"
  >
    <pms-loader
      v-if="detailsLoader"
      :options="{
        body: { class: 'p-0 ps-2 pt-0 pe-2' }
      }"
      :items="6"
    />
    <template v-else>
      <div v-if="sideBarData === null">
        <pms-no-data class="mt-20 fs-4 fw-bold" />
      </div>
      <template v-else>
        <div class="table-responsive mt-4">
          <table class="table align-middle">
            <tbody>
              <template
                v-for="(details, index) in getDetails(sideBarData)"
                :key="index"
              >
                <tr>
                  <td class="pt-0 pb-1 w-150px text-start">
                    <span class="text-gray-600 fw-bold fs-5">{{ $t(details.title) }}:</span>
                  </td>
                  <td class="pt-0 pb-1 text-start">
                    <span class="text-gray-800 fw-bold fs-5 me-1">{{ details.value }}</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="separator separator-dashed my-3" />
        <template
          v-for="(section, index) in sideBarData.sections"
          :key="index"
        >
          <div
            v-if="index !== 0"
            class="separator separator-dashed my-3"
          />
          <div class="table-responsive">
            <table class="table align-middle">
              <tbody>
                <tr>
                  <td
                    class="pt-0 pb-0 text-center"
                    colspan="3"
                  >
                    <span class="fw-bolder fs-5">{{ section.section }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    class="pt-0 pb-0 text-center"
                    colspan="3"
                  >
                    <span class="fw-bolder fs-6">{{ section.instructor_name }} {{ section.instructor_surname }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-row-dashed">
              <tr>
                <td class="pt-2 pb-2 text-center">
                  <span class="fw-bold fs-6">{{ $t('common.table.assessment') }}</span>
                </td>
                <td class="text-center">
                  <span class="fw-bold fs-6">{{ $t('common.table.grade') }}</span>
                </td>
                <td class="text-center">
                  <span class="fw-bold fs-6">{{ $t('common.table.average') }}</span>
                </td>
              </tr>
              <template
                v-for="item in sideBarData.grades"
                :key="item.assessment"
              >
                <tr>
                  <td class="pt-2 pb-2">
                    <span class="fs-6">{{ item.assessment }}</span>
                  </td>
                  <td class="text-center">
                    <span class="fs-6">{{ item.grade }}</span>
                  </td>
                  <td class="text-center">
                    <span class="fs-6">{{ item.grade }}</span>
                  </td>
                </tr>
              </template>
              <template v-if="sideBarData.grades.length === 0">
                <tr>
                  <td
                    class="pt-0 pb-1 text-center pt-4 pb-4"
                    colspan="3"
                  >
                    <span class="fs-6">{{ $t('common.no-data') }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </template>
      </template>
    </template>
  </pms-modal-sidebar>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { useLoader } from '@shared/composable'
import type { AcademicSingleStudentGradesDetailsGetResponse } from '@/shared/swagger'

import { sideBarTitle, gradeSideBarState, sidebarDescription, currentWidth } from '../values'

defineProps({
  sideBarData: {
    type: Object as PropType<AcademicSingleStudentGradesDetailsGetResponse | null>,
    required: true
  }
})

const { isActive } = useLoader()

const detailsLoader = isActive('academic/students/grades/details')

const getDetails = (data: AcademicSingleStudentGradesDetailsGetResponse) => [
  { title: 'common.year', value: data.year },
  { title: 'common.table.term', value: data.term },
  { title: 'common.title', value: data.title },
  { title: 'common.course-code', value: data.course_code },
  { title: 'common.grade', value: data.total_grade },
  { title: 'common.grade-letter', value: data.total_letter_grade }
]
</script>
