import { SmartTableHeader, SmartTableSortField } from '@/shared/types'
import { ref } from 'vue'

export const advisoryMyStudentsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.student',
    field: 'name',
    slotCellName: 'studentData',
    sortable: true
  },
  {
    title: 'common.table.class',
    field: 'class',
    slotCellName: 'class',
    subHeaders: [{
      title: 'common.table.class',
      field: 'class',
      slotName: 'class_header',
      cellClass: 'text-center',
      slotCellName: 'class'
    }]
  },
  {
    title: 'common.table.speciality',
    field: 'speciality',
    subHeaders: [{
      title: 'common.table.speciality',
      field: 'speciality',
      slotName: 'speciality_header',
      cellClass: 'text-center',
      slotCellName: 'speciality'
    }]
  },
  {
    title: 'common.table.email',
    field: 'email',
    slotCellName: 'email',
    headerClass: 'text-center',
    cellClass: 'text-center text-primary'
  },
  {
    title: 'common.table.last-login',
    field: 'last_login_date',
    slotCellName: 'lastLogin',
    sortable: false,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.birthdate',
    field: 'birth_date',
    slotCellName: 'birthdate',
    sortable: false,
    cellClass: 'text-center'
  },
  {
    title: 'common.table.confirmation',
    field: 'confirmation',
    subHeaders: [
      {
        title: 'common.table.student',
        field: 'student_confirm',
        slotCellName: 'student-confirm',
        headerClass: 'text-center',
        sortable: false,
        cellClass: 'text-center'
      },
      {
        title: 'common.table.advisor',
        field: 'advisor_confirm',
        slotCellName: 'advisor-confirm',
        headerClass: 'text-center',
        sortable: false,
        cellClass: 'text-center'
      }
    ]
  }
]
export const advisoryThesisStudentsTable: Array<SmartTableHeader> = [
  {
    title: 'common.table.student',
    field: 'name',
    slotCellName: 'studentData',
    sortable: true
  },
  {
    title: 'common.table.level',
    field: 'level'
  },
  {
    title: 'common.table.status',
    field: 'status',
    slotCellName: 'academicStatus',
    cellClass: 'text-center'
  },
  {
    title: 'common.table.thesis',
    field: 'thesis'
  }
]

export const classOptions = ref<number[]>([])
export const specialityOptions = ref<string[]>([])

export const myStudentsSortField = ref<SmartTableSortField>({ field: '', direction: 'neutral' })
export const thesisStudentsSortField = ref<SmartTableSortField>({ field: '', direction: 'neutral' })
export const myStudentsCurrentClass = ref<number>()
export const myStudentsCurrentSpeciality = ref<string>()

export const resetMyStudentsTableFields = () => {
  myStudentsSortField.value = { field: '', direction: 'neutral' }
  myStudentsCurrentClass.value = undefined
  myStudentsCurrentSpeciality.value = undefined
}

export const resetThesisStudentsSortField = () => {
  thesisStudentsSortField.value = { field: '', direction: 'neutral' }
}
