import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { getScheduleManagementSectionProgramHours } from '@shared/api'
import { ScheduleManagementSectionProgramHoursGetRequest } from '@/shared/swagger'

export default async function fetchScheduleManagementSectionProgramHours (this: StoreType, params: ScheduleManagementSectionProgramHoursGetRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getScheduleManagementSectionProgramHours(params)

  if (ok) {
    this.setScheduleManagementSectionProgramHours(data)
  }

  return {
    status: ok,
    message
  }
}
