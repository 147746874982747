import type { StoreType } from '../types'
import { putSectionManagementRequestsQuota, putSectionManagementReservedProgramsQuota } from '@shared/api'
import { SectionManagementRequestsQuotasPutRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@shared/types'
import { useAlert } from '@shared/composable'

export default async function editQuota (this: StoreType, requestId: number, quotaId: number, params: SectionManagementRequestsQuotasPutRequest, isReserved: boolean): Promise<StatusMessageResponse> {
  const { message, ok } = isReserved ? await putSectionManagementReservedProgramsQuota(quotaId, params) : await putSectionManagementRequestsQuota(requestId, quotaId, params)
  const { error, success } = useAlert()

  if (ok) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message
  }
}
