import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'
import * as getters from './getters'

export const useScheduleManagement = defineStore<Name, State, Getters, Actions>('Academic.useScheduleManagement', {
  actions: {
    ...actions
  },

  getters: {
    ...getters
  },

  state () {
    return {
      currentYearTerm: { id: 0, year: 0, term: 0 },
      formValues: null,
      programs: [],
      selectedProgram: null,
      sections: [],
      singleSection: null,
      singleSectionPrograms: [],
      singleSection1Program: null,
      buildings: [],
      scheduleManagementSectionProgramHours: [],
      selectedRoomId: null,
      teachingHours: []
    }
  }
})
