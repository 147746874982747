import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type {
  AcademicManageSyllabusReadingsPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusReading (id: number, body: AcademicManageSyllabusReadingsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = put<AcademicManageSyllabusReadingsPutRequest, UnknownResponse>({
    url: `academic/manage/syllabus/readings/${id}`,
    body,
    loader: 'academic/manage/syllabus/readings'
  })
  return response
}
