import { useUser } from '@/shared/stores'
import { AxiosResponse } from '@/shared/types'

import type { StoreType } from '../types'
import type { ForgotPasswordRequest, ForgotPasswordResponse } from '@shared/swagger'

export default async function forgotPassword (this: StoreType, credentials: ForgotPasswordRequest): Promise<AxiosResponse<ForgotPasswordResponse>> {
  const user = useUser()

  return await user.forgotPassword(credentials)
}
