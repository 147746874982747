import { computed, ref } from 'vue'
import QRCode from 'qrcode'
import { current_course } from '@/domains/AcademicSingleCourse/values'
import { useSingleCourse } from '@/domains/AcademicSingleCourse/store'
import { opts } from '../values'
import { router } from '@/router'

export function useAttendanceQR () {
  const courseStore = useSingleCourse()

  const items = computed(() => {
    if (current_course.value.sections) {
      return current_course.value.sections.split('#').map(section => {
        const item = section.split(',')
        return {
          datetime: item[0],
          section: item[1],
          schedule_id: item[2]
        }
      })
    } else {
      return []
    }
  })
  const sections = computed(() => [...new Set(
    items.value.map(item => item.section)
  )])
  const times = computed(() =>
    JSON.parse(JSON.stringify(items.value)).filter(item => item.section === choosen.value.section).map(time => {
      const [day, month, year, hour, minute] = time.datetime.split(/[\s:-]+/)
      const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`)
      time.datetime = `${String(date.getHours()).padStart(2, '0')} : ${String(date.getMinutes()).padStart(2, '0')}`
      return time
    })
  )

  const choosen = ref(
    {
      section: null,
      hour: '',
      schedule_id: ''
    }
  )

  let interval
  async function chooseTime (time_request:typeof items.value[0]) {
    clearInterval(interval)
    choosen.value.hour = time_request.datetime
    choosen.value.schedule_id = time_request.schedule_id
    generateQR()
    interval = setInterval(() => {
      generateQR()
    }, 20000)
  }

  async function generateQR () {
    const { key, time } = await courseStore.postAttendanceQR({
      schedule_id: +choosen.value.schedule_id
    })
    const text = `https://oldmy.sdu.edu.kz/index.php?mod=attend&cs=${choosen.value.schedule_id}&key=${key}&t=${time}`
    return QRCode.toCanvas((document.getElementById('qr-canvas') as HTMLCanvasElement), text, opts, (err) => {
      if (err) {
        console.error(err)
      }
    })
  }
  function mount () {
    const query = router.currentRoute.value.query
    if (query.schedule_id) {
      chooseTime({
        datetime: '',
        section: query.section as string,
        schedule_id: query.schedule_id as string
      })
    }
  }
  function unmount () {
    clearInterval(interval)
  }
  return {
    times,
    sections,
    choosen,
    chooseTime,
    mount,
    unmount
  }
}
