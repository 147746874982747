
export const modes = [
  {
    title: 'mode.advisory',
    value: 'advisory'
  },
  {
    title: 'mode.thesis-counseling',
    value: 'thesis-counseling'
  }
]
