import { SelectField } from '@shared/types'
import { AcademicManageSyllabusResourcesResponse } from '@shared/swagger'

export default (data: AcademicManageSyllabusResourcesResponse['activity_types']): SelectField['options'] => {
  const options: SelectField['options'] = []

  data.forEach((activity) => {
    options.push({
      label: activity.title,
      value: activity.id
    })
  })

  return options
}
