import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendancesResourcesRequest,
  AcademicSingleCourseAttendancesResourcesResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getAcademicInformationSchedule (params: AcademicSingleCourseAttendancesResourcesRequest): Promise<AxiosResponse<AcademicSingleCourseAttendancesResourcesResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendancesResourcesResponse>({
    url: 'academic/courses/attendances/resources',
    body: { params },
    loader: 'academic/courses/attendances/resources'
  })

  return response
}
