import { ref } from 'vue'

export const useToolbar = () => {
  const toolbar = ref<boolean>(false)

  const toggleToolbar = (value = true): void => {
    toolbar.value = value
  }

  return {
    toolbar,
    toggleToolbar
  }
}

export default useToolbar
