<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'card'"
    :full-width="true"
  />
  <pms-card :title="t('domains.academic.manage.curriculum.edit-course-settings-title')">
    <pms-smart-form
      class="d-flex flex-wrap"
      :fields="[courseSettingsFormFields]"
      :options="{
        controls: {class: 'w-100 mt-5'}
      }"
      :loader="saveLoader"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    />
  </pms-card>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import useCoreEditor from '../composable/useCoreEditor'
import { useLoader } from '@/shared/composable'

const { isActive } = useLoader()
const { t } = useI18n()
const loader = isActive('api-fetchCurriculumCore')

const saveLoader = isActive('put-academic/manage/curriculum/core')

const { courseSettings, courseSettingsFormFields, onSubmit } = useCoreEditor()

const onHandleChange = ({ name, newValue }) => {
  courseSettings.value[name] = newValue
}
</script>
<style scope>
.formItem {
  width: 200px
}
</style>
