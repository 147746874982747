<template>
  <pms-card
    :header="false"
  >
    <!-- relocate info -->
    <div class="text-center">
      <p class="fs-3 fw-bold m-0">
        {{ resource?.course.code }}. {{ resource?.course.title }} ({{ resource?.course.credits }} {{ $t('common.table.credits') }})
      </p>
      <p class="fs-5">
        {{ schedule?.room.title }} <br> {{ schedule?.day }} - {{ schedule?.time }}
      </p>
    </div>

    <!-- search form -->
    <div class="row align-items-center justify-content-center">
      <div class="col-6">
        <pms-smart-form
          :fields="[formFields]"
          @handleSubmit="onSubmit"
          @handleChange="onChange"
        >
          <template #formControls>
            <div class="d-flex mt-3">
              <pms-button
                class="w-100"
                type="submit"
                :text="loader? '...loading':$t('common.search')"
                :disabled="loader"
              />
            </div>
          </template>
        </pms-smart-form>
      </div>
    </div>
  </pms-card>

  <div
    v-if="!change_active"
    class="card mt-5"
  >
    <div class="card-body">
      <RelocateTable />
    </div>
  </div>

  <pms-teleport-modal
    :name="'relocate-modal'"
    :teleport-name="'relocate-modal-content'"
    :title="$t(`domains.academic-single-course.relocate.submit-title`)"
    :description="$t(`domains.academic-single-course.relocate.submit-description`)"
    :keyboard="false"
    :backdrop="false"
    :options="{
      content: {class: 'rounded'},
      header: {class: 'pb-0 border-0 justify-content-between'},
      body: {class: 'scroll-y pt-0'}
    }"
  />
  <SubmitModal v-if="modifier && modifier.name==='relocate-modal'" />
</template>
<script setup lang="ts">
import { onMounted } from 'vue'

import { useLoader, useTeleportModal } from '@/shared/composable'

import RelocateTable from './ui/RelocateTable.vue'
import SubmitModal from './ui/SubmitModal.vue'
import { useAttendanceRelocate, useSearchFormComposable } from './composable'
import { change_active } from './values'

const { resource, schedule, mount } = useAttendanceRelocate()
const { formFields, onSubmit, onChange } = useSearchFormComposable()
const { modifier } = useTeleportModal()
const { isActiveOneOf } = useLoader()

const loader = isActiveOneOf(['academic/courses/attendances/relocates'])

onMounted(mount)
</script>
