import { App } from 'vue'
import { router } from '@/router'
import axios from 'axios'

import VueApexCharts from 'vue3-apexcharts'
import InlineSvg from 'vue-inline-svg'
import VueGtag from 'vue-gtag'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'

import AppShadow from '@/shared/utils/shadow'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const runner = (vue: App<Element>): void => {
  const TRACKER_ID = process.env.APP_GOOGLE_TRACKER || ''

  vue.use(VueApexCharts)
  vue.component('InlineSvg', InlineSvg)
  vue.use(Notifications)

  vue.use(VueGtag, {
    enabled: true,
    appName: 'Personnel Management System',
    pageTrackerScreenviewEnabled: true,
    config: {
      id: TRACKER_ID,
      params: {
        anonymize_ip: true
      }
    }
  }, router)

  vue.use(VueAxios, axios)

  const uri = process.env.APP_API_URI || 'https://pmstest-api.sdu.edu.kz'
  const version = process.env.APP_API_VERSION || '1.0.0'

  vue.axios.defaults.baseURL = `${uri}/${version}/`
  vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  vue.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS'
  vue.axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type'

  AppShadow.init(vue)
}

export default {
  run: runner,
  priority: 99
}
