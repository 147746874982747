import type { AdministrativeGateEntryResponse } from '@shared/swagger'

const generateHtml = (headers, rows) => {
  let tableRows = ''

  rows.forEach((row, index) => {
    tableRows += `
      <tr>
        <td>${index + 1}</td>
        <td>${row.full_name}</td>
        <td>${row.access_date_in ? row.access_date_in : row.access_date_out}</td>
        <td>
            <pms-badge
            v-if="row.access_time_in != null"
            :color-variant="'tonal'"
            :color="'success'"
            class="fs-base"
          >
            <pms-kt-icon
              icon-name="black-down"
              class="text-success fs-5 ms-n1 mt-1"
            />
            ${row.access_time_in || ''}
          </pms-badge>
        </td>
        <td>
            <span
            v-if="row.access_time_out != null"
            class="text-dark fw-bold d-block fs-base"
          >
            <pms-badge
              v-if="row.access_time_in != null"
              :color-variant="'tonal'"
              :color="'danger'"
              class="fs-base"
            >
              <pms-kt-icon
                icon-name="black-up"
                class="text-danger fs-5 ms-n1 mt-1"
              />
              ${row.access_time_out || ''}
            </pms-badge>
          </span>
        </td>
        <td>${Math.floor(row.work_time / 60)}h ${row.work_time % 60}m</td>
      </tr>
    `
  })

  return `
    <head>
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700|Poppins:300,400,500,600,700|Roboto:300,400,500,600,700|Material+Icons" rel="stylesheet">
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
      <style>
      .table td, .table th {
        border: 1px solid #000 !important;
      }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  ${headers.map((header) => `<th>${header}</th>`).join('')}
                </tr>
              </thead>
              <tbody>
                ${tableRows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </body>
  `
}
export const printGateEntryRecords = (data: AdministrativeGateEntryResponse, headers: Array<string>): void => {
  const myWindow: Window | null = window.open('', '', 'width=1200,height=600')

  if (myWindow) {
    myWindow.document.write(generateHtml(headers, data))
    myWindow.document.close()
    myWindow.focus()
    setTimeout(() => myWindow.print(), 100)
  }
}
