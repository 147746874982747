<template>
  <div class="fw-semibold text-muted d-flex fs-6 mb-2">
    <p class="pe-5 mb-1">
      {{ $t('domains.knowledge.all-posts.category') }}: {{ categoryTitle }}
    </p>
  </div>
  <div
    class="fs-5 mb-1"
    v-html="description"
  />
</template>
<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useResource } from '@shared/stores'

const props = defineProps({
  categoryUuid: {
    type: String as PropType<string>,
    required: true
  },
  description: {
    type: String as PropType<string>,
    required: true
  }
})

const resource = useResource()
const categories = computed(() => resource.getKnowledgeManageResourceCategories)

const categoryTitle = computed(() => {
  const category = categories.value.find((category) => category.uuid === props.categoryUuid)
  return category?.title
})

</script>
