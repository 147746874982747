<template>
  <template
    v-if="
      Array.isArray(editor.sessions)
        ? editor.sessions.includes(sessionId)
        : editor.sessions == sessionId
    "
  >
    <pms-loader
      :height="30"
      :size="'sm'"
    />
  </template>
  <session-lock-icon v-else />
</template>
<script setup lang="ts">
import { useAttendanceEditor } from '../../composable'
import SessionLockIcon from './SessionLockIcon.vue'

const { editor } = useAttendanceEditor()

defineProps({
  sessionId: {
    type: Number,
    required: true
  }
})
</script>
