import { message_types } from '../enums'
import { MessageType } from '../type'

type ValidatorType = {
  validate: boolean
  errorMessage?: string
}

export const validator = (message: MessageType): ValidatorType => {
  if (message.type === null) {
    return {
      validate: false,
      errorMessage: 'domains.service.message-service.new-message.errors.no-type'
    }
  }

  if (message.subject === null || message.subject === '') {
    return {
      validate: false,
      errorMessage: 'domains.service.message-service.new-message.errors.no-subject'
    }
  }

  if (message.content === null || message.content === '') {
    return {
      validate: false,
      errorMessage: 'domains.service.message-service.new-message.errors.no-content'
    }
  }

  if (message.type === message_types.student && message.students.length === 0) {
    return {
      validate: false,
      errorMessage: 'domains.service.message-service.new-message.errors.no-students'
    }
  }

  return {
    validate: true
  }
}
