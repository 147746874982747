<template>
  <div
    id="profile-education"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.education.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-150px"
        @click="setIdAction(-1)"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.new') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div class="timeline">
        <div
          v-for="(item, index) in educations"
          :key="index"
          class="timeline-item"
        >
          <div class="timeline-line w-20px" />
          <div
            class=" timeline-icon"
            style="padding-left: 1px"
          >
            <i
              class="fa fa-genderless  bg-body  fs-1"
              :class="{
                'text-warning': item.is_ongoing,
                'text-success': !item.is_ongoing,
              }"
            />
          </div>
          <div class="fw-normal timeline-content ps-3">
            <div class="mb-3">
              <div class="d-flex align-items-start justify-content-between mb-0">
                <div>
                  <span class="fw-bold lh-1 fs-4 me-1">
                    {{ item.university }}
                  </span>
                  <span class="me-1 fs-4 lh-1">, {{ item.speciality }}</span>
                  <span
                    v-if="getLevelById(item.level_id)"
                    class="text-gray-800 badge badge-light-primary px-2 py-1 fs-6"
                  >
                    {{ getLevelById(item.level_id) }}
                  </span>
                  <div
                    v-if="item.country"
                    class="d-flex align-items-center fs-4"
                  >
                    <div class="text-muted fs-4 lh-1">
                      {{ getCountryById(item.country) }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ms-2 d-flex align-items-center gap-2 justify-content-end">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm btn-icon btn-light-primary"
                      @click="setIdAction(item.id)"
                    >
                      <pms-kt-icon
                        icon-name="notepad-edit"
                        class="fs-3 m-0 p-0"
                      />
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm btn-icon btn-light-primary"
                      @click="deleteAction(item.id, `${item.cipher}`)"
                    >
                      <pms-kt-icon
                        icon-name="trash"
                        class="fs-3 m-0 p-0"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <span class="me-1 fs-6 lh-1">{{ formatDate(item.start_date) }}{{ !item.is_ongoing ? ' - ' + formatDate(item.end_date || '') : '' }}</span>
              </div>
            </div>
            <div>
              <span class="fs-6 fw-bold">{{ $t('common.details') }}:</span>
              <p class="mb-1 fs-5">
                <span class="fw-bold">{{ $t('domains.profile.education.educations.table.cipher') }}:</span>
                {{ item.cipher }}
              </p>
              <p class="mb-1 fs-5">
                <span class="fw-bold">{{ $t('domains.profile.education.educations.table.registration-no') }}:</span>
                {{ item.registration_no }}
              </p>
              <p
                v-if="!item.is_ongoing"
                class="mb-1 fs-5"
              >
                <span class="fw-bold">{{ $t('domains.profile.education.educations.table.diploma-no') }}:</span>
                {{ item.diploma_no }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <pms-no-data v-if="educations.length ===0" />
      <EducationForm v-if="modifier && modifier.name === 'education'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import { useResource } from '@shared/stores'

import { useProfile } from '../../../store'
import EducationForm from './EducationForm.vue'

const { t } = useI18n()
const { dialog } = useAlert()
const { open, modifier, setModifier } = useTeleportModal()

const resource = useResource()
const store = useProfile()

const levels = computed(() => resource.getProfileResource?.edu_levels || [])
const country = computed(() => resource.getProfileResource?.countries || [])
const educations = computed(() => store.getEducations)

const formatDate = (date: string) => {
  if (!date) {
    return ''
  }

  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const setIdAction = (id: number) => {
  setModifier('education',
    { id: id, action: id === -1 ? 'new' : 'edit' },
    {
      title: id === -1 ? 'education-new-title' : 'education-edit-title',
      description: id === -1 ? 'education-new-description' : 'education-edit-description'
    }
  )
  open({ name: 'education-modal' })
}

const deleteAction = async (id: number, title?: string) => {
  dialog({
    title: 'domains.profile.education.are-you-sure',
    text: t('domains.profile.education.delete-text', { name: title }),
    onConfirm: async () => {
      await store.deleteEducation(id)
    }
  })
}

const getLevelById = (id: number|string): string => {
  return levels.value.find((level) => level.code === id)?.title || ''
}
const getCountryById = (id: number|string): string => {
  return country.value.find((country) => country.id === id)?.title || ''
}
</script>
