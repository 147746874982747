import { SelectField } from '@shared/types'
import { AcademicInformationSchedulesResourcesResponse } from '@shared/swagger'

export default (data: AcademicInformationSchedulesResourcesResponse['instructors']) => {
  const options: SelectField['options'] = []

  data.forEach((item) => {
    options.push({
      label: `${item.full_name}`,
      value: item.id
    })
  })

  return options
}
