<template>
  <BackToRequestBlock class="mb-5" />
  <ProgramRequestSearchSelectors />
  <div v-if="newRequestStore.isFetched">
    <MappedProgramCourses v-if="newRequestStore.program_request_type === 'C'" />
    <ProgramCoursesTable
      v-else-if="newRequestStore.program_request_type === 'E'"
      class="mt-3"
    />
  </div>
</template>

<script setup lang="ts">
import BackToRequestBlock from './BackToRequestBlock.vue'
import ProgramRequestSearchSelectors from './ProgramRequestSearchSelectors.vue'
import ProgramCoursesTable from './ProgramCoursesTable.vue'

import { useNewRequest } from '@domains/SectionManagement/features/NewRequest/store'
import MappedProgramCourses from '@domains/SectionManagement/features/NewRequest/ui/MappedProgramCourses.vue'

const newRequestStore = useNewRequest()
</script>
