<template>
  <pms-card
    :title="$t( 'domains.academic.advisory.table.header-advisory')"
    :description="$t('domains.academic.advisory.table.header-description-advisory')"
    :options="{
      body: {
        class: 'pt-1'
      }
    }"
  >
    <template #toolbar>
      <student-search />
    </template>
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="table-block-my-students"
      :headers="advisoryMyStudentsTable"
      :items="myStudents"
      :sortable="true"
      :sort-field="myStudentsSortField"
      @onSortFieldChange="handleSortClick"
    >
      <template #studentData="{ item }">
        <span
          class="cursor-pointer"
          @click="handleRedirectToStudentInfo(item.id)"
        >
          <MyStudentData :student="item" />
        </span>
      </template>
      <template #class_header>
        <select
          v-model="myStudentsCurrentClass"
          class="form-select"
        >
          <option :value="null" />
          <option
            v-for="option in classOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </template>
      <template #class="{ value }">
        <span class="fs-5 fw-bold">{{ value }}</span>
      </template>
      <template #speciality_header>
        <select
          v-model="myStudentsCurrentSpeciality"
          class="form-select"
        >
          <option :value="null" />
          <option
            v-for="option in specialityOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </template>
      <template #speciality="{ item }">
        <span
          class="fs-6"
          :data-bs-original-title="item.speciality.title "
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
        >
          {{ item.speciality.code+ ' - '+ item.speciality.language.code }}
        </span>
      </template>
      <template #email="{ item }">
        {{ item.email }}
      </template>
      <template #lastLogin="{ item }">
        {{ item.last_login_date }}
      </template>
      <template #birthdate="{ item }">
        {{ item.birth_date }}
      </template>

      <template #student-confirm="{ item }">
        <div
          v-if="item.financial_lock"
        >
          <pms-kt-icon
            :icon-name="'lock'"
            class="fs-4 text-danger opacity-75"
          />
        </div>
        <div
          v-else
        >
          <pms-kt-icon
            v-if="item.student_confirm"
            :icon-name="'check-square'"
            class="fs-1 text-success opacity-75"
          />
        </div>
      </template>
      <template #advisor-confirm="{ item }">
        <div
          v-if="item.advisor_confirm"
        >
          <pms-kt-icon

            :icon-name="'check-square'"
            class="fs-1 text-success opacity-75"
          />
        </div>
      </template>
    </pms-smart-table>
    <pms-pagination class="p-0 m-0 mt-6" />
  </pms-card>
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>
<script setup lang="ts">
import { useLoader, usePagination } from '@shared/composable'

import {
  advisoryMyStudentsTable, classOptions, myStudentsSortField,
  specialityOptions, myStudentsCurrentClass, myStudentsCurrentSpeciality
} from '../values'
import { useRedirectToSingleStudent } from '../composables'
import { useMyStudents } from '../store'

import MyStudentData from './MyStudentData.vue'
import StudentSearch from '@domains/AcademicAdvisory/ui/StudentSearch.vue'
import { storeToRefs } from 'pinia'
import { SmartTableSortField } from '@/shared/types'
import { watch } from 'vue'

const { isActive } = useLoader()
const { perPage } = usePagination()
const store = useMyStudents()
const { handleRedirectToStudentInfo } = useRedirectToSingleStudent()

const loader = isActive('academic/students/my')
const { myStudents } = storeToRefs(store)

const handleSortClick = (sortField: SmartTableSortField) => {
  myStudentsSortField.value = { ...sortField }
}
watch([myStudentsSortField, myStudentsCurrentClass, myStudentsCurrentSpeciality], async (newTableFields, oldTableFields) => {
  JSON.stringify(newTableFields) !== JSON.stringify(oldTableFields) &&
  await store.fetchMyStudents({
    per_page: perPage.value,
    offset: 1
  })
}, { deep: true })
</script>

<style scoped>
.student-tooltip {
  position: absolute;
  z-index: 999;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 200px;
  pointer-events: none;

  .student-image {
    width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}
</style>
