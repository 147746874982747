import type { ProfileAddressesGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

import { getProfileAddresses } from '@shared/api'
import { addressTypes } from '../../features/Addresses/values'
import { StoreType } from '../types'

export default async function fetchAddresses (this: StoreType): Promise<void> {
  const request: Array<Promise<{ address_type: string, response: AxiosResponse<ProfileAddressesGetResponse> }>> = []

  for (const addressType in addressTypes) {
    request.push(getProfileAddresses({ type: addressType }))
  }

  const responses = await Promise.all(request)

  responses.forEach(({ address_type, response }) => {
    const { ok, data } = response
    if (ok) {
      this.setAddress(address_type, data)
    }
  })
}
