import { SmartTableHeader } from '@/shared/types'

export const table_headers: Array<SmartTableHeader> = [
  {
    title: '№',
    field: 'number',
    slotCellName: 'number'
  },
  {
    title: 'common.table.title',
    field: 'title',
    slotCellName: 'title'
  },
  {
    title: 'common.table.description',
    field: 'description'
  }
]
