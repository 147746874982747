<template>
  <pms-container>
    <pms-row
      class="mt-10"
    >
      <announcement-form
        :title="$t('domains.service.announcements.create-announcement.title')"
        @save="handleAnnouncementFormSave"
      />
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useAlert } from '@/shared/composable'
import { ServiceManageAnnouncementsPostRequest } from '@/shared/swagger'
import { useAnnouncements } from '../../store'

import AnnouncementForm from '../../ui/AnnouncementForm.vue'

const router = useRouter()
const store = useAnnouncements()
const { success, error } = useAlert()

const handleAnnouncementFormSave = async (values: ServiceManageAnnouncementsPostRequest) => {
  const { status, message } = await store.createNewAnnouncement(values)
  if (status) {
    success({ text: message, isToast: true })
    router.push({ name: 'AnnouncementsPage' })
  } else {
    error({ text: message })
  }
}
</script>
