import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  AcademicManageSyllabusDescriptionsPutRequest,
  UnknownResponse
} from '@shared/swagger'

export default async function putAcademicManageSyllabusDescriptions (body: AcademicManageSyllabusDescriptionsPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<AcademicManageSyllabusDescriptionsPutRequest, UnknownResponse>({
    url: 'academic/manage/syllabus/descriptions',
    body,
    loader: 'academic/manage/syllabus/descriptions'
  })

  return response
}
