<template>
  <div class="wrapper">
    <div class="title">
      Personnel Information System
    </div>
  </div>
</template>
<script setup lang="ts">

</script>
<style scoped lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }

  .title {
    font-size: 2rem;
    text-align: center;
  }
</style>
