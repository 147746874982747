import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'
import { useAllStudents } from './store'
import { resetTempValues, resetAllStudentsSortField } from './values'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const student_id = to.query.student_id ? String(to.query.student_id) : undefined
  const store = useAllStudents()
  const pagination = usePagination()
  const fromName = String(from.name)
  if (!fromName.includes('SingleStudent') || from.query.previous_page !== 'AllStudentsPage') {
    store.$reset()
    pagination.$reset()
    resetTempValues()
    resetAllStudentsSortField()
  }

  if (!student_id) {
    store.bootstrap()
    next()
  }
  store.bootstrap(student_id)
  next()
}

export default {
  layout: 'default',
  path: '/academic/students',
  children: [
    {
      path: '',
      name: 'AllStudentsPage',
      component: () => import('./AllStudentsPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
