<template>
  <div>
    <pms-card
      :title="$t('domains.academic.single-course.navigation.items.exam-dates')"
      :description="$t('domains.academic.single-course.navigation.items.exam-dates-description')"
      :options="{
        body: { class: 'pt-2'}
      }"
    >
      <template #toolbar>
        <div class="d-flex flex-row align-center mt-3 gap-3">
          <span class="mt-3 fs-6">{{ $t('common.filter') }}:</span>
          <select
            v-model="currentView"
            class="form-select w-175px"
          >
            <option value="all">
              {{ $t('common.all') }}
            </option>
            <template
              v-for="(item, index) in viewOptions"
              :key="index"
            >
              <option :value="item">
                {{ $t(`common.assessments.${camelCaseToLowerKebabCase(item)}`) }}
              </option>
            </template>
          </select>
        </div>
      </template>
      <pms-smart-table
        :headers="tableHeaders"
        :items="examDates"
        :sortable="true"
      >
        <template #item-code="{ value, row }">
          <span
            v-styler="{ marginLeft: '15px'}"
            class="fw-bold fs-6"
          >{{ value }}.{{ row.section }}</span>
        </template>
        <template #item-title="{ value, row }">
          <div class="d-flex justify-content-start flex-column">
            <span class="text-gray-800 fw-bold text-primary mb-1 fs-6">
              {{ value }}
              <span
                v-styler="{ marginLeft: '3px' }"
                class="badge badge-light-primary fs-base"
              >{{ $t(`common.course-types.${camelCaseToLowerKebabCase(row.type)}`) }} </span>
            </span>
            <span class="text-gray-400 fw-semibold d-block fs-7">
              {{ row.instructor }}
            </span>
          </div>
        </template>
        <template #item-assessment="{value}">
          <span class="fw-bold fs-6">{{ $t(`common.assessments.${camelCaseToLowerKebabCase(value)}`) }}</span>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { camelCaseToLowerKebabCase } from '@shared/utils'

import { useSingleCourse } from '../../../store'
import { tableHeaders } from '../values'

const store = useSingleCourse()

const currentView = ref<string>('all')
const viewOptions = computed(() => {
  return store.getExamDates.reduce((acc: Array<string>, item) => {
    if (!acc.includes(item.assessment)) {
      acc.push(item.assessment)
    }

    return acc
  }, [])
})
const examDates = computed(() => {
  return store.getExamDates.filter((item) => {
    if (currentView.value === 'all') return true
    return currentView.value === item.assessment
  })
})
</script>
