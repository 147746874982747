import type { StoreType } from '../types'
import { getMessageTranslatePaths } from '@shared/api'

export default async function bootstrap (this: StoreType): Promise<void> {
  const { ok, data } = await getMessageTranslatePaths()

  if (ok) {
    this.setPaths(data as Array<string>)
  }
}
