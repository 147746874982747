import type { SmartTableHeader } from '@shared/types'

export const tableHeaders: Array<SmartTableHeader> = [
  {
    title: 'common.table.student-id',
    field: 'studentId',
    slotCellName: 'student'
  },
  {
    title: 'common.table.level',
    field: 'level'
  },
  {
    title: 'common.table.class',
    field: 'class'
  },
  {
    title: 'common.table.type',
    field: 'type'
  },
  {
    title: 'common.table.date',
    field: 'date'
  },
  {
    title: 'common.table.message',
    field: 'message',
    slotCellName: 'message',
    headerClass: 'w-300px'
  },
  {
    title: 'common.table.status',
    field: 'status'
  },
  {
    title: 'common.table.action',
    field: 'actions',
    headerClass: 'w-100px',
    cellClass: 'text-center'
  }
]
