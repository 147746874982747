<template>
  <pms-button
    type="button"
    class="btn btn-danger w-180px"
    :variant="'outlined'"
    :color="'danger'"
    :title="'delete selected'"
    @click="showDeletePopup($event)"
  >
    <i class="bi bi-pencil" />
    {{ $t('domains.academic.curricula.delete-electives-from-curricula') }}
  </pms-button>
</template>
<script lang="ts" setup>
import { useConfirm } from 'primevue/useconfirm'
import useElectiveDeleter from '../composable/useElectiveDeleter'

const props = defineProps({
  electiveType: {
    type: String,
    required: true
  }
})

const { handleDeleteElectives } = useElectiveDeleter(props.electiveType)

const confirm = useConfirm()

const showDeletePopup = (event) => {
  confirm.require({
    target: event.currentTarget,
    group: 'delete',
    message: 'Delete selected electives',
    icon: 'pi pi-exclamation-circle',
    acceptIcon: 'pi pi-check',
    rejectIcon: 'pi pi-times',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'p-button-outlined p-button-sm',
    acceptClass: 'p-button-sm',
    accept: () => {
      handleDeleteElectives()
    }
  })
}
</script>
