import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesGetRequest } from '@shared/swagger'

import { getAcademicCoursesAttendances } from '@shared/api'

export default async function fetchAttendance (this: StoreType, params: AcademicSingleCourseAttendancesGetRequest): Promise<void> {
  const { data, ok } = await getAcademicCoursesAttendances(params)

  if (ok) {
    this.setAttendance(data)
  }
}
