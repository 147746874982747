/* eslint-disable camelcase */
import { StatusMessageResponse } from '@/shared/types'
import type { StoreType } from '../types'
import { ServiceAdvisorAssignmentAssginStudentsToAdvisorRequest } from '@/shared/swagger/entities/AdvisoryAssignment/Request'
import assignStudentsToAdvisor from '@/shared/api/AdvisorAssignment/assignStudentsToAdvisor'

export default async function actionAssignStudentsToAdvisor (this: StoreType, data: ServiceAdvisorAssignmentAssginStudentsToAdvisorRequest): Promise<StatusMessageResponse> {
  const response = await assignStudentsToAdvisor(data)

  return {
    message: response.message,
    status: response.ok
  }
}
