<template>
  <Teleport to="#knowledge-categories-content">
    <pms-smart-form
      :fields="formFields"
      :options="formOptions"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="form-group mt-3 d-flex justify-content-end gap-2">
          <pms-button
            type="reset"
          >
            {{ $t('common.reset') }}
          </pms-button>
          <pms-button
            class="w-150px"
            type="submit"
            :text="$t('common.submit')"
          />
        </div>
      </template>
    </pms-smart-form>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAlert, useTeleportModal } from '@shared/composable'
import type { FormGroup, FormOptions } from '@shared/types'
import { objectDeepCopy } from '@shared/utils'
import { useResource } from '@shared/stores'

import { knowledgeManageResourcesLanguagesToOptions } from '../utils'
import { useKnowledgeCategories } from './../store'

const { error, success } = useAlert()
const { t } = useI18n()
const { close, show, modifier, unsetModifier } = useTeleportModal()

const resource = useResource()
const store = useKnowledgeCategories()

const categories = computed(() => store.getKnowledgeCategories)

const tempValues = ref({
  uuid: '',
  language_id: -1,
  title: '',
  details: ''
})

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async ({ values }) => {
  if (!values.language_id) {
    error({ text: t('knowledge.manage.categories.language-field-error') })
    return
  }
  if (!values.title) {
    error({ text: t('knowledge.manage.categories.title-field-error') })
    return
  }
  const { status, message } =
  modifier.value?.data?.action === 'new'
    ? await store.createCategory({ ...values })
    : await store.editCategory(tempValues.value.uuid, { ...values })
  if (status) {
    success({ text: message, isToast: true })
    close({ name: 'knowledge-categories-modal' })
    unsetModifier()
    store.bootstrap()
  } else {
    error({ text: message })
  }
}

const formOptions : FormOptions = {
  labelContainer: {
    left: {
      class: 'col-md-3 col-sm-6'
    },
    right: {
      class: 'col-md-9'
    }
  },
  group: {
    class: 'mb-4'
  }
}

const formFields = computed<Array<FormGroup>>(() => {
  const languageResource = resource.getKnowledgeManageResourceLanguages
  return [
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.title')}`
      },
      fields: [
        {
          name: 'title',
          type: 'text',
          value: tempValues.value.title || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.language')}`
      },
      fields: [
        {
          type: 'select',
          name: 'language_id',
          options: knowledgeManageResourcesLanguagesToOptions(languageResource),
          value: tempValues.value.language_id || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    },
    {
      label: {
        class: 'fw-semibold fs-5',
        text: `${t('common.fields.details')}`
      },
      fields: [
        {
          name: 'details',
          type: 'textarea',
          value: tempValues.value.details || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required'
            ]
          }
        }
      ]
    }
  ]
})

watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data && newValue.data.id && newValue.data.action) {
      if (newValue.data.action === 'edit') {
        const exactCategory = categories.value.find((item) => item.id === newValue.data?.id)
        if (exactCategory) {
          tempValues.value = objectDeepCopy(exactCategory)
        }
      }
      show()
    }
  }, { immediate: true }
)
</script>
