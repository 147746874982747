<template>
  <changelog-wrapper
    :title="'domains.release.changelog.title'"
    :version="version"
  >
    <pms-page-shimmer
      v-if="loader"
      :shimmer="'list'"
      :full-width="true"
    />
    <pms-card
      v-else
      :header="false"
    >
      <pms-no-data v-if="items.length === 0" />
      <ChangelogItems
        v-else
        :items="items"
      />
    </pms-card>
  </changelog-wrapper>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { getReleaseChangelog } from '@shared/api'
import { useAlert, useLoader } from '@shared/composable'
import type { ReleaseChangelogResponse } from '@shared/swagger'

import ChangelogItems from './ui/ChangelogItems.vue'
import ChangelogWrapper from './ui/ChangelogWrapper.vue'

const { isActive } = useLoader()
const { error } = useAlert()
const version = process.env.APP_VERSION as string
const loader = isActive('release/changelog')
const items = ref<ReleaseChangelogResponse>([])

onMounted(async () => {
  const { ok, data, message } = await getReleaseChangelog()
  if (ok) {
    items.value = data
  } else {
    error({ text: message })
  }
})
</script>
