import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceManageSingleAnnouncementGetResponse, UnknownRequest } from '@shared/swagger'

export default async function getServiceManageLanguagesApplicants (announcement: string): Promise<AxiosResponse<ServiceManageSingleAnnouncementGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ServiceManageSingleAnnouncementGetResponse>({
    url: `service/manage/announcements/${announcement}`,
    body: {},
    loader: 'service/manage/announcements/single'
  })

  return response
}
