import { defineStore } from 'pinia'
import * as actions from './actions'
import type { Name, State, Getters, Actions } from './types'

export const useAnnouncements = defineStore<Name, State, Getters, Actions>('Service.useAnnouncements', {
  actions: {
    ...actions
  },

  state () {
    return {
      announcements: [],
      singleAnnouncement: null,
      targets: []
    }
  }
})
