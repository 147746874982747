import { useRouter } from 'vue-router'
import { useLoader } from '@/shared/composable'
import { SingleContent } from '@/shared/types'

import { services } from '../../values'
import { service, contents } from '../values'

export const fetchService = async (serviceId) => {
  if (!serviceId) return

  const router = useRouter()
  const { activate, deactivate } = useLoader()

  activate('single-service')
  const singleService = services.find((service) => service.id === serviceId)

  if (!singleService) {
    router.push({ path: '/services' })
  } else {
    service.value = singleService
    contents.value = await singleService.contents() as SingleContent
  }
  deactivate('single-service')
}
