<template>
  <pms-container class="mt-2 pb-10">
    <pms-row>
      <pms-grid :col="12">
        <div class="d-flex justify-content-between mt-8 mb-3 align-items-end">
          <div class="d-flex flex-row align-items-center gap-3">
            <h2 class="pt-1 pt-md-0 fs-1">
              {{ $t(title) }}
            </h2>
            <a
              href="javascript:void(0)"
              class="btn btn-primary btn-sm"
              @click="router.back()"
            >
              {{ $t("common.back") }}
            </a>
          </div>
          <div class="me-2">
            <span class="badge badge-primary fs-4"> v{{ version }} </span>
          </div>
        </div>
      </pms-grid>
      <pms-grid :col="12">
        <slot />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'

defineProps({
  title: {
    type: String,
    required: true
  },
  version: {
    type: String,
    required: true
  }
})

const router = useRouter()
</script>
