import { FormGroup } from '@/shared/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { IAnnouncementFormEmits, IAnnouncementFormProps } from '../types'
import { ServiceManageAnnouncementsPostRequest, ServiceManageSingleAnnouncementGetResponse } from '@/shared/swagger'
import { useAnnouncements } from '../store'
import { storeToRefs } from 'pinia'
import moment from 'moment'

const useAnnouncementForm = (emit: IAnnouncementFormEmits, props: Required<IAnnouncementFormProps>) => {
  const { t } = useI18n()
  const store = useAnnouncements()
  const { targets } = storeToRefs(store)

  const tempValues = ref<ServiceManageSingleAnnouncementGetResponse>(props.announcement)

  const formFields = computed((): Array<FormGroup> => [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.table.title_en')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-6'
          },
          name: 'title_en',
          type: 'text',
          value: tempValues.value.title_en
        },
        {
          label: {
            text: `${t('common.table.content_en')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-12 mt-3'
          },
          name: 'content_en',
          type: 'editor',
          required: true,
          value: tempValues.value.content_en,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              {
                func: 'test',
                args: [
                  'Validation',
                  t(
                    'domains.service.announcements.announcement-form.content-en-required'
                  ),
                  (value) => !!value && !!value.length
                ]
              }
            ]
          }
        },
        {
          label: {
            text: `${t('common.table.title_ru')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-6 mt-3'
          },
          name: 'title_ru',
          type: 'text',
          value: tempValues.value.title_ru
        },

        {
          label: {
            text: `${t('common.table.content_ru')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-12 mt-3'
          },
          name: 'content_ru',
          type: 'editor',
          value: tempValues.value.content_ru
        },
        {
          label: {
            text: `${t('common.table.title_kz')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-6 mt-3'
          },
          name: 'title_kz',
          type: 'text',
          value: tempValues.value.title_kz
        },

        {
          label: {
            text: `${t('common.table.content_kz')}`,
            class: 'mb-1'
          },
          group: {
            class: 'col-12 mt-3'
          },
          name: 'content_kz',
          type: 'editor',
          value: tempValues.value.content_kz
        },
        {
          label: {
            text: `${t('common.common.table.exam-start-date')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-6 mt-3'
          },
          name: 'start_date',
          type: 'date',
          binding: {
            min: moment().format('YYYY-MM-DD')
          },
          value: tempValues.value.start_date.split(' ')[0],
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('domains.service.announcements.announcement-form.start-date-required') }
            ]
          }
        },
        {
          label: {
            text: `${t('common.common.table.end-date')}`,
            class: 'mb-1 required'
          },
          group: {
            class: 'col-6 mt-3'
          },
          name: 'end_date',
          type: 'date',
          binding: {
            min: moment(tempValues.value.start_date).format('YYYY-MM-DD')
          },
          value: tempValues.value.end_date.split(' ')[0],
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'string',
              'required',
              { func: 'label', value: t('domains.service.announcements.announcement-form.end-date-required') }
            ]
          }
        },
        {
          group: {
            class: 'col-6 mt-3'
          },
          label: {
            text: t('domains.service.announcements.announcement-form.announcement-area'),
            class: 'required mb-1'
          },
          name: 'targets',
          type: 'checkbox',
          value: tempValues.value.targets.map(n => n + ''),
          options: targets.value.map(t => ({
            label: { text: t.title, class: 'text-dark' },
            id: String(t.id),
            value: t.id + ''
          })),
          required: true,
          validator: {
            schema: 'yup',
            rules: [
              'array',
              'required',
              {
                func: 'test',
                args: [
                  'Validation',
                  t(
                    'domains.service.announcements.announcement-form.announcement-area-validation'
                  ),
                  (value) => value.length > 0
                ]
              }
            ]
          }
        }
      ]
    }
  ])

  const onHandleChange = ({ name, newValue }) => {
    tempValues.value[name] = newValue
  }

  const onSubmit = ({ values }) => {
    const newAnnouncement = values as ServiceManageAnnouncementsPostRequest
    emit('save', newAnnouncement)
  }
  return {
    formFields,
    onHandleChange,
    onSubmit
  }
}

export default useAnnouncementForm
