<template>
  <pms-container>
    <pms-row class="mt-6">
      <pms-grid :col="12">
        <pms-page-header
          class="mt-3"
          title="domains.knowledge.manage.title"
          description="domains.knowledge.manage.description"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex gap-3 justify-content-between mb-2 mt-md-0">
              <pms-driver-button />
              <pms-feedback-modal :options="[]" />
            </div>
          </template>
        </pms-page-header>
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </pms-grid>
    </pms-row>
    <pms-row v-if="knowledgeManageLoader || resourceLoader">
      <pms-page-shimmer
        :shimmer="'list'"
        :filter="true"
      />
    </pms-row>
    <pms-row v-else>
      <pms-grid :col="8">
        <pms-card
          :header="false"
          :options="{
            body: {
              class: 'pt-0 pb-2'
            }
          }"
        >
          <KnowledgeManagePostListingTitle class="mb-4" />
        </pms-card>
        <posts-component />
      </pms-grid>
      <pms-grid :col="4">
        <new-article-btn id="new-post" />
        <knowledge-manage-post-filter id="knowledge-manage-post-filter" />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import { useDriver, useLoader, useTour } from '@shared/composable'

import PostsComponent from './ui/PostsComponent.vue'
import KnowledgeManagePostFilter from './ui/KnowledgeManagePostFilter.vue'
import NewArticleBtn from './ui/NewArticleBtn.vue'
import KnowledgeManagePostListingTitle from './ui/KnowledgeManagePostListingTitle.vue'

import { tour, quickTourSteps } from './values'

const { isActive } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()

const knowledgeManageLoader = isActive('knowledge/manage/posts')
const resourceLoader = isActive('knowledge/manage/resources')

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickTourSteps())
})
</script>
