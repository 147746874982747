import type { SelectField } from '@/shared/types'
import type { ResourceResponse } from '@shared/swagger'

export default (yearTerms: ResourceResponse['year_terms']): SelectField['options'] => {
  return [
    ...new Set(
      yearTerms.map((yearTerm) => yearTerm.year)
    )
  ].map((year) => ({
    label: year,
    value: year
  })) as unknown as SelectField['options']
}
