<template>
  <div
    v-if="courses.length > 0"
    class="col-12"
  >
    <pms-card
      :title="'domains.academic.my-sections.list.title'"
      :description="'domains.academic.my-sections.list.description'"
      :options="{
        body: { class: 'pt-0' }
      }"
    >
      <pms-smart-table
        :headers="tableHeaders"
        :items="courses"
        :sortable="true"
        :options="{ wrapper: { responsive: false }}"
      >
        <template #details="{value}">
          <span class="fs-bold fs-5">{{ value }}</span>
        </template>
        <template #courseCode="{ value, item }">
          <span class="fw-bold fs-6">{{ value }}</span>
          <span class="fw-semibold fs-5 ms-2">[{{ item.section }}-{{ item.type }} ]</span>
        </template>
        <template #item-courseTitle="{ row }">
          <span class="fw-bold fs-6">{{ row[`title_${localeShortest}`] }}</span>
        </template>
        <template #item-studentsCount="{ row }">
          {{ row.students_count }}
        </template>
        <template #item-action="{ row }">
          <my-course-actions :course="row" />
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <empty-card
    v-else
    title="domains.academic.my-sections.empty"
  />
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { useLocale } from '@shared/composable'
import type { AcademicCoursesResourcesResponse, AcademicMySectionsGetResponse } from '@shared/swagger'

import { tableHeaders } from '../values'
import MyCourseActions from './MyCourseActions.vue'
import EmptyCard from '@/shared/ui/components/EmptyCard.vue'

defineProps({
  courses: {
    type: Array as PropType<AcademicMySectionsGetResponse>,
    required: true
  },
  currentYearTerm: {
    type: Object as PropType<AcademicCoursesResourcesResponse['my_year_terms'][0]>,
    required: true
  }
})

const { localeShortest } = useLocale()
</script>
