import type { StoreType } from '../types'
import type { ResourceRequest } from '@shared/swagger'

import { getProfileResource } from '@shared/api'

export default async function fetchProfileResource (this: StoreType, body: ResourceRequest): Promise<void> {
  const { ok, data } = await getProfileResource(body)

  if (ok) {
    this.setProfileResource(data)
  }
}
