<template>
  <pms-card
    :header="false"
  >
    <pms-loader v-if="loader" />
    <pms-smart-table
      v-else
      id="all-students-table"
      :items="students"
      :headers="allStudentsTable"
      :sortable="true"
      :sort-field="allStudentsSortField"
      @onSortFieldChange="handleSortClick"
    >
      <template #studentData="{ item }">
        <span
          class="cursor-pointer"
          @click="handleRedirectToStudentInfo(item.id)"
        >
          <StudentData :student="item" />
        </span>
      </template>
      <template #class="{ value }">
        <span class="fs-5 fw-bold">{{ value }}</span>
      </template>
      <template #academicStatus="{ item }">
        <pms-badge
          v-if="item.academic_status"
          :value="item.academic_status"
          :color="getAcademicStatus(item.academic_status)"
          :size="'md'"
          class="me-2"
        />
      </template>
      <template #speciality="{ item }">
        <span
          class="fs-6"
          :data-bs-original-title="`${item.speciality.code}/${item.speciality.year}/${item.speciality.language.code}/${item.academic_level}`"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
        >
          {{ item.speciality.title }}
        </span>
      </template>
      <template #entranceYear="{ item }">
        {{ item.entrance_year }}
      </template>
      <template #financial-lock="{ item }">
        <div
          v-if="item.financial_lock"
          :title="'Financial lock'"
        >
          <pms-kt-icon
            :icon-name="'lock'"
            class="fs-4 text-danger opacity-75"
          />
        </div>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </pms-card>
  <div class="student-tooltip">
    <img
      class="student-image"
      src=""
    >
  </div>
</template>
<script setup lang="ts">
import { useAlert, useLoader, usePagination } from '@/shared/composable'

import { useAllStudents } from './../store'
import { allStudentsSortField, allStudentsTable, tempValues } from './../values'
import StudentData from '@domains/AcademicAllStudents/ui/StudentData.vue'
import { LocationQueryRaw, useRouter } from 'vue-router'
import { SmartTableSortField } from '@/shared/types'
import { useI18n } from 'vue-i18n'
import { StudentStatus } from '@/shared/enums'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const { perPage } = usePagination()
const { error } = useAlert()
const { t } = useI18n()
const router = useRouter()
const store = useAllStudents()
const studentsState = storeToRefs(store)
const loader = isActive('academic/students')

const students = studentsState.students

function handleRedirectToStudentInfo (id: number) {
  const query : LocationQueryRaw = {}
  query.id = String(id)
  query.previous_page = 'AllStudentsPage'

  router.push({
    path: '/academic/student',
    query
  })
}

function getAcademicStatus (status: string) {
  let color = 'primary'
  switch (status) {
    case StudentStatus.NotStudying: {
      color = 'secondary'
      break
    }
    case StudentStatus.Graduated: {
      color = 'success'
      break
    }
  }

  return color
}

const handleSortClick = async (sortField: SmartTableSortField) => {
  const isNewOrder = JSON.stringify(sortField) !== JSON.stringify(allStudentsSortField.value)
  if (Object.values(tempValues.value).some(value => !!value)) {
    allStudentsSortField.value = { ...sortField }
    isNewOrder && await store.fetchStudents({
      per_page: perPage.value,
      offset: 1
    })
  } else {
    error({ text: t('domains.academic-all-students.filter-not-selected') })
  }
}
</script>

<style scoped>
.student-tooltip {
  position: absolute;
  z-index: 999;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 200px;
  pointer-events: none;

  .student-image {
    width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}
</style>
