import { ref, computed } from 'vue'

const windowHeight = ref(window.innerHeight)
const windowWidth = ref(window.innerWidth)

const windowDevice = computed(() => {
  const size = windowWidth.value
  if (size < 640) return 'mobile'
  if (size < 768) return 'tablet'
  if (size < 1024) return 'laptop'
  return 'desktop'
})

const isMobile = computed(() => windowDevice.value === 'mobile')
const isTablet = computed(() => windowDevice.value === 'tablet')
const isLaptop = computed(() => windowDevice.value === 'laptop')

export const useWindow = () => {
  const onWindowMounted = () => {
    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth
    })
  }

  const onWindowUnMounted = () => {
    window.removeEventListener('resize', () => {
      windowWidth.value = window.innerWidth
    })
  }

  return {
    height: windowHeight,
    width: windowWidth,
    device: windowDevice,
    isMobile,
    isTablet,
    isLaptop,
    onWindowMounted,
    onWindowUnMounted
  }
}
export default useWindow
