<template>
  <pms-card
    id="search-course-widget"
    :title="'common.search'"
    :description="$t('domains.academic.manage.courses.search.description', { year })"
    :options="{ description: { class: 'mt-1 fs-7 text-muted' }, body: { class: 'pt-0'}}"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
    >
      <template #department_code="{ setValue }">
        <label
          class="mb-1"
        >{{ $t('common.select-department') }}:</label>
        <treeselect
          :multiple="false"
          :options="sortedDep"
          placeholder=""
          class="styled"
          @select="(value)=>setValue(value.id)"
        />
      </template>
      <template #formControls>
        <div
          class="form-group d-flex justify-content-center mt-3"
        >
          <div
            class="flex-fill"
          >
            <pms-button
              type="submit"
              class="w-100 "
              :disabled="loader"
              :loader="searchLoader"
              :text="$t('common.search')"
            />
          </div>
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useLoader } from '@shared/composable'

import { useManageCourses } from '../store'
import { useCourseForm } from '../composable'

import Treeselect from 'vue3-treeselect'
import { departmentsToDepartmentOptions } from '../utils'

const { formFields, onSubmit, resource } = useCourseForm()
const { isActive } = useLoader()
const store = useManageCourses()
const loader = isActive('academic/resources')
const searchLoader = isActive('academic/courses')

const sortedDep = computed(() => departmentsToDepartmentOptions(resource.getAcademicResource))
const year = computed(() => store.getYear)
</script>
