import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendanceRelocatesGetRequest } from '@shared/swagger'

import { getAcademicSingleCourseAttendanceRelocates } from '@shared/api'
import { relocate_date, change_active } from '../../features/Attendance/features/AttendanceRelocate/values'

export default async function (this: StoreType, params: AcademicSingleCourseAttendanceRelocatesGetRequest): Promise<void> {
  const { data, ok } = await getAcademicSingleCourseAttendanceRelocates(params)

  if (ok) {
    data.hours.sort((a, b) => a.start_time > b.start_time ? 1 : 0)
    data.rooms.sort((a, b) => a.title > b.title ? 1 : 0)
    this.setAttendanceRelocates(data)
    relocate_date.value = params.date
    change_active.value = false
  }
}
