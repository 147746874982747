import type { StoreType } from '../types'
import { useAlert } from '@shared/composable'
import { getAcademicInformationConsentRequestSections } from '@shared/api'
import { AcademicInformationConsentRequestSectionsGetResponse, AcademicInformationConsentRequestSectionsGetRequest } from '@shared/swagger'

export default async function fetchConsentRequestsSections (this: StoreType, params: AcademicInformationConsentRequestSectionsGetRequest): Promise<void> {
  const { error } = useAlert()

  const setData = (data: AcademicInformationConsentRequestSectionsGetResponse): void => {
    this.setConsentRequestsSections(data || [])
  }

  const { ok, message, data } = await getAcademicInformationConsentRequestSections(params)

  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }
}
