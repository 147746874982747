import { SingleStudentSidebarItem } from '../types'

export const menuItems: Array<SingleStudentSidebarItem> = [
  {
    to: '/academic/student',
    routeName: 'SingleStudentInformationPage',
    label: 'domains.academic.single-student.navigation.items.information'

  },
  {

    to: '/academic/student/course-registration',
    routeName: 'SingleStudentCourseRegistrationPage',
    label: 'domains.academic.single-student.navigation.items.course-registration'

  },
  {

    to: '/academic/student/grades',
    routeName: 'SingleStudentGradesPage',
    label: 'domains.academic.single-student.navigation.items.grades'

  },
  {

    to: '/academic/student/attendance',
    routeName: 'SingleStudentAttendancePage',
    label: 'domains.academic.single-student.navigation.items.attendance'

  },
  {

    to: '/academic/student/schedule',
    routeName: 'SingleStudentSchedulePage',
    label: 'domains.academic.single-student.navigation.items.schedule'

  },
  {

    to: '/academic/student/curriculum',
    routeName: 'SingleStudentCurriculumPage',
    label: 'domains.academic.single-student.navigation.items.curriculum'

  },
  {

    to: '/academic/student/transcript',
    routeName: 'SingleStudentTranscriptPage',
    label: 'domains.academic.single-student.navigation.items.transcript'

  },
  {

    to: '/academic/student/accounting',
    routeName: 'SingleStudentAccountingPage',
    label: 'domains.academic.single-student.navigation.items.accounting'

  }
]
