import { ScheduleManagementSectionProgramGetTeachingHoursRequest } from '@/shared/swagger'
import type { StoreType } from '../types'
import getTeachingHours from '@/shared/api/ScheduleManagement/getTeachingHours'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchTeachingHours (this: StoreType, query: ScheduleManagementSectionProgramGetTeachingHoursRequest): Promise<StatusMessageResponse> {
  const { ok, data, message } = await getTeachingHours(query)

  if (ok) {
    this.setTeachingHours(data)
  }

  return {
    status: ok,
    message
  }
}
