<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <div class="d-flex gap-2">
        <AcademicAdvisoryModeSelector id="type-selector" />
        <pms-driver-button />
        <pms-feedback-modal :options="feedback" />
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </div>
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row :class="{ 'mt-6' : !toolbar }">
      <pms-grid :col="12">
        <MyStudentsTable v-if="store.mode === 'advisory'" />
        <ThesisStudentsTable v-else />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import AcademicAdvisoryModeSelector from './ui/AcademicAdvisoryModeSelector.vue'
import MyStudentsTable from './ui/MyStudentsTable.vue'
import ThesisStudentsTable from './ui/ThesisStudentsTable.vue'

import { header, tour, feedback, quickMyStudentsSetTourSteps } from './values'

import { onMounted } from 'vue'
import { useToolbar, useTour, useDriver } from '@shared/composable'
import { useMyStudents } from './store'

const { setSteps: setQuickTourSteps } = useDriver()
const { toolbar, toggleToolbar } = useToolbar()
const { status, steps, launch, setSteps } = useTour([])
const store = useMyStudents()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(await quickMyStudentsSetTourSteps())
})
</script>
