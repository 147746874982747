<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <pms-card
    :title="$t('domains.profile.overview.knowledge.title')"
    :description="$t('domains.profile.overview.knowledge.description')"
    :options="{body: {class: 'pt-0'}}"
  >
    <template
      v-for="(post, index) in posts"
      :key="index"
    >
      <pms-bank-single-post
        class="mb-2"
        :device="device"
        :post="post"
        :view="'short'"
        :description-length="100"
      />
    </template>
    <div
      v-if="posts.length === 0"
      class="text-center mt-10 mb-10"
    >
      {{ $t('common.no-data') }}
    </div>
  </pms-card>
</template>

<script setup lang="ts">
import { deviceType } from '@shared/utils'

const device = deviceType()
const posts = []
</script>
