import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  AcademicInformationConsentRequestSectionsGetRequest,
  AcademicInformationConsentRequestSectionsGetResponse
} from '@shared/swagger'

export default async function getAcademicInformationConsentRequestSections (params: AcademicInformationConsentRequestSectionsGetRequest): Promise<AxiosResponse<AcademicInformationConsentRequestSectionsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicInformationConsentRequestSectionsGetResponse>({
    url: `academic/information/consent-requests/sections/${params.section}`,
    body: { params: params },
    loader: 'academic/information/consent-requests/sections'
  })

  return response
}
