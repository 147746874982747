import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { StudentSignInRequest, StudentSignInResponse } from '@shared/swagger'

export default async function postStudentAuthenticate (body: StudentSignInRequest): Promise<AxiosResponse<StudentSignInResponse>> {
  const { post } = useRequests()

  const response = post<StudentSignInRequest, StudentSignInResponse>({
    url: 'student/sign-in',
    body,
    configs: { token: false },
    loader: 'student/sign-in',
    camelize: true
  })

  return response
}
