<template>
  <div class="p-3">
    <div class="d-flex justify-content-end">
      <button
        v-if="item.status == 2 || item.status == 1 || item.status == 0"
        class="btn btn-md btn-light-danger m-2"
        @click="putContentRequest(item.id, 3)"
      >
        {{ $t('common.decline') }}
      </button>
      <button
        v-if="item.status == 3 || item.status == 1 || item.status == 0"
        class="btn btn-md btn-light-success m-2"
        @click="putContentRequest(item.id, 2)"
      >
        {{ $t('common.accept') }}
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAlert } from '@/shared/composable'
import type { AcademicInformationConsentRequestSectionsGetResponse } from '@shared/swagger'
import { PropType } from 'vue'
import { useConsentRequest } from '../store'

const { success, error } = useAlert()
const store = useConsentRequest()

const putContentRequest = async (currStatus: number, newStatus: number) => {
  const { status, message } = await store.putConsentRequest(currStatus, { status: newStatus })
  if (status) {
    success({ text: message, isToast: true })
  } else {
    error({ text: message })
  }
}

defineProps({
  item: {
    type: Object as PropType<AcademicInformationConsentRequestSectionsGetResponse[0]>,
    required: true
  }
})
</script>
