<template>
  <div class="d-flex gap-3 align-items-center">
    <span class="fs-4">{{ $t('common.common.table.year') }}:</span>
    <Calendar
      v-model="year"
      view="year"
      date-format="yy"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { setTempValues, tempValues } from '../values'

import Calendar from 'primevue/calendar'

const year = ref<Date>(tempValues.value.year)

watch(() => year.value, () => setTempValues({ year: year.value }))

</script>
