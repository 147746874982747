import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicInformationExamDatesPutRequest, AcademicInformationExamDatesPutResponse } from '@shared/swagger'

export default async function putAcademicInformationExamDates (body: AcademicInformationExamDatesPutRequest): Promise<AxiosResponse<AcademicInformationExamDatesPutResponse>> {
  const { put } = useRequests()

  const response = await put<AcademicInformationExamDatesPutRequest, AcademicInformationExamDatesPutResponse>({
    url: 'academic/information/exam-dates',
    body: body,
    loader: 'put/academic/information/exam-dates'
  })

  return response
}
