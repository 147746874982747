import { ScheduleManagementHoursRequest } from '@/shared/swagger'
import type { StoreType } from '../types'
import getYearTermScheduleHours from '@/shared/api/ScheduleManagement/getYearTermScheduleHours'

export default async function fetchYearTermScheduleHoursResources (this: StoreType, body: ScheduleManagementHoursRequest): Promise<void> {
  const { ok, data } = await getYearTermScheduleHours({
    term: body.term,
    year: body.year
  })

  if (ok) {
    this.setYearTermScheduleHoursResources(data)
  }
}
