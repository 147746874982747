// AcademicManageCurriculumPutReferenceRequest
import { useRequests } from '@shared/composable'
import type { StatusMessageResponse } from '@shared/types'
import type { AcademicManageCurriculumPutReferenceRequest } from '@shared/swagger'

export default async function editReference (id: number, body: AcademicManageCurriculumPutReferenceRequest): Promise<StatusMessageResponse> {
  const { put } = useRequests()

  const { ok, message } = await put({
    url: `/academic/manage/curriculum/references/${id}`,
    body: body,
    loader: 'put-academic/manage/curriculum/references'
  })

  return {
    status: ok,
    message
  }
}
