import type { StoreType } from '../types'

import { useAlert, usePagination } from '@shared/composable'
import { getServiceManageLanguagesStudents } from '@shared/api'
import { ServiceManageLanguagesStudentsGetRequest, ServiceManageLanguagesStudentsGetResponse } from '@shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchStudents (this: StoreType, params: ServiceManageLanguagesStudentsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: ServiceManageLanguagesStudentsGetResponse): void => {
    this.setStudents(data.students || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const { ok, data } = await getServiceManageLanguagesStudents({ ...params, per_page: perPage, offset: currentPage })

    if (ok) {
      setData(data)
    }
  })

  const { ok, message, data } = await getServiceManageLanguagesStudents(params)
  if (ok) {
    setData(data)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
