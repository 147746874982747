import { useRequests } from '@/shared/composable'

import type {
  UnknownRequest,
  AcademicSingleCourseAttendanceRelocatesResourceGetRequest,
  AcademicSingleCourseAttendanceRelocatesResourceGetResponse
} from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (params: AcademicSingleCourseAttendanceRelocatesResourceGetRequest): Promise<AxiosResponse<AcademicSingleCourseAttendanceRelocatesResourceGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicSingleCourseAttendanceRelocatesResourceGetResponse>({
    url: 'academic/courses/attendances/relocates/resources',
    body: { params },
    loader: 'academic/courses/attendances/relocates/resources'
  })

  return response
}
