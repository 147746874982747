<template>
  <div
    class="d-flex flex-row align-items-center"
  >
    <button
      title="Back"
      type="button"
      class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
      @click="handleCoursesNullify"
    >
      <pms-kt-icon
        icon-name="arrow-left"
        class="m-0 p-0"
      />
    </button>
    <h2 class="m-0">
      {{ programTitle }}
    </h2>
  </div>
</template>

<script setup lang="ts">
import { useNewRequest } from './../store'
import { computed } from 'vue'

const newRequestStore = useNewRequest()
const programTitle = computed(() => newRequestStore.selectedProgram?.title || '')

function handleCoursesNullify () {
  newRequestStore.requestType = null
  newRequestStore.isFetched = false
  newRequestStore.requestCourses = []
}
</script>
