<template>
  <div
    id="grades-history"
    class="mt-4"
  >
    <h3 class="py-2">
      <span class="card-label fw-bold text-dark fs-3">
        {{ $t('domains.academic.single-course.history.grades-title') }}
      </span>
    </h3>
    <div
      v-if="gradesGrapData && Object.keys(gradesGrapData).length === 0"
    >
      <pms-no-data class="mt-20 fs-4 fw-bold" />
    </div>
    <div v-else>
      <apexchart
        type="bar"
        :height="gradesGrapData.letters.length > 1 ? gradesGrapData.letters.length * 50 : 150"
        :options="gradesGraphOptions"
        :series="[gradesGrapData.series]"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'

import { useSingleCourse } from '../../../store'
import { gradesGraphOptions } from '../values'
import { formatGradesForGraphs } from '../utils'

const store = useSingleCourse()

const gradesGrapData = computed(() => formatGradesForGraphs(store.getHistoryGrades))
gradesGraphOptions.value.xaxis.categories = gradesGrapData.value.letters

watch(() => gradesGrapData,
  (newValue) => {
    gradesGraphOptions.value.xaxis.categories = newValue.value.letters
  }
)
</script>
