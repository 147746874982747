import { useStore } from '@/domains/AcademicCurricula/store'
import postCurriculaOutcome from '@/shared/api/AcademicCurricula/postCurriculaOutcome'
import { useAlert } from '@/shared/composable'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

// View model
const useOutcomeForm = () => {
  const store = useStore()
  const { outcome } = storeToRefs(store)

  const { success, error } = useAlert()

  interface PageParams { program_code: string, program_year: number }

  const route = useRoute()
  const params = route.params as unknown as PageParams

  const localLang = ref({
    ru: '',
    en: '',
    kz: ''
  })

  const isDirty = computed(() => {
    return localLang.value.ru !== (outcome.value ? outcome.value.outcome_ru : '') ||
    localLang.value.en !== (outcome.value ? outcome.value.outcome_en : '') ||
    localLang.value.kz !== (outcome.value ? outcome.value.outcome_kz : '')
  })

  onMounted(async () => {
    fetchCurriculumOutcome()
  })

  const fetchCurriculumOutcome = async () => {
    await store.fetchCurriculumOutcome(params)
  }

  watch(outcome, () => {
    localLang.value.ru = outcome.value ? outcome.value.outcome_ru : ''
    localLang.value.en = outcome.value ? outcome.value.outcome_en : ''
    localLang.value.kz = outcome.value ? outcome.value.outcome_kz : ''
  })

  const save = async () => {
    const res = await postCurriculaOutcome({
      program_code: params.program_code,
      program_year: params.program_year,
      outcome_en: localLang.value.en,
      outcome_ru: localLang.value.ru,
      outcome_kz: localLang.value.kz
    })
    if (res.ok) {
      success({ isToast: true, text: res.message })
      await fetchCurriculumOutcome()
    } else {
      error({ isToast: true, text: res.message })
    }
  }

  return {
    localLang,
    isDirty,
    save
  }
}

export default useOutcomeForm
