import type { StoreType } from '../types'
import { AdministrativeStaffsWorkloadRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@shared/types'
import { getAdministrativeStaffsWorkload } from '@shared/api'

export default async function fetchWorkload (this: StoreType, body: AdministrativeStaffsWorkloadRequest): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getAdministrativeStaffsWorkload(body)

  if (ok) this.setWorkload(data)
  return {
    status: ok,
    message
  }
}
