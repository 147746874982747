import { createPage } from '@shared-utils/storybook'

import MessageBroadcastComposePage from '../MessageBroadcastComposePage.vue'
import { useMessageBroadcastCompose } from './../store'

const Template = createPage({
  layout: 'default',
  component: MessageBroadcastComposePage,
  setup () {
    const store = useMessageBroadcastCompose()

    store.$reset()
    store.bootstrap()
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Service/Message Broadcast (Compose)',
  component: MessageBroadcastComposePage,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
