import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  RulesAndRegulationsGetRequest,
  RulesAndRegulationsGetResponse
} from '@shared/swagger'

export default async function getRulesAndRegulations (params: RulesAndRegulationsGetRequest): Promise<AxiosResponse<RulesAndRegulationsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, RulesAndRegulationsGetResponse>({
    url: 'academic/information/rules',
    body: { params },
    loader: 'academic/information/rules'
  })

  return response
}
