export const locales = [
  {
    locale: 'English',
    short: 'en-EN',
    flag: '/media/flags/united-states.svg'
  },
  {
    locale: 'Қазақша',
    short: 'kz-KZ',
    flag: '/media/flags/kazakhstan.svg'
  },
  {
    locale: 'Русский',
    short: 'ru-RU',
    flag: '/media/flags/russia.svg'
  }
]
