<template>
  <div
    id="kt_scrolltop"
    ref="kt_scrolltop"
    class="scrolltop"
    data-kt-scrolltop="true"
  >
    <pms-kt-icon
      icon-name="arrow-up"
      icon-class="fs-2x"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { ScrollTopComponent } from '@/assets/ts/components/_ScrollTopComponent'

onMounted(() => {
  ScrollTopComponent.reinitialization()
})
</script>
