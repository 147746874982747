import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'

export default async function destroyProfileEducationConducted (course_id: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `profile/education/conducted-courses/${course_id}`,
    body: {},
    loader: 'profile/education/conducted-courses-destroy'
  })

  return response
}
