import { destroySectionManagementSections } from '@shared/api'
import type { StatusMessageResponse } from '@shared/types'

import { useSectionManagement } from '@domains/SectionManagement/store'
import type { StoreType } from '../types'

export default async function deleteSection (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await destroySectionManagementSections(id)
  const store = useSectionManagement()
  if (ok) {
    await this.fetchRequestSections(
      {
        course_code: this.selectedCourse?.code ?? '',
        year: this.selectedCourse?.year ?? -1,
        term: store.currentYearTerm.term
      })
  }
  return {
    status: ok,
    message
  }
}
