<template>
  <div v-if="gradeExamReports">
    <table
      border="0"
      cellpadding="0"
      style="border-collapse:collapse"
      width="100%"
    >
      <tr>
        <td colspan="2">
          <table
            border="0"
            bordercolor="#000000"
            width="100%"
          >
            <tr>
              <td
                align="center"
                width="40%"
                class="fs-3"
              >
                <b>SDU UNIVERSITY<br>ЕМТИХАН ВЕДОМОСТІ</b>
              </td>
              <td
                align="center"
                class="fs-2"
              >
                <img src="/sdu.gif">
              </td>
              <td
                align="center"
                class="fs-3"
                width="40%"
              >
                <b>SDU UNIVERSITY<br>EXAMINATION REPORT </b>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="2">
&nbsp;
        </td>
      </tr>
      <tr>
        <td>Факультет / Faculty:</td><td style="padding-left: 10px">
          {{ gradeExamReports.base_info.faculty }}
        </td>
      </tr>
      <tr>
        <td>Кафедра / Department:</td><td style="padding-left: 10px">
          {{ gradeExamReports.base_info.department }}
        </td>
      </tr>
      <tr>
        <td>Пәннің аты (Секция) / Course (Section): </td>
        <td style="padding-left: 10px">
          <b>{{ gradeExamReports.base_info.course_section.split(' - ')[0] }}</b> - {{ gradeExamReports.base_info.course_section.split(' - ')[1] }}
        </td>
      </tr>
      <tr>
        <td>Оқу жылы | Семестр / Year | Semester:</td><td style="padding-left: 10px">
          <b>{{ gradeExamReports.base_info.year_term }}</b>
        </td>
      </tr>
    </table>

    <br>

    <table
      border="1"
      bordercolor="#A0A0A0"
      width="100%"
      style="border-spacing: 0;"
    >
      <tr
        align="center"
        style="font-weight:bold"
      >
        <td
          width="25"
          rowspan="2"
        >
          №
        </td>
        <td
          width="75"
          rowspan="2"
        >
          мамандық<br>speciality
        </td>
        <td
          width="150"
          rowspan="2"
        >
          Студенттің жеке номері<br>Student ID
        </td>
        <td
          width="200"
          rowspan="2"
        >
          Аты - жөні<br>Surname and Name
        </td>

        <td
          v-for="assesment in gradeAssessments"
          :key="assesment.assessment_id"
          align="center"
          width="50"
          rowspan="2"
        >
          {{ assesment.assessment_type }}
        </td>

        <td colspan="3">
          Барлығы<br>Total
        </td>
      </tr>
      <tr
        align="center"
        style="font-weight:bold"
      >
        <td width="33">
          Проценттік<br>Percent
        </td>
        <td width="33">
          Әріптік<br>Letter
        </td>
        <td width="33">
          Дәстүрлі<br>Traditional
        </td>
      </tr>

      <tr
        v-for="(student,index) in gradeExamReports.students"
        :key="student.student_id"
      >
        <td
          align="center"
          width="25"
        >
          {{ index+1 }}
        </td>
        <td
          align="center"
          width="75"
        >
          {{ student.speciality.split(' ')[0] }}<br>{{ student.speciality.split(' ')[1] }}
        </td>
        <td
          align="center"
          width="150"
        >
          {{ student.student_id }}
        </td>
        <td
          align="center"
          width="200"
        >
          {{ student.surname_name }}
        </td>

        <td
          v-for="assesment in gradeAssessments"
          :key="assesment.assessment_id"
          align="center"
          width="50"
        >
          {{ getStudentGrade(student, assesment) }}
        </td>
        <td align="center">
          {{ student.percent }}%
        </td>
        <td align="center">
          {{ student.letter }}
        </td>
        <td align="center">
          {{ student.traditional }}
        </td>
      </tr>
    </table>
    <br>

    <br>
    <div class="parent_graph">
      <div
        id="graph_of_grades"
        class="graph"
      >
        <div class="percent">
          <span>100%<hr style="margin-top: 0; margin-bottom: 0;"></span> <br><br>
          <span>75%<hr style="margin-top: 0; margin-bottom: 0;"></span> <br><br>
          <span>50%<hr style="margin-top: 0; margin-bottom: 0;"></span> <br><br>
          <span>25%<hr style="margin-top: 0; margin-bottom: 0;"></span>
        </div>
        <ul>
          <li align="center">
            {{ getLetterPercent('A', gradeExamReports.grade_statistics) }}:A
          </li>
          <li align="center">
            {{ getLetterPercent('A-', gradeExamReports.grade_statistics) }}:A-
          </li>
          <li align="center">
            {{ getLetterPercent('B', gradeExamReports.grade_statistics) }}:B
          </li>
          <li align="center">
            {{ getLetterPercent('B+', gradeExamReports.grade_statistics) }}:B+
          </li>
          <li align="center">
            {{ getLetterPercent('B-', gradeExamReports.grade_statistics) }}:B-
          </li>
          <li align="center">
            {{ getLetterPercent('C', gradeExamReports.grade_statistics) }}:C
          </li>
          <li align="center">
            {{ getLetterPercent('C+', gradeExamReports.grade_statistics) }}:C+
          </li>
          <li align="center">
            {{ getLetterPercent('C-', gradeExamReports.grade_statistics) }}:C-
          </li>
          <li align="center">
            {{ getLetterPercent('D', gradeExamReports.grade_statistics) }}:D
          </li>
          <li align="center">
            {{ getLetterPercent('D+', gradeExamReports.grade_statistics) }}:D+
          </li>
          <li align="center">
            {{ getLetterPercent('F', gradeExamReports.grade_statistics) }}:F
          </li>
          <li align="center">
            {{ getLetterPercent('FX', gradeExamReports.grade_statistics) }}:FX
          </li>
          <li align="center">
            {{ getLetterPercent('IP', gradeExamReports.grade_statistics) }}:IP
          </li>
          <li align="center">
            {{ getLetterPercent('NP', gradeExamReports.grade_statistics) }}:NP
          </li>
          <li align="center">
            {{ getLetterPercent('P', gradeExamReports.grade_statistics) }}:P
          </li>
        </ul>
      </div>
      <div
        id="labels_of_grades"
        class="labels"
      />
    </div>

    <br>
    <br>
    <table
      border="1"
      bordercolor="#A0A0A0"
      width="100%"
      style="border-spacing: 0;"
    >
      <tr>
        <td>Мерзімі / Date</td><td>{{ formatDate(new Date()) }}</td><b><td align="center">ҚОЛЫ / SIGNATURE</td><td
          width="120"
          align="center"
        >МӨР / SEAL</td></b>
      </tr>
      <tr>
        <td height="25">
          Оқытушы / Instructor
        </td><td>{{ gradeExamReports.signatures.instructor }}</td><td /><td rowspan="3" />
      </tr>
      <tr>
        <td height="25">
          Кафедра меңгерушісі / Head of Department
        </td><td>{{ gradeExamReports.signatures.head_of_department }}</td><td />
      </tr>
      <tr>
        <td height="25">
          Факультет деканы / Dean of Faculty
        </td><td>{{ gradeExamReports.signatures.dean_of_faculty }}</td><td />
      </tr>
    </table>
    <p class="more" />
  </div>
</template>
<script setup lang="ts">
import { useSingleSection } from '@/domains/AcademicSingleSection/store'
import { useResource } from '@/shared/stores'
import { AcademicCoursesGradesExamReportsGetResponse, AcademicCoursesResourcesSingleResponse } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const store = useSingleSection()
const resource = useResource()

const { gradeExamReports, currentSection } = storeToRefs(store)
const gradeAssessments = computed(() => resource.getAcademicSingleCoursesResourceAssessments
  .filter((a) => a.section_id === currentSection.value.section_id)
  .sort((a) => {
    if (a.assessment_type === 'FA') return -1
    if (a.assessment_type === 'FIN') return -1
    return 1
  }).filter(a => a.assessment_type !== 'LMS')
)

const getStudentGrade = (student: AcademicCoursesGradesExamReportsGetResponse['students'][0], assesment: AcademicCoursesResourcesSingleResponse['assessments'][0]): string => {
  const grade = student.grades.find(g => g.assessment_id === assesment.assessment_id)
  return grade ? grade.grade + '' : ''
}

const getLetterPercent = (letter: string, grades: AcademicCoursesGradesExamReportsGetResponse['grade_statistics']): number => {
  const percent = grades.find(g => g.letter === letter)
  return percent ? percent.percent : 0
}
const formatDate = (date: Date): string => {
  const day: string = String(date.getDate()).padStart(2, '0')
  const month: string = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
  const year: number = date.getFullYear()

  return `${day}.${month}.${year}`
}
</script>
