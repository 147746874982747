import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouteRecordWithLayout } from '@shared/types'
import { usePagination } from '@shared/composable'
import { useAllStudents } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const student_id = to.query.student_id ? String(to.query.student_id) : undefined
  const store = useAllStudents()
  const pagination = usePagination()

  store.$reset()
  pagination.$reset()

  if (!student_id) {
    store.bootstrap()
    next()
  }
  store.bootstrap()
  next()
}

export default {
  layout: 'default',
  path: '/service/manage/languages',
  children: [
    {
      path: '',
      name: 'Language Levels Page',
      component: () => import('./LanguageLevelsPage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
