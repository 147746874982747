import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { RouteParams } from '../../types'
import { header } from '../../values'

export default async function bootstrap (this: StoreType, params: RouteParams): Promise<void> {
  const resource = useResource()
  const { code, year, term } = params

  if (!+term) {
    if (resource.getAcademicSingleCoursesResource) {
      resource.setAcademicSingleCoursesResource({
        ...resource.getAcademicSingleCoursesResource,
        enrolled: false
      })
    }
  } else {
    await Promise.all([
      this.setCourse(params),
      resource.fetchAcademicSingleCoursesResource({ code, year, term }),
      resource.fetchAcademicResource({ modules: ['departments'], year, term }),
      resource.fetchYearTermScheduleHoursResources({ year: year, term: term })
    ])
  }

  const data = resource.getAcademicSingleCoursesResource

  data && (header.title = `${data.information.code} - ${data.information.title}`)
}
