import type { StoreType } from '../types'

import { useResource } from '@shared/stores'

export default async function bootstrapByPage (this: StoreType, page: string, params: {id: string}): Promise<void> {
  const resource = useResource()
  const { id } = params

  switch (page) {
    case 'information':
      await Promise.all([
        this.fetchInformation(id),
        resource.fetchProfileResource({ modules: ['provinces', 'regions', 'countries', 'cities'] }),
        this.advisorOpinion && this.fetchAdvisorOpinion(id, (this.advisorOpinion.id).toString())
      ])
      break
    case 'course-registration':
      await Promise.all([
        this.fetchCourseRegistration(id)
      ])
      break
    case 'attendance':
      await Promise.all([
        this.setAttendanceDetails(null),
        this.fetchAttendance(id, { year: this.currentYearTerm.year, term: this.currentYearTerm.term })
      ])
      break
    case 'grades':
      await Promise.all([
        this.setGradesDetails(null),
        this.fetchGrades(id)
      ])
      break
    case 'schedule':
      await Promise.all([
        this.fetchSchedule(id, { year: this.currentYearTerm.year, term: this.currentYearTerm.term })
      ])
      break
    case 'curriculum': {
      await Promise.all([
        this.fetchCurriculum(id)
      ])
      break
    }
    case 'transcript': {
      await Promise.all([
        this.fetchTranscript(id)
      ])
      break
    }
    case 'accounting': {
      await Promise.all([
        this.fetchAccounting(id),
        this.setAccountingDetails(null)
      ])
      break
    }
  }
}
