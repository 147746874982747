import type { StoreType } from '../types'
import type { ServiceEsignPutRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { putServiceEsigns } from '@shared/api'

export default async function putEsign (this: StoreType, params: ServiceEsignPutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putServiceEsigns(params)

  return {
    status: ok,
    message
  }
}
