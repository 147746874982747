import { SmartTableHeader } from '@/shared/types'

export const gradesTableHeader: Array<SmartTableHeader> = [
  {
    title: 'common.table.no',
    field: 'no'
  },
  {
    title: 'common.table.course-code',
    field: 'course_code'
  },
  {
    title: 'common.table.title',
    field: 'title'
  },
  {
    title: 'common.table.credits',
    field: 'credits'
  },
  {
    title: 'common.table.ects',
    field: 'ects'
  },
  {
    title: 'common.table.total-grade',
    field: 'total_grade'
  },
  {
    title: 'common.table.letter-grade',
    field: 'letter_grade'
  },
  {
    title: 'common.table.action',
    field: 'action'
  }
]
