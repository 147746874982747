import type { StoreType } from '../types'
import type { AcademicSingleCourseAttendancesDeleteRequest } from '@shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'

import { destroyAcademicCoursesAttendances } from '@shared/api'

export default async function deleteAttendance (this: StoreType, params: AcademicSingleCourseAttendancesDeleteRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await destroyAcademicCoursesAttendances(params)

  return {
    status: ok,
    message
  }
}
