import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'

import type { DashboardWidgetsResponse } from '@shared/swagger'
import { UnknownRequest } from '@shared/swagger'

export default async function getDashboardWidgets (): Promise<AxiosResponse<DashboardWidgetsResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, DashboardWidgetsResponse>({
    url: 'dashboard/widgets',
    body: { },
    loader: '/1.0.0/dashboard/widgets',
    camelize: false
  })

  return response
}
