<template>
  <div
    v-if="yearTerms.length !== 0"
    class="d-flex align-items-center gap-3"
  >
    <p class="fs-5 m-0">
      {{ $t('common.year-terms') }}
    </p>

    <div class="d-flex flex-row">
      <select
        class="form-select form-select-solid w-lg-200px w-200px"
        @change="setYearTerm($event)"
      >
        <option
          v-for="yearTerm in yearTerms"
          :key="(yearTerm.year + '' + yearTerm.term)"
          :value="yearTerm.year + '-' + yearTerm.term"
          :selected="(yearTerm.year === currentYearTerm.year && yearTerm.term === currentYearTerm.term)"
        >
          {{ getYearTermLabel(yearTerm, t) }}
        </option>
      </select>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useResource } from '@shared/stores'
import { useSystemCalendar } from '../store'
import { getYearTermLabel } from '@/shared/utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useSystemCalendar()
const resource = useResource()

const yearTerms = computed(() => resource.getResourceYearTerms)
const currentYearTerm = computed(() => store.getCurrentYearTerm)

const setYearTerm = ($event) => {
  const [year, term] = $event.target.value.split('-')
  store.setCurrentYearTerm({ year, term, id: 0 })
}
</script>
