import type { StatusMessageResponse } from '@shared/types'
import type { ProfileBiographyPutRequest } from '@shared/swagger'
import { putProfileBiography } from '@shared/api'

import type { StoreType } from '../types'

export default async function editBiography (this:StoreType, bio: ProfileBiographyPutRequest['biography']): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileBiography(bio)

  return {
    status: ok,
    message: message
  }
}
