import { AcademicSingleStudentCurriculumGetResponse } from '@shared/swagger'

export default (
  gradeDetails: AcademicSingleStudentCurriculumGetResponse['groups'][0]['items'][0]['details']
): AcademicSingleStudentCurriculumGetResponse['groups'][0]['items'][0]['details'][0] => {
  // Sort the details array by registration_date
  const sortedDetails = gradeDetails.sort((a, b) => {
    const dateA = new Date(a.registration_date).getTime()
    const dateB = new Date(b.registration_date).getTime()
    return dateA - dateB
  })

  // Return the last registered detail
  return sortedDetails[sortedDetails.length - 1]
}
