<template>
  <div class="app-navbar flex-shrink-0">
    <div class="app-navbar-item ms-1 ms-lg-3">
      <div
        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <span class="svg-icon svg-icon-1">
          <template v-if="messages.length>0">
            <pms-kt-icon
              v-badge.danger="messages.length"
              icon-name="message-notif"
              icon-class="fs-2 fs-md-1"
            />
          </template>
          <template v-else>
            <pms-kt-icon
              icon-name="message-notif"
              icon-class="fs-2 fs-md-1"
            />
          </template>
        </span>
      </div>
      <KTNotificationMenu />
    </div>
    <div class="app-navbar-item ms-1 ms-lg-3">
      <div
        id="kt_activities_toggle"
        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
      >
        <span class="svg-icon svg-icon-1">
          <pms-kt-icon
            icon-name="calendar-tick"
            icon-class="fs-2 fs-md-1"
          />
        </span>
      </div>
    </div>
    <div class="app-navbar-item ms-1 ms-lg-3">
      <a
        href="#"
        class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <span
          v-if="themeMode === 'light'"
          class="svg-icon theme-dark-hide svg-icon-2"
        >
          <pms-kt-icon
            icon-name="night-day"
            icon-class="fs-2 fs-md-1"
          />
        </span>
        <span
          v-else
          class="svg-icon theme-light-hide svg-icon-2"
        >
          <pms-kt-icon
            icon-name="moon"
            icon-class="fs-2 fs-md-1"
          />
        </span>
      </a>
      <KTThemeModeSwitcher />
    </div>
    <div
      id="kt_header_user_menu_toggle"
      class="app-navbar-item ms-1 ms-lg-3"
    >
      <div
        class="cursor-pointer symbol symbol-35px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <img
          style="object-fit: cover"
          :src="(user && user.photo) || '/user.png'"
          :alt="(user && (user.name + ' ' + user.surname)) || ''"
        >
      </div>
      <KTUserMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import KTNotificationMenu from './menu/NotificationsMenu.vue'
import KTUserMenu from './menu/UserAccountMenu.vue'
import KTThemeModeSwitcher from './menu/ThemeModeSwitcher.vue'

import { ThemeModeComponent } from '@/assets/ts/layout'
import { useThemeStore } from '@shared-stores/pattern'
import { useUser } from '@shared/stores'

const themeStore = useThemeStore()
const userStore = useUser()

const messages = computed(() => userStore.getNewInboxMessages)

const themeMode = computed(() => {
  if (themeStore.theme === 'system') {
    return ThemeModeComponent.getSystemMode()
  }
  return themeStore.theme
})

const user = computed(() => userStore.currentUser)
</script>
