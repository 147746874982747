export default [
  {
    routeName: 'NewRequestPage',
    label: 'domains.section-management.sidebar.new-request',
    to: '/academic/manage/section-management'
  },
  {
    routeName: 'SentRequestsPage',
    label: 'domains.section-management.sidebar.sent-requests',
    to: '/academic/manage/section-management/sent-requests'
  },
  {
    routeName: 'ReceivedRequestsPage',
    label: 'domains.section-management.sidebar.received-requests',
    to: '/academic/manage/section-management/received-requests'
  }
]
