import type { StatusMessageResponse } from '@shared/types'
import type{ ScheduleManagementDiscardAppliedProgramRequestParams } from '@shared/swagger'

import type { StoreType } from '../types'
import api from '@/shared/api/ScheduleManagement/discardAppiedProgram'

export default async function discardAppiedProgram (this: StoreType, data: ScheduleManagementDiscardAppliedProgramRequestParams): Promise<StatusMessageResponse> {
  const { ok, message } = await api(data)

  if (ok && data.section) await this.fetchSingleSectionPrograms(data.section.toString())

  return {
    status: ok,
    message
  }
}
