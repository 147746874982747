import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileSkillsLanguagesPostRequest } from '@shared/swagger'
import { postProfileSkillsLanguages } from '@shared/api'

import type { StoreType } from '../types'

export default async function addLanguage (this: StoreType, values: ProfileSkillsLanguagesPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileSkillsLanguages(values)

  if (ok) await this.fetchLanguages()

  return {
    status: ok,
    message
  }
}
