import { setTempValues, tempValues } from '../../values'
import type { StoreType } from '../types'
import { RouteLocationNormalized } from 'vue-router'

export default async function bootstrap (this: StoreType, page: RouteLocationNormalized): Promise<void> {
  await Promise.all([
    this.fetchRuleCategories(),
    this.fetchRuleLanguages()
  ]).then(() => {
    this.categories.length && setTempValues({ category_id: this.categories[0].id })
    switch (page.name) {
      case 'RulesAndRegulationsPage':
        this.fetchRules({ ...tempValues.value })
        break
    }
  })
}
