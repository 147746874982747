import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  SectionManagementSectionsQuotasGetResponse, SectionManagementSectionsQuotasGetRequest
} from '@shared/swagger'

export default async function getSectionManagementSectionsQuotas (params: SectionManagementSectionsQuotasGetRequest): Promise<AxiosResponse<SectionManagementSectionsQuotasGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, SectionManagementSectionsQuotasGetResponse>({
    url: 'academic/manage/sections/quotas',
    body: { params },
    loader: 'academic/manage/sections/quotas'
  })

  return response
}
