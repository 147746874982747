import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePagination } from '@shared/composable'

export default defineComponent({
  props: {
    perOptions: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100]
    },
    classList: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const { t } = useI18n()
    const { total, perPage, currentPage, lastPage, maxVisiblePages, setPerPage, setCurrentPage, trigger } = usePagination()

    const changePerPage = (e) => {
      setPerPage(e.target.value)
      trigger()
    }

    const range = computed(() => {
      const half = Math.floor(maxVisiblePages.value / 2)
      let start = currentPage.value - half
      let end = currentPage.value + half
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > lastPage.value) {
        start -= end - lastPage.value
        end = lastPage.value
      }
      if (start < 1) start = 1
      return { start, end }
    })

    const back = (): void => {
      if ((currentPage.value - 1) >= 0) {
        setCurrentPage(currentPage.value - 1)
      }
      trigger()
    }

    const next = (): void => {
      if ((currentPage.value + 1) <= lastPage.value) {
        setCurrentPage(currentPage.value + 1)
      }
      trigger()
    }

    const changePage = (index: number): void => {
      setCurrentPage(index)
      trigger()
    }

    return () => (
      <div class="d-block d-sm-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between flex-rows align-items-center">
          <div class={'d-flex flex-rows align-items-center'}>
            <select
              class="form-select form-select-sm w-75px"
              onChange={ (e) => changePerPage(e) }
            >
              {
                props.perOptions.map((item) => (
                  <option value={ item } selected={ item === perPage.value }>{ item }</option>
                ))
              }
            </select>
            <span class="ms-2">{ t('common.pagination.per-page') }</span>
          </div>
          <span class="ms-4 fs-6 fw-semibold">
            {
              t('common.pagination.total', {
                total: total.value,
                first: perPage.value * (currentPage.value - 1),
                second: perPage.value * currentPage.value
              })
            }
          </span>
        </div>
        <div class={ props.classList }>
          <ul class="pagination pt-6 pt-md-0">
            <li class={'page-item previous me-2 m-sm-0 ' + ((lastPage.value === 1) ? 'disabled' : '')}>
              <span class="page-link cursor-pointer" onClick={back}>
                <i class="previous" />
              </span>
            </li>
            {range.value.start > 1 && (
              Array.from({ length: 3 }).map((_, i) => {
                const pageNum = i + 1
                if (pageNum < range.value.start) {
                  return (
                    <li class="page-item">
                      <span class="page-link cursor-pointer" onClick={() => changePage(pageNum)}>{pageNum}</span>
                    </li>
                  )
                }
                return null
              })
            )}
            {range.value.start > 4 && (
              <li class="page-item disabled">
                <span class="page-link cursor-pointer">...</span>
              </li>
            )}
            {
              Array.from({ length: range.value.end - range.value.start + 1 }).map((_, i) => {
                const pageNum = range.value.start + i
                return (
                  <li class={'page-item ' + ((currentPage.value === pageNum) ? 'active' : '')}>
                    <span class="page-link cursor-pointer" onClick={() => changePage(pageNum)}>
                      {pageNum}
                    </span>
                  </li>
                )
              })
            }
            {range.value.end < lastPage.value - 3 && (
              <li class="page-item disabled">
                <span class="page-link cursor-pointer">...</span>
              </li>
            )}
            {range.value.end < lastPage.value && (
              Array.from({ length: 3 }).map((_, i) => {
                const pageNum = lastPage.value - (2 - i)
                if (pageNum > range.value.end) {
                  return (
                    <li class="page-item">
                      <span class="page-link cursor-pointer" onClick={() => changePage(pageNum)}>{pageNum}</span>
                    </li>
                  )
                }
                return null
              })
            )}
            <li class={`page-item next ${total.value === 0 ? 'ms-0' : 'ms-2'} m-sm-0 ` + ((currentPage.value === lastPage.value) ? 'disabled' : '') }>
              <span class="page-link cursor-pointer" onClick={next}>
                <i class="next" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
})
