import type { StoreType } from '../types'
import type { AdministrativeStaffsRequest, AdministrativeStaffsResponse } from '@shared/swagger'
import type { StatusMessageResponse } from '@shared/types'

import { getAdministrativeStaffs } from '@shared/api'
import { usePagination } from '@shared/composable'
import { search_values } from '../../values'

export default async function fetchEmployees (this: StoreType, values: AdministrativeStaffsRequest): Promise<StatusMessageResponse> {
  const { setPaginate, setTriggerFunction } = usePagination()

  const setData = (data: AdministrativeStaffsResponse): void => {
    this.setEmployees(data.employees || [])
    setPaginate(data.per_page, data.current_page, data.last_page, data.total)
  }

  setTriggerFunction(async (perPage: number, currentPage: number) => {
    const v = { ...values, per_page: perPage, offset: currentPage }
    const { ok, data } = await getAdministrativeStaffs(v)

    if (ok) {
      search_values.value = v
      setData(data)
    }
  })

  const { ok, message, data } = await getAdministrativeStaffs(values)
  if (ok) {
    search_values.value = values
    setData(data)
  }
  return {
    status: ok,
    message
  }
}
