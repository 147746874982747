import { storeToRefs } from 'pinia'
import type { StoreType } from '../types'

import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType, params: {id: string}): Promise<void> {
  const { id } = params
  const resource = useResource()
  const { singleStudentResource } = storeToRefs(resource)

  await Promise.all([
    this.setStudentId(id),
    resource.fetchAcademicSingleStudentResource(id)
  ])
  const lastYearTermOfStudent = singleStudentResource.value && singleStudentResource.value.year_terms[singleStudentResource.value.year_terms.length - 1]
  lastYearTermOfStudent && this.setCurrentYearTerm({
    id: 0,
    ...lastYearTermOfStudent
  })
}
