<template>
  <template v-if="!(editor.sessions as number[]).includes(sessionId)">
    <session-lock-icon />
  </template>
</template>
<script setup lang="ts">
import { useAttendanceEditor } from '../../composable'
import SessionLockIcon from './SessionLockIcon.vue'

const { editor } = useAttendanceEditor()

defineProps({
  sessionId: {
    type: Number,
    required: true
  }
})
</script>
