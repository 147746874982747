<template>
  <PermissionCheck :permission-code="permissionCodes.show_esing.code">
    <pms-page-toolbar
      v-if="toolbar"
      v-bind="header"
      @launch="launch"
    >
      <template #action>
        <toolbar-actions />
        <pms-page-tour
          :launched="status"
          :steps="steps"
          @launch="launch"
        />
      </template>
    </pms-page-toolbar>
    <pms-container>
      <pms-row
        class="mt-4"
      >
        <div class="col-12">
          <SearchForm />
        </div>
        <div class="col-12 mt-4">
          <SearchList />
        </div>
      </pms-row>
    </pms-container>
    <Dialog
      v-model:visible="modal_shown"
      modal
      :header="'domains.service.esign.modal.title'"
      :style="{ width: '30rem' }"
      :draggable="false"
    >
      <SignModal />
    </Dialog>
  </permissioncheck>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
import Dialog from 'primevue/dialog'

import { useTour, useToolbar } from '@shared/composable'
import { header, tour, modal_shown } from './values'

import ToolbarActions from './ui/ToolbarActions.vue'
import SearchForm from './ui/SearchForm.vue'
import SearchList from './ui/SearchList.vue'
import SignModal from './ui/SignModal.vue'
import PermissionCheck from '@/shared/ui/components/PermissionCheck.vue'
import { permissionCodes } from '@/shared/values'

const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
})
</script>
