import { SelectField } from '@shared/types'
import { ServiceManageLanguagesResourcesResponse } from '@shared/swagger'
import { groupByParentChildFields, nestedToOneDimension } from '@shared/utils/array'

export default (data: ServiceManageLanguagesResourcesResponse['years']): SelectField['options'] => {
  const options: SelectField['options'] = []

  let nested = groupByParentChildFields(data, 'parent_id', 'id', null)
  nested = nestedToOneDimension(nested)

  nested.forEach((item) => {
    options.push({
      label: `${item.year}`,
      value: item.year
    })
  })

  return options
}
