import type { StatusMessageResponse } from '@shared/types'
import type{ ProfileSkillsHardPostRequest } from '@shared/swagger'
import { postProfileSkillsHard } from '@shared/api'

import type { StoreType } from '../types'

export default async function addSkill (this: StoreType, values: ProfileSkillsHardPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await postProfileSkillsHard(values)

  if (ok) await this.fetchSkills()

  return {
    status: ok,
    message
  }
}
