import { FormField } from '@/shared/types'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useResource } from '@/shared/stores'
import { useQueryHelper } from './useQueryHelper'
import { AcademicManageCurriculumElectivePostRequest } from '@/shared/swagger'
import { storeToRefs } from 'pinia'
import { useStore } from '@/domains/AcademicCurricula/store'
import addElectiveSlotToTrack from '@/shared/api/AcademicCurricula/addElectiveSlotToTrack'
import { useRouter } from 'vue-router'
import { useAlert } from '@/shared/composable'
import { Nullable } from '../types'

const useElectiveSlotForm = () => {
  const { t } = useI18n()
  const { query } = useQueryHelper()
  const store = useStore()
  const { track } = storeToRefs(store)
  const router = useRouter()
  const { error } = useAlert()

  watch(() => track.value, () => {
    if (track.value) {
      slotSettings.value.track_id = track.value.id
      terms.value = Array.from({ length: track.value.terms }, (_, i) => i + 1)
    }
  })

  const onHandleChange = ({ name, newValue }) => {
    slotSettings.value[name] = newValue
  }

  const onSubmit = async (data: { values: AcademicManageCurriculumElectivePostRequest & { is_restricted: [], for_all_tracks: [] }}) => {
    const values = data.values
    if (track.value) {
      const response = await addElectiveSlotToTrack({
        track_id: track.value.id,
        term: values.term,
        course_code: values.course_code,
        title_kz: values.title_kz,
        title_ru: values.title_ru,
        title_en: values.title_en,
        theory: values.theory,
        practice: values.practice,
        lab: values.lab,
        ects: values.ects,
        elective_code: values.elective_code,
        language_code: values.language_code,
        is_restricted: (values.is_restricted.length > 0),
        for_all_tracks: values.for_all_tracks.length > 0
      })

      if (response.status) {
        // Back to previous page after create slot
        router.back()
      } else {
        error({
          text: response.message
        })
      }
    }
  }

  type tempData = {
    coursePrefix: Nullable<string>,
    coursePostfix: Nullable<string>,
    credits: number
  }

  const slotSettings = ref<AcademicManageCurriculumElectivePostRequest & tempData>({
    track_id: 1,
    term: query.term,
    coursePrefix: null,
    coursePostfix: null,
    course_code: '',
    title_kz: '',
    title_ru: '',
    title_en: '',
    theory: 0,
    practice: 0,
    lab: 0,
    ects: 0,
    elective_code: '',
    language_code: '',
    is_restricted: true,
    for_all_tracks: true,

    credits: 0
  })

  enum SectionTypes {
    Lection = 'N',
    Labaratory = 'L',
    Practice = 'P'
  }

  type Options = {label: string, value: string}[]

  const resource = useResource()
  const coursePrefixOptions = ref<Options>([])
  const electiveNameOptions = ref<Options>([])
  const languageOptions = ref<Options>([])

  // Todo get values from resources Daulet
  const theoryRate = ref<number>(0)
  const practiceRate = ref<number>(0)
  const labRate = ref<number>(0)

  watch(() => [slotSettings.value.theory, slotSettings.value.practice, slotSettings.value.lab],
    ([newTheory, newPractice, newLab], [oldTheory, oldPractice, oldLab]) => {
      if (newTheory !== oldTheory || newPractice !== oldPractice || newLab !== oldLab) {
        const creditRates =
      ((slotSettings.value.theory ?? 0) * theoryRate.value) +
      ((slotSettings.value.practice ?? 0) * practiceRate.value) +
      ((slotSettings.value.lab ?? 0) * labRate.value)

        slotSettings.value.credits = creditRates

        slotSettings.value.ects = Math.ceil(creditRates * 1.5)
      }
    }
  )

  resource.fetchAcademicManageCurriculumResource({ modules: [] })
  // course_prefixes
  watch(() => resource.academicManageCurriculumResource, () => {
    if (resource.academicManageCurriculumResource) {
      coursePrefixOptions.value = resource.academicManageCurriculumResource.course_prefixes.map((course_prefix) => {
        return {
          label: course_prefix,
          value: course_prefix
        }
      })

      electiveNameOptions.value = resource.academicManageCurriculumResource.elective_names.filter(elective_name => elective_name.is_visible).map((elective_name) => {
        return {
          label: elective_name.title,
          value: elective_name.code
        }
      })

      languageOptions.value = resource.academicManageCurriculumResource.languages.map((language) => {
        return {
          label: language.title,
          value: language.language_code
        }
      })

      resource.academicManageCurriculumResource.section_types.forEach((section_type) => {
        if (section_type.key === SectionTypes.Lection) {
          theoryRate.value = section_type.credit_rate
        } else if (section_type.key === SectionTypes.Labaratory) {
          labRate.value = section_type.credit_rate
        } else if (section_type.key === SectionTypes.Practice) {
          practiceRate.value = section_type.credit_rate
        }
      })
    }
  })

  const terms = ref<number[]>([])

  const formItemClasses = 'formItem me-5'

  const formFields = computed<Array<FormField>>(() => [
    {
      label: {
        text: t('common.term'),
        class: 'mb-1'
      },
      name: 'term',
      required: true,
      type: 'select',
      value: slotSettings.value.term || null,
      options: terms.value.map((term) => {
        return {
          label: term.toString(),
          value: term
        }
      }),
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.language'),
        class: 'mb-1'
      },
      name: 'language_code',
      type: 'select',
      value: slotSettings.value.language_code || null,
      options: languageOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.elective-type'),
        class: 'mb-1'
      },
      name: 'elective_code',
      type: 'select',
      value: slotSettings.value.elective_code || null,
      options: electiveNameOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.elective-restrict'),
        class: 'mb-1'
      },
      name: 'is_restricted',
      type: 'checkbox',
      options: [
        {
          id: 'is_restricted',
          value: true,
          label: {
            text: 'Unrestricted - Leave checkbox if you don\'t know.'
          }
        }
      ],
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.program-slot'),
        class: 'mb-1'
      },
      name: 'for_all_tracks',
      type: 'checkbox',
      options: [
        {
          id: 'for_all_tracks',
          value: true,
          label: {
            text: 'Add slot for all tracks in program'
          }
        }
      ],
      group: {
        class: formItemClasses
      }
    },

    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.course-prefix'),
        class: 'mb-1'
      },
      name: 'coursePrefix',
      type: 'select',
      value: slotSettings.value.coursePrefix || null,
      options: coursePrefixOptions.value,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.course-postfix'),
        class: 'mb-1'
      },
      name: 'coursePostfix',
      type: 'text',
      value: slotSettings.value.coursePostfix || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.course-code'),
        class: 'mb-1'
      },
      name: 'course_code',
      type: 'text',
      disabled: true,
      value: slotSettings.value.coursePrefix ? slotSettings.value.coursePrefix + ' ' + (slotSettings.value.coursePostfix ? slotSettings.value.coursePostfix : '') : null,
      group: {
        class: formItemClasses
      }
    },

    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },
    {
      label: {
        text: `${t('common.theory')}`,
        class: 'mb-1'
      },
      name: 'theory',
      type: 'number',
      value: slotSettings.value.theory || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.practice'),
        class: 'mb-1'
      },
      name: 'practice',
      type: 'number',
      value: slotSettings.value.practice || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('common.lab'),
        class: 'mb-1'
      },
      name: 'lab',
      type: 'number',
      value: slotSettings.value.lab || null,
      group: {
        class: formItemClasses
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.ects'),
        class: 'mb-1'
      },
      name: 'ects',
      type: 'number',
      value: slotSettings.value.ects || null,
      group: {
        class: formItemClasses
      }
    },

    {
      label: {
        text: t('common.credits'),
        class: 'mb-1'
      },
      name: 'credits',
      type: 'number',
      disabled: true,
      value: slotSettings.value.credits || null,
      group: {
        class: formItemClasses
      }
    },

    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    },

    {
      label: {
        text: t('domains.academic.curricula.course-title-kz'),
        class: 'mb-1'
      },
      name: 'title_kz',
      type: 'text',
      value: slotSettings.value.title_kz,
      group: {
        class: formItemClasses + ' w-100'
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.course-title-ru'),
        class: 'mb-1'
      },
      name: 'title_ru',
      type: 'text',
      value: slotSettings.value.title_ru,
      group: {
        class: formItemClasses + ' w-100'
      }
    },
    {
      label: {
        text: t('domains.academic.curricula.course-title-en'),
        class: 'mb-1'
      },
      name: 'title_en',
      type: 'text',
      value: slotSettings.value.title_en,
      group: {
        class: formItemClasses + ' w-100'
      }
    },
    {
      name: 'separator',
      type: 'separator',
      value: 'separator',
      group: {
        class: 'separator'
      }
    }
  ])

  return {
    slotSettings,
    formFields,
    terms,
    onHandleChange,
    onSubmit
  }
}

export default useElectiveSlotForm
