import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileOverviewResponse, ProfileOverviewRequest } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileOverview (params: ProfileOverviewRequest): Promise<AxiosResponse<ProfileOverviewResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileOverviewResponse>({
    url: 'profile',
    body: { params },
    loader: 'profile'
  })
  return response
}
