import { ServiceMessageBroadcastResourceResponse } from '@/shared/swagger'
import { useResource } from '@/shared/stores'
import { storeToRefs } from 'pinia'
import { useMessageBroadcastCompose } from '../store'

export function useCourseModal () {
  const resourceStore = useResource()
  const { serviceMessageBroadcastResource } = storeToRefs(resourceStore)
  const store = useMessageBroadcastCompose()
  const { selectedStudents, selectedCourses } = storeToRefs(store)

  function toggleCourses () {
    serviceMessageBroadcastResource.value?.courses.forEach(course => {
      if (course.students.some(student => selectedStudents.value.includes(student.id))) {
        if (!selectedCourses.value.includes(course)) {
          selectedCourses.value.push(course)
        }
      } else {
        if (selectedCourses.value.includes(course)) {
          selectedCourses.value = selectedCourses.value.filter(selected_course => selected_course !== course)
        }
      }
    })
  }

  function toggleStudent (student: ServiceMessageBroadcastResourceResponse['courses'][0]['students'][0]) {
    if (selectedStudents.value.some(e => e === student.id)) {
      selectedStudents.value = selectedStudents.value.filter(e => e !== student.id)
    } else {
      selectedStudents.value = [...selectedStudents.value, student.id]
    }
    toggleCourses()
  }

  function toggleCourseStudents (course:ServiceMessageBroadcastResourceResponse['courses'][0]) {
    if (course.students.every(student => selectedStudents.value.includes(student.id))) {
      selectedStudents.value = selectedStudents.value.filter(selected_student_id => !course.students.map(st => st.id).includes(selected_student_id))
    } else {
      selectedStudents.value = selectedStudents.value.concat(course.students.filter(course_student => !selectedStudents.value.includes(course_student.id)).map(st => st.id))
    }
    toggleCourses()
  }

  return {
    toggleStudent,
    toggleCourseStudents
  }
}
