import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { RouteRecordWithLayout } from '@shared-types/common'

import { useMessageBroadcastCompose } from './store'

const handleRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useMessageBroadcastCompose()

  store.$reset()
  store.bootstrap()

  next()
}

export default {
  layout: 'default',
  path: '/services/message-broadcast/new-message',
  children: [
    {
      path: '',
      name: 'MessageBroadcastComposePage',
      component: () => import('./MessageBroadcastComposePage.vue'),
      beforeEnter: [
        handleRoute
      ]
    }
  ]
} as RouteRecordWithLayout
