<template>
  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
    <div class="fs-2 fw-bold counted mb-0">
      {{ props.amount }}
    </div>
    <div class="fw-semibold fs-6 text-gray-400 mb-0">
      {{ props.title }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string
  amount: number
}>()
</script>
