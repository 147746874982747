import type { StatusMessageResponse } from '@shared/types'
import type { ProfileEducationOrganizationsPostRequest } from '@shared/swagger'
import { putProfileEducationOrganizations } from '@shared/api'

import type { StoreType } from '../types'

export default async function editOrganization (this:StoreType, id: number, values: ProfileEducationOrganizationsPostRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putProfileEducationOrganizations(id, values)

  return {
    status: ok,
    message: message
  }
}
