import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@shared/types'

import { postAdministrativeMessageBoardViewCount } from '@shared/api'

export default async function viewPost (this: StoreType, id: number): Promise<StatusMessageResponse> {
  const { ok, message } = await postAdministrativeMessageBoardViewCount(id)

  return {
    status: ok,
    message
  }
}
