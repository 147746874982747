<template>
  <div class="d-flex flex-row align-items-center">
    <p class="fs-7 fw-bold text-gray-700 me-3 m-0">
      {{ $t('common.view') }}:
    </p>
    <pms-button
      class="m-0 ps-2 pe-2 me-2"
      :title="$t('common.views.grid')"
      :size="'sm'"
      :variant="columnState === 'two-line' ? 'default' : 'tonal'"
      @click="setView('two-line')"
    >
      <pms-kt-icon
        :icon-name="'abstract-28'"
        class="fs-1 p-0"
        style="padding-right: 0.18rem;"
      />
    </pms-button>
    <pms-button
      class="m-0 ps-2 pe-2"
      :title="$t('common.views.list')"
      :size="'sm'"
      :variant="columnState === 'one-line' ? 'default' : 'tonal'"
      @click="setView('one-line')"
    >
      <pms-kt-icon
        :icon-name="'abstract-14'"
        class="fs-1 p-0"
        style="padding-right: 0.18rem;"
      />
    </pms-button>
  </div>
</template>
<script setup lang="ts">
import { columnState } from '../values'

const setView = (view) => {
  columnState.value = view
}
</script>
