import { ServiceMessageBroadcastPostResponse } from '@shared/swagger'

const getFirstFiveReceivers = (receives: ServiceMessageBroadcastPostResponse['receives'], maxLength: number): ServiceMessageBroadcastPostResponse['receives'] => {
  if (receives.length > maxLength) {
    return receives.slice(0, maxLength)
  }

  return receives
}

export default getFirstFiveReceivers
