<template>
  <a
    class="card shadow-sm w-100"
    :href="withoutMenu ? attachment.url : '#'"
    :target="withoutMenu ? '_blank' : ''"
  >
    <div class="card-body d-flex justify-content-center gap-5 text-center p-2 flex-column align-items-center position-relative">
      <pms-dropdown
        v-if="!withoutMenu"
        :placement="'start'"
        group-class="position-absolute end-0 top-0"
      >
        <template #button>
          <span class="btn btn-sm">
            <i class="bi bi-three-dots-vertical fw-bolder text-dark" />
          </span>
        </template>
        <button
          class="btn btn-light-primary btn-sm mb-2 fw-bold w-100px"
          @click="handleEditClick"
        >
          <pms-kt-icon
            :icon-name="'pencil'"
            class="fw-bold  p-0"
          />
          {{ $t('common.edit') }}
        </button>
        <a
          type="button"
          class="btn btn-light-primary btn-sm fw-bold mb-2 w-100px"
          :href="attachment.url"
          target="_blank"
        >
          <pms-kt-icon
            :icon-name="'eye'"
            class="fw-bold p-0"
          />
          {{ $t('common.view') }}
        </a>
        <button
          class="btn btn-light-danger btn-sm mb-2 fw-bold w-100px"
          @click="handleDeleteClick"
        >
          <pms-kt-icon
            :icon-name="'trash'"
            class="fw-bold  p-0"
          />
          {{ $t('common.delete') }}
        </button>
      </pms-dropdown>

      <div class="symbol symbol-50px m-4">
        <img
          src="/pdf.svg"
          class="theme-light-show"
          alt=""
        >
        <img
          src="/pdf-dark.svg"
          class="theme-dark-show"
          alt=""
        >
      </div>

      <div class="d-flex flex-column">
        <div class="fs-5 fw-bold mb-2">
          {{ attachment.language_name.toUpperCase() }}
        </div>
      </div>
    </div>

  </a>
</template>

<script setup lang="ts">
import { useAlert } from '@/shared/composable'
import { useRulesAndRegulations } from '../store'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { ISingleAttachmentCardEmits, ISingleAttachmentCardProps } from '../types'

const emit = defineEmits<ISingleAttachmentCardEmits>()
const props = defineProps<ISingleAttachmentCardProps>()
const store = useRulesAndRegulations()
const { dialog, success, error } = useAlert()
const { t } = useI18n()

const { singleRule } = storeToRefs(store)

const handleEditClick = () => {
  emit('edit')
}
const handleDeleteClick = () => {
  dialog({
    title: 'common.are-you-sure',
    text: t('domains.rules-and-regulations.delete-rule-attachment-text'),
    onConfirm: async () => {
      const { status, message } = await store.deleteRuleAttachment(singleRule.value?.id + '', props.attachment.id + '')
      status
        ? success({ text: message, isToast: true })
        : error({ text: message })
    }
  })
}

</script>
