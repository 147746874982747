import type { StoreType } from '../types'

import { usePagination } from '@shared/composable'
import { useResource } from '@shared/stores'

export default async function bootstrap (this: StoreType, search: string | undefined, category: string | undefined): Promise<void> {
  const resource = useResource()
  const { perPage } = usePagination()

  await Promise.all([
    this.fetchSearchedKnowledgeBase({ search, category, per_page: perPage.value, offset: 1 }),
    resource.fetchKnowledgeResource({ modules: ['categories'], limit: -1 })
  ])
}
