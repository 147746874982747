<template>
  <pms-page-shimmer
    v-if="loader"
    :shimmer="'list'"
    :full-width="true"
  />
  <pms-card
    v-else
    id="staff-workload-search"
    :header="false"
    :title="'common.search'"
    :description="$t('domains.administrative.staff-workload.search.description')"
    :options="{ body: { class: 'pt-0'}}"
  >
    <div class="d-flex flex-rows align-items-center pt-5">
      <button
        title="Back"
        type="button"
        class="btn btn-light-primary btn-sm me-2 p-2 ps-3 pe-3"
        @click="back()"
      >
        <pms-kt-icon
          icon-name="arrow-left"
          class="m-0 p-0"
        />
      </button>
      <div class="d-flex flex-column justify-content-start">
        <h1 class="text-dark fs-2 fw-bold mb-1">
          {{ $t('common.search-workload') }}
        </h1>
      </div>
    </div>
    <p class="fs-6 mt-3 mb-4">
      {{ $t('domains.administrative.staff-workload.search.description') }}
    </p>
    <div>
      <pms-smart-form
        :fields="formFields"
        @handleSubmit="onSubmit"
      >
        <template #department>
          <div class="card">
            <span class="mb-2 mt-1 required"> {{ `${t('common.departments')}:` }}</span>
            <TreeSelect
              v-model="selectedDep"
              class="styled"
              :options="sortedDep"
              :disable-branch-nodes="true"
            />
          </div>
        </template>
        <template #yearTerm>
          <span class="required">
            {{ $t('common.year-terms') }}:
          </span>
          <div class="mt-2">
            <select
              class="form-select form-select"
              @change="setYearTerm($event)"
            >
              <option>
                {{ $t('common.not-selected') }}
              </option>
              <option
                v-for="yearTerm in yearTerms || []"
                :key="(yearTerm.year + '' + yearTerm.term)"
                :value="yearTerm.year + '-' + yearTerm.term"
              >
                {{ $t('phrases.year-term', { year: `(${yearTerm.year} - ${(yearTerm.year)+ 1})`, term: yearTerm.term }) }}
              </option>
            </select>
          </div>
        </template>
        <template #formControls>
          <div class="form-group d-flex justify-content-end mt-4">
            <pms-button
              type="submit"
              class="w-150px"
              :disabled="searchLoader"
              :loader="searchLoader"
              :text="$t('common.search')"
            />
          </div>
        </template>
      </pms-smart-form>
    </div>
  </pms-card>
</template>
<script setup lang="ts">
import { useResource } from '@shared/stores'
import { computed, ref, watch } from 'vue'
import { useStaffList } from '../store'
import { useAlert, useLoader } from '@/shared/composable'
import { useI18n } from 'vue-i18n'
import { FormGroup } from '@/shared/types'
import TreeSelect from 'vue3-treeselect'
import { organizeDepartmentsByFaculties } from '../utils'
import { storeToRefs } from 'pinia'

const resource = useResource()
const store = useStaffList()
const { error } = useAlert()
const { t } = useI18n()
const { isActive } = useLoader()
const selectedDep = ref()
const { department, year, term } = storeToRefs(store)

const loader = isActive('administrative/staffs/workload/resources')
const searchLoader = isActive('administrative/staffs/workload')

const departments = computed(() => resource.getAdministrativeStaffsWorkloadResourcesDepartments)
const yearTerms = computed(() => resource.getAdministrativeStaffsWorkloadResourcesYearTerm)
const sortedDep = organizeDepartmentsByFaculties(departments.value)

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        name: 'department',
        type: 'slot',
        group: {
          class: 'mb-3 col-6'
        }
      },
      {
        name: 'yearTerm',
        type: 'slot',
        group: {
          class: 'mb-3 col-6'
        }
      }
    ]
  }
])

const onSubmit = async () => {
  const { status, message } = await store.fetchWorkload({ year: year.value, term: term.value, department_code: department.value.department_code })
  if (!status) error({ text: message, isToast: true })
}

const setYearTerm = ($event) => {
  const [year, term] = $event.target.value.split('-')
  store.setYearTerm(year, term)
}

const back = () => {
  store.setCurrentStep('employee')
}

watch(() => selectedDep.value, (newValue) => {
  store.setDepartment(newValue, departments.value)
})
</script>
