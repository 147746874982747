import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { ResourceRequest, ProfileResourceResponse, UnknownRequest } from '@shared/swagger'

export default async function getProfileResource (params: ResourceRequest): Promise<AxiosResponse<ProfileResourceResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, ProfileResourceResponse>({
    url: 'profile/resources',
    body: { params: params },
    loader: 'profile/resources'
  })

  return response
}
