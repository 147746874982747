import type { AxiosResponse, TourStep } from '@/shared/types'
import type { StoreType } from '../types'

import { saveTourTranslateContentByPath } from '@shared/api'
import { UnknownResponse } from '@/shared/swagger'

export default async function saveContentByPath (this: StoreType, path: string, content: Array<TourStep>): Promise<AxiosResponse<UnknownResponse>> {
  const response = await saveTourTranslateContentByPath(path, content)

  return response
}
