import type { StoreType } from '../types'

import { getSectionManagementSectionsSingleSection } from '@shared/api'

export default async function fetchSingleSection (this: StoreType, id: number): Promise<void> {
  const { data, ok } = await getSectionManagementSectionsSingleSection(id)

  if (ok) {
    this.setSingleSection(data)
  } else {
    this.setSingleSection(null)
  }
}
