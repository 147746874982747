import { computed, ref } from 'vue'
import { useLocale } from '@shared/composable'

import type { TourStep } from '@shared-types/common'

const tourSteps = ref<TourStep[]>([])

const useTour = (steps: TourStep[], status = false) => {
  const { locale } = useLocale()

  const currentStatus = ref<boolean>(status)
  const currentSteps = computed(() => tourSteps.value.filter((step) => step.locale === locale.value))

  const launch = (value: boolean): void => {
    currentStatus.value = value
  }

  const setSteps = (steps: TourStep[]): void => {
    tourSteps.value = steps
  }

  setSteps(steps)

  return {
    status: currentStatus,
    steps: currentSteps,
    launch,
    setSteps
  }
}

export default useTour
