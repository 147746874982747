import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import { ServiceEsignPutRequest, UnknownResponse } from '@shared/swagger'

export default async function getServiceEsignsResource (params: ServiceEsignPutRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { put } = useRequests()

  const response = await put<ServiceEsignPutRequest, UnknownResponse>({
    url: 'service/manage/esigns',
    body: params,
    loader: 'service/manage/esigns'
  })

  return response
}
