import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type { UnknownRequest, AcademicSingleStudentAttendanceGetRequest, AcademicSingleStudentAttendanceGetResponse } from '@shared/swagger'

export default async function getAcademicSingleStudentAttendance (id: string, params: AcademicSingleStudentAttendanceGetRequest): Promise<AxiosResponse<AcademicSingleStudentAttendanceGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, AcademicSingleStudentAttendanceGetResponse>({
    url: `academic/students/${id}/attendance`,
    body: { params: params },
    loader: 'academic/students/attendance'
  })

  return response
}
