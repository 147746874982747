import type { StoreType } from '../types'
import type { SingleRulePutRequest } from '@/shared/swagger'
import type { StatusMessageResponse } from '@/shared/types'
import { putRulesAndRegulations } from '@/shared/api'

export default async function editExistingRule (this: StoreType, ruleId: string, values: SingleRulePutRequest): Promise<StatusMessageResponse> {
  const { ok, message } = await putRulesAndRegulations(ruleId, values)

  ok && this.fetchSingleRule(ruleId)

  return {
    status: ok,
    message: message
  }
}
