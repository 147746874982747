<template>
  <pms-container class="mt-6 h-100">
    <pms-row>
      <pms-grid :col="12">
        <AllWidgetsHeader />
        <pms-page-header
          v-bind="header"
          @launch="launch"
        >
          <template #toolbar>
            <div class="d-flex flex-row mb-2">
              <div class="app-navbar-item ms-1 me-2 ms-lg-3">
                <pms-button
                  :class="device === 'mobile' ? 'd-none' : 'btn btn-icon fs-5'"
                  :title="$t('common.widgets')"
                  variant="tonal"
                  @click="toggleMenu"
                >
                  <span class="svg-icon svg-icon-1">
                    <pms-kt-icon
                      icon-name="element-11"
                      icon-class="fs-2 fs-md-1"
                    />
                  </span>
                </pms-button>
              </div>
              <pms-feedback-modal
                class="me-2"
                :options="feedback"
              />
            </div>
          </template>
        </pms-page-header>
      </pms-grid>
    </pms-row>
    <pms-page-tour
      :launched="status"
      :steps="steps"
      @launch="launch"
    />
    <pms-row>
      <pms-page-shimmer
        v-if="loader"
        :filter="true"
      />
      <template v-else>
        <span
          v-test-id="'title'"
          class="fw-bolder mb-2 text-dark h1 mt-2"
        >
          {{ $t('domains.dashboard.widgets.greeting.title', { name: user }) }}
        </span>
        <div :class="device === 'mobile' ? 'd-none' : 'card border border-dashed border-2 border-primary w-90 h-100'">
          <draggable
            class="dragArea list-group d-flex flex-row flex-wrap"
            group="addWidgets"
            item-key="id"
          >
            <template #item>
              <div />
            </template>
            <template #footer>
              <div class="card-body text-center w-100 h-100px">
                <h3 class="card-title">
                  {{ $t('dashboard.drop.press') }}
                  <pms-button
                    class="btn m-2 btn-icon fs-5"
                    variant="tonal"
                    @click="toggleMenu"
                  >
                    <span class="svg-icon svg-icon-1">
                      <pms-kt-icon
                        icon-name="element-11"
                        icon-class="fs-2 fs-md-1"
                      />
                    </span>
                  </pms-button>
                  {{ $t('dashboard.drop.card') }}
                </h3>
              </div>
            </template>
          </draggable>
        </div>
        <draggable
          :class="device === 'mobile' ? 'd-none' : 'dragArea list-group d-flex flex-row flex-wrap'"
          :list="dashboardWidgets"
          item-key="id"
          group="addWidgets"
          @change="saveOrder"
          @end="end"
        >
          <template #item="{ element }">
            <component
              :is="getWidget(element.id)"
              :data-widget-id="element.id"
              class="list-group-item p-2 m-2 card"
              v-bind="element.props"
            />
          </template>
        </draggable>
        <div
          v-for="element in dashboardWidgets"
          :key="element.id"
          :class="{
            'list-group d-flex flex-row flex-wrap': device === 'mobile',
            'd-none': device !== 'mobile'
          }"
        >
          <component
            :is="getWidget(element.id)"
            :data-widget-id="element.id"
            class="list-group-item p-2 m-2 card"
          />
        </div>
      </template>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useLoader, useTour } from '@shared/composable'
import { header, tour, feedback, dashboardWidgets } from './values'
import { useDashboard } from './store'
import { toggleMenu, saveOrder } from './utils'
import draggable from 'vuedraggable'
import { useUser } from '@shared/stores'
import AllWidgetsHeader from './features/AllWidgetsHeader.vue'
import { storeToRefs } from 'pinia'
import { deviceType } from '@shared/utils'

const { isActiveOneOf } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const userStore = useUser()
const user = computed(() => userStore.currentUser?.name)
const loader = isActiveOneOf(['/1.0.0/dashboard/widgets'])
const store = useDashboard()
const { widgets } = storeToRefs(store)
const device = deviceType()

const getWidget = (widgetId) => {
  return widgets.value[widgetId] || null
}

const end = async (event) => {
  const { item, from, to } = event

  if (from !== to) {
    const widgetId = item.dataset.widgetId

    if (widgetId) {
      await store.addWidgets({ widget_id: Number(widgetId) })
    }
  }
}

onMounted(async () => {
  setSteps(await tour())
})
</script>
