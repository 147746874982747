<template>
  <vue-resizable
    id="syllabus-widget"
    class="resizable-widget card"
    :width="widgetSize.width"
    :height="widgetSize.height"
    min-width="450"
    min-height="450"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column col-8">
        <span
          v-test-id="'title'"
          class="card-label fw-bolder text-dark"
        >
          {{ $t('domains.dashboard.widgets.syllabus.title') }}
        </span>
        <span
          v-test-id="'description'"
          class="text-muted mt-1 fw-bold fs-7"
        >
          {{ $t('domains.dashboard.widgets.syllabus.description') }}
        </span>
      </h3>
      <div class="card-toolbar">
        <router-link
          class="btn btn-sm btn-light"
          :to="{ path: redirectLink }"
        >
          {{ $t('domains.dashboard.widgets.syllabus.view-all') }}
        </router-link>
      </div>
    </div>
    <div class="card-body pt-6">
      <div
        v-if="currentView !== null"
        class="card-toolbar pb-10"
      >
        <select
          v-model="formModel"
          v-test-id="'syllabus-select'"
          class="form-select"
          @change="updateView()"
        >
          <option
            v-for="(stat, index) in statistics"
            :key="index"
            :value="stat.title"
          >
            {{ stat.title }}
          </option>
        </select>
      </div>
      <div
        v-if="currentView === null"
        v-test-id="'no-data'"
        class="d-flex align-items-center justify-content-center min-h-40px mb-3"
      >
        {{ $t('common.no-data') }}
      </div>
      <div
        class="flex-grow-1"
      >
        <apexchart
          class="mixed-widget-4-chart"
          type="radialBar"
          height="250"
          :options="options"
          :series="currentValues"
        />
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCSSVariableValue } from '@/assets/ts/_utils'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { SyllabusWidget } from '../types'
import { onResize, getSavedWidgetSize } from '../utils'

const store = useDashboard()
const { t } = useI18n()

const statistics = computed(() => store.getSyllabusStatistics)
const redirectLink = '/academic/syllabuses'
const optionColors: string[] = [
  getCSSVariableValue('--bs-info'),
  getCSSVariableValue('--bs-warning'),
  getCSSVariableValue('--bs-primary'),
  getCSSVariableValue('--bs-danger'),
  getCSSVariableValue('--bs-success')
]
const optionLabels: string[] = [
  t('domains.dashboard.widgets.syllabus.not-applied'),
  t('domains.dashboard.widgets.syllabus.applied'),
  t('domains.dashboard.widgets.syllabus.accepted'),
  t('domains.dashboard.widgets.syllabus.rejected'),
  t('domains.dashboard.widgets.syllabus.confirmed')
]
const options = {
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: '30%',
        background: 'transparent',
        image: undefined
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          show: false
        }
      }
    }
  },
  colors: optionColors,
  labels: optionLabels,
  stroke: { lineCap: 'round' },
  legend: {
    show: true,
    floating: true,
    fontSize: '12px',
    position: 'left',
    offsetX: 15,
    offsetY: -15,
    labels: {
      useSeriesColors: true
    },
    markers: {
      size: 0
    },
    formatter: function (seriesName, opts) {
      const percentage: number = opts.w.globals.series[opts.seriesIndex]
      return `${percentage.toFixed(0)}% ${seriesName}`
    },
    itemMargin: {
      vertical: 1
    }
  },
  responsive: [{ breakpoint: 480, options: { legend: { show: false } } }]
}

const currentView = ref<SyllabusWidget | null>(null)
const currentValues = ref<number[]>([0, 0, 0, 0, 0])
const formModel = ref<string>('')
const updateView = (): void => {
  const selected = statistics.value.find((statistic) => statistic.title === formModel.value)
  if (selected) {
    currentView.value = selected
    const total: number = currentView.value.notApplied +
          currentView.value.applied +
          currentView.value.accepted +
          currentView.value.confirmed +
          currentView.value.rejected

    currentValues.value = [
      ((currentView.value.notApplied / total) * 100),
      ((currentView.value.applied / total) * 100),
      ((currentView.value.accepted / total) * 100),
      ((currentView.value.rejected / total) * 100),
      ((currentView.value.confirmed / total) * 100)
    ]
  }
}

if (statistics.value.length > 0) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  formModel.value = statistics.value[0].title
  updateView()
}

const widgetId = 'syllabus-widget'

const widgetSize = ref({ width: 'auto', height: 'auto' })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
