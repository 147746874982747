import { useRequests } from '@shared/composable'
import type { AxiosResponse } from '@shared/types'
import type {
  UnknownRequest,
  KnowledgeManagePostsGetRequest,
  KnowledgeManagePostsGetResponse
} from '@shared/swagger'

export default async function getKnowledgeManagePosts (params: KnowledgeManagePostsGetRequest): Promise<AxiosResponse<KnowledgeManagePostsGetResponse>> {
  const { get } = useRequests()

  const response = await get<UnknownRequest, KnowledgeManagePostsGetResponse>({
    url: 'knowledge/manage/posts',
    body: { params: params },
    loader: 'knowledge/manage/posts'
  })

  return response
}
