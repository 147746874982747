import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { deleteServiceManageSingleAnnouncement } from '@/shared/api'
import { tempValues } from '../../values'

export default async function deleteExistingRule (this: StoreType, announcementId: number): Promise<StatusMessageResponse> {
  const { ok, message } = await deleteServiceManageSingleAnnouncement(announcementId)

  ok && await this.fetchAnnouncements(tempValues.value.year.getFullYear())

  return {
    status: ok,
    message: message
  }
}
