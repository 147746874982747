<template>
  <pms-card
    :title="$t('domains.service.manage.language.search.title')"
    :description="$t('domains.service.manage.language.search.applicants.description')"
    :options="{body:{class: 'pt-0'}}"
  >
    <pms-smart-form
      :fields="formFields"
      @handleChange="onHandleChange"
      @handleSubmit="onSubmitForm"
    >
      <template #formControls>
        <div class="d-flex flex-end mt-6">
          <pms-button
            :type="'submit'"
            :disabled="loader"
            :loader="loader"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { FormGroup } from '@shared/types'
import { useLoader, usePagination } from '@shared/composable'
import { useResource } from '@shared/stores'
import { applicant_language_code, applicantsSearchValues, selectedDatasForModal } from '../values'
import { useAllStudents } from '../store'
import { languageaToOptions, programCodeToOption, yearToOption } from '../utils'

const { t } = useI18n()
const { perPage } = usePagination()
const resource = useResource()
const store = useAllStudents()
const languages = computed(() => resource.getServiceManageLanguagesResourcesLanguages)
const department = computed(() => resource.getServiceManageLanguagesResourcesDepartments)
const year = computed(() => resource.getServiceManageLanguagesResourcesYears)

const loader = useLoader().isActiveOneOf(['service/manage/languages/applicants'])

const formFields = computed(():Array<FormGroup> => {
  return [
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.search-field.language')}:`,
            class: 'mb-1 mt-2 required'
          },
          name: 'language_code',
          type: 'select',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.language_code || null,
          options: languageaToOptions(languages.value)
        },
        {
          label: {
            text: `${t('common.search-field.department-code')}:`,
            class: 'mb-1 mt-2 required'
          },
          name: 'program_code',
          type: 'select',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.program_code || null,
          options: programCodeToOption(department.value)
        }
      ]
    },
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.search-field.iin')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'iin',
          type: 'text',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.iin || ''
        },
        {
          label: {
            text: `${t('common.search-field.name')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'name',
          type: 'text',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.name || ''
        }
      ]
    },
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.search-field.surname')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'surname',
          type: 'text',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.surname || ''
        },
        {
          label: {
            text: `${t('common.search-field.entrance_year')}:`,
            class: 'mb-1 mt-2 required'
          },
          name: 'entrance_year',
          type: 'select',
          group: {
            class: 'mb-3 col-6'
          },
          value: applicantsSearchValues.value.entrance_year || null,
          options: yearToOption(year.value)
        }
      ]
    },
    {
      class: 'row',
      fields: [
        {
          label: {
            text: `${t('common.search-field.applicants.no_level')}:`,
            class: 'mb-1 mt-2'
          },
          name: 'no_level',
          type: 'switch',
          class: 'mt-2',
          cover: {
            class: 'flex-row-reverse gap-4 justify-content-end'
          },
          value: applicantsSearchValues.value.no_level
        }
      ]
    }
  ]
})

const onHandleChange = ({ name, newValue }) => {
  applicantsSearchValues.value[name] = newValue
}

const onSubmitForm = async ({ values }) => {
  let no_level: 0|1 = 0
  if (values.no_level) no_level = 1

  applicantsSearchValues.value = {
    ...applicantsSearchValues.value,
    ...values
  }
  const status = await store.fetchApplicants({ ...values, no_level, per_page: perPage.value, offset: 1 })

  if (status.status) {
    applicant_language_code.push(values.language_code)
    selectedDatasForModal.value = []
  }
}
</script>
