import { App } from 'vue'
import { event } from 'vue-gtag'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const runner = (vue: App<Element>): void => {
  if (process.env.NODE_ENV === 'production') {
    vue.config.errorHandler = (error, instance, info) => {
      event('production-error-log', { error, info })
    }
  }

  vue.config.globalProperties.variables = {
    APP_NAME: process.env.APP_NAME,
    APP_FULL_NAME: process.env.APP_FULL_NAME,
    APP_VERSION: process.env.APP_VERSION,
    APP_DEVICE: process.env.APP_DEVICE,

    APP_GOOGLE_TRACKER: process.env.APP_GOOGLE_TRACKER,

    APP_API_URI: process.env.APP_API_URI,
    APP_API_VERSION: process.env.APP_API_VERSION,

    APP_PAGINATION_PAGINATE: process.env.APP_PAGINATION_PAGINATE,
    APP_PAGINATION_PER_PAGE: process.env.APP_PAGINATION_PER_PAGE,
    APP_PAGINATION_CURRENT_PAGE: process.env.APP_PAGINATION_CURRENT_PAGE,
    APP_PAGINATION_MAX_VISIBLE_PAGES: process.env.APP_PAGINATION_MAX_VISIBLE_PAGES,

    APP_OLD_PMS_URI: process.env.APP_OLD_PMS_URI,

    PUSHER_APP_ID: process.env.PUSHER_APP_ID,
    PUSHER_APP_KEY: process.env.PUSHER_APP_KEY,
    PUSHER_APP_SECRET: process.env.PUSHER_APP_SECRET,
    PUSHER_HOST: process.env.PUSHER_HOST,
    PUSHER_PORT: process.env.PUSHER_PORT,
    PUSHER_CLUSTER: process.env.PUSHER_CLUSTER,
    PUSHER_HTTP_HOST: process.env.PUSHER_HTTP_HOST
  }
}

export default {
  run: runner,
  priority: 99
}
