<template>
  <Teleport to="#syllabus-form-content">
    <pms-smart-form
      class="mb-5"
      :fields="[formFields]"
      @handleSubmit="onSubmit"
    >
      <template #formControls>
        <div class="form-group mt-4">
          <pms-button
            type="submit"
            class="w-10 text-center"
            :text="$t('common.search')"
          />
        </div>
      </template>
    </pms-smart-form>
    <pms-smart-table
      :items="employees"
      :headers="headers"
      :sortable="true"
    >
      <template #no="{ idx }">
        {{ idx + 1 }}
      </template>
      <template #fullName="{ row }">
        <span>{{ row.name }} {{ row.surname }}</span>
      </template>
      <template #action="{ item }">
        <button
          type="button"
          class="btn btn-light-primary"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addInstructor(item)"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </template>
    </pms-smart-table>
    <pms-pagination
      class="p-0 m-0 mt-6"
    />
  </Teleport>
</template>
<script setup lang="ts">
import { computed, ref, watch, inject, onUnmounted } from 'vue'
import { headers } from './values'
import { useStaffList } from './store'
import { FormField } from '@shared/types'
import { useI18n } from 'vue-i18n'
import { usePagination, useTeleportModal } from '@shared/composable'
import { AdministrativeStaffsResponse } from '@shared/swagger'
import { objectDeepCopy } from '@shared/utils'

const staffStore = useStaffList()
const employees = computed(() => staffStore.getEmployees)
const { perPage } = usePagination()
const { t } = useI18n()

const { show, modifier } = useTeleportModal()

const options = inject('EditorSyllabus')

const defaultValue = {
  search: null
}

const tempValue = ref(objectDeepCopy(defaultValue))

const formFields = computed<Array<FormField>>(() => [
  {
    label: {
      text: `${t('common.search')}:`,
      class: 'mb-1'
    },
    name: 'search',
    type: 'text',
    value: tempValue.value.search || '',
    validator: {
      schema: 'yup',
      rules: [
        'string',
        { func: 'label', value: t('common.table.search') },
        'trim'
      ]
    }
  }
])

options?.bus.on('resetStaffStore', resetStaffStore)

onUnmounted(() => {
  options?.bus.off('resetStaffStore', resetStaffStore)
})
watch(
  () => modifier.value,
  (newValue) => {
    if (newValue && newValue.data) {
      if (newValue.data.mode === 'add') {
        tempValue.value = objectDeepCopy(defaultValue)

        show()
      }
    }
  },
  { immediate: true }
)

async function onSubmit ({ values }) {
  tempValue.value.search = values.search
  if (typeof values.search === 'string' && values.search.length > 0) {
    await staffStore.fetchEmployees({ ...values, per_page: perPage.value, offset: 1 })
  }
}

function addInstructor (instructor: AdministrativeStaffsResponse['employees'][0]) {
  options?.bus?.emit('employeeShowId', instructor.id)
}

function resetStaffStore () {
  staffStore.$reset()
}
</script>
