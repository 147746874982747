<template>
  <pms-row>
    <pms-grid
      id="single-student-schedule"
      class="mt-4"
    >
      <pms-card
        :header="false"
        :options="{
          card: {class: 'rounded-3 shadow-sm'},
          body: {class: 'p-0 rounded-3'}
        }"
      >
        <div class="table-responsive">
          <table
            id="singleCourseSchedulePrint"
            class="table table-row-bordered align-middle text-center gy-4 mb-0 pb-0"
          >
            <table-head />
            <table-body
              :schedule="scheduleRecords"
              :schedule-hours="scheduleHours"
            />
          </table>
        </div>
      </pms-card>
    </pms-grid>
  </pms-row>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue'

import TableHead from '@domains/AcademicSchedule/ui/TableHead.vue'
import TableBody from '@domains/AcademicSchedule/ui/TableBody.vue'

import { useSingleStudent } from '../../../store'
import { useResource } from '@/shared/stores'

const store = useSingleStudent()
const resource = useResource()

const scheduleRecords = computed(() => store.getSchedule)

const scheduleHours = computed(() => {
  return resource.scheduleManagementSectionProgramHours ?? []
})

const currentYearTerm = computed(() => store.getCurrentYearTerm)

watch(() => currentYearTerm.value, () => {
  if (currentYearTerm.value.year && currentYearTerm.value.term) {
    resource.fetchYearTermScheduleHoursResources({ year: currentYearTerm.value.year, term: currentYearTerm.value.term })
  }
}, { immediate: true })

</script>
