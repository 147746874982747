export const scheduleFormTabs = [
  {
    id: 'weekly',
    label: 'domains.academic.single-student.weekly.title'
  },
  {
    id: 'hour',
    label: 'domains.academic.single-student.hour.title'
  }
]
