<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <year-terms v-if="myYearTerms.length > 0" />
      <toolbar-actions />
      <pms-driver-button
        class="me-2 ms-2 mt-2 mt-md-0"
      />
      <pms-feedback-modal
        class="mb-0"
        :button-class="'mt-2 mt-md-0'"
        :options="[]"
      />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container class="mt-6">
    <pms-row v-if="loader">
      <pms-page-shimmer
        :shimmer="'table'"
        :full-width="true"
        :filter="false"
      />
    </pms-row>
    <pms-row v-else>
      <pms-grid :col="12">
        <template v-if="scheduleRecords.length>0">
          <schedule-table />
        </template>
        <template
          v-else
        >
          <empty-card
            title="domains.academic.my-schedule.empty"
          />
        </template>
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useTour, useLoader, useToolbar, useDriver } from '@shared/composable'
import { tour, header, quickScheduleSetTourSteps } from './values'
import { useStore } from './store'
import { useResource } from '@/shared/stores'

import YearTerms from './ui/YearTerms.vue'
import ScheduleTable from './ui/ScheduleTable.vue'
import EmptyCard from '@shared/ui/components/EmptyCard.vue'

const { isActiveOneOf } = useLoader()
const { status, steps, launch, setSteps } = useTour([])
const { setSteps: setQuickTourSteps } = useDriver()
const resource = useResource()
const { toolbar, toggleToolbar } = useToolbar()
const { scheduleRecords } = storeToRefs(useStore())

const myYearTerms = computed(() => resource.getAcademicCoursesResourceMyYearTerms)
const loader = isActiveOneOf(['academic/information/schedules', 'academic/courses/resources'])

const store = useStore()

const currentYearTerm = computed(() => store.getCurrentYearTerm)

watch(() => currentYearTerm.value, () => {
  if (currentYearTerm.value.year && currentYearTerm.value.term) {
    resource.fetchYearTermScheduleHoursResources({ year: currentYearTerm.value.year, term: currentYearTerm.value.term })
  }
}, { immediate: true })

onMounted(async () => {
  setSteps(await tour())
  setQuickTourSteps(await quickScheduleSetTourSteps())
  toggleToolbar()
})

</script>
