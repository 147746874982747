import { useRequests } from '@/shared/composable'

import type { UnknownRequest, AcademicReportsCategoriesGetResponse } from '@/shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function (): Promise<AxiosResponse<AcademicReportsCategoriesGetResponse>> {
  const { get } = useRequests()

  const response = get<UnknownRequest, AcademicReportsCategoriesGetResponse>({
    url: 'academic/information/reports/categories',
    body: { },
    loader: 'academic/information/reports/categories'
  })

  return response
}
