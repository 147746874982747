import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { useToken } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  const resource = useResource()
  await resource.fetchResource({ modules: ['year-terms'] })
  const year = resource.resource?.year_terms[0].year ?? useToken().getYear()

  await Promise.all([
    this.setYear(year),
    this.fetchNavigation(year)
  ])
}
