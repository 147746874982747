<template>
  <div class="d-flex align-items-center flex-fill">
    <div class="symbol symbol-50px me-5">
      <img
        style="object-fit: cover;"
        :src="post.user_photo"
        :alt="post.user_full_name"
      >
    </div>
    <div
      class="flex-grow-1"
      @click="toggleShow"
    >
      <span
        class="text-gray-800 fs-4 fw-bold"
      >
        {{ post.user_full_name }}
      </span>
      <span class="text-gray-400 fw-semibold d-block">
        {{ post.created_at }}
      </span>
    </div>
  </div>
  <div
    class="ms-2"
  >
    <button
      v-if="user?.username === post.username"
      class="btn btn-light-danger btn-sm fw-bold"
      @click="handleDeletePost"
    >
      <span
        v-if="postDeleteLoader && id === post.id"
        class="indicator-label"
      >
        {{ $t('phrases.please-wait') }}
        <span class="spinner-border spinner-border-sm align-middle ms-2" />
      </span>
      <span
        v-else
        class="indicator-label d-flex gap-1 align-items-center"
      >
        <pms-kt-icon
          :icon-name="'trash'"
          class="fw-bold fs-4 p-0 "
        />
        {{ $t('common.delete') }}
      </span>
    </button>
    <button
      class="btn btn-sm px-3 ms-2 btn-outline text-hover-primary border-hover-primary"
      :class="expand ? 'btn-outline-warning':'btn-outline-primary'"
      @click="toggleShow"
    >
      <template v-if="expand">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-eye-slash"
          viewBox="0 0 16 16"
        >
          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
          <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
        </svg>
      </template>
      <template v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-eye"
          viewBox="0 0 16 16"
        >
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
        </svg>
      </template>
    </button>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import { useUser } from '@shared/stores'
import { useAlert, useLoader } from '@shared/composable'

import { useMessageBoard } from '@domains/AdministrativeMessageBoard/store'
import { AdministrativeMessageBoardPostsGetResponse } from '@shared/swagger'

const props = defineProps({
  post: {
    type: Object as PropType<AdministrativeMessageBoardPostsGetResponse['posts'][0]>,
    required: true
  },
  toggleShow: {
    type: Function as PropType<CallableFunction>,
    required: true
  },
  expand: {
    type: Boolean,
    required: true
  }
})
const alert = useAlert()
const { isActive } = useLoader()

const store = useMessageBoard()

const user = computed(() => useUser().currentUser)
const postDeleteLoader = isActive('administrative/message-board/posts-delete')

const id = ref<number|null>(null)

const handleDeletePost = () => {
  id.value = props.post.id
  alert.dialog(
    {
      text: 'domains.administrative.message-board.post-item.delete-post-dialog',
      textProperties: { title: props.post.title },
      onConfirm: () => {
        store.deletePost(props.post.id)
      }
    }
  )
}
</script>

<style scoped>
img {
  max-width: 100% !important;
}
</style>
