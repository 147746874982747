import type { StoreType } from '../types'

import { useResource } from '@shared/stores'
import { useToken } from '@shared/composable'

export default async function bootstrap (this: StoreType): Promise<void> {
  let year = useToken().getYear()
  let term = useToken().getTerm()
  const resource = useResource()

  await resource.fetchAcademicCoursesResources({ modules: ['my-year-terms'] })
  const yearTerms = resource.getAcademicCoursesResourceMyYearTerms

  const isExistedYear = yearTerms.find((item) => item.year === year && item.term === term)

  if (!isExistedYear && yearTerms.length > 0) {
    year = yearTerms[0].year
    term = yearTerms[0].term
  }

  this.setCurrentYearTerm({ year, term })
}
