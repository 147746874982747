<template>
  <div class="mt-2 mb-6">
    <select
      id="knowledge-post-content-selector"
      v-model="active"
      class="form-select w-250px"
    >
      <template
        v-for="(content, index) in contents"
        :key="index"
      >
        <option
          :value="content.uuid"
          :selected="content.uuid === active"
        >
          {{ content.version }} ({{ content.type_title }}) {{ content.active ? ` - ${$t('common.active')} ` : '' }}
        </option>
      </template>
    </select>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useKnowledgeBaseSingle } from '../store'
import { active } from '../values'

const store = useKnowledgeBaseSingle()
const contents = computed(() => store.getSingleKnowledgeBase?.contents || [])
</script>
