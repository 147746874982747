import type { StoreType } from '../types'
import type { StatusMessageResponse } from '@/shared/types'
import { getKnowledgeManageSinglePost } from '@shared/api'

export default async function fetchPost (this: StoreType, uuid: string): Promise<StatusMessageResponse> {
  const { ok, message, data } = await getKnowledgeManageSinglePost(uuid)

  if (ok) {
    this.setPost(data)
    // this.setPostContents(data.contents)
  }

  return {
    status: ok,
    message
  }
}
