<template>
  <pms-page-toolbar
    v-if="toolbar"
    v-bind="header"
    @launch="launch"
  >
    <template #action>
      <year-terms />
      <pms-driver-button class="me-2 ms-2 mt-2 mt-md-0" />
      <pms-feedback-modal :options="[]" />
      <pms-page-tour
        :launched="status"
        :steps="steps"
        @launch="launch"
      />
    </template>
  </pms-page-toolbar>
  <pms-container>
    <pms-row class="mt-6">
      <pms-row v-if="loader">
        <pms-page-shimmer
          :shimmer="'list'"
          :full-width="true"
          :filter="false"
        />
      </pms-row>
    </pms-row>
    <pms-row>
      <pms-grid
        :col="12"
        class="mb-5"
      >
        <horizontal-tab-menu :menu-items="menuItems.map(m => ({...m, to: m.to+ `?year=${yearTerm.year}&term=${yearTerm.term}`}))" />
      </pms-grid>
      <pms-grid
        :col="12"
        :order-mb="2"
        class="mt-5 mt-md-0"
      >
        <router-view />
      </pms-grid>
    </pms-row>
  </pms-container>
</template>
<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useTour, useToolbar, useDriver } from '@shared/composable'

import { header, tour, newRequestsTour, sentRequestsTour, receivedRequestsTour, menuItems } from './values'

import YearTerms from './ui/YearTerms.vue'
import HorizontalTabMenu from '@/shared/ui/components/HorizontalTabMenu.vue'
import { useSectionManagement } from './store'

const route = useRoute()
const store = useSectionManagement()

const { status, steps, launch, setSteps } = useTour([])
const { toolbar, toggleToolbar } = useToolbar()
const { setSteps: setQuickTourSteps } = useDriver()
const yearTerm = computed(() => store.getCurrentYearTerm)

const loader = ''

onMounted(async () => {
  setSteps(await tour())
  toggleToolbar()
  setQuickTourSteps(
    route.name === 'NewRequestPage'
      ? await newRequestsTour()
      : route.name === 'SentRequestsPage'
        ? await sentRequestsTour()
        : await receivedRequestsTour())
})
</script>
