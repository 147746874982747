import { useServiceEsignStore } from '@/domains/ServiceEsign/store'
import { yearTermsToYearOptions, entranceYearsToYearOptions, programsToProgramOptions, documentsToDocumentOptions, programCodeTitleToValue } from '@/domains/ServiceEsign/utils'
import { useAlert, usePagination } from '@/shared/composable'
import { useResource } from '@/shared/stores'
import { FormGroup } from '@/shared/types'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { classes } from '../values'
import { ServiceEsignGetRequest } from '@/shared/swagger'

export function useSearchForm () {
  const { t } = useI18n()
  const { error } = useAlert()
  const { perPage } = usePagination()
  const { serviceEsignResource } = storeToRefs(useResource())
  const store = useServiceEsignStore()

  const tempValues = ref({
    document_type: undefined,
    program_code: undefined,
    student_id: undefined,
    student_name: undefined,
    student_surname: undefined,
    student_entrance_year: undefined,
    student_class: undefined,
    with_signed: [],
    year_term: `${serviceEsignResource.value?.year_terms[0].year}-${serviceEsignResource.value?.year_terms[0].term}`
  })

  const formFields = computed(():Array<FormGroup> => [
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('domains.service.esign.form.select-year-term')}:`,
            class: 'mb-1 required'
          },
          name: 'year_term',
          type: 'select',
          required: true,
          value: tempValues.value.year_term,
          options: yearTermsToYearOptions(serviceEsignResource.value?.year_terms)
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('domains.service.esign.form.select-entrance-year')}:`,
            class: 'mb-1'
          },
          name: 'student_entrance_year',
          type: 'select',
          value: tempValues.value.student_entrance_year,
          options: entranceYearsToYearOptions(serviceEsignResource.value?.entrance_years)
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: t('domains.service.esign.form.select-program-code')
          },
          name: 'program_code',
          type: 'select',
          value: tempValues.value.program_code,
          options: programsToProgramOptions(serviceEsignResource.value?.programs, serviceEsignResource.value?.departments)
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('domains.service.esign.form.select-document')}:`,
            class: 'mb-1'
          },
          name: 'document_type',
          type: 'select',
          value: tempValues.value.document_type,
          options: documentsToDocumentOptions(serviceEsignResource.value?.document_types)
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('common.table.student_id')}:`,
            class: 'mb-1'
          },
          name: 'student_id',
          type: 'text',
          value: tempValues.value.student_id || ''
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('common.table.name')}:`,
            class: 'mb-1'
          },
          name: 'student_name',
          type: 'text',
          value: tempValues.value.student_name || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.name') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('common.table.surname')}:`,
            class: 'mb-1'
          },
          name: 'student_surname',
          type: 'text',
          value: tempValues.value.student_surname || '',
          validator: {
            schema: 'yup',
            rules: [
              'string',
              { func: 'label', value: t('common.table.surname') },
              'trim'
            ]
          }
        },
        {
          group: {
            class: 'col-12 col-md-3'
          },
          label: {
            text: `${t('domains.service.esign.form.select-class')}:`,
            class: 'mb-1'
          },
          name: 'student_class',
          type: 'select',
          value: tempValues.value.student_class,
          options: classes
        }
      ]
    },
    {
      class: 'row mb-3',
      fields: [
        {
          group: {
            class: 'mt-3'
          },
          name: 'with_signed',
          type: 'checkbox',
          inline: false,
          options: [
            {
              id: '1',
              value: tempValues.value.with_signed,
              label: {
                class: 'text-dark',
                text: t('domains.service.esign.form.with_signed')
              }
            }
          ]
        }
      ]
    }
  ])

  const onSubmit = async () => {
    if (tempValues.value.year_term) {
      const request:ServiceEsignGetRequest = {
        per_page: perPage.value,
        offset: 1,
        year: +tempValues.value.year_term.split('-')[0],
        term: +tempValues.value.year_term.split('-')[1],
        document_type: tempValues.value.document_type,
        program_code: programCodeTitleToValue(tempValues.value.program_code),
        student_id: tempValues.value.student_id,
        student_name: tempValues.value.student_name,
        student_surname: tempValues.value.student_surname,
        student_entrance_year: tempValues.value.student_entrance_year,
        with_signed: tempValues.value.with_signed.length > 0 ? 1 : 0,
        student_class: tempValues.value.student_class
      }
      const { status, message } = await store.fetchEsignStudents(request)
      if (!status) error({ text: message })
    } else {
      error({ text: 'Year and term are not selected' })
    }
  }

  const onHandleChange = ({ name, newValue }) => {
    tempValues.value[name] = newValue
  }

  return {
    formFields,
    tempValues,
    onSubmit,
    onHandleChange
  }
}
