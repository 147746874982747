<template>
  <div id="course-history-information">
    <h3 class="py-2">
      <span class="card-label fw-bold text-dark fs-3">
        {{ $t('domains.academic.single-course.history.information-title') }}
      </span>
    </h3>
    <div
      v-if="information && Object.keys(information).length > 0"
      class="row"
    >
      <template
        v-for="(headers, index) in informationHeaders"
        :key="index"
      >
        <div class="col-12 col-md-6">
          <div class="table-responsive">
            <table class="table table-bordered table-border-blue">
              <tbody>
                <template
                  v-for="(item, hIndex) in headers"
                  :key="hIndex"
                >
                  <tr style="vertical-align: middle;">
                    <td
                      class="fs-5 fw-bold"
                      :rowspan="item.optional && item.optional === 'credits' ? item.value.length + 1 : 1"
                      :class="{
                        'w-100px': item.optional && item.optional === 'credits'
                      }"
                    >
                      {{ $t(`common.table-fields.${item.label}`) }}
                    </td>
                    <template v-if="item.optional && item.optional === 'code'">
                      <td class="fs-5 fw-semibold">
                        {{ information?.[item.value[0]] }} {{ information?.[item.value[1]] ? `(${information?.[item.value[1]]})` : '' }}
                      </td>
                    </template>
                    <template v-if="item.optional && item.optional === 'name'">
                      <td
                        class="fs-5 fw-semibold"
                        colspan="2"
                      >
                        {{ information?.[item.value[0]] }} {{ information?.[item.value[1]] ? `(${information?.[item.value[1]]})` : '' }}
                      </td>
                    </template>
                    <template v-if="item.optional && item.optional === 'default'">
                      <td class="fs-5 fw-semibold">
                        {{ information?.[item.value[0]] }}
                      </td>
                    </template>
                  </tr>
                  <template v-if="item.optional && item.optional === 'credits'">
                    <tr
                      v-for="i in item.value"
                      :key="i"
                    >
                      <td class="fs-5 fw-bold">
                        {{ $t(`common.table-fields.${i}`) }}
                      </td>
                      <td class="fs-5 fw-semibold">
                        {{ information?.[i] }}
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useSingleCourse } from '../../../store'
import { informationHeaders } from '../values'

const store = useSingleCourse()
const information = computed(() => store.getHistoryInformation)

</script>
