import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useAllStudents = defineStore<Name, State, Getters, Actions>('Academic.useAllStudents', {
  actions: {
    ...actions
  },

  getters: {},

  state () {
    return {
      students: null
    }
  }
})
