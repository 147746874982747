import { useRequests } from '@/shared/composable'
import type { AxiosResponse } from '@/shared/types'
import type { KnowledgeManagePostsContentsPostRequest, KnowledgeManagePostsContentPostResponse } from '@shared/swagger'

export default async function postKnowledgeManagePostsContents (uuid: string, body: KnowledgeManagePostsContentsPostRequest): Promise<AxiosResponse<KnowledgeManagePostsContentPostResponse>> {
  const { post } = useRequests()

  const response = post<KnowledgeManagePostsContentsPostRequest, KnowledgeManagePostsContentPostResponse>({
    url: `knowledge/manage/posts/${uuid}/contents`,
    body,
    loader: 'knowledge/manage/posts/post-contents'
  })
  return response
}
