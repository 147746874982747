
import getCoursesOfferedOtherDepartment from '@/shared/api/AcademicCurricula/getCoursesOfferedOtherDepartment'
import type { StoreType } from '../types'
import type { AcademicManageCurriculumCoursesRequest } from '@/shared/swagger'

export default async function prepareCoursesOfferedOtherDepartment (this: StoreType, params: AcademicManageCurriculumCoursesRequest): Promise<void> {
  const { data, ok } = await getCoursesOfferedOtherDepartment({
    program_code: params.program_code,
    year: params.year
  })

  if (ok) {
    this.setCoursesOfferedOtherDepartment(data)
  }
}
