import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse } from '@shared/swagger'

export default async function destroyProfileExperiences (id: number): Promise<AxiosResponse<UnknownResponse>> {
  const { destroy } = useRequests()

  const response = await destroy<UnknownResponse, UnknownResponse>({
    url: `profile/experiences/${id}`,
    body: {},
    loader: 'profile/experiences-destroy'
  })

  return response
}
