import { useRequests } from '@shared/composable'

import type { UnknownRequest, ProfileEducationOrganizationsGetResponse } from '@shared/swagger'
import type { AxiosResponse } from '@shared/types'

export default async function getProfileEducationOrganizations (): Promise<AxiosResponse<ProfileEducationOrganizationsGetResponse>> {
  const { get } = useRequests()
  const response = await get<UnknownRequest, ProfileEducationOrganizationsGetResponse>({
    url: 'profile/education/organizations',
    body: {},
    loader: 'profile/education/organizations'
  })
  return response
}
