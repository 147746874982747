<template>
  <template v-if="loader">
    <pms-page-shimmer
      :shimmer="`table`"
      :full-width="true"
    />
  </template>
  <div v-else>
    <pms-card
      :header="false"
      class="mb-3"
      :options="{ body: { class: 'pt-5'} }"
    >
      <div class="w-100 position-relative mb-5">
        <div
          :key="'content.course-learning-outcomes'"
          class="fs-2 fw-bold text-danger text-center flex-fill"
        >
          {{ getMessage('content.course-learning-outcomes') }}
        </div>
        <button
          v-if="amIEnrolled && has_permission"
          type="button"
          class="btn btn-light-primary p-2 position-absolute top-0 end-0"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.add')"
          @click="addOutcome"
        >
          <pms-kt-icon
            :icon-name="'plus'"
            class="fw-bold fs-3 p-0"
          />
        </button>
      </div>
      <pms-smart-table
        :items="learning_outcomes"
        :headers="header"
        :options="{
          design: 'bordered-dashed'
        }"
      >
        <template
          v-for="(title, index) in learningOutcomes.map((el) => el.slotName)"
          :key="index"
          #[title]="{ item }"
        >
          {{ getMessage(item.title) }}
        </template>
        <template #no="{ idx }">
          {{ idx + 1 }}
        </template>
        <template #item-outcome="{ item }">
          {{ item.outcome }}
        </template>
        <template #item-subject="{ item }">
          {{ item.subject }}
        </template>
        <template #item-action="{ item }">
          <span class="fs-5 fw-bold">{{ item.action || '' }}</span>
        </template>
        <template #operations="{ item }">
          <div
            v-if="has_permission"
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class="btn btn-light-primary p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.view-edit')"
              @click="openEditor(item)"
            >
              <pms-kt-icon
                :icon-name="'click'"
                class="fw-bold fs-3 p-0"
              />
            </button>
            <button
              type="button"
              class="btn btn-light-danger p-2"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-dismiss-="click"
              :data-bs-original-title="$t('domains.academic.editor.syllabus.tooltip.delete')"
              @click="deleteLearningOutcome(item.id)"
            >
              <pms-kt-icon
                :icon-name="'trash'"
                class="fw-bold fs-3 p-0"
              />
            </button>
          </div>
        </template>
      </pms-smart-table>
    </pms-card>
  </div>
  <SyllabusLearningOutcomesForm v-if="modifier && modifier.name === 'syllabus.learning-outcomes'" />
</template>

<script setup lang="ts">
import { useStore } from './../store'
import { computed } from 'vue'
import { learningOutcomes, has_permission } from './../values'
import { useAlert, useLoader, useTeleportModal } from '@shared/composable'
import { AcademicManageSyllabusLearningOutcomesGetResponse } from '@shared/swagger'
import SyllabusLearningOutcomesForm from './SyllabusLearningOutcomesForm.vue'
import { getMessage } from '../../../utils'
import { useResource } from '@/shared/stores'
import { storeToRefs } from 'pinia'

const { isActive } = useLoader()
const alert = useAlert()
const { setModifier, open, modifier } = useTeleportModal()
const loader = isActive('academic/manage/syllabus/learning-outcomes')

const store = useStore()
const { learning_outcomes } = storeToRefs(store)

const resource = useResource()
const amIEnrolled = computed(() => resource.getAcademicSingleCoursesResource?.enrolled || false)
const header = computed(() => amIEnrolled.value
  ? [...learningOutcomes,
      {
        title: 'common.operations',
        field: 'operations',
        slotCellName: 'operations',
        cellClass: 'text-center',
        headerClass: 'w-50px'
      }]
  : learningOutcomes)

function openEditor (outcome: AcademicManageSyllabusLearningOutcomesGetResponse[0]) {
  setModifier(
    'syllabus.learning-outcomes',
    {
      id: outcome.id,
      outcome: outcome.outcome || '',
      subject: outcome.subject || '',
      action: outcome.action || '',
      count: outcome.count,
      mode: 'edit'
    },
    { title: 'learning-outcomes.title.edit', description: 'learning-outcomes.description.edit' }
  )
  open({ name: 'syllabus-form-content-modal' })
}
function addOutcome () {
  setModifier(
    'syllabus.learning-outcomes',
    { mode: 'add' },
    { title: 'learning-outcomes.title.add', description: 'learning-outcomes.description.add' }
  )
  open({ name: 'syllabus-form-content-modal' })
}

async function deleteLearningOutcome (id: number) {
  alert.dialog(
    {
      text: 'domains.academic.editor.syllabus.delete-learning-outcome',
      onConfirm: async () => {
        await store.deleteLearningOutcome(id)
      }
    }
  )
}
</script>
