<template>
  <div
    id="interest"
    class="col-12"
  >
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-dark fw-bold fs-2 pb-0">
        {{ $t('domains.profile.skills.interest.header') }}
      </span>
      <pms-button
        :size="'sm'"
        class="w-60px w-sm-100px"
        @click="setIdAction()"
      >
        <span class="fw-bold fs-6">
          {{ $t('common.edit') }}
        </span>
      </pms-button>
    </div>
    <pms-card
      :header="false"
      class="mt-4"
      :options="{
        body: { class: 'pb-3' }
      }"
    >
      <div v-if="interest">
        <p
          class="text-dark fs-5"
          v-html="interest"
        />
      </div>
      <pms-no-data v-else />
      <InterestForm v-if="modifier && modifier.name === 'interest'" />
    </pms-card>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import { useTeleportModal } from '@shared/composable'

import { useProfile } from '../../../store'
import InterestForm from './InterestForm.vue'

const { open, modifier, setModifier } = useTeleportModal()

const store = useProfile()

const interest = computed(() => store.getInterest)

const setIdAction = () => {
  setModifier('interest',
    {},
    {
      title: 'interest-edit-title',
      description: 'interest-edit-description'
    }
  )
  open({ name: 'skill-modal' })
}
</script>
