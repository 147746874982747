import { AcademicCoursesHistoryResponse } from '@shared/swagger'
import { arrayGroupByProperty } from '@shared/utils'
import {
  HistoryAttendanceDetailsGraph,
  HistoryAttendanceGraphItem, Series
} from '../types'
import { TabElement } from '@/shared/types'

const formatAttendanceForGraphs = (data: AcademicCoursesHistoryResponse['attendance'], sectionId: number | null): HistoryAttendanceDetailsGraph => {
  if (sectionId === null || !data.length) {
    return {
      activeHour: null,
      dates: [],
      hours: [],
      hourTabs: [{ id: '', label: '' }]
    }
  }

  const hours: Array<HistoryAttendanceGraphItem> = []
  const sectionRecords = data.filter((item) => item.section_id === sectionId)
  const groupData = arrayGroupByProperty<Omit<AcademicCoursesHistoryResponse['attendance'][0], 'section_id' | 'room'>>(sectionRecords, 'time')
  let tabs: Array<TabElement> = []
  let dates: Array<string> = []

  for (const key in groupData) {
    const [hour, minute] = key.split(':')
    dates = []
    const series: Array<Series> = [
      {
        name: 'Attended',
        data: []
      },
      {
        name: 'Absent',
        data: []
      },
      {
        name: 'Permitted',
        data: []
      }
    ]
    for (const item of groupData[key]) {
      const [, month, day] = item.date.split('-')
      dates.push(`${day}.${month}`)
      series[0].data.push(item.attended_count)
      series[1].data.push(item.absent_count)
      series[2].data.push(item.permitted_count)
    }
    tabs.push({
      id: key,
      label: `${hour}:${minute}`
    })
    hours.push({
      hour: key,
      series
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabs = tabs.sort((a: any, b: any) => b.id - a.id)

  return {
    activeHour: tabs[0] ? tabs[0].id : null,
    dates,
    hours,
    hourTabs: tabs
  }
}

export default formatAttendanceForGraphs
