import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { UnknownResponse, ProfileCertificatesPostRequest } from '@shared/swagger'

export default async function postProfileCertificates (params: ProfileCertificatesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = await post<ProfileCertificatesPostRequest, UnknownResponse>({
    url: 'profile/certificates',
    body: params,
    loader: 'profile/certificates-post'
  })

  return response
}
