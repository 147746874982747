<template>
  <pms-button
    class="mb-3 w-100"
    :text="$t('knowledge.manage.articles.new-article')"
    size="lg"
    @click="goToNewArticlePage()"
  />
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

const goToNewArticlePage = () => {
  router.push({
    path: '/knowledge-base/manage/article/new'
  })
}

</script>
