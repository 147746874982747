<template>
  <pms-container>
    <pms-row class="mt-10">
      <pms-page-shimmer
        v-if="loader"
        :shimmer="'list'"
        :full-width="true"
      />
      <template v-else>
        <compose-form id="service-message-new" />
      </template>
    </pms-row>
  </pms-container>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useLoader, useDriver } from '@shared/composable'
import { quickTourMessageBroadcastCompose } from './values'
import ComposeForm from './ui/ComposeForm.vue'

const { isActive } = useLoader()
const { setSteps: setQuickTourSteps } = useDriver()

const loader = isActive('service/message-broadcast/resources')

onMounted(async () => {
  setQuickTourSteps(await quickTourMessageBroadcastCompose())
})
</script>
