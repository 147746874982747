import { useRequests } from '@shared/composable'

import type { AxiosResponse } from '@shared/types'
import type { AcademicSingleCourseAttendancesPostRequest, UnknownResponse } from '@shared/swagger'

export default async function postAcademicCoursesAttendances (body: AcademicSingleCourseAttendancesPostRequest): Promise<AxiosResponse<UnknownResponse>> {
  const { post } = useRequests()

  const response = post<AcademicSingleCourseAttendancesPostRequest, UnknownResponse>({
    url: 'academic/courses/attendances',
    body,
    loader: 'academic/courses/attendances/post'
  })

  return response
}
