import { createPage } from '@shared-utils/storybook'

import { useGateEntryStore } from '../store'
import { useUser } from '@shared/stores'

import GateEntry from '../GateEntryPage.vue'

const Template = createPage({
  layout: 'default',
  component: GateEntry,
  setup () {
    const store = useGateEntryStore()
    const user = useUser()
    user.authMe()
    store.$reset()
    store.bootstrap()
    return {}
  }
})

export const Default = Template.bind({})

export default {
  title: 'Domains/Administrative/GateEntry',
  component: GateEntry,
  parameters: {
    design: '',
    status: {
      type: 'released',
      url: 'https://automation-team.jetbrains.space/p/pms/issue-boards'
    }
  }
}
