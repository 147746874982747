
import { defineStore } from 'pinia'
import type { Name, State, Getters, Actions } from './types'

import * as actions from './actions'

export const useStudentAuth = defineStore<Name, State, Getters, Actions>('Student.studentAuth', {
  actions: {
    ...actions
  },

  getters: {},

  state () {
    return { }
  }
})
